import React from 'react';

const AuthFooter = (): JSX.Element => {
  const companyName = `${String(new Date().getFullYear())} TreezPay`;

  return (
    <>
      <footer
        className="body-subtext-regular d-flex justify-content-center align-items-center mt-5"
        style={{
          color: '#666E7A',
          paddingBottom: '48px',
        }}
      >
        <div>© {companyName}</div>
      </footer>
    </>
  );
};

export default AuthFooter;
