import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Button } from 'reactstrap';
import URL from 'utils/url';
import { oneAppUrl } from 'utils/urlGenerator';
import { ENV } from 'utils/config';

const EmptyStoresList = () => {
  return (
    <div className={classNames('mt-7', styles.storeListContainerEmpty)}>
      <div>
        <div className={classNames(styles.emptyListHeading, 'mb-2')}>
          Welcome to your TreezPay portal!
        </div>
        <div className={classNames(styles.emptyListInfo, 'pb-3 mb-3')}>
          Manage all payment options in one place, simplifying reconciliation
          and obtaining customer transaction data to gain valuable insights.
        </div>
        <div className={classNames(styles.buttonInfo, 'mb-5')}>
          <span>
            First step is to fill out the{' '}
            <span className={styles.oneAppText}>TreezPay One Application</span>{' '}
            to get approved for all TreezPay options simultaneously.
          </span>
        </div>
        <div>
          <Button
            onClick={() => URL.openInNewTab(oneAppUrl(ENV || 'production'))}
            role="link"
            type="button"
            className={styles.addStore}
          >
            Start Application
          </Button>
          <span className={classNames(styles.resumeApplication, 'mr-1')}>
            Already started your application?
          </span>
          <span className={styles.resumeApplication}>
            <a
              target="_blank"
              href={oneAppUrl(ENV || 'production')}
              style={{ color: '#1A4007', textDecoration: 'underline' }}
            >
              Click here
            </a>{' '}
            to resume.
          </span>
        </div>
        <div className={classNames(styles.emptyPageQuestion, 'mt-7 pt-1')}>
          <span>Curious to see it in action?</span>
        </div>
        <div className={styles.oneAppLink}>
          <span>
            Check out a{' '}
            <a
              target="_blank"
              href="https://www.youtube.com/watch?v=59fSttMQ10E"
              style={{ color: '#1A4007', textDecoration: 'underline' }}
            >
              quick video
            </a>{' '}
            previewing{' '}
            <span className={styles.oneAppText}>‘TreezPay 2.0’ </span>
            to see the future of TreezPay cashless payments.
          </span>
        </div>
      </div>
    </div>
  );
};

export default EmptyStoresList;
