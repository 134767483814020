import classNames from 'classnames';
import { IFilterConfig, IFilterConfigList } from 'components/filter/types';
import React, { useEffect } from 'react';
import { Input } from 'reactstrap';
import { isListItemPresent } from 'utils/filter';
import styles from './export.module.scss';

interface IProps {
  item: IFilterConfig;
  setValues: (arg1: string, arg2: string[]) => void;
  setSelected: (arg1: string, arg2: boolean) => void;
}

const ListExport = ({ item, setValues, setSelected }: IProps): JSX.Element => {
  const { key, label, data } = item;
  const { values, options } = data as IFilterConfigList;

  useEffect(() => {
    if (values.length > 0) {
      setSelected(key, true);
    } else {
      setSelected(key, false);
    }
  }, [values]);

  return (
    <>
      <div className={classNames(styles.label)}>{label}</div>
      <div
        style={{
          columnCount: options.length > 3 ? 2 : 1,
        }}
        className={classNames(styles.container)}
      >
        {options.map((option) => {
          return (
            <div
              className={classNames('mb-1', styles.checkboxContainer)}
              key={option.name}
            >
              <Input
                type="checkbox"
                id={option.name}
                name={option.name}
                className={classNames(styles.checkbox)}
                checked={isListItemPresent(values, option.value)}
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    setValues(key, values.concat(option.value));
                  } else {
                    const arr = values.filter((v) => {
                      return Array.isArray(option.value)
                        ? !option.value.includes(v)
                        : v !== option.value;
                    });
                    setValues(key, arr);
                  }
                }}
              />
              <label
                htmlFor={option.name}
                className={classNames(styles.checkbox_label, 'body-subtext')}
              >
                {option.name || option}
              </label>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ListExport;
