import React from 'react';
import { Card } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import CustomersList, { ICustomerFilters } from './list';

const getQuery = (queryParams: string) => {
  return new URLSearchParams(queryParams);
};

const getFilters = (queryParams: string): ICustomerFilters => {
  const query = getQuery(queryParams);

  const dates = query.get('dates')?.split(',') || [];
  const dateFrom = dates.length > 0 ? dates[0] : '';
  const dateTo = dates.length > 1 ? dates[1] : '';
  const email = query.get('email');
  const amountOption = query.get('condition') || 'equal';
  const firstAmount = query.get('amount1') || undefined;
  const secondAmount = query.get('amount2') || undefined;
  const status = query.get('status') || undefined;

  return {
    email,
    dateFrom,
    dateTo,
    amountOption,
    firstAmount,
    secondAmount,
    status,
  };
};

const Customers = (): JSX.Element => {
  const location = useLocation();
  const queryParams = location?.search || '';

  const filters = getFilters(queryParams);

  return (
    <>
      <Card className="card-profile border-0">
        <CustomersList filters={filters} queryParams={queryParams} />
      </Card>
    </>
  );
};

export default Customers;
