import { apiWithOrg } from 'services/api';
import { useStateValue } from '../..';
import {
  RESET_LOGISTICS_UPDATE,
  action,
  updateLogistics,
  updateLogisticsFailure,
  updateLogisticsSuccess,
} from '../actions';
import { SETTINGS } from '../type';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useSettings = () => {
  const { state, dispatch } = useStateValue();
  const { settings } = state;

  const toUpdateLogisticsSettings = async (values: SETTINGS.ILogistics) => {
    dispatch(updateLogistics());
    try {
      const response = await apiWithOrg.put(
        `/organizations/logistic_provider`,
        values
      );
      dispatch(updateLogisticsSuccess(response?.data));
    } catch (e) {
      dispatch(updateLogisticsFailure(e));
    }
  };

  const toResetLogisticsUpdate = () => {
    dispatch(action(RESET_LOGISTICS_UPDATE));
  };
  return {
    settings,
    toUpdateLogisticsSettings,
    toResetLogisticsUpdate,
  };
};

export default useSettings;
