import React, { useState, useEffect } from 'react';
import { formatToTz } from 'utils/date-format';
import classNames from 'classnames';
import { PAYMENTS } from 'state/payment/type';
import { Link } from 'react-router-dom';
import routeConfig from 'routes/config';
import { transactionSourceFormatter } from 'utils/source-formatter';
import BackButton from 'components/backButton';
import AmountDetails from 'components/amount-details';
import CopyIDToClipboard from 'components/copyToClipboard';
import { EVENTS } from 'state/events/type';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import { transactionStatusFormatter } from '../../../../utils/status-formatter';
import styles from '../../sessions.module.scss';

interface IProps {
  info: EVENTS.ISessionEvents;
  consumerInfo: PAYMENTS.IConsumerInfo;
}

const Summary = ({
  info = {} as EVENTS.ISessionEvents,
  consumerInfo = {} as PAYMENTS.IConsumerInfo,
}: IProps): JSX.Element => {
  const { organisation } = useOrganisation();
  const { settings } = organisation;

  // Copy to clipboard params
  const [copied, setCopied] = useState(false);
  // Set copied to false after 2 seconds
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  return (
    <>
      <BackButton />
      <AmountDetails
        amount={info.total_amount}
        unit="USD"
        statusBadge={transactionStatusFormatter(info.status)}
      />
      <CopyIDToClipboard id={info.id || 'NA'} />
      <hr className="my-0" />
      <div className="d-flex align-items-center mb-7 pt-2 mt-1">
        <p className={classNames('SettlementSummaryPayment', 'pr-5')}>
          <div className={classNames('SettlementSummaryHeading')}>
            Create Date
          </div>
          <div className={classNames('SettlementHeadingData')}>
            {info.create_date
              ? formatToTz(
                  info.create_date,
                  settings?.timezone,
                  'MMM dd, hh:mm a'
                )
              : 'NA'}
          </div>
        </p>
        <p className={classNames('SettlementSummaryPayment', 'pl-5', 'pr-5')}>
          <div className={classNames('SettlementSummaryHeading')}>
            Swifter Id
          </div>
          <div
            className={classNames(
              styles.summaryResult,
              'SettlementHeadingData'
            )}
          >
            {info.track_number?.toUpperCase() || ''}
          </div>
        </p>
        <p className={classNames('SettlementSummaryPayment', 'pl-5', 'pr-5')}>
          <div className={classNames('SettlementSummaryHeading')}>Source</div>
          <div
            className={classNames(
              styles.summaryResult,
              'SettlementHeadingData'
            )}
          >
            {transactionSourceFormatter(info.source)}
          </div>
        </p>
        {consumerInfo.first_name && (
          <p className={classNames('pl-5', 'pr-5 SettlementSummaryPayment')}>
            <div className={classNames('SettlementSummaryHeading')}>
              Customer
            </div>
            <div className={classNames('SettlementHeadingData')}>
              {!consumerInfo.consumer_profile_id ? (
                consumerInfo.consumer_id
              ) : (
                <Link
                  to={`../../${routeConfig.CUSTOMERS.layout}/${consumerInfo.consumer_profile_id}`}
                >
                  {consumerInfo.first_name} {consumerInfo.last_name || ''}
                </Link>
              )}
            </div>
          </p>
        )}
      </div>
    </>
  );
};

export default Summary;
