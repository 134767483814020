import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import { IView } from 'views';
import NavitemSidebar from './navitem';
import styles from './sidebar.module.scss';

interface IProps {
  open: boolean;
  options: IView;
  closeCollapse: () => void;
  routes: IView[];
  layout: string;
}

const ExpandableNavitemSidebar: FC<IProps> = ({
  open,
  options,
  closeCollapse,
  routes,
  layout,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const toggleSettings = () => setIsOpen(!isOpen);
  const createLinks = () => {
    return routes
      .filter((route) => route.hasSidebar && route.layout === layout)
      .map((prop) => {
        const { children } = prop;
        return (
          <>
            {(children || [])
              .filter((item) => item.hasSidebar)
              .map((item) => (
                <NavitemSidebar
                  key={item.name}
                  options={item}
                  closeCollapse={closeCollapse}
                ></NavitemSidebar>
              ))}
          </>
        );
      });
  };

  return (
    <>
      <Nav navbar className={classNames(styles.collapse)}>
        <NavItem key={options.name}>
          <NavLink
            id={options.name.replace(/\s+/g, '').toLowerCase()}
            onClick={toggleSettings}
            className={classNames(styles.sidebarTab)}
          >
            <span className="d-flex align-items-center">
              <i className={options.icon}></i>
              {options.name}
            </span>
          
              {isOpen ? (
                <i className={classNames('ph-caret-up', styles.icon_caret)}></i>
              ) : (
                <i
                  className={classNames('ph-caret-down', styles.icon_caret)}
                ></i>
              )}
          </NavLink>
        </NavItem>
      </Nav>
      <Collapse isOpen={isOpen} id="options">
        <Nav navbar>{createLinks()}</Nav>
      </Collapse>
    </>
  );
};

export default ExpandableNavitemSidebar;
