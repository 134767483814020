import React from 'react';
import ComingSoon from 'components/comingSoon';

const NotFoundError = (): JSX.Element => {
  return (
    <>
      <ComingSoon />
    </>
  );
};

export default NotFoundError;
