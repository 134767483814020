import React from 'react';
import classNames from 'classnames';
import { PAYMENTS } from 'state/payment/type';
import AmountDetails from 'components/amount-details';
import { Card } from 'reactstrap';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import STRING from 'utils/string';
import styles from './styles.module.scss';

const CashPaymentReview = ({
  info,
}: {
  info: PAYMENTS.IIndividualTransactionResult;
}) => {
  const { organisation } = useOrganisation();
  const { activeOrg } = organisation;

  const storeLogoUrl = activeOrg?.brand_settings?.logo_url;
  const storeName = activeOrg?.brand_settings?.name || '';

  const customerName = `${info?.customer_first_name} ${
    info?.customer_last_name || ''
  }`;
  const customerPhone = STRING.formatToUSPhoneNumber(info.customer_phone);

  const PayerCard = (
    <Card
      className={classNames(styles.reviewCard, 'mr-5')}
      data-testid="card-payer"
    >
      <div className={styles.iconContainer}>
        <i className="ph-user-circle-light icon-2xl" />
      </div>
      <div>
        <div className="body-subtext-medium text-capitalize">
          {customerName}
        </div>
        {customerPhone && (
          <p className="footnote text-muted">{customerPhone}</p>
        )}
      </div>
    </Card>
  );

  const PayeeCard = (
    <Card className={styles.reviewCard} data-testid="card-payee">
      <div
        className={classNames(
          styles.iconContainer,
          styles.iconContainerRound,
          'mr-2'
        )}
      >
        {storeLogoUrl ? (
          <img src={storeLogoUrl} alt={`${storeName} logo`} />
        ) : (
          <i className="ph-storefront-light icon-2xl" />
        )}
      </div>
      <div className="body-subtext-medium">{storeName}</div>
    </Card>
  );

  return (
    <div className={styles.reviewContainer}>
      <div className={styles.reviewAmount}>
        <div className="mb-1">Cash payment of</div>
        <AmountDetails
          amount={info?.charge_amount || 0}
          className={styles.reviewAmount}
        />
      </div>
      <div className="d-flex justify-content-center align-items-center">
        {PayerCard}
        <div className="mr-5">
          <i className="ph-arrow-right-bold align-items-center icon-2xl" />
        </div>
        {PayeeCard}
      </div>
    </div>
  );
};

export default CashPaymentReview;
