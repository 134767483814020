import classNames from 'classnames';
import React from 'react';
import { Input } from 'reactstrap';
import styles from './filters.module.scss';
import { IFilterConfig, IFilterConfigString } from './types';

interface IProps {
  item: IFilterConfig;
  setSelected: (arg1: string, arg2: boolean) => void;
  setValue: (arg1: string, arg2: number, arg3: string) => void;
}

const StringFilter = ({ item, setSelected, setValue }: IProps): JSX.Element => {
  const { selected, key, label, data } = item;
  const { values, placeholder } = data as IFilterConfigString;
  const value = values[0];
  const placeholderValue = placeholder;
  return (
    <>
      <div className={classNames(styles.filterMenu_item)}>
        <Input
          data-testid="string-checkbox"
          id={label}
          type="checkbox"
          className={classNames(styles.checkbox)}
          checked={selected}
          onChange={(e) => {
            setSelected(key, e.currentTarget.checked);
          }}
        />
        <label htmlFor={label} className={classNames('body-subtext-regular')}>
          {label}
        </label>
      </div>

      {selected && (
        <div className={classNames(styles.filterMenu_dropdown)}>
          <div className={classNames()}>
            <Input
              placeholder={placeholderValue}
              className={classNames(styles.input, 'w-100', 'mb-2')}
              defaultValue={value}
              onChange={(e) => {
                setValue(key, 0, e.target.value);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default StringFilter;
