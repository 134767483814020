const QUERY = {
  /**
   * Checks if parameter value is of valid type
   * @param value
   * @returns True is value is valid, false otherwise
   */
  isArgValid: (value: unknown): boolean => {
    return typeof value !== 'object' && Boolean(value || value === 0);
  },

  /**
   * Builds a complete query string from the given key-value pairs
   * @param params Object containing all parameter-values
   * @returns URL query string
   */
  build: (params: Record<string, unknown>): string => {
    const queryString = Object.keys(params)
      .map((key) => {
        return QUERY.isArgValid(params[key]) ? `${key}=${params[key]}` : null;
      })
      .filter(Boolean)
      .join('&');

    return queryString ? `?${queryString}` : '';
  },

  /**
   * Updates the existing query string with the given key-value pairs
   * @param searchStr Current query string
   * @param params Object with key-values to tbe updated
   * @returns URL query string
   */
  update: (searchStr: string, params: Record<string, unknown>): string => {
    const query = new URLSearchParams(searchStr);
    Object.keys(params).forEach((key) => {
      const value = params[key];
      if (QUERY.isArgValid(value)) query.set(key, String(value));
      else query.delete(key);
    });
    return decodeURIComponent(query.toString());
  },
};

export default QUERY;
