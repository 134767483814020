import React from 'react';
import { Card, CardText, CardBody, Row, Col } from 'reactstrap';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import CURRENCY from 'utils/currency';
import { formatToTz } from 'utils/date-format';
import { TRANSACTION_DETAILS_HEADINGS } from '../../../../utils/constants';
import { transactionStatusFormatter } from '../../../../utils/status-formatter';

export interface IOrderInfo {
  statementReference?: string;
  status?: string;
  amount?: number;
  createdAt?: string;
  charge: string;
}

interface prop {
  info: IOrderInfo;
}
const Orders = ({ info }: prop): JSX.Element => {
  const {
    organisation: { settings },
  } = useOrganisation();

  return (
    <>
      <Card className="mb-4 d-flex justify-content-center">
        <CardBody>
          <Row>
            <Col>
              You are currently viewing an order for which an ACH request has
              not been initiated. When this order is marked as ready/delievered,
              the ACH request will be initiated, and this order will move to the
              &apos;Payment initiated&apos; tab.
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="mb-5 TransactionDetailsCard">
        <CardBody>
          <Row>
            <Col>
              <h4>
                <i className="fas fa-dollar-sign text-muted pr-2" />
                Transaction Details
              </h4>
            </Col>
            <Col className="d-flex justify-content-end">
              <h5 className="text-muted mb-0">{info.charge}</h5>
            </Col>
          </Row>

          <Row className="mt-3 mb-3">
            <Col>
              <CardText>
                <h5 className="text-muted mb-0">
                  {TRANSACTION_DETAILS_HEADINGS.HEADINGS_1}
                </h5>
                {CURRENCY.convertToMainUnit(info.amount)}
              </CardText>
            </Col>
            <Col>
              <CardText>
                <h5 className="text-muted mb-0">
                  {TRANSACTION_DETAILS_HEADINGS.HEADINGS_5}
                </h5>
                {info.statementReference}
              </CardText>
            </Col>
            <Col>
              <CardText>
                <h5 className="text-muted mb-0">
                  {TRANSACTION_DETAILS_HEADINGS.HEADINGS_2}
                </h5>
                {transactionStatusFormatter(info.status)}
              </CardText>
            </Col>
          </Row>
          <Row className="mt-3 mb-3"></Row>
          <Row className="mt-3 mb-3">
            <Col>
              <CardText>
                <h5 className="text-muted mb-0">
                  {TRANSACTION_DETAILS_HEADINGS.HEADINGS_3}
                </h5>
                {info.createdAt &&
                  formatToTz(
                    info.createdAt,
                    settings?.timezone,
                    'MMM dd yyyy, HH:mm'
                  )}
              </CardText>
            </Col>
            <Col></Col>

            <Col></Col>
          </Row>
          <Row className="mt-3 mb-3">
            <Col />
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default Orders;
