import React from 'react';
import usePayment from 'state/payment/hooks/usePayment';
import Orders from './transactionDetailsCard/order';
import PaymentDetails from './transactionDetailsCard/paymentDetails';
import PaymentMethod from './transactionDetailsCard/paymentMethod';
import Summary from './transactionDetailsCard/summary';

const TransactionPartnerDetails = (): JSX.Element => {
  // payment object and hooks to fetch transactions
  const { payment } = usePayment();

  const isDataAvailable = () =>
    Object.keys(payment?.individualTransaction).length > 0 &&
    payment.individualTransaction.transaction &&
    payment.error?.addIndividualTransaction;

  const isOrderCharged = () =>
    payment?.individualTransaction &&
    payment?.individualTransaction.transaction;

  return (
    <>
      {isDataAvailable() && (
        <>
          {isOrderCharged() && (
            <>
              <Summary info={payment?.individualTransaction?.transaction} />
              <PaymentDetails
                info={payment?.individualTransaction?.transaction}
              />
              <PaymentMethod
                info={payment?.individualTransaction?.transaction}
              />
            </>
          )}
          {!isOrderCharged() && (
            <Orders
              info={{
                charge: 'na',
              }}
            />
          )}
        </>
      )}
      {!isDataAvailable() ?? (
        <div className="pt-2 pb-2">
          <h5 className="d-flex justify-content-center">
            No details available
          </h5>
        </div>
      )}
    </>
  );
};

export default TransactionPartnerDetails;
