import { apiWithOrg } from 'services/api';
import { useStateValue } from '../..';
import {
  action,
  actionFailure,
  actionSuccess,
  FETCH_SUMMARY_CARDS,
  FETCH_GRAPH_DATA,
  FETCH_CUSTOMER_INFO,
  setSummaryTab,
  setIntervalTypes,
} from '../actions';
import { STATS } from '../type';

const useStats = () => {
  const { state, dispatch } = useStateValue();
  const { stats } = state;

  const toFetchSummaryCard = async (request: STATS.ISummaryCardRequest) => {
    dispatch(action(FETCH_SUMMARY_CARDS));
    try {
      if (
        request.payment_type != undefined &&
        request.payment_type.trim() === ''
      ) {
        request.payment_type = undefined;
      }
      const response = await apiWithOrg.get('/dashboards/new/stats', request);
      dispatch(actionSuccess(FETCH_SUMMARY_CARDS, response?.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_SUMMARY_CARDS, e));
    }
  };

  const toFetchGraphPoints = async (request: STATS.IGraphRequest) => {
    dispatch(action(FETCH_GRAPH_DATA));
    try {
      const response = await apiWithOrg.get(
        '/dashboards/new/graph/gross_amount',
        request
      );
      dispatch(actionSuccess(FETCH_GRAPH_DATA, response?.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_GRAPH_DATA, e));
    }
  };

  const toFetchCustomerInfo = async (request: STATS.ICustomerInfoRequest) => {
    dispatch(action(FETCH_CUSTOMER_INFO));
    try {
      const [topOrders, freqOrders, freqReturns] = await Promise.all([
        apiWithOrg.get('/dashboards/consumer/top_orders', request),
        apiWithOrg.get('/dashboards/consumer/frequent_orders', request),
        apiWithOrg.get('/dashboards/consumer/frequent_returns', request),
      ]);
      const payload = {
        topOrders: topOrders.data,
        freqOrders: freqOrders.data,
        freqReturns: freqReturns.data,
      };
      dispatch(actionSuccess(FETCH_CUSTOMER_INFO, payload));
    } catch (e) {
      dispatch(actionFailure(FETCH_CUSTOMER_INFO, e));
    }
  };

  const toSetSummaryTab = (activeTab: STATS.graphType) => {
    dispatch(setSummaryTab(activeTab));
  };

  const toSetIntervalTypes = (intervalTypes: STATS.IIntervalType[]) => {
    dispatch(setIntervalTypes(intervalTypes));
  };

  return {
    stats,
    toFetchSummaryCard,
    toFetchGraphPoints,
    toFetchCustomerInfo,
    toSetSummaryTab,
    toSetIntervalTypes,
  };
};

export default useStats;
