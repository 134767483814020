import ErrorMessage from 'components/errorMessage';
import classNames from 'classnames';
import FormLabel from 'components/form-label';
import { withAdminRole } from 'components/rbac/helper';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, FormGroup, Input, Form, Alert } from 'reactstrap';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import CURRENCY from 'utils/currency';
import styles from './styles.module.scss';
import NumberFormat from 'react-number-format';

const Payments = (): JSX.Element => {
  const { organisation, toUpdateOrgSettings } = useOrganisation();
  const { settings, activeOrg } = organisation;

  const { register, handleSubmit, errors, setValue, control, getValues } =
    useForm({
      defaultValues: {
        convenience_fee:
          CURRENCY.convertToMainUnit(settings?.convenience_fee) || 0,
        has_tip: settings?.has_tip || false,
        global_transaction_limit:
          CURRENCY.convertToMainUnit(settings?.global_transaction_limit) || 0,
        auto_retry: settings?.auto_retry,
      },
    });

  const setFormValues = () => {
    if (settings) {
      setValue(
        'convenience_fee',
        CURRENCY.convertToMainUnit(settings?.convenience_fee)
      );
      setValue('has_tip', settings.has_tip);
      setValue(
        'global_transaction_limit',
        CURRENCY.convertToMainUnit(settings.global_transaction_limit)
      );
    }
  };

  useEffect(() => {
    setFormValues();
  }, [settings]);

  useEffect(() => {
    setFormValues();
  }, []);

  const SaveButton = () => (
    <Button
      size="md"
      color="primary"
      type="submit"
      className="btn btn-primary my-10"
      style={{ width: '174px' }}
    >
      Save
    </Button>
  );

  const saveButton = withAdminRole(SaveButton);

  const getCurrencyValue = (input: string) =>
    CURRENCY.convertToSmallestUnit(CURRENCY.getValueFromCurrencyStr(input));

  const onFormSubmit = (data: { [x: string]: number | boolean | string }) => {
    const updatedFees = getCurrencyValue(data.convenience_fee as string);
    const maxLimit = data.global_transaction_limit
      ? getCurrencyValue(data.global_transaction_limit as string)
      : undefined;

    if (activeOrg) {
      toUpdateOrgSettings({
        convenience_fee: updatedFees,
        has_tip: data.has_tip as boolean,
        auto_retry: data.auto_retry as boolean,
        global_transaction_limit: maxLimit,
        timezone: settings?.timezone,
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)} style={{ width: '772px' }}>
      <div className="my-5">
        <h2 className={styles.storePaymentsHeaders}>Convenience fee</h2>
        {/* <div className="mb-10 body-subtext" style={{ color: '#4D5664' }}>
          copy
        </div> */}
        <FormGroup>
          <FormLabel
            element="convenience_fee"
            label="Convenience fee"
            className="body-subtext-medium mb-1"
            style={{ color: '#333D4E' }}
          ></FormLabel>
          <Controller
            control={control}
            name="convenience_fee"
            id="convenience_fee"
            rules={{
              required: true,
            }}
            render={(field) => (
              <NumberFormat
                name="convenience_fee"
                id="convenience_fee"
                data-testid="convenience_fee"
                prefix="$"
                fixedDecimalScale
                decimalScale={2}
                placeholder="$1.5"
                className={classNames(
                  styles.storePaymentInput,
                  'd-block form-control',
                  errors?.convenience_fee ? styles.borderAlert : ''
                )}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  field.onChange(e);
                }}
                value={field.value}
                getInputRef={field.ref}
              />
            )}
          />
          {errors?.convenience_fee && (
            <ErrorMessage
              isValidation
              message="This is a required field."
              preIcon={
                <i
                  className="ph-warning-circle-fill dangerIcon p-0 icon-lg"
                  style={{ marginRight: '2px' }}
                ></i>
              }
            />
          )}
        </FormGroup>
      </div>
      <div className="my-5">
        <h2 className={styles.storePaymentsHeaders}>
          Customer transaction limit
        </h2>
        <div className="mb-10 body-subtext" style={{ color: '#4D5664' }}>
          You can put a transactional limit on prepaid orders per customer to
          reduce the risk from fraudulent ACH transactions.
        </div>
        <Alert color="info" className={styles.storeTxnLimitBanner}>
          <i className="ph-info-fill icon-xl mr-10"></i>
          <div>
            Please ensure that limit for the order value should be between $150
            to $999.
          </div>
        </Alert>
        <FormGroup>
          <FormLabel
            element="global_transaction_limit"
            label="Customer transaction limit"
            className="body-subtext-medium mb-1"
            style={{ color: '#333D4E' }}
          ></FormLabel>
          <Controller
            control={control}
            name="global_transaction_limit"
            id="global_transaction_limit"
            rules={{
              validate: (v: string) => {
                if (v) {
                  const value = Number(v.split('$')?.[1] || 0);
                  return value >= 150 && value <= 999;
                }
                return false;
              },
            }}
            render={(field) => (
              <NumberFormat
                name="global_transaction_limit"
                id="global_transaction_limit"
                data-testid="global_transaction_limit"
                prefix="$"
                fixedDecimalScale
                decimalScale={2}
                placeholder="$500"
                className={classNames(
                  styles.storePaymentInput,
                  'd-block form-control',
                  errors?.global_transaction_limit ? styles.borderAlert : ''
                )}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  field.onChange(e);
                }}
                value={field.value}
                getInputRef={field.ref}
              />
            )}
          />
          {errors?.global_transaction_limit?.type === 'validate' && (
            <ErrorMessage
              isValidation
              message="Limit should be between $150 & $999."
              preIcon={
                <i
                  className="ph-warning-circle-fill dangerIcon p-0 icon-lg"
                  style={{ marginRight: '2px' }}
                ></i>
              }
            />
          )}
        </FormGroup>
      </div>
      <div className="my-5">
        <h2 className={styles.storePaymentsHeaders}>Balance Verification</h2>
        <p className="body-subtext" style={{ color: '#4D5664' }}>
          <p className="body-subtext mb-4">
            Your balance verification criteria is set as follows:
          </p>
          <p className="body-subtext mb-4">
            Customer should have a balance sufficient to cover the order amount,
            and have a remaining balance of 10% of the order total, or $25
            whichever is higher.
          </p>
          <p className="body-subtext mb-4">
            Eg: If a customer wants to place an order of $100, Swifter checks
            that their balance is atleast $125. If they have less than this
            amount, their order will be declined due to "insufficent balance".
          </p>
        </p>
      </div>
      <div className="my-5">
        <h2 className={styles.storePaymentsHeaders}>Accept Tips</h2>
        <FormGroup check className="d-flex align-items-center">
          <Input
            type="checkbox"
            id="has_tip"
            name="has_tip"
            className="mt-0"
            innerRef={register}
          />
          <label
            className="body-subtext"
            htmlFor="has_tip"
            style={{ color: '#4D5664', marginLeft: '9px' }}
          >
            Accept tips from your customers while paying for their orders
          </label>
        </FormGroup>
      </div>
      <div className="my-5">
        <h2 className={styles.storePaymentsHeaders}>Smart retry</h2>
        <Alert color="info" className={styles.storeTxnLimitBanner}>
          <i className="ph-info-fill icon-xl mr-10"></i>
          <div>
            Smart retry is applicable for Failed ACHs whose return codes are
            R01. <a href="https://support.treez.io/hc/en-us/articles/24330799105165-What-are-ACH-Smart-Retries" target="_blank">Learn more</a>
          </div>
        </Alert>
        <FormGroup check className="d-flex align-items-start my-5">
          <Input
            type="checkbox"
            id="auto_retry"
            name="auto_retry"
            className="mt-0"
            innerRef={register}
            style={{ top: '7px' }}
          />
          <div className="ml-10">
            <label
              className="body-subtext"
              htmlFor="auto_retry"
              style={{ color: '#4D5664' }}
            >
              Enable Smart retry
            </label>
            <div
              className="body-subtext"
              style={{ color: '#666E7A', fontSize: '12px' }}
            >
              Smart retry automatically schedules retry attempts for failed
              transactions. We'll help ensure successful payments by timing the
              retry for when your customers are most likely to have sufficient
              funds available.
            </div>
          </div>
        </FormGroup>
      </div>
      {saveButton}
    </Form>
  );
};
export default Payments;
