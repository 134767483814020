import React from 'react';
import { Container, Row } from 'reactstrap';
import AuthNavbar from 'components/navbar/auth';
import AuthFooter from 'components/footer/auth';
import { Outlet } from 'react-router-dom';
import styles from './layouts.module.scss';

const Layout = (): JSX.Element => {
  return (
    <div className={styles.authLayout}>
      <div>
        <AuthNavbar />
        <Container className="mt-5 p-0">
          <Row className="justify-content-center align-items-center m-0 p-0">
            <Outlet />
          </Row>
        </Container>
      </div>
      <AuthFooter />
    </div>
  );
};

export default Layout;
