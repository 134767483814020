export const {
  REACT_APP_SPARC_BASE: SPARC_BASE,
  REACT_APP_USER_VERIFY_BASE: USER_VERIFY_BASE,
  REACT_APP_SPARC_BASE_SANDBOX: SPARC_BASE_SANDBOX,
  REACT_APP_MSO_GATEWAY_BASE_URL: MSO_GATEWAY_BASE_URL,
  REACT_APP_ENV: ENV,
  REACT_APP_USER_POOL_ID: USER_POOL_ID,
  REACT_APP_CLIENT_ID: CLIENT_ID,
  REACT_APP_SENTRY_DSN: SENTRY_DSN,
  REACT_APP_SENTRY_ENV: SENTRY_ENV,
  REACT_APP_PENDO_KEY: PENDO_KEY,
  REACT_APP_CHECKOUT_HOST: CHECKOUT_HOST,
  REACT_APP_FULLSTORY_ORG_ID: FULLSTORY_ORG_ID,
  REACT_APP_INTERCOM_KEY: INTERCOM_KEY
} = process.env;
