import React from 'react';
import { Card, CardBody } from 'reactstrap';

const NotAuthorizedError = (): JSX.Element => {
  return (
    <>
      <Card className="card-profile border-0">
        <CardBody className="bg-white border-0 text-center">
          <h2>Not Authorized</h2>
          <p className="pt-3 text-regular">
            You do not have permissions to view this page.
          </p>
          <p className="text-regular">Contact your team admin.</p>
        </CardBody>
      </Card>
    </>
  );
};

export default NotAuthorizedError;
