import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button, Row } from 'reactstrap';
import usePayment from 'state/payment/hooks/usePayment';
import { PAYMENTS } from 'state/payment/type';
import { formatToTz } from 'utils/date-format';
import { findEvent } from 'utils/timelineEvents';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import Notes from '../notepad/notes';
import styles from '../../transactions.module.scss';

interface IProps {
  info?: PAYMENTS.IIndividualTransactionResult;
  events?: PAYMENTS.IIndividualSession[];
  addNotes?: boolean;
  className?: string;
}

const Timeline = ({
  info = {} as PAYMENTS.IIndividualTransactionResult,
  events = {} as PAYMENTS.IIndividualSession[],
  addNotes = true,
  className = '',
}: IProps): JSX.Element => {
  const {
    id,
    order_id,
    updated_by_username,
    notes_added_date,
    timeline_notes,
  } = info;

  const [noteAreaOpen, setNoteAreaOpen] = useState(false);

  const toggle = () => setNoteAreaOpen((prevState) => !prevState);
  const [inputValue, setInputValue] = useState('');
  const { toAddNotes } = usePayment();
  const closeNoteArea = () => {
    setNoteAreaOpen(false);
    setInputValue('');
  };
  const { toFetchIndividualTransaction } = usePayment();
  const { payment } = usePayment();
  const request: PAYMENTS.IAddNotes = {
    notes_data: inputValue,
  };
  const {
    organisation: { settings },
  } = useOrganisation();

  const updateNote = () => {
    toAddNotes(id, request);
  };
  useEffect(() => {
    if (payment.addedNote) {
      toFetchIndividualTransaction(order_id);
      closeNoteArea();
    }
  }, [payment.addedNote]);

  const getEventDetails = (event: PAYMENTS.IIndividualSession) => {
    const { eventName, eventIcon } = findEvent.findEventName(event, info);
    // var for session log
    return { eventName, eventIcon };
  };

  // const uniqueEvents = events.length > 1 ? events?.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i) : [];

  return (
    <>
      <div className={`mb-7 ${className}`}>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h3 className="mb-2 PaymentDetailsHeading">Timeline</h3>
          {addNotes && !noteAreaOpen && (
            <Button
              onClick={toggle}
              size="md"
              className={classNames(
                'd-flex align-items-center pt-2 pb-2 pr-3 pl-2',
                styles.addNotebtn
              )}
            >
              <i className="ph-plus icon-lg pr-1 pl-1" /> Add Note
            </Button>
          )}
        </div>
        {noteAreaOpen && (
          <Notes
            inputValue={inputValue}
            noteAreaOpen={noteAreaOpen}
            setInputValue={setInputValue}
            closeNoteArea={closeNoteArea}
            updateNote={updateNote}
          />
        )}
        <hr className="mt-0 mb-2 pb-1" />
        {timeline_notes && (
          <Row className="pl-4">
            <div className="pr-5 d-flex PL6">
              <div className={classNames(styles.timelineIcon)}>
                <i className="ph-note-fill icon-lg" />
              </div>
              <div className="pb-4 DIB">
                <div className="TimelineSummaryHeading">{timeline_notes}</div>
                <div className="TimelineHeadingData">
                  <span>
                    {' '}
                    {formatToTz(
                      notes_added_date,
                      settings?.timezone,
                      'MMM dd, hh:mm a'
                    )}{' '}
                    by {updated_by_username}
                  </span>
                </div>
              </div>
            </div>
          </Row>
        )}

        {/* {transaction_canceled_date && (
          <Row className="pl-4">
            <div className="pr-5 d-flex PL6">
              <div className={classNames(styles.timelineIcon)}>
                <i className="ph-x-circle-fill" />
              </div>

              <div className="pb-4 DIB">
                <div className="TimelineSummaryHeading">
                  Transaction canceled
                </div>
                <div className="TimelineHeadingData">
                  {formatDate(transaction_canceled_date, 'MMM dd, hh:mm a')}
                </div>
              </div>
            </div>
          </Row>
        )} */}
        {/* {return_date && (
          <Row className="pl-4">
            <div className="pr-5 d-flex PL6">
              <div className={classNames(styles.timelineIcon)}>
                <i className="ph-arrow-circle-left-fill" />
              </div>
              <div className="pb-4 DIB">
                <div className="TimelineSummaryHeading">
                  Payment (ACH) returned with the code{' '}
                  <span className="text-regular">
                    {return_code} {return_reason && `(${return_reason})`}
                  </span>
                </div>{' '}
                <div className="TimelineHeadingData">
                  {formatDate(return_date, 'MMM dd, hh:mm a')}
                </div>
              </div>
            </div>
          </Row>
        )} */}
        {/* {ach_upload_date && (
				<Row className="pl-4">
					<div className="pr-5 d-flex PL6">
						<div className={classNames(styles.timelineIcon)}>
							<i className="fas fa-money-check text-muted" />
						</div>
						<div className="pb-4 DIB">
							<div className="TimelineSummaryHeading">
								Payment (ACH) file uploaded
							</div>
							<div className="TimelineHeadingData">
								{formatDate(ach_upload_date, 'MMM dd, hh:mm a')}
							</div>
						</div>
					</div>
				</Row>
			)}
			{charge_create_date && (
				<Row className="pl-4">
					<div className="pr-5 d-flex PL6">
						<div className={classNames(styles.timelineIcon)}>
							<i className="fas fa-coins text-muted" />
						</div>
						<div className="pb-4 DIB">
							<div className="TimelineSummaryHeading">Payment initiated</div>{' '}
							<div className="TimelineHeadingData">
								{formatDate(charge_create_date, 'MMM dd, hh:mm a')}
							</div>
						</div>
					</div>
				</Row>
			)} */}
        {events.length >= 1 && (
          <>
            {events
              .slice()
              .reverse()
              .map((e, index) => {
                const sessionEventClass = getEventDetails(e);
                return (
                  <>
                    {e.create_date && sessionEventClass.eventName && (
                      <Row className="pl-4">
                        <div className="pr-5 d-flex PL6">
                          <div
                            className={
                              index === events.length - 1
                                ? classNames(styles.timelineIcon, styles.last)
                                : classNames(styles.timelineIcon)
                            }
                          >
                            <i
                              className={classNames(
                                sessionEventClass.eventIcon
                              )}
                            />
                          </div>
                          <div className="pb-4 DIB">
                            <div className="TimelineSummaryHeading">
                              {sessionEventClass.eventName}
                            </div>
                            <div className="TimelineHeadingData">
                              {formatToTz(
                                e.create_date,
                                settings?.timezone,
                                'MMM dd, hh:mm a'
                              )}
                            </div>
                          </div>
                        </div>
                      </Row>
                    )}
                  </>
                );
              })}
          </>
        )}
      </div>
    </>
  );
};

export default Timeline;
