import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import useAuth from '../../state/auth/hooks/useAuth';
import styles from './auth.module.scss';
import { ssoLoginCallback, treezLoginPageUrl } from 'utils/urlGenerator';
import { ENV } from 'utils/config';
import { oneAppUrl } from 'utils/urlGenerator';

const LoginSSO = (): JSX.Element => {
  // Variables to keep track of the alerts
  const { toSetSSOLogin } = useAuth();

  // TODO: treez login page url - env map
  const ssoLogin = async () => {
    toSetSSOLogin();

    const env = ENV || 'production';
    const url = `${treezLoginPageUrl(
      env
    )}/?application=MSO&callback=${ssoLoginCallback(env)}&authorize=true`;
    window.open(url, '_top');
  };

  return (
    <>
      <div>
        <div id="SignInText" className={classNames(styles.ssoLoginSubHeading)}>
          Log in with SellTreez credentials
        </div>
        <div className={classNames(styles.ssoLoginButtonContainer)}>
          <Button
            data-testid="ssoLoginBtn"
            color=""
            type="submit"
            className={classNames(styles.loginButton)}
            onClick={() => {
              ssoLogin();
            }}
          >
            Log in
          </Button>
        </div>
        <div className="pl-5 body-subtext" style={{ color: '#4D5664' }}>
          Don’t have an account?
          <a
            rel="noreferrer"
            target="_blank"
            href={oneAppUrl(ENV || 'production')}
            className={classNames('ml-1 p-0', styles.forgotPasswordButton)}
          >
            Sign up
          </a>
        </div>
      </div>
    </>
  );
};

export default LoginSSO;
