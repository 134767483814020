import React, { Dispatch, SetStateAction, useState } from 'react';
import classNames from 'classnames';
import ModalContainer from 'components/modals/container';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from 'reactstrap';
import routeConfig from 'routes/config';
import useConsumer from 'state/consumer/hooks/useConsumer';

interface IProps {
  modal: boolean;
  customerProfileId: string;
  activeOrgId: string | null;
  setModal: Dispatch<SetStateAction<boolean>>;
}

/* Delete Customer modal on Customer Details page */
const DeleteCustomerModal = (props: IProps): JSX.Element => {
  const modalTitle = 'Delete customer';
  const { modal, customerProfileId, activeOrgId, setModal } = props;
  const { toRemoveCustomer } = useConsumer();
  const navigate = useNavigate();

  const toggle = () => {
    setModal(!modal);
  };

  const modalClose = () => {
    if (activeOrgId) {
      setModal(!modal);
      navigate(`/${activeOrgId}/${routeConfig.CUSTOMERS.layout}`);
    }
  };

  const deleteAction = () => {
    if (activeOrgId) {
      toRemoveCustomer(customerProfileId);
      modalClose();
    }
  };

  const [typedText, setTypedText] = useState('');

  const footerContent = (
    <div>
      <Button onClick={toggle} size="lg" className="btn-text">
        Cancel
      </Button>
      <Button
        size="lg"
        color="danger"
        onClick={deleteAction}
        disabled={typedText.toLowerCase() !== 'delete customer'}
      >
        Delete
      </Button>
    </div>
  );

  return (
    <ModalContainer
      isOpen={modal}
      onClose={toggle}
      isFullHeight={false}
      title={modalTitle}
      footer={footerContent}
    >
      <>
        <p className={classNames('mb-2')}>
          This will permanently remove the customer's billing information and
          immediately cancel any current subscriptions.
        </p>
        <p className={classNames('mb-2')}>
          Past transactions or invoices associated with the customer will
          remain.
        </p>
        <p className={classNames('mb-3')}>
          Type <span className="text-danger">delete customer</span> to delete
          the profile. This action cannot be undone.
        </p>
        <Input
          className="form-control-alternative InputTextColor"
          id="input-campaign"
          placeholder="delete customer"
          type="text"
          name="emailAddress"
          defaultValue={''}
          onChange={(e) => {
            setTypedText(e.target.value);
          }}
        />
      </>
    </ModalContainer>
  );
};

export default DeleteCustomerModal;
