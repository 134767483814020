import {
  FETCH_STORES_LIST,
  FETCH_STORES_LIST_FAILURE,
  FETCH_STORES_LIST_SUCCESS,
  FETCH_STORE,
  FETCH_STORE_FAILURE,
  FETCH_STORE_SUCCESS,
  RESET,
  SET_ACTIVE_STORE,
  UPDATE_STORE,
  UPDATE_STORE_FAILURE,
  UPDATE_STORE_SUCCESS,
} from './actions';
import { STORE } from './type';

export const INITIAL_STATE: STORE.StoreReducer = {
  isLoading: 0,
  error: {} as STORE.Error,
  active: null,
  updated: false,
  storesList: null,
};

const storeReducer = (
  action: IAction,
  state = INITIAL_STATE
): STORE.StoreReducer => {
  switch (action.type) {
    case FETCH_STORES_LIST:
      return {
        ...state,
        isLoading: state.isLoading + 1,
        storesList: null,
        error: {},
      };
    case FETCH_STORES_LIST_SUCCESS:
      return {
        ...state,
        isLoading: state.isLoading - 1,
        storesList: action.payload,
        error: {},
      };
    case FETCH_STORES_LIST_FAILURE:
      return {
        ...state,
        storesList: null,
        isLoading: state.isLoading - 1,
        error: { fetchAllStores: action.payload.message },
      };
    case FETCH_STORE:
      return {
        ...state,
        isLoading: state.isLoading + 1,
        error: {},
      };
    case FETCH_STORE_SUCCESS:
      return {
        ...state,
        isLoading: state.isLoading - 1,
        active: action.payload,
        error: {},
      };
    case FETCH_STORE_FAILURE:
      return {
        ...state,
        isLoading: state.isLoading - 1,
        error: { fetchStore: action.payload.message },
      };
    case UPDATE_STORE:
      return {
        ...state,
        isLoading: state.isLoading + 1,
        updated: false,
        error: {},
      };
    case UPDATE_STORE_SUCCESS:
      return {
        ...state,
        isLoading: state.isLoading - 1,
        updated: true,
        error: {},
      };
    case UPDATE_STORE_FAILURE:
      return {
        ...state,
        isLoading: state.isLoading - 1,
        updated: false,
        error: { updateStore: action.payload },
      };
    case SET_ACTIVE_STORE:
      return {
        ...state,
        active: action.payload,
      };
    case RESET:
      return {
        ...INITIAL_STATE,
        active: state.active, // Store ID is crucial for loading data, so won't be cleared
      };
    default:
      return state;
  }
};
export default storeReducer;
