import { WarningIcon } from 'assets/img/icons/warning';
import classNames from 'classnames';
import React, { Dispatch, SetStateAction } from 'react';
import { Button } from 'reactstrap';
import styles from '../../styles.module.scss';
import ModalContainer from 'components/modals/container';

interface IProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  deleteDevice: (id: string) => void;
  deviceId: string;
  deviceName: string;
}
const DeleteDevice = ({
  setShowModal,
  showModal,
  deleteDevice,
  deviceId,
  deviceName,
}: IProps): JSX.Element => {
  return (
    <div>
      <ModalContainer
        testId="delete-device-modal"
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        header={false}
        isFullHeight={false}
        footer={
          <div>
            <Button variant="text" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button
              data-testid="deleteCTA"
              onClick={() => deleteDevice(deviceId)}
              color="danger"
            >
              Delete payment device
            </Button>
          </div>
        }
      >
        <>
          <div className={classNames(styles.confirmCloseModal)}>
            <WarningIcon viewBox="0 0 32 32" />
            <div className="ml-3">
              <h1 className="mb-2 body-semibold">Delete payment device?</h1>
              <span>
                Are you sure you want to delete this{' '}
                <span className="body-subtext-semibold"> {deviceName} </span>{' '}
                payment device?
              </span>
            </div>
          </div>
        </>
      </ModalContainer>
    </div>
  );
};

export default DeleteDevice;
