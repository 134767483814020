import { api, apiVerifyUser, changeAPIBaseInSandbox } from 'services/api';
import accessLocalStorage from 'services/localStorage';
import axios from 'axios';
import Logger from 'services/logging';
import { useStateValue } from '../..';
import { toFetchTenant } from '../../../services/auth/tenant';
import {
  action,
  actionSuccess,
  fetchUser,
  fetchUserFailure,
  fetchUserSuccess,
  setView,
  SET_MODE,
  actionFailure,
  VERIFY_OTP_CHALLENGE,
  RESET_USER_SESSION,
  FETCH_TENANT,
  LOGIN,
  LOGOUT,
  SET_SSO_LOGIN,
  SET_PERMISSION_ERROR,
} from '../actions';
import { AUTH } from '../type';
import { TREEZ_AUTH_BASE } from 'services/api/api';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useAuth = () => {
  const { state, dispatch } = useStateValue();
  const { auth } = state;


  const toFetchUser = async () => {
    dispatch(fetchUser());
    try {
      const response = await api.get<AUTH.IUser>('/profile');
      const memberships = response?.data?.roles;
      if (memberships && memberships.length > 0) {
        // setting the value in the local storage
        accessLocalStorage.setTenantId(memberships[0]?.tenant_id);
      }
      dispatch(fetchUserSuccess(response));
    } catch (e) {
      dispatch(fetchUserFailure(e));
    }
  };

  const toLogout = async () => {
    dispatch(action(LOGOUT));
    try {
      dispatch(actionSuccess(LOGOUT, {}));
      accessLocalStorage.clear();
    } catch (e) {
      dispatch(actionFailure(LOGOUT, e));
    }
  };

  const toSetView = (view: string) => {
    dispatch(setView(view));
  };
  const toPersistMode = (isSandbox: boolean) => {
    accessLocalStorage.setSandboxMode(isSandbox);
  };

  const toChangeMode = (mode: boolean) => {
    changeAPIBaseInSandbox(mode);
    dispatch(actionSuccess(SET_MODE, mode));
  };

  /**
   * Sets the given sandbox mode in global state and local storage.
   * @param {boolean} mode - sandbox mode
   */
  const toSetSandboxMode = (mode: boolean) => {
    toChangeMode(mode);
    toPersistMode(mode);
  };

  const verifyOtpChallenge = async (values: AUTH.IVerifyOTPChallenge) => {
    dispatch(action(VERIFY_OTP_CHALLENGE));
    try {
      const response = await apiVerifyUser.post(
        '/verify_challenge',
        values,
        {},
        false
      );
      dispatch(actionSuccess(VERIFY_OTP_CHALLENGE, response.data));
    } catch (e) {
      const error: any = axios.isAxiosError(e) ? e?.response : e;
      if (error?.status === 403) {
        dispatch(actionSuccess(VERIFY_OTP_CHALLENGE, error?.data));
      }
      dispatch(actionFailure(VERIFY_OTP_CHALLENGE, error));
    }
  };

  const fetchTenant = async (): Promise<void> => {
    dispatch(action(FETCH_TENANT));
    try {
      const response = await toFetchTenant();
      dispatch(actionSuccess(FETCH_TENANT, response));
    } catch (e) {
      dispatch(actionFailure(FETCH_TENANT, e));
      Logger.captureException(e as Record<string, unknown>);
    }
  };


  const resetOtp = () => {
    dispatch(action(RESET_USER_SESSION));
  };

  const toSetSSOLogin = () => {
    dispatch(action(SET_SSO_LOGIN));
  };

  const toLoginSSO = async (treez_code: string) => {
    dispatch(action(LOGIN));
    const headers = {
      'x-application': 'MSO',
      'Content-Type': 'application/json',
      code: treez_code,
    };
    const config = {
      headers: headers,
      baseURL: TREEZ_AUTH_BASE,
    };
    try {
      const response = await axios.get('/sso/tokens', config);
      const tokens = response?.data;
      if (tokens && tokens?.accessToken) {
        const user = {
          accessToken: tokens.accessToken,
          refreshToken: tokens.refreshToken,
          expiresIn: tokens?.expiresIn,
          idToken: tokens?.idToken,
        };
        dispatch(actionSuccess(LOGIN, user));
        accessLocalStorage.setUser(user);
      }
    } catch (e) {
      dispatch(actionFailure(LOGIN, e));
    }
  };

  const toSetPermissionError = (value: boolean) => {
    dispatch(action(SET_PERMISSION_ERROR, value));
  };

  return {
    auth,
    toLoginSSO,
    toFetchUser,
    toSetView,
    toPersistMode,
    toChangeMode,
    toSetSandboxMode,
    verifyOtpChallenge,
    resetOtp,
    fetchTenant,
    toLogout,
    toSetSSOLogin,
    toSetPermissionError,
  };
};

export default useAuth;
