import { apiWithOrg } from 'services/api';
import { useStateValue } from '../..';
import {
  action,
  actionFailure,
  actionSuccess,
  CREATE_TERMINAL,
  FETCH_INSTORE_CASH_DRAWER,
  FETCH_INSTORE_SESSIONS,
  FETCH_INSTORE_TERMINALS,
  reset,
  UPDATE_SESSION_TICKET,
} from '../actions';
import { TERMINAL } from '../type';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTerminal = () => {
  const { state, dispatch } = useStateValue();
  const { terminal } = state;

  // fetch details of instore terminals
  const toFetchInstoreTerminals = async (
    values: TERMINAL.IFetchInStoreTerminals
  ) => {
    dispatch(action(FETCH_INSTORE_TERMINALS));
    try {
      const response = await apiWithOrg.get<TERMINAL.IInStoreTerminals>(
        `terminals`,
        values
      );
      dispatch(actionSuccess(FETCH_INSTORE_TERMINALS, response?.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_INSTORE_TERMINALS, e));
    }
  };

  // fetch details of cash drawers
  const toFetchInstoreCashDrawers = async (
    values: TERMINAL.IFetchInStoreCashDrawers
  ) => {
    dispatch(action(FETCH_INSTORE_CASH_DRAWER));
    try {
      const response = await apiWithOrg.get<TERMINAL.IInStoreCashDrawerResult>(
        `terminals/cash_drawers`,
        values
      );
      dispatch(actionSuccess(FETCH_INSTORE_CASH_DRAWER, response?.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_INSTORE_CASH_DRAWER, e));
    }
  };

  const toFetchInstoreSessions = async (
    values: TERMINAL.IFetchInstoreSessions
  ) => {
    dispatch(action(FETCH_INSTORE_SESSIONS));
    try {
      const response = await apiWithOrg.get<
        IPaginatedResponse<TERMINAL.IInStoreSessions>
      >('in_store_sessions', values);
      dispatch(actionSuccess(FETCH_INSTORE_SESSIONS, response?.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_INSTORE_SESSIONS, e));
    }
  };

  const toUpdateSessionTicket = async (
    instoreSessionId: string,
    ticketId: string
  ) => {
    dispatch(action(UPDATE_SESSION_TICKET));
    try {
      const response = await apiWithOrg.post(
        `/in_store_sessions/${instoreSessionId}/ticket/map`,
        { order_number: ticketId }
      );
      dispatch(actionSuccess(UPDATE_SESSION_TICKET, response?.data));
    } catch (e) {
      const err = { message: 'Please enter a valid ticket ID' };
      dispatch(actionFailure(UPDATE_SESSION_TICKET, err));
    }
  };

  const toCreateTerminal = async (addTerminal: TERMINAL.ICreateTerminal) => {
    dispatch(action(CREATE_TERMINAL));
    try {
      const response = await apiWithOrg.post(`/terminals`, addTerminal);
      dispatch(actionSuccess(CREATE_TERMINAL, response?.data));
    } catch (e) {
      dispatch(actionFailure(CREATE_TERMINAL, e));
    }
  };

  const toResetTerminal = () => {
    dispatch(reset());
  };

  return {
    terminal,
    toCreateTerminal,
    toFetchInstoreTerminals,
    toFetchInstoreCashDrawers,
    toFetchInstoreSessions,
    toUpdateSessionTicket,
    toResetTerminal,
  };
};

export default useTerminal;
