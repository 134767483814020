import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, FormGroup, Input } from 'reactstrap';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import { PAYMENT_CONFIG, PaymentDeviceConfig } from 'state/paymentConfig/type';
import usePaymentConfig from 'state/paymentConfig/hooks/usePaymentConfig';
import FormLabel from 'components/form-label';
import { useForm } from 'react-hook-form';
import BootstrapTable, {
  ExpandColumnRendererProps,
} from 'react-bootstrap-table-next';
import AddPaymentDevice, { ModalType } from './modals/addPaymentDeviceModal';
import DeleteDevice from './modals/deleteDevice';
import ToastContainer from 'components/toasts/container';
import { PaymentSolutionTab } from '../../paymentConfig';
import SimpleDeviceConfig from './simpleDeviceConfig';
import FullDeviceConfig from './fullDeviceConfig';
import { initialDeviceConfig } from '../atm';

interface ICommonProps {
  defaultProvider: PAYMENT_CONFIG.IProcessors | undefined;
  activeTab: PaymentSolutionTab;
  localTab: PaymentSolutionTab;
  deviceConfig: Record<string, string>;
  setDeviceConfig: Dispatch<SetStateAction<Record<string, string>>>;
}
const DeviceConfig = ({
  defaultProvider,
  activeTab,
  localTab,
  deviceConfig,
  setDeviceConfig,
}: ICommonProps) => {
  const {
    paymentConfig,
    resetDeviceCreated,
    resetDeviceDelete,
    resetDeviceupdated,
    toGetPaymentDeviceLocation,
    toCreatePaymentDeviceLocation,
    toUpdatePaymentDeviceLocation,
    toTestPaymentDeviceLocation,
    toDeletePaymentDeviceLocation
  } = usePaymentConfig();
  const {
    paymentDeviceList,
    paymentDeviceCreated,
    paymentDeviceUpdated,
    deviceDeleted,
    paymentConfiguration,
  } = paymentConfig;

  const { register, errors } = useForm({ mode: 'onChange' });

  const [modal, setAddDeviceModal] = useState(false);

  const [devicDeleteSuccess, setDeviceDeleteSuccess] = useState(false);

  const [deviceCreateSuccess, setDeviceCreateSuccess] = useState(false);

  const [modalType, setModalType] = useState<ModalType>(ModalType.SAVE);

  const [deviceId, setDeviceId] = useState<string>();

  const [deleteDeviceName, setDeleteDeviceName] = useState<string>();

  const [deletDeviceId, setDeleteDeviceId] = useState<string>();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const editDeviceCall=(
    values:
      | PAYMENT_CONFIG.ICreatePaymentDeviceSimple
      | PAYMENT_CONFIG.ICreatePaymentDeviceFull,
    id: string
  ) => {toUpdatePaymentDeviceLocation(values, id)};

  const [deviceList, setDeviceList] = useState<
    PAYMENT_CONFIG.IGetPaymentDeviceLocation[] | []
  >([]);

  const emptyDeviceListCheck =
    paymentDeviceList && paymentDeviceList.length > 0 && activeTab === localTab;

  const configDeleteDevice = (id: string, name: string) => {
    setDeleteDeviceName(name);
    setDeleteDeviceId(id);
    setShowDeleteModal(true);
  };

  const configDeviceModal = (show: boolean, type?: ModalType) => {
    if (show && type) {
      setModalType(type);
      setAddDeviceModal(true);
    }
    if (!show) {
      setDeviceConfig(initialDeviceConfig);
      setAddDeviceModal(false);
    }
  };

  const expandRow = {
    className: 'dropdownArea',
    showExpandColumn: true,
    onlyOneExpanding: true,
    renderer: (row: PAYMENT_CONFIG.IGetPaymentDeviceLocation) => {
      return (
        <div className="w-100">
          <FormGroup className="mb-2 pl-1">
            <FormLabel
              element="device_name"
              label="Name of device"
              className={classNames(styles.fieldLabel, 'mt-2 pl-3')}
            />
            <Input
              name="device_name"
              id="device_name"
              className={classNames(styles.fieldWidth, 'mt-1 ml-3')}
              disabled
              onChange={(e) => {
                deviceConfig.deviceName = e.currentTarget.value;
                setDeviceConfig({ ...deviceConfig });
              }}
              defaultValue={row.name}
            />
          </FormGroup>
          {paymentConfiguration?.deviceConfigurationMode ===
            PaymentDeviceConfig.FULL && (
            <FullDeviceConfig
              deviceConfig={{
                registerId: row.deviceregisterid,
                printConfig: row?.printConfig,
                deviceAuthCode: row?.deviceauthcode,
                tpn: row?.tpn,
              }}
              inModal={false}
            />
          )}
          {paymentConfiguration?.deviceConfigurationMode ===
            PaymentDeviceConfig.SIMPLE && (
            <SimpleDeviceConfig
              disabled
              inModal={false}
              deviceConfig={{
                registerId: row.deviceregisterid,
                printConfig: row?.printConfig,
                deviceAuthCode: row?.deviceauthcode,
              }}
            />
          )}
          <span className="d-inline-flex ml-4">
            <Button
              data-testid="testDevice"
              color="primary"
              size="md"
              className={classNames(
                'd-inline-flex align-items-center m-0 mb-2',
                styles.button
              )}
              onClick={() => toTestPaymentDeviceLocation(row.id, row.processorName)}
            >
              Test
            </Button>
          </span>
        </div>
      );
    },
    expandColumnRenderer: ({ expanded }: ExpandColumnRendererProps) => {
      if (expanded) {
        return (
          <span className="react-bootstrap-table-sort-order dropup d-flex justify-content-center align-items-center">
            <i className="ph-caret-up-fill icon-md"></i>
          </span>
        );
      }
      return (
        <span className="react-bootstrap-table-sort-order d-flex justify-content-center align-items-center">
          <i className="ph-caret-down-fill icon-md"></i>
        </span>
      );
    },
    expandColumnPosition: 'right' as const,
  };

  const NoDataIndication = (
    <>
      <span className="d-flex align-items-center justify-content-center">
        No hardware locations to show.
        <span
          className={classNames(
            !defaultProvider ? 'text-muted' : styles.textPrimary,
            'ml-1'
          )}
          onClick={(e) =>
            !defaultProvider
              ? e.preventDefault()
              : configDeviceModal(true, ModalType.SAVE)
          }
        >
          Add a new payment device
        </span>
      </span>
    </>
  );

  const columns = [
    {
      dataField: 'name',
      text: '',
      headerAttrs: {
        hidden: true,
      },
    },
    {
      text: 'Actions',
      dataField: 'id',
      style: { width: 0 },
      headerAttrs: {
        hidden: true,
      },
      sort: true,
      formatter: (
        cell: string,
        row: PAYMENT_CONFIG.IGetPaymentDeviceLocation
      ) => {
        return (
          <>
            <Button
              type="button"
              color="link"
              size="sm"
              data-testid="edit_device"
              onClick={() => {
                deviceConfig.registerId = row?.deviceregisterid;
                deviceConfig.deviceName = row?.name;
                deviceConfig.printConfig = row?.printConfig;
                deviceConfig.deviceAuthCode = row?.deviceauthcode;
                deviceConfig.tpn = row?.tpn;
                setDeviceConfig(deviceConfig);
                setDeviceId(row?.id);
                configDeviceModal(true, ModalType.EDIT);
              }}
            >
              <i className="ph-pencil icon-lg text-muted" />
            </Button>
            <Button
              type="button"
              color="link"
              size="sm"
              data-testid="delete_device"
              onClick={() => {
                configDeleteDevice(row?.id, row?.name);
              }}
            >
              <i className="ph-trash icon-lg text-muted" />
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (paymentDeviceCreated && activeTab === localTab) {
      resetDeviceCreated();
      setAddDeviceModal(false);
      setModalType(ModalType.SAVE);
      setDeviceCreateSuccess(true);
      if (defaultProvider?.processorName ) {
        toGetPaymentDeviceLocation(defaultProvider.processorName);
      }
    }
    resetDeviceCreated();
  }, [paymentDeviceCreated]);

  useEffect(() => {
    if (paymentDeviceUpdated && activeTab === localTab) {
      setAddDeviceModal(false);
      setDeviceId(undefined);
      setModalType(ModalType.EDIT);
      setDeviceCreateSuccess(true);
      deviceConfig.registerId = '';
      setDeviceConfig(deviceConfig);
      if (defaultProvider?.processorName ) {
        toGetPaymentDeviceLocation(defaultProvider.processorName);
      }
    }
    resetDeviceupdated();
  }, [paymentDeviceUpdated]);

  useEffect(() => {
    if (deviceDeleted && activeTab === localTab) {
      resetDeviceDelete();
      setDeviceDeleteSuccess(true);
      setDeleteDeviceId(undefined);
      setDeleteDeviceName(undefined);
      setShowDeleteModal(false);
      if (defaultProvider?.processorName ) {
        toGetPaymentDeviceLocation(defaultProvider.processorName);
      }
    }
    resetDeviceDelete();
  }, [deviceDeleted]);

  useEffect(() => {
    if (!modal) {
      setDeviceConfig({ ...initialDeviceConfig });
    }
  }, [modal]);

  useEffect(() => {
    if (emptyDeviceListCheck) {
      setDeviceList(paymentDeviceList);
    } else {
      setDeviceList([]);
    }
  }, [paymentDeviceList]);

  return (
    <>
      {paymentConfiguration?.deviceConfigurationMode !== 'none' && (
        <>
          <div
            className={classNames(
              'd-flex justify-content-between mt-6',
              styles.width
            )}
            data-testid="payment_device"
          >
            <span className={styles.heading}>
              Payment device configurations
            </span>
            <span>
              <Button
                variant="secondary"
                onClick={() => configDeviceModal(true, ModalType.SAVE)}
                className={classNames(
                  'd-inline-flex btn-text align-items-center m-0 mb-2',
                  styles.addPaymentDevice
                )}
                disabled={!defaultProvider}
              >
                <i className="ph-plus-bold icon-lg mr-1 pl-1 d-flex align-items-center justify-items-center"></i>
                Add a new payment device
              </Button>
            </span>
          </div>
          <BootstrapTable
            wrapperClasses={classNames(styles.tableBorderless)}
            keyField="id"
            data={deviceList}
            headerClasses="hideHeader"
            expandRow={expandRow}
            columns={columns}
            hover={false}
            bootstrap4
            bordered={false}
            noDataIndication={NoDataIndication}
            // id={testId}
          />
          {modal && paymentConfiguration?.deviceConfigurationMode && (
            <AddPaymentDevice
              type={modalType}
              modal={modal}
              setModal={configDeviceModal}
              submitCall={(
                device:
                  | PAYMENT_CONFIG.ICreatePaymentDeviceSimple
                  | PAYMENT_CONFIG.ICreatePaymentDeviceFull,
                id?: string
              ) =>
                modalType === ModalType.SAVE
                  ? toCreatePaymentDeviceLocation(device)
                  : editDeviceCall(device, id || '')
              }
              deviceId={deviceId}
              processorId={defaultProvider?.processorId || ''}
              deviceConfigType={paymentConfiguration?.deviceConfigurationMode}
              deviceConfig={deviceConfig}
              setDeviceConfig={setDeviceConfig}
            />
          )}

          {showDeleteModal && (
            <DeleteDevice
              deleteDevice={(id: string) => toDeletePaymentDeviceLocation(id)}
              showModal={showDeleteModal}
              setShowModal={setShowDeleteModal}
              deviceId={deletDeviceId ?? ''}
              deviceName={deleteDeviceName ?? ''}
            />
          )}
          {deviceCreateSuccess && (
            <ToastContainer
              id={modalType}
              showToast={deviceCreateSuccess}
              onClose={() => setDeviceCreateSuccess(false)}
              message={
                modalType === ModalType.SAVE
                  ? 'Payment device added successfully'
                  : 'Payment device updated successfully'
              }
              showCross
            />
          )}
          {devicDeleteSuccess && (
            <ToastContainer
              id="deleteDevice"
              showToast={devicDeleteSuccess}
              onClose={() => setDeviceDeleteSuccess(false)}
              message={'Device deleted successfully'}
              showCross
            />
          )}
        </>
      )}
    </>
  );
};

export default DeviceConfig;
