import React, { useState } from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import LocalSpinner from 'components/spinners/local';
import useWebhooks from 'state/webhooks/hooks/useWebhooks';
import styles from '../webhooks.module.scss';

const RevealSecret = () => {
  const [showHMAC, setShowHMAC] = useState(false);
  const { toRevealWebhookSecret, webhooks } = useWebhooks();
  const {
    webhookDetails: { id: webhookId, signing_secret },
  } = webhooks;

  const toRevealSecret = () => {
    setShowHMAC(!showHMAC);
    toRevealWebhookSecret(webhookId);
  };

  return !showHMAC ? (
    <Button
      onClick={toRevealSecret}
      className={classNames('btn-link', styles.revealBtn)}
    >
      Reveal
    </Button>
  ) : signing_secret ? (
    <div
      data-testid="webhook-secret"
      className={classNames('SettlementHeadingData')}
    >
      {signing_secret}
    </div>
  ) : (
    <div className="d-inline-flex align-items-center">
      <LocalSpinner size="sm" color="gray" isLoading />
      <Button className={classNames('btn-link ml-2', styles.revealBtn)}>
        Reveal
      </Button>
    </div>
  );
};

export default RevealSecret;
