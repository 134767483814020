import { api, apiOrgLive } from 'services/api';
import { useStateValue } from '../..';
import {
  action,
  actionFailure,
  actionSuccess,
  FETCH_STORES_LIST,
  reset,
  UPDATE_STORE,
} from '../actions';
import { STORE } from '../type';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useStore = () => {
  const { state, dispatch } = useStateValue();
  const { store } = state;

  const toFetchStoresList = async () => {
    dispatch(action(FETCH_STORES_LIST));
    try {
      const response = await api.get<{ stores: STORE.IStore[] }>(
        `/organizations/stores`
      );
      dispatch(actionSuccess(FETCH_STORES_LIST, response?.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_STORES_LIST, e));
    }
  };

  const toUpdateStore = async (
    storeId: string,
    values: STORE.IStoreRequest
  ) => {
    dispatch(action(UPDATE_STORE));
    try {
      const response = await apiOrgLive.put(`/stores/${storeId}`, values);
      if (
        response.status === 400 &&
        (response.data as Record<string, unknown>).error_code ===
          'subdomain_not_available'
      ) {
        dispatch(actionFailure(UPDATE_STORE, response.data));
        return;
      }
      dispatch(actionSuccess(UPDATE_STORE, response?.data));
    } catch (e) {
      dispatch(actionFailure(UPDATE_STORE, e));
    }
  };

  const toResetStore = () => {
    dispatch(reset());
  };

  return {
    store,
    toUpdateStore,
    toResetStore,
    toFetchStoresList,
  };
};

export default useStore;
