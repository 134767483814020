import classNames from 'classnames';
import FormLabel from 'components/form-label';
import PageHeading from 'components/heading';
import ModalContainer from 'components/modals/container/index';
import Pagination from 'components/pagination';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import { ToolkitContextType } from 'react-bootstrap-table2-toolkit';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
} from 'reactstrap';
import useAuth from 'state/auth/hooks/useAuth';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import useWebhooks from 'state/webhooks/hooks/useWebhooks';
import { WEBHOOKS } from 'state/webhooks/type';
import { formatToTz } from 'utils/date-format';
import { webhooksStatusFormatter } from 'utils/status-formatter';
import { URL_VALIDATION } from 'utils/validation';
import styles from './webhooks.module.scss';

const Webhook = (): JSX.Element => {
  const { register, errors, handleSubmit, control, clearErrors } = useForm();

  const { organisation } = useOrganisation();
  const { activeOrg, settings } = organisation;

  const sessionEvents = [
    { label: 'session.created', value: 'session.created' },
    {
      label: 'session.consumer_connected',
      value: 'session.consumer_connected',
    },
    {
      label: 'session.fi_account_connected',
      value: 'session.fi_account_connected',
    },
    { label: 'session.authorized', value: 'session.authorized' },
    {
      label: 'session.authorize_failed',
      value: 'session.authorize_failed',
    },
    { label: 'session.finalized', value: 'session.finalized' },
  ];
  const orderEvents = [
    { label: 'order.created', value: 'order.created' },
    { label: 'order.charged', value: 'order.charged' },
    { label: 'order.total_finalized', value: 'order.total_finalized' },
    { label: 'order.canceled', value: 'order.canceled' },
  ];

  const chargeEvents = [
    { label: 'charge.created', value: 'charge.created' },
    { label: 'charge.succeeded', value: 'charge.succeeded' },
    { label: 'charge.failed', value: 'charge.failed' },
  ];

  const refundEvents = [
    { label: 'refund.created', value: 'refund.created' },
    { label: 'refund.succeeded', value: 'refund.succeeded' },
    { label: 'refund.failed', value: 'refund.failed' },
  ];

  const [selectedOption, setSelectedOption] = useState<string>();

  const setEvent = (event: string) => {
    clearErrors('event');
    setSelectedOption(event);
  };

  const { webhooks, toFetchWebhooks, toCreateWebhook } = useWebhooks();
  const { webhooksList, createWebhook } = webhooks;

  const { auth } = useAuth();
  const { isSandbox } = auth;

  const [show, setShow] = useState<boolean>(false);

  const [prevKey, setPrevKey] = useState<string | null>(null);
  const [nextKey, setNextKey] = useState<string | null>(null);

  // Number of results
  const [numResults, setNumResults] = useState(0);
  const navigate = useNavigate();

  const [endpointUrl, setEndpoint] = useState<string | null>();

  const [descriptionVal, setDescription] = useState<string>();

  // get to prev page
  const prevPage = () => {
    toFetchWebhooks(prevKey);
  };

  // get to next page
  const nextPage = () => {
    toFetchWebhooks(nextKey);
  };

  useEffect(() => {
    if (webhooksList?.items) {
      setNextKey(webhooksList.next_key);
      setPrevKey(webhooksList.prev_key);
      setNumResults(webhooksList.total_count);
    }
  }, [webhooks]);

  useEffect(() => {
    toFetchWebhooks();
  }, [createWebhook]);

  // adding a new key
  const onCreateKey = () => {
    setShow(true);
  };

  const [dropdownOpen, setDropdown] = useState<boolean>(false);
  // Taking actions on row events
  const rowEvents = {
    onClick: (
      e: SyntheticEvent<Element, Event>,
      row: WEBHOOKS.IWebhooksItems
    ) => {
      navigate(row.id);
    },
  };

  const TABLE_COLUMNS = [
    {
      text: 'Created date',
      sort: true,
      dataField: 'create_date',
      formatter: (cell: string) => {
        return (
          <>
            {cell
              ? formatToTz(cell, settings?.timezone, 'MMM dd yyyy, hh:mm a')
              : ''}
          </>
        );
      },
    },
    {
      text: 'URL',
      sort: false,
      dataField: 'endpoint',
    },

    {
      text: 'Event',
      sort: false,
      dataField: 'event',
    },
    {
      text: 'Status',
      sort: true,
      dataField: 'status',
      formatter: webhooksStatusFormatter,
    },
  ];
  const heading = 'Webhooks';
  const subHeading = 'View webhooks and endpoints here';
  const formSubmit = () => {
    if (endpointUrl && selectedOption && activeOrg) {
      toCreateWebhook({
        organization_id: activeOrg.id,
        endpoint: endpointUrl,
        event: selectedOption,
        description: descriptionVal || '',
      });
      setShow(false);
    }
  };

  return (
    <>
      <Card className="card-profile border-0">
        <PageHeading
          heading={heading}
          subHeading={subHeading}
          className="mb-2"
        />
        <div className="d-flex mb-2">
          <span className="ml-auto">
            <Button
              color="primary"
              size="sm"
              onClick={onCreateKey}
              className={classNames(styles.addKeyBtn)}
            >
              <i
                className={classNames(
                  styles.addKeyBtn,
                  'ph-plus-fill icon-lg pr-1'
                )}
              />
              Add {isSandbox ? 'test' : ''} endpoint
            </Button>
          </span>
        </div>
        <ToolkitProvider
          keyField="_id"
          search
          data={webhooksList?.items || []}
          columns={TABLE_COLUMNS}
        >
          {(props: ToolkitContextType) => (
            <div>
              <BootstrapTable
                wrapperClasses="table-responsive"
                {...props.baseProps}
                hover
                condensed
                rowEvents={rowEvents}
                bordered={false}
                noDataIndication={
                  <p className="text-center text-regular">No results found</p>
                }
              />
            </div>
          )}
        </ToolkitProvider>
        <Pagination
          results={numResults}
          prevPage={prevPage}
          nextPage={nextPage}
          prevKey={prevKey}
          nextKey={nextKey}
        />
      </Card>
      <ModalContainer
        isOpen={show}
        onClose={() => setShow(false)}
        isFullHeight={false}
        title="Add endpoint"
        footer={
          <div>
            <Button variant="text" onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button
              color="primary"
              className="btn btn-primary"
              onClick={handleSubmit(formSubmit)}
            >
              Add endpoint
            </Button>
          </div>
        }
      >
        <>
          <div className={classNames(styles.modal)}>
            <FormGroup>
              <FormLabel
                check
                element="endpoint"
                label="Endpoint URL"
              ></FormLabel>
              <Input
                name="endpoint"
                placeholder="Eg: https://vtbvohngx1.execute-api.ap-south-1.amazonaws.com...."
                innerRef={register({
                  pattern: URL_VALIDATION,
                  validate: {
                    required: (value) => {
                      return value.length <= 0
                        ? 'Please enter a valid endpoint'
                        : true;
                    },
                  },
                })}
                onChange={(e) => {
                  setEndpoint(e.target.value);
                }}
                className={errors.endpoint ? '' : 'mb-3'}
              />
              {errors.endpoint && (
                <p className="text-sm text-danger mb-3">
                  Please enter valid endpoint
                </p>
              )}

              <FormLabel
                check
                element="event"
                label="Select events to listen"
              ></FormLabel>
              <Controller
                name="event"
                rules={{ required: selectedOption === undefined }}
                control={control}
                render={() => (
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={() => setDropdown(!dropdownOpen)}
                    className={classNames(
                      styles.dropdownContainer,
                      errors.event ? '' : 'mb-3'
                    )}
                  >
                    <DropdownToggle className={classNames(styles.toggleBtn)}>
                      <span
                        className={
                          selectedOption === undefined
                            ? classNames(styles.dropdownText)
                            : classNames(styles.dropdownTextSelected)
                        }
                      >
                        {selectedOption || 'Select Event'}
                      </span>
                      <i className="ph-caret-down icon-md"></i>
                    </DropdownToggle>
                    <DropdownMenu className={classNames(styles.dropdownArea)}>
                      <DropdownItem
                        header
                        className={classNames(styles.dropdownHeader)}
                      >
                        Session
                      </DropdownItem>
                      {sessionEvents.map((item) => (
                        <DropdownItem
                          key={item.label}
                          className={classNames(styles.dropdownItem)}
                          onClick={() => setEvent(item.value)}
                        >
                          {item.label}
                        </DropdownItem>
                      ))}
                      <DropdownItem
                        header
                        className={classNames(styles.dropdownHeader)}
                      >
                        Order
                      </DropdownItem>
                      {orderEvents.map((item) => (
                        <DropdownItem
                          key={item.label}
                          className={classNames(styles.dropdownItem)}
                          onClick={() => setEvent(item.value)}
                        >
                          {item.label}
                        </DropdownItem>
                      ))}
                      <DropdownItem
                        header
                        className={classNames(styles.dropdownHeader)}
                      >
                        Charge
                      </DropdownItem>
                      {chargeEvents.map((item) => (
                        <DropdownItem
                          key={item.label}
                          className={classNames(styles.dropdownItem)}
                          onClick={() => setEvent(item.value)}
                        >
                          {item.label}
                        </DropdownItem>
                      ))}
                      <DropdownItem
                        header
                        className={classNames(styles.dropdownHeader)}
                      >
                        Refund
                      </DropdownItem>
                      {refundEvents.map((item) => (
                        <DropdownItem
                          key={item.label}
                          className={classNames(styles.dropdownItem)}
                          onClick={() => setEvent(item.value)}
                        >
                          {item.label}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                )}
              />
              {errors.event && (
                <p className="text-sm text-danger">Please select an event</p>
              )}
              <br />
              <FormLabel
                check
                element="description"
                label="Description"
              ></FormLabel>
              <Input
                name="description"
                type="textarea"
                placeholder="Add description..."
                onChange={(e) => setDescription(e.target.value)}
                className={classNames(styles.description)}
              />
            </FormGroup>
          </div>
        </>
      </ModalContainer>
    </>
  );
};

export default Webhook;
