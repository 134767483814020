import {
  ACT_INVITE,
  ACT_INVITE_FAILURE,
  ACT_INVITE_SUCCESS,
  ADD_USER,
  ADD_USER_FAILURE,
  ADD_USER_SUCCESS,
  DELETE_INVITE,
  DELETE_INVITE_FAILURE,
  DELETE_INVITE_SUCCESS,
  FETCH_INVITES,
  FETCH_INVITES_FAILURE,
  FETCH_INVITES_SUCCESS,
  FETCH_ACTIVE_ORG,
  FETCH_ACTIVE_ORG_FAILURE,
  FETCH_ACTIVE_ORG_SUCCESS,
  FETCH_ROLES,
  FETCH_ROLES_FAILURE,
  FETCH_ROLES_SUCCESS,
  FETCH_USERS,
  FETCH_USERS_FAILURE,
  FETCH_USERS_SUCCESS,
  FETCH_USER_INVITES,
  FETCH_USER_INVITES_FAILURE,
  FETCH_USER_INVITES_SUCCESS,
  REMOVE_ROLE,
  REMOVE_ROLE_FAILURE,
  REMOVE_ROLE_SUCCESS,
  RESET,
  SET_ACTIVE_ORG,
  UPDATE_ORG_SETTINGS,
  UPDATE_ORG_SETTINGS_FAILURE,
  UPDATE_ORG_SETTINGS_SUCCESS,
  UPDATE_ROLE,
  UPDATE_ROLE_FAILURE,
  UPDATE_ROLE_SUCCESS,
  RESET_ACTIVE_ORG,
  getOrgStatus,
  FETCH_ORG_ROLE_ID,
  FETCH_ORG_ROLE_ID_SUCCESS,
  FETCH_ORG_ROLE_ID_FAILURE,
} from './actions';
import { ORGANISATION } from './type';

export const INITIAL_STATE: ORGANISATION.OrganisationReducer = {
  isLoading: false,
  error: {} as ORGANISATION.Error,
  user: {} as ORGANISATION.IRole,
  users: [] as Array<ORGANISATION.IRoleItem>,
  invites: [] as Array<ORGANISATION.IInvite>,
  userInvites: [] as Array<ORGANISATION.IInvite>,
  internalUser: {} as ORGANISATION.IInternalUser,
  activeOrg: null,
  activeOrgId: null,
  addedUser: false,
  editedUser: false,
  removedUser: false,
  removedInvite: false,
  actedInvite: false,
  settings: null,
  orgRoleList: {} as IPaginatedResponse<ORGANISATION.IOrgRole>,
};

const organisationReducer = (
  action: IAction,
  state = INITIAL_STATE
): ORGANISATION.OrganisationReducer => {
  switch (action.type) {
    case FETCH_USERS:
      return {
        ...state,
        isLoading: true,
        error: {},
        users: [],
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: {},
        users: action.payload,
      };
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        users: [],
        error: { fetchUsers: action.payload },
      };
    case FETCH_INVITES:
      return {
        ...state,
        isLoading: true,
        error: {},
        invites: [],
      };
    case FETCH_INVITES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: {},
        invites: action.payload,
      };
    case FETCH_INVITES_FAILURE:
      return {
        ...state,
        isLoading: false,
        invites: [],
        error: { fetchInvites: action.payload },
      };
    case FETCH_USER_INVITES:
      return {
        ...state,
        isLoading: true,
        error: {},
        userInvites: [],
      };
    case FETCH_USER_INVITES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: {},
        userInvites: action.payload,
      };
    case FETCH_USER_INVITES_FAILURE:
      return {
        ...state,
        isLoading: false,
        userInvites: [],
        error: { fetchUserInvites: action.payload },
      };
    case DELETE_INVITE:
      return {
        ...state,
        isLoading: true,
        error: {},
        removedInvite: false,
      };
    case DELETE_INVITE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: {},
        removedInvite: true,
      };
    case DELETE_INVITE_FAILURE:
      return {
        ...state,
        isLoading: false,
        removedInvite: false,
        error: { removedInvite: action.payload },
      };
    case ACT_INVITE:
      return {
        ...state,
        isLoading: true,
        error: {},
        actedInvite: false,
      };
    case ACT_INVITE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: {},
        actedInvite: true,
      };
    case ACT_INVITE_FAILURE:
      return {
        ...state,
        isLoading: false,
        actedInvite: false,
        error: { actedInvite: action.payload },
      };
    case ADD_USER:
      return {
        ...state,
        isLoading: true,
        addedUser: false,
        error: {},
        user: {} as ORGANISATION.IRole,
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addedUser: true,
        error: {},
        user: action.payload,
      };
    case ADD_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        addedUser: false,
        user: {} as ORGANISATION.IRole,
        error: { addUser: action.payload },
      };
    case UPDATE_ROLE:
      return {
        ...state,
        isLoading: true,
        editedUser: false,
        error: {},
        user: {} as ORGANISATION.IRole,
      };
    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        editedUser: true,
        error: {},
        user: action.payload,
      };
    case UPDATE_ROLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        editedUser: false,
        user: {} as ORGANISATION.IRole,
        error: { editUser: action.payload },
      };
    case REMOVE_ROLE:
      return {
        ...state,
        isLoading: true,
        removedUser: false,
        error: {},
      };
    case REMOVE_ROLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        removedUser: true,
        error: {},
      };
    case REMOVE_ROLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        removedUser: false,
        error: { removeUser: action.payload },
      };
    case FETCH_ROLES:
      return {
        ...state,
        isLoading: true,
        users: [],
        error: {},
      };
    case FETCH_ROLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: {},
        users: action.payload,
      };
    case FETCH_ROLES_FAILURE:
      return {
        ...state,
        isLoading: false,
        users: [],
        error: { fetchUsers: action.payload.message },
      };
    case FETCH_ACTIVE_ORG:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case FETCH_ACTIVE_ORG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: {},
        activeOrg: {
          ...action.payload,
          status: getOrgStatus(
            action.payload.is_kyb_collected,
            action.payload.is_payment_enabled
          ),
        },
        activeOrgId: action.payload?.id,
        settings: action.payload?.settings,
      };
    case FETCH_ACTIVE_ORG_FAILURE:
      return {
        ...state,
        isLoading: false,
        settings: null,
        activeOrg: null,
        error: { fetchOrgs: action.payload.message },
      };
    case SET_ACTIVE_ORG:
      return {
        ...state,
        activeOrgId: action.payload,
      };
    case UPDATE_ORG_SETTINGS:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case UPDATE_ORG_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        settings: action.payload,
        error: {},
      };
    case UPDATE_ORG_SETTINGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { updatePaymentSettings: action.payload.message },
      };
    case RESET_ACTIVE_ORG:
      return {
        ...state,
        activeOrg: null,
        activeOrgId: null,
        settings: null,
        // Reset activeOrg for app-wide state refreshes (sandbox mode change)
      };

    case FETCH_ORG_ROLE_ID:
      return {
        ...state,
        error: {},
        isLoading: true,
      };
    case FETCH_ORG_ROLE_ID_SUCCESS:
      return {
        ...state,
        error: {},
        orgRoleList: action.payload,
        isLoading: false,
      };
    case FETCH_ORG_ROLE_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { fetchOrgRoles: action.payload.message },
      };
    case RESET:
      return {
        ...INITIAL_STATE,
        activeOrg: {
          ...action.payload,
          status: getOrgStatus(
            action.payload.business_type,
            action.payload.is_kyb_collected
          ),
        },
        activeOrgId: state.activeOrgId, // Org ID is crucial for loading data, so won't be cleared
      };
    default:
      return state;
  }
};
export default organisationReducer;
