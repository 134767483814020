export const FETCH_EVENTS_SESSION = 'payments/FETCH_EVENTS_SESSION';
export const FETCH_EVENTS_SESSION_SUCCESS =
  'payments/FETCH_EVENTS_SESSION_SUCCESS';
export const FETCH_EVENTS_SESSION_FAILURE =
  'payments/FETCH_EVENTS_SESSION_FAILURE';

export const FETCH_EVENTS = 'payments/FETCH_EVENTS';
export const FETCH_EVENTS_SUCCESS = 'payments/FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAILURE = 'payments/FETCH_EVENTS_FAILURE';
export const FETCH_INDIVIDUAL_EVENTS = 'payments/FETCH_INDIVIDUAL_EVENTS';
export const FETCH_INDIVIDUAL_EVENTS_SUCCESS =
  'payments/FETCH_INDIVIDUAL_EVENTS_SUCCESS';
export const FETCH_INDIVIDUAL_EVENTS_FAILURE =
  'payments/FETCH_INDIVIDUAL_EVENTS_FAILURE';

export const CLEAR_EXPORT_ERROR = 'payments/CLEAR_EXPORT_ERROR';

export const action = (type: string): IAction => ({
  type,
});

export const actionSuccess = <T>(type: string, payload: T): IAction => ({
  type: `${type}_SUCCESS`,
  payload,
});

export const actionFailure = <T>(type: string, payload: T): IAction => ({
  type: `${type}_FAILURE`,
  payload,
});
