import React from 'react';
import classNames from 'classnames';
import { BANKING } from 'state/banking/type';
import { extractDigitValues, maskedData } from 'utils/pii';
import styles from '../../banking/styles.module.scss';

interface props {
  info: BANKING.IPayout;
}

const AccountDetails = ({
  info = {} as BANKING.IPayout,
}: props): JSX.Element => {
  return (
    <>
      <div className="mb-9">
        <h3 className="mb-2 PaymentDetailsHeading">Account details</h3>
        <hr className="mt-0 mb-10" />
        <div className={classNames(styles.SummaryInfo, 'p-0 mb-2')}>
          <span className={classNames(styles.SummaryHeading)}>Method</span>
          <span className={classNames(styles.HeadingData)}>
            {info?.payment_method || 'NA'}
          </span>
        </div>
        <div className={classNames(styles.SummaryInfo, 'p-0 mb-2')}>
          <span className={classNames(styles.SummaryHeading)}>
            Beneficiary bank account
          </span>
          <span
            className={classNames(styles.HeadingData)}
            data-testid="payout_masked_account_number"
          >
            {maskedData(
              extractDigitValues(info?.masked_account_number) || 'NA'
            )}
          </span>
        </div>
        <div className={classNames(styles.SummaryInfo, 'p-0')}>
          <span className={classNames(styles.SummaryHeading)}>Bank name</span>
          <span
            className={classNames(styles.HeadingData)}
            data-testid="payout_bank_name"
          >
            {info?.institution_name || 'NA'}
          </span>
        </div>
      </div>
    </>
  );
};

export default AccountDetails;
