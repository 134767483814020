import {
  GET_FORM_DETAILS,
  GET_FORM_DETAILS_SUCCESS,
  GET_FORM_DETAILS_FAILURE,
  SET_FORM_VIEW,
  SET_BO_FORM_VIEW,
  SET_BO_LIST,
  RESET,
  SUBMIT_FORM,
  SUBMIT_FORM_SUCCESS,
  SUBMIT_FORM_FAILURE,
  SUBMIT_EXTERNAL_FORM,
  SUBMIT_EXTERNAL_FORM_SUCCESS,
  SUBMIT_EXTERNAL_FORM_FAILURE,
  AUTO_SAVE_SUCCESS,
  AUTO_SAVE,
  AUTO_SAVE_RESET,
  SET_EXTERNAL_BO_TOKEN,
  FETCH_EXTERNAL_FORM,
  FETCH_EXTERNAL_FORM_SUCCESS,
  FETCH_EXTERNAL_FORM_FAILURE,
  RESET_SUBMIT_STAGE,
  RESET_EXT_SUBMIT_STAGE,
  RESET_IS_SUBMITTED,
  REFRESH_FORM,
  REFRESH_FORM_SUCCESS,
} from './actions';
// skipcq: JS-E1010
import { AUTO_SAVE_STATES, BO_STAGE, FORMS } from './config';
import { ACTIVATION } from './type';

export const INITIAL_STATE: ACTIVATION.IActivationReducer = {
  getFormDetails: false,
  formDetails: {
    [FORMS.BUSINESS_INFORMATION]: null,
    [FORMS.CORPORATE_INFORMATION]: null,
    [FORMS.LICENSE_INFORMATION]: null,
    [FORMS.BANK_INFORMATION]: null,
    [FORMS.SALES_INFORMATION]: null,
    [FORMS.BUSINESS_OWNERS]: null,
    [FORMS.CONFIRMATION]: null,
  } as ACTIVATION.formDetails,
  isSubmitted: false,
  isLoading: false,
  isAutoSaving: null,
  error: {} as ACTIVATION.Error,
  activeForm: FORMS.GET_STARTED,
  activeBOForm: BO_STAGE.LIST_OWNERS,
  listBusinessOwners: [],
  businessOwnerExternal: {} as ACTIVATION.IBusinessOwnerExternal,
  extToken: null,
  extTokenPayload: {} as ACTIVATION.IExBOTokenPayload,
  stage_submit: false,
  ext_stage_submit: false,
};

const activationReducer = (
  action: IAction,
  state = INITIAL_STATE
): ACTIVATION.IActivationReducer => {
  switch (action.type) {
    case SET_FORM_VIEW:
      return {
        ...state,
        activeForm: action.payload,
      };

    case SET_BO_FORM_VIEW:
      return { ...state, activeBOForm: action.payload };

    case SET_BO_LIST:
      return { ...state, listBusinessOwners: action.payload };

    case GET_FORM_DETAILS:
      return {
        ...state,
        isLoading: true,
        getFormDetails: false,
        error: {},
      };

    case GET_FORM_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        formDetails: action.payload
          ? action.payload
          : {
              [FORMS.BUSINESS_INFORMATION]: null,
              [FORMS.CORPORATE_INFORMATION]: null,
              [FORMS.LICENSE_INFORMATION]: null,
              [FORMS.BANK_INFORMATION]: null,
              [FORMS.SALES_INFORMATION]: null,
              [FORMS.BUSINESS_OWNERS]: null,
              [FORMS.CONFIRMATION]: null,
            },
        getFormDetails: true,
        // activeForm:
        //   (localStorage.getItem('stage') as FORMS) ||
        //   (action.payload?.stage as FORMS),
        error: {},
      };

    case GET_FORM_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { getFormDetails: action.payload },
        getFormDetails: false,
      };

    case SUBMIT_FORM:
      return { ...state, isLoading: true, error: {}, stage_submit: false };

    case SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: {},
        formDetails: action.payload,
        isSubmitted:
          action.payload?.stage === FORMS.CONFIRMATION
            ? true
            : state.isSubmitted,
        stage_submit: true,
      };

    case SUBMIT_FORM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { [action.payload.stage]: action.payload.errorMessage },
        isSubmitted:
          action.payload.stage === FORMS.CONFIRMATION
            ? false
            : state.isSubmitted,
        stage_submit: false,
      };

    case REFRESH_FORM:
      return {
        ...state,
        isLoading: true,
        error: {},
      };

    case REFRESH_FORM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        formDetails: action.payload ? action.payload : state?.formDetails,
        error: {},
      };

    case SUBMIT_EXTERNAL_FORM:
      return { ...state, isLoading: true, error: {}, ext_stage_submit: false };

    case SUBMIT_EXTERNAL_FORM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: {},
        businessOwnerExternal: action.payload,
        ext_stage_submit: true,
      };

    case SUBMIT_EXTERNAL_FORM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { externalOwner: action.payload },
        isSubmitted:
          action.payload.stage === FORMS.CONFIRMATION
            ? false
            : state.isSubmitted,
        ext_stage_submit: false,
      };

    case FETCH_EXTERNAL_FORM:
      return { ...state, isLoading: true, error: {} };

    case FETCH_EXTERNAL_FORM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: {},
        businessOwnerExternal: action.payload,
      };

    case FETCH_EXTERNAL_FORM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { fetchExtOwnerForm: action.payload },
      };

    case SET_EXTERNAL_BO_TOKEN:
      return {
        ...state,
        extToken: action.payload.token,
        extTokenPayload: action.payload.payload,
      };

    case AUTO_SAVE:
      return {
        ...state,
        isAutoSaving: AUTO_SAVE_STATES.SAVING,
      };

    case AUTO_SAVE_SUCCESS:
      return {
        ...state,
        isAutoSaving: AUTO_SAVE_STATES.SAVED,
      };

    case AUTO_SAVE_RESET:
      return { ...state, isAutoSaving: null };

    case RESET:
      return {
        ...state,
        getFormDetails: false,
      };
    case RESET_SUBMIT_STAGE:
      return {
        ...state,
        stage_submit: false,
      };
    case RESET_EXT_SUBMIT_STAGE:
      return {
        ...state,
        ext_stage_submit: false,
      };
    case RESET_IS_SUBMITTED:
      return {
        ...state,
        isSubmitted: false,
      };

    default:
      return state;
  }
};

export default activationReducer;
