import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { NavLink as NavLinkRRD, useLocation } from 'react-router-dom';
import { Collapse, Container, Nav, NavItem, NavLink, Navbar } from 'reactstrap';
import routeConfig from 'routes/config';
import views, { IView } from 'views';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import ExpandableNavitemSidebar from './expandable-navitem';
import NavitemSidebar from './navitem';
import styles from './sidebar.module.scss';
import TreezLogo from 'assets/img/store/logo.svg';
import { treezDashboardUrl } from 'utils/urlGenerator';
import { ENV } from 'utils/config';



const Sidebar = () => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const { organisation } = useOrganisation();
  const { activeOrg } = organisation;
  const location = useLocation();
  const activeRoute = (routeName: string) => {
    return location && location.pathname.indexOf(routeName) > -1;
  };

  const env = ENV || 'production';
  

  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes: IView[], layout: string) => {
    return routes
      .filter((route) => route.hasSidebar && route.layout === layout)
      .map((prop) => {
        return (
          <>
            {prop.children && prop.children.length > 0 ? (
              <ExpandableNavitemSidebar
                open={activeRoute(layout) || false}
                options={prop}
                closeCollapse={closeCollapse}
                routes={routes}
                layout={layout}
              />
            ) : (
              <NavitemSidebar
                options={prop}
                closeCollapse={closeCollapse}
              ></NavitemSidebar>
            )}
          </>
        );
      });
  };

  const DispensaryNavLinks = () => (
    <>
      <Nav navbar>
        {createLinks(views.dispensary, routeConfig.ANALYTICS.layout)}
      </Nav>
      {createLinks(views.dispensary, routeConfig.PAYMENTS.layout)}
      <h6
        className={classNames(
          'footnote-bold-caps',
          styles.nav_subheading,
          styles.treezpayAchHeading
        )}
      >
        TreezPay ACH
      </h6>
      <Nav navbar>
        {createLinks(views.dispensary, routeConfig.PAYMENT_LINKS.layout)}
      </Nav>
      {createLinks(views.dispensary, routeConfig.BANKING.layout)}

      <Nav navbar>
        {createLinks(views.dispensary, routeConfig.CUSTOMERS.layout)}
      </Nav>
      <h6
        className={classNames(
          'footnote-bold-caps',
          styles.nav_subheading,
          styles.accountHeading
        )}
      >
        Account
      </h6>
      {createLinks(views.dispensary, routeConfig.SETTINGS.layout)}
      {createLinks(views.dispensary, routeConfig.DEVELOPERS.layout)}

      <Nav navbar className={classNames(styles.dashboardNavButton)}>
        <NavItem
          key={'Dashboard'}
          id={'Dashboard'.toLowerCase()}
          itemID={'Dashboard'.toLowerCase()}
        >
          <NavLink
            target="_blank"
            href={treezDashboardUrl(env)}
            activeClass="active"
          >
            <img src={TreezLogo}/>
            <span className={classNames(styles.dashboardNavButtonText)}>{'Dashboard'}</span>
          </NavLink>
        </NavItem>
      </Nav>
    </>
  );

  const NonAchDispensaryNavLinks = () => (
    <>
      <Nav navbar>
        {createLinks(views.nonAchDispensaryViews, routeConfig.ANALYTICS.layout)}
      </Nav>
      {createLinks(views.nonAchDispensaryViews, routeConfig.PAYMENTS.layout)}

      <h6
        className={classNames(
          'footnote-bold-caps',
          styles.nav_subheading,
          styles.accountHeading
        )}
      >
        Account
      </h6>
      {createLinks(views.nonAchDispensaryViews, routeConfig.SETTINGS.layout)}

      <Nav navbar className={classNames(styles.dashboardNavButton)}>
        <NavItem
          key={'Dashboard'}
          id={'Dashboard'.toLowerCase()}
          itemID={'Dashboard'.toLowerCase()}
        >
          <NavLink
            target="_blank"
            href={treezDashboardUrl(env)}
            activeClass="active"
          >
            <img src={TreezLogo}/>
            <span className={classNames(styles.dashboardNavButtonText)}>{'Dashboard'}</span>
          </NavLink>
        </NavItem>
      </Nav>
    </>
  );

  const PartnerNavLinks = () => (
    <>
      <Nav navbar>
        {createLinks(views.partner, routeConfig.PARTNER_STORES.layout)}
      </Nav>
      <Nav navbar>
        {createLinks(views.partner, routeConfig.PARTNER_TRANSACTIONS.layout)}
      </Nav>
      <hr className="mb-3 mt-2" />
      <h6
        className={classNames(
          'footnote-bold-caps pl-5 mb-5',
          styles.nav_subheading
        )}
      >
        Account
      </h6>
      {createLinks(views.partner, routeConfig.SETTINGS.layout)}
      {createLinks(views.partner, routeConfig.DEVELOPERS.layout)}
    </>
  );
  const getNavLinks = () => {
    if (activeOrg?.is_partner === true) {
      return <PartnerNavLinks />;
    }
    if (activeOrg?.is_partner === false) {
      if (activeOrg.is_payment_enabled) {
        return <DispensaryNavLinks />;
      } else {
        return <NonAchDispensaryNavLinks />;
      }
    }
    return <NonAchDispensaryNavLinks />;
  };

  return (
    <Navbar
      className={classNames('navbar-vertical', 'fixed-left', styles.navBar)}
      expand="md"
      id="sidenav-main"
    >
      <div className="d-block d-md-none" onClick={() => setCollapseOpen(true)}>
        <i className={classNames(styles.collapse, 'fas', 'fa-bars')} />
      </div>
      <Container fluid className={classNames(styles.container)}>
        <Collapse navbar isOpen={collapseOpen}>
          <a
            className={classNames(
              styles.collapse,
              'd-block',
              'd-md-none',
              'd-flex',
              'justify-content-end'
            )}
            onClick={() => setCollapseOpen(false)}
          >
            &times;
          </a>
          {getNavLinks()}
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default Sidebar;
