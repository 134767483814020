import React, { SyntheticEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import Pagination from 'components/pagination';
import BootstrapTable, { SortOrder } from 'react-bootstrap-table-next';
import { formatToTz } from 'utils/date-format';
import CURRENCY from 'utils/currency';
import useQuery from 'utils/hooks/useQuery';
import routeConfig from 'routes/config';
import Search from 'components/search';
import Filter from 'components/filter';
import ExportBtn from 'components/modals/export/exportBtn';
import { EFilterConfigType } from 'components/filter/types';
import ExportModal from 'components/modals/export';
import useBanking from 'state/banking/hooks/useBanking';
import { BANKING } from 'state/banking/type';
import { fromDate, todayDate } from 'components/summary-tab';
import {
  bankingDescriptionFormatter,
  bankingTypeFormatter,
} from 'utils/source-formatter';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import styles from '../styles.module.scss';
import { getBankingFilterConfig } from './config';

interface IProps {
  amountOption?: string;
  firstAmount?: string;
  secondAmount?: string;
  queryParams: string;
  activeTab: string;
  typeOption: string[];
}

const BankingDetails = ({
  amountOption,
  firstAmount,
  secondAmount,
  queryParams,
  activeTab,
  typeOption,
}: IProps): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery(location.search);
  const urlParams = useQuery(queryParams);
  const { organisation } = useOrganisation();
  const { settings } = organisation;

  const datesFromQuery = query.get('dates')?.split(',') || [];

  const dateFrom =
    datesFromQuery.length > 0 ? datesFromQuery[0].replace(' ', '+') : '';
  const dateTo =
    datesFromQuery.length > 1 ? datesFromQuery[1].replace(' ', '+') : '';

  const dateValues: string[] = [];
  if (dateFrom) {
    dateValues[0] = dateFrom;
  }
  if (dateTo) {
    dateValues[1] = dateTo;
  }

  const amountValues = ['equal'];
  if (amountOption) {
    amountValues[0] = amountOption;
  }
  if (firstAmount) {
    amountValues[1] = firstAmount;
  }
  if (secondAmount) {
    amountValues[2] = secondAmount;
  }

  // Number of results
  const [numResults, setNumResults] = useState(0);

  // Next and prev key
  const [prevKey, setPrevKey] = useState<string | null>(null);
  const [nextKey, setNextKey] = useState<string | null>(null);

  const {
    banking,
    toFetchCreditDetails,
    toFetchDebitDetails,
    toFetchGrossDetails,
    toFetchBankingCSVTransactions,
    toClearBankingExport,
  } = useBanking();

  const {
    credit_details,
    total_gross_details,
    debit_details,
    export_url,
    error,
  } = banking;

  // export modal display toggle
  const [showModal, setShowModal] = useState(false);
  const [products, setProducts] = useState<
    BANKING.IBankStatement[] | undefined
  >();

  const searchString = urlParams.get('search') || undefined;

  useEffect(() => {
    if (activeTab === 'all') {
      setProducts(total_gross_details.items);
      setPrevKey(total_gross_details.prev_key);
      setNextKey(total_gross_details.next_key);
      setNumResults(total_gross_details.total_count);
    }
    if (activeTab === 'incoming') {
      setProducts(credit_details.items);
      setPrevKey(credit_details.prev_key);
      setNextKey(credit_details.next_key);
      setNumResults(credit_details.total_count);
    }
    if (activeTab === 'outgoing') {
      setProducts(debit_details.items);
      setPrevKey(debit_details.prev_key);
      setNextKey(debit_details.next_key);
      setNumResults(debit_details.total_count);
    }
  }, [total_gross_details, credit_details, debit_details, activeTab]);

  const getTypeQueryStr = (typesArr: string[]) => {
    const type = new Set();
    const sub_type = new Set();

    typesArr.forEach((t) => {
      if (t === 'retail_income') {
        type.add('ach_transfer');
        sub_type.add('regular').add('retry');
      } else if (t === 'refund') {
        type.add('ach_transfer');
        sub_type.add('refund');
      } else type.add(t);
    });

    return {
      transaction_type: Array.from(type).join(',') || undefined,
      sub_type: Array.from(sub_type).join(',') || undefined,
    };
  };

  const activeTabGetData = (paginationKey?: string) => {
    const typeQuery = getTypeQueryStr(typeOption);
    const fetchFilterParams = {
      key: paginationKey,
      start_date: dateFrom || fromDate || undefined,
      end_date: dateTo || todayDate || undefined,
      amount_1: firstAmount,
      amount_2: secondAmount,
      condition: amountOption,
      search_string: searchString,
      ...typeQuery,
    };

    if (activeTab === 'all') {
      toFetchGrossDetails({
        ...fetchFilterParams,
      });
      if (total_gross_details?.items?.length > 0)
        setProducts(total_gross_details.items);
    }
    if (activeTab === 'incoming') {
      toFetchCreditDetails(fetchFilterParams);
      if (credit_details?.items?.length > 0) setProducts(credit_details.items);
    }
    if (activeTab === 'outgoing') {
      toFetchDebitDetails({
        ...fetchFilterParams,
      });
      if (debit_details?.items?.length > 0) setProducts(debit_details.items);
    }
  };

  useEffect(() => {
    activeTabGetData();
  }, [
    dateFrom,
    dateTo,
    amountOption,
    firstAmount,
    secondAmount,
    queryParams,
    activeTab,
  ]);

  // get to prev page
  const prevPage = () => {
    activeTabGetData(prevKey || undefined);
  };

  // get to next page
  const nextPage = () => {
    activeTabGetData(nextKey || undefined);
  };

  const columns = [
    {
      dataField: 'transaction_create_date',
      text: 'Date',
      formatter: (cell: string, values: BANKING.IBankStatement) => {
        const formattedDate = formatToTz(
          cell,
          settings?.timezone,
          'MMM dd, hh:mm a'
        );

        return values.direction === 'credit' ? (
          <span className="d-flex align-items-center">
            <i
              className={classNames(
                'ph-arrow-down-left-bold text-success',
                styles.statusIconCredit
              )}
            ></i>
            {cell ? formattedDate : ''}
          </span>
        ) : (
          <span className="d-flex align-items-center">
            <i
              className={classNames(
                'ph-arrow-up-right-bold dangerIcon',
                styles.statusIconDebit
              )}
            ></i>
            {cell ? formattedDate : ''}
          </span>
        );
      },
    },
    {
      dataField: 'amount',
      text: 'Amount',
      sort: true,
      formatter: (cell: number) => {
        return (
          <div className={'currencyColumn'} data-testid="amount">
            {CURRENCY.convertToMainUnit(cell)}
          </div>
        );
      },
    },
    {
      dataField: 'resource',
      text: 'Type',
      sort: true,
      formatter: (cell: string, values: BANKING.IBankStatement) => {
        return (
          <div data-testid="type">
            {bankingTypeFormatter(cell, values.direction, values)}
          </div>
        );
      },
    },
    {
      dataField: 'internal_reference_id',
      text: 'Transaction ID',
      formatter: (cell: string) => {
        // if (values.direction === 'credit') {
        //   return <span className="text-uppercase">{cell}</span>;
        // }
        // if (values.direction === 'debit') {
        //   return (
        //     <span className="text-uppercase">
        //       {values.resource_object?.id || ''}
        //     </span>
        //   );
        // }
        return (
          <span data-testid="txn-id" className="text-uppercase">
            {cell || ''}
          </span>
        );
      },
    },
    {
      dataField: 'source',
      text: 'Description',
      formatter: (_: string, values: BANKING.IBankStatement) => {
        return (
          <div data-testid="description">
            {bankingDescriptionFormatter(values)}
          </div>
        );
      },
    },
  ];

  const filterConfig = getBankingFilterConfig({
    activeTab,
    amount: amountValues,
    type: typeOption,
  });

  const getExportFilterConfig = () => {
    return [
      {
        key: 'dates',
        label: 'Dates',
        type: EFilterConfigType.date,
        data: {
          values: dateValues,
        },
        selected: true,
      },
    ].concat(filterConfig);
  };

  const exportFilterConfig = getExportFilterConfig();

  const defaultSorted: [{ dataField: string; order: SortOrder }] = [
    {
      dataField: 'date',
      order: 'desc',
    },
  ];

  // Taking actions on row events
  const rowEvents = {
    onClick: (
      e: SyntheticEvent<Element, Event>,
      row: BANKING.IBankStatement
    ) => {
      const transactionResource = row.resource_object;
      if (
        (transactionResource !== null &&
          transactionResource !== undefined &&
          (row.resource === BANKING.OutgoingTypes.SELF_PAYOUT ||
            row.resource === BANKING.OutgoingTypes.EXTERNAL_PAYOUT)) ||
        (row.resource === BANKING.OutgoingTypes.FEE &&
          'type' in transactionResource)
      ) {
        navigate(
          `../../${routeConfig.PAYOUTS.layout}/${transactionResource.id}`
        );
      } else if (
        transactionResource !== null &&
        transactionResource !== undefined &&
        'order_id' in transactionResource
      ) {
        navigate(
          `../../${routeConfig.PAYMENTS.layout}/${routeConfig.PAYMENTS.transactions.path}/${transactionResource.order_id}`
        );
      }
    },
  };

  return (
    <>
      <div className="d-flex mb-3">
        <span className="mr-auto">
          <Search queryParams={queryParams} />
        </span>

        {filterConfig && (
          <>
            <Filter
              config={filterConfig}
              queryParams={location.search}
            ></Filter>
            <ExportBtn
              onClick={() => setShowModal(true)}
              id="bank-statements-export-btn"
            />
          </>
        )}
      </div>
      <BootstrapTable
        wrapperClasses="table-responsive"
        keyField="_id"
        data={products || []}
        columns={columns}
        defaultSorted={defaultSorted}
        rowEvents={rowEvents}
        hover
        condensed
        bordered={false}
        noDataIndication={
          <p className="text-center text-regular">No results found</p>
        }
        id="table-details"
      />
      <Pagination
        results={numResults}
        prevPage={prevPage}
        nextPage={nextPage}
        prevKey={prevKey}
        nextKey={nextKey}
      />
      <ExportModal
        showModal={showModal}
        setShowModal={setShowModal}
        config={exportFilterConfig}
        heading="Account statements"
        onExport={toFetchBankingCSVTransactions} // skipcq JS-0336
        reportUrl={export_url}
        label="Account statements"
        errorMessage={error.exportUrl?.message ?? undefined}
        toClearExport={toClearBankingExport}
      />
    </>
  );
};

export default BankingDetails;
