import React, { useEffect } from 'react';
import usePayment from 'state/payment/hooks/usePayment';
import TotalTransactionSummary from './summaryTotals';
import SummaryByPaymentType from './summaryByPaymentType';

interface IProps {
  dateFrom: string;
  dateTo: string;
  prevDateFrom: string;
  prevDateTo: string;
  triggerRefresh: boolean;
  isActiveTab: boolean;
}

const TransactionSummaryTab = ({
  dateFrom,
  dateTo,
  prevDateFrom,
  prevDateTo,
  triggerRefresh,
  isActiveTab,
}: IProps): JSX.Element => {
  const { toFetchTransactionSummary } = usePayment();

  useEffect(() => {
    if (isActiveTab) {
      toFetchTransactionSummary({
        start_date: dateFrom,
        end_date: dateTo,
        prev_start_date: prevDateFrom,
        prev_end_date: prevDateTo,
      });
    }
  }, [dateFrom, dateTo, triggerRefresh, isActiveTab]);

  return (
    <>
      <TotalTransactionSummary
        prevFromDate={prevDateFrom}
        prevToDate={prevDateTo}
      />
      <SummaryByPaymentType />
    </>
  );
};

export default TransactionSummaryTab;
