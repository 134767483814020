import PageHeading from 'components/heading';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { TIPS_TABS, paymentTypeTipList, tipsThresholdDate } from 'utils/constants';
import useQuery from 'utils/hooks/useQuery';
import { useLocation, useNavigate } from 'react-router-dom';
import { SUMMARY } from 'state/summary/type';
import ExportModal from 'components/modals/export';
import { EFilterConfigType, IFilterConfig } from 'components/filter/types';
import styles from '../tips/tips.module.scss';
import usePayment from 'state/payment/hooks/usePayment';
import TipSummary from 'views/tips/tipSummary/tips-summary';
import DateTimeRangePicker from 'components/calender/rangePicker';
import PrimaryExportBtn from 'components/modals/export/exportBtnPrimary';
import TipHeading from './tip-heading';
import { Moment } from 'moment';
import moment from 'moment';
import TipsDetails from './tipDetails/details';
import { getComparisonDateTime } from 'utils/dashboard';
import STRING from 'utils/string';
import QUERY from 'utils/query';
import TipInAccurateDataBanner from './tipBanner';

const Tips = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    payment,
    toClearTipsSummaryExport,
    toFetchTipsSummaryCSV,
    toFetchTipsDetailsCSV,
    toClearTipsExport,
  } = usePayment();
  const query = useQuery(location?.search);

  // dates from url query
  const dates = query.get('dates')?.split(',') || [];
  const urlDateFrom = dates.length > 0 ? dates[0].replace(' ', '+') : '';
  const urlDateTo = dates.length > 1 ? dates[1].replace(' ', '+') : '';


  // default dates
  const [defaultFromDate, defaultToDate] =
    urlDateFrom && urlDateTo
      ? [moment(urlDateFrom), moment(urlDateTo)]
      : [moment().subtract(7, 'day').startOf('day'), moment().endOf('day')];

  const [dateFrom, setDateFrom] = useState<Moment>(defaultFromDate);
  const [dateTo, setDateTo] = useState<Moment>(defaultToDate);
  const [prevFrom, prevTo] =
    getComparisonDateTime(dateFrom.toISOString(), dateTo.toISOString()) || [];
  const dateFromISO = dateFrom.toISOString();
  const dateToISO = dateTo.toISOString();

  const { url, error } = payment;

  const urlParams = useQuery(location?.search);

  const [showModal, setShowModal] = useState(false);
  const [summaryType, setSummaryType] = useState('employee');
  const [intervalTypes, setIntervalTypes] = useState<SUMMARY.IIntervalType[]>(
    []
  );
  const [activeTab, setActiveTab] = useState(
    query.get('tab')?.toLowerCase() ||
      (TIPS_TABS.length > 0 && TIPS_TABS[0].label) ||
      ''
  );
  const [withInDateRange, setWithInDateRange] = useState(true);

  const subHeading =
    'Efficiently track and distribute employee tips, view breakdowns by payment methods and access tip details by Ticket ID.';

  const paymentTypeArray = query.get('payment_type')?.split(',') || [];
  const amountOption = query.get('condition') || 'equal';
  const firstAmount = query.get('amount1') || undefined;
  const secondAmount = query.get('amount2') || undefined;
  const searchString = urlParams.get('search') || undefined;

  const dateValues: string[] = [];
  if (dateFrom) {
    dateValues[0] = urlDateFrom;
  }
  if (dateTo) {
    dateValues[1] = urlDateTo;
  }

  const amountValues = ['equal'];
  if (amountOption) {
    amountValues[0] = amountOption;
  }
  if (firstAmount) {
    amountValues[1] = firstAmount;
  }
  if (secondAmount) {
    amountValues[2] = secondAmount;
  }

  const getFilterConfig = () => [
    {
      key: 'amount',
      label: 'Amount',
      type: EFilterConfigType.number,
      data: {
        keys: ['condition'],
        values: amountValues,
      },
      selected: Boolean(firstAmount || secondAmount),
    },
    {
      key: 'payment_type',
      label: 'Payment Type',
      type: EFilterConfigType.list,
      data: {
        keys: 'payment_type',
        options: paymentTypeTipList,
        values: paymentTypeArray,
      },
      selected: paymentTypeArray?.length > 0,
    },
  ];

  const getExportConfig = () => {
    const filterConfig: IFilterConfig[] = getFilterConfig();
    const dateFilterConfig: IFilterConfig = {
      key: 'dates',
      label: 'Create Date',
      type: EFilterConfigType.date,
      data: {
        values: [dateFromISO, dateToISO],
      },
      selected: false,
    };
    const exportConfig = [dateFilterConfig].concat(filterConfig);

    return exportConfig;
  };

  const [exportFilterConfig, setExportFilterConfig] = useState(
    getExportConfig()
  );

  const getKeyFromUrl = () => {
    const queryString = window.location.search;

    // Remove the leading question mark
    const trimmedQueryString = queryString.substring(1);

    // Split the string by "&" to get individual key-value pairs
    const keyValuePairs = trimmedQueryString.split('&');

    // Create an object to store key-value pairs
    const params: { [key: string]: string } = {};

    // Loop through each key-value pair and add them to the object
    keyValuePairs.forEach((keyValue) => {
      const [key, value] = keyValue.split('=');
      params[key] = decodeURIComponent(value);
    });
    return params.key;
  };

  useEffect(() => {
    const queryTabParam = urlParams.get('tab')?.toLowerCase();
    const tabFromUrl = TIPS_TABS.find(
      (t) => t.label.toLowerCase() === queryTabParam
    )?.label;

    if (location.search && tabFromUrl) {
      setActiveTab(tabFromUrl);
    } else {
      navigate({
        search: QUERY.update(location.search, {
          tab: 'summary',
          dates: [dateFrom.toISOString(), dateTo.toISOString()].join(),
        }),
      });
    }
    setExportFilterConfig(getExportConfig());
  }, [location.search]);

  useEffect(() => {
    if (dateFrom && dateTo) {
      navigate({
        search: QUERY.update(location.search, {
          dates: [dateFrom.toISOString(), dateTo.toISOString()].join(),
        }),
      });
    }
  }, [dateFrom, dateTo]);

  useEffect(() => {
    if (new Date(defaultFromDate.toISOString()) < tipsThresholdDate) {
      setWithInDateRange(true);
    } else {
      setWithInDateRange(false);
    }
  }, [defaultFromDate]);

  return (
    <>
      <Card className="card-profile border-0">
        <div className={classNames(styles.headingBox)}>
          <PageHeading
            heading={
              <TipHeading
                pageKey={getKeyFromUrl()}
                dateFrom={dateFromISO}
                dateTo={dateToISO}
                prevFromDate={prevFrom}
                prevToDate={prevTo}
                intervalTypes={intervalTypes}
                summaryType={summaryType}
                tab={activeTab}
                searchString={searchString}
                paymentTypeArray={paymentTypeArray}
                condition={amountOption}
                amount_1={firstAmount}
                amount_2={secondAmount}
              />
            }
            subHeading={subHeading}
            className="mb-7"
          />

          <PrimaryExportBtn
            onClick={() => setShowModal(true)}
            id="tips-export-btn"
          />
        </div>
        <div className="d-flex align-items-center mb-5">
          <DateTimeRangePicker
            initialStartValue={dateFrom}
            initialEndValue={dateTo}
            setStartDatetime={setDateFrom}
            setEndDatetime={setDateTo}
          />
          <div
            className={styles.comparisonDateText}
          >{`compared to ${STRING.getComparisonDateString(
            prevFrom,
            prevTo
          )}`}</div>
        </div>

        {withInDateRange && <TipInAccurateDataBanner  isDismissable/>}
        <Nav
          tabs
          id="tips-tabs"
          className={classNames(styles.tipsTabs, 'mb-5')}
        >
          {TIPS_TABS.map((item) => {
            return (
              <>
                <NavItem>
                  <NavLink
                    id={`${item.label.toLowerCase()}-tab`}
                    className={classNames({
                      active: item?.label === activeTab,
                    })}
                    onClick={() => {
                      navigate({
                        search: `?tab=${item.label.toLowerCase()}`,
                      });
                    }}
                  >
                    {item?.label}
                  </NavLink>
                </NavItem>
              </>
            );
          })}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={TIPS_TABS[0].label} key={TIPS_TABS[0].label}>
            <TipSummary
              activeTab={activeTab}
              summaryType={summaryType}
              toSetSummaryType={setSummaryType}
              intervalTypes={intervalTypes}
              toSetIntervalTypes={setIntervalTypes}
            />
          </TabPane>
          <TabPane tabId={TIPS_TABS[1].label} key={TIPS_TABS[1].label}>
            <TipsDetails
              filterConfig={getFilterConfig()}
              activeTab={activeTab}
              dateFrom={dateFromISO}
              dateTo={dateToISO}
              firstAmount={firstAmount}
              secondAmount={secondAmount}
              amountOption={amountOption}
              paymentTypeArray={paymentTypeArray}
              searchString={searchString}
              queryParams={location?.search}
              setActiveTab={setActiveTab}
            />
          </TabPane>
        </TabContent>
        {showModal && (
          <ExportModal
            showModal={showModal}
            setShowModal={setShowModal}
            config={exportFilterConfig}
            heading={
              activeTab === TIPS_TABS[0].label ? 'Tip Summary' : 'Tip Details'
            }
            onExport={
              activeTab === TIPS_TABS[0].label
                ? toFetchTipsSummaryCSV
                : toFetchTipsDetailsCSV
            } // skipcq JS-0336
            reportUrl={url}
            label="Tips"
            errorMessage={error.addUrl?.message ?? undefined}
            toClearExport={
              activeTab === TIPS_TABS[0].label
                ? toClearTipsSummaryExport
                : toClearTipsExport
            }
            isTipsExport={true}
          />
        )}
      </Card>
    </>
  );
};
export default Tips;
