import React from 'react';
import App from 'app';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { defaultState, StateProvider } from './state';
import reducers from './state/reducers';
import { Integrations } from '@sentry/tracing';
import { SENTRY_DSN, SENTRY_ENV } from './utils/config';

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  environment: SENTRY_ENV,
});

ReactDOM.render(
  <StateProvider initialState={defaultState} reducer={reducers}>
    <App />
  </StateProvider>,
  document.getElementById('root')
);
