import React, { useEffect, useState } from 'react';
import useEvents from 'state/events/hooks/useEvents';
import Summary from './sessionDetails/summary';
import Timeline from './sessionDetails/timeline';

const SessionDetails = (): JSX.Element => {
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const { events } = useEvents();

  useEffect(() => {
    if (Object.keys(events?.sessionEvents).length !== 0) {
      setIsDataAvailable(true);
    } else {
      setIsDataAvailable(false);
    }
  }, [events?.sessionEvents]);

  useEffect(() => {
    if (events.error?.fetchSessionEvents) {
      setIsDataAvailable(false);
    }
  }, [events.error]);
  return (
    <>
      {isDataAvailable ? (
        <>
          <Summary
            info={events.sessionEvents}
            consumerInfo={events.sessionEvents.consumer_info}
          />
          <Timeline events={events?.sessionEvents?.events} />
        </>
      ) : (
        <div className="pt-2 pb-2">
          <h5 className="d-flex justify-content-center">
            No details available
          </h5>
        </div>
      )}
    </>
  );
};

export default SessionDetails;
