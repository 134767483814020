export const FETCH_PAYMENT_SOLUTIONS = 'config/FETCH_PAYMENT_SOLUTIONS';
export const FETCH_PAYMENT_SOLUTIONS_SUCCESS =
  'config/FETCH_PAYMENT_SOLUTIONS_SUCCESS';
export const FETCH_PAYMENT_SOLUTIONS_FAILURE =
  'config/FETCH_PAYMENT_SOLUTIONS_FAILURE';

export const GET_ENTITY_CONFIG = 'config/GET_ENTITY_CONFIG';
export const GET_ENTITY_CONFIG_SUCCESS = 'config/GET_ENTITY_CONFIG_SUCCESS';
export const GET_ENTITY_CONFIG_FAILURE = 'config/GET_ENTITY_CONFIG_FAILURE';

export const GET_DEFAULT_PROVIDER = 'config/GET_DEFAULT_PROVIDER';
export const GET_DEFAULT_PROVIDER_SUCCESS =
  'config/GET_DEFAULT_PROVIDER_SUCCESS';
export const GET_DEFAULT_PROVIDER_FAILURE =
  'config/GET_DEFAULT_PROVIDER_FAILURE';

export const CREATE_PAYMENT_DEVICE = 'config/CREATE_PAYMENT_DEVICE';
export const CREATE_PAYMENT_DEVICE_SUCCESS =
  'config/CREATE_PAYMENT_DEVICE_SUCCESS';
export const CREATE_PAYMENT_DEVICE_FAILURE =
  'config/CREATE_PAYMENT_DEVICE_FAILURE';

export const GET_PAYMENT_DEVICE = 'config/GET_PAYMENT_DEVICE';
export const GET_PAYMENT_DEVICE_SUCCESS = 'config/GET_PAYMENT_DEVICE_SUCCESS';
export const GET_PAYMENT_DEVICE_FAILURE = 'config/GET_PAYMENT_DEVICE_FAILURE';

export const DELETE_PAYMENT_DEVICE = 'config/DELETE_PAYMENT_DEVICE';
export const DELETE_PAYMENT_DEVICE_SUCCESS =
  'config/DELETE_PAYMENT_DEVICE_SUCCESS';
export const DELETE_PAYMENT_DEVICE_FAILURE =
  'config/DELETE_PAYMENT_DEVICE_FAILURE';

export const RESET_DEVICE_DELETED = 'config/RESET_DEVICE_DELETED';

export const UPDATE_PAYMENT_DEVICE = 'config/UPDATE_PAYMENT_DEVICE';
export const UPDATE_PAYMENT_DEVICE_SUCCESS =
  'config/UPDATE_PAYMENT_DEVICE_SUCCESS';
export const UPDATE_PAYMENT_DEVICE_FAILURE =
  'config/UPDATE_PAYMENT_DEVICE_FAILURE';

export const TEST_PAYMENT_DEVICE = 'config/TEST_PAYMENT_DEVICE';
export const TEST_PAYMENT_DEVICE_SUCCESS = 'config/TEST_PAYMENT_DEVICE_SUCCESS';
export const TEST_PAYMENT_DEVICE_FAILURE = 'config/TEST_PAYMENT_DEVICE_FAILURE';

export const SET_FILTERED_LIST = 'config/SET_FILTERED_LIST';
export const SET_FILTERED_LIST_SUCCESS = 'config/SET_FILTERED_LIST_SUCCESS';

export const RESET_DEVICE_CREATED = 'config/RESET_DEVICE_CREATED';
export const RESET_DEVICE_UPDATED = 'config/RESET_DEVICE_UPDATED';
export const RESET = 'config/RESET';

export const GET_CARD_READER_VALUES = 'config/GET_CARD_READER_VALUES';
export const GET_CARD_READER_VALUES_SUCCESS =
  'config/GET_CARD_READER_VALUES_SUCCESS';
export const GET_CARD_READER_VALUES_FAILURE =
  'config/GET_CARD_READER_VALUES_FAILURE';

export const GET_CARD_READER_CONFIG = 'config/GET_CARD_READER_CONFIG';
export const GET_CARD_READER_CONFIG_SUCCESS =
  'config/GET_CARD_READER_CONFIG_SUCCESS';
export const GET_CARD_READER_CONFIG_FAILURE =
  'config/GET_CARD_READER_CONFIG_FAILURE';

export const SAVE_CARD_READER_VALUES = 'config/SAVE_CARD_READER_VALUES';
export const SAVE_CARD_READER_VALUES_SUCCESS =
  'config/SAVE_CARD_READER_VALUES_SUCCESS';
export const SAVE_CARD_READER_VALUES_FAILURE =
  'config/SAVE_CARD_READER_VALUES_FAILURE';
export const SAVE_DEFAULT_CARD_READER_VALUES =
  'config/SAVE_DEFAULT_CARD_READER_VALUES';
export const SAVE_DEFAULT_CARD_READER_VALUES_SUCCESS =
  'config/SAVE_DEFAULT_CARD_READER_VALUES_SUCCESS';
export const SAVE_DEFAULT_CARD_READER_VALUES_FAILURE =
  'config/SAVE_DEFAULT_CARD_READER_VALUES_FAILURE';

export const RESET_SAVE_CARD_READER_VALUES =
  'config/RESET_SAVE_CARD_READER_VALUES';
export const RESET_SAVE_DEFAULT_CARD_READER_VALUES =
  'config/RESET_SAVE_DEFAULT_CARD_READER_VALUES';

export const RESET_CARD_READER_VALUES = 'config/RESET_CARD_READER_VALUES';

export const RESET_PAYMENT_DEVICE_LIST = 'config/RESET_PAYMENT_DEVICE_LIST';

export const RESET_DEVICE_STATUS = 'config/RESET_DEVICE_STATUS';

export const action = <T>(type: string, payload?: T): IAction => ({
  type,
  payload,
});

export const actionSuccess = <T>(type: string, payload: T): IAction => ({
  type: `${type}_SUCCESS`,
  payload,
});

export const actionFailure = <T>(type: string, payload: T): IAction => ({
  type: `${type}_FAILURE`,
  payload,
});
