import ErrorMessage from 'components/errorMessage';
import FormLabel from 'components/form-label';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import useBanking from 'state/banking/hooks/useBanking';
import { BANK_ROUTING_NUMBER, BANK_ACCOUNT_NUMBER } from 'utils/validation';
import {
  EXTERNAL_PAYOUT_STAGES,
  IActiveState,
  PAYOUTS_NAV_CONFIG,
  PAYOUT_STAGE,
} from 'components/modals/banking/payouts/payout';
import { BANKING } from 'state/banking/type';
import { DropdownConfig } from 'components/selectDropdown';
import styles from '../../styles.module.scss';

interface Iprops {
  dropdownConfig: DropdownConfig<BANKING.IVendorAccountList>;
  setDropdownConfig: Dispatch<
    SetStateAction<DropdownConfig<BANKING.IVendorAccountList>>
  >;
  setActiveState: Dispatch<SetStateAction<IActiveState>>;
  activeState: IActiveState;
  showToast: boolean;
  setShowToast: Dispatch<SetStateAction<boolean>>;
}

const AddBankAccount = ({
  activeState,
  setActiveState,
  dropdownConfig,
  setDropdownConfig,
  setShowToast,
  showToast,
}: Iprops): JSX.Element => {
  const { register, errors, handleSubmit, setError, getValues } = useForm();

  const {
    clearAddBankAccountError,
    addVendorBankAccount,
    getVendorPayoutAccountList,
    resetAddVendorBankAccount,
    banking,
  } = useBanking();

  const { add_vendor_account, vendor_id } = banking;

  const toggle = () => {
    clearAddBankAccountError();
  };

  const addBankAccountWrapper = (values: { [x: string]: string }) => {
    if (vendor_id) {
      toggle();
      addVendorBankAccount({
        beneficiary_name: values.account_holder_name,
        bank_name: values.institution_name,
        routing_number: values.routing_number.toString(),
        account_number: values.account_number.toString(),
        re_account_number: values.re_account_number.toString(),
        account_nick_name: values?.account_nick_name,
        vendor_id,
      });
    }
  };

  useEffect(() => {
    if (add_vendor_account) {
      if (vendor_id) {
        getVendorPayoutAccountList(vendor_id);
      }
      setShowToast(true);
      activeState.payoutStage = PAYOUT_STAGE.external;
      activeState.activeForm = EXTERNAL_PAYOUT_STAGES.BANK_DETAILS;
      setActiveState({ ...activeState });
      dropdownConfig.addNewItem = false;
      dropdownConfig.updateItem = false;
      setDropdownConfig({ ...dropdownConfig });
      resetAddVendorBankAccount();
    }
  }, [add_vendor_account]);

  const formSubmit = (values: { [x: string]: string }) => {
    addBankAccountWrapper(values);
  };

  const formSubmission = () => {
    handleSubmit(formSubmit)();
  };

  useEffect(() => {
    if (
      Number(getValues('confirm_account')) &&
      !Number(getValues('account_number'))
    ) {
      setError('confirm_account', {
        type: 'validate',
        message: "The account numbers don't match",
      });
    }
  }, [
    Number(getValues('confirm_account')),
    Number(getValues('account_number')),
  ]);

  useEffect(() => {
    const footerElement = (
      <>
        <Button
          className="btn btn-primary mr-2"
          onClick={() => {
            dropdownConfig.addNewItem = false;
            dropdownConfig.updateItem = false;
            dropdownConfig.isSelected = false;
            dropdownConfig.selectedItemData = undefined;
            dropdownConfig.selectedItemName = undefined;
            setDropdownConfig({ ...dropdownConfig });
            activeState.payoutStage = PAYOUT_STAGE.external;
            activeState.activeForm = EXTERNAL_PAYOUT_STAGES.BANK_DETAILS;
            PAYOUTS_NAV_CONFIG[
              EXTERNAL_PAYOUT_STAGES.BANK_DETAILS
            ].isCompleted = false;
            setActiveState({ ...activeState });
            // resetAddVendorBankAccount();
          }}
        >
          <span className="d-flex">Back</span>
        </Button>
        <Button
          data-testid="add-bank-next"
          color="primary"
          className="btn btn-primary"
          type="submit"
          onClick={() => {
            formSubmission();
          }}
        >
          <span className="d-flex">
            Next <i className="ph-arrow-right pl-1"></i>
          </span>
        </Button>
      </>
    );
    activeState.footer = footerElement;
    setActiveState({ ...activeState });
  }, []);

  return (
    <>
      <Form role="form" onSubmit={handleSubmit(formSubmit)}>
        <FormGroup>
          <FormLabel
            isRequired
            element="account_holder_name"
            label="Account beneficiary name"
            className={styles.fieldLabel}
          />
          <Input
            name="account_holder_name"
            id="account_holder_name"
            innerRef={
              register &&
              register({
                required: true,
                // pattern: FULL_NAME_VALIDATION,
              })
            }
            className={errors?.account_holder_name ? styles.borderAlert : ''}
          />
          {errors?.account_holder_name && (
            <ErrorMessage
              isValidation
              message="Please enter account holder name."
              preIcon={
                <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
              }
            />
          )}
        </FormGroup>
        <FormGroup>
          <FormLabel
            isRequired
            element="account_number"
            label="Account number"
            className={styles.fieldLabel}
          />
          <Input
            type="number"
            name="account_number"
            placeholder="234152893"
            id="account_number"
            innerRef={register?.({
              required: {
                value: true,
                message: 'Please enter your account number.',
              },
              pattern: {
                value: BANK_ACCOUNT_NUMBER,
                message: 'Please enter a valid account number.',
              },
            })}
            className={errors?.account_number ? styles.borderAlert : ''}
          />
          {errors?.account_number && (
            <ErrorMessage
              testId="account_number"
              isValidation
              message={
                errors?.account_number?.message ||
                'Please enter account number.'
              }
              preIcon={
                <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
              }
            />
          )}
        </FormGroup>
        <FormGroup>
          <FormLabel
            isRequired
            element="re_account_number"
            label="Confirm account number"
            className={styles.fieldLabel}
          />
          <Input
            type="number"
            name="re_account_number"
            id="re_account_number"
            innerRef={
              register &&
              register({
                required: true,
                validate: () =>
                  Number(getValues('account_number')) ===
                  Number(getValues('re_account_number')),
              })
            }
            className={
              errors?.re_account_number?.type === 'validate'
                ? styles.borderAlert
                : '' ||
                  (errors.re_account_number?.type === 'required' &&
                  !errors?.account_number
                    ? styles.borderAlert
                    : '')
            }
          />
          {errors?.re_account_number &&
            errors?.re_account_number.type === 'required' &&
            !errors?.account_number && (
              <ErrorMessage
                isValidation
                message={'Please confirm your account number.'}
                preIcon={
                  <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
                }
              />
            )}
          {errors?.re_account_number &&
            errors?.re_account_number.type === 'required' &&
            errors?.re_account_number && <></>}
          {errors?.re_account_number &&
            errors?.re_account_number.type === 'validate' && (
              <ErrorMessage
                isValidation
                message={"The account numbers don't match."}
                preIcon={
                  <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
                }
              />
            )}
        </FormGroup>
        <FormGroup>
          <FormLabel
            isRequired
            element="institution_name"
            label="Bank name"
            className={styles.fieldLabel}
          />
          <Input
            name="institution_name"
            id="institution_name"
            placeholder="XYZ Bank"
            innerRef={
              register &&
              register({
                required: true,
              })
            }
            className={errors?.institution_name ? styles.borderAlert : ''}
          />
          {errors?.institution_name && (
            <ErrorMessage
              isValidation
              message="Please enter a bank name."
              preIcon={
                <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
              }
            />
          )}
        </FormGroup>
        <FormGroup>
          <FormLabel
            isRequired
            element="routing_number"
            label="Bank routing number"
            className={styles.fieldLabel}
          />
          <Input
            type="number"
            name="routing_number"
            id="routing_number"
            placeholder="623852453"
            innerRef={
              register &&
              register({
                required: {
                  value: true,
                  message: 'Please enter a bank routing number.',
                },
                pattern: {
                  value: BANK_ROUTING_NUMBER,
                  message: 'Please enter a valid bank routing number.',
                },
              })
            }
            className={errors?.routing_number ? styles.borderAlert : ''}
          />
          {errors?.routing_number && (
            <ErrorMessage
              testId="routing_number"
              isValidation
              message={errors?.routing_number.message}
              preIcon={
                <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
              }
            />
          )}
        </FormGroup>
        <FormGroup style={{ marginBottom: '64px' }}>
          <FormLabel
            element="account_nick_name"
            label="Account nick name"
            className={styles.fieldLabel}
          />
          <Input
            name="account_nick_name"
            id="account_nick_name"
            innerRef={register}
            className={errors?.account_nick_name ? styles.borderAlert : ''}
          />

          {errors?.account_nick_name && (
            <ErrorMessage
              testId="account_nick_name"
              isValidation
              message={errors?.account_nick_name.message}
              preIcon={
                <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
              }
            />
          )}
        </FormGroup>
      </Form>
    </>
  );
};

export default AddBankAccount;
