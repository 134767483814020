import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import ModalContainer from 'components/modals/container';
import { BANKING } from 'state/banking/type';
import useAuth from 'state/auth/hooks/useAuth';
import FailedTxnDetails from 'components/failed-payment-txn-details';
import { PAYMENTS } from 'state/payment/type';
import useBanking from 'state/banking/hooks/useBanking';
import WriteOffSelection from './writeOffSelection';
import WriteOffAuth from './writeOffAuth';
import modalStyles from '../styles.module.scss';

interface IProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  toShowToast: () => void;
  data: BANKING.IIndividualTransactionResult[];
}

enum VIEWS {
  INDEX,
  AUTH,
  DETAIL,
}

const MultiWriteOffModal = ({
  showModal,
  setShowModal,
  toShowToast,
  data,
}: IProps): JSX.Element => {
  const [otp, setOTP] = useState('');
  const toggle = () => {
    setShowModal(false);
  };
  const [view, setView] = useState<VIEWS>(VIEWS.INDEX);
  const [details, setDetails] =
    useState<PAYMENTS.IIndividualTransactionResult | null>(null);
  const { verifyOtpChallenge } = useAuth();
  const { toInitiateWriteOffs, banking } = useBanking();
  const { error } = banking;

  const toViewDetails = (
    transactionDetails: PAYMENTS.IIndividualTransactionResult
  ) => {
    setDetails(transactionDetails);
    setView(VIEWS.DETAIL);
  };

  const getListOfChargeIds = () => {
    const chargeIdList = data.map((list_data: any) => {
      return list_data.charge_id;
    });
    return { charge_ids: chargeIdList } as BANKING.IBatchWriteOffRequest;
  };

  const toWriteOff = async () => {
    const chargeIds = getListOfChargeIds();
    const response = await toInitiateWriteOffs(chargeIds);
    return response;
  };

  const onWriteOffSubmit = async () => {
    const response = await toWriteOff();
    if (response) {
      toShowToast();
      setShowModal(false);
    }
  };

  const sendOTPforVerification = () => {
    if (
      otp &&
      otp.length === 6 &&
      banking.auth_session.challenge_data?.challenge_id
    ) {
      verifyOtpChallenge({
        challenge_id: banking.auth_session.challenge_data?.challenge_id,
        challenge_related_info: { submitted_otp: otp },
      });
    }
  };

  const NextButton = (
    <Button
      data-testid="write-off-next-button"
      color="primary"
      onClick={() => {
        setView(VIEWS.AUTH);
      }}
      type="submit"
      className="d-flex align-items-center justify-content-center"
    >
      Next
      <i className="ph-arrow-right-bold align-items-center ml-1" />
    </Button>
  );

  const BackButton = (
    <Button
      color="primary"
      onClick={() => {
        setView(VIEWS.INDEX);
      }}
      type="submit"
      className="d-flex justify-content-center align-items-center"
    >
      <i
        className={classNames(
          'ph-arrow-left-bold align-items-center icon-lg mr-1'
        )}
      />
      Back
    </Button>
  );

  const WriteOffButton = (
    <Button
      data-testid="writeOff_button_writeOff_modal"
      disabled={otp.length !== 6}
      color="primary"
      onClick={sendOTPforVerification}
      type="submit"
    >
      Write-off
    </Button>
  );

  const CancelButton = (
    <Button onClick={toggle} color="secondary">
      Cancel
    </Button>
  );

  const OtpBAckButton = (
    <Button
      onClick={() => {
        setView(VIEWS.INDEX);
      }}
      color="secondary"
    >
      Back
    </Button>
  );

  const config = {
    [VIEWS.INDEX]: {
      element: <WriteOffSelection onRowClick={toViewDetails} data={data} />,
      primaryCTA: NextButton,
    },
    [VIEWS.DETAIL]: {
      element: details && (
        <FailedTxnDetails
          heading="Write-off amount"
          info={details}
          onBack={() => setView(VIEWS.INDEX)}
        />
      ),
      primaryCTA: BackButton,
    },
    [VIEWS.AUTH]: {
      element: (
        <WriteOffAuth
          otp={otp}
          setOTP={setOTP}
          onWriteOffSubmit={onWriteOffSubmit}
        />
      ),
      primaryCTA: WriteOffButton,
    },
  };

  useEffect(() => {
    if (error?.writeoff?.error_code === 'role_based_permission_denied') {
      toggle();
    }
  }, [error?.writeoff]);

  return (
    <>
      <ModalContainer
        testId="write-off-modal"
        isOpen={showModal}
        title="Write-off"
        onClose={toggle}
        isFullHeight={false}
        footer={
          <div className="d-flex">
            <>
              {view === VIEWS.AUTH ? OtpBAckButton : CancelButton}
              {config[view].primaryCTA}
            </>
          </div>
        }
        className={modalStyles.setWidth}
      >
        <>{config[view].element}</>
      </ModalContainer>
    </>
  );
};

export default MultiWriteOffModal;
