import React, { useEffect, useState } from 'react';
import NotAuthorizedError from 'views/error/notauthorized';
import ConfigurationSettings from './paymentConfig';

const PaymentConfig = (): JSX.Element => {
  const accesstoken = JSON.parse(localStorage.getItem('user') ?? '');

  const [msoLogin, setMsoLogin] = useState(false);

  useEffect(() => {
    if (accesstoken?.accessToken) {
      setMsoLogin(true);
    }
  }, []);

  return <>{!msoLogin ? <NotAuthorizedError /> : <ConfigurationSettings />}</>;
};

export default PaymentConfig;
