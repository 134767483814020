import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import routeConfig from 'routes/config';
import useAuth from '../state/auth/hooks/useAuth';

const PrivateRoute = () => {
  const { auth } = useAuth();
  const location = useLocation();

  return auth.loggedIn ? (
    <Outlet />
  ) : (
    <Navigate
      to={`${routeConfig.AUTH.layout}${routeConfig.AUTH.LOGIN.path}`}
      replace
      state={{ from: location }}
    />
  );
};

export default PrivateRoute;
