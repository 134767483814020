import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  Input,
  Alert,
  ModalFooter,
  Button,
  FormGroup,
  UncontrolledTooltip,
} from 'reactstrap';
import FormLabel from 'components/form-label';
import ErrorMessage from 'components/errorMessage';
import usePaymentLinks from 'state/paymentLinks/hooks/usePaymentLinks';
import { useForm } from 'react-hook-form';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import styles from '../addLinks.module.scss';

interface IProps {
  onClose: () => void;
}

const LogisticsOrderID = ({ onClose }: IProps) => {
  const { errors, register, handleSubmit } = useForm();
  const { paymentLinks, toCreatePaymentLinkWithTreez } = usePaymentLinks();
  const { organisation } = useOrganisation();
  const { activeOrg } = organisation;

  const [autoCharge, setAutoCharge] = useState(false);

  const onTicketIdSubmit = (data: Record<string, string>) => {
    if (data?.ticketId && activeOrg?.id)
      toCreatePaymentLinkWithTreez(activeOrg?.id, data.ticketId, autoCharge);
  };
  const label = 'Capture payments automatically';
  const tooltipText =
    "Enabling auto capture let's you charge the customers bank account as soon as they authorize the transaction. Auto captured transactions can only be refunded and not canceled";

  const [amountValidation, setAmountValidation] = useState(
    'Something went wrong!'
  );
  useEffect(() => {
    if (
      paymentLinks.error?.createPaymentLink?.error_obj?.error_code ===
      'invalid_total_amount'
    ) {
      setAmountValidation(
        'It appears that the amount linked to the order you are attempting to create a payment link for is below $1. Make sure that the order amount falls within the range of $1 to $999 and try again.'
      );
    }
    // adding block for later reference
    // else if (
    //   paymentLinks.error?.createPaymentLink?.error_obj?.error_code ===
    //   'ticket_not_found'
    // ) {
    //   setAmountValidation(
    //     'Ticket ID not found. Please enter a valid ticket ID.'
    //   );
    // }
    else {
      setAmountValidation('Something went wrong!');
    }
  }, [paymentLinks.error?.createPaymentLink]);

  return (
    <div className="d-flex flex-column justify-content-between h-100">
      <div>
        {paymentLinks.error?.createPaymentLink &&
          paymentLinks.error?.createPaymentLink?.type === 'treez' && (
            <Alert
              id="alert"
              color="danger"
              className="d-flex align-items-start mx-5 mt-5"
            >
              <i className="ph-warning-circle-fill pl-1 text-danger"></i>
              <div className="ml-2 pr-1 pb-1">
                <strong
                  className={classNames(
                    'body-subtext-semibold',
                    styles.errorHeading
                  )}
                >
                  Payment link cannot be created
                </strong>
                <p
                  className={classNames(
                    'body-subtext-regular mt-1',
                    styles.errorSubheading
                  )}
                >
                  {amountValidation}
                </p>
              </div>
            </Alert>
          )}
        <FormGroup className="mx-5 mt-4">
          <FormLabel
            isRequired
            check
            element="ticketId"
            label="Ticket ID"
            className={styles.label}
          ></FormLabel>
          <Input
            defaultValue={undefined}
            name="ticketId"
            placeholder="7H70H7"
            innerRef={
              register &&
              register({
                required: true,
                maxLength: 6,
                minLength: 6,
                pattern: /^[0-9A-Z]{6}$/,
              })
            }
          />
          {errors.ticketId?.type && (
            <ErrorMessage
              isValidation
              message="Please enter a valid ticket ID"
            />
          )}
          <div className="d-flex flex-row align-items-center justify-items-center mt-4">
            <Input
              type="checkbox"
              className="m-0 mr-2"
              name="auto_charge"
              id="auto_charge"
              value="auto_charge"
              onChange={(e) => {
                setAutoCharge(e.currentTarget.checked);
              }}
            />
            <FormLabel
              inline
              element="auto_charge"
              label={label}
              className={classNames(
                'body-subtext-medium mb-0',
                styles.fieldLabel
              )}
            ></FormLabel>
            <span id="auto_charge_message" className="pl-1">
              <i className="ph-info-fill icon-md text-muted" />
            </span>
            <UncontrolledTooltip
              placement="bottom"
              target="auto_charge_message"
            >
              {tooltipText}
            </UncontrolledTooltip>
          </div>
        </FormGroup>
      </div>

      <div>
        <ModalFooter>
          <div>
            <Button variant="text" onClick={onClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              className="btn btn-primary"
              onClick={handleSubmit(onTicketIdSubmit)}
            >
              Create Link
            </Button>
          </div>
        </ModalFooter>
      </div>
    </div>
  );
};

export default LogisticsOrderID;
