import classNames from 'classnames';
import DownChevronIcon from 'components/icons/downchevron';
import DeleteCustomerModal from 'views/customers/details/delete-modal';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import { STATUS_SUSPENDED } from 'utils/constants';
import useConsumer from 'state/consumer/hooks/useConsumer';
import styles from '../customers.module.scss';
import ToastContainer, { ToastType } from 'components/toasts/container';

const boxshadowStyle = {
  boxShadow: `0px 1px 5px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.05)`,
};

/* Actions like Reinstate and Delete on Customer Details page */
const CustomerDetailsActions = (): JSX.Element => {
  const { consumers, toReinstateConsumer } = useConsumer();
  const { individualCustomer, error } = consumers;
  const { organisation } = useOrganisation();
  const { activeOrg } = organisation;
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [reinstateError, setReinstateError] = useState('');
  const { id: consumerProfileId } = individualCustomer.profile;

  const toDeleteCustomer = () => {
    setModal(!modal);
  };

  const reinstateConsumerHandler = async () => {
    const responseCode = await toReinstateConsumer(consumerProfileId);
    if (responseCode) {
      navigate(0); // refresh page
    } else {
      setReinstateError(
        error?.reinstateConsumer?.message || 'Failed to reinstate consumer'
      );
    }
  };

  return (
    <UncontrolledDropdown>
      {/* Customer actions dropdown menu */}
      <DropdownToggle
        style={boxshadowStyle}
        className={classNames(styles.dropdownToggle, 'mr-0')}
      >
        <span className={classNames(styles.filterText)}>Actions</span>
        <DownChevronIcon />
      </DropdownToggle>

      <DropdownMenu right className={classNames(styles.filterMenu)}>
        {/* Reinstate customer option */}
        <DropdownItem
          onClick={reinstateConsumerHandler}
          disabled={individualCustomer?.profile?.status !== STATUS_SUSPENDED}
          className={classNames(
            styles.dropdownText,
            individualCustomer?.profile?.status !== STATUS_SUSPENDED &&
              styles.disabled
          )}
        >
          <i className="ph-arrows-clockwise-light mr-1"></i>Reinstate customer
        </DropdownItem>

        {/* Delete customer option */}
        <DropdownItem
          onClick={toDeleteCustomer}
          className={classNames(styles.dropdownText)}
        >
          <i className="ph-trash-light mr-1"></i>Delete customer
        </DropdownItem>
      </DropdownMenu>

      <DeleteCustomerModal
        modal={modal}
        setModal={setModal}
        customerProfileId={consumerProfileId}
        activeOrgId={activeOrg ? activeOrg?.id : null}
      />
      <ToastContainer
        showToast={Boolean(reinstateError)}
        onClose={() => setReinstateError('')}
        message={reinstateError}
        type={ToastType.error}
        showCross={true}
      />
    </UncontrolledDropdown>
  );
};

export default CustomerDetailsActions;
