import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import routeConfig from 'routes/config';
import SupportForm from 'services/support';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import CopyIDToClipboard from 'components/copyToClipboard';
import treezDashboardLogo from '../../assets/img/brand/treez_dashboard_logo.svg';
import useAuth from '../../state/auth/hooks/useAuth';
import styles from './navbar.module.scss';
import StoreSwitch from './storeSwitch';
import { treezLoginPageUrl } from 'utils/urlGenerator';
import { ENV } from 'utils/config';

const AdminNavbar = (): JSX.Element => {
  const { organisation } = useOrganisation();

  const { userInvites, activeOrg, activeOrgId } = organisation;
  const { auth, toLogout } = useAuth();
  const { isSandbox, user } = auth;
  let userName = '';

  if (user && user?.user_info && user?.user_info.name) {
    userName = user.user_info.name;
  } else {
    userName = 'Welcome!';
  }
  const logoutWrapper = () => {
    toLogout();

    if (auth.isSSOLogin) {
      const env = ENV || 'production';
      const treezLogoutUrl = `${treezLoginPageUrl(env)}/signout`;
      window.open(treezLogoutUrl, '_blank');
    }
  };

  const myRef = useRef(null);

  useEffect(() => {
    if (myRef.current) {
      const script: HTMLScriptElement = document.createElement('script');
      script.src = 'https://app.getbeamer.com/js/beamer-embed.js';
      script.type = 'text/javascript';
      script.defer = true;
      script.async = true;

      document.body.appendChild(script);
    }
  }, []);

  return (
    <>
      <Navbar
        className={classNames(
          styles.navbar,
          isSandbox ? styles.navbarSandbox : ''
        )}
        expand="md"
        id="navbar-main"
      >
        <div
          className="d-inline-flex align-items-center"
          style={{ width: '265px' }}
        >
          <Link
            to={`/${routeConfig.STORES.layout}/${routeConfig.STORES.LIST.path}`}
          >
            <img src={treezDashboardLogo} alt="TreezPay logo" />
          </Link>
          <StoreSwitch />
        </div>

        <div className="d-flex">
        
          <Nav navbar className="mr-5">
            <UncontrolledDropdown
              nv
              className={classNames(styles.icon_dropdown_toggle)}
            >
              <DropdownToggle
                id={'help-tooltip'}
                nav
                className="p-0 d-inline-flex align-items-center justify-content-center"
              >
                <i
                  data-testid="help-icon"
                  className={classNames(
                    styles.toggle_icon_right,
                    'ph-question'
                  )}
                />
                <UncontrolledTooltip
                  placement="bottom"
                  target={'help-tooltip'}
                  style={{ minWidth: '39px', minHeight: '24px' }}
                >
                  Help
                </UncontrolledTooltip>
              </DropdownToggle>
              <DropdownMenu className={classNames(styles.dropdown_right)} right>
                <DropdownItem
                  as="a"
                  className={classNames(styles.dropdown_item_right)}
                  href="https://payswifter.freshdesk.com/support/solutions"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="ph-notebook-light" />
                  <span>Knowledge Base</span>
                </DropdownItem>
                <DropdownItem
                  as="a"
                  className={classNames(styles.dropdown_item_right)}
                  href="https://payswifter.readme.io/reference/online-checkout"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="ph-file-text-light" />
                  <span>API Documentation</span>
                </DropdownItem>
                <DropdownItem
                  className={classNames(styles.dropdown_item_right)}
                  onClick={SupportForm.openForm}
                >
                  <i className="ph-chat-text-light" aria-hidden="true" />
                  <span>Contact Support</span>
                </DropdownItem>
                <DropdownItem
                  as="a"
                  href="mailto:support@swifterhq.com"
                  className={classNames(styles.dropdown_item_right)}
                >
                  <i className="ph-repeat-light" aria-hidden="true" />
                  <span>Send Feedback</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>

          <Nav navbar>
            <UncontrolledDropdown
              nv
              className={classNames(styles.icon_dropdown_toggle)}
            >
              <DropdownToggle
                nav
                className="p-0 d-inline-flex align-items-center justify-content-center"
                id={'profile-tooltip'}
              >
                <i
                  data-testid="profile-icon"
                  className={classNames(
                    'ph-user-circle',
                    styles.toggle_icon_right
                  )}
                />
                <UncontrolledTooltip
                  placement="bottom"
                  target={'profile-tooltip'}
                  style={{ minWidth: '49px', minHeight: '24px' }}
                >
                  Profile
                </UncontrolledTooltip>
                {userInvites.length > 0 && (
                  <span className="position-absolute top-0 start-100 translate-middle p-1 bg-danger rounded-circle"></span>
                )}
              </DropdownToggle>
              <DropdownMenu className={classNames(styles.dropdown_right)} right>
                <div className="px-2 pt-2">
                  <DropdownItem text className={classNames(styles.username)}>
                    {userName}
                  </DropdownItem>
                  <DropdownItem text className={classNames(styles.email)}>
                    {user?.user_info?.email}
                  </DropdownItem>
                </div>
                <hr className="my-2" />
                <div className="px-2 pt-2">
                  <DropdownItem text className={classNames(styles.username)}>
                    Organisation ID
                  </DropdownItem>
                  <DropdownItem text className={classNames(styles.email)}>
                    <CopyIDToClipboard
                      className="text-uppercase"
                      id={activeOrg?.short_identifier || ''}
                    ></CopyIDToClipboard>
                  </DropdownItem>
                </div>
                <hr className="my-2" />
                <DropdownItem
                  className={classNames(styles.dropdown_item_right)}
                  onClick={SupportForm.openForm}
                >
                  <i className="ph-chat-text-light" aria-hidden="true" />
                  <span>Contact Support</span>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    logoutWrapper();
                  }}
                  className={classNames(styles.dropdown_item_right)}
                >
                  <i className="ph-sign-out-light" />
                  <span>Sign out</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </div>
      </Navbar>
      {isSandbox && (
        <div className={classNames(styles.bannerDiv)}>
          <span className={classNames(styles.sandboxBanner)}>
            You are in test mode
          </span>
        </div>
      )}
    </>
  );
};

export default AdminNavbar;
