export const FETCH_STORES = 'partner/FETCH_STORES';
export const FETCH_STORES_SUCCESS = 'partner/FETCH_STORES_SUCCESS';
export const FETCH_STORES_FAILURE = 'partner/FETCH_STORES_FAILURE';

export const FETCH_PARTNERS = 'partner/FETCH_PARTNERS';
export const FETCH_PARTNERS_SUCCESS = 'partner/FETCH_PARTNERS_SUCCESS';
export const FETCH_PARTNERS_FAILURE = 'partner/FETCH_PARTNERS_FAILURE';

export const RESET = 'partner/RESET';

export const action = (type: string): IAction => ({
  type,
});

export const actionSuccess = <T>(type: string, payload: T): IAction => ({
  type: `${type}_SUCCESS`,
  payload,
});

export const actionFailure = <T>(type: string, payload: T): IAction => ({
  type: `${type}_FAILURE`,
  payload,
});
