import React, { useState, Dispatch, SetStateAction } from 'react';
import { Button } from 'reactstrap';
import ModalContainer from 'components/modals/container';
import FailedTxnDetails from 'components/failed-payment-txn-details';
import useBanking from 'state/banking/hooks/useBanking';
import { PAYMENTS } from 'state/payment/type';
import { STATUS_WRITE_OFF } from 'utils/constants';
import CashPaymentReview from './cashPaymentReview';
import modalStyles from '../styles.module.scss';
import ConfirmCashPayment from './confirmCashPayment';

interface IProps {
  showModal: boolean;
  toShowToast: (arg: boolean) => void;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  data: PAYMENTS.IIndividualTransactionResult;
}

enum VIEWS {
  CONFIRM,
  INDEX,
  REVIEW,
}

const CashPaymentModal = ({
  showModal,
  setShowModal,
  toShowToast,
  data,
}: IProps): JSX.Element => {
  const [view, setView] = useState<VIEWS>(
    data?.transaction_status === STATUS_WRITE_OFF ? VIEWS.CONFIRM : VIEWS.INDEX
  );
  const heading_text = 'Record cash payment?';
  const message1 =
    'This transactions was written-off by “Role” on Apr 30, 2023. Are you sure you want to collect this money and record a cash payment for the same?';
  const message2 = 'Please contact Role for more info.';

  const { toRecordCash } = useBanking();

  const handleOnCashRecord = async () => {
    const response = await toRecordCash(data.charge_id);
    if (response) {
      toShowToast(true);
      setShowModal(false);
    }
    else
    {
      toShowToast(false);
    }

  };

  const toggle = () => {
    setShowModal(false);
  };

  const ReviewButton = (
    <Button
      color="primary"
      onClick={() => {
        setView(VIEWS.REVIEW);
      }}
      type="submit"
      className="d-flex align-items-center justify-content-center"
    >
      Review
      <i className="ph-bold ph-arrow-right align-items-center ml-1" />
    </Button>
  );

  const RecordButton = (
    <Button color="primary" onClick={handleOnCashRecord} type="submit">
      Record cash payment
    </Button>
  );

  const ProceedButton = (
    <Button
      color="primary"
      onClick={() => {
        setView(VIEWS.INDEX);
      }}
      type="submit"
    >
      Yes, proceed
    </Button>
  );

  const config = {
    [VIEWS.INDEX]: {
      element: (
        <FailedTxnDetails heading="Record cash payment of" info={data} />
      ),
      CTA: (
        <>
          <Button onClick={toggle} color="secondary">
            Cancel
          </Button>
          {ReviewButton}
        </>
      ),
    },
    [VIEWS.REVIEW]: {
      element: <CashPaymentReview info={data} />,
      CTA: (
        <>
          <Button
            onClick={() => {
              setView(VIEWS.INDEX);
            }}
            color="secondary"
          >
            Back
          </Button>
          {RecordButton}
        </>
      ),
    },
    [VIEWS.CONFIRM]: {
      element: (
        <ConfirmCashPayment
          heading_text={heading_text}
          message1={message1}
          message2={message2}
        />
      ),
      CTA: (
        <>
          <Button onClick={toggle} color="secondary">
            Cancel
          </Button>
          {ProceedButton}
        </>
      ),
    },
  };

  return (
    <>
      <ModalContainer
        isOpen={showModal}
        title="Cash payment"
        onClose={toggle}
        isFullHeight={false}
        footer={
          <div className="d-flex">
            <>{config[view].CTA}</>
          </div>
        }
        header={view !== VIEWS.CONFIRM}
        className={modalStyles.setWidth}
        testId="modal-cash-payment"
      >
        <>{config[view].element}</>
      </ModalContainer>
    </>
  );
};

export default CashPaymentModal;
