import { BANKING } from 'state/banking/type';
import { PAYMENTS } from 'state/payment/type';
import CURRENCY from 'utils/currency';

export interface eventDetails<T> {
  icon: string;
  formatter: (arg?: T) => string;
}

export const PLAID_ERRORS = {
  CREDENTIALS: [
    'INVALID_CREDENTIALS',
    'INVALID_MFA',
    'INSUFFICIENT_CREDENTIALS',
  ],
  NOT_FOUND: ['NO_ACCOUNTS'],
  TECHNICAL: [
    'INSTITUTION_DOWN',
    'INSTITUTION_NOT_RESPONDING',
    'INTERNAL_SERVER_ERROR',
    'ITEM_LOCKED',
    'USER_INPUT_TIMEOUT',
    'USER_SETUP_REQUIRED',
  ],
  AUTHORIZATION: [
    'MFA_NOT_SUPPORTED',
    'INSTITUTION_NO_LONGER_SUPPORTED',
    'INVALID_SEND_METHOD',
    'ITEM_NOT_SUPPORTED',
    'PRODUCTS_NOT_SUPPORTED',
  ],
};

// DEPRECATED: Yodlee errors
export const userExprienceEvents = [
  'ACCOUNT_LOCKED',
  'BETA_SITE_DEV_IN_PROGRESS',
  'DATA_NOT_AVAILABLE',
  'PROPERTY_VALUE_NOT_AVAILABLE',
  'SITE_NOT_SUPPORTED',
  'ADDL_AUTHENTICATION_REQUIRED',
  'CREDENTIALS_UPDATE_NEEDED',
  'USER_ACTION_NEEDED_AT_SITE',
  'SITE_SESSION_INVALIDATED',
  'USER_ACTION_NEEDED_AT_SITE',
  'SITE_SESSION_INVALIDATED',
  'REQUEST_TIME_OUT',
];

export const eventClass: Record<
  string,
  eventDetails<PAYMENTS.IIndividualTransactionResult>
> = {
  // session events
  'session.created': {
    icon: 'ph-check-circle-fill icon-md',
    formatter: () => {
      return 'Session initiated';
    },
  },
  'session.consumer_connected': {
    icon: 'ph-check-circle-fill icon-md',
    formatter: () => {
      return 'Customer logged in';
    },
  },
  'session.fi_account_connected': {
    icon: 'ph-check-circle-fill icon-md',
    formatter: () => {
      return 'Customer selected bank account';
    },
  },
  // 'session.authorize_failed': {
  //   icon: 'ph-x-circle-fill icon-lg',
  //   eventName: 'Payment authorization failed',
  // },
  'session.authorized': {
    icon: 'ph-check-circle-fill icon-md',
    formatter: () => {
      return 'Payment authorization successful';
    },
  },
  // "session.finalized": { icon: 'fas fa-handshake', eventName: 'Customer placed order' },
  // 'session.log': {icon: 'ph-x-circle-fill icon-lg', eventName:'Session log'},
  // order events
  // 'order.created': { icon: 'fas fa-money-check', eventName: 'Order created' },
  // 'order.canceled': { icon: 'fas fa-ban', eventName: 'Order cancelled' },
  'order.total_finalized': {
    icon: 'ph-check-circle-fill icon-md',
    formatter: () => {
      return 'Order finalized';
    },
  },
  // 'order.charged': { icon: 'ph-check-circle-fill icon-lg', eventName: 'Order charged' },
  // yodlee events
  // 'Error': { icon: 'ph-x-circle-fill icon-lg', eventName: 'Enrolment Error' },
  'order.canceled': {
    icon: 'ph-x-circle-fill icon-md',
    formatter: (data) => {
      return `Transaction canceled - ${data?.id || ''}`;
    },
  },
  MODAL_CLOSED: {
    icon: 'ph-check-circle-fill icon-md',
    formatter: () => {
      return 'Customer closed the modal';
    },
  },
  ENROLMENT_ERROR: {
    icon: 'ph-x-circle-fill icon-md',
    formatter: () => {
      return 'Enrolment error';
    },
  },
  ACCT_SUMMARY_RECEIVED: {
    icon: 'ph-check-circle-fill icon-md',
    formatter: () => {
      return 'Customer linked bank account';
    },
  },
  link_success: {
    icon: 'ph-check-circle-fill icon-md',
    formatter: () => {
      return 'Customer linked bank account';
    },
  },
  link_failure: {
    icon: 'ph-x-circle-fill icon-md',
    formatter: () => {
      return 'Error - Could not retrieve customer bank information';
    },
  },
  SESSION_ERROR: {
    icon: 'ph-x-circle-fill icon-md',
    formatter: () => {
      return 'Session error';
    },
  },
  SESSION_INSUFFICIENT_BALANCE: {
    icon: 'ph-x-circle-fill icon-md',
    formatter: () => {
      return 'Payment failed - Customer had insufficient balance';
    },
  },
  SESSION_PENDING_BALANCE: {
    icon: 'ph-x-circle-fill icon-md',
    formatter: () => {
      return 'Payment failed - Customer had insufficient balance';
    },
  },
  SESSION_HIGH_RISK: {
    icon: 'ph-x-circle-fill icon-md',
    formatter: () => {
      return 'Payment failed - Customer account suspended (high risk)';
    },
  },
  SESSION_ACCOUNT_SUSPENDED: {
    icon: 'ph-x-circle-fill icon-md',
    formatter: () => {
      return 'Payment failed - Customer account suspended (pending return)';
    },
  },
  // charge events
  'charge.created': {
    icon: 'ph-check-circle-fill icon-md',
    formatter: () => {
      return 'Payment initiated';
    },
  },
  'ach_debit_transfer.succeeded': {
    icon: 'ph-check-circle-fill icon-md',
    formatter: () => {
      return 'Payment successful';
    },
  },
  // 'charge.succeeded': { icon: 'ph-check-circle-fill icon-lg', eventName: 'Payment initiated(ACH uploaded)' },
  'charge.failed': {
    icon: 'ph-x-circle-fill icon-md',
    formatter: (data) => {
      return `Error - Payment failed ${data?.return_code || ''}`;
    },
  },
  'refund.created': {
    icon: 'ph-check-circle-fill icon-md',
    formatter: (data) => {
      return `Refund initiated by ${data?.refunded_by || ''}`;
    },
  },
  'refund.succeeded': {
    icon: 'ph-x-circle-fill icon-md',
    formatter: () => {
      return 'Refund successful';
    },
  },
  'refund.failed': {
    icon: 'ph-x-circle-fill icon-md',
    formatter: () => {
      return 'Refund failed';
    },
  },
  'charge.retried': {
    icon: 'ph-check-circle-fill icon-md',
    formatter: () => {
      return 'Payment retry initiated';
    },
  },
  'charge_conversion.created': {
    icon: 'ph-check-circle-fill icon-md',
    formatter: (data) => {
      return `Cash payment recorded by ${data?.cash_collected_by}`;
    },
  },
  /**
   * ##########################################
   * DEPRECATED:
   * Yodlee error codes
   * balance refresh events
   */
  'user.experience': {
    icon: 'ph-x-circle-fill icon-md',
    formatter: () => {
      return 'Error - Bank authorization error occurred';
    },
  },
  INCORRECT_CREDENTIALS: {
    icon: 'ph-x-circle-fill icon-md',
    formatter: () => {
      return 'Error - Customer entered invalid bank credentials';
    },
  },
  INVALID_ADDL_INFO_PROVIDED: {
    icon: 'ph-x-circle-fill icon-lg',
    formatter: () => {
      return 'Error - Customer entered invalid bank credentials';
    },
  },
  // 'NO_ACCOUNTS_FOR_VERIFICATION': { icon: 'ph-x-circle-fill icon-lg', eventName: 'No account found' },
  TECH_ERROR: {
    icon: 'ph-check-circle-fill icon-md',
    formatter: () => {
      return 'Error - Technical error occurred';
    },
  },
  UNEXPECTED_SITE_ERROR: {
    icon: 'ph-x-circle-fill icon-md',
    formatter: () => {
      return 'Error - Technical error occurred';
    },
  },
  // 'AVAILABLE_DATA_RETRIEVED': { icon: 'fas fa-money-check', eventName: 'Data retrieving successful' },
  // 'PARTIAL_DATA_RETRIEVED': { icon: 'fas fa-money-check', eventName: 'Partial data retrieved' },
  BALANCE_REFRESH_SUCCESS: {
    // DEPRECATED: Yodlee events
    icon: 'ph-check-circle-fill icon-md',
    formatter: () => {
      return 'Customer account balance retrieved successfully';
    },
  },
  BALANCE_REFRESH_ERROR: {
    // Yodlee event
    icon: 'ph-x-circle-fill icon-md',
    formatter: () => {
      return 'Error - Could not retrieve customer account balance';
    },
  },
};

export const eventFailedTranasctionClass: Record<
  string,
  eventDetails<PAYMENTS.IIndividualSession>
> = {
  'write_off.created': {
    icon: 'ph-check-circle-fill icon-md',
    formatter: (data) => {
      return `Transaction written-off by ${data?.payload?.name}`;
    },
  },
};

export const eventTransactionFeesClass: Record<
  string,
  eventDetails<PAYMENTS.IIndividualSession | BANKING.IPayoutEvent>
> = {
  'transaction_fee.created': {
    icon: 'ph-check-circle-fill icon-md',
    formatter: (data) => {
      if (data?.payload?.amount != 0) {
        return `Swifter fee collected : ${CURRENCY.convertToMainUnit(
          data?.payload?.amount
        )}`;
      } else {
        return '';
      }
    },
  },
};

export const getPlaidEvent = (event: PAYMENTS.IIndividualSession) => {
  // link failure
  if (event.payload?.payload.status === 'link_failure') {
    const eventObj = {
      icon: 'ph-x-circle-fill icon-md',
      name: 'Error - Could not retrieve customer bank information',
    };

    if (
      event.payload?.context?.error_type === 'ITEM_ERROR' &&
      PLAID_ERRORS.CREDENTIALS.includes(event.payload?.context?.error_code)
    ) {
      eventObj.name = 'Error - Customer entered invalid bank credentials';
    } else if (event.payload?.context?.error_type === 'INSTITUTION_ERROR') {
      eventObj.name = 'Error - Could not retrieve customer bank information ';
    } else if (
      event.payload?.context?.error_type === 'RECAPTCHA_ERROR' ||
      event.payload?.context?.error_type === 'ITEM_ERROR'
    ) {
      eventObj.name = 'Error - Bank authorization error occurred';
    } else {
      eventObj.name = 'Error - Technical error occurred';
    }

    return eventObj;
  }

  // link success
  return {
    icon: 'ph-check-circle-fill icon-md',
    name: 'Customer linked bank account',
  };
};

export const findEvent = {
  findEventName(
    event: PAYMENTS.IIndividualSession,
    info?: PAYMENTS.IIndividualTransactionResult
  ) {
    let eventName;
    let eventIcon;
    if (
      event.payload?.payload &&
      (userExprienceEvents.includes(event.payload.payload.code) ||
        userExprienceEvents.includes(event.payload.payload.message))
    ) {
      eventName = eventClass['user.experience'].formatter(info);
      eventIcon = eventClass['user.experience'].icon;
    } else if (
      event.payload?.payload &&
      eventClass[event.payload.payload.code] &&
      eventClass[event.payload.payload.message]
    ) {
      eventName = eventClass[event.payload.payload.message].formatter(info);
      // copyName = eventClass[event.payload.payload.message].eventName;
      eventIcon = eventClass[event.payload.payload.message].icon;
    } else if (
      event?.payload?.type === 'fi_provider' &&
      ['link_failure', 'link_success'].includes(
        event.payload?.payload?.status || ''
      )
    ) {
      // bank linking events
      const plaidEvent = getPlaidEvent(event);
      eventName = plaidEvent.name;
      eventIcon = plaidEvent.icon;
    } else if (event?.entity_type === 'transaction_fee') {
      eventName = eventTransactionFeesClass[event?.name].formatter(
        event as PAYMENTS.IIndividualSession
      );
      eventIcon = eventTransactionFeesClass[event?.name].icon;
    } else if (
      event.payload?.payload &&
      eventClass[event.payload.payload.code] &&
      !eventClass[event.payload.payload.message]
    ) {
      eventName = eventClass[event.payload.payload.code].formatter(info);
      eventIcon = eventClass[event.payload.payload.code].icon;
    } else if (
      event.payload?.payload &&
      !eventClass[event.payload.payload.code] &&
      eventClass[event.payload.payload.message]
    ) {
      eventName = eventClass[event.payload.payload.message].formatter(info);
      eventIcon = eventClass[event.payload.payload.message].icon;
    } else if (event?.name && eventFailedTranasctionClass[event?.name]) {
      eventName = eventFailedTranasctionClass[event?.name].formatter(
        event as PAYMENTS.IIndividualSession
      );
      eventIcon = eventFailedTranasctionClass[event?.name].icon;
    }
    // var for simple events
    else if (eventClass[event.name]) {
      eventName = eventClass[event.name].formatter(info);
      eventIcon = eventClass[event.name].icon;
    }

    return { eventName, eventIcon };
  },
};

export const payoutEventDetails: Record<
  BANKING.PayoutEvents,
  eventDetails<BANKING.IPayout>
> = {
  [BANKING.PayoutEvents.INITIATED]: {
    icon: 'ph-arrow-circle-left-fill',
    formatter: (payout) => {
      return `Payout initiated${
        payout
          ? ` (by ${
              payout?.customer_first_name === 'auto_pay'
                ? 'Autopay'
                : payout?.customer_first_name
            })`
          : ''
      }`;
    },
  },
  [BANKING.PayoutEvents.FAILED]: {
    icon: 'ph-check-circle-fill',
    formatter: () => {
      return 'Payout reversed';
    },
  },
  [BANKING.PayoutEvents.SUCCESSFUL]: {
    icon: 'ph-check-circle-fill',
    formatter: () => {
      return 'Payout successful';
    },
  },
};
