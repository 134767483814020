import classNames from 'classnames';
import FormLabel from 'components/form-label';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Col, FormGroup, Row } from 'reactstrap';
import useBanking from 'state/banking/hooks/useBanking';

import {
  CREATE_PAYOUTS,
  EXTERNAL_PAYOUT_STAGES,
  IActiveState,
  PAYOUTS_NAV_CONFIG,
  PAYOUT_STAGE,
  SELF_PAYOUT_STAGES,
} from 'components/modals/banking/payouts/payout';
import { extractDigitValues, maskedData } from 'utils/pii';
import styles from './styles.module.scss';

interface Iprops {
  setActiveState: Dispatch<SetStateAction<IActiveState>>;
  activeState: IActiveState;
}

const Review = ({ setActiveState, activeState }: Iprops): JSX.Element => {
  const [inputValue, setInputValue] = useState<string | undefined>();

  const { banking, updateNotes } = useBanking();

  const { payout_amount, external_account, vendor_bank_account, notes } =
    banking;

  const formSubmit = () => {
    updateNotes(inputValue);
    if (activeState.payoutStage === PAYOUT_STAGE.self) {
      activeState.activeForm = SELF_PAYOUT_STAGES.CONFIRMATION;
      PAYOUTS_NAV_CONFIG[CREATE_PAYOUTS.REVIEW].isCompleted = true;
      setActiveState({ ...activeState });
    }
    if (activeState.payoutStage === PAYOUT_STAGE.external) {
      activeState.activeForm = EXTERNAL_PAYOUT_STAGES.CONFIRMATION;
      PAYOUTS_NAV_CONFIG[CREATE_PAYOUTS.REVIEW].isCompleted = true;
      setActiveState({ ...activeState });
    }
  };

  const backButton = () => {
    if (activeState.payoutStage === PAYOUT_STAGE.self) {
      activeState.activeForm = SELF_PAYOUT_STAGES.PAYOUT_DETAILS;
      PAYOUTS_NAV_CONFIG[CREATE_PAYOUTS.REVIEW].isCompleted = false;
      setActiveState({ ...activeState });
    }
    if (activeState.payoutStage === PAYOUT_STAGE.external) {
      activeState.activeForm = EXTERNAL_PAYOUT_STAGES.BANK_DETAILS;
      PAYOUTS_NAV_CONFIG[CREATE_PAYOUTS.REVIEW].isCompleted = false;
      setActiveState({ ...activeState });
    }
  };

  useEffect(() => {
    const footerElement = (
      <>
        <Button
          className="btn btn-primary mr-2"
          type="submit"
          onClick={() => backButton()}
        >
          <span className="d-flex">Back</span>
        </Button>
        <Button
          color="primary"
          className="btn btn-primary"
          type="submit"
          onClick={() => formSubmit()}
        >
          <span className="d-flex">
            Next <i className="ph-arrow-right pl-1"></i>
          </span>
        </Button>
      </>
    );
    activeState.footer = footerElement;
    setActiveState({ ...activeState });
  }, [inputValue]);

  return (
    <>
      <div
        className={classNames(
          styles.formContainer,
          'hide-scrollbars overflow-hidden'
        )}
      >
        <h1 className={classNames(styles.heading)}>Review</h1>
        <div className="mt-2 pt-8">
          <span className={classNames(styles.amountHeading)}>
            You are making a Payout of
          </span>
          <br />
          <div className={classNames(styles.payoutAmount, 'mt-2 pt-1')}>
            {payout_amount}
          </div>
        </div>
        <div className={classNames(styles.sendingDetails, 'mt-5 mb-2 pb-1')}>
          <span>Sending from</span>
          <hr className={classNames('my-2', styles.linedBorder)} />
        </div>
        <div>
          <Row>
            <Col xs="6" sm="1">
              <span className={styles.bankIcon}>
                <i className="ph ph-bank-fill icon-xl" />
              </span>
            </Col>
            <Col xs="6" sm="6" className="pl-2 ml-1">
              <span className={styles.bankName}>
                {external_account?.sender_account_details?.institution_name ||
                  'Not available'}
              </span>
              <br />
              <span className={styles.accountNo}>
                {external_account?.sender_account_details?.account_name ||
                  'Not available'}{' '}
                /{' '}
                {maskedData(
                  extractDigitValues(
                    external_account?.sender_account_details
                      ?.masked_account_number
                  ) || 'NA'
                )}
              </span>
            </Col>
          </Row>
        </div>
        <div className={classNames(styles.sendingDetails, 'mt-4 mb-2 pb-1')}>
          <span>Sending to</span>
          <hr className={classNames('my-2', styles.linedBorder)} />
        </div>
        <div className="pb-2">
          <Row>
            <Col xs="6" sm="1">
              <span className={styles.bankIcon}>
                <i className="ph ph-bank-fill icon-xl" />
              </span>
            </Col>
            <Col xs="6" sm="6">
              <span className={styles.bankName}>
                {activeState.payoutStage === PAYOUT_STAGE.self &&
                  (external_account?.receiver_account_details
                    ?.institution_name ||
                    'Not available')}
                {activeState.payoutStage === PAYOUT_STAGE.external &&
                  (vendor_bank_account?.institution_name || 'Not available')}
              </span>
              <br />
              <span className={styles.accountNo}>
                {activeState.payoutStage === PAYOUT_STAGE.self
                  ? external_account?.receiver_account_details?.account_name
                  : vendor_bank_account?.account_name || 'Not available'}{' '}
                /{' '}
                {activeState.payoutStage === PAYOUT_STAGE.self
                  ? maskedData(
                      extractDigitValues(
                        external_account?.receiver_account_details
                          ?.masked_account_number
                      ) || 'NA'
                    )
                  : maskedData(
                      extractDigitValues(
                        vendor_bank_account?.masked_account_number
                      ) || 'NA'
                    )}
              </span>
            </Col>
          </Row>
        </div>
        <div className="d-flex align-items-center">
          <i className="ph ph-warning-circle-fill text-muted mr-2"></i>
          <span className={styles.payoutTime}>
            You understand that Payouts take 2-3 banking business days to
            settle.
          </span>
        </div>
        <FormGroup>
          <FormLabel
            element="notes"
            label="Remarks (Optional)"
            className={styles.fieldLabel}
          />
          <textarea
            id="notes"
            defaultValue={notes}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className={classNames(
              styles.notes,
              inputValue ? inputValue.length > 20 && styles.notes_error : ''
            )}
            rows={4}
            required
          ></textarea>
        </FormGroup>
      </div>
    </>
  );
};

export default Review;
