import {
  FETCH_STORES,
  FETCH_STORES_SUCCESS,
  FETCH_STORES_FAILURE,
  FETCH_PARTNERS,
  FETCH_PARTNERS_SUCCESS,
  FETCH_PARTNERS_FAILURE,
  RESET,
} from './actions';
import { PARTNER_STORES } from './type';

export const INITIAL_STATE: PARTNER_STORES.IReducer = {
  isLoading: false,
  error: {} as PARTNER_STORES.Error,
  list: null,
  partners: null,
};

const partnerStoresReducer = (
  action: IAction,
  state = INITIAL_STATE
): PARTNER_STORES.IReducer => {
  switch (action.type) {
    case FETCH_STORES:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case FETCH_STORES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: {},
      };
    case FETCH_STORES_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: null,
        error: { fetchStores: action.payload.message },
      };

    case FETCH_PARTNERS:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case FETCH_PARTNERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        partners: action.payload,
        error: {},
      };
    case FETCH_PARTNERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        partners: null,
        error: { fetchPartners: action.payload.message },
      };
    case RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default partnerStoresReducer;
