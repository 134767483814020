import SelectDropdown, { DropdownConfig } from 'components/selectDropdown';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, FormGroup } from 'reactstrap';
import FormLabel from 'components/form-label';
import classNames from 'classnames';
import ErrorMessage from 'components/errorMessage';
import {
  EXTERNAL_PAYOUT_STAGES,
  IActiveState,
  PAYOUTS_NAV_CONFIG,
  PAYOUT_STAGE,
} from 'components/modals/banking/payouts/payout';
import useBanking from 'state/banking/hooks/useBanking';
import { BANKING } from 'state/banking/type';
import styles from '../../styles.module.scss';
import AddVendor from './addVendor';

interface Iprops {
  dropdownConfig: DropdownConfig<BANKING.IVendor>;
  setDropdownConfig: Dispatch<SetStateAction<DropdownConfig<BANKING.IVendor>>>;
  setActiveState: Dispatch<SetStateAction<IActiveState>>;
  activeState: IActiveState;
}

const ListVendor = ({
  setActiveState,
  activeState,
  dropdownConfig,
  setDropdownConfig,
}: Iprops): JSX.Element => {
  const { banking, resetUpdateVendor, setVendorId } = useBanking();
  const { vendors } = banking;
  const [error, setError] = useState(false);

  useEffect(() => {
    if (dropdownConfig.isSelected) {
      setError(false);
    }
  }, [dropdownConfig.isSelected]);

  const backButton = () => {
    activeState.payoutStage = PAYOUT_STAGE.info;
    activeState.activeForm = undefined;
    activeState.config = null;
    setActiveState({ ...activeState });
    dropdownConfig.isSelected = false;
    dropdownConfig.addNewItem = false;
    dropdownConfig.updateItem = false;
    dropdownConfig.selectedItemData = undefined;
    dropdownConfig.selectedItemName = undefined;
    setDropdownConfig({ ...dropdownConfig });
    resetUpdateVendor();
  };

  const nextButton = (dropdownConfigArg: DropdownConfig<BANKING.IVendor>) => {
    if (dropdownConfigArg.isSelected && dropdownConfigArg.selectedItemData) {
      activeState.payoutStage = PAYOUT_STAGE.external;
      activeState.activeForm = EXTERNAL_PAYOUT_STAGES.INVOICE_DETAILS;
      PAYOUTS_NAV_CONFIG[EXTERNAL_PAYOUT_STAGES.VENDOR_DETAILS].isCompleted =
        true;
      setVendorId(dropdownConfigArg?.selectedItemData?.id);
      setActiveState({ ...activeState });
    } else {
      setError(true);
    }
  };

  const onSelect = (option: BANKING.IVendor | null) => {
    if (option === null) {
      dropdownConfig.isSelected = false;
      dropdownConfig.selectedItemName = undefined;
      dropdownConfig.selectedItemData = undefined;
      setDropdownConfig({ ...dropdownConfig });
    } else {
      dropdownConfig.selectedItemName = option?.name;
      dropdownConfig.isSelected = true;
      dropdownConfig.selectedItemData = option;
      setDropdownConfig({ ...dropdownConfig });
    }
  };

  useEffect(() => {
    const footerElement = (
      <>
        <Button
          // color="primary"
          className="btn btn-primary mr-2"
          type="submit"
          onClick={() => backButton()}
        >
          <span className="d-flex">Back</span>
        </Button>
        <Button
          color="primary"
          className="btn btn-primary"
          type="submit"
          onClick={() => nextButton(dropdownConfig)}
        >
          <span className="d-flex">
            Next <i className="ph-arrow-right pl-1"></i>
          </span>
        </Button>
      </>
    );
    activeState.footer = footerElement;
    setActiveState({ ...activeState });
  }, [dropdownConfig.isSelected]);

  const onEdit = () => {
    dropdownConfig.updateItem = true;
    setDropdownConfig({ ...dropdownConfig });
  };

  const getVendorValues = () => {
    return vendors?.vendors_list?.find(
      (item: BANKING.IVendor) => item.id === dropdownConfig.selectedItemData?.id
    );
  };

  const changeToAddVendorView = () => {
    dropdownConfig.addNewItem = true;
    dropdownConfig.selectedItemData = undefined;
    dropdownConfig.selectedItemName = undefined;
    dropdownConfig.isSelected = false;
    setDropdownConfig({ ...dropdownConfig });
  };

  return (
    <>
      <div>
        <FormGroup className="mb-0">
          <FormLabel
            isRequired
            element="vendor_name"
            label="Vendor Name"
          ></FormLabel>
        </FormGroup>
        <SelectDropdown
          buttonText="Create new vendor"
          placeHolder={'Forrest'}
          dropdownConfig={dropdownConfig}
          setDropdownConfig={setDropdownConfig}
          onSelect={onSelect}
          getOptionValue={(option: BANKING.IVendor) => option.id}
          getLabel={(option: BANKING.IVendor) => option.name}
          addScreen={changeToAddVendorView}
        />
        {error && (
          <ErrorMessage
            className="mb-0"
            isValidation
            message="Please select a vendor."
            preIcon={
              <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
            }
          />
        )}
        {dropdownConfig.isSelected && !error && (
          <div className={classNames('mt-0')}>
            <p className={classNames(styles.selectedText)}>
              {getVendorValues()?.email}
            </p>
            <p
              style={{ marginBottom: '12px' }}
              className={classNames(styles.selectedText)}
            >
              {getVendorValues()?.phone}
            </p>
            <div className={classNames(styles.editVendor)} onClick={onEdit}>
              Edit vendor
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ListVendor;
