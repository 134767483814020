import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { Label, LabelProps, UncontrolledTooltip } from 'reactstrap';
import styles from './form-label.module.scss';

interface IProps {
  label: string | JSX.Element;
  element: string; // htmlFor
  isRequired?: boolean;
  tooltip?: string;
  checked?: boolean;
  children?: ReactNode;
}

const FormLabel = ({
  label,
  isRequired = false,
  tooltip,
  element,
  inline,
  children,
  ...props
}: IProps & LabelProps): JSX.Element => {
  return (
    <Label
      className={classNames(
        'body-subtext-semibold',
        'pb-1',
        inline && 'd-inline'
      )}
      for={element}
      {...props}
    >
      {children}
      {label}
      {isRequired && <span className={classNames(styles.required)}>*</span>}
      {tooltip && (
        <>
          <span id={`${element}-tooltip`} className="pl-1">
            <i className="ph-info-fill icon-md text-muted" />
          </span>
          <UncontrolledTooltip placement="right" target={`${element}-tooltip`}>
            {tooltip}
          </UncontrolledTooltip>
        </>
      )}
    </Label>
  );
};
export default FormLabel;
