import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
// import OtpInput from 'react-otp-input';
import ErrorMessage from 'components/errorMessage';
import { Button } from 'reactstrap';
import useAuth from 'state/auth/hooks/useAuth';
import useBanking from 'state/banking/hooks/useBanking';
import STRING from 'utils/string';
import PayoutOptions from 'views/payout/payoutOptions';
import SelfPayout from 'views/payout/selfPayout';
import ExternalPayout from 'views/payout/externalPayouts';
import ToastContainer from 'components/toasts/container';
import { extractDigitValues, maskedData } from 'utils/pii';
import ModalContainer from '../../container';
import styles from '../styles.module.scss';
import {
  CREATE_PAYOUTS,
  EXTERNAL_PAYOUTS_NAV_CONFIG,
  EXTERNAL_PAYOUT_STAGES,
  Headings,
  IActiveState,
  PAYOUTTYPE,
  PAYOUT_STAGE,
  SELF_PAYOUTS_BANK_NAV_CONFIG,
  SELF_PAYOUTS_NAV_CONFIG,
  SELF_PAYOUT_BANK_STAGES,
  SELF_PAYOUT_STAGES,
  SubHeadings,
  clearCompletedStates,
  typeOfPayout,
} from './payout';
import Sidebar from './sidebar';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export type IPayoutConfig =
  | Record<
      SELF_PAYOUT_BANK_STAGES,
      {
        label: string;
        to: SELF_PAYOUT_BANK_STAGES;
      }
    >
  | Record<
      SELF_PAYOUT_STAGES,
      {
        label: string;
        to: SELF_PAYOUT_STAGES;
      }
    >
  | Record<
      EXTERNAL_PAYOUT_STAGES,
      {
        label: string;
        to: EXTERNAL_PAYOUT_STAGES;
      }
    >
  | null;

const PayoutInfo = (props: IProps): JSX.Element => {
  const { isOpen, onClose } = props;

  const initialActiveState: IActiveState = {
    footer: <></>,
    heading: Headings.info,
    subheading: SubHeadings.stage_1,
    config: null,
    activeForm: undefined,
    isCompleted: false,
    payoutStage: PAYOUT_STAGE.info,
  };

  const [activeState, setActiveState] =
    useState<IActiveState>(initialActiveState);

  const { banking, resetPayout, resetPayoutSuccess, setPayoutType } =
    useBanking();

  const { auth, resetOtp } = useAuth();

  const { external_account, stats, error, payout_amount } = banking;

  const [startPayout, setStartPayout] = useState(false);

  const externalPayoutConfig: IActiveState = {
    heading: Headings.external,
    subheading: SubHeadings.stage_3,
    payoutStage: PAYOUT_STAGE.external,
    config: EXTERNAL_PAYOUTS_NAV_CONFIG,
    activeForm: EXTERNAL_PAYOUT_STAGES.VENDOR_DETAILS,
    isCompleted: false,
    footer: activeState.footer,
  };

  const selfPayoutConfig: IActiveState = {
    heading: Headings.self,
    subheading: SubHeadings.stage_2,
    payoutStage: PAYOUT_STAGE.self,
    config: SELF_PAYOUTS_NAV_CONFIG,
    activeForm: SELF_PAYOUT_STAGES.PAYOUT_DETAILS,
    isCompleted: false,
    footer: activeState.footer,
  };

  const selfPayoutBankConfig: IActiveState = {
    heading: Headings.self,
    subheading: SubHeadings.stage_2,
    payoutStage: PAYOUT_STAGE.self,
    config: SELF_PAYOUTS_BANK_NAV_CONFIG,
    activeForm: SELF_PAYOUT_BANK_STAGES.BANK_DETAILS,
    isCompleted: false,
    footer: activeState.footer,
  };

  const infoPayoutConfig: IActiveState = {
    heading: Headings.info,
    subheading: SubHeadings.stage_1,
    payoutStage: PAYOUT_STAGE.info,
    config: null,
    activeForm: undefined,
    isCompleted: false,
    footer: activeState.footer,
  };

  const setNavbarConfig = () => {
    if (activeState.payoutStage === PAYOUT_STAGE.external) {
      setPayoutType(PAYOUTTYPE.EXTERNAL);
      setActiveState({ ...externalPayoutConfig });
    }
    if (activeState.payoutStage === PAYOUT_STAGE.self) {
      setPayoutType(PAYOUTTYPE.SELF);
      if (external_account.receiver_account_details) {
        setActiveState({ ...selfPayoutConfig });
      } else {
        setActiveState({ ...selfPayoutBankConfig });
      }
    }
    if (activeState.payoutStage === PAYOUT_STAGE.info) {
      setPayoutType(undefined);
      setActiveState({ ...infoPayoutConfig });
    }
  };

  const onCloseModal = () => {
    setActiveState({ ...initialActiveState });
    clearCompletedStates();
    onClose();
  };

  const serverError =
    error?.addBankAccount ||
    error?.initiateSelfPayout ||
    error?.createVendor ||
    error?.addVendorAccount;

  const serverErrorMessage = STRING.capitalizeFirstLetter(
    error?.addBankAccount?.detail?.[0].msg ||
      error?.initiateSelfPayout?.detail?.[0].msg ||
      error?.createVendor?.detail?.[0].msg ||
      error?.addVendorAccount?.detail?.[0].msg
  );

  useEffect(() => {
    if (banking?.auth_session?.id) {
      onCloseModal();
    }
  }, [banking?.auth_session?.id]);

  return (
    <>
      <ModalContainer
        testId="modal-payout"
        isOpen={isOpen}
        // onClose={onClose}
        isFullHeight={false}
        header={false}
        size="lg"
        onClose={() => onCloseModal()}
        nopadding
        className={styles.payoutModal}
        footerClass
        footer={
          <div className="d-flex justify-content-between">
            <span style={{ paddingLeft: '264px' }}>
              {serverError && (
                <ErrorMessage
                  isValidation
                  message={
                    serverErrorMessage ||
                    'Something went wrong. Please try again!'
                  }
                  preIcon={
                    <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
                  }
                  testId="server-response"
                />
              )}
            </span>
            <span style={{ paddingLeft: '264px' }}></span>
            <span>{activeState.footer}</span>
          </div>
        }
      >
        <>
          <div className="row m-0 flex-nowrap h-100">
            <div className={classNames(styles.sidebar, 'col p-0 flex-grow-0')}>
              <div className={classNames(styles.sideNavbar)}>
                <span>
                  <p className={styles.heading}>{activeState.heading}</p>
                  <p className={styles.subheading}>{activeState.subheading}</p>
                </span>
              </div>
              <Sidebar
                activeState={activeState}
                setActiveState={setActiveState}
              />
            </div>
            <div className="col pt-0 px-0 mb-0" style={{ overflowY: 'scroll' }}>
              {activeState.payoutStage !== PAYOUT_STAGE.info && (
                <div className={classNames(styles.formInfoContainer)}>
                  <span className={styles.routerHeading}>
                    Payouts <i className="ph ph-caret-right icon-md mx-2"></i>
                  </span>
                  <span className={styles.routerPayout}>
                    {typeOfPayout(activeState.payoutStage)}
                  </span>
                </div>
              )}
              {/* TODO move this into a function */}
              {activeState.payoutStage === PAYOUT_STAGE.info && (
                <PayoutOptions
                  activeState={activeState}
                  setActiveState={setActiveState}
                  setNavbarConfig={setNavbarConfig}
                />
              )}
              {activeState.payoutStage === PAYOUT_STAGE.self && (
                <SelfPayout
                  activeState={activeState}
                  setActiveState={setActiveState}
                />
              )}
              {activeState.payoutStage === PAYOUT_STAGE.external && (
                <ExternalPayout
                  activeState={activeState}
                  setActiveState={setActiveState}
                />
              )}

              <Button
                data-testid="btn-close"
                onClick={() => onCloseModal()}
                className="btn-icon p-0 position-absolute top-6 right-5"
              >
                <i className="ph-x icon-xl" />
              </Button>
            </div>
          </div>
        </>
      </ModalContainer>
    </>
  );
};

export default PayoutInfo;
