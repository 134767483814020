import React, { useEffect, useState } from 'react';
import 'assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/swifter.scss';
import accessLocalStorage from 'services/localStorage';
import { TRACK } from 'services/analytics';
import Tracking from './tracking';
import AppRoutes from './routes';
import Spinner from './components/spinners/global';
import useAuth from 'state/auth/hooks/useAuth';
import useStore from 'state/store/hooks/useStore';
import APIErrorHandler from 'services/api/errorHandler';
import { BrowserRouter } from 'react-router-dom';

function App() {
  const [isLoaded, setIsLoaded] = useState(false);
  const { fetchTenant, auth, toLoginSSO, toSetSSOLogin } = useAuth();
  const { loggedIn } = auth;
  const { toFetchStoresList } = useStore();

  const appInit = async () => {
    const user = accessLocalStorage.getUser();

    if (user?.accessToken) {
      toSetSSOLogin();

      /**
       * For logged in user: fetch user profile, active stores list
       */
      await fetchTenant();
      await toFetchStoresList();
    }
    setIsLoaded(true);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const treez_code = urlParams.get('code') || undefined;
    if (treez_code && window.location.pathname === '/' && !auth.loggedIn) {
      toLoginSSO(treez_code);
    }
  }, [window.location]);

  useEffect(() => {
    if (loggedIn) {
      appInit();
    }
  }, [loggedIn]);

  useEffect(() => {
    TRACK.initialize();
    appInit();
  }, []);

  return (
    <BrowserRouter>
      <Tracking />
      <Spinner isLoading={!isLoaded} />
      <APIErrorHandler />
      {isLoaded ? <AppRoutes /> : <></>}
    </BrowserRouter>
  );
}

export default App;
