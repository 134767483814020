import React, { Dispatch, SetStateAction } from 'react';
import FormLabel from 'components/form-label';
import { FormGroup, Input } from 'reactstrap';
import classNames from 'classnames';
import styles from '../styles.module.scss';
interface IProps {
  disabled: boolean;
  inModal: boolean;
  setDeviceConfig?: Dispatch<SetStateAction<Record<string, string>>>;
  deviceConfig: Record<string, string>;
}
const SimpleDeviceConfig = ({
  disabled,
  inModal = false,
  setDeviceConfig,
  deviceConfig,
}: IProps) => {
  return (
    <div className={!inModal ? 'ml-4 pt-2' : ''}>
      <FormGroup className="mb-3">
        <FormLabel
          element="register_id"
          label="Register Id"
          className={styles.fieldLabel}
        />
        <Input
          name="register_id"
          id="register_id"
          className={classNames(inModal ? 'w-100' : styles.fieldWidth, 'mt-1')}
          disabled={disabled}
          defaultValue={deviceConfig.registerId}
          onChange={(e) => {
            if (inModal && setDeviceConfig) {
              deviceConfig.registerId = e.currentTarget.value;
              setDeviceConfig({ ...deviceConfig });
            }
          }}
        />
      </FormGroup>
    </div>
  );
};

export default SimpleDeviceConfig;
