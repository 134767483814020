import { PDFViewer } from '@react-pdf/renderer';
import Invoice, { IInvoice } from 'components/invoice';
import React, { Dispatch, SetStateAction } from 'react';
import ModalContainer from './container';

interface IProps {
  data: IInvoice;
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
}

const PreviewPdf = ({ modal, setModal, data }: IProps): JSX.Element => {
  const toggle = () => setModal(!modal);

  return (
    <div data-testid="modal">
      <ModalContainer
        data-testid="modal-container"
        isOpen={modal}
        onClose={toggle}
        isFullHeight={false}
        title="Preview PDF"
      >
        <PDFViewer className="WO" style={{ height: '90vh' }}>
          <Invoice data={data} />
        </PDFViewer>
      </ModalContainer>
    </div>
  );
};

export default PreviewPdf;
