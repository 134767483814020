import React, { useEffect } from 'react';
import { Card } from 'reactstrap';
import usePayment from 'state/payment/hooks/usePayment';
import useQuery from 'utils/hooks/useQuery';
import { useLocation } from 'react-router-dom';
import Sessions from './list';

const PaymentSessions = (): JSX.Element => {
  const location = useLocation();
  const { toResetPayment } = usePayment();
  const query = useQuery(location?.search);

  const dates = query.get('dates')?.split(',') || [];
  const dateFrom = dates.length > 0 ? dates[0].replace(' ', '+') : '';
  const dateTo = dates.length > 1 ? dates[1].replace(' ', '+') : '';
  const statusArray = query.get('status')?.split(',') || [];

  useEffect(() => {
    return () => {
      toResetPayment();
    };
  }, []);

  return (
    <>
      <Card className="card-profile border-0">
        <Sessions
          dateFrom={dateFrom}
          dateTo={dateTo}
          statusArray={statusArray}
          queryParams={location?.search}
        />
      </Card>
    </>
  );
};

export default PaymentSessions;
