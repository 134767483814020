import React, { useEffect, useState } from 'react';
import { Alert, Button, Input } from 'reactstrap';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import {
  ConfigurationInputType,
  PAYMENT_CONFIG,
} from 'state/paymentConfig/type';
import usePaymentConfig from 'state/paymentConfig/hooks/usePaymentConfig';
import { useForm } from 'react-hook-form';
import { PaymentSolutionTab } from '../../paymentConfig';
import ConfigInput from './configInput';
import ConfigPassword from './configPassword';
import { useDebounce } from '@react-hook/debounce';
import STRING from 'utils/string';
import ConfigSelect from './configSelect';
import ConfigToggle from './configToggle';

interface IProps {
  defaultProvider: PAYMENT_CONFIG.IProcessors | undefined;
  activeTab: PaymentSolutionTab;
  localTab: PaymentSolutionTab;
}

export const encryptedElementFinder = (
  list: PAYMENT_CONFIG.IProcessorConfiguration[],
  code: string
) => {
  return list?.find(
    (item) =>
      item.code === code && item.inputType === ConfigurationInputType.PASSWORD
  );
};
export type FieldConfig = {
  itemConfig: PAYMENT_CONFIG.IProcessorConfiguration;
  itemValue?: {
    id: string;
    value: string;
  };
};

export const findConfig = (
  list: PAYMENT_CONFIG.IProcessorConfiguration[],
  code: string
) => {
  return list?.find((item) => item.code === code);
};

const CardReaderInput = ({ defaultProvider, activeTab, localTab }: IProps) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    errors,
    getValues,
    formState,
  } = useForm();

  const {
    paymentConfig,
    updateGlobalCardReaderValues,
    saveGlobalCardReaderValues,
    setGlobalCardReaderDefault,
  } = usePaymentConfig();
  const { getCardReaderValues, paymentConfiguration, saveCardReader } =
    paymentConfig;

  const [formChange, setOnFormChange] = useDebounce<string | null>(null, 200);

  const [disabledSave, setDisabledSave] = useState(true);

  const [elementList, setElementList] = useState<Record<string, FieldConfig>>(
    {}
  );

  const saveDefaultValuesCall = (values: { [x: string]: string }) => {
    const name = defaultProvider?.processorName;
    return Object.keys(values).forEach((item: string) => {
      if (values[item] !== `%${item}%magic`) {
        const isSecret = elementList?.[item]?.itemConfig?.isSecret;
        const isRequired = elementList?.[item]?.itemConfig?.isRequired;
        const object = {
          id: elementList?.[item]?.itemValue?.id,
          name: `${name}_${item}`,
          category: null,
          code: item,
          value: values[item].toString(),
          isSecret: isSecret,
          isRequired: isRequired,
          processorId: defaultProvider?.processorId,
        };

        if (elementList?.[item]?.itemValue?.id) {
          updateGlobalCardReaderValues(object);
        } else {
          saveGlobalCardReaderValues(object);
        }
      }
    });
  };

  const elementCreator = (
    config: PAYMENT_CONFIG.IProcessorConfiguration,
    value?: string
  ) => {
    switch (config.inputType) {
      case ConfigurationInputType.TEXT:
        return (
          <ConfigInput
            config={config}
            register={register}
            value={value || ''}
          />
        );
      case ConfigurationInputType.PASSWORD:
        return (
          <ConfigPassword
            config={config}
            register={register}
            value={value || ''}
            getCardReaderValues={getCardReaderValues}
          />
        );
      case ConfigurationInputType.SELECT:
        return (
          <ConfigSelect
            config={config}
            register={register}
            value={value || ''}
          />
        );
      default:
        return null;
    }
  };

  const setToDefault = (values: PAYMENT_CONFIG.IGlobalCardReaderDefault) => {
    setGlobalCardReaderDefault(values);
  };

  const setToDefaultCall = () => {
    if (defaultProvider?.processorId) {
      const name = defaultProvider?.paymentMethod.includes('DEFAULT')
        ? defaultProvider?.paymentMethod
        : `DEFAULT_${defaultProvider?.paymentMethod}`;
      const defaultObj = {
        name: name,
        category: 'DEFAULT_PROCESSOR',
        processorId: defaultProvider?.processorId,
        code: name,
        value: defaultProvider?.processorName,
        isSecret: false,
        isRequired: true,
      };
      setToDefault(defaultObj);
    }
  };

  const toggleElementFinder = () => {
    const element = Object.keys(elementList).find(
      (item) =>
        elementList?.[item]?.itemConfig.inputType ===
        ConfigurationInputType.TOGGLE
    );

    if (element) {
      return (
        <ConfigToggle
          config={elementList?.[element]?.itemConfig}
          register={register}
          submitCall={saveDefaultValuesCall}
          defaultValue={elementList?.[element]?.itemValue?.value ?? 'false'}
        />
      );
    }
  };

  const getEmptyFieldCheck = () => {
    let isEmptyField = false;
    paymentConfiguration?.configurations?.forEach((item) => {
      if (
        item.isRequired &&
        (getValues(item.code) === null ||
          getValues(item.code) === undefined ||
          getValues(item.code) === '')
      ) {
        isEmptyField = true;
      }
    });
    return isEmptyField;
  };

  useEffect(() => {
    // to show the element in the UI
    if (
      paymentConfiguration?.configurations &&
      paymentConfiguration?.configurations.length > 0 &&
      activeTab === localTab
    ) {
      paymentConfiguration.configurations.forEach((item) => {
        elementList[item.code] = { itemConfig: item };
        setElementList({ ...elementList });
      });
    }
  }, [paymentConfiguration, defaultProvider]);

  useEffect(() => {
    if (getCardReaderValues?.items && activeTab === localTab) {
      getCardReaderValues?.items.forEach((item) => {
        const configItemValue = elementList[item.code];

        let value;
        // to set values from the API call
        if (
          !encryptedElementFinder(
            paymentConfiguration?.configurations,
            item.code
          )
        ) {
          value = item.value;
        } else {
          value = `%${item.code}%magic`;
        }
        elementList[item.code] = {
          itemConfig: {
            ...elementList[item.code]?.itemConfig,
            isSecret: configItemValue?.itemConfig?.isSecret ?? item?.isSecret,
            isRequired:
              configItemValue?.itemConfig.isRequired ?? item?.isRequired,
          },
          itemValue: {
            id: item.id,
            value: value,
          },
        };
        setElementList({ ...elementList });
      });
    }
  }, [getCardReaderValues, activeTab, paymentConfiguration]);

  useEffect(() => {
    setElementList({});
    reset();
  }, [activeTab, defaultProvider]);

  useEffect(() => {
    if (activeTab === localTab) {
      if (
        !defaultProvider ||
        Object.keys(errors)?.length > 0 ||
        getEmptyFieldCheck() ||
        Object.keys(formState?.dirtyFields).length === 0
      ) {
        setDisabledSave(true);
      } else {
        setDisabledSave(false);
      }
    }
  }, [errors, defaultProvider, activeTab, formChange]);

  useEffect(() => {
    if (saveCardReader) {
      setDisabledSave(true);
    }
  }, [saveCardReader]);

  return (
    <>
      <div>
        <span className={classNames(styles.heading)}>Global configuration</span>

        {localTab === activeTab && (
          <div>
            {Object.keys(elementList).length > 0 ? (
              <>
                {activeTab !== PaymentSolutionTab.ACH && (
                  <Alert
                    color="info"
                    isOpen
                    className={classNames(styles.alertInfoSupport, 'mt-3')}
                  >
                    <i className="ph-info-fill icon-xl mr-2"></i>
                    <div>
                      These defaults can be overridden for individual terminals
                      under Payment device configurations.
                    </div>
                  </Alert>
                )}
                <form
                  role="form"
                  onSubmit={handleSubmit(saveDefaultValuesCall)}
                  onChange={() => {
                    setOnFormChange(new Date().toString());
                  }}
                  className="mt-2"
                >
                  {Object.keys(elementList).map((item) => {
                    return elementCreator(
                      elementList[item].itemConfig,
                      elementList[item].itemValue?.value
                    );
                  })}
                  <span>
                    <Button
                      data-testid="save_default"
                      variant="text"
                      size="md"
                      className={classNames(
                        'd-inline-flex align-items-center m-0 mb-2 mr-3',
                        styles.saveDefault
                      )}
                      onClick={setToDefaultCall}
                      disabled={!defaultProvider}
                    >
                      Set to default
                    </Button>
                    <Button
                      data-testid="save_values"
                      color="primary"
                      size="md"
                      type="submit"
                      className={classNames(
                        'd-inline-flex align-items-center m-0 mb-2 ',
                        styles.saveDefault
                      )}
                      disabled={disabledSave}
                    >
                      Save
                    </Button>
                  </span>
                </form>
                {toggleElementFinder()}
              </>
            ) : (
              <Alert
                color="info"
                isOpen
                className={classNames(styles.alertInfoSupport, 'mt-3')}
              >
                <i className="ph-info-fill icon-xl mr-2"></i>
                <div>
                  Something went wrong. Please contact your TreezPay Account
                  Manager or
                  <a
                    className="ml-1"
                    target="_blank"
                    href="https://support.treez.io/hc/en-us/requests/new"
                  >
                    contact support{' '}
                  </a>
                  in case there are any queries.
                </div>
              </Alert>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default CardReaderInput;
