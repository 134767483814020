export const FETCH_SETTINGS = 'settings/FETCH_SETTINGS';
export const FETCH_SETTINGS_SUCCESS = 'settings/FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_FAILURE = 'settings/FETCH_SETTINGS_FAILURE';

export const ADD_SETTINGS = 'settings/ADD_SETTINGS';
export const ADD_SETTINGS_SUCCESS = 'settings/ADD_SETTINGS_SUCCESS';
export const ADD_SETTINGS_FAILURE = 'settings/ADD_SETTINGS_FAILURE';

export const UPDATE_LOGISTICS_SETTINGS = 'settings/UPDATE_LOGISTICS_SETTINGS';
export const UPDATE_LOGISTICS_SETTINGS_SUCCESS =
  'settings/UPDATE_LOGISTICS_SETTINGS_SUCCESS';
export const UPDATE_LOGISTICS_SETTINGS_FAILURE =
  'settings/UPDATE_LOGISTICS_SETTINGS_FAILURE';

export const FETCH_LOGISTICS_MAPPING = 'settings/FETCH_LOGISTICS_MAPPING';
export const FETCH_LOGISTICS_MAPPING_SUCCESS =
  'settings/FETCH_LOGISTICS_MAPPING_SUCCESS';
export const FETCH_LOGISTICS_MAPPING_FAILURE =
  'settings/FETCH_LOGISTICS_MAPPING_FAILURE';

export const ADD_LOGISTICS_MAPPING = 'settings/ADD_LOGISTICS_MAPPING';
export const ADD_LOGISTICS_MAPPING_SUCCESS =
  'settings/ADD_LOGISTICS_MAPPING_SUCCESS';
export const ADD_LOGISTICS_MAPPING_FAILURE =
  'settings/ADD_LOGISTICS_MAPPING_FAILURE';

export const UPDATE_LOGISTICS_CONFIG = 'settings/UPDATE_LOGISTICS_CONFIG';
export const UPDATE_LOGISTICS_CONFIG_SUCCESS =
  'settings/UPDATE_LOGISTICS_CONFIG_SUCCESS';
export const UPDATE_LOGISTICS_CONFIG_FAILURE =
  'settings/UPDATE_LOGISTICS_CONFIG_FAILURE';

export const RESET_LOGISTICS_UPDATE = 'settings/RESET_LOGISTICS_UPDATE';

export const updateLogistics = (): IAction => ({
  type: UPDATE_LOGISTICS_SETTINGS,
});

export const updateLogisticsSuccess = <T>(payload: T): IAction => ({
  type: UPDATE_LOGISTICS_SETTINGS_SUCCESS,
  payload,
});

export const updateLogisticsFailure = <T>(payload: T): IAction => ({
  type: UPDATE_LOGISTICS_SETTINGS_FAILURE,
  payload,
});

export const action = (type: string): IAction => ({
  type,
});

export const actionSuccess = <T>(type: string, payload: T): IAction => ({
  type: `${type}_SUCCESS`,
  payload,
});

export const actionFailure = <T>(type: string, payload: T): IAction => ({
  type: `${type}_FAILURE`,
  payload,
});
