import { INVITES } from 'components/rbac/type';
import { api, apiOrgLive, apiWithOrg } from 'services/api';
import { InviteAction } from 'views/settings/invites';
import { useStateValue } from '../..';
import {
  action,
  actionFailure,
  actionSuccess,
  ACT_INVITE,
  addUser,
  addUserFailure,
  addUserSuccess,
  DELETE_INVITE,
  EDIT_USER,
  fetchUsers,
  fetchUsersFailure,
  fetchUsersSuccess,
  FETCH_ACTIVE_ORG,
  FETCH_INVITES,
  FETCH_ORG_ROLE_ID,
  FETCH_ROLES,
  FETCH_USER_INVITES,
  REMOVE_ROLE,
  reset,
  resetActiveOrg,
  setActiveOrg,
  UPDATE_ROLE,
  UPDATE_ORG_SETTINGS,
} from '../actions';
import { ORGANISATION } from '../type';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useOrganisation = () => {
  const { state, dispatch } = useStateValue();
  const { organisation } = state;

  const toFetchUsers = async () => {
    dispatch(fetchUsers());
    try {
      const response = await api.get<{
        items: ORGANISATION.IInternalUser[];
      }>(`/internal_users`);
      dispatch(fetchUsersSuccess(response?.data.items));
    } catch (e) {
      dispatch(fetchUsersFailure(e));
    }
  };

  const toAddUser = async (data: ORGANISATION.IRoleRequest) => {
    dispatch(addUser());
    try {
      const response = await apiOrgLive.post(`/invites`, data);
      dispatch(addUserSuccess(response));
    } catch (e) {
      dispatch(
        addUserFailure((e as ORGANISATION.IFailure)?.response?.data?.detail)
      );
    }
  };

  const toEditUser = async (data: ORGANISATION.IRoleRequest) => {
    dispatch(action(EDIT_USER));
    try {
      const response = await apiOrgLive.put(`/invites`, data);
      dispatch(actionSuccess(EDIT_USER, response?.data));
    } catch (e) {
      dispatch(
        actionFailure(
          EDIT_USER,
          (e as ORGANISATION.IFailure)?.response?.data?.detail
        )
      );
    }
  };

  const toFetchRoles = async () => {
    dispatch(action(FETCH_ROLES));
    try {
      const response = await apiOrgLive.get<{ items: ORGANISATION.IRole[] }>(
        `/roles`
      );
      dispatch(actionSuccess(FETCH_ROLES, response?.data.items));
    } catch (e) {
      dispatch(actionFailure(FETCH_ROLES, e));
    }
  };

  const toRemoveRole = async (roleId: string) => {
    dispatch(action(REMOVE_ROLE));
    try {
      const response = await apiOrgLive.remove<{ items: ORGANISATION.IRole[] }>(
        `/roles/${roleId}`
      );
      dispatch(actionSuccess(REMOVE_ROLE, response?.data.items));
    } catch (e) {
      dispatch(actionFailure(REMOVE_ROLE, e));
    }
  };

  const toUpdateRole = async (memberId: string, role_id: string) => {
    dispatch(action(UPDATE_ROLE));
    try {
      const response = await apiOrgLive.put(`/roles/${memberId}`, {
        // data,
        role_id,
      });
      dispatch(actionSuccess(UPDATE_ROLE, response));
    } catch (e) {
      dispatch(
        actionFailure(
          UPDATE_ROLE,
          (e as ORGANISATION.IFailure)?.response?.data?.detail
        )
      );
    }
  };

  const toGetOrgRoleId = async () => {
    dispatch(action(FETCH_ORG_ROLE_ID));
    const url = `/org_roles`;
    try {
      const response = await apiWithOrg.get<{ items: ORGANISATION.IOrgRole[] }>(
        url
      );
      dispatch(actionSuccess(FETCH_ORG_ROLE_ID, response?.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_ORG_ROLE_ID, e));
    }
  };

  const toFetchUserInvites = async (status: Array<INVITES>) => {
    dispatch(action(FETCH_USER_INVITES));
    const url = `/invites?status=${status.join('&status=')}`;
    try {
      const response = await api.get<{ items: ORGANISATION.IInvite[] }>(url);
      dispatch(actionSuccess(FETCH_USER_INVITES, response?.data.items));
    } catch (e) {
      dispatch(actionFailure(FETCH_USER_INVITES, e));
    }
  };

  const toFetchInvites = async (status: Array<INVITES> = []) => {
    dispatch(action(FETCH_INVITES));
    const url = `/invites?status=${status.join('&status=')}`;
    try {
      const response = await apiOrgLive.get<{ items: ORGANISATION.IInvite[] }>(
        url
      );
      dispatch(actionSuccess(FETCH_INVITES, response?.data.items));
    } catch (e) {
      dispatch(actionFailure(FETCH_INVITES, e));
    }
  };

  const toRemoveInvite = async (inviteId: string) => {
    dispatch(action(DELETE_INVITE));
    try {
      const response = await apiOrgLive.remove<{
        items: ORGANISATION.IInvite[];
      }>(`/invites/${inviteId}`);
      dispatch(actionSuccess(DELETE_INVITE, response?.data.items));
    } catch (e) {
      dispatch(actionFailure(DELETE_INVITE, e));
    }
  };

  const toActOnInvite = async (
    inviteId: string,
    inviteAction: InviteAction
  ) => {
    dispatch(action(ACT_INVITE));
    try {
      const response = await api.put<{ items: ORGANISATION.IInvite[] }>(
        `/invites/${inviteId}/${inviteAction}`
      );
      dispatch(actionSuccess(ACT_INVITE, response));
    } catch (e) {
      dispatch(actionFailure(ACT_INVITE, e));
    }
  };

  const toUpdateOrgSettings = async (request: Record<string, unknown>) => {
    dispatch(action(UPDATE_ORG_SETTINGS));
    try {
      const response = await apiWithOrg.put(`/organizations/settings`, request);
      dispatch(actionSuccess(UPDATE_ORG_SETTINGS, response));
    } catch (e) {
      dispatch(actionFailure(UPDATE_ORG_SETTINGS, e));
    }
  };

  /**
   * Fetches the active organization entity
   * @param {string} organisationId - active org ID
   */
  const toFetchActiveOrg = async () => {
    dispatch(action(FETCH_ACTIVE_ORG));
    try {
      const response = await apiOrgLive.get<ORGANISATION.IOrg>(
        `/organizations`
      );
      dispatch(actionSuccess(FETCH_ACTIVE_ORG, response?.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_ACTIVE_ORG, e));
    }
  };

  const toResetOrganisation = () => {
    dispatch(reset());
  };

  const toResetActiveOrg = () => {
    dispatch(resetActiveOrg());
  };

  return {
    organisation,
    toAddUser,
    toEditUser,
    toFetchUsers,
    toFetchInvites,
    toFetchUserInvites,
    toRemoveInvite,
    toFetchRoles,
    toUpdateRole,
    toRemoveRole,
    toActOnInvite,
    toFetchActiveOrg,
    toResetOrganisation,
    toUpdateOrgSettings,
    toResetActiveOrg,
    toGetOrgRoleId,
  };
};

export default useOrganisation;
