import React from 'react';

interface IProps {
  viewBox?: string;
  width?: string;
  height?: string;
}
const SuccessIcon = ({ width, height, viewBox }: IProps) => (
  <svg
    width={width || '60px'}
    height={height || '60px'}
    viewBox={viewBox || '0 0 32 32'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
      fill="#009963"
    />
    <path
      d="M21.5 13L14.1625 20L10.5 16.5"
      stroke="#009963"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
      stroke="#009963"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SuccessIcon;
