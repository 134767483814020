import FormLabel from 'components/form-label';
import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import styles from '../styles.module.scss';
import classNames from 'classnames';
import { UseFormMethods } from 'react-hook-form';
import { PAYMENT_CONFIG } from 'state/paymentConfig/type';

interface Iprops {
  config: PAYMENT_CONFIG.IProcessorConfiguration;
  register: UseFormMethods['register'];
  value: string;
}
const ConfigSelect = ({ config, register, value }: Iprops) => {
  return (
    <>
      <FormGroup className="mb-3 mr-3">
        <FormLabel
          element={config.code}
          label={config.displayName}
          className={styles.fieldLabel}
        />
        <Input
          type="select"
          name={config.code}
          id={`config-${config.code}`}
          innerRef={register({
            required: config.isRequired,
          })}
          className={classNames(styles.inputWidth, 'py-2')}
          defaultValue={value || 'NONE'}
        >
          {config?.selectOptions?.map((item) => (
            <option value={item.value}>{item.name}</option>
          ))}
        </Input>
      </FormGroup>
    </>
  );
};

export default ConfigSelect;
