import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button, Tooltip } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ModalContainer from 'components/modals/container';
import { PAYMENTLINKS } from 'state/paymentLinks/type';
import BannerContainer, { BannerType } from 'components/banner';
import styles from './addLinks.module.scss';

interface IProps {
  showModal: boolean;
  paymentLink: string;
  onClose: () => void;
  sendSMS: (arg: string) => void;
  sendEmail: (arg: string) => void;
  paymentLinkID: string;
  errors?: PAYMENTLINKS.Error;
  disableEmail: boolean;
  disableSMS: boolean;
}

const CreatePLSuccess = ({
  showModal,
  paymentLink,
  onClose,
  sendSMS,
  sendEmail,
  paymentLinkID,
  errors,
  disableEmail,
  disableSMS,
}: IProps) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  const serverError = errors?.sendEmail || errors?.sendSMS;

  const hasError = () => {
    return (
      <div className="mb-5">
        <BannerContainer
          heading={'An unexpected error occured'}
          message={
            'We are unable to send the payment link at the moment. Please close and try again.'
          }
          showBanner
          type={BannerType.error}
          listOfError={false}
          fixedHeight={false}
        />
      </div>
    );
  };

  const hasSuccess = () => {
    return (
      <div className={classNames('mb-7 d-flex', styles.createPLSuccessHeader)}>
        <i className="ph-check-circle text-success"></i>
        <div className="ml-3">
          <h1 className="mb-2">Payment link created</h1>
          <h2>
            Payment link created successfully. Copy the link below to share it
            instantly.
          </h2>
        </div>
      </div>
    );
  };

  return (
    <ModalContainer
      isOpen={showModal}
      onClose={onClose}
      footerClass
      center
      footer={
        <>
          <div className="d-flex justify-content-between">
            <span className="ml-auto">
              <Button
                disabled={disableEmail}
                color="secondary"
                onClick={() => sendEmail(paymentLinkID)}
                className="mr-2"
              >
                Send via Email
              </Button>
              <Button
                disabled={disableSMS}
                color="primary"
                onClick={() => sendSMS(paymentLinkID)}
              >
                Send via SMS
              </Button>
            </span>
          </div>
        </>
      }
      header={false}
      isFullHeight={false}
      className={styles.createPLSuccessModal}
    >
      <>
        <span className="d-table ml-auto">
          <i
            className="ph-x-fill icon-xl"
            style={{ cursor: 'pointer' }}
            onClick={onClose}
          ></i>
        </span>
        {serverError ? hasError() : hasSuccess()}
        <div className={styles.createPLShareLink}>
          <h2 className="mb-1">Share link</h2>
          <div className="d-flex">
            <div className={classNames('d-flex', styles.copyPaymentLink)}>
              <div>{paymentLink}</div>
            </div>

            <CopyToClipboard text={paymentLink}>
              <Button
                id="copy-payment-link"
                onClick={() => {
                  setCopied(true);
                }}
                aria-describedby={'tooltipCopy'}
                aria-hidden
                role="tooltip"
                className={classNames(styles.copyButton)}
              >
                <i
                  className={classNames(
                    styles.copyBtn,
                    'ph-copy-simple-light mr-1 icon-xl align-middle'
                  )}
                />
                Copy
              </Button>
            </CopyToClipboard>
            <Tooltip
              isOpen={copied}
              placement="bottom"
              target="copy-payment-link"
              className="mt-1"
            >
              Copied!
            </Tooltip>
          </div>
        </div>
      </>
    </ModalContainer>
  );
};

export default CreatePLSuccess;
