export enum ls {
  SANDBOX = 'sandboxMode',
  ORG_ID = 'org',
  TENANT_ID = 'tenant_id',
  USER = 'user',
  ENTITY_ID = 'treez_entity_id',
}

const accessLocalStorage = {
  getOrgId: (): string => localStorage.getItem(ls.ORG_ID) || '',
  setOrgId: (orgId: string): void => {
    try {
      localStorage.setItem(ls.ORG_ID, orgId);
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  },
  setEntityId: (entity_id: string) => {
    try {
      localStorage.setItem(ls.ENTITY_ID, entity_id);
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  },

  getSandboxMode: (): boolean => localStorage.getItem(ls.SANDBOX) === 'true',
  setSandboxMode: (mode: boolean): void =>
    localStorage.setItem(ls.SANDBOX, mode.toString()),

  getUser: () => JSON.parse(localStorage.getItem(ls.USER) ?? '{}'),
  setUser: (user: Record<string, unknown>): void =>
    localStorage.setItem(ls.USER, JSON.stringify(user)),

  getTenantId: (): string | null => localStorage.getItem(ls.TENANT_ID),
  setTenantId: (tenantId: string): void =>
    localStorage.setItem(ls.TENANT_ID, tenantId),

  clear: (): void => localStorage.clear(),
};

export default accessLocalStorage;
