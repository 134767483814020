import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import classNames from 'classnames';
import 'react-datetime/css/react-datetime.css';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { PAYMENTLINKS } from 'state/paymentLinks/type';
import usePaymentLinks from 'state/paymentLinks/hooks/usePaymentLinks';
import LogisticsOrderID from './logistics-order-id';
import CustomerDetails from './customer-details';
import styles from './addLinks.module.scss';

interface IProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  prefillData?: PAYMENTLINKS.ICreateDuplicatePaymentLink;
  onSuccess?: (paymentLink: string, first_name: string) => void;
}

const AddPaymentLink = ({
  showModal,
  setShowModal,
  prefillData,
  onSuccess,
}: IProps): JSX.Element => {
  const { paymentLinks, resetVerification } = usePaymentLinks();

  enum TABS {
    CUSTOMER_DETAILS = 'Customer Details',
    LOGISTICS_ORDER_ID = 'Logistics Order ID',
  }

  const PAYMENT_LINK_MODAL_TABS = [
    {
      label: TABS.LOGISTICS_ORDER_ID,
      filters: [],
    },
    {
      label: TABS.CUSTOMER_DETAILS,
      filters: [],
    },
  ];

  const tabNames = PAYMENT_LINK_MODAL_TABS;
  const [activeTab, setActiveTab] = useState(tabNames[1].label);

  const toExitModal = () => {
    setShowModal(false);
    resetVerification();
    // resetCreatePaymentLink();
  };

  useEffect(() => {
    if (
      !paymentLinks.isLoading &&
      paymentLinks.createPaymentLink?.invoice_type &&
      !paymentLinks.error.createPaymentLink
    ) {
      if (onSuccess)
        onSuccess(
          paymentLinks.createPaymentLink?.payment_link_url,
          paymentLinks.createPaymentLink.customer_json.first_name
        );
      toExitModal();
    } else if (
      paymentLinks?.error?.createPaymentLink?.message ===
      'role_based_permission_denied'
    ) {
      toExitModal();
    }
  }, [paymentLinks.createPaymentLink, paymentLinks.error]);

  return (
    <Modal
      isOpen={showModal}
      data-testid={'addPaymentTest'}
      className={styles.paymentLinkModal}
    >
      <ModalHeader toggle={() => toExitModal()}>
        Create Payment Link
      </ModalHeader>
      <ModalBody className="p-0">
        <Nav tabs className={styles.modalNavTabs}>
          {tabNames.map((item) => {
            return (
              <>
                <NavItem>
                  <NavLink
                    className={classNames({
                      active: item?.label === activeTab,
                    })}
                    onClick={() => {
                      setActiveTab(item?.label);
                    }}
                  >
                    {item?.label}
                  </NavLink>
                </NavItem>
              </>
            );
          })}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={TABS.CUSTOMER_DETAILS}>
            <CustomerDetails prefillData={prefillData} onClose={toExitModal} />
          </TabPane>
          <TabPane
            tabId={TABS.LOGISTICS_ORDER_ID}
            className={styles.logisticsOrderIdTab}
          >
            <LogisticsOrderID onClose={toExitModal} />
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  );
};

export default AddPaymentLink;
