import classNames from 'classnames';
import ErrorMessage from 'components/errorMessage';
import FormLabel from 'components/form-label';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import useBanking from 'state/banking/hooks/useBanking';
import { BANK_ROUTING_NUMBER, BANK_ACCOUNT_NUMBER } from 'utils/validation';
import ModalContainer from '../container';
import styles from './styles.module.scss';
import SuccessAddBank from './successAddBank';

interface IProps {
  openModal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
}
const AddBankAccount = (props: IProps): JSX.Element => {
  const { openModal, setModal } = props;

  const { register, errors, handleSubmit, setError, getValues } = useForm();
  const modalTitle = 'Add bank account';

  const {
    clearAddBankAccountError,
    addBankAccount,
    banking,
    toFetchExternalAccount,
  } = useBanking();

  const { add_bank_account } = banking;

  const [showSuccess, setShowSuccess] = useState(false);

  const addBankAccountWrapper = (values: { [x: string]: string }) => {
    addBankAccount({
      account_holder_name: values.account_holder_name,
      institution_name: values.institution_name,
      routing_number: values.routing_number.toString(),
      account_number: values.account_number.toString(),
      re_account_number: values.re_account_number.toString(),
    });
  };

  useEffect(() => {
    if (add_bank_account) {
      setModal(false);
      setShowSuccess(true);
      toFetchExternalAccount();
    }
  }, [add_bank_account]);

  const formSubmit = (values: { [x: string]: string }) => {
    addBankAccountWrapper(values);
  };
  const toggle = () => {
    setModal(false);
    clearAddBankAccountError();
  };

  useEffect(() => {
    if (
      Number(getValues('confirm_account')) &&
      !Number(getValues('account_number'))
    ) {
      setError('confirm_account', {
        type: 'validate',
        message: "The account numbers don't match",
      });
    }
  }, [
    Number(getValues('confirm_account')),
    Number(getValues('account_number')),
  ]);

  return (
    <div>
      <ModalContainer
        testId="add-bank-modal"
        isOpen={openModal}
        onClose={toggle}
        isFullHeight={false}
        title={modalTitle}
        footer={
          <div>
            <Button onClick={toggle} color="secondary">
              Cancel
            </Button>
            <Button
              color="primary"
              className={styles.addBank}
              onClick={handleSubmit(formSubmit)}
              type="submit"
            >
              <i className={classNames('ph-plus-bold icon-lg pr-1 ')} />
              Add bank account
            </Button>
            {banking.error.addBankAccount && (
              <ErrorMessage
                isValidation
                message={'Something went wrong. Please try again!'}
                preIcon={
                  <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
                }
                testId="api"
              />
            )}
          </div>
        }
      >
        <>
          <div className={styles.container}>
            <p className={styles.payoutInfo}>
              Add your bank to receive payouts. We&apos;ll send your USD payouts
              to this bank account.
            </p>
            <Form role="form" onSubmit={handleSubmit(formSubmit)}>
              <FormGroup>
                <FormLabel
                  element="account_holder_name"
                  label="Account holder name"
                  className={styles.fieldLabel}
                />
                <Input
                  name="account_holder_name"
                  id="account_holder_name"
                  innerRef={
                    register &&
                    register({
                      required: true,
                    })
                  }
                  className={
                    errors?.account_holder_name ? styles.borderAlert : ''
                  }
                />
                {errors?.account_holder_name && (
                  <ErrorMessage
                    isValidation
                    message="Please enter account holder name."
                    preIcon={
                      <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
                    }
                  />
                )}
              </FormGroup>
              <FormGroup>
                <FormLabel
                  element="institution_name"
                  label="Financial institution name"
                  className={styles.fieldLabel}
                />
                <Input
                  name="institution_name"
                  id="institution_name"
                  placeholder="XYZ Bank"
                  innerRef={
                    register &&
                    register({
                      required: true,
                    })
                  }
                  className={errors?.institution_name ? styles.borderAlert : ''}
                />
                {errors?.institution_name && (
                  <ErrorMessage
                    isValidation
                    message="Please enter a financial institution name."
                    preIcon={
                      <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
                    }
                  />
                )}
              </FormGroup>
              <FormGroup>
                <FormLabel
                  element="routing_number"
                  label="Bank routing number"
                  className={styles.fieldLabel}
                />
                <Input
                  type="number"
                  name="routing_number"
                  id="routing_number"
                  placeholder="623852453"
                  innerRef={
                    register &&
                    register({
                      required: {
                        value: true,
                        message: 'Please enter a bank routing number.',
                      },
                      pattern: {
                        value: BANK_ROUTING_NUMBER,
                        message: 'Please enter a valid bank routing number.',
                      },
                    })
                  }
                  className={errors?.routing_number ? styles.borderAlert : ''}
                />
                {errors?.routing_number && (
                  <ErrorMessage
                    testId="routing_number"
                    isValidation
                    message={errors?.routing_number.message}
                    preIcon={
                      <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
                    }
                  />
                )}
              </FormGroup>
              <FormGroup>
                <FormLabel
                  element="account_number"
                  label="Bank account number"
                  className={styles.fieldLabel}
                />
                <Input
                  type="number"
                  name="account_number"
                  placeholder="234152893"
                  id="account_number"
                  innerRef={
                    register &&
                    register({
                      required: {
                        value: true,
                        message: 'Please enter your account number.',
                      },
                      pattern: {
                        value: BANK_ACCOUNT_NUMBER,
                        message: 'Please enter a valid account number.',
                      },
                    })
                  }
                  className={errors?.account_number ? styles.borderAlert : ''}
                />
                {errors?.account_number && (
                  <ErrorMessage
                    testId="account_number"
                    isValidation
                    message={
                      errors?.account_number?.message ||
                      'Please enter your account number.'
                    }
                    preIcon={
                      <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
                    }
                  />
                )}
              </FormGroup>
              <FormGroup className="mb-1">
                <FormLabel
                  element="re_account_number"
                  label="Confirm account number"
                  className={styles.fieldLabel}
                />
                <Input
                  type="number"
                  name="re_account_number"
                  id="re_account_number"
                  innerRef={
                    register &&
                    register({
                      required: true,
                      validate: () =>
                        Number(getValues('account_number')) ===
                        Number(getValues('re_account_number')),
                    })
                  }
                  className={
                    errors?.re_account_number?.type === 'validate'
                      ? styles.borderAlert
                      : '' ||
                        (errors.re_account_number?.type === 'required' &&
                        !errors?.account_number
                          ? styles.borderAlert
                          : '')
                  }
                />
                {errors?.re_account_number &&
                  errors?.re_account_number.type === 'required' &&
                  !errors?.account_number && (
                    <ErrorMessage
                      isValidation
                      message={'Please confirm your account number.'}
                      preIcon={
                        <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
                      }
                    />
                  )}
                {errors?.re_account_number &&
                  errors?.re_account_number.type === 'required' &&
                  errors?.re_account_number && <></>}
                {errors?.re_account_number &&
                  errors?.re_account_number.type === 'validate' && (
                    <ErrorMessage
                      isValidation
                      message={"The account numbers don't match."}
                      preIcon={
                        <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
                      }
                    />
                  )}
              </FormGroup>
            </Form>
          </div>
        </>
      </ModalContainer>
      {add_bank_account && showSuccess && (
        <SuccessAddBank openModal={showSuccess} setModal={setShowSuccess} />
      )}
    </div>
  );
};

export default AddBankAccount;
