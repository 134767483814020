import FormLabel from 'components/form-label';
import React, { ChangeEvent, useState } from 'react';
import { FormGroup, Input } from 'reactstrap';
import styles from '../styles.module.scss';
import { UseFormMethods } from 'react-hook-form';
import classNames from 'classnames';
import { PAYMENT_CONFIG } from 'state/paymentConfig/type';

interface Iprops {
  config: PAYMENT_CONFIG.IProcessorConfiguration;
  register: UseFormMethods['register'];
  submitCall: (values: { [x: string]: string }) => void;
  defaultValue: string;
}
const ConfigToggle = ({
  config,
  register,
  submitCall,
  defaultValue,
}: Iprops) => {
  return (
    <>
      <FormGroup
        className={classNames(
          styles.width,
          'mb-3 mr-3 mt-8 pt-4 d-flex justify-content-between'
        )}
      >
        <span className="mb-7">
          <FormLabel
            element={config.code}
            label={config.displayName}
            className={styles.toggleHeading}
          />
          {config.description && (
            <p className={styles.toggleSubheading}>{config.description}</p>
          )}
          {config.helpText && (
            <p className={styles.toggleSubheading}>
              <span className="body-subtext-semibold">Note: </span>
              {config.helpText}
            </p>
          )}
        </span>
        <span>
          <label
            className="custom-toggle paylinks-toggle mt-1"
            htmlFor={config?.code}
          >
            <Input
              type="checkbox"
              name={config.code}
              id={`${config.code}`}
              innerRef={register({
                required: config?.isRequired,
              })}
              onClick={(e) =>
                submitCall({
                  [`${config.code}`]: e.currentTarget.checked.toString(),
                })
              }
              checked={defaultValue === 'true'}
              defaultChecked={defaultValue === 'true'}
            />
            <span className="custom-toggle-slider paylinks-toggle-slider"></span>
          </label>
        </span>
      </FormGroup>
    </>
  );
};

export default ConfigToggle;
