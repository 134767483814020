import { IFilterConfig, IFilterConfigList } from 'components/filter/types';
import { achStatusList, gatewayStatusList, statusList } from 'utils/constants';

/**
 * Checks if the given value is present in the array
 * Used to check if a filter list value is checked or not
 * @param arr - Array<string> - The array to check if the value is present in.
 * @param {string | string[]} value - The value to check if it's present in the array.
 * @returns Boolean
 */
export const isListItemPresent = (
  arr: Array<string>,
  value: string | string[]
) => {
  return Array.isArray(value)
    ? value.every((v) => arr.includes(v))
    : arr.includes(value);
};

/**
 * Update status options and values based on changes in payment type.
 * @param currFilter - Current filter configuration containing payment type and status data.
 * @returns Updated filter configuration with modified status options and values.
 */
export const updateStatusOnPaymentTypeChange = (
  currFilter: IFilterConfig[]
) => {
  /**
   * Filter status values based on required values.
   * @param status - List of status options with name and value.
   * @param currValues - Current selected status values.
   * @returns Filtered status values.
   */
  const filterValues = (
    status: {
      name: string;
      value: string | string[];
    }[],
    currValues: string[]
  ): string[] => {
    const requiredValues = status.reduce(
      (arr: string[], i) => arr.concat(i.value),
      []
    );
    return currValues.filter((i) => requiredValues.includes(i));
  };

  /**
   * Select status values from all status based on current values.
   * To include overlapping status values
   * @param status - List of status options with name and value.
   * @param currValues - Current selected status values.
   * @returns Selected status values.
   */
  const selectFromAllStatus = (
    status: {
      name: string;
      value: string | string[];
    }[],
    currValues: string[]
  ) => {
    return status?.reduce((selectArr: string[], statusItem) => {
      const statusValue = statusItem?.value;
      if (
        Array.isArray(statusValue) &&
        statusValue?.some((val) => currValues.includes(val))
      ) {
        return selectArr.concat(statusValue);
      } else if (currValues.includes(statusValue as string)) {
        return selectArr.concat(statusValue);
      } else {
        return selectArr;
      }
    }, []);
  };

  const paymentTypeValues = currFilter.find((i) => i.key === 'payment_type')
    ?.data?.values;
  const statusConfig = currFilter.find((i) => i.key === 'status')
    ?.data as IFilterConfigList;
  let statusValues = statusConfig?.values;
  let statusOptions = statusConfig?.options;

  if (paymentTypeValues?.length === 1 && paymentTypeValues?.includes('ach')) {
    // only ach status
    statusOptions = achStatusList;
    statusValues = filterValues(achStatusList, statusValues);
  } else if (
    paymentTypeValues &&
    paymentTypeValues?.length > 0 &&
    !paymentTypeValues?.includes('ach')
  ) {
    // only gateway
    statusOptions = gatewayStatusList;
    statusValues = filterValues(gatewayStatusList, statusValues);
  } else {
    // both
    statusOptions = statusList;
    statusValues = selectFromAllStatus(statusList, statusValues);
  }

  return currFilter.map((i) => {
    if (i.key === 'status')
      return {
        ...i,
        data: {
          key: 'status',
          options: statusOptions,
          values: statusValues,
        },
      };
    else return i;
  });
};
