import FormLabel from 'components/form-label';
import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import styles from '../styles.module.scss';
import { UseFormMethods } from 'react-hook-form';
import { PAYMENT_CONFIG } from 'state/paymentConfig/type';

interface Iprops {
  config: PAYMENT_CONFIG.IProcessorConfiguration;
  register: UseFormMethods['register'];
  value: string;
  getCardReaderValues: PAYMENT_CONFIG.IGetCardReaderList;
}
const ConfigPassword = ({
  config,
  register,
  value,
  getCardReaderValues,
}: Iprops) => {
  const dummyValueSetter = () => {
    const actualValue = getCardReaderValues?.items?.find(
      (item) => item.code === config.code
    )?.value;
    if (actualValue) {
      return `%${config.code}%magic`;
    } else {
      return '';
    }
  };

  return (
    <>
      <FormGroup className="mb-3 mr-3">
        <FormLabel
          element={config.code}
          label={config.displayName}
          className={styles.fieldLabel}
        />
        <Input
          name={config.code}
          id={`config-${config.code}`}
          type="password"
          innerRef={register({
            required: config.isRequired,
          })}
          className={styles.inputWidth}
          defaultValue={dummyValueSetter()}
        />
      </FormGroup>
    </>
  );
};

export default ConfigPassword;
