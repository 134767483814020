import React, { SyntheticEvent, useEffect, useState } from 'react';
import Filter from 'components/filter';
import ExportModal from 'components/modals/export';
import Pagination from 'components/pagination';
import Search from 'components/search';
import ExportBtn from 'components/modals/export/exportBtn';
import BootstrapTable, { SortOrder } from 'react-bootstrap-table-next';
import { updateStatusOnPaymentTypeChange } from 'utils/filter';
import { EFilterConfigType, IFilterConfig } from 'components/filter/types';
import STRING from 'utils/string';
import {
  paymentTypeList,
  statusList,
} from 'utils/constants';
import ToastContainer, { ToastType } from 'components/toasts/container';
import { PAYMENTS } from 'state/payment/type';
import CURRENCY from 'utils/currency';
import { formatToTz } from 'utils/date-format';
import {
  gatewatTransactionStatusFormatter,
  transactionStatusFormatter,
} from 'utils/status-formatter';
import usePayment from 'state/payment/hooks/usePayment';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import useRefunds from 'state/refunds/hooks/useRefunds';
import useQuery from 'utils/hooks/useQuery';
import { useNavigate } from 'react-router-dom';
import CashPaymentModal from 'views/failedTransactions/cashPaymentModal';

export enum TOAST_TYPE {
  CASH = 'CASH',
  RETRY = 'SINGLE_RETRY',
  CANCEL = 'CANCEL',
  FAILED_RETRY = 'FAILED_RETRY',
  FAILED_CASH = 'FAILED_CASH',
  FAILED_CANCEL = 'FAILED_CANCEL',
}

interface IProps {
  dateFrom: string;
  dateTo: string;
  triggerRefresh: boolean;
  isActiveTab: boolean;
}

const TransactionDetailsTab = ({
  dateFrom,
  dateTo,
  triggerRefresh,
  isActiveTab,
}: IProps) => {
  const urlParams = useQuery(location?.search);
  const amountOption = urlParams.get('condition') || 'equal';
  const firstAmount = urlParams.get('amount1') || undefined;
  const secondAmount = urlParams.get('amount2') || undefined;
  const statusArray = urlParams.get('status')?.split(',') || [];
  const paymentTypeArray = urlParams.get('payment_type')?.split(',') || [];

  const successRetryMessage = 'Retry initiated successfully';
  const successCashRecordedMessage = 'Cash payment recorded successfully';
  const successCancelMessage = 'Transaction cancelled';
  const [showModal, setShowModal] = useState(false);
  const [toastType, setToastType] = useState<TOAST_TYPE | null>(null);
  const [warningType, setWarningType] = useState<ToastType | undefined>(
    ToastType.success
  );
  // Next and prev key
  const [prevKey, setPrevKey] = useState<string | null>(null);
  const [nextKey, setNextKey] = useState<string | null>(null);
  const navigate = useNavigate();
  const [showCashPaymentModal, setShowCashPaymentModal] = useState(false);
  const [rowData, setRowData] = useState<any>();

  // Number of results
  const [numResults, setNumResults] = useState(0);

  const {
    payment,
    toFetchTransactions,
    toFetchCSVTransactions,
    toClearTransactionsExport,
    toCancelTransaction,
    isGatewayTransactionID,
    toResetPayment,
  } = usePayment();
  const { url, error } = payment;
  const { organisation } = useOrganisation();
  const { settings } = organisation;
  const { retryTransaction } = useRefunds();
  const searchString = urlParams.get('search') || undefined;

  useEffect(() => {
    if (payment.transactions?.items?.length > 0) {
      // setting next and prev key
      setNextKey(payment.transactions.next_key);
      setPrevKey(payment.transactions.prev_key);
      setNumResults(payment.transactions.total_count);
    } else {
      setNumResults(0);
    }
  }, [payment.transactions]);

  const handleOnCancel = async (row: any) => {
    const response = await toCancelTransaction(row.order_id);
    if (response) {
      setToastType(TOAST_TYPE.CANCEL);
    } else {
      setToastType(TOAST_TYPE.FAILED_CANCEL);
      setWarningType(ToastType.error);
    }
  };

  const getSubDetails = () => {
    if (
      toastType === TOAST_TYPE.FAILED_CANCEL ||
      toastType === TOAST_TYPE.FAILED_CASH ||
      toastType === TOAST_TYPE.FAILED_RETRY
    ) {
      return <p>We are unable to perform intended action. Please try again.</p>;
    }
    return <></>;
  };

  const getToastMessage = () => {
    if (toastType === TOAST_TYPE.CASH) return successCashRecordedMessage;
    if (toastType === TOAST_TYPE.RETRY) return successRetryMessage;
    if (toastType === TOAST_TYPE.CANCEL) return successCancelMessage;
    if (
      toastType === TOAST_TYPE.FAILED_CANCEL ||
      toastType === TOAST_TYPE.FAILED_CASH ||
      toastType === TOAST_TYPE.FAILED_RETRY
    )
      return 'An unexpected error occured';
    return '';
  };

  const getFetchAPIFilter = () => ({
    start_date: dateFrom,
    end_date: dateTo,
    amount_1: firstAmount,
    amount_2: secondAmount,
    condition: amountOption || undefined,
    status: statusArray.length > 0 ? statusArray.join(',') : undefined,
    payment_type:
      paymentTypeArray.length > 0 ? paymentTypeArray.join(',') : undefined,
    search_string: searchString,
  });

  // get to prev page
  const prevPage = () => {
    toFetchTransactions({
      key: prevKey,
      ...getFetchAPIFilter(),
    });
  };

  // get to next page
  const nextPage = () => {
    toFetchTransactions({
      key: nextKey,
      ...getFetchAPIFilter(),
    });
  };

  const columns = [
    {
      dataField: 'order_create_date',
      text: 'Date',
      sort: true,
      formatter: (cell: string, row: PAYMENTS.ITransactionResult) => {
        return (
          <>
            {cell || row?.create_date
              ? formatToTz(
                  cell || row.create_date,
                  settings?.timezone,
                  'MMM dd, hh:mm a'
                )
              : ''}
          </>
        );
      },
    },
    {
      dataField: 'charge_amount',
      text: 'Gross Amount',
      sort: true,
      formatter: (charge_amount: number, row: PAYMENTS.ITransactionResult) => {
        return (
          <div className="currencyColumn">
            {isGatewayTransactionID(row.id)
              ? CURRENCY.convertToMainUnit(
                  row.order_amount + row.tip_amount + row.order_fee_amount
                )
              : CURRENCY.convertToMainUnit(
                  charge_amount || row?.calculated_total
                )}
          </div>
        );
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: (_: string, row: PAYMENTS.ITransactionResult) => {
        let status = row?.transaction_status || row?.order_status;

        if (isGatewayTransactionID(row.id))
          return gatewatTransactionStatusFormatter(row?.gateway_status);

        if (
          status === 'inited' &&
          (String(row?.ach_debit_error_count) === '1' ||
            String(row?.ach_debit_error_count) === '2')
        ) {
          status = `${status} Retry ${String(row?.ach_debit_error_count)}`;
        }
        return transactionStatusFormatter(status);
      },
    },
    {
      dataField: 'method',
      text: 'Payment Type',
      formatter: (cell?: string) => {
        return <>{!cell ? 'ACH' : cell === 'ATM' ? 'CATM' : cell}</>;
      },
    },
    {
      dataField: 'track_id',
      text: 'Ticket id',
      formatter: (cell: string, row: PAYMENTS.ITransactionResult) => {
        const referenceNumberParts = STRING.getTreezTicketIDFromReferenceNumber(row.reference_number);
        return referenceNumberParts ?  <>{referenceNumberParts}</> : <>{row.track_id}</>;
      },
    },
    {
      dataField: 'customer_first_name',
      text: 'Customer',
      sort: true,
      formatter: (cell: string, row: PAYMENTS.ITransactionResult) => {
        // when consumer_profile_id is deleted
        // change name to [DELETED]
        if (!row.customer_first_name && !row.customer_last_name) {
          return <div className="text-capitalize">NA</div>;
        }
        if (
          row.customer_first_name === row.customer_last_name &&
          cell === row.customer_last_name
        ) {
          return <>{cell}</>;
        }
        return (
          <div className="text-capitalize">
            {cell} {row.customer_last_name}
          </div>
        );
      },
    },
  ];

  const amountValues = ['equal'];
  if (amountOption) {
    amountValues[0] = amountOption;
  }
  if (firstAmount) {
    amountValues[1] = firstAmount;
  }
  if (secondAmount) {
    amountValues[2] = secondAmount;
  }

  const getFilterConfig = () => {
    return [
      {
        key: 'amount',
        label: 'Amount',
        type: EFilterConfigType.number,
        data: {
          keys: ['condition'],
          values: amountValues,
        },
        selected: false,
      },
      {
        key: 'payment_type',
        label: 'Payment Type',
        type: EFilterConfigType.list,
        data: {
          keys: 'payment_type',
          options: paymentTypeList,
          values: paymentTypeArray,
        },
        selected: paymentTypeArray?.length > 0,
      },
      {
        key: 'status',
        label: 'Transaction status',
        type: EFilterConfigType.list,
        data: {
          key: 'status',
          options: statusList,
          values: statusArray,
        },
        selected: statusArray?.length > 0,
      },
    ];
  };

  const filterConfig: IFilterConfig[] = getFilterConfig();
  const dateFilterConfig: IFilterConfig = {
    key: 'dates',
    label: 'Create Date',
    type: EFilterConfigType.date,
    data: {
      values: [dateFrom, dateTo],
    },
    selected: false,
  };
  const exportFilterConfig = [dateFilterConfig].concat(filterConfig);

  const defaultSorted: [{ dataField: string; order: SortOrder }] = [
    {
      dataField: 'date',
      order: 'desc',
    },
  ];

  // Taking actions on row events
  const rowEvents = {
    onClick: (
      e: SyntheticEvent<Element, Event>,
      row: PAYMENTS.ITransactionResult
    ) => {
      if (isGatewayTransactionID(row.id)) navigate(row?.gateway_id || '');
      else navigate(row?.order_id || '');
    },
  };

  useEffect(() => {
    if (isActiveTab) {
      toFetchTransactions(getFetchAPIFilter());
    }
  }, [location.search, triggerRefresh, isActiveTab, dateFrom, dateTo]);

  useEffect(() => {
    // export state reset on unmount
    return () => {
      toResetPayment();
    };
  }, []);

  return (
    <>
      <div className="d-flex mb-3">
        <span className="mr-auto">
          <Search queryParams={location.search} />
        </span>

        {filterConfig && (
          <>
            <Filter
              config={updateStatusOnPaymentTypeChange(filterConfig)}
              queryParams={location.search}
              changeKey="payment_type"
              changeKeyUpdateHandlerCb={updateStatusOnPaymentTypeChange}
            ></Filter>
            <ExportBtn onClick={() => setShowModal(true)} id="tnx-export-btn" />
          </>
        )}
      </div>
      <BootstrapTable
        data-testid="list_transactions_table"
        id="list_transactions_table"
        wrapperClasses="table-responsive"
        keyField="_id"
        data={payment?.transactions?.items || []}
        columns={columns}
        defaultSorted={defaultSorted}
        rowEvents={rowEvents}
        hover
        condensed
        bordered={false}
        noDataIndication={
          <p className="text-center text-regular">No results found</p>
        }
      />
      <Pagination
        results={numResults}
        prevPage={prevPage}
        nextPage={nextPage}
        prevKey={prevKey}
        nextKey={nextKey}
      />
      {showModal && (
        <ExportModal
          showModal={showModal}
          setShowModal={setShowModal}
          config={updateStatusOnPaymentTypeChange(exportFilterConfig)}
          heading="Transaction Summary"
          onExport={toFetchCSVTransactions}
          reportUrl={url}
          label="Transactions"
          errorMessage={error.addUrl?.message ?? undefined}
          toClearExport={toClearTransactionsExport}
          changeKey="payment_type"
          changeKeyUpdateHandlerCb={updateStatusOnPaymentTypeChange}
        />
      )}
      {showCashPaymentModal && (
        <CashPaymentModal
          showModal={showCashPaymentModal}
          setShowModal={setShowCashPaymentModal}
          data={rowData}
          toShowToast={(arg) => {
            if (arg) {
              setToastType(TOAST_TYPE.CASH);
            } else {
              setToastType(TOAST_TYPE.FAILED_CASH);
              setWarningType(ToastType.error);
            }
          }}
        />
      )}
      <ToastContainer
        type={warningType}
        showToast={Boolean(toastType)}
        onClose={() => setToastType(null)}
        message={getToastMessage()}
        id={toastType?.toLowerCase() || ''}
        data-testid="cash-toast-success"
        details={getSubDetails()}
      />
    </>
  );
};

export default TransactionDetailsTab;