import classNames from 'classnames';
import Filter from 'components/filter';
import { EFilterConfigType, IFilterConfig } from 'components/filter/types';
import PageHeading from 'components/heading';
import Pagination from 'components/pagination';
import Search from 'components/search';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import BootstrapTable, { SortOrder } from 'react-bootstrap-table-next';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from 'reactstrap';
import useAuth from 'state/auth/hooks/useAuth';
import { EVENTS } from 'state/events/type';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import usePayment from 'state/payment/hooks/usePayment';
import { PAYMENTS } from 'state/payment/type';
import CURRENCY from 'utils/currency';
import useSearchParams from 'utils/hooks/useSearchParams';
import QUERY from 'utils/query';
import {
  STATUS_COMPLETED,
  STATUS_FINALIZED,
  STATUS_STARTED,
} from '../../utils/constants';
import { formatToTz } from '../../utils/date-format';
import { transactionStatusFormatter } from '../../utils/status-formatter';
import Sandbox from './sandbox';
import styles from './sessions.module.scss';

interface IProps {
  dateFrom?: string;
  dateTo?: string;
  statusArray?: string[];
  queryParams: string;
}

const Sessions = ({
  dateFrom,
  dateTo,
  statusArray = [],
  queryParams,
}: IProps): JSX.Element => {
  const heading = 'Sessions';
  const subHeading =
    'View all payment sessions started by your customers here.';
  const tabNames = [
    {
      label: 'All',
      filters: [],
    },
    {
      label: 'Completed',
      filters: [STATUS_FINALIZED],
      tooltip: 'Successful session that created an Order',
    },
    {
      label: 'Lost',
      filters: [STATUS_COMPLETED],
      tooltip: "Session completed by user, but Order wasn't captured",
    },
    {
      label: 'Dropped',
      filters: [STATUS_STARTED],
      tooltip: 'User dropped off without placing order',
    },
  ];

  const [activeTab, setActiveTab] = useState(
    (tabNames.length > 0 && tabNames[0].label) || ''
  );

  const dateValues: string[] = [];
  if (dateFrom) {
    dateValues[0] = dateFrom;
  }
  if (dateTo) {
    dateValues[1] = dateTo;
  }
  const [hasDemoModal, setHasDemoModal] = useState(false);
  const [searchString] = useSearchParams('search');

  const getFilterConfig = () => {
    return [
      {
        key: 'dates',
        label: 'Dates',
        type: EFilterConfigType.date,
        data: {
          keys: [],
          values: dateValues,
        },
        selected: false,
      },
      {
        key: 'status',
        label: 'Status',
        type: EFilterConfigType.list,
        data: {
          key: 'status',
          options: [
            { name: 'Dropped', value: STATUS_STARTED },
            { name: 'Lost', value: STATUS_COMPLETED },
            { name: 'Completed', value: STATUS_FINALIZED },
          ],
          values: statusArray,
        },
        selected: false,
      },
    ];
  };

  const defaultFilterConfig: IFilterConfig[] = getFilterConfig();

  // history object for routing purposes
  const navigate = useNavigate();
  const location = useLocation();

  const { payment, toFetchSessions } = usePayment();
  const { sessions } = payment;

  const { organisation } = useOrganisation();
  const { activeOrg, settings } = organisation;

  const { auth } = useAuth();
  const { isSandbox } = auth;

  const [filterConfig, setFilterConfig] =
    useState<IFilterConfig[]>(defaultFilterConfig);
  // Next and prev key
  const [prevKey, setPrevKey] = useState<string | null>(null);
  const [nextKey, setNextKey] = useState<string | null>(null);

  // Number of results
  const [numResults, setNumResults] = useState(0);

  useEffect(() => {
    if (sessions.items?.length > 0) {
      setFilterConfig(getFilterConfig());
    }
  }, [sessions]);

  // get to prev page
  const prevPage = () => {
    toFetchSessions({
      key: prevKey,
      start_date: dateFrom || undefined,
      end_date: dateTo || undefined,
      search_string: searchString,
      session_status:
        statusArray.length > 0 ? statusArray.join(',') : undefined,
    });
  };

  // get to next page
  const nextPage = () => {
    toFetchSessions({
      key: nextKey,
      start_date: dateFrom || undefined,
      end_date: dateTo || undefined,
      search_string: searchString,
      session_status:
        statusArray.length > 0 ? statusArray.join(',') : undefined,
    });
  };

  useEffect(() => {
    if (sessions?.items) {
      setNextKey(sessions.next_key);
      setPrevKey(sessions.prev_key);
      setNumResults(sessions.total_count);
    }
  }, [sessions]);

  const loadSessions = () => {
    const tab = tabNames.filter(
      (currentTab) =>
        currentTab.filters.join().replace(' ', '') ===
        statusArray.join().replace(' ', '')
    );
    if (tab.length > 0) {
      setActiveTab(tab[0].label);
    }
    toFetchSessions({
      start_date: dateFrom || undefined,
      end_date: dateTo || undefined,
      search_string: searchString,
      session_status:
        statusArray.length > 0 ? statusArray.join(',') : undefined,
    });
  };

  useEffect(() => {
    loadSessions();
  }, [activeOrg, queryParams]);

  const defaultSorted: [{ dataField: string; order: SortOrder }] = [
    {
      dataField: 'date',
      order: 'desc',
    },
  ];
  // Taking actions on row events
  const rowEvents = {
    onClick: (
      e: SyntheticEvent<Element, Event>,
      row: EVENTS.ISessionEvents
    ) => {
      navigate(row.id);
    },
  };

  const columns = [
    {
      dataField: 'create_date',
      text: 'Date',
      sort: true,
      formatter: (cell: string) => {
        return (
          <>
            {cell
              ? formatToTz(cell, settings?.timezone, 'MMM dd, hh:mm a')
              : ''}
          </>
        );
      },
    },
    {
      dataField: 'total_amount',
      text: 'Gross Amount',
      sort: true,
      formatter: (cell: number) => {
        return (
          <div className="currencyColumn">
            {CURRENCY.convertToMainUnit(cell)}
          </div>
        );
      },
      // headerAlign: 'right',
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: transactionStatusFormatter,
    },
    {
      dataField: 'first_name',
      text: 'Customer',
      sort: true,
      formatter: (cell: string, row: PAYMENTS.ISessionResult) => {
        return cell ? (
          row.consumer_profile_id ? (
            <>{`${cell} ${row.last_name}`}</>
          ) : (
            <>{`${cell} ${row.last_name}`}</>
          )
        ) : (
          'NA'
        );
      },
    },
    {
      dataField: 'track_number',
      text: 'Swifter ID',
      formatter: (cell: string) => {
        return <>{cell?.toUpperCase() || ''}</>;
      },
    },
    {
      dataField: 'id',
      text: 'Session ID',
      sort: true,
    },
  ];

  const applyStatusFilter = (statuses: string[]) => {
    const updatedFilters = filterConfig.map((item) => {
      if (item.key === 'status') {
        item.data.values = statuses;
        item.selected = true;
      }
      return item;
    });

    const query: {
      [key: string]: string;
    } = {};
    updatedFilters.forEach((item) => {
      const { key, data, selected, type } = item;
      if (selected) {
        if (type === EFilterConfigType.number) {
          const condition = data.values.shift();
          query.condition = condition || '';
        }
        query[key] = data.values.join();
      }
    });
    navigate({ search: QUERY.update(location.search, query) });
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <PageHeading heading={heading} subHeading={subHeading} />
        {isSandbox && (
          <div className="d-flex align-items-center">
            <Button
              color="primary"
              onClick={() => setHasDemoModal(true)}
              className={classNames(
                'd-flex align-items-center',
                styles.createDemoButton
              )}
            >
              <i className="ph-bounding-box mr-1" />
              Create Demo Session
            </Button>
          </div>
        )}
      </div>
      <div className={classNames(styles.tabContainer)}>
        <Nav tabs>
          {tabNames.map((item) => {
            return (
              <>
                <NavItem>
                  <NavLink
                    className={classNames({
                      active: item?.label === activeTab,
                    })}
                    onClick={() => {
                      applyStatusFilter(item.filters);
                      setActiveTab(item?.label);
                    }}
                  >
                    {item?.label}
                    {item?.tooltip && (
                      <>
                        <span id={`${item?.label}-tooltip`} className="pl-1">
                          <i className="ph-info-fill icon-md text-muted" />
                        </span>
                        <UncontrolledTooltip
                          placement="right"
                          target={`${item?.label}-tooltip`}
                        >
                          {item?.tooltip}
                        </UncontrolledTooltip>
                      </>
                    )}
                  </NavLink>
                </NavItem>
              </>
            );
          })}
        </Nav>
        <TabContent activeTab={activeTab}>
          <div className="d-flex mb-2">
            <span className="mr-auto">
              <Search queryParams={queryParams} />
            </span>
            <span>
              {filterConfig && (
                <Filter
                  config={filterConfig}
                  queryParams={queryParams}
                ></Filter>
              )}
            </span>
          </div>
          {tabNames.map((item) => {
            return (
              <TabPane tabId={item?.label} key={item.label}>
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  keyField="_id"
                  data={sessions?.items || []}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  rowEvents={rowEvents}
                  hover
                  condensed
                  bordered={false}
                  noDataIndication={
                    <p className="text-center text-regular">No results found</p>
                  }
                />
              </TabPane>
            );
          })}
        </TabContent>
      </div>

      <Pagination
        results={numResults}
        prevPage={prevPage}
        nextPage={nextPage}
        prevKey={prevKey}
        nextKey={nextKey}
      />
      <Sandbox
        isOpen={hasDemoModal}
        toggle={setHasDemoModal}
        onClose={loadSessions}
      ></Sandbox>
    </>
  );
};

export default Sessions;
