import classNames from 'classnames';
import Pagination from 'components/pagination';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import BootstrapTable, { SortOrder } from 'react-bootstrap-table-next';
import { useNavigate } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import routeConfig from 'routes/config';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import usePayment from 'state/payment/hooks/usePayment';
import { PAYMENTS } from 'state/payment/type';
import { CHARGES_TABLE_COLUMNS, STATUS_FINALIZED } from 'utils/constants';
import CURRENCY from 'utils/currency';
import { formatToTz } from 'utils/date-format';
import { transactionStatusFormatter } from 'utils/status-formatter';
import styles from '../customers.module.scss';

interface IProps {
  customerProfileId?: string;
}

/* Sessions tab content on Customer Details page */
const CustomerDetailsSessions = ({
  customerProfileId,
}: IProps): JSX.Element => {
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const [prevKey, setPrevKey] = useState<string | null>(null);
  const [nextKey, setNextKey] = useState<string | null>(null);
  const [data, setData] = useState<PAYMENTS.IChargeItems[]>([]);

  const { payment, toFetchCharges } = usePayment();
  const { charges } = payment;
  const { organisation } = useOrganisation();
  const { settings, activeOrgId } = organisation;

  useEffect(() => {
    toFetchCharges({
      consumer_profile_id: customerProfileId,
    });
  }, []);

  useEffect(() => {
    if (charges?.items) {
      setData(charges.items);
      setPrevKey(charges?.prev_key);
      setNextKey(charges?.next_key);
    }
  }, [charges]);

  const filterData = (filters: string[]) => {
    if (filters.length > 0) {
      const filtered = charges?.items?.filter((charge: PAYMENTS.IChargeItems) =>
        filters.includes(charge.status)
      );
      setData(filtered);
    } else {
      setData(charges?.items);
    }
  };

  const columns = [
    {
      dataField: 'create_date',
      text: 'Date',
      sort: true,
      formatter: (cell: string) => {
        return (
          <>
            {cell
              ? formatToTz(cell, settings?.timezone, 'MMM dd, hh:mm a')
              : ''}
          </>
        );
      },
    },
    {
      dataField: 'total_amount',
      text: 'Amount',
      sort: true,
      formatter: (cell: number) => {
        return (
          <div className="currencyColumn">
            {CURRENCY.convertToMainUnit(cell)}
          </div>
        );
      },
      // // headerAlign: 'right',
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: transactionStatusFormatter,
    },
    {
      dataField: 'track_number',
      text: 'Reference ID',
      sort: true,
      formatter: (cell: string) => {
        return <>{cell !== undefined ? cell.toUpperCase() : ''}</>;
      },
    },
    {
      dataField: 'id',
      text: 'Session ID',
      sort: true,
    },
  ];

  const defaultSorted: [{ dataField: string; order: SortOrder }] = [
    {
      dataField: 'create_date',
      order: 'desc',
    },
  ];

  const prevPage = () => {
    if (prevKey) {
      toFetchCharges({
        prev_key: prevKey,
        consumer_profile_id: customerProfileId,
      });
    }
  };

  const nextPage = () => {
    if (nextKey) {
      toFetchCharges({
        next_key: nextKey,
        consumer_profile_id: customerProfileId,
      });
    }
  };

  const rowEvents = {
    onClick: (
      e: SyntheticEvent<Element, Event>,
      row: PAYMENTS.IChargeItems
    ) => {
      if (row.status === STATUS_FINALIZED) {
        navigate(
          `/${activeOrgId}/${routeConfig.PAYMENTS.layout}/${routeConfig.PAYMENTS.transactions.path}/${row.order_id}`
        );
      } else {
        navigate(
          `/${activeOrgId}/${routeConfig.DEVELOPERS.layout}/${routeConfig.DEVELOPERS.SESSIONS.path}/${row.id}`
        );
      }
    },
  };

  return (
    <>
      <div className={classNames(styles.sessions_txn_tab, 'pb-7')}>
        <h3 className={classNames(styles.customer_details_heading)}>
          Sessions
        </h3>
        <Nav tabs className="mb-5">
          {CHARGES_TABLE_COLUMNS.map((item, index) => {
            return (
              <>
                <NavItem>
                  <NavLink
                    className={classNames({
                      active: index === activeTab,
                    })}
                    onClick={() => {
                      setActiveTab(index);
                      filterData(item.filters);
                    }}
                  >
                    {item?.label}
                  </NavLink>
                </NavItem>
              </>
            );
          })}
        </Nav>
        <TabContent activeTab={activeTab}>
          {CHARGES_TABLE_COLUMNS.map((item, index) => {
            return (
              <TabPane tabId={index}>
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  keyField="_id"
                  data={data}
                  columns={columns}
                  rowEvents={rowEvents}
                  defaultSorted={defaultSorted}
                  condensed
                  hover
                  bordered={false}
                  noDataIndication={
                    <p className="text-center text-regular">No results found</p>
                  }
                />
              </TabPane>
            );
          })}
        </TabContent>
        <Pagination
          results={data?.length}
          prevPage={prevPage}
          nextPage={nextPage}
          prevKey={prevKey}
          nextKey={nextKey}
        />
      </div>
    </>
  );
};

export default CustomerDetailsSessions;
