import React from 'react';
import classNames from 'classnames';
import styles from './summary.module.scss';

const NoSummaryFound = () => {
  return (
    <div
      className={classNames(
        'd-flex flex-col align-items-center justify-content-center border-0',
        styles.noSummaryFound
      )}
    >
      <h3 className="body-semibold">No summary found</h3>
    </div>
  );
};

export default NoSummaryFound;
