import React, { useState } from 'react';
import { formatToTz } from 'utils/date-format';
import classNames from 'classnames';
import { PAYMENTS } from 'state/payment/type';
import { Link } from 'react-router-dom';
import routeConfig from 'routes/config';
import { transactionSourceFormatter } from 'utils/source-formatter';
import BackButton from 'components/backButton';
import AmountDetails from 'components/amount-details';
import CopyIDToClipboard from 'components/copyToClipboard';
import ToastContainer from 'components/toasts/container';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import { transactionStatusFormatter } from '../../../../utils/status-formatter';
import RefundActionsComponent from './refundActions';

interface IProps {
  info: PAYMENTS.IIndividualTransactionResult;
}
const Summary = ({
  info = {} as PAYMENTS.IIndividualTransactionResult,
}: IProps): JSX.Element => {
  const [showToast, setShowToast] = useState<boolean>(false);
  const { organisation } = useOrganisation();
  const { settings } = organisation;

  const [otpString, setOtpString] = useState<string>('');

  const toastMessage = 'Refund initiated successfully';

  return (
    <>
      <BackButton />
      <AmountDetails
        amount={info.charge_amount || info.calculated_total}
        unit="USD"
        statusBadge={transactionStatusFormatter(
          info.transaction_status || info.order_status
        )}
      />
      <div className="d-flex align-items-center">
        <span className="mr-auto">
          <CopyIDToClipboard id={info.charge_id || info.order_id || 'NA'} />
        </span>
        <RefundActionsComponent
          chargeId={info.charge_id}
          orderId={info.order_id}
          transaction_status={info.transaction_status}
          amount={info.charge_amount || info.calculated_total}
          payment_type={info.payment_type}
          is_retryable={info.is_retryable}
        />
      </div>
      <ToastContainer
        showToast={showToast}
        onClose={() => setShowToast(false)}
        message={toastMessage}
      />
      <hr className="my-0" />

      <div className="d-flex align-items-center mb-9 pt-2 mt-1">
        <p className={classNames('SettlementSummaryPayment', 'pr-5')}>
          <div className={classNames('SettlementSummaryHeading')}>Date</div>
          <div className={classNames('SettlementHeadingData')}>
            {info.order_create_date
              ? formatToTz(
                  info.order_create_date,
                  settings?.timezone,
                  'MMM dd, hh:mm a'
                )
              : 'NA'}
          </div>
        </p>
        <p className={classNames('SettlementSummaryPayment', 'pl-5', 'pr-5')}>
          <div className={classNames('SettlementSummaryHeading', 'pr-5')}>
            Customer
          </div>
          <div className={classNames('SettlementHeadingData')}>
            {
              // condition is when consumer_profile_id is deleted
              !info.consumer_profile_id ? (
                info.customer_first_name
              ) : (
                <Link
                  to={`../../${routeConfig.CUSTOMERS.layout}/${info.consumer_profile_id}`}
                >
                  {info.customer_first_name} {info.customer_last_name}
                </Link>
              )
            }
          </div>
        </p>
        <p className={classNames('SettlementSummaryPayment', 'pl-5', 'pr-5')}>
          <div className={classNames('SettlementSummaryHeading')}>
            Payment method
          </div>
          <div className={classNames('SettlementHeadingData')}>
            {info.payment_type}
          </div>
        </p>

        <p
          className={classNames(
            info.return_date && 'SettlementSummaryPayment',
            'pl-5',
            'pr-5'
          )}
        >
          <div className={classNames('SettlementSummaryHeading')}>Source</div>
          <div className={classNames('SettlementHeadingData')}>
            {transactionSourceFormatter(info.source)}
          </div>
        </p>
        {info.return_date && (
          <p className={classNames('SettlementSummaryPayment', 'pl-5', 'pr-5')}>
            <div className={classNames('SettlementSummaryHeading')}>
              Return Date
            </div>
            <div className={classNames('SettlementHeadingData')}>
              {info.return_date
                ? formatToTz(
                    info.return_date,
                    settings?.timezone,
                    'MMM dd, hh:mm a'
                  )
                : 'NA'}
            </div>
          </p>
        )}
      </div>
    </>
  );
};

export default Summary;
