import React from 'react';
import classNames from 'classnames';
import { PAYMENTS } from 'state/payment/type';
import styles from '../../transactions.module.scss';

interface props {
  info: PAYMENTS.IGatewayTransaction;
}

const GatewayPaymentMethod = ({
  info = {} as PAYMENTS.IGatewayTransaction,
}: props): JSX.Element => {
  const decodedAccountName = info?.account_name
    ? decodeURIComponent(info?.account_name).trim()
    : 'NA';

  return (
    <>
      <div className="mb-9 pb-2">
        <h3 className="mb-2 PaymentDetailsHeading">Payment method</h3>
        <hr className="mt-0 pb-1 mb-2" />
        <div>
          <div className={classNames(styles.SummaryInfo, 'pt-0')}>
            <span className={classNames(styles.SummaryHeading)}>Method</span>
            <span className={classNames(styles.HeadingData)}>
              {info.method}
            </span>
          </div>
          <div className={classNames(styles.SummaryInfo)}>
            <span className={classNames(styles.SummaryHeading)}>
              Card Issuer / Bank / Wallet
            </span>
            <span className={classNames(styles.HeadingData)}>
              {/* {decodedAccountName} */}
              {'NA'}
            </span>
          </div>
          <div className={classNames(styles.SummaryInfo, 'pb-0')}>
            <span className={classNames(styles.SummaryHeading)}>
              Account / Card Number
            </span>
            <span className={classNames(styles.HeadingData)}>
              {info.account_number || 'NA'}
            </span>
          </div>
          <div className={classNames(styles.SummaryInfo, 'pb-0')}>
            <span className={classNames(styles.SummaryHeading)}>
              Payment Partner
            </span>
            <span className={classNames(styles.HeadingData)}>
              {info.processor_name}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default GatewayPaymentMethod;
