import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import useStore from 'state/store/hooks/useStore';
import Error from 'views/error/notfound';
import PageHeading from '../../components/heading/index';
import BrandingSettings from './branding';
import General from './general';
import Payments from './payments';
import Notifications from './notifications';
import styles from './styles.module.scss';
import NonAchBrandingSettings from './nonAchSettings/storeDetails';

const GeneralSettings = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const { organisation, toFetchActiveOrg } = useOrganisation();
  const { store, toUpdateStore } = useStore();
  const { updated } = store;
  const { activeOrg } = organisation;

  useEffect(() => {
    if (updated && activeOrg) {
      toFetchActiveOrg();
    }
  }, [updated]);

  return activeOrg?.is_gateway_payment_enabled &&
    !activeOrg.is_payment_enabled ? (
    <Card className="card-profile border-0" style={{ marginBottom: '30px' }}>
      <PageHeading heading="Store" className="mb-7" />
      <NonAchBrandingSettings
        data={activeOrg?.brand_settings ?? null}
        logisticsProvider={activeOrg?.logistic_provider ?? null}
      />
    </Card>
  ) : (
    <>
      <Card className="card-profile border-0" style={{ marginBottom: '30px' }}>
        <PageHeading heading="Store" className="mb-7" />
        <Nav tabs className={styles.storePageNav}>
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === '1' })}
              onClick={() => {
                toggle('1');
              }}
            >
              General
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === '2' })}
              onClick={() => {
                toggle('2');
              }}
            >
              Branding
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === '3' })}
              onClick={() => {
                toggle('3');
              }}
            >
              Payments
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === '4' })}
              onClick={() => {
                toggle('4');
              }}
            >
              Notifications
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === '5' })}
              onClick={() => {
                toggle('5');
              }}
            >
              My profile
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <General
              settings={activeOrg?.brand_settings ?? null}
              toUpdateStore={toUpdateStore}
            />
          </TabPane>
        </TabContent>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="2">
            <BrandingSettings
              data={activeOrg?.brand_settings ?? null}
              logisticsProvider={activeOrg?.logistic_provider ?? null}
            />
          </TabPane>
        </TabContent>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="3">{activeTab === '3' && <Payments />}</TabPane>
        </TabContent>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="4">{activeTab === '4' && <Notifications />}</TabPane>
        </TabContent>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="5">
            <Error />
          </TabPane>
        </TabContent>
      </Card>
    </>
  );
};

export default GeneralSettings;
