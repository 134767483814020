import React from 'react';
import BootstrapTable, { SortOrder } from 'react-bootstrap-table-next';
import classNames from 'classnames';
import * as util from 'utils/dashboard'; // skipcq: JS-C1003
import CURRENCY from 'utils/currency';
import styles from '../../tips/tips.module.scss';
import NoSummaryFound from '../../../components/summary-tab/no-summary-found';
import { PAYMENTS } from 'state/payment/type';
import { AmountChangePercentLabel } from 'utils/status-formatter';
import { UncontrolledTooltip } from 'reactstrap';
import { formatDate } from 'utils/date-format';

interface IProps {
  summaryData: PAYMENTS.TipByEmployeeAggregate;
  previousStartDate: string;
  previousEndDate: string;
}

const TipSummaryByEmployee = ({
  summaryData,
  previousStartDate,
  previousEndDate,
}: IProps): JSX.Element => {
  const tooltipText = `Compared to previous period: ${formatDate(
    previousStartDate,
    'MMM dd yyyy, hh:mm a'
  )} - ${formatDate(previousEndDate, 'MMM dd yyyy, hh:mm a')}`;
  const NAtooltipText =
    'Unable to get the Employee ID. Please check if it’s added as user info under User Management section in your Treez Dashboard.';
  // Table data
  const data = [
    {
      treez_employee_name: 'Total',
      treez_employee_id: '',
      total_tip_amount: summaryData.total_tip,
    },
    {
      treez_employee_name: 'Employees',
      treez_employee_id: '',
      total_tip_amount: null,
    },
    ...(Array.isArray(summaryData.employee_tips)
      ? summaryData.employee_tips
      : []),
  ];

  const totalTipPercentChange =
    summaryData.previous_total_tip > 0
      ? util.getPercentChange(
          summaryData.total_tip,
          summaryData.previous_total_tip
        )
      : null;

  const SummaryByEmployeeColumns = [
    {
      dataField: 'treez_employee_name',
      text: '',
      headerStyle: {
        height: '48px',
      },
      formatter: (cell: string) => {
        if (cell === 'Employees') {
          return (
            <div className={classNames(styles.employeeText)}>
              {cell}
              <span>
                <i className="ph-caret-down-fill icon-sm"></i>
              </span>
            </div>
          );
        }
        if (cell === 'Total') {
          return (
            <div className={classNames(styles.totalTextEmployeeSummary)}>
              {cell ? cell.toUpperCase() : '' || ''}
            </div>
          );
        } else {
          return cell;
        }
      },
    },
    {
      dataField: 'treez_employee_id',
      text: 'Employee Id',
      formatter: (cell: string, row: any, rowIndex: number) => {
        if (rowIndex === 0 || rowIndex === 1) {
          return <></>;
        }
        return (
          <>
            {cell
              ? cell.toUpperCase()
              : (
                  <span
                    className={classNames(styles.naText, styles.dashedBorder)}
                    id="natooltip"
                  >
                    NA
                    {NAtooltipText && (
                      <>
                        <UncontrolledTooltip
                          placement="right"
                          target="natooltip"
                        >
                          {NAtooltipText}
                        </UncontrolledTooltip>
                      </>
                    )}
                  </span>
                ) || ''}
          </>
        );
      },
    },
    {
      dataField: 'total_tip_amount',
      text: 'Tips',
      formatter: (cell: number | null, row: any, rowIndex: number) => {
        if (rowIndex === 0) {
          return (
            <div
              className={classNames(
                'currencyColumn body-medium text-left',
                styles.currencyRowColumn,
                styles.totalTextEmployeeSummary
              )}
            >
              {cell ? CURRENCY.convertToMainUnit(cell) : '$0.00'}
              <span
                className={classNames(styles.tipPercentChange)}
                id="totaltiptooltip"
              >
                {(totalTipPercentChange || totalTipPercentChange === 0) && (
                  <AmountChangePercentLabel value={totalTipPercentChange} />
                )}
                {tooltipText && (
                  <>
                    <UncontrolledTooltip
                      placement="bottom"
                      target="totaltiptooltip"
                    >
                      {tooltipText}
                    </UncontrolledTooltip>
                  </>
                )}
              </span>
            </div>
          );
        } else if (rowIndex === 1) {
          return <></>;
        } else {
          return (
            <div
              className={classNames(
                'currencyColumn body-medium text-left',
                styles.currencyRowColumn
              )}
            >
              {cell ? CURRENCY.convertToMainUnit(cell) : '$0.00'}
            </div>
          );
        }
      },
    },
  ];

  const defaultSorted: [{ dataField: string; order: SortOrder }] = [
    {
      dataField: 'total_tip_amount',
      order: 'desc',
    },
  ];

  return (
    <div className={classNames(styles.summaryTabContainer)}>
      <div className={classNames('mt-3')}>
        <div>
          <BootstrapTable
            wrapperClasses="table-responsive"
            keyField="key"
            data={data}
            columns={SummaryByEmployeeColumns}
            hover
            bootstrap4
            defaultSorted={defaultSorted}
            bordered={false}
            classes={classNames(styles.tableCustomStyle)}
            noDataIndication={<NoSummaryFound />}
            rowStyle={(row, rowIndex) => {
              if (rowIndex === 0) {
                return {
                  backgroundColor: '#f7f8f8',
                  height: '48px',
                };
              }
              if (rowIndex === 1) {
                return {
                  height: '48px',
                };
              }
              return {};
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TipSummaryByEmployee;
