import React, { Dispatch, SetStateAction, SyntheticEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { UncontrolledTooltip } from 'reactstrap';
import { TIPS_TABS } from 'utils/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import Search from 'components/search';
import Filter from 'components/filter';
import styles from '../../tips/tips.module.scss';
import { formatToTz } from 'utils/date-format';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import CURRENCY from 'utils/currency';
import Pagination from 'components/pagination';
import { PAYMENTS } from 'state/payment/type';
import usePayment from 'state/payment/hooks/usePayment';
import NoSummaryFound from 'components/summary-tab/no-summary-found';
import routeConfig from 'routes/config';
import { IFilterConfig } from 'components/filter/types';
import useQuery from 'utils/hooks/useQuery';

interface IProps<T> {
  dateFrom: string;
  dateTo: string;
  firstAmount: string | undefined;
  secondAmount: string | undefined;
  amountOption: string;
  paymentTypeArray: string[];
  searchString: string | undefined;
  filterConfig: IFilterConfig[];
  activeTab: string;
  queryParams: string
  setActiveTab:Dispatch<SetStateAction<string>>;
}

const TipsDetails = <T,>({
  dateFrom,
  dateTo,
  firstAmount,
  secondAmount,
  amountOption,
  paymentTypeArray,
  searchString,
  filterConfig,
  activeTab,
  queryParams,
  setActiveTab
}: IProps<T>): JSX.Element => {

  const query = useQuery(queryParams);
  
  const [prevKey, setPrevKey] = useState<string | null>(null);
  const [nextKey, setNextKey] = useState<string | null>(null);

  // Number of results
  const [numResults, setNumResults] = useState(0);

  const navigate = useNavigate();


  const { organisation } = useOrganisation();
  const { settings } = organisation;
  const { payment, toFetchTipsList } =
    usePayment();

  const NAtooltipText =
    'Unable to get the Employee ID. Please check if it’s added as user info under User Management section in your Treez Dashboard.';

  // get to prev page
  const prevPage = () => {
    toFetchTipsList({
      key: prevKey,
      start_date: dateFrom || undefined,
      end_date: dateTo || undefined,
      amount_1: firstAmount,
      amount_2: secondAmount,
      condition: amountOption || undefined,
      payment_type:
        paymentTypeArray.length > 0 ? paymentTypeArray.join(',') : undefined,
      search_string: searchString,
    });
  };

  // get to next page
  const nextPage = () => {
    toFetchTipsList({
      key: nextKey,
      start_date: dateFrom || undefined,
      end_date: dateTo || undefined,
      amount_1: firstAmount,
      amount_2: secondAmount,
      condition: amountOption || undefined,
      payment_type:
        paymentTypeArray.length > 0 ? paymentTypeArray.join(',') : undefined,
      search_string: searchString,
    });
  };

  useEffect(() => {
    if (payment.tips?.items) {
      // setting next and prev key
      setNextKey(payment.tips.next_key);
      setPrevKey(payment.tips.prev_key);
      setNumResults(payment.tips.total_count);
    }
  }, [payment.tips]);

  useEffect(() => {
    const queryTabParam = query.get('tab')?.toLowerCase();

    if (location.search && queryTabParam) {
      setActiveTab(
        TIPS_TABS.find((t) => t.label.toLowerCase() === queryTabParam)?.label ||
          ''
      );
    } else {
      navigate({
        search: '?tab=summary',
      });
    }

    if (activeTab === TIPS_TABS[1].label) {
      toFetchTipsList({
        start_date: dateFrom || undefined,
        end_date: dateTo || undefined,
        amount_1: firstAmount,
        amount_2: secondAmount,
        condition: amountOption || undefined,
        payment_type:
          paymentTypeArray.length > 0 ? paymentTypeArray.join(',') : undefined,
        search_string: searchString,
      });
    }
  }, [location.search, activeTab]);

  const rowEvents = {
    onClick: (e: SyntheticEvent<Element, Event>, row: PAYMENTS.ITipsResult) => {
      navigate(
        `../${routeConfig.PAYMENTS.transactions.path}/${row.transaction_id}`
      );
    },
  };

  const columns = [
    {
      dataField: 'transaction_date',
      text: 'Date',
      sort: true,
      formatter: (cell: string, row: PAYMENTS.ITipsResult) => {
        return (
          <>
            {cell || row?.transaction_date
              ? formatToTz(
                  cell || row.transaction_date,
                  settings?.timezone,
                  'MMM dd, hh:mm a'
                )
              : ''}
          </>
        );
      },
    },
    {
      dataField: 'ticket_id',
      text: 'Ticket Id',
      formatter: (cell: string) => {
        return <>{cell.toUpperCase() || ''}</>;
      },
    },
    {
      dataField: 'tip_amount',
      text: 'Tip Amount',
      sort: true,
      formatter: (cell: number) => {
        return (
          <div className="currencyColumn">
            {CURRENCY.convertToMainUnit(cell)}
          </div>
        );
      },
    },
    {
      dataField: 'treez_employee_name',
      text: 'Employee Name',
      sort: true,
      formatter: (cell: string) => {
        return <div className="text-capitalize">{cell}</div>;
      },
    },
    {
      dataField: 'treez_employee_id',
      text: 'Employee Id',
      formatter: (cell: string) => {
        return (
          <>
            {cell ? (
              cell.toUpperCase()
            ) : (
              <>
                <div className={classNames(styles.naText)} id="detailnatooltip">
                  <span className={classNames(styles.dashedBorder)}>NA</span>
                </div>
                {NAtooltipText && (
                  <>
                    <UncontrolledTooltip
                      placement="right"
                      target="detailnatooltip"
                    >
                      {NAtooltipText}
                    </UncontrolledTooltip>
                  </>
                )}
              </>
            )}
          </>
        );
      },
    },
    {
      dataField: 'payment_type',
      text: 'Payment Type',
      sort: true,
      formatter: (cell: string) => {
        return <div className="text-capitalize">{cell === 'ATM' ? 'CATM' : cell }</div>;
      },
    },
  ];

  return (
    <>
      <div className="d-flex mb-3">
        <span className="mr-auto">
          <Search queryParams={location.search} placeHolders='Search by Employee name, Employee ID and Ticket ID' className={styles.searchStyleDetails}/>
        </span>

        {filterConfig && (
          <>
            <Filter
              config={filterConfig}
              queryParams={location.search}
            ></Filter>
          </>
        )}
      </div>
      <BootstrapTable
        id="tipsList"
        wrapperClasses="table-responsive"
        keyField="_id"
        data={payment.tips?.items || []}
        columns={columns}
        rowEvents={rowEvents}
        hover
        condensed
        bordered={false}
        noDataIndication={<NoSummaryFound />}
      />
      <Pagination
        results={numResults}
        prevPage={prevPage}
        nextPage={nextPage}
        prevKey={prevKey}
        nextKey={nextKey}
      />
    </>
  );
};
export default TipsDetails;
