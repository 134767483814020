import React, { useEffect, useRef } from 'react';
import { apiOrgLive, apiWithOrg } from '.';
import useAuth from 'state/auth/hooks/useAuth';
import PermissionDeniedError from 'components/error/permissionDenied';

const APIErrorHandler = () => {
  const interceptorIdOrg = useRef<number | null>(null);
  const interceptorIdLive = useRef<number | null>(null);
  const { toSetPermissionError, auth } = useAuth();

  const permissionErrorInterceptor = (error: any) => {
    if (
      error.request.status === 403 &&
      error.response.data.error_code === 'role_based_permission_denied'
    ) {
      toSetPermissionError(true);
    }
    throw error;
  };

  useEffect(() => {
    interceptorIdOrg.current = apiWithOrg.axiosInst.interceptors.response.use(
      undefined,
      permissionErrorInterceptor
    );
    interceptorIdLive.current = apiOrgLive.axiosInst.interceptors.response.use(
      undefined,
      permissionErrorInterceptor
    );

    return () => {
      apiWithOrg.axiosInst.interceptors.response.eject(
        interceptorIdOrg.current as number
      );
      apiOrgLive.axiosInst.interceptors.response.eject(
        interceptorIdLive.current as number
      );
    };
  }, []);

  return auth.permissionError ? <PermissionDeniedError /> : null;
};

export default APIErrorHandler;
