import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'reactstrap';
import useBanking from 'state/banking/hooks/useBanking';
import IndividualPayout from 'views/payoutsList/payout/individual-payout';

const Payout = (): JSX.Element => {
  const { balTransactionId } = useParams<{ balTransactionId: string }>();
  const { toFetchPayoutDetails } = useBanking();

  useEffect(() => {
    if (balTransactionId) toFetchPayoutDetails(balTransactionId);
  }, []);

  return (
    <>
      <Card className="card-profile border-0">
        <IndividualPayout />
      </Card>
    </>
  );
};

export default Payout;
