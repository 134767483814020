import classNames from 'classnames';
import ErrorMessage from 'components/errorMessage';
import FormLabel from 'components/form-label';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import useBanking from 'state/banking/hooks/useBanking';
import { BANK_ROUTING_NUMBER, BANK_ACCOUNT_NUMBER } from 'utils/validation';
import {
  CREATE_PAYOUTS,
  EXTERNAL_PAYOUT_STAGES,
  IActiveState,
  PAYOUTTYPE,
  PAYOUT_STAGE,
  SELF_PAYOUT_BANK_STAGES,
  SELF_PAYOUT_STAGES,
} from 'components/modals/banking/payouts/payout';
import SuccessAddBank from 'components/modals/banking/successAddBank';
import styles from '../styles.module.scss';

interface Iprops {
  setActiveState: Dispatch<SetStateAction<IActiveState>>;
  activeState: IActiveState;
}

const AddBankAccount = ({
  activeState,
  setActiveState,
}: Iprops): JSX.Element => {
  const { register, errors, handleSubmit, setError, getValues } = useForm();
  const modalTitle = 'Add bank account';

  const { clearAddBankAccountError, addBankAccount, banking } = useBanking();

  const { add_bank_account } = banking;

  const [showSuccess, setShowSuccess] = useState(false);

  const addBankAccountWrapper = (values: { [x: string]: string }) => {
    addBankAccount({
      account_holder_name: values.account_holder_name,
      institution_name: values.institution_name,
      routing_number: values.routing_number.toString(),
      account_number: values.account_number.toString(),
      re_account_number: values.re_account_number.toString(),
    });
  };

  useEffect(() => {
    if (add_bank_account) {
      setShowSuccess(true);
      activeState.activeForm = SELF_PAYOUT_STAGES.PAYOUT_DETAILS;
    }
  }, [add_bank_account]);

  const formSubmit = (values: { [x: string]: string }) => {
    addBankAccountWrapper(values);
  };

  const formSubmission = () => {
    handleSubmit(formSubmit)();
  };
  const toggle = () => {
    clearAddBankAccountError();
  };

  useEffect(() => {
    if (
      Number(getValues('confirm_account')) &&
      !Number(getValues('account_number'))
    ) {
      setError('confirm_account', {
        type: 'validate',
        message: "The account numbers don't match",
      });
    }
  }, [
    Number(getValues('confirm_account')),
    Number(getValues('account_number')),
  ]);

  useEffect(() => {
    const footerElement = (
      <>
        <Button
          // color="primary"
          className="btn btn-primary mr-2"
          type="submit"
          onClick={() => {
            activeState.payoutStage = PAYOUT_STAGE.info;
            activeState.activeForm = undefined;
            activeState.config = null;
            setActiveState({ ...activeState });
          }}
        >
          <span className="d-flex">Back</span>
        </Button>
        <Button
          color="primary"
          className="btn btn-primary"
          type="submit"
          onClick={() => formSubmission()}
        >
          <span className="d-flex">
            Next <i className="ph-arrow-right pl-1"></i>
          </span>
        </Button>
      </>
    );
    activeState.footer = footerElement;
    setActiveState({ ...activeState });
  }, []);

  return (
    <>
      <div
        className={classNames(
          styles.formContainer,
          'hide-scrollbars overflow-hidden'
        )}
      >
        <h1 className={classNames(styles.heading)}>Bank details</h1>
        <h2 className={classNames(styles.subHeading, 'body-subtext-regular')}>
          You have not added your bank account yet. Please add your bank account
          to payout your funds.
        </h2>
        <Form role="form" onSubmit={handleSubmit(formSubmit)}>
          <FormGroup>
            <FormLabel
              isRequired
              element="account_holder_name"
              label="Account beneficiary name"
              className={styles.fieldLabel}
            />
            <Input
              name="account_holder_name"
              id="account_holder_name"
              innerRef={
                register &&
                register({
                  required: true,
                })
              }
              className={errors?.account_holder_name ? styles.borderAlert : ''}
            />
            {errors?.account_holder_name && (
              <ErrorMessage
                isValidation
                message="Please enter account holder name."
                preIcon={
                  <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
                }
              />
            )}
          </FormGroup>

          <FormGroup>
            <FormLabel
              isRequired
              element="account_number"
              label="Account number"
              className={styles.fieldLabel}
            />
            <Input
              type="number"
              name="account_number"
              placeholder="234152893"
              id="account_number"
              innerRef={
                register &&
                register({
                  required: {
                    value: true,
                    message: 'Please enter your account number.',
                  },
                  pattern: {
                    value: BANK_ACCOUNT_NUMBER,
                    message: 'Please enter a valid account number.',
                  },
                })
              }
              className={errors?.account_number ? styles.borderAlert : ''}
            />
            {errors?.account_number && (
              <ErrorMessage
                testId="account_number"
                isValidation
                message={
                  errors?.account_number?.message ||
                  'Please enter your account number.'
                }
                preIcon={
                  <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
                }
              />
            )}
          </FormGroup>
          <FormGroup>
            <FormLabel
              isRequired
              element="re_account_number"
              label="Confirm account number"
              className={styles.fieldLabel}
            />
            <Input
              type="number"
              name="re_account_number"
              id="re_account_number"
              innerRef={
                register &&
                register({
                  required: true,
                  validate: () =>
                    Number(getValues('account_number')) ===
                    Number(getValues('re_account_number')),
                })
              }
              className={
                errors?.re_account_number?.type === 'validate'
                  ? styles.borderAlert
                  : '' ||
                    (errors.re_account_number?.type === 'required' &&
                    !errors?.account_number
                      ? styles.borderAlert
                      : '')
              }
            />
            {errors?.re_account_number &&
              errors?.re_account_number.type === 'required' &&
              !errors?.account_number && (
                <ErrorMessage
                  isValidation
                  message={'Please confirm your account number.'}
                  preIcon={
                    <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
                  }
                />
              )}
            {errors?.re_account_number &&
              errors?.re_account_number.type === 'required' &&
              errors?.re_account_number && <></>}
            {errors?.re_account_number &&
              errors?.re_account_number.type === 'validate' && (
                <ErrorMessage
                  isValidation
                  message={"The account numbers don't match."}
                  preIcon={
                    <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
                  }
                />
              )}
          </FormGroup>
          <FormGroup>
            <FormLabel
              isRequired
              element="institution_name"
              label="Bank name"
              className={styles.fieldLabel}
            />
            <Input
              name="institution_name"
              id="institution_name"
              placeholder="XYZ Bank"
              innerRef={
                register &&
                register({
                  required: true,
                })
              }
              className={errors?.institution_name ? styles.borderAlert : ''}
            />
            {errors?.institution_name && (
              <ErrorMessage
                isValidation
                message="Please enter a financial institution name."
                preIcon={
                  <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
                }
              />
            )}
          </FormGroup>
          <FormGroup>
            <FormLabel
              isRequired
              element="routing_number"
              label="Bank routing number"
              className={styles.fieldLabel}
            />
            <Input
              type="number"
              name="routing_number"
              id="routing_number"
              placeholder="623852453"
              innerRef={
                register &&
                register({
                  required: {
                    value: true,
                    message: 'Please enter a bank routing number.',
                  },
                  pattern: {
                    value: BANK_ROUTING_NUMBER,
                    message: 'Please enter a valid bank routing number.',
                  },
                })
              }
              className={errors?.routing_number ? styles.borderAlert : ''}
            />
            {errors?.routing_number && (
              <ErrorMessage
                testId="routing_number"
                isValidation
                message={errors?.routing_number.message}
                preIcon={
                  <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
                }
              />
            )}
          </FormGroup>
        </Form>
        {add_bank_account && showSuccess && (
          <SuccessAddBank openModal={showSuccess} setModal={setShowSuccess} />
        )}
      </div>
    </>
  );
};

export default AddBankAccount;
