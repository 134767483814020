import * as Sentry from '@sentry/react';
import accessLocalStorage from 'services/localStorage';

const Logger = {
  captureException: (e: Record<string, unknown>): void => {
    Sentry.captureException(e);
  },
  getUserInfo: () => accessLocalStorage.getUser(),
};

export default Logger;
