import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button, Tooltip } from 'reactstrap';
import ModalContainer from 'components/modals/container';
import BannerContainer, { BannerType } from 'components/banner';
import usePartnerStores from 'state/partnerStores/hooks/usePartnerStores';
import CopyToClipboard from 'react-copy-to-clipboard';
import RadioButtonCard, { IRadioConfig } from 'components/card-radio-input';
import { PARTNER_STORES } from 'state/partnerStores/type';
import usePayment from 'state/payment/hooks/usePayment';
import { PAYMENTS } from 'state/payment/type';
import { downloadFile } from 'utils/download';
import styles from '../developers.module.scss';
interface IProps {
  openModal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  isPartner: boolean;
}

enum APIType {
  PARTNERS = 'partners',
  SELF = 'self',
}

enum STAGE {
  SELECT_TYPE,
  LIST_PARTNERS,
  GET_KEYS,
}

const errorBanner = (
  <BannerContainer
    heading="An unexpected error occured"
    message="We are unable to create API keys at the moment. Please close and try again."
    showBanner
    type={BannerType.error}
    listOfError={false}
    fixedHeight={false}
    testId="error-create-keys"
  />
);

const SelectKeyType = ({
  type,
  setType,
}: {
  type: APIType | null;
  setType: React.Dispatch<React.SetStateAction<APIType | null>>;
}) => {
  const {
    partnerStores: { error: partnerErr },
  } = usePartnerStores();
  const {
    payment: { error: keyError },
  } = usePayment();

  const radioOptions = [
    {
      detailsElement: (
        <>
          <div className="keyHeading body-subtext-semibold">Partner</div>
          <div className="keySubHeading footnote">Create key for a partner</div>
        </>
      ),
      value: APIType.PARTNERS,
      selected: type === APIType.PARTNERS,
      onChangeHandler: (e: string) => {
        setType(e as APIType);
      },
      label: APIType.PARTNERS,
    },
    {
      detailsElement: (
        <>
          <div className="keyHeading body-subtext-semibold">Self</div>
          <div className="keySubHeading footnote">Create key for self</div>
        </>
      ),
      value: APIType.SELF,
      selected: type === APIType.SELF,
      onChangeHandler: (e: string) => {
        setType(e as APIType);
      },
      label: APIType.SELF,
    },
  ];

  const showError = () => {
    return (
      (type === APIType.PARTNERS && partnerErr?.fetchPartners) ||
      (type === APIType.SELF && keyError?.addKeySelf)
    );
  };

  return (
    <>
      {showError() && errorBanner}
      <div className="mt-1">
        You can either create API keys for a Partner or for Self.
      </div>
      <div className="d-flex mt-5">
        {radioOptions.map((option: IRadioConfig) => (
          <RadioButtonCard
            config={option}
            name="APIKeyType"
            cardStyles={{ marginRight: '24px' }}
          />
        ))}
      </div>
      <div className="d-flex mt-4">
        <span>
          <i
            className={classNames('ph-info-fill icon-lg pr-2')}
            style={{ paddingTop: '2px', color: '#808690' }}
          />
        </span>
        <span>
          Partner API keys have a restricted scope, which allows them to manage
          only the resources they have created.
        </span>
      </div>
    </>
  );
};

const ListPartners = ({
  selectedPartner,
  setSelectedPartner,
}: {
  selectedPartner: string | null;
  setSelectedPartner: React.Dispatch<React.SetStateAction<string | null>>;
}) => {
  const { partnerStores } = usePartnerStores();
  const { partners } = partnerStores;
  const {
    payment: { error: keyError },
  } = usePayment();

  const getRadioConfig = (partner: PARTNER_STORES.IPartner) => {
    return {
      detailsElement: (
        <>
          <div className="keyHeading body-subtext-semibold">{partner.name}</div>
          <div style={{ width: '124px', height: '28px' }} className="mt-2">
            {partner?.image ? (
              <img
                style={{ maxWidth: '100%', maxHeight: '100%' }}
                src={partner.image}
                alt={`${partner.name} logo`}
              />
            ) : (
              <div className={styles.partnerIconFallback}>
                <i className="ph ph-handshake-fill" title="Partner icon"></i>
              </div>
            )}
          </div>
        </>
      ),
      value: partner.id,
      selected: partner.id === selectedPartner,
      onChangeHandler: (e: string) => {
        setSelectedPartner(e);
      },
      label: partner.name,
    };
  };

  return (
    <>
      {keyError?.addKeyPartner ? (
        errorBanner
      ) : partners?.items?.length === 0 ? (
        <div className="body-subtext mt-6 mb-6 text-center">
          No partners to select. Please go back and select Self option if that
          is applicable.
        </div>
      ) : (
        <>
          <div className="body-subtext-medium" style={{ color: '#333D4E' }}>
            Select a Partner
          </div>
          <div className={classNames('mt-5', styles.partnerListContainer)}>
            {partners?.items.map((partner) => (
              <RadioButtonCard
                config={getRadioConfig(partner)}
                name="partnersList"
                cardStyles={{
                  height: '84px',
                }}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};

const GetAPIKeys = () => {
  const [copiedID, setCopiedID] = useState(false);
  const [copiedSecret, setCopiedSecret] = useState(false);
  const { payment } = usePayment();
  const { key } = payment;
  const clientID = key?.client_id;
  const clientSecret = key?.client_secret;

  useEffect(() => {
    if (copiedID) {
      setTimeout(() => {
        setCopiedID(false);
      }, 2000);
    }
    if (copiedSecret) {
      setTimeout(() => {
        setCopiedSecret(false);
      }, 2000);
    }
  }, [copiedID, copiedSecret]);

  const downloadKeysJSON = () => {
    if (!key?.client_id) return;

    const data = {
      client_id: key?.client_id,
      client_secret: key?.client_secret,
    };
    const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(
      JSON.stringify(data)
    )}`;
    downloadFile(dataStr, 'api_keys', 'json');
  };

  return (
    <div className={styles.getKeysContainer}>
      <div className="mt-12">Client ID</div>
      <div className={classNames('d-flex mt-1', styles.copyKeys)}>
        <div className="body-subtext">{clientID}</div>
        <Button type="button" id="copy-clientID" className="p-0">
          <CopyToClipboard onCopy={() => setCopiedID(true)} text={clientID}>
            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
              <i className="ph-copy-simple-bold m-0"></i>
              <span className="ml-1">Copy</span>
            </div>
          </CopyToClipboard>
          <Tooltip
            isOpen={copiedID}
            placement="right"
            target="copy-clientID"
            className="mt-1"
          >
            Copied!
          </Tooltip>
        </Button>
      </div>
      <div className="mt-5">Client secret</div>
      <div className={classNames('d-flex mt-1', styles.copyKeys)}>
        <div className="body-subtext">{clientSecret}</div>
        <Button type="button" id="copy-clientID" className="p-0">
          <CopyToClipboard
            onCopy={() => setCopiedSecret(true)}
            text={clientSecret}
          >
            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
              <i className="ph-copy-simple-bold m-0"></i>
              <span className="ml-1">Copy</span>
            </div>
          </CopyToClipboard>
          <Tooltip
            isOpen={copiedSecret}
            placement="right"
            target="copy-clientID"
            className="mt-1"
          >
            Copied!
          </Tooltip>
        </Button>
      </div>
      <div className="d-flex mt-1">
        <span>
          <i
            className={classNames('ph-info-fill icon-lg pr-2')}
            style={{ paddingTop: '2px', color: '#808690' }}
          />
        </span>
        <span className="body-subtext-medium text-muted">
          Save and store this new key to a secure place, such as password
          manager or secret store. It is non-recoverable and you won’t be able
          to see it again.
          <button
            type="button"
            className="downloadJSON pl-1"
            onClick={downloadKeysJSON}
          >
            Download as JSON
          </button>
        </span>
      </div>
    </div>
  );
};

const CreateAPIKey = ({
  openModal,
  setModal,
  isPartner,
}: IProps): JSX.Element => {
  const [stage, setStage] = useState(STAGE.SELECT_TYPE);
  const [type, setType] = useState<APIType | null>(null);
  const [selectedPartner, setSelectedPartner] = useState<string | null>(null);
  const { toAddKey, toResetPayment, toFetchKeys, payment } = usePayment();
  const { partnerStores, toFetchPartnersList, toResetPartner } =
    usePartnerStores();
  const { partners } = partnerStores;
  const { error: keyError } = payment;

  const toggle = () => {
    setModal(false);
  };

  const createAPIKeysHandler = async () => {
    let request;

    if (type === APIType.PARTNERS) {
      const partnerSelected = partners?.items?.find(
        (p) => p.id === selectedPartner
      );
      request =
        type === APIType.PARTNERS
          ? ({
              partner: {
                id: partnerSelected?.id,
                name: partnerSelected?.name,
              },
            } as PAYMENTS.ICreateAPIKeys)
          : undefined;
    }
    const response = await toAddKey(request);
    if (response) {
      if (
        (type === APIType.PARTNERS && !keyError?.addKeyPartner) ||
        !keyError.addKeySelf
      )
        setStage(STAGE.GET_KEYS);
    }
  };

  const continueClickHandler = async () => {
    if (type === APIType.PARTNERS) {
      const response = await toFetchPartnersList();
      if (response) setStage(STAGE.LIST_PARTNERS);
    } else if (type === APIType.SELF) {
      createAPIKeysHandler();
    }
  };

  const CreateKeyButton = (
    <Button
      color="primary"
      disabled={selectedPartner === null || Boolean(keyError?.addKeyPartner)}
      onClick={createAPIKeysHandler}
      type="submit"
      className={classNames('d-flex align-items-center')}
    >
      <i className={classNames('ph-plus-bold icon-lg pr-1 ')} />
      Create key
    </Button>
  );

  const ContinueButton = (
    <Button
      color="primary"
      disabled={type === null}
      onClick={continueClickHandler}
      type="submit"
      className={classNames('d-flex align-items-center')}
    >
      Continue
      <i className="ph-arrow-right icon-lg pl-1" />
    </Button>
  );

  useEffect(() => {
    if (isPartner) {
      createAPIKeysHandler();
    }

    return () => {
      toResetPartner();
      toResetPayment();
      toFetchKeys();
    };
  }, []);

  useEffect(() => {
    if (
      keyError?.addKeySelf?.error_code === 'role_based_permission_denied' ||
      keyError?.addKeyPartner?.error_code === 'role_based_permission_denied'
    )
      toggle();
  }, [keyError]);

  return (
    <ModalContainer
      isOpen={openModal}
      title={
        stage === STAGE.GET_KEYS
          ? 'API key created successfully'
          : 'Create API Key'
      }
      onClose={toggle}
      isFullHeight={false}
      testId="modal-createAPIKey"
      footer={
        <div className="d-flex">
          {stage === STAGE.SELECT_TYPE && (
            <>
              <Button onClick={toggle} color="secondary">
                Cancel
              </Button>
              {ContinueButton}
            </>
          )}
          {stage === STAGE.LIST_PARTNERS && (
            <>
              <Button
                onClick={() => setStage(STAGE.SELECT_TYPE)}
                color="secondary"
              >
                Back
              </Button>
              {CreateKeyButton}
            </>
          )}
          {stage === STAGE.GET_KEYS && (
            <>
              <Button onClick={toggle} color="primary">
                Close
              </Button>
            </>
          )}
        </div>
      }
    >
      <div className={styles.createKeysContainer}>
        {isPartner && keyError?.addKeySelf && errorBanner}
        {!isPartner && stage === STAGE.SELECT_TYPE && (
          <SelectKeyType type={type} setType={setType} />
        )}
        {!isPartner && stage === STAGE.LIST_PARTNERS && (
          <ListPartners
            selectedPartner={selectedPartner}
            setSelectedPartner={setSelectedPartner}
          />
        )}
        {(isPartner || stage === STAGE.GET_KEYS) && !keyError?.addKeySelf && (
          <GetAPIKeys />
        )}
      </div>
    </ModalContainer>
  );
};

export default CreateAPIKey;
