import React, { FC } from 'react';

const DownChevronIcon: FC = () => {
  return (
    <div
      aria-hidden="true"
      className="SVGInline SVGInline--cleaned SVG Icon Icon--chevronDown Icon-color Icon-color--gray600 Box-root Flex-flex"
      style={{ marginLeft: '8px', display: 'inline-flex' }}
    >
      <svg
        aria-hidden="true"
        className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--chevronDown-svg Icon-color-svg Icon-color--gray600-svg"
        height="11"
        width="11"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.591 5.293a1 1 0 0 1 1.416 1.416l-6.3 6.3a1 1 0 0 1-1.414 0l-6.3-6.3A1 1 0 0 1 2.41 5.293L8 10.884z"
          fillRule="evenodd"
        ></path>
      </svg>
    </div>
  );
};

export default DownChevronIcon;
