import React from 'react';
import accessLocalStorage from 'services/localStorage';
import useAuth from 'state/auth/hooks/useAuth';
import { isUserAuthorized } from './util';

const withRole =
  (allowedRoles: string[]) =>
  (Component: () => JSX.Element): JSX.Element | null => {
    const { auth } = useAuth();

    const { roles } = auth.user;

    const orgId = accessLocalStorage.getOrgId();

    const storeSpecificRole = roles?.filter((item) => item.tenant_id === orgId);

    if (isUserAuthorized(allowedRoles, storeSpecificRole)) return <Component />;
    return null;
  };

export default withRole;
