import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import PageHeading from '../../../components/heading/index';
import Home, { checkEnabled, getPaymentFilterList } from './tabs/home';
import IntegratedAtm from './tabs/atm';
import usePaymentConfig from 'state/paymentConfig/hooks/usePaymentConfig';
import { PAYMENT_CONFIG } from 'state/paymentConfig/type';
import IntegratedDebit from './tabs/debit';
import IntegratedCredit from './tabs/credit';
import IntegratedAch from './tabs/ach';

export enum PaymentSolutionTab {
  ATM = 'ATM',
  CREDIT = 'Credit',
  DEBIT = 'Debit',
  HOME = 'Home',
  ACH = 'ACH',
}

const ConfigurationSettings = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState(PaymentSolutionTab.HOME);

  const [defaultProvider, setDefaultProvider] =
    useState<PAYMENT_CONFIG.IProcessors>();

  const {
    paymentConfig,
    toFetchPaymentSolutions,
    toFetchDefaultProviders,
    updatedFilterList,
    resetPaymentDeviceList,
  } = usePaymentConfig();

  const { paymentSolutions, defaultProcessor } = paymentConfig;

  useEffect(() => {
    Promise.all([toFetchPaymentSolutions(), toFetchDefaultProviders()]);
  }, []);

  const getPaymentTab = (tabNo: PaymentSolutionTab) => {
    if (tabNo === PaymentSolutionTab.ATM) {
      return 'ATM';
    } else if (tabNo === PaymentSolutionTab.DEBIT) {
      return 'Debit';
    } else if (tabNo === PaymentSolutionTab.CREDIT) {
      return 'Credit';
    } else if (tabNo === PaymentSolutionTab.ACH) {
      return 'ACH';
    } else if (tabNo === PaymentSolutionTab.HOME) {
      return '';
    }
    return 'ATM';
  };

  const [showAtm, setShowAtm] = useState(false);
  const [showCredit, setShowCredit] = useState(false);
  const [showDebit, setShowDebit] = useState(false);
  const [showAch, setShowAch] = useState(false);

  const toggleTab = (
    tabNo: PaymentSolutionTab,
    paymentSolution: Array<PAYMENT_CONFIG.IProcessors> | null,
    processorDefault: PAYMENT_CONFIG.IFetchDefaultProcessor
  ) => {
    resetPaymentDeviceList();
    setActiveTab(tabNo);
    const paymentType = getPaymentTab(tabNo);

    const tempList =
      paymentSolution && paymentSolution?.length > 0
        ? getPaymentFilterList(
            paymentSolution,
            paymentType as PaymentSolutionTab
          )
        : [];
    updatedFilterList(tempList);
    const getDefaultProvider = processorDefault?.data?.filter((item) => {
      return item?.paymentType?.includes(paymentType);
    });
    const defaultProviderEnableCheck = checkEnabled(
      getDefaultProvider?.[0],
      tempList
    );
    if (defaultProviderEnableCheck && getDefaultProvider?.[0]?.processorName) {
      setDefaultProvider({
        paymentMethod: getDefaultProvider?.[0]?.paymentType ?? '',
        processorId: getDefaultProvider?.[0]?.processorId ?? '',
        processorName: getDefaultProvider?.[0]?.processorName ?? '',
        isEnabled: true,
      });
    } else {
      resetPaymentDeviceList();
      setDefaultProvider(undefined);
    }
  };
  const enableActivePaymentTabs = (paymentMethod: string) => {
    if (paymentMethod === PaymentSolutionTab.ATM) {
      setShowAtm(true);
    }
    if (paymentMethod === PaymentSolutionTab.CREDIT) {
      setShowCredit(true);
    }
    if (paymentMethod === PaymentSolutionTab.DEBIT) {
      setShowDebit(true);
    }
    if (paymentMethod === PaymentSolutionTab.ACH) {
      setShowAch(true);
    }
  };

  useEffect(() => {
    if (
      paymentSolutions?.processor?.processors &&
      paymentSolutions?.processor?.processors?.length > 0
    ) {
      paymentSolutions?.processor?.processors?.forEach((item) => {
        if (item?.isEnabled) {
          enableActivePaymentTabs(item?.paymentMethod);
        }
      });
    }
  }, [paymentSolutions]);

  return (
    <>
      <Card className="card-profile border-0">
        <PageHeading heading="Payment configurations" />
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === PaymentSolutionTab.HOME,
              })}
              onClick={() =>
                toggleTab(
                  PaymentSolutionTab.HOME,
                  paymentSolutions?.processor?.processors,
                  defaultProcessor
                )
              }
            >
              Home
            </NavLink>
          </NavItem>

          {showAtm && (
            <NavItem>
              <NavLink
                className={classNames({
                  active: activeTab === PaymentSolutionTab.ATM,
                })}
                onClick={() =>
                  toggleTab(
                    PaymentSolutionTab.ATM,
                    paymentSolutions?.processor?.processors,
                    defaultProcessor
                  )
                }
              >
                Integrated ATM
              </NavLink>
            </NavItem>
          )}
          {showDebit && (
            <NavItem>
              <NavLink
                className={classNames({
                  active: activeTab === PaymentSolutionTab.DEBIT,
                })}
                onClick={() =>
                  toggleTab(
                    PaymentSolutionTab.DEBIT,
                    paymentSolutions?.processor?.processors,
                    defaultProcessor
                  )
                }
              >
                Integrated Debit
              </NavLink>
            </NavItem>
          )}
          {showCredit && (
            <NavItem>
              <NavLink
                className={classNames({
                  active: activeTab === PaymentSolutionTab.CREDIT,
                })}
                onClick={() =>
                  toggleTab(
                    PaymentSolutionTab.CREDIT,
                    paymentSolutions?.processor?.processors,
                    defaultProcessor
                  )
                }
              >
                Integrated Credit
              </NavLink>
            </NavItem>
          )}
          {showAch && (
            <NavItem>
              <NavLink
                className={classNames({
                  active: activeTab === PaymentSolutionTab.ACH,
                })}
                onClick={() =>
                  toggleTab(
                    PaymentSolutionTab.ACH,
                    paymentSolutions?.processor?.processors,
                    defaultProcessor
                  )
                }
              >
                Integrated ACH
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={PaymentSolutionTab.HOME}>
            <Home
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              defaultProvider={defaultProvider}
              setDefaultProvider={setDefaultProvider}
            />
          </TabPane>
        </TabContent>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={PaymentSolutionTab.ATM}>
            <IntegratedAtm
              activeTab={activeTab}
              defaultProvider={defaultProvider}
              setDefaultProvider={setDefaultProvider}
            />
          </TabPane>
        </TabContent>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={PaymentSolutionTab.DEBIT}>
            <IntegratedDebit
              activeTab={activeTab}
              defaultProvider={defaultProvider}
              setDefaultProvider={setDefaultProvider}
            />
          </TabPane>
        </TabContent>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={PaymentSolutionTab.CREDIT}>
            <IntegratedCredit
              activeTab={activeTab}
              defaultProvider={defaultProvider}
              setDefaultProvider={setDefaultProvider}
            />
          </TabPane>
        </TabContent>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={PaymentSolutionTab.ACH}>
            <IntegratedAch
              activeTab={activeTab}
              defaultProvider={defaultProvider}
              setDefaultProvider={setDefaultProvider}
            />
          </TabPane>
        </TabContent>
      </Card>
    </>
  );
};

export default ConfigurationSettings;
