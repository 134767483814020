import classNames from 'classnames';
import { EFilterConfigType, IFilterConfig } from 'components/filter/types';
import ToastContainer from 'components/toasts/container';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toIsoStringWithTz } from 'utils/date-format';
import BannerContainer, { BannerType } from 'components/banner';
import DateExport from './date';
import styles from './export.module.scss';
import ListExport from './list';
import NumberExport from './number';
import TipInAccurateDataBanner from 'views/tips/tipBanner';
import { tipsThresholdDate } from 'utils/constants';

export interface IExport {
  start_date?: string;
  end_date?: string;
  status?: string;
  store?: string;
  condition?: string;
  amount_1?: string;
  amount_2?: string;
  payment_type?: string;
  [arg: string]: string | undefined;
}

interface IProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  config: IFilterConfig[]; // Will use filterconfig until export diverges from filter
  onExport: (arg: IExport) => void;
  heading: string;
  reportUrl: string;
  label: string;
  errorMessage?: string;
  changeKey?: string;
  changeKeyUpdateHandlerCb?: (arg: IFilterConfig[]) => IFilterConfig[]; // callback to update filter config based on changeKey updates
  toClearExport: () => void;
  isTipsExport?: boolean;  // to render tips corrupt data info banner
}
const ExportModal = ({
  showModal,
  setShowModal,
  config,
  onExport,
  heading,
  reportUrl,
  label,
  errorMessage,
  toClearExport,
  changeKey,
  changeKeyUpdateHandlerCb,
  isTipsExport= false
}: IProps): JSX.Element => {
  // default date values prefilled in input
  const defaultStartDate = toIsoStringWithTz(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const changeIndex = config.findIndex((i) => i.key === changeKey);

  const [amountError, setAmountError] = useState(false);

  const defaultEndDate = toIsoStringWithTz(new Date());
  const [startDate, setStartDate] = useState<string>(defaultStartDate);
  const [endDate, setEndDate] = useState<string>(defaultEndDate);

  const [showExportToast, setShowExportToast] = useState<boolean>(false);
  const successExportMessage = 'Your export is ready!';

  const [items, setItems] = useState(config);
  const updateDates = (item: IFilterConfig) => {
    if (item.type === EFilterConfigType.date && item.data.values?.length > 0) {
      setStartDate(item.data.values[0]);
      setEndDate(item.data.values[1]);
    }
  };

  const [submitExport, setSubmitExport] = useState(false);

  const toggle = () => {
    setShowModal(!showModal);
    setAmountError(false);
    items.forEach((item) => {
      if (item.key !== 'dates') {
        item.selected = false;
      }
    });
    setSubmitExport(false);
    toClearExport();
  };

  const downloadFile = (filePath: string) => {
    const link = document.createElement('a');
    link.href = filePath;

    link.download = `${label}-${startDate}-${endDate}` + '.csv';

    link.click();
    toClearExport();
  };

  const exportCSV = () => {
    const params: IExport = {};
    let error = false;

    items.forEach((item) => {
      const { data } = item;
      const { values } = data;

      params.start_date = startDate;
      params.end_date = endDate;

      if (item.type === EFilterConfigType.number && item.selected) {
        [params.condition] = values;
        if (values[0] !== 'between' && values[1]) {
          params.amount_1 = values[1].toString();
        } else if (values[1] && values[2]) {
          params.amount_1 = values[1].toString();
          params.amount_2 = values[2].toString();
        } else {
          error = true;
        }
      }
      if (item.type === EFilterConfigType.list && item.selected) {
        if (values.length > 0) {
          params[item.key] = values.join();
        }
      }
    });
    setSubmitExport(true);
    setAmountError(error);
    if (!error) {
      onExport(params);
    }
  };

  useEffect(() => {
    if (changeKey && changeKeyUpdateHandlerCb) {
      setItems(changeKeyUpdateHandlerCb(items));
    }
  }, [items?.[changeIndex]?.data?.values?.length]);

  useEffect(() => {
    if (reportUrl) {
      setShowModal(false);
      setShowExportToast(true);
      downloadFile(reportUrl);
    }
  }, [reportUrl]);

  /*
   *  Set single value in an array to generate comma-separated values
   */
  const setValue = (key: string, index: number, value: string) => {
    const updatedItems = items.map((item) => {
      if (item.key === key) {
        item.data.values[index] = value;
        updateDates(item);
      }

      return item;
    });
    setItems(updatedItems);
  };

  const [filterCount, setFilterCount] = useState(0);
  const [filterName, setFilterName] = useState('');
  const [withInDateRange, setWithInDateRange] = useState(true);

  const getFilterDetails = () => {
    let count = 0;
    items.forEach((item) => {
      if (item.selected) {
        count += 1;
      }
      if (item.key === 'amount' && !item.selected) {
        setAmountError(false);
      }
    });
    setFilterCount(count);
  };

  useEffect(() => {
    if (filterCount === 1) {
      setFilterName(EFilterConfigType.date);
    } else {
      setFilterName(filterCount.toString());
    }
  }, [filterCount]);

  // set dates to default if not selected otherwise the values from config
  useEffect(() => {
    items.forEach((item) => {
      if (item.type === EFilterConfigType.date && !item.selected) {
        updateDates(item);
        item.selected = true;
        // Set default date if empty
        if (!item.data.values[0]) {
          setValue(item.key, 0, defaultStartDate);
        }
        if (!item.data.values[1]) {
          setValue(item.key, 1, defaultEndDate);
        }
      } else if (item.type === EFilterConfigType.date && item.selected) {
        updateDates(item);
      }
      return item;
    });
    getFilterDetails();
  }, [startDate, endDate, config]);

  /*
   *  Set entire array to generate comma-separated values
   */
  const setValues = (key: string, values: string[]) => {
    const updatedItems = items.map((item) => {
      if (item.key === key) {
        item.data.values = values;
      }
      return item;
    });
    setItems(updatedItems);
  };
  const setSelected = (key: string, value: boolean) => {
    const updatedItems = items.map((item) => {
      if (item.key === key) {
        item.selected = value;
      }
      return item;
    });
    setItems(updatedItems);
  };

  const getComponent = (item: IFilterConfig) => {
    switch (item.type) {
      case EFilterConfigType.date:
        return <DateExport setValue={setValue} item={item} />;
      case EFilterConfigType.number:
        return (
          <NumberExport
            setValue={setValue}
            item={item}
            setSelected={setSelected}
            setShowAmountError={setAmountError}
            amountError={amountError}
            setSubmitExport={setSubmitExport}
            submitExport={submitExport}
          />
        );
      case EFilterConfigType.list:
        return (
          <ListExport
            setValues={setValues}
            item={item}
            setSelected={setSelected}
          ></ListExport>
        );
      default:
        return <></>;
    }
  };


 
  useEffect(() => {
    if (isTipsExport) {
    if (new Date(startDate) < tipsThresholdDate) {
      setWithInDateRange(true);
    } else {
      setWithInDateRange(false);
    }
  }
  }, [startDate]);

  return (
    <>
      <Modal isOpen={showModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{heading}</ModalHeader>
        <ModalBody className={classNames(styles.modal)}>
          <BannerContainer
            heading="To proceed with export, 1 change needs to be made"
            message="Please provide the amount to filter your results or uncheck the amount box"
            showBanner={amountError}
            type={BannerType.error}
          />
          <BannerContainer
            message="There is no data to be exported based on your filter selection. Please readjust your filters and try again"
            showBanner={errorMessage !== undefined}
            type={BannerType.warning}
            setShowBanner={toClearExport}
          />
          {isTipsExport && withInDateRange && <TipInAccurateDataBanner  isDismissable={false}/>}
          {items.length > 0 && heading != 'Tip Summary' && withInDateRange  && (
            <>
              <div className="mb-3 d-flex">
                <span
                  id={`settle-tooltip`}
                  className={classNames('pl-1 mr-2', styles.icon)}
                >
                  <i className="ph-info-fill icon-lg text-muted " />
                </span>
                <span className={styles.appliedFilter}>
                  This export has {filterName}{' '}
                  {filterCount === 1 ? 'filter' : 'filters'} applied
                </span>
              </div>
            </>
          )}
          {items.map((item) => getComponent(item))}
        </ModalBody>
        <ModalFooter>
          <div>
            <Button variant="text" onClick={toggle} size="md">
              Cancel
            </Button>
            <Button
              size="md"
              color="primary"
              onClick={() => {
                exportCSV();
              }}
              // disabled={!startDate || !endDate}
            >
              Export
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <ToastContainer
        showToast={showExportToast}
        onClose={() => setShowExportToast(!showExportToast)}
        message={successExportMessage}
      />
    </>
  );
};

export default ExportModal;
