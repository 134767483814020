import React from 'react';

interface IProps {
  viewBox?: string;
  width?: string;
  height?: string;
}

export const WarningIconFilled = ({ viewBox, width, height }: IProps) => (
  <svg
    width={width || '24'}
    height={height || '24'}
    viewBox={viewBox || '0 0 24 24'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2" y="2" width="20" height="20" rx="10" fill="#FDD795" />
    <path
      d="M12 5.5C10.7144 5.5 9.45772 5.88122 8.3888 6.59545C7.31988 7.30968 6.48676 8.32484 5.99479 9.51256C5.50282 10.7003 5.37409 12.0072 5.6249 13.2681C5.8757 14.529 6.49477 15.6872 7.40381 16.5962C8.31285 17.5052 9.47104 18.1243 10.7319 18.3751C11.9928 18.6259 13.2997 18.4972 14.4874 18.0052C15.6752 17.5132 16.6903 16.6801 17.4046 15.6112C18.1188 14.5423 18.5 13.2856 18.5 12C18.4967 10.2771 17.8108 8.62573 16.5925 7.40746C15.3743 6.18918 13.7229 5.5033 12 5.5ZM11.5 9C11.5 8.86739 11.5527 8.74021 11.6464 8.64645C11.7402 8.55268 11.8674 8.5 12 8.5C12.1326 8.5 12.2598 8.55268 12.3536 8.64645C12.4473 8.74021 12.5 8.86739 12.5 9V12.5C12.5 12.6326 12.4473 12.7598 12.3536 12.8536C12.2598 12.9473 12.1326 13 12 13C11.8674 13 11.7402 12.9473 11.6464 12.8536C11.5527 12.7598 11.5 12.6326 11.5 12.5V9ZM12 15.5C11.8517 15.5 11.7067 15.456 11.5833 15.3736C11.46 15.2912 11.3639 15.1741 11.3071 15.037C11.2503 14.9 11.2355 14.7492 11.2644 14.6037C11.2934 14.4582 11.3648 14.3246 11.4697 14.2197C11.5746 14.1148 11.7082 14.0434 11.8537 14.0144C11.9992 13.9855 12.15 14.0003 12.287 14.0571C12.4241 14.1139 12.5412 14.21 12.6236 14.3333C12.706 14.4567 12.75 14.6017 12.75 14.75C12.75 14.9489 12.671 15.1397 12.5303 15.2803C12.3897 15.421 12.1989 15.5 12 15.5Z"
      fill="#B97909"
    />
    <rect
      x="2"
      y="2"
      width="20"
      height="20"
      rx="10"
      stroke="#FEEED2"
      strokeWidth="4"
    />
  </svg>
);

export const WarningIcon = ({ viewBox, width, height }: IProps) => (
  <svg
    width={width || '32'}
    height={height || '32'}
    viewBox={viewBox || '0 0 32 32'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
      fill="#FEDFA8"
    />
    <path
      d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
      stroke="#B97909"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M16 10V17"
      stroke="#B97909"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4375 21.5C17.4375 22.2939 16.7939 22.9375 16 22.9375C15.2061 22.9375 14.5625 22.2939 14.5625 21.5C14.5625 20.7061 15.2061 20.0625 16 20.0625C16.7939 20.0625 17.4375 20.7061 17.4375 21.5Z"
      fill="#B97909"
      stroke="#B97909"
      strokeWidth="0.125"
    />
  </svg>
);
