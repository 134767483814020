export const CREATE = 'payments/FETCH';
export const CREATE_SUCCESS = 'payments/FETCH_SUCCESS';
export const CREATE_FAILURE = 'payments/FETCH_FAILURE';

export const CREATE_SESSION = 'payments/CREATE_SESSION';
export const CREATE_SESSION_SUCCESS = 'payments/CREATE_SESSION_SUCCESS';
export const CREATE_SESSION_FAILURE = 'payments/CREATE_SESSION_FAILURE';

export const FETCH_TRANSACTIONS = 'payments/FETCH_TRANSACTIONS';
export const FETCH_TRANSACTIONS_SUCCESS = 'payments/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_FAILURE = 'payments/FETCH_TRANSACTIONS_FAILURE';

export const FETCH_SESSIONS = 'payments/FETCH_SESSIONS';
export const FETCH_SESSIONS_SUCCESS = 'payments/FETCH_SESSIONS_SUCCESS';
export const FETCH_SESSIONS_FAILURE = 'payments/FETCH_SESSIONS_FAILURE';

export const FETCH_INDIVIDUAL_TRANSACTIONS =
  'payments/FETCH_INDIVIDUAL_TRANSACTIONS';
export const FETCH_INDIVIDUAL_TRANSACTIONS_SUCCESS =
  'payments/FETCH_INDIVIDUAL_TRANSACTIONS_SUCCESS';
export const FETCH_INDIVIDUAL_TRANSACTIONS_FAILURE =
  'payments/FETCH_INDIVIDUAL_TRANSACTIONS_FAILURE';

export const FETCH_GATEWAY_TRANSACTION = 'payments/FETCH_GATEWAY_TRANSACTION';
export const FETCH_GATEWAY_TRANSACTION_SUCCESS =
  'payments/FETCH_GATEWAY_TRANSACTION_SUCCESS';
export const FETCH_GATEWAY_TRANSACTION_FAILURE =
  'payments/FETCH_GATEWAY_TRANSACTION_FAILURE';

export const FETCH_STORES = 'payments/FETCH_STORES';
export const FETCH_STORES_SUCCESS = 'payments/FETCH_STORES_SUCCESS';
export const FETCH_STORES_FAILURE = 'payments/FETCH_STORES_FAILURE';

export const FETCH_CSV = 'payments/FETCH_CSV';
export const FETCH_CSV_SUCCESS = 'payments/FETCH_CSV_SUCCESS';
export const FETCH_CSV_FAILURE = 'payments/FETCH_CSV_FAILURE';

export const FETCH_INDIVIDUAL_SETTLEMENT_CSV =
  'payments/FETCH_INDIVIDUAL_SETTLEMENT_CSV';
export const FETCH_INDIVIDUAL_SETTLEMENT_CSV_SUCCESS =
  'payments/FETCH_INDIVIDUAL_SETTLEMENT_CSV_SUCCESS';
export const FETCH_INDIVIDUAL_SETTLEMENT_CSV_FAILURE =
  'payments/FETCH_INDIVIDUAL_SETTLEMENT_CSV_FAILURE';

export const FETCH_KEYS = 'payments/FETCH_KEYS';
export const FETCH_KEYS_SUCCESS = 'payments/FETCH_KEYS_SUCCESS';
export const FETCH_KEYS_FAILURE = 'payments/FETCH_KEYS_FAILURE';

export const FETCH_CHARGES = 'payments/FETCH_CHARGES';
export const FETCH_CHARGES_SUCCESS = 'payments/FETCH_CHARGES_SUCCESS';
export const FETCH_CHARGES_FAILURE = 'payments/FETCH_CHARGES_FAILURE';

export const FETCH_CUSTOMERS = 'payments/FETCH_CUSTOMERS';
export const FETCH_CUSTOMERS_SUCCESS = 'payments/FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILURE = 'payments/FETCH_CUSTOMERS_FAILURE';

export const ADD_KEY = 'payments/ADD_KEY';
export const ADD_KEY_SUCCESS = 'payments/ADD_KEY_SUCCESS';
export const ADD_KEY_FAILURE = 'payments/ADD_KEY_FAILURE';

export const DELETE_KEY = 'payments/DELETE_KEY';
export const DELETE_KEY_SUCCESS = 'payments/DELETE_KEY_SUCCESS';
export const DELETE_KEY_FAILURE = 'payments/DELETE_KEY_FAILURE';

export const ADD_NOTES = 'payments/ADD_NOTES';
export const ADD_NOTES_SUCCESS = 'payments/ADD_NOTES_SUCCESS';
export const ADD_NOTES_FAILURE = 'payments/ADD_NOTES_FAILURE';

export const CREATE_TERMINAL = 'payments/CREATE_TERMINAL';
export const CREATE_TERMINAL_SUCCESS = 'payments/CREATE_TERMINAL_SUCCESS';
export const CREATE_TERMINAL_FAILURE = 'payments/CREATE_TERMINAL_FAILURE';

export const CREATE_INTERNAL_USER = 'payments/CREATE_INTERNAL_USER';
export const CREATE_INTERNAL_USER_SUCCESS =
  'payments/CREATE_INTERNAL_USER_SUCCESS';
export const CREATE_INTERNAL_USER_FAILURE =
  'payments/CREATE_INTERNAL_USER_FAILURE';

export const FETCH_INDIVIDUAL_CUSTOMER = 'payments/FETCH_INDIVIDUAL_CUSTOMER';
export const FETCH_INDIVIDUAL_CUSTOMER_SUCCESS =
  'payments/FETCH_INDIVIDUAL_CUSTOMER_SUCCESS';
export const FETCH_INDIVIDUAL_CUSTOMER_FAILURE =
  'payments/FETCH_INDIVIDUAL_CUSTOMER_FAILURE';

export const RESET = 'payments/RESET';

export const FETCH_INSTORE_TERMINALS = 'payments/FETCH_INSTORE_TERMINALS';
export const FETCH_INSTORE_TERMINALS_SUCCESS =
  'payments/FETCH_INSTORE_TERMINALS_SUCCESS';
export const FETCH_INSTORE_TERMINALS_FAILURE =
  'payments/FETCH_INSTORE_TERMINALS_FAILURE';

export const FETCH_INSTORE_CASH_DRAWER = 'payments/FETCH_INSTORE_CASH_DRAWER';
export const FETCH_INSTORE_CASH_DRAWER_FAILURE =
  'payments/FETCH_INSTORE_CASH_DRAWER_FAILURE';
export const FETCH_INSTORE_CASH_DRAWER_SUCCESS =
  'payments/FETCH_INSTORE_CASH_DRAWER_SUCCESS';

export const FETCH_INSTORE_SESSIONS = 'payments/FETCH_INSTORE_SESSIONS';
export const FETCH_INSTORE_SESSIONS_FAILURE =
  'payments/FETCH_INSTORE_SESSIONS_FAILURE';
export const FETCH_INSTORE_SESSIONS_SUCCESS =
  'payments/FETCH_INSTORE_SESSIONS_SUCCESS';

export const UPDATE_SESSION_TICKET = 'payments/UPDATE_SESSION_TICKET';
export const UPDATE_SESSION_TICKET_FAILURE =
  'payments/UPDATE_SESSION_TICKET_FAILURE';
export const UPDATE_SESSION_TICKET_SUCCESS =
  'payments/UPDATE_SESSION_TICKET_SUCCESS';

export const CLEAR_EXPORT = 'payments/CLEAR_EXPORT';

export const FETCH_TRANSACTIONS_SUMMARY = 'payments/FETCH_TRANSACTIONS_SUMMARY';
export const FETCH_TRANSACTIONS_SUMMARY_SUCCESS =
  'payments/FETCH_TRANSACTIONS_SUMMARY_SUCCESS';
export const FETCH_TRANSACTIONS_SUMMARY_FAILURE =
  'payments/FETCH_TRANSACTIONS_SUMMARY_FAILURE';

export const CANCEL_TRANSACTION = 'payments/CANCEL_TRANSACTION';
export const CANCEL_TRANSACTION_SUCCESS = 'payments/CANCEL_TRANSACTION_SUCCESS';
export const CANCEL_TRANSACTION_FAILURE = 'payments/CANCEL_TRANSACTION_FAILURE';
export const RESET_CANCEL_TRANSACTION = 'payments/RESET_CANCEL_TRANSACTION';
export const TIPS = 'payments/TIPS';
export const TIPS_SUCCESS = 'payments/TIPS_SUCCESS';
export const TIPS_FAILURE = 'payments/TIPS_FAILURE';
export const TIPS_SUMMARY = 'payments/TIPS_SUMMARY';
export const TIPS_SUMMARY_SUCCESS = 'payments/TIPS_SUMMARY_SUCCESS';
export const TIPS_SUMMARY_FAILURE = 'payments/TIPS_SUMMARY_FAILURE';

export const action = (type: string): IAction => ({
  type,
});

export const actionSuccess = <T>(type: string, payload: T): IAction => ({
  type: `${type}_SUCCESS`,
  payload,
});

export const actionFailure = <T>(type: string, payload: T): IAction => ({
  type: `${type}_FAILURE`,
  payload,
});

export const addNotes = (): IAction => ({
  type: ADD_NOTES,
});

export const addNotesSuccess = <T>(payload: T): IAction => ({
  type: ADD_NOTES_SUCCESS,
  payload,
});

export const addNotesFailure = <T>(payload: T): IAction => ({
  type: ADD_NOTES_FAILURE,
  payload,
});

export const createTerminal = (): IAction => ({
  type: CREATE_TERMINAL,
});

export const createTerminalSuccess = <T>(payload: T): IAction => ({
  type: CREATE_TERMINAL_SUCCESS,
  payload,
});

export const createTerminalFailure = <T>(payload: T): IAction => ({
  type: CREATE_TERMINAL_FAILURE,
  payload,
});

export const addKey = (): IAction => ({
  type: ADD_KEY,
});

export const addKeySuccess = <T>(payload: T): IAction => ({
  type: ADD_KEY_SUCCESS,
  payload,
});

export const addKeyFailure = <T>(
  payload: T,
  isPartnerKey: boolean
): IAction => ({
  type: ADD_KEY_FAILURE,
  payload: { error: payload, isPartnerKey },
});

export const fetchTransactions = (): IAction => ({
  type: FETCH_TRANSACTIONS,
});

export const fetchTransactionsSuccess = <T>(payload: T): IAction => ({
  type: FETCH_TRANSACTIONS_SUCCESS,
  payload,
});

export const fetchTransactionsFailure = <T>(payload: T): IAction => ({
  type: FETCH_TRANSACTIONS_FAILURE,
  payload,
});

export const fetchInStore = (): IAction => ({
  type: FETCH_INSTORE_TERMINALS,
});

export const fetchInStoreTerminalSuccess = <T>(payload: T): IAction => ({
  type: FETCH_INSTORE_TERMINALS_SUCCESS,
  payload,
});

export const fetchInStoreTerminalFailure = <T>(payload: T): IAction => ({
  type: FETCH_INSTORE_TERMINALS_FAILURE,
  payload,
});

export const fetchInStoreCashDrawer = (): IAction => ({
  type: FETCH_INSTORE_CASH_DRAWER,
});

export const fetchInStoreCashDrawerSuccess = <T>(payload: T): IAction => ({
  type: FETCH_INSTORE_CASH_DRAWER_FAILURE,
  payload,
});

export const fetchInStoreCashDrawerFailure = <T>(payload: T): IAction => ({
  type: FETCH_INSTORE_CASH_DRAWER_SUCCESS,
  payload,
});

export const fetchCharges = (): IAction => ({
  type: FETCH_CHARGES,
});

export const fetchChargesSuccess = <T>(payload: T): IAction => ({
  type: FETCH_CHARGES_SUCCESS,
  payload,
});

export const fetchChargesFailure = <T>(payload: T): IAction => ({
  type: FETCH_CHARGES_FAILURE,
  payload,
});

export const fetchCustomers = (): IAction => ({
  type: FETCH_CUSTOMERS,
});

export const fetchCustomersSuccess = <T>(payload: T): IAction => ({
  type: FETCH_CUSTOMERS_SUCCESS,
  payload,
});

export const fetchCustomersFailure = <T>(payload: T): IAction => ({
  type: FETCH_CUSTOMERS_FAILURE,
  payload,
});

export const fetchCSV = (): IAction => ({
  type: FETCH_CSV,
});

export const fetchCSVSuccess = <T>(payload: T): IAction => ({
  type: FETCH_CSV_SUCCESS,
  payload,
});

export const fetchCSVFailure = <T>(payload: T): IAction => ({
  type: FETCH_CSV_FAILURE,
  payload,
});

export const fetchStores = (): IAction => ({
  type: FETCH_STORES,
});

export const fetchStoresSuccess = <T>(payload: T): IAction => ({
  type: FETCH_STORES_SUCCESS,
  payload,
});

export const fetchStoresFailure = <T>(payload: T): IAction => ({
  type: FETCH_STORES_FAILURE,
  payload,
});

export const fetchIndividualTransaction = (): IAction => ({
  type: FETCH_INDIVIDUAL_TRANSACTIONS,
});

export const fetchIndividualTransactionSuccess = <T>(payload: T): IAction => ({
  type: FETCH_INDIVIDUAL_TRANSACTIONS_SUCCESS,
  payload,
});

export const fetchIndividualTransactionFailure = <T>(payload: T): IAction => ({
  type: FETCH_INDIVIDUAL_TRANSACTIONS_FAILURE,
  payload,
});

export const fetchKeys = (): IAction => ({
  type: FETCH_KEYS,
});

export const fetchKeysSuccess = <T>(payload: T): IAction => ({
  type: FETCH_KEYS_SUCCESS,
  payload,
});

export const fetchKeysFailure = <T>(payload: T): IAction => ({
  type: FETCH_KEYS_FAILURE,
  payload,
});

export const fetchIndividualCustomers = (): IAction => ({
  type: FETCH_INDIVIDUAL_CUSTOMER,
});

export const fetchIndividualCustomersSuccess = <T>(payload: T): IAction => ({
  type: FETCH_INDIVIDUAL_CUSTOMER_SUCCESS,
  payload,
});

export const fetchIndividualCustomersFailure = <T>(payload: T): IAction => ({
  type: FETCH_INDIVIDUAL_CUSTOMER_FAILURE,
  payload,
});

export const reset = (): IAction => ({
  type: RESET,
});
