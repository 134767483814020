import { apiWithEntityId, apiWithOrg } from 'services/api';
import { useStateValue } from '../..';
import {
  CREATE_PAYMENT_DEVICE,
  DELETE_PAYMENT_DEVICE,
  FETCH_PAYMENT_SOLUTIONS,
  GET_CARD_READER_CONFIG,
  GET_CARD_READER_VALUES,
  GET_DEFAULT_PROVIDER,
  GET_PAYMENT_DEVICE,
  RESET_CARD_READER_VALUES,
  RESET_DEVICE_CREATED,
  RESET_DEVICE_DELETED,
  RESET_DEVICE_STATUS,
  RESET_DEVICE_UPDATED,
  RESET_PAYMENT_DEVICE_LIST,
  RESET_SAVE_CARD_READER_VALUES,
  RESET_SAVE_DEFAULT_CARD_READER_VALUES,
  SAVE_CARD_READER_VALUES,
  SAVE_DEFAULT_CARD_READER_VALUES,
  SET_FILTERED_LIST,
  TEST_PAYMENT_DEVICE,
  UPDATE_PAYMENT_DEVICE,
  action,
  actionFailure,
  actionSuccess,
} from '../actions';
import { PAYMENT_CONFIG } from '../type';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const usePaymentConfig = () => {
  const { state, dispatch } = useStateValue();
  const { paymentConfig } = state;

  const toFetchPaymentSolutions = async () => {
    dispatch(action(FETCH_PAYMENT_SOLUTIONS));
    try {
      const response = await apiWithEntityId.get(
        `/adminEntityProcessorConfiguration`
      );
      dispatch(actionSuccess(FETCH_PAYMENT_SOLUTIONS, response?.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_PAYMENT_SOLUTIONS, e));
    }
  };

  const toFetchCardReaderValues = async (id: string) => {
    dispatch(action(GET_CARD_READER_VALUES));
    try {
      const response = await apiWithEntityId.get(
        `/entityConfigurations?processorId=${id}`
      );
      dispatch(actionSuccess(GET_CARD_READER_VALUES, response?.data));
    } catch (e) {
      dispatch(actionFailure(GET_CARD_READER_VALUES, e));
    }
  };

  const toFetchDefaultProviders = async () => {
    dispatch(action(GET_DEFAULT_PROVIDER));
    try {
      const response = await apiWithEntityId.get(`/defaultProcessors`);
      dispatch(actionSuccess(GET_DEFAULT_PROVIDER, response?.data));
    } catch (e) {
      dispatch(actionFailure(GET_DEFAULT_PROVIDER, e));
    }
  };

  const toCreatePaymentDeviceLocation = async (
    values:
      | PAYMENT_CONFIG.ICreatePaymentDeviceSimple
      | PAYMENT_CONFIG.ICreatePaymentDeviceFull
  ) => {
    dispatch(action(CREATE_PAYMENT_DEVICE));
    try {
      const response = await apiWithEntityId.post(
        `/paymentDeviceLocations`,
        values
      );
      dispatch(actionSuccess(CREATE_PAYMENT_DEVICE, response?.data));
    } catch (e) {
      dispatch(actionFailure(CREATE_PAYMENT_DEVICE, e));
    }
  };

  const saveGlobalCardReaderValues = async (
    values: PAYMENT_CONFIG.IGlobalCardReader
  ) => {
    dispatch(action(SAVE_CARD_READER_VALUES));
    try {
      const response = await apiWithEntityId.post(
        `/entityConfigurations`,
        values
      );
      dispatch(actionSuccess(SAVE_CARD_READER_VALUES, response?.data));
    } catch (e) {
      dispatch(actionFailure(SAVE_CARD_READER_VALUES, e));
    }
  };
  const updateGlobalCardReaderValues = async (
    values: PAYMENT_CONFIG.IGlobalCardReader
  ) => {
    dispatch(action(SAVE_CARD_READER_VALUES));
    try {
      const response = await apiWithEntityId.put(
        `/entityConfigurations`,
        values
      );
      dispatch(actionSuccess(SAVE_CARD_READER_VALUES, response?.data));
    } catch (e) {
      dispatch(actionFailure(SAVE_CARD_READER_VALUES, e));
    }
  };

  const setGlobalCardReaderDefault = async (
    values: PAYMENT_CONFIG.IGlobalCardReaderDefault
  ) => {
    dispatch(action(SAVE_DEFAULT_CARD_READER_VALUES));
    try {
      const response = await apiWithEntityId.post(`/defaultProcessors`, values);
      dispatch(actionSuccess(SAVE_DEFAULT_CARD_READER_VALUES, response?.data));
    } catch (e) {
      dispatch(actionFailure(SAVE_DEFAULT_CARD_READER_VALUES, e));
    }
  };

  const toGetPaymentDeviceLocation = async (
    processorName: string
  ) => {
    dispatch(action(GET_PAYMENT_DEVICE));
    try {
      const response = await apiWithEntityId.get(
        `/paymentDeviceLocations/?processorNames=${processorName}`
      );

      dispatch(actionSuccess(GET_PAYMENT_DEVICE, response?.data));
    } catch (e) {
      dispatch(actionFailure(GET_PAYMENT_DEVICE, e));
    }
  };

  const toUpdatePaymentDeviceLocation = async (
    values:
      | PAYMENT_CONFIG.ICreatePaymentDeviceSimple
      | PAYMENT_CONFIG.ICreatePaymentDeviceFull,
    id: string
  ) => {
    dispatch(action(UPDATE_PAYMENT_DEVICE));
    try {
      const response = await apiWithEntityId.put(`/paymentDeviceLocations`, {
        ...values,
        id,
      });
      dispatch(actionSuccess(UPDATE_PAYMENT_DEVICE, response));
    } catch (e) {
      dispatch(actionFailure(UPDATE_PAYMENT_DEVICE, e));
    }
  };
  const toDeletePaymentDeviceLocation = async (id: string) => {
    dispatch(action(DELETE_PAYMENT_DEVICE));
    try {
      const response = await apiWithEntityId.remove(
        `/paymentDeviceLocations/${id}`
      );
      dispatch(actionSuccess(DELETE_PAYMENT_DEVICE, response?.data));
    } catch (e) {
      dispatch(actionFailure(DELETE_PAYMENT_DEVICE, e));
    }
  };

  const toTestPaymentDeviceLocation = async (
    id: string,
    processorName: string
  ) => {
    dispatch(action(TEST_PAYMENT_DEVICE));
    try {
      const response = await apiWithEntityId.get(
        `/paymentDeviceLocations/${id}/status`,
        { processorName }
      );
      dispatch(actionSuccess(TEST_PAYMENT_DEVICE, response?.data));
    } catch (e) {
      dispatch(actionFailure(TEST_PAYMENT_DEVICE, e));
    }
  };

  const getCardReaderConfig = async (filter: string) => {
    dispatch(action(GET_CARD_READER_CONFIG));
    try {
      const response = await apiWithEntityId.get(
        `/processors/${filter}/configuration`
      );
      dispatch(actionSuccess(GET_CARD_READER_CONFIG, response?.data));
    } catch (e) {
      dispatch(actionFailure(GET_CARD_READER_CONFIG, e));
    }
  };

  const updatedFilterList = (list: PAYMENT_CONFIG.IProcessors[]) => {
    dispatch(actionSuccess(SET_FILTERED_LIST, list));
  };

  const resetDeviceCreated = () => {
    dispatch(action(RESET_DEVICE_CREATED));
  };
  const resetDeviceupdated = () => {
    dispatch(action(RESET_DEVICE_UPDATED));
  };

  const resetSaveCardReader = () => {
    dispatch(action(RESET_SAVE_CARD_READER_VALUES));
  };
  const resetSaveDefaultCardReader = () => {
    dispatch(action(RESET_SAVE_DEFAULT_CARD_READER_VALUES));
  };
  const resetDeviceDelete = () => {
    dispatch(action(RESET_DEVICE_DELETED));
  };

  const resetCardReaderValues = () => {
    dispatch(action(RESET_CARD_READER_VALUES));
  };

  const resetPaymentDeviceList = () => {
    dispatch(action(RESET_PAYMENT_DEVICE_LIST));
  };

  const resetDeviceStatus = () => {
    dispatch(action(RESET_DEVICE_STATUS));
  };

  return {
    paymentConfig,
    toFetchPaymentSolutions,
    toFetchDefaultProviders,
    toCreatePaymentDeviceLocation,
    toGetPaymentDeviceLocation,
    toUpdatePaymentDeviceLocation,
    toTestPaymentDeviceLocation,
    updatedFilterList,
    resetDeviceCreated,
    resetDeviceupdated,
    toDeletePaymentDeviceLocation,
    resetDeviceDelete,
    toFetchCardReaderValues,
    saveGlobalCardReaderValues,
    updateGlobalCardReaderValues,
    setGlobalCardReaderDefault,
    resetSaveCardReader,
    resetSaveDefaultCardReader,
    resetCardReaderValues,
    resetPaymentDeviceList,
    resetDeviceStatus,
    getCardReaderConfig,
  };
};

export default usePaymentConfig;
