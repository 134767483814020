import classNames from 'classnames';
import Filter from 'components/filter';
import { EFilterConfigType, IFilterConfig } from 'components/filter/types';
import PageHeading from 'components/heading';
import Pagination from 'components/pagination';
import Search from 'components/search';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import BootstrapTable, { SortOrder } from 'react-bootstrap-table-next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from 'reactstrap';
import useAuth from 'state/auth/hooks/useAuth';
import useBanking from 'state/banking/hooks/useBanking';
import { BANKING } from 'state/banking/type';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import { PAYOUTTYPE } from 'components/modals/banking/payouts/payout';
import CURRENCY from 'utils/currency';
import useQuery from 'utils/hooks/useQuery';
import QUERY from 'utils/query';
import { payoutsStatusFormatter } from 'utils/status-formatter';
import Summary from 'components/summary-tab';
import { SUMMARY } from 'state/summary/type';
import { statusListPayouts } from 'utils/constants';
import { withAdminRole } from 'components/rbac/helper';
import AddBankAccountBanner from 'views/banking/statements/add-bank-account-banner';
import { fromDate, todayDate } from 'views/banking/statements';
import PayoutInfo from 'components/modals/banking/payouts';
import routeConfig from 'routes/config';
import { extractDigitValues, maskedData } from 'utils/pii';
import ToastContainer from 'components/toasts/container';
import { formatToTz } from '../../utils/date-format';
import styles from './payouts.module.scss';
import PayoutAccordion from './summary-accordion';
import PayoutBalanceError from 'components/modals/banking/payoutError';
import * as util from 'utils/dashboard'; // skipcq: JS-C1003

interface IProps {
  dateFrom?: string;
  dateTo?: string;
  invoiceDateFrom?: string;
  invoiceDateTo?: string;
  queryParams: string;
}

const Payouts = ({
  invoiceDateFrom,
  invoiceDateTo,
  queryParams,
  dateFrom,
  dateTo,
}: IProps): JSX.Element => {
  const navigate = useNavigate();
  const urlParams = useQuery(queryParams);
  const heading = 'Payouts';
  const subHeading = 'View all payouts here.';

  const tabNames = [
    {
      label: 'Summary',
      filters: [],
      tooltip: '',
    },
    {
      label: 'Details',
      filters: [],
      tooltip: 'All payouts initiated.',
    },
  ];

  const [activeTab, setActiveTab] = useState(
    urlParams.get('tab')?.toLowerCase() ||
      (tabNames.length > 0 && tabNames[0].label) ||
      ''
  );

  const dateValues: string[] = [];
  const invoiceDateVaues: string[] = [];

  if (dateFrom) {
    dateValues[0] = dateFrom;
  }
  if (dateTo) {
    dateValues[1] = dateTo;
  }
  if (invoiceDateFrom) {
    invoiceDateVaues[0] = invoiceDateFrom;
  }
  if (invoiceDateTo) {
    invoiceDateVaues[1] = invoiceDateTo;
  }
  const searchString = urlParams.get('search') || undefined;

  const location = useLocation();
  const url_query = useQuery(location.search);

  const statusArray = url_query.get('status')?.split(',') || [];

  const getFilterConfig = () => {
    return [
      {
        key: 'dates',
        label: 'Create Date',
        type: EFilterConfigType.date,
        data: {
          keys: [],
          values: dateValues,
        },
        selected: false,
      },
      {
        key: 'invoice_date',
        label: 'Invoice Date',
        type: EFilterConfigType.date,
        data: {
          keys: [],
          values: dateValues,
        },
        selected: false,
      },
      {
        key: 'status',
        label: 'Status',
        type: EFilterConfigType.list,
        data: {
          key: 'status',
          options: statusListPayouts,
          values: statusArray,
        },
        selected: false,
      },
    ];
  };

  const defaultFilterConfig: IFilterConfig[] = getFilterConfig();
  const [intervalTypes, setIntervalTypes] = useState<SUMMARY.IIntervalType[]>(
    []
  );

  useEffect(() => {
    const interval = util.intervalInDays(
      dateFrom || fromDate,
      dateTo || todayDate
    );
    setIntervalTypes(util.mapIntervalToFrequency(interval));
  }, []);

  const [payoutFlow, setPayoutFlow] = useState(false);

  const {
    banking,
    toFetchPayouts,
    toFetchExternalAccount,
    toFetchBankingStats,
    resetPayoutSuccess,
    toFetchPayoutsSummaryData,
    resetPayout,
  } = useBanking();

  const { stats } = banking;

  const { organisation } = useOrganisation();
  const { activeOrg, settings } = organisation;

  const { auth, resetOtp } = useAuth();

  const { isSandbox } = auth;

  const filterConfig = defaultFilterConfig;
  // Next and prev key
  const [prevKey, setPrevKey] = useState<string | null>(null);
  const [nextKey, setNextKey] = useState<string | null>(null);

  // Number of results
  const [numResults, setNumResults] = useState(0);

  const [payoutError, setPayoutError] = useState(false);

  const [availableBalance, setAvailableBalance] = useState<number>(
    CURRENCY.totalAvailableBalance(stats.total_balance, stats.holding_balance)
  );

  // get to prev page
  const prevPage = () => {
    toFetchPayouts({
      key: prevKey,
      start_date: dateFrom || undefined,
      end_date: dateTo || undefined,
      invoice_start_date: invoiceDateFrom || undefined,
      invoice_end_date: invoiceDateTo || undefined,
      search_string: searchString || undefined,
      status: statusArray.length > 0 ? statusArray.join(',') : undefined,
    });
  };

  useEffect(() => {
    setAvailableBalance(
      CURRENCY.totalAvailableBalance(stats.total_balance, stats.holding_balance)
    );
  }, [stats.holding_balance, stats.total_balance]);

  // get to next page
  const nextPage = () => {
    toFetchPayouts({
      key: nextKey,
      start_date: dateFrom || undefined,
      end_date: dateTo || undefined,
      invoice_start_date: invoiceDateFrom || undefined,
      invoice_end_date: invoiceDateTo || undefined,
      search_string: searchString,
      status: statusArray.length > 0 ? statusArray.join(',') : undefined,
    });
  };

  const loadPayouts = () => {
    const tab = tabNames.filter(
      (currentTab) => currentTab.filters.join() === statusArray.join()
    );

    const queryTabParam = urlParams.get('tab')?.toLowerCase();

    if (location.search && queryTabParam) {
      setActiveTab(
        tabNames.find((t) => t.label.toLowerCase() === queryTabParam)?.label ||
          (tab.length > 0 && tab[0].label) ||
          ''
      );
    } else {
      navigate({
        search: '?tab=summary',
      });
    }

    toFetchPayouts({
      start_date: dateFrom || undefined,
      end_date: dateTo || undefined,
      invoice_start_date: invoiceDateFrom || undefined,
      invoice_end_date: invoiceDateTo || undefined,
      search_string: searchString || undefined,
      status: statusArray.length > 0 ? statusArray.join(',') : undefined,
    });
  };

  const defaultSorted: [{ dataField: string; order: SortOrder }] = [
    {
      dataField: 'date',
      order: 'desc',
    },
  ];
  // Taking actions on row events
  const rowEvents = {
    onClick: (e: SyntheticEvent<Element, Event>, row: BANKING.IPayout) => {
      navigate(`../../${routeConfig.PAYOUTS.layout}/${row.id}`);
    },
  };

  const columns = [
    {
      dataField: 'create_date',
      text: 'Initiated Date',
      sort: true,
      formatter: (cell: string) => {
        return (
          <>
            {cell
              ? formatToTz(cell, settings?.timezone, 'MMM dd, hh:mm a')
              : ''}
          </>
        );
      },
    },
    {
      dataField: 'invoice_date',
      text: 'Invoice Date',
      sort: true,
      formatter: (cell: string) => {
        return (
          <>
            {cell
              ? formatToTz(cell, settings?.timezone, 'MMM dd, hh:mm a')
              : ''}
          </>
        );
      },
    },
    {
      dataField: 'amount',
      text: 'Amount',
      sort: true,
      formatter: (cell: number) => {
        return (
          <div className="currencyColumn">
            {CURRENCY.convertToMainUnit(cell)}
          </div>
        );
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: payoutsStatusFormatter,
    },
    {
      dataField: 'vendor_name',
      text: 'Paid to',
      sort: true,
      formatter: (cell: string) => {
        return <div className="text-capitalize">{cell || 'NA'}</div>;
      },
    },
    {
      dataField: 'invoice_number',
      text: 'Invoice number',
      formatter: (cell: string) => {
        return <>{cell || ''}</>;
      },
    },
  ];

  const payoutInitiateCheck = () => {
    if (availableBalance > 10) {
      resetPayoutSuccess();
      setPayoutFlow(true);
    } else {
      setPayoutError(true);
    }
  };

  const initiatePayoutBtn = withAdminRole(() => (
    <Button
      color="primary"
      className={classNames(
        'd-flex align-items-center body-subtext-medium pr-2'
      )}
      disabled={!banking.fetchExternalAccount}
      onClick={payoutInitiateCheck}
    >
      Make payment
      <i className="ph-arrow-right-bold icon-lg ml-1 pr-1"></i>
    </Button>
  ));

  const applyStatusFilter = (statuses: string[]) => {
    const updatedFilters = filterConfig.map((item) => {
      if (item.key === 'status') {
        item.data.values = statuses;
        item.selected = true;
      }
      return item;
    });

    const query: {
      [key: string]: string;
    } = {};
    updatedFilters.forEach((item) => {
      const { key, data, selected, type } = item;
      if (selected && data.values.length > 0) {
        if (type === EFilterConfigType.number) {
          const condition = data.values.shift();
          query.condition = condition || '';
        }
        query[key] = data.values.join();
      }
    });
    navigate({ search: QUERY.update(location.search, query) });
  };

  const addBankAccountBanner = withAdminRole(AddBankAccountBanner);

  const [showToast, setShowToast] = useState<boolean>(false);

  const closeToast = (arg: boolean) => {
    resetPayout();
    resetPayoutSuccess();
    setShowToast(arg);
    window.location.reload();
  };

  const successMessageSelfPayout = `${
    banking?.payout_amount
  } paid out to ${maskedData(
    extractDigitValues(
      banking?.external_account?.receiver_account_details?.masked_account_number
    ) || 'NA'
  )}`;
  const successMessageExternalPayout = `${
    banking?.payout_amount
  } paid out to ${maskedData(
    extractDigitValues(banking?.vendor_bank_account?.masked_account_number) ||
      'NA'
  )}`;

  const subDetails = (
    <>
      <span className={styles.subDetails}>
        (Payouts take 2-3 banking business days to settle)
      </span>
    </>
  );

  useEffect(() => {
    if (banking?.auth_session?.id) {
      setShowToast(true);
      resetOtp();
    }
  }, [banking?.auth_session?.id]);

  useEffect(() => {
    toFetchExternalAccount();
  }, []);

  useEffect(() => {
    loadPayouts();
  }, [activeOrg?.id, queryParams]);

  useEffect(() => {
    toFetchBankingStats({
      start_date: fromDate,
      end_date: todayDate,
    });
  }, []);

  useEffect(() => {
    if (activeTab === tabNames[0].label && intervalTypes.length > 0) {
      toFetchPayoutsSummaryData({
        start_date: dateFrom || fromDate,
        end_date: dateTo || todayDate,
        interval_types: intervalTypes.join(),
      });
    }
  }, [dateFrom, dateTo, intervalTypes, activeTab]);

  useEffect(() => {
    if (banking?.payoutList?.items) {
      setNextKey(banking.payoutList?.next_key);
      setPrevKey(banking.payoutList?.prev_key);
      setNumResults(banking.payoutList?.total_count);
    }
  }, [banking?.payoutList]);

  return (
    <>
      {!banking.external_account?.receiver_account_details &&
        !isSandbox &&
        banking.fetchExternalAccount &&
        addBankAccountBanner}
      <div className="d-flex align-items-center justify-content-between">
        <PageHeading heading={heading} subHeading={subHeading} />
        {!isSandbox && initiatePayoutBtn}
      </div>
      <div className={classNames(styles.summaryHeading)}>USD Balance</div>
      <>
        <div className={classNames(styles.summaryContainer)}>
          <div className={classNames(styles.summaryBanner)}>
            <div className={classNames(styles.payoutBalance)}>
              <span className="d-flex">Total balance</span>
              {CURRENCY.convertToMainUnit(stats?.total_balance || 0)}
            </div>
            <div className={classNames(styles.payoutBalance)}>
              <span className={styles.dashedBorder} id="holdingInfo">
                Holding balance
              </span>
              ({CURRENCY.convertToMainUnit(stats?.holding_balance || 0)})
            </div>
            <div className={classNames(styles.payoutAvailableBalance, 'mt-2')}>
              <span className="d-flex pt-1">Total available balance</span>
              <span className="pt-1">
                {CURRENCY.convertToMainUnit(availableBalance || 0)}
              </span>
            </div>
          </div>
        </div>
        <UncontrolledTooltip placement="right" target="holdingInfo">
          The funds are on hold to maintain a minimum balance to cover future
          refunds, possibility of negative balance, etc.
        </UncontrolledTooltip>
      </>
      <div className={classNames(styles.tabContainer)}>
        <Nav tabs>
          {tabNames.map((item) => {
            return (
              <>
                <NavItem>
                  <NavLink
                    className={classNames({
                      active: item?.label === activeTab,
                    })}
                    data-testid={`${item.label.toLowerCase()}-tab`}
                    id={`${item.label.toLowerCase()}-tab`}
                    onClick={() => {
                      navigate({
                        search: `?tab=${item.label.toLowerCase()}`,
                      });
                    }}
                  >
                    {item?.label}
                  </NavLink>
                </NavItem>
              </>
            );
          })}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={tabNames[0].label} key={tabNames[0].label}>
            <Summary
              addDateFilter
              rowHeaderValue="value.total_payout_amount"
              rowHeading="Total Payouts"
              dateFrom={dateFrom || ''}
              dateTo={dateTo || ''}
              summaryData={banking.payout_summary}
              expandRowFormatter={(row) => (
                <>{<PayoutAccordion row={row.value} />}</>
              )}
              intervalTypes={intervalTypes}
              toSetIntervalTypes={setIntervalTypes}
            />
          </TabPane>
          <TabPane tabId={tabNames[1]?.label} key={tabNames[1].label}>
            <div className="d-flex mb-2">
              <span className="mr-auto">
                <Search queryParams={queryParams} />
              </span>
              <span>
                {filterConfig && (
                  <Filter
                    config={filterConfig}
                    queryParams={queryParams}
                  ></Filter>
                )}
              </span>
            </div>
            <BootstrapTable
              wrapperClasses="table-responsive"
              keyField="_id"
              data={banking?.payoutList?.items || []}
              columns={columns}
              defaultSorted={defaultSorted}
              rowEvents={rowEvents}
              hover
              condensed
              bordered={false}
              noDataIndication={
                <p className="text-center text-regular">No results found</p>
              }
            />

            <Pagination
              results={numResults}
              prevPage={prevPage}
              nextPage={nextPage}
              prevKey={prevKey}
              nextKey={nextKey}
            />
          </TabPane>
        </TabContent>
        <PayoutInfo isOpen={payoutFlow} onClose={() => setPayoutFlow(false)} />
        {payoutError && (
          <PayoutBalanceError
            openModal={payoutError}
            setModal={setPayoutError}
            availableBalance={availableBalance}
          />
        )}
        {banking.payout_type === PAYOUTTYPE.EXTERNAL && (
          <ToastContainer
            showToast={showToast}
            onClose={() => closeToast(false)}
            message={successMessageExternalPayout}
            details={subDetails}
            showCross
          />
        )}
        {banking.payout_type === PAYOUTTYPE.SELF && (
          <ToastContainer
            showToast={showToast}
            onClose={() => closeToast(false)}
            message={successMessageSelfPayout}
            details={subDetails}
            showCross
          />
        )}
      </div>
    </>
  );
};

export default Payouts;
