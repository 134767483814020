import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import styles from '../tips/tips.module.scss';
import usePayment from 'state/payment/hooks/usePayment';
import { SUMMARY } from 'state/summary/type';
import { Moment } from 'moment';
import moment from 'moment';

interface IProps {
  dateFrom?: string;
  dateTo?: string;
  prevFromDate?: string;
  prevToDate?: string;
  summaryType: string;
  intervalTypes: SUMMARY.IIntervalType[];
  tab: string;
  amount_1: string | undefined;
  amount_2: string | undefined;
  condition: string | undefined;
  paymentTypeArray: string[];
  searchString: string | undefined;
  pageKey: string | undefined;
}

const TipHeading = ({
  dateFrom,
  dateTo,
  prevFromDate,
  prevToDate,
  summaryType,
  intervalTypes,
  tab,
  amount_1,
  amount_2,
  condition,
  searchString,
  paymentTypeArray,
  pageKey,
}: IProps): JSX.Element => {
  const fromDate = moment().subtract(7, 'day').startOf('day');
  const todayDate = moment().endOf('day')
  const { toFetchTipsSummary, toFetchTipsList } = usePayment();

  const [timer, setTimer] = useState(0);
  const [lastUpdateMessage, setLastUpdateMessage] = useState(
    'Last updated a few seconds ago'
  );

  useEffect(() => {
    const second = timer < 60 ? timer : 0;
    const minute = timer >= 60 && timer <= 3540 ? Math.floor(timer / 60) : 1;
    if (second != 0) {
      setLastUpdateMessage('Last updated a few seconds ago');
    } else {
      setLastUpdateMessage(`Last updated ${minute} minute ago`);
    }
    const timerInterval = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 3600);

    return () => clearInterval(timerInterval);
  }, [timer]);

  return (
    <>
      <div className="d-flex">
        <h1 id="page-heading" className={classNames(styles.PageHeadingText)}>
          Tips
        </h1>
        <div
          className={classNames(
            'd-flex align-items-center',
            styles.textStyleAllText
          )}
        >
          <i className={classNames('ph-clock icon-lg')}></i>
          <p className={classNames('pl-1 mr-2 pr-1', styles.textStyleAllText)}>
            {lastUpdateMessage}
          </p>
          <p
            className={classNames(styles.refreshButton)}
            onClick={() => {
              if (tab === 'Summary') {
                toFetchTipsSummary({
                  start_date: dateFrom || fromDate.toISOString(),
                  end_date: dateTo || todayDate.toISOString(),
                  summary_type: summaryType,
                  interval_types: intervalTypes.join(),
                  search_string: searchString,
                  prev_start_date: prevFromDate,
                  prev_end_date: prevToDate,
                });
              } else {
                toFetchTipsList({
                  key: pageKey,
                  start_date: dateFrom || undefined,
                  end_date: dateTo || undefined,
                  amount_1: amount_1,
                  amount_2: amount_2,
                  condition: condition || undefined,
                  payment_type:
                    paymentTypeArray.length > 0
                      ? paymentTypeArray.join(',')
                      : undefined,
                  search_string: searchString,
                });
              }
              setTimer(0);
            }}
          >
            <i className={classNames('ph-arrows-clockwise-bold icon-lg')} />
            Refresh
          </p>
        </div>
      </div>
    </>
  );
};

export default TipHeading;
