import {
  ADD_KEY,
  ADD_KEY_FAILURE,
  ADD_KEY_SUCCESS,
  ADD_NOTES,
  ADD_NOTES_FAILURE,
  ADD_NOTES_SUCCESS,
  CANCEL_TRANSACTION,
  CANCEL_TRANSACTION_FAILURE,
  CANCEL_TRANSACTION_SUCCESS,
  CLEAR_EXPORT,
  CREATE_SESSION,
  CREATE_SESSION_FAILURE,
  CREATE_SESSION_SUCCESS,
  DELETE_KEY,
  DELETE_KEY_FAILURE,
  DELETE_KEY_SUCCESS,
  FETCH_CHARGES,
  FETCH_CHARGES_FAILURE,
  FETCH_CHARGES_SUCCESS,
  FETCH_CSV,
  FETCH_CSV_FAILURE,
  FETCH_CSV_SUCCESS,
  FETCH_GATEWAY_TRANSACTION,
  FETCH_GATEWAY_TRANSACTION_FAILURE,
  FETCH_GATEWAY_TRANSACTION_SUCCESS,
  FETCH_INDIVIDUAL_SETTLEMENT_CSV,
  FETCH_INDIVIDUAL_SETTLEMENT_CSV_FAILURE,
  FETCH_INDIVIDUAL_SETTLEMENT_CSV_SUCCESS,
  FETCH_INDIVIDUAL_TRANSACTIONS,
  FETCH_INDIVIDUAL_TRANSACTIONS_FAILURE,
  FETCH_INDIVIDUAL_TRANSACTIONS_SUCCESS,
  FETCH_KEYS,
  FETCH_KEYS_FAILURE,
  FETCH_KEYS_SUCCESS,
  FETCH_SESSIONS,
  FETCH_SESSIONS_FAILURE,
  FETCH_SESSIONS_SUCCESS,
  FETCH_TRANSACTIONS_SUMMARY,
  FETCH_TRANSACTIONS_SUMMARY_FAILURE,
  FETCH_TRANSACTIONS_SUMMARY_SUCCESS,
  FETCH_TRANSACTIONS,
  FETCH_TRANSACTIONS_FAILURE,
  FETCH_TRANSACTIONS_SUCCESS,
  RESET,
  RESET_CANCEL_TRANSACTION,
  TIPS,
  TIPS_FAILURE,
  TIPS_SUCCESS,
  TIPS_SUMMARY,
  TIPS_SUMMARY_FAILURE,
  TIPS_SUMMARY_SUCCESS,
} from './actions';
import { PAYMENTS } from './type';

export const INITIAL_STATE: PAYMENTS.PaymentReducer = {
  isLoading: false,
  error: {} as PAYMENTS.Error,
  keys: {} as PAYMENTS.IPaginatedResponse<PAYMENTS.IApiKey>,
  key: {} as PAYMENTS.IApiKeyResponse,
  charges: {} as PAYMENTS.ICharge,
  transactions: {} as PAYMENTS.ITransactions,
  sessions: {} as PAYMENTS.ISessions,
  individualTransaction: {} as PAYMENTS.IIndividualTransaction,
  addedUser: false,
  addedKey: false,
  revokeKey: false,
  addedNote: false,
  created: false,
  url: '',
  addedSession: null,
  sessionResults: {} as PAYMENTS.ISessionResult,
  transactionSummary: {} as PAYMENTS.ITransactionSummary,
  cancelTransaction: false,
  gatewayTransaction: {} as PAYMENTS.IGatewayTransaction,
  tips: {} as PAYMENTS.ITips,
  tipsSummaryPaymentType: {} as PAYMENTS.TipByPaymentTypeAggregate,
  tipsSummaryEmployeeType: {} as PAYMENTS.TipByEmployeeAggregate,
};

const paymentReducer = (
  action: IAction,
  state = INITIAL_STATE
): PAYMENTS.PaymentReducer => {
  switch (action.type) {
    case FETCH_TRANSACTIONS:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        transactions: action.payload,
        error: {},
      };
    case FETCH_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        transactions: {} as PAYMENTS.ITransactions,
        error: { fetchTransactions: action.payload.message },
      };
    case FETCH_SESSIONS:
      return {
        ...state,
        isLoading: true,
        sessions: {} as PAYMENTS.ISessions,
        error: {},
      };
    case FETCH_SESSIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sessions: action.payload,
        error: {},
      };
    case FETCH_SESSIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        sessions: {} as PAYMENTS.ISessions,
        error: { fetchSessions: action.payload.message },
      };

    case FETCH_CSV:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case FETCH_CSV_SUCCESS:
      return {
        ...state,
        isLoading: false,
        url: action.payload.url,
        error: {},
      };
    case FETCH_CSV_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { addUrl: action.payload.data },
      };
    case FETCH_INDIVIDUAL_SETTLEMENT_CSV:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case FETCH_INDIVIDUAL_SETTLEMENT_CSV_SUCCESS:
      return {
        ...state,
        isLoading: false,
        url: action.payload.url,
        error: {},
      };
    case FETCH_INDIVIDUAL_SETTLEMENT_CSV_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { fetchIndividualCsv: action.payload.message },
      };
    case FETCH_INDIVIDUAL_TRANSACTIONS:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case FETCH_INDIVIDUAL_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        individualTransaction: action.payload,
        error: {},
      };
    case FETCH_INDIVIDUAL_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        individualTransaction: {} as PAYMENTS.IIndividualTransaction,
        error: { addIndividualTransaction: action.payload.message },
      };
    case FETCH_CHARGES:
      return {
        ...state,
        isLoading: true,
        charges: {} as PAYMENTS.ICharge,
        error: {},
      };
    case FETCH_CHARGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        charges: action.payload,
        error: {},
      };
    case FETCH_CHARGES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { charges: action.payload.message },
      };
    case CREATE_SESSION:
      return {
        ...state,
        isLoading: true,
        addedSession: null,
        error: {},
      };
    case CREATE_SESSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addedSession: action.payload,
        error: {},
      };
    case CREATE_SESSION_FAILURE:
      return {
        ...state,
        isLoading: false,
        addedSession: null,
        error: { addSession: action.payload.message },
      };
    case ADD_KEY:
      return {
        ...state,
        isLoading: true,
        error: {},
        addedKey: false,
        key: {} as PAYMENTS.IApiKeyResponse,
      };
    case ADD_KEY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: {},
        key: action.payload,
        addedKey: true,
      };
    case ADD_KEY_FAILURE:
      return {
        ...state,
        isLoading: false,
        key: {} as PAYMENTS.IApiKeyResponse,
        addedKey: false,
        error: {
          [action.payload.isPartnerKey ? 'addKeyPartner' : 'addKeySelf']:
            action.payload?.error?.response?.data,
        },
      };
    case DELETE_KEY:
      return {
        ...state,
        isLoading: true,
        revokeKey: false,
        error: {},
      };
    case DELETE_KEY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        revokeKey: true,
        error: {},
      };
    case DELETE_KEY_FAILURE:
      return {
        ...state,
        isLoading: false,
        revokeKey: false,
        error: { deleteKey: action.payload.message },
      };

    case ADD_NOTES:
      return {
        ...state,
        isLoading: true,
        error: {},
        addedNote: false,
      };
    case ADD_NOTES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: {},
        addedNote: true,
      };
    case ADD_NOTES_FAILURE:
      return {
        ...state,
        isLoading: false,
        addedNote: false,
        error: { addNotes: action.payload.message },
      };

    case FETCH_KEYS:
      return {
        ...state,
        isLoading: true,
        error: {},
        keys: {} as PAYMENTS.IPaginatedResponse<PAYMENTS.IApiKey>,
        addedKey: false,
      };
    case FETCH_KEYS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: {},
        keys: action.payload,
      };
    case FETCH_KEYS_FAILURE:
      return {
        ...state,
        isLoading: false,
        keys: {} as PAYMENTS.IPaginatedResponse<PAYMENTS.IApiKey>,
        error: { fetchKeys: action.payload.message },
      };
    case RESET:
      return {
        ...INITIAL_STATE,
      };
    case CLEAR_EXPORT:
      return {
        ...state,
        url: '',
        error: {
          ...state.error,
          addUrl: undefined,
        },
      };
    case FETCH_TRANSACTIONS_SUMMARY:
      return {
        ...state,
        isLoading: true,
        transactionSummary: {} as PAYMENTS.ITransactionSummary,
      };
    case FETCH_TRANSACTIONS_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        transactionSummary: action.payload,
      };
    case FETCH_TRANSACTIONS_SUMMARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        transactionSummary: {} as PAYMENTS.ITransactionSummary,
        error: { fetchSummaryData: action.payload.message },
      };
    case CANCEL_TRANSACTION:
      return {
        ...state,
        isLoading: true,
        cancelTransaction: false,
        error: {},
      };
    case CANCEL_TRANSACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cancelTransaction: true,
        error: {},
      };
    case CANCEL_TRANSACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        cancelTransaction: false,
        error: { cancelTransaction: action.payload.message },
      };
    case RESET_CANCEL_TRANSACTION:
      return {
        ...state,
        cancelTransaction: false,
        error: {},
      };
    case FETCH_GATEWAY_TRANSACTION:
      return {
        ...state,
        isLoading: true,
        gatewayTransaction: {} as PAYMENTS.IGatewayTransaction,
        error: {},
      };
    case FETCH_GATEWAY_TRANSACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        gatewayTransaction: action.payload.transaction,
        error: {},
      };
    case FETCH_GATEWAY_TRANSACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        gatewayTransaction: {} as PAYMENTS.IGatewayTransaction,
        error: { fetchGatewayTransaction: action.payload.message },
      };
    case TIPS:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case TIPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tips: action.payload,
        error: {},
      };
    case TIPS_FAILURE:
      return {
        ...state,
        isLoading: false,
        tips: {} as PAYMENTS.ITips,
        error: { tip: action.payload.message },
      };
    case TIPS_SUMMARY:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case TIPS_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tipsSummaryPaymentType:
          action.payload.type === 'payment_type'
            ? action.payload.data
            : ({} as PAYMENTS.TipByPaymentTypeAggregate),
        tipsSummaryEmployeeType:
          action.payload.type === 'employee' ? action.payload.data : [],
        error: {},
      };
    case TIPS_SUMMARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        tipsSummaryPaymentType: {} as PAYMENTS.TipByPaymentTypeAggregate,
        tipsSummaryEmployeeType: {} as PAYMENTS.TipByEmployeeAggregate,
        error: { tip: action.payload.message },
      };
    default:
      return state;
  }
};
export default paymentReducer;
