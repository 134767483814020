export enum IOrgStatus {
  KYB_PENDING = 'KYB_PENDING',
  ACTIVATION_PENDING = 'ACTIVATION_PENDING',
  ACTIVATED = 'ACTIVATED',
}

export enum FULFILMENT_STAGES {
  AWAITING_PROCESSING = 'AWAITING_PROCESSING',
  PACKED_READY = 'PACKED_READY',
  IN_PROCESS = 'IN_PROCESS',
}

// skipcq: JS-0337
export declare namespace ORGANISATION {
  export interface IFailure {
    response: { data: { detail: string } };
  }

  export interface Error {
    fetchUsers?: {
      message: string;
    };
    addUser?: string;
    editUser?: string;
    fetchOrgs?: { message: string };
    updatePaymentSettings?: { message: string };
    fetchInvites?: string;
    fetchUserInvites?: string;
    removedInvite?: string;
    removeUser?: string;
    actedInvite?: string;
    fetchOrgRoles?: string;
  }
  type IRoleRequest = {
    email: string;
    role_name: string;
    organization_id: string;
  };

  interface IInvite {
    id: string;
    create_date: string;
    email: string;
    role_name: string;
    inviter_id: string;
    inviter_name: string;
    organization_id: string;
    store_name: string;
    status: string;
    valid_till_date: string;
  }

  export interface IInternalUserRequest {
    name: string;
    email: string;
    id_token: string;
    access_token: string;
  }

  export interface IInternalUser {
    id: string;
    name: string;
    email: string;
    create_date: string;
    first_name?: string;
    last_name?: string;
  }
  interface IRole {
    id: string;
    create_date: string;
    role_name: string;
    organization_id: string;
    internal_user_id: string;
    internal_user: IInternalUser;
  }

  export interface ISettings {
    id: string;
    create_date: string;
    convenience_fee: number;
    organization_id: string;
    has_tip: boolean;
    timezone: string;
    checkout_domain: string;
    checkout_domain_status: string;
    short_link_domain: string;
    short_link_domain_status: string;
    owner_email?: string;
    global_transaction_limit?: number;
    auto_retry: boolean;
    enable_auto_paylink: boolean;
    auto_paylink_min_order_value?: number;
    auto_paylink_fulfilment_stage?: FULFILMENT_STAGES;
  }

  export type ILogisticsProvider = {
    create_date?: string;
    id?: string;
    name?: string;
    client_id: string;
    api_key: string;
    organization_id: string;
    store_id: string;
    logistic_store_identifier: string;
  };

  export interface IBrandSettings {
    brand_name: string;
    create_date: string;
    contact_email: string;
    domain: string;
    id: string;
    logo_url: string;
    name: string;
    organization_id: string;
    privacy_policy_url: string;
    store_identifier: string;
    sub_domain: string;
    tos_url: string;
  }

  export interface IOrg {
    brand_settings: IBrandSettings;
    create_date: string;
    creator_user_id: string;
    id: string;
    is_kyb_collected: boolean;
    is_payment_enabled: boolean;
    logistic_provider: ILogisticsProvider;
    name: string;
    settings: ISettings;
    status: IOrgStatus;
    update_date: string;
    short_identifier: string;
    is_partner?: boolean;
    treez_entity_id: string;
    is_gateway_payment_enabled: boolean;
  }

  export type IFetchRequest = {
    orgId: string;
    startDate?: string;
    endDate?: string;
    store_id?: string;
    key?: string;
    offset?: number;
  };

  export interface IOrgRole {
    id: string;
    name: string; // role_<role_name> format, ex: 'role_admin'
    scopes: string;
    create_date: string;
    update_date: string;
  }

  export interface IRoleItem {
    id: string;
    role_id: string;
    user_id: string;
    organization_id: string;
    create_date: string;
    update_date: string;
    role: IOrgRole;
    organization: {
      create_date: string;
      creator_user_id: string;
      id: string;
      is_kyb_collected: boolean;
      is_payment_enabled: boolean;
      name: string;
      update_date: string;
    };
    internal_user: IInternalUser;
  }

  export interface OrganisationReducer {
    isLoading: boolean;
    error: Error;
    user: IRole;
    users: Array<IRoleItem>;
    invites: Array<IInvite>;
    userInvites: Array<IInvite>;
    activeOrg: IOrg | null;
    activeOrgId: string | null;
    internalUser: IInternalUser;
    addedUser: boolean;
    editedUser: boolean;
    removedUser: boolean;
    removedInvite: boolean;
    actedInvite: boolean;
    settings: ISettings | null;
    orgRoleList: IPaginatedResponse<IOrgRole>;
  }
}
