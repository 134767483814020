export const getinviteParams = (URL: string) => {
  const getQuery = (url: string) => {
    return new URLSearchParams(url);
  };
  // get email and source from url params
  const query = getQuery(URL);

  const params = {
    source: query.get('source'),
    email: decodeURIComponent(query.get('email') || ''),
    invite: query.get('invite'),
    inviteToken: query.get('invite_token'),
    org_id: query.get('org_id'),
    org_name: decodeURIComponent(query.get('org_name') || ''),
  };
  return params;
};
