import React from 'react';
import classNames from 'classnames';
import AdminNavbar from 'components/navbar/admin';
import Sidebar from 'components/sidebar';
import styles from './layouts.module.scss';

const PrivateLayout = ({ children }: { children: JSX.Element }) => {
  return (
    <>
      <Sidebar />
      <div className={classNames('main-content', styles.mainContent)}>
        <AdminNavbar />
        <div className={classNames(styles.container)}>{children}</div>
      </div>
    </>
  );
};

export default PrivateLayout;
