export enum PAYOUTTYPE {
  SELF = 'self_payout',
  EXTERNAL = 'external_payout',
}
export enum SELF_PAYOUT_BANK_STAGES {
  BANK_DETAILS = 'bank_details',
  PAYOUT_DETAILS = 'payout_details',
  REVIEW = 'review',
  CONFIRMATION = 'confirmation',
}
export enum Headings {
  info = 'Make payment',
  self = 'Self payout',
  external = 'External payout',
}
export enum SubHeadings {
  stage_1 = 'Make payment to vendors, suppliers and service providers or pay out funds to your external account.',
  stage_2 = 'Pay out funds to your external account',
  stage_3 = 'Make payment to vendors, suppliers and service providers.',
}
export interface IActiveState {
  footer: JSX.Element;
  heading: Headings;
  subheading: SubHeadings;
  payoutStage: PAYOUT_STAGE;
  config:
    | Record<
        SELF_PAYOUT_BANK_STAGES,
        {
          label: string;
          to: SELF_PAYOUT_BANK_STAGES;
        }
      >
    | Record<
        SELF_PAYOUT_STAGES,
        {
          label: string;
          to: SELF_PAYOUT_STAGES;
        }
      >
    | Record<
        EXTERNAL_PAYOUT_STAGES,
        {
          label: string;
          to: EXTERNAL_PAYOUT_STAGES;
        }
      >
    | null;
  activeForm:
    | CREATE_PAYOUTS
    | SELF_PAYOUT_STAGES
    | EXTERNAL_PAYOUT_STAGES
    | SELF_PAYOUT_BANK_STAGES
    | undefined;
  isCompleted: boolean;
}
export enum SELF_PAYOUT_STAGES {
  PAYOUT_DETAILS = 'payout_details',
  REVIEW = 'review',
  CONFIRMATION = 'confirmation',
}
export enum EXTERNAL_PAYOUT_STAGES {
  VENDOR_DETAILS = 'vendor_details',
  INVOICE_DETAILS = 'invoice_details',
  BANK_DETAILS = 'bank_details',
  REVIEW = 'review',
  CONFIRMATION = 'confirmation',
}
export enum CREATE_PAYOUTS {
  BANK_DETAILS = 'bank_details',
  PAYOUT_DETAILS = 'payout_details',
  VENDOR_DETAILS = 'vendor_details',
  INVOICE_DETAILS = 'invoice_details',
  BANK_DETAILS_EXT = 'bank_details',
  REVIEW = 'review',
  CONFIRMATION = 'confirmation',
}
export enum PAYOUT_STAGE {
  info = 'info',
  self = 'self_payout',
  external = 'external_payout',
}
export const PAYOUTS_NAV_CONFIG: Record<
  CREATE_PAYOUTS,
  { label: string; to: CREATE_PAYOUTS; isCompleted: boolean }
> = {
  [CREATE_PAYOUTS.BANK_DETAILS]: {
    label: 'Bank details',
    to: CREATE_PAYOUTS.PAYOUT_DETAILS,
    isCompleted: false,
  },
  [CREATE_PAYOUTS.PAYOUT_DETAILS]: {
    label: 'Payout details',
    to: CREATE_PAYOUTS.REVIEW,
    isCompleted: false,
  },
  [CREATE_PAYOUTS.VENDOR_DETAILS]: {
    label: 'Vendor details',
    to: CREATE_PAYOUTS.INVOICE_DETAILS,
    isCompleted: false,
  },
  [CREATE_PAYOUTS.INVOICE_DETAILS]: {
    label: 'Invoice details',
    to: CREATE_PAYOUTS.BANK_DETAILS,
    isCompleted: false,
  },
  [CREATE_PAYOUTS.BANK_DETAILS_EXT]: {
    label: 'Bank details',
    to: CREATE_PAYOUTS.REVIEW,
    isCompleted: false,
  },
  [CREATE_PAYOUTS.REVIEW]: {
    label: 'Review',
    to: CREATE_PAYOUTS.CONFIRMATION,
    isCompleted: false,
  },
  [CREATE_PAYOUTS.CONFIRMATION]: {
    label: 'Confirmation',
    to: CREATE_PAYOUTS.CONFIRMATION,
    isCompleted: false,
  },
};
export const SELF_PAYOUTS_BANK_NAV_CONFIG: Record<
  SELF_PAYOUT_BANK_STAGES,
  { label: string; to: SELF_PAYOUT_BANK_STAGES }
> = {
  [SELF_PAYOUT_BANK_STAGES.BANK_DETAILS]: {
    label: 'Bank details',
    to: SELF_PAYOUT_BANK_STAGES.PAYOUT_DETAILS,
  },
  [SELF_PAYOUT_BANK_STAGES.PAYOUT_DETAILS]: {
    label: 'Payout details',
    to: SELF_PAYOUT_BANK_STAGES.REVIEW,
  },
  [SELF_PAYOUT_BANK_STAGES.REVIEW]: {
    label: 'Review',
    to: SELF_PAYOUT_BANK_STAGES.CONFIRMATION,
  },
  [SELF_PAYOUT_BANK_STAGES.CONFIRMATION]: {
    label: 'Confirmation',
    to: SELF_PAYOUT_BANK_STAGES.CONFIRMATION,
  },
};
export const SELF_PAYOUTS_NAV_CONFIG: Record<
  SELF_PAYOUT_STAGES,
  { label: string; to: SELF_PAYOUT_STAGES }
> = {
  [SELF_PAYOUT_STAGES.PAYOUT_DETAILS]: {
    label: 'Payout details',
    to: SELF_PAYOUT_STAGES.REVIEW,
  },
  [SELF_PAYOUT_STAGES.REVIEW]: {
    label: 'Review',
    to: SELF_PAYOUT_STAGES.CONFIRMATION,
  },
  [SELF_PAYOUT_STAGES.CONFIRMATION]: {
    label: 'Confirmation',
    to: SELF_PAYOUT_STAGES.CONFIRMATION,
  },
};
export const EXTERNAL_PAYOUTS_NAV_CONFIG: Record<
  EXTERNAL_PAYOUT_STAGES,
  { label: string; to: EXTERNAL_PAYOUT_STAGES }
> = {
  [CREATE_PAYOUTS.VENDOR_DETAILS]: {
    label: 'Vendor details',
    to: EXTERNAL_PAYOUT_STAGES.INVOICE_DETAILS,
  },
  [CREATE_PAYOUTS.INVOICE_DETAILS]: {
    label: 'Invoice details',
    to: EXTERNAL_PAYOUT_STAGES.BANK_DETAILS,
  },
  [CREATE_PAYOUTS.BANK_DETAILS_EXT]: {
    label: 'Bank details',
    to: EXTERNAL_PAYOUT_STAGES.REVIEW,
  },
  [CREATE_PAYOUTS.REVIEW]: {
    label: 'Review',
    to: EXTERNAL_PAYOUT_STAGES.CONFIRMATION,
  },
  [CREATE_PAYOUTS.CONFIRMATION]: {
    label: 'Confirmation',
    to: EXTERNAL_PAYOUT_STAGES.CONFIRMATION,
  },
};
export const typeOfPayout = (type: PAYOUT_STAGE) => {
  if (type === PAYOUT_STAGE.self) {
    return 'Self Payouts';
  }
  if (type === PAYOUT_STAGE.external) {
    return 'External Payouts';
  }
  return '';
};
export const clearCompletedStates = () => {
  Object.keys(PAYOUTS_NAV_CONFIG).forEach((item) => {
    PAYOUTS_NAV_CONFIG[item as CREATE_PAYOUTS].isCompleted = false;
  });
};
