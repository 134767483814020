import classNames from 'classnames';
import ErrorMessage from 'components/errorMessage';
import FormLabel from 'components/form-label';
import ModalContainer from 'components/modals/container';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Col, FormGroup, Input, Row } from 'reactstrap';
import useAuth from 'state/auth/hooks/useAuth';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import usePayment from 'state/payment/hooks/usePayment';
import { PAYMENTS } from 'state/payment/type';
import CURRENCY from 'utils/currency';
import {
  EMAIL_VALIDATION,
  NAME_VALIDATION,
  SESSION_VALIDATION,
} from 'utils/validation';
import styles from './sessions.module.scss';

interface IProps {
  isOpen: boolean;
  toggle: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}

enum DemoCreationType {
  id = 'id',
  details = 'details',
}

const Sandbox = ({ isOpen, toggle, onClose }: IProps): JSX.Element => {
  const [type, setType] = useState(DemoCreationType.details);
  const { organisation } = useOrganisation();
  const { activeOrg } = organisation;

  const { payment, toCreateSession } = usePayment();
  const { addedSession, error } = payment;

  const { auth } = useAuth();
  const { user } = auth;
  const { user_info } = user;

  const { register, handleSubmit, setValue, errors } = useForm();

  const onOpen = (sessionId: string) => {
    const sp = new SwifterPay();
    sp.startSession({
      env: 'sandbox',
      sessionID: sessionId,
      selector: 'body',
      renderInModal: true,
      onComplete: () => {
        onClose();
      },
      onExit: () => {
        onClose();
      },
    });
  };

  useEffect(() => {
    if (user_info?.email) setValue('email', user_info?.email);
    if (user_info?.first_name) setValue('first_name', user_info?.first_name);
    if (user_info?.last_name) setValue('last_name', user_info?.last_name);
  }, [user_info]);

  const onFormSubmit = (values: { [x: string]: string }) => {
    const { amount, firstName, lastName, email, inputSessionId } = values;

    if (activeOrg && type === DemoCreationType.details) {
      const request: PAYMENTS.ISessionParams = {
        organization_id: activeOrg?.id,
        amount: CURRENCY.convertToSmallestUnit(Number(amount)),
        consumer_info: {
          first_name: firstName,
          last_name: lastName,
          email,
          drivers_license: 'TESTLICENSE',
        },
        type: 'consumer_checkout',
      };
      toCreateSession(request);
    } else if (type === DemoCreationType.id) {
      toggle(!isOpen);
      onOpen(inputSessionId);
    }
  };

  useEffect(() => {
    if (addedSession) {
      toggle(!isOpen);
      onOpen(addedSession.id);
    }
  }, [addedSession]);

  return (
    <>
      <ModalContainer
        title="Create Demo Session"
        isFullHeight={false}
        isOpen={isOpen}
        onClose={() => {
          toggle(!isOpen);
        }}
        footer={
          <div>
            <Button
              id="demo-session-submit"
              color="primary"
              className="btn btn-primary"
              onClick={handleSubmit(onFormSubmit)}
            >
              Initiate Session
            </Button>
            {error?.addSession && (
              <p className="mt-3 small text-danger">{error.addSession}</p>
            )}
          </div>
        }
      >
        <form className={classNames(styles.sandboxForm)}>
          <FormGroup check className={classNames(styles.sandboxRow)}>
            <FormLabel check element="formType" label="By entering details">
              <Input
                defaultChecked
                type="radio"
                name="formType"
                value={DemoCreationType.details}
                onChange={(e) =>
                  setType(e.target.value as DemoCreationType.details)
                }
              />
            </FormLabel>
          </FormGroup>
          <div className={classNames(styles.sandboxHelpText, 'body-subtext')}>
            Enter the following details to initiate a demo session
          </div>

          <Row className={classNames(styles.sandboxRow)}>
            <Col xs="6">
              <FormLabel isRequired check element="amount" label="Amount ($)" />
              <Input
                name="amount"
                defaultValue={1}
                innerRef={register({
                  validate: {
                    required: (value) => {
                      return type === DemoCreationType.details
                        ? value.length < 1
                          ? 'Please enter a valid amount'
                          : true
                        : true;
                    },
                  },
                })}
              />
              <div>
                {errors.amount && (
                  <ErrorMessage
                    isValidation
                    message="Please enter a valid amount"
                  />
                )}
              </div>
            </Col>
          </Row>

          <Row className={classNames(styles.sandboxRow)}>
            <Col xs="12" sm="6" className="pr-3">
              <FormLabel
                isRequired
                check
                element="firstName"
                label="First Name"
              ></FormLabel>
              <Input
                name="firstName"
                innerRef={register({
                  pattern: NAME_VALIDATION,
                  validate: {
                    required: (value) => {
                      return type === DemoCreationType.details
                        ? value.length <= 0
                          ? 'Please enter a valid first name'
                          : true
                        : true;
                    },
                  },
                })}
              />
              <div>
                {errors.firstName && (
                  <ErrorMessage
                    isValidation
                    message="Please enter a valid first name"
                  />
                )}
              </div>
            </Col>
            <Col xs="12" sm="6" className="pl-3">
              <FormLabel
                isRequired
                check
                element="lastName"
                label="Last Name"
              ></FormLabel>
              <Input
                name="lastName"
                innerRef={register({
                  pattern: NAME_VALIDATION,
                  validate: {
                    required: (value) => {
                      return type === DemoCreationType.details
                        ? value.length <= 0
                          ? 'Please enter a valid last name'
                          : true
                        : true;
                    },
                  },
                })}
              />
              <div>
                {errors.lastName && (
                  <ErrorMessage
                    isValidation
                    message="Please enter a valid last name"
                  />
                )}
              </div>
            </Col>
          </Row>
          <Row className={classNames(styles.sandboxRow)}>
            <Col>
              <FormLabel
                isRequired
                check
                element="email"
                label="Email"
              ></FormLabel>
              <Input
                name="email"
                innerRef={register({
                  pattern: EMAIL_VALIDATION,
                  validate: {
                    required: (value) => {
                      return type === DemoCreationType.details
                        ? value.length <= 0
                          ? 'Please enter a valid email'
                          : true
                        : true;
                    },
                  },
                })}
              />
              <div>
                {errors.email && (
                  <ErrorMessage
                    isValidation
                    message="Please enter a valid email"
                  />
                )}
              </div>
            </Col>
          </Row>
          <hr className="my-3"></hr>
          <FormGroup check className={classNames(styles.sandboxRow)}>
            <FormLabel check element="formType" label="By Session ID">
              <Input
                type="radio"
                name="formType"
                value={DemoCreationType.id}
                onChange={(e) => setType(e.target.value as DemoCreationType.id)}
              />
            </FormLabel>
          </FormGroup>
          <div className={classNames(styles.sandboxHelpText, 'body-subtext')}>
            Enter Session ID to initiate a demo session
          </div>
          <Row className={classNames(styles.sandboxRow)}>
            <Col xs="8">
              <FormLabel
                isRequired
                check
                element="inputSessionId"
                label="Session ID"
              ></FormLabel>
              <Input
                name="inputSessionId"
                innerRef={register({
                  pattern: SESSION_VALIDATION,
                  validate: {
                    required: (value) => {
                      return type === DemoCreationType.id
                        ? value.length <= 0
                          ? 'Please enter a valid session ID'
                          : true
                        : true;
                    },
                  },
                })}
              />
              <div>
                {errors.inputSessionId && (
                  <ErrorMessage
                    isValidation
                    message="Please enter a valid session ID"
                  />
                )}
              </div>
            </Col>
          </Row>
        </form>
      </ModalContainer>
    </>
  );
};

export default Sandbox;
