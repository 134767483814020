import React from 'react';
import classNames from 'classnames';
import { Input } from 'reactstrap';
import { isListItemPresent } from 'utils/filter';
import styles from './filters.module.scss';
import { IFilterConfig, IFilterConfigList } from './types';

interface IProps {
  item: IFilterConfig;
  setSelected: (arg1: string, arg2: boolean) => void;
  setValues: (arg1: string, arg2: string[]) => void;
}

const ListFilter = ({ item, setSelected, setValues }: IProps): JSX.Element => {
  const { selected, key, label, data } = item;
  const { values, options } = data as IFilterConfigList;

  return (
    <>
      <div className={classNames(styles.filterMenu_item)}>
        <Input
          data-testid="list-checkbox"
          id={label}
          data-cy={`listFilter_${key}`}
          type="checkbox"
          className={classNames(styles.checkbox)}
          checked={selected}
          onChange={(e) => {
            setSelected(key, e.currentTarget.checked);
          }}
          name={label}
        />
        <label htmlFor={label} className={classNames('body-subtext-regular')}>
          {label}
        </label>
      </div>
      {selected && (
        <div className={classNames(styles.filterMenu_dropdown)}>
          {options.map((option) => {
            return (
              <div
                className={classNames(styles.checkbox_option, 'mb-2')}
                key={option.name}
              >
                <Input
                  data-testid={option.name}
                  type="checkbox"
                  className={classNames(styles.checkbox)}
                  checked={isListItemPresent(values, option.value)}
                  id={option.name}
                  onChange={(e) => {
                    if (e.currentTarget.checked) {
                      setValues(key, values.concat(option.value));
                    } else {
                      const arr = values.filter((v) => {
                        return Array.isArray(option.value)
                          ? !option.value.includes(v)
                          : v !== option.value;
                      });
                      setValues(key, arr);
                    }
                  }}
                />
                <label htmlFor={option.name} className={classNames('footnote')}>
                  {option.name}
                </label>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
export default ListFilter;
