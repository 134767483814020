import classNames from 'classnames';
import React, { ReactChild, ReactChildren } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styles from './container.module.scss';

interface AuxProps {
  children: ReactChild | ReactChildren;
}
interface IProps {
  children: AuxProps | JSX.Element;
  isFullHeight?: boolean;
  isOpen: boolean;
  onClose: () => void;
  footer?: JSX.Element;
  title?: string;
  size?: string;
  actions?: JSX.Element;
  nopadding?: boolean;
  className?: string;
  header?: boolean;
  footerClass?: boolean;
  center?: boolean;
  testId?: string;
  id?: string;
}

const ModalContainer = ({
  children,
  isFullHeight = true,
  isOpen = false,
  onClose,
  size = 'md',
  footer,
  title,
  actions,
  nopadding = false,
  className = '',
  header = true,
  footerClass = false,
  center = false,
  testId = '',
  id = '',
}: IProps): JSX.Element => {
  return (
    <>
      <Modal
        keyboard={false}
        fade
        className={classNames(
          `${isFullHeight ? 'modal-dialog-centered' : 'modal-short'}`,
          className
        )}
        isOpen={isOpen}
        toggle={onClose}
        backdrop="static"
        size={size}
        centered={center}
        data-testid={testId}
        id={id}
      >
        {header && (
          <ModalHeader toggle={onClose} charCode="&times;">
            {title}
            <div>{actions}</div>
          </ModalHeader>
        )}
        <ModalBody
          className={classNames(
            nopadding ? styles.nopadding : '',
            'body-subtext-regular',
            !header && styles.borderRadius
          )}
        >
          {children}
        </ModalBody>
        {footer && (
          <ModalFooter
            className={classNames(
              'modal-footer',
              footerClass ? styles.splitButton : ''
            )}
          >
            {footer}
          </ModalFooter>
        )}
      </Modal>
    </>
  );
};
export default ModalContainer;
