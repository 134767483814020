import React from 'react';
import { Spinner } from 'reactstrap';

interface IProps {
  isLoading: boolean;
  size?: string;
  color?: string;
  className?: string;
}

const LocalSpinner = ({
  isLoading,
  size,
  color = 'primary',
  className = '',
}: IProps): JSX.Element | null => {
  return isLoading ? (
    <Spinner
      data-testid="spinner-local"
      className={className}
      color={color}
      size={size}
    />
  ) : null;
};

export default LocalSpinner;
