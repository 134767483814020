import {
  CREATE_PAYMENT_DEVICE,
  CREATE_PAYMENT_DEVICE_FAILURE,
  CREATE_PAYMENT_DEVICE_SUCCESS,
  DELETE_PAYMENT_DEVICE,
  DELETE_PAYMENT_DEVICE_FAILURE,
  DELETE_PAYMENT_DEVICE_SUCCESS,
  FETCH_PAYMENT_SOLUTIONS,
  FETCH_PAYMENT_SOLUTIONS_FAILURE,
  FETCH_PAYMENT_SOLUTIONS_SUCCESS,
  GET_CARD_READER_CONFIG,
  GET_CARD_READER_CONFIG_FAILURE,
  GET_CARD_READER_CONFIG_SUCCESS,
  GET_CARD_READER_VALUES,
  GET_CARD_READER_VALUES_FAILURE,
  GET_CARD_READER_VALUES_SUCCESS,
  GET_DEFAULT_PROVIDER,
  GET_DEFAULT_PROVIDER_FAILURE,
  GET_DEFAULT_PROVIDER_SUCCESS,
  GET_PAYMENT_DEVICE,
  GET_PAYMENT_DEVICE_FAILURE,
  GET_PAYMENT_DEVICE_SUCCESS,
  RESET,
  RESET_CARD_READER_VALUES,
  RESET_DEVICE_CREATED,
  RESET_DEVICE_DELETED,
  RESET_DEVICE_STATUS,
  RESET_DEVICE_UPDATED,
  RESET_PAYMENT_DEVICE_LIST,
  RESET_SAVE_CARD_READER_VALUES,
  RESET_SAVE_DEFAULT_CARD_READER_VALUES,
  SAVE_CARD_READER_VALUES,
  SAVE_CARD_READER_VALUES_FAILURE,
  SAVE_CARD_READER_VALUES_SUCCESS,
  SAVE_DEFAULT_CARD_READER_VALUES,
  SAVE_DEFAULT_CARD_READER_VALUES_FAILURE,
  SAVE_DEFAULT_CARD_READER_VALUES_SUCCESS,
  SET_FILTERED_LIST_SUCCESS,
  TEST_PAYMENT_DEVICE,
  TEST_PAYMENT_DEVICE_FAILURE,
  TEST_PAYMENT_DEVICE_SUCCESS,
  UPDATE_PAYMENT_DEVICE,
  UPDATE_PAYMENT_DEVICE_FAILURE,
  UPDATE_PAYMENT_DEVICE_SUCCESS,
} from './actions';
import {
  ConfigurationInputType,
  PAYMENT_CONFIG,
  PaymentDeviceConfig,
} from './type';

export const INITIAL_STATE: PAYMENT_CONFIG.PaymentConfigReducer = {
  isLoading: false,
  error: {} as PAYMENT_CONFIG.Error,
  paymentSolutions: {} as PAYMENT_CONFIG.IPaymentSolutions,
  defaultProcessor: {} as PAYMENT_CONFIG.IFetchDefaultProcessor,
  filteredList: undefined,
  paymentDeviceList: undefined,
  paymentDeviceCreated: false,
  paymentDeviceUpdated: false,
  deviceStatus: {} as PAYMENT_CONFIG.IDeviceStatus,
  deviceDeleted: false,
  getCardReaderValues: {} as PAYMENT_CONFIG.IGetCardReaderList,
  saveCardReader: false,
  saveDefaultCardReader: false,
  paymentConfiguration: {} as PAYMENT_CONFIG.IProcessorConfigurationObj,
};

const paymentConfigReducer = (
  action: IAction,
  state = INITIAL_STATE
): PAYMENT_CONFIG.PaymentConfigReducer => {
  switch (action.type) {
    case FETCH_PAYMENT_SOLUTIONS:
      return {
        ...state,
        isLoading: true,
        error: {} as PAYMENT_CONFIG.Error,
        paymentSolutions: {} as PAYMENT_CONFIG.IPaymentSolutions,
      };
    case FETCH_PAYMENT_SOLUTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: {} as PAYMENT_CONFIG.Error,
        paymentSolutions: action.payload.data,
      };
    case FETCH_PAYMENT_SOLUTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { fetchPaymentSolutions: action.payload.message },
      };
    case GET_DEFAULT_PROVIDER:
      return {
        ...state,
        isLoading: true,
        defaultProcessor: {} as PAYMENT_CONFIG.IFetchDefaultProcessor,
        error: {} as PAYMENT_CONFIG.Error,
      };
    case GET_DEFAULT_PROVIDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        defaultProcessor: action.payload,
      };
    case GET_DEFAULT_PROVIDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { fetchDefaultProvider: action.payload.message },
      };

    case SET_FILTERED_LIST_SUCCESS:
      return {
        ...state,
        filteredList: action.payload,
      };
    case GET_PAYMENT_DEVICE:
      return {
        ...state,
        isLoading: true,
        paymentDeviceList: undefined,
        error: {} as PAYMENT_CONFIG.Error,
      };
    case GET_PAYMENT_DEVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentDeviceList: action.payload.data,
        error: {} as PAYMENT_CONFIG.Error,
      };
    case GET_PAYMENT_DEVICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        paymentDeviceList: {} as PAYMENT_CONFIG.IGetPaymentDeviceLocation[],
        error: { getPaymentDevice: action.payload.message },
      };
    case CREATE_PAYMENT_DEVICE:
      return {
        ...state,
        isLoading: true,
        paymentDeviceCreated: false,
        error: {} as PAYMENT_CONFIG.Error,
      };
    case CREATE_PAYMENT_DEVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentDeviceCreated: true,
        error: {} as PAYMENT_CONFIG.Error,
      };
    case CREATE_PAYMENT_DEVICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        paymentDeviceCreated: false,
        error: { createPaymentDevice: action.payload.message },
      };

    case UPDATE_PAYMENT_DEVICE:
      return {
        ...state,
        isLoading: true,
        paymentDeviceUpdated: false,
        error: {} as PAYMENT_CONFIG.Error,
      };
    case UPDATE_PAYMENT_DEVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentDeviceUpdated: true,
        error: {} as PAYMENT_CONFIG.Error,
      };
    case UPDATE_PAYMENT_DEVICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        paymentDeviceUpdated: false,
        error: { editPaymentDevice: action.payload.message },
      };
    case TEST_PAYMENT_DEVICE:
      return {
        ...state,
        isLoading: true,
        deviceStatus: {} as PAYMENT_CONFIG.IDeviceStatus,
        error: {} as PAYMENT_CONFIG.Error,
      };
    case TEST_PAYMENT_DEVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deviceStatus: action.payload.data,
        error: {} as PAYMENT_CONFIG.Error,
      };
    case TEST_PAYMENT_DEVICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        deviceStatus: {} as PAYMENT_CONFIG.IDeviceStatus,
        error: { deviceStatus: action.payload.message },
      };
    case DELETE_PAYMENT_DEVICE:
      return {
        ...state,
        isLoading: true,
        deviceDeleted: false,
        error: {} as PAYMENT_CONFIG.Error,
      };
    case DELETE_PAYMENT_DEVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deviceDeleted: true,
        error: {} as PAYMENT_CONFIG.Error,
      };
    case DELETE_PAYMENT_DEVICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        deviceDeleted: false,
        error: { deviceDeleted: action.payload.message },
      };
    case GET_CARD_READER_VALUES:
      return {
        ...state,
        isLoading: true,
        getCardReaderValues: {} as PAYMENT_CONFIG.IGetCardReaderList,
        error: {} as PAYMENT_CONFIG.Error,
      };
    case GET_CARD_READER_VALUES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        getCardReaderValues: action.payload.data,
        error: {} as PAYMENT_CONFIG.Error,
      };
    case GET_CARD_READER_VALUES_FAILURE:
      return {
        ...state,
        isLoading: false,
        getCardReaderValues: {} as PAYMENT_CONFIG.IGetCardReaderList,
        error: { getCardReaderValues: action.payload.message },
      };
    case SAVE_CARD_READER_VALUES:
      return {
        ...state,
        isLoading: true,
        saveCardReader: false,
        error: {} as PAYMENT_CONFIG.Error,
      };
    case SAVE_CARD_READER_VALUES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        saveCardReader: true,
        error: {} as PAYMENT_CONFIG.Error,
      };

    case SAVE_CARD_READER_VALUES_FAILURE:
      return {
        ...state,
        isLoading: false,
        saveCardReader: false,
        error: { saveCardReader: action.payload.message },
      };
    case SAVE_DEFAULT_CARD_READER_VALUES:
      return {
        ...state,
        isLoading: true,
        saveDefaultCardReader: false,
        error: {} as PAYMENT_CONFIG.Error,
      };
    case SAVE_DEFAULT_CARD_READER_VALUES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        saveDefaultCardReader: true,
        error: {} as PAYMENT_CONFIG.Error,
      };

    case SAVE_DEFAULT_CARD_READER_VALUES_FAILURE:
      return {
        ...state,
        isLoading: false,
        saveDefaultCardReader: false,
        error: { saveDefaultCardReader: action.payload.message },
      };
    case GET_CARD_READER_CONFIG:
      return {
        ...state,
        isLoading: true,
        paymentConfiguration: {} as PAYMENT_CONFIG.IProcessorConfigurationObj,
        error: {} as PAYMENT_CONFIG.Error,
      };

    case GET_CARD_READER_CONFIG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentConfiguration: action?.payload?.data,
        error: {} as PAYMENT_CONFIG.Error,
      };
    case GET_CARD_READER_CONFIG_FAILURE:
      return {
        ...state,
        isLoading: false,
        paymentConfiguration: {} as PAYMENT_CONFIG.IProcessorConfigurationObj,
        error: { paymentConfiguration: action.payload?.message },
      };
    case RESET_DEVICE_DELETED:
      return {
        ...state,
        deviceDeleted: false,
        error: {} as PAYMENT_CONFIG.Error,
      };
    case RESET_DEVICE_UPDATED:
      return {
        ...state,
        paymentDeviceUpdated: false,
        error: {} as PAYMENT_CONFIG.Error,
      };
    case RESET_DEVICE_CREATED:
      return {
        ...state,
        paymentDeviceCreated: false,
        error: {} as PAYMENT_CONFIG.Error,
      };
    case RESET_PAYMENT_DEVICE_LIST:
      return {
        ...state,
        paymentDeviceList: undefined,
      };
    case RESET_SAVE_CARD_READER_VALUES:
      return {
        ...state,
        saveCardReader: false,
        error: {} as PAYMENT_CONFIG.Error,
      };
    case RESET_SAVE_DEFAULT_CARD_READER_VALUES:
      return {
        ...state,
        saveDefaultCardReader: false,
        error: {} as PAYMENT_CONFIG.Error,
      };
    case RESET:
      return {
        ...state,
        isLoading: false,
        error: {} as PAYMENT_CONFIG.Error,
        deviceStatus: {} as PAYMENT_CONFIG.IDeviceStatus,
      };
    case RESET_DEVICE_STATUS:
      return {
        ...state,
        deviceStatus: {} as PAYMENT_CONFIG.IDeviceStatus,
      };
    case RESET_CARD_READER_VALUES:
      return {
        ...state,
        getCardReaderValues: {} as PAYMENT_CONFIG.IGetCardReaderList,
        paymentConfiguration: {} as PAYMENT_CONFIG.IProcessorConfigurationObj,
      };
    default:
      return state;
  }
};
export default paymentConfigReducer;
