import { format, isAfter } from 'date-fns';
import { STATS } from 'state/stats/type';

export const getMonthlyRange = (date: Date): string => {
  const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const today = new Date();
  return `${format(date, 'dd MMM yyyy')} - ${format(
    isAfter(endOfMonth, today) ? today : endOfMonth,
    'dd MMM yyyy'
  )}`;
};

const { IIntervalType: INTERVAL } = STATS;

export const mapIntervalToFrequencyBanking = (
  interval: number
): STATS.IIntervalType[] => {
  if (interval >= 1 && interval <= 13) return [INTERVAL.DAILY];
  if (interval >= 14 && interval <= 61)
    return [INTERVAL.DAILY, INTERVAL.WEEKLY];
  if (interval >= 62 && interval <= 90)
    return [INTERVAL.WEEKLY, INTERVAL.MONTHLY];

  if (interval > 90) return [INTERVAL.MONTHLY];
  return [];
};
