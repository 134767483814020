import { apiWithOrg } from 'services/api';
import axios from 'axios';
import { PAYOUTTYPE } from 'components/modals/banking/payouts/payout';
import { useStateValue } from '../..';
import {
  action,
  actionFailure,
  actionSuccess,
  FETCH_DAILY_BANKING_SUMMARIES,
  FETCH_BANKING_STATS,
  FETCH_TOTAL_BALANCE_DETAILS,
  FETCH_CREDIT_DETAILS,
  FETCH_DEBIT_DETAILS,
  FETCH_EXPORT,
  FETCH_PAYOUTS,
  INITIATE_SELF_PAYOUT,
  FETCH_EXTERNAL_ACCOUNT,
  ADD_BANK_ACCOUNT,
  CLEAR_EXPORT,
  FETCH_PAYOUT_DETAILS,
  CLEAR_ADD_BANK_ACCOUNT,
  SET_PAYOUT_AMOUNT,
  LIST_VENDORS,
  CREATE_VENDOR,
  UPDATE_VENDOR,
  RESET_VENDOR_UPDATE,
  SET_INVOICE_DETAILS,
  RESET_PAYOUT_FLOW,
  RESET_VENDOR_ADD,
  SET_VENDOR_ID,
  GET_VENDOR_PAYOUT_ACCOUNT_LIST,
  ADD_VENDOR_BANK_ACCOUNT,
  FETCH_PAYOUTS_SUMMARY_DATA,
  FETCH_TOTAL_FAILED_AMOUNT,
  LIST_FAILED_TRANSACTIONS,
  RESET_ADD_VENDOR_ACCOUNT,
  INITIATE_EXTERNAL_PAYOUT,
  SET_VENDOR_ACCOUNT,
  RESET_PAYOUT_SUCCESS,
  SET_VENDOR_NOTES,
  SET_PAYOUT_TYPE,
  LIST_WRITE_OFF_TRANSACTIONS,
  INITIATE_BATCH_RETRY,
  INITIATE_WRITE_OFFS,
  RECORD_CASH,
  FETCH_BANKING_SUMMARY_EXPORT,
  FETCH_STATEMENT_TOTAL_BALANCE,
} from '../actions';
import { BANKING } from '../type';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useBanking = () => {
  const { state, dispatch } = useStateValue();
  const { banking } = state;

  const toFetchBankingStats = async (
    values?: BANKING.IFetchBankingStatsRequest
  ) => {
    dispatch(action(FETCH_BANKING_STATS));
    try {
      const response = await apiWithOrg.get(`/banking/stats`, values);
      dispatch(actionSuccess(FETCH_BANKING_STATS, response.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_BANKING_STATS, e));
    }
  };

  const toFetchBankingTotalBalance = async () => {
    dispatch(action(FETCH_STATEMENT_TOTAL_BALANCE));
    try {
      const response = await apiWithOrg.get(`/banking/total_balance`);
      dispatch(actionSuccess(FETCH_STATEMENT_TOTAL_BALANCE, response.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_STATEMENT_TOTAL_BALANCE, e));
    }
  };

  const toFetchDailyBalanceSummaries = async (
    values: BANKING.IFetchBankingDailySummariesRequest
  ) => {
    dispatch(action(FETCH_DAILY_BANKING_SUMMARIES));
    try {
      const response = await apiWithOrg.get(
        `/banking/balance_aggregate/daily`,
        values
      );

      dispatch(
        actionSuccess(FETCH_DAILY_BANKING_SUMMARIES, {
          summary: response.data,
          type: BANKING.BankingTxnType.ALL,
        })
      );
    } catch (e) {
      dispatch(actionFailure(FETCH_DAILY_BANKING_SUMMARIES, e));
    }
  };

  const toFetchDailyIncomingSummaries = async (
    values: BANKING.IFetchBankingDailySummariesRequest
  ) => {
    dispatch(action(FETCH_DAILY_BANKING_SUMMARIES));
    try {
      const response = await apiWithOrg.get(
        `/transaction_reports/incoming_aggregate/daily`,
        values
      );

      dispatch(
        actionSuccess(FETCH_DAILY_BANKING_SUMMARIES, {
          summary: response.data,
          type: BANKING.BankingTxnType.INCOMING,
        })
      );
    } catch (e) {
      dispatch(actionFailure(FETCH_DAILY_BANKING_SUMMARIES, e));
    }
  };

  const toFetchDailyOutgoingSummaries = async (
    values: BANKING.IFetchBankingDailySummariesRequest
  ) => {
    dispatch(action(FETCH_DAILY_BANKING_SUMMARIES));
    try {
      const response = await apiWithOrg.get(
        `/banking/outgoing_aggregate/daily`,
        values
      );

      dispatch(
        actionSuccess(FETCH_DAILY_BANKING_SUMMARIES, {
          summary: response.data,
          type: BANKING.BankingTxnType.OUTGOING,
        })
      );
    } catch (e) {
      dispatch(actionFailure(FETCH_DAILY_BANKING_SUMMARIES, e));
    }
  };

  const toFetchGrossDetails = async (values: BANKING.IFetchBankingDetails) => {
    dispatch(action(FETCH_TOTAL_BALANCE_DETAILS));
    try {
      const response = await apiWithOrg.get(`/banking/statement`, values);
      dispatch(actionSuccess(FETCH_TOTAL_BALANCE_DETAILS, response?.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_TOTAL_BALANCE_DETAILS, e));
    }
  };
  const toFetchCreditDetails = async (values: BANKING.IFetchBankingDetails) => {
    dispatch(action(FETCH_CREDIT_DETAILS));
    try {
      const response = await apiWithOrg.get(
        `/banking/statement?summary_type=incoming`,
        values
      );
      dispatch(actionSuccess(FETCH_CREDIT_DETAILS, response?.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_CREDIT_DETAILS, e));
    }
  };
  const toFetchDebitDetails = async (values: BANKING.IFetchBankingDetails) => {
    dispatch(action(FETCH_DEBIT_DETAILS));
    try {
      const response = await apiWithOrg.get(
        `/banking/statement?summary_type=outgoing`,
        values
      );
      dispatch(actionSuccess(FETCH_DEBIT_DETAILS, response?.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_DEBIT_DETAILS, e));
    }
  };
  const toFetchBankingCSVTransactions = async (
    values: BANKING.IFetchCSVTransactions
  ) => {
    dispatch(action(FETCH_EXPORT));
    try {
      values.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await apiWithOrg.get(`/banking/export`, values);
      if (response.status === 204) {
        dispatch(
          actionFailure(FETCH_EXPORT, {
            data: { message: 'No records available for given date range' },
          })
        );
      } else {
        dispatch(actionSuccess(FETCH_EXPORT, response?.data));
      }
    } catch (e) {
      dispatch(actionFailure(FETCH_EXPORT, e));
    }
  };

  const toExportBankingSummary = async (
    values: BANKING.IFetchBankingSummay
  ) => {
    dispatch(action(FETCH_BANKING_SUMMARY_EXPORT));
    try {
      values.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await apiWithOrg.get(`/banking/summary_export`, values);
      dispatch(actionSuccess(FETCH_BANKING_SUMMARY_EXPORT, response?.data));
      return response.data;
    } catch (e) {
      dispatch(actionFailure(FETCH_BANKING_SUMMARY_EXPORT, e));
      return e;
    }
  };

  const toInitiateSelfPayout = async (values: BANKING.ISelfPayout) => {
    dispatch(action(INITIATE_SELF_PAYOUT));
    try {
      const response = await apiWithOrg.post(`/payouts`, values);
      dispatch(actionSuccess(INITIATE_SELF_PAYOUT, response.data));
    } catch (e) {
      const error: any = axios.isAxiosError(e) ? e?.response : e;
      if (error?.status === 403) {
        dispatch(actionSuccess(INITIATE_SELF_PAYOUT, error?.data));
      } else {
        dispatch(actionFailure(INITIATE_SELF_PAYOUT, error));
      }
    }
  };

  const toInitiateExternalPayout = async (values: BANKING.IExternalPayout) => {
    dispatch(action(INITIATE_EXTERNAL_PAYOUT));
    try {
      const response = await apiWithOrg.post('/payouts', values);
      dispatch(actionSuccess(INITIATE_EXTERNAL_PAYOUT, response.data));
    } catch (e) {
      const error: any = axios.isAxiosError(e) ? e?.response : e;
      if (error?.status === 403) {
        dispatch(actionSuccess(INITIATE_EXTERNAL_PAYOUT, error?.data));
      } else {
        dispatch(actionFailure(INITIATE_EXTERNAL_PAYOUT, error));
      }
    }
  };

  const toListVendors = async () => {
    dispatch(action(LIST_VENDORS));
    try {
      const response = await apiWithOrg.get('/vendors');
      dispatch(actionSuccess(LIST_VENDORS, response?.data));
    } catch (e) {
      dispatch(actionFailure(LIST_VENDORS, e));
    }
  };

  const toCreateVendors = async (values: BANKING.IAddVendor) => {
    dispatch(action(CREATE_VENDOR));
    try {
      const response = await apiWithOrg.post('/vendors', values);
      dispatch(actionSuccess(CREATE_VENDOR, response?.data));
    } catch (e) {
      const errorMessage = axios.isAxiosError(e) ? e?.response : e;
      dispatch(actionFailure(CREATE_VENDOR, errorMessage));
    }
  };

  const toUpdateVendors = async (
    values: BANKING.IAddVendor,
    vendor_id: string
  ) => {
    dispatch(action(UPDATE_VENDOR));
    try {
      const response = await apiWithOrg.put(
        `/vendors/update/${vendor_id}`,
        values
      );
      dispatch(actionSuccess(UPDATE_VENDOR, response?.data));
    } catch (e) {
      dispatch(actionFailure(UPDATE_VENDOR, e));
    }
  };

  const toFetchExternalAccount = async () => {
    dispatch(action(FETCH_EXTERNAL_ACCOUNT));
    try {
      const response = await apiWithOrg.get('/payouts/accounts');
      dispatch(actionSuccess(FETCH_EXTERNAL_ACCOUNT, response?.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_EXTERNAL_ACCOUNT, e));
    }
  };
  const resetPayout = () => {
    dispatch(action(RESET_PAYOUT_FLOW));
  };

  const resetPayoutSuccess = () => {
    dispatch(action(RESET_PAYOUT_SUCCESS));
  };
  const addBankAccount = async (values: BANKING.IAddBankAccount) => {
    dispatch(action(ADD_BANK_ACCOUNT));
    try {
      const response = await apiWithOrg.post(`payouts/accounts`, values);
      dispatch(actionSuccess(ADD_BANK_ACCOUNT, response?.data));
    } catch (e) {
      const errorMessage = axios.isAxiosError(e) ? e?.response : e;
      dispatch(actionFailure(ADD_BANK_ACCOUNT, errorMessage));
    }
  };

  const addVendorBankAccount = async (
    values: BANKING.IAddVendorBankAccount
  ) => {
    dispatch(action(ADD_VENDOR_BANK_ACCOUNT));
    try {
      const response = await apiWithOrg.post('vendors/fi/accounts', values);
      dispatch(actionSuccess(ADD_VENDOR_BANK_ACCOUNT, response?.data));
    } catch (e) {
      const errorMessage = axios.isAxiosError(e) ? e?.response : e;
      dispatch(actionFailure(ADD_VENDOR_BANK_ACCOUNT, errorMessage));
    }
  };

  const toFetchPayouts = async (values: BANKING.IPayoutsSearch) => {
    dispatch(action(FETCH_PAYOUTS));
    try {
      const response = await apiWithOrg.get(`/payouts`, values);
      dispatch(actionSuccess(FETCH_PAYOUTS, response.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_PAYOUTS, e));
    }
  };

  const toFetchPayoutDetails = async (bal_transaction_id: string) => {
    dispatch(action(FETCH_PAYOUT_DETAILS));
    try {
      const response = await apiWithOrg.get(`/payouts/${bal_transaction_id}`);
      dispatch(actionSuccess(FETCH_PAYOUT_DETAILS, response.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_PAYOUT_DETAILS, e));
    }
  };

  const getVendorPayoutAccountList = async (vendor_id: string) => {
    dispatch(action(GET_VENDOR_PAYOUT_ACCOUNT_LIST));
    try {
      const response = await apiWithOrg.get(
        `/vendors/${vendor_id}/fi/accounts`
      );
      dispatch(actionSuccess(GET_VENDOR_PAYOUT_ACCOUNT_LIST, response.data));
    } catch (e) {
      dispatch(actionFailure(GET_VENDOR_PAYOUT_ACCOUNT_LIST, e));
    }
  };
  const toFetchPayoutsSummaryData = async (
    values: BANKING.IFetchBankingSummariesRequest
  ) => {
    dispatch(action(FETCH_PAYOUTS_SUMMARY_DATA));
    try {
      const response = await apiWithOrg.get('/payouts/aggregate', values);
      dispatch(actionSuccess(FETCH_PAYOUTS_SUMMARY_DATA, response?.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_PAYOUTS_SUMMARY_DATA, e));
    }
  };

  const toFetchTotalFailed = async () => {
    dispatch(action(FETCH_TOTAL_FAILED_AMOUNT));
    try {
      const response = await apiWithOrg.get('/failed_transactions/summary');
      dispatch(actionSuccess(FETCH_TOTAL_FAILED_AMOUNT, response.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_TOTAL_FAILED_AMOUNT, e));
    }
  };

  const toFetchFailedTransactions = async (
    values: BANKING.IFetchFailedTransactionsRequest
  ) => {
    dispatch(action(LIST_FAILED_TRANSACTIONS));
    try {
      const response = await apiWithOrg.get('/failed_transactions', values);
      dispatch(actionSuccess(LIST_FAILED_TRANSACTIONS, response.data));
    } catch (e) {
      dispatch(actionFailure(LIST_FAILED_TRANSACTIONS, e));
    }
  };

  const toInitiateBatchRetry = async (values: BANKING.IBatchRetryRequest) => {
    dispatch(action(INITIATE_BATCH_RETRY));
    try {
      const response = await apiWithOrg.post('/charges/retry', values);
      dispatch(actionSuccess(INITIATE_BATCH_RETRY, response.data));
      return true;
    } catch (e) {
      const errorMessage = axios.isAxiosError(e) ? e?.response : e;
      dispatch(actionFailure(INITIATE_BATCH_RETRY, errorMessage));
      return false;
    }
  };

  const toInitiateWriteOffs = async (values: BANKING.IBatchWriteOffRequest) => {
    dispatch(action(INITIATE_WRITE_OFFS));
    try {
      const response = await apiWithOrg.post('/write_offs', values);
      dispatch(actionSuccess(INITIATE_WRITE_OFFS, response.data));
      return true;
    } catch (e) {
      const error: any = axios.isAxiosError(e) ? e?.response : e;
      if (
        error?.status === 403 &&
        error?.data?.error_code !== 'role_based_permission_denied'
      ) {
        dispatch(actionSuccess(INITIATE_WRITE_OFFS, error?.data));
      } else {
        dispatch(actionFailure(INITIATE_WRITE_OFFS, error));
      }
      return false;
    }
  };

  const toRecordCash = async (charge_id: string) => {
    dispatch(action(RECORD_CASH));
    try {
      const response = await apiWithOrg.put(
        `/failed_transactions/${charge_id}/record_cash`
      );
      dispatch(actionSuccess(RECORD_CASH, response.data));
      return true;
    } catch (e) {
      const errorMessage = axios.isAxiosError(e) ? e?.response : e;
      dispatch(actionFailure(RECORD_CASH, errorMessage));
      return false;
    }
  };

  const toFetchWriteOffTransactions = async (
    values: BANKING.IFetchWriteOffTransactionsRequest
  ) => {
    dispatch(action(LIST_WRITE_OFF_TRANSACTIONS));
    try {
      const response = await apiWithOrg.get('/write_offs', values);
      dispatch(actionSuccess(LIST_WRITE_OFF_TRANSACTIONS, response.data));
    } catch (e) {
      dispatch(actionFailure(LIST_WRITE_OFF_TRANSACTIONS, e));
    }
  };

  const setPayoutAmount = (amount: string) => {
    dispatch(action(SET_PAYOUT_AMOUNT, amount));
  };

  const setVendorId = (id: string) => {
    dispatch(action(SET_VENDOR_ID, id));
  };

  const setVendorAccount = (details: BANKING.IStoreVendorAccount) => {
    dispatch(action(SET_VENDOR_ACCOUNT, details));
  };
  const setInvoiceDetail = (invoice: BANKING.IInvoiceDetails) => {
    dispatch(action(SET_INVOICE_DETAILS, invoice));
  };

  const toClearBankingExport = () => {
    dispatch(action(CLEAR_EXPORT));
  };

  const clearAddBankAccountError = () => {
    dispatch(action(CLEAR_ADD_BANK_ACCOUNT));
  };

  const resetUpdateVendor = () => {
    dispatch(action(RESET_VENDOR_UPDATE));
  };
  const resetAddVendor = () => {
    dispatch(action(RESET_VENDOR_ADD));
  };
  const resetAddVendorBankAccount = () => {
    dispatch(action(RESET_ADD_VENDOR_ACCOUNT));
  };

  const updateNotes = (note_value: string | undefined) => {
    dispatch(action(SET_VENDOR_NOTES, note_value));
  };
  const setPayoutType = (type: PAYOUTTYPE | undefined) => {
    dispatch(action(SET_PAYOUT_TYPE, type));
  };

  return {
    banking,
    toFetchBankingStats,
    toFetchDailyBalanceSummaries,
    toFetchDailyIncomingSummaries,
    toFetchDailyOutgoingSummaries,
    toFetchGrossDetails,
    toFetchCreditDetails,
    toFetchDebitDetails,
    toFetchBankingCSVTransactions,
    toInitiateSelfPayout,
    resetPayout,
    toFetchExternalAccount,
    addBankAccount,
    toClearBankingExport,
    toFetchPayoutDetails,
    clearAddBankAccountError,
    toFetchPayouts,
    getVendorPayoutAccountList,
    addVendorBankAccount,
    setPayoutAmount,
    toListVendors,
    toCreateVendors,
    toUpdateVendors,
    resetUpdateVendor,
    resetAddVendor,
    setInvoiceDetail,
    setVendorId,
    setVendorAccount,
    toFetchPayoutsSummaryData,
    toFetchTotalFailed,
    toFetchFailedTransactions,
    resetAddVendorBankAccount,
    toInitiateExternalPayout,
    resetPayoutSuccess,
    updateNotes,
    setPayoutType,
    toFetchWriteOffTransactions,
    toInitiateBatchRetry,
    toInitiateWriteOffs,
    toRecordCash,
    toExportBankingSummary,
    toFetchBankingTotalBalance,
  };
};

export default useBanking;
