import React, { SetStateAction, Dispatch } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import styles from './styles.module.scss';

interface IProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  warningStatement: string;
  tag: string;
  action: (id?: string | undefined) => void;
}

const DeleteModal = ({
  showModal,
  setShowModal,
  warningStatement,
  tag,
  action,
}: IProps): JSX.Element => {
  const toggle = () => setShowModal(!showModal);

  return (
    <Modal isOpen={showModal} toggle={toggle} className="HO">
      <ModalHeader toggle={toggle}>Delete {tag}</ModalHeader>
      <ModalBody className={styles.deleteWebhookText}>
        {warningStatement}
      </ModalBody>
      <ModalFooter>
        <div>
          <Button onClick={toggle} color="secondary">
            Cancel
          </Button>
          <Button color="danger" onClick={() => action()}>
            Delete {tag}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteModal;
