import React from 'react';

/* Defining the props that the component will receive. */
interface IProps {
  children: React.ReactNode;
  title: string;
}

/**
 * `Page` is a function that takes an object with two properties, `children` and `title`, and returns a
 * JSX element with the title of the page.
 */
const Page = ({ children, title }: IProps): JSX.Element => {
  document.title = title;
  return <>{children}</>;
};

export default Page;
