export enum ROLES {
  ADMIN = 'admin',
  MANAGER = 'manager',
  ANALYST = 'analyst',
  DEVELOPER = 'developer',
}

export enum INVITES {
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
  ACCEPTED = 'ACCEPTED',
}
