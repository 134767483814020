import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import classNames from 'classnames';
import ModalContainer from 'components/modals/container';
import { Button, Input } from 'reactstrap';
import useRefunds from 'state/refunds/hooks/useRefunds';
import BannerContainer, { BannerType } from 'components/banner';
import styles from './styles.module.scss';

interface IProps {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  chargeId: string;
}

/* Delete Customer modal on Customer Details page */
const ConfirmRefund = (props: IProps): JSX.Element => {
  const modalTitle = 'Initiate Refund';
  const { modal, setModal, chargeId } = props;

  const { initiateRefund, refunds, resetRefundState } = useRefunds();

  const { error, refundCreationSuccess } = refunds;

  const [errorHeading, setErrorHeading] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const [showError, setShowError] = useState(false);

  const modalClose = () => {
    setModal(!modal);
    setShowError(false);
    resetRefundState();
  };
  const toggle = () => {
    modalClose();
  };

  const refundAction = () => {
    initiateRefund(chargeId);
  };

  useEffect(() => {
    if (refundCreationSuccess) {
      modalClose();
    }
  }, [refundCreationSuccess]);

  useEffect(() => {
    if (error.initiateRefund) {
      if (error.initiateRefund.message === 'insufficient_funds') {
        setShowError(true);
        setErrorHeading('Unable to initiate refund');
        setErrorMessage(
          "Your account doesn't have any available balance to initiate a refund. Refunds can only be initiated when the available balance is more than the refund amount."
        );
      } else if (
        error?.initiateRefund?.message === 'role_based_permission_denied'
      ) {
        toggle();
      } else {
        setErrorHeading('An unexpected error occured');
        setErrorMessage(
          'We are unable to initiate a refund at the moment. Please close and try again.'
        );
        setShowError(true);
      }
    } else {
      setShowError(false);
    }
  }, [error.initiateRefund]);

  const [typedText, setTypedText] = useState('');

  const footerContent = (
    <div>
      {!error.initiateRefund ? (
        <>
          <Button onClick={toggle} size="md" color="secondary">
            Cancel
          </Button>
          <Button
            data-testid="initiate-refund-button"
            size="md"
            color="primary"
            onClick={refundAction}
            disabled={typedText.toLowerCase() !== 'refund'}
          >
            Initiate refund
          </Button>
        </>
      ) : (
        <Button size="md" color="primary" onClick={modalClose}>
          Close
        </Button>
      )}
    </div>
  );

  return (
    <ModalContainer
      data-testid="initiate-refund-modal"
      isOpen={modal}
      onClose={toggle}
      isFullHeight={false}
      title={modalTitle}
      footer={footerContent}
      header={false}
    >
      <>
        <span className={styles.refundModalHeading}>Intitate refund</span>
        <p
          className={classNames(
            showError ? 'mt-4' : 'mt-3 mb-2',
            styles.refundAction
          )}
        >
          <div className="mb-5">
            <BannerContainer
              heading={errorHeading}
              message={errorMessage}
              showBanner={showError}
              type={BannerType.error}
              listOfError={false}
              fixedHeight={false}
            />
          </div>
          Type <span className={'body-subtext-semibold'}>Refund</span> in the
          text box below to initiate a refund.
        </p>

        <Input
          id="input-campaign"
          placeholder="Refund"
          className={styles.inputHeight}
          type="text"
          name="refund"
          defaultValue={''}
          disabled={error?.initiateRefund !== undefined}
          onChange={(e) => {
            setTypedText(e.target.value);
          }}
        />
      </>
    </ModalContainer>
  );
};

export default ConfirmRefund;
