import React from 'react';
import classNames from 'classnames';
import styles from '../transactions.module.scss';
import CURRENCY from 'utils/currency';
import { PAYMENTS } from 'state/payment/type';
import BootstrapTable, {
  ExpandColumnRendererProps,
} from 'react-bootstrap-table-next';
import usePayment from 'state/payment/hooks/usePayment';
import NoSummaryFound from '../../../components/summary-tab/no-summary-found';
import TransactionsAccordion from 'components/summary-tab/accordions/transactions';

const summaryColumnsConfig = [
  {
    dataField: 'method',
    text: '',
    headerStyle: {
      height: '64px',
      fontSize: '16px',
      lineHeight: '24px',
      paddingLeft: '0px',
      background: '#ffffff',
    },
    headerFormatter: () => (
      <div className={styles.summaryTableHeaderFormatter}>
        Gross Sales by “Payment types”
      </div>
    ),
    formatter: (cell: string) => {
      return <>{cell && <div>{cell === 'ATM' ? 'CATM' : cell}</div>}</>;
    },
    style: {
      width: '31.55%',
      height: '52px',
      paddingTop: '16px',
      paddingBottom: '16px',
      paddingLeft: '0px',
    },
  },
  {
    dataField: 'gross_amount',
    text: '',
    headerStyle: {
      background: '#ffffff',
    },
    formatter: (cell: number) => {
      return (
        <>
          <div className={classNames('currencyColumn text-left')}>
            {cell ? CURRENCY.convertToMainUnit(cell) : '$0.00'}
          </div>
        </>
      );
    },
    style: {
      width: '21.67%',
      paddingTop: '16px',
      paddingBottom: '16px',
    },
  },
  {
    dataField: 'transaction_count',
    text: '',
    headerStyle: {
      background: '#ffffff',
    },
    style: {
      width: '21.67%',
      paddingTop: '16px',
      paddingBottom: '16px',
    },
  },
  {
    dataField: 'gross_amount',
    text: '',
    headerStyle: {
      background: '#ffffff',
    },
    formatter: (
      cell: number,
      row: PAYMENTS.ITransactionSummaryByPaymentType
    ) => {
      return (
        <>
          <div className={classNames('currencyColumn text-left')}>
            {cell
              ? CURRENCY.convertToMainUnit(
                  row.gross_amount / row.transaction_count
                )
              : '$0.00'}
          </div>
        </>
      );
    },
    style: {
      width: '21.67%',
      paddingTop: '16px',
      paddingBottom: '16px',
    },
  },
];

const tableExpandRowConfig = {
  className: 'dropdownArea',
  renderer: (row: PAYMENTS.ITransactionSummaryByPaymentType) => {
    return (
      <div className="w-100 pl-0">{<TransactionsAccordion row={row} />}</div>
    );
  },
  expandHeaderColumnRenderer: () => {
    return <></>;
  },
  expandColumnRenderer: ({ expanded }: ExpandColumnRendererProps) => (
    <div className="d-flex align-items-center justify-content-center py-2 px-1">
      {expanded ? (
        <i className="ph-caret-up-fill icon-md"></i>
      ) : (
        <i className="ph-caret-down-fill icon-md"></i>
      )}
    </div>
  ),
  showExpandColumn: true, // Set this to false to remove expand column from the header
  expandColumnPosition: 'left' as const,
};

const SummaryByPaymentType = () => {
  const { payment } = usePayment();
  const { transactionSummary: summary } = payment;

  return (
    <div
      className={classNames(
        styles.summaryByPaymentType,
        styles.tableRoundedBorder
      )}
    >
      <BootstrapTable
        wrapperClasses="table-responsive"
        keyField="method"
        data={summary?.aggregate_transaction_amount || []}
        expandRow={tableExpandRowConfig}
        columns={summaryColumnsConfig}
        hover
        bootstrap4
        bordered={false}
        classes={styles.summaryByPaymentTypeTable}
        noDataIndication={<NoSummaryFound />}
      />
    </div>
  );
};

export default SummaryByPaymentType;
