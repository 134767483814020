import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
} from 'reactstrap';
import useStore from 'state/store/hooks/useStore';
import routeConfig from 'routes/config';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import styles from './navbar.module.scss';
import { oneAppUrl } from 'utils/urlGenerator';
import { ENV } from 'utils/config';

const StoreSwitch = (): JSX.Element | null => {
  const { store } = useStore();
  const { storesList } = store;
  const { organisation } = useOrganisation();
  const { activeOrg } = organisation;
  const [tooltips, setTooltips] = useState<boolean[]>();
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const activeStoresList = Array.isArray(storesList)
    ? storesList?.filter((s) => s.has_access && s.is_activated)
    : [];

  const onStoreSelected = (organisationId: string) => {
    // hard refresh app to new org ID
    window.location.href = `${routeConfig.ROOT}${organisationId}`;
  };

  useEffect(() => {
    if (activeStoresList?.length && activeStoresList?.length > 0) {
      // initialize
      setTooltips(new Array(activeStoresList?.length).fill(false));
    }
  }, [activeStoresList?.length]);

  useEffect(() => {
    if (
      toggleDropdown &&
      activeStoresList?.length &&
      activeStoresList?.length > 0
    ) {
      const el = document.querySelectorAll('#storeName > div > button > div');
      const newTooltips = new Array(activeStoresList?.length).fill(false);

      // check & enable tooltip on text overflow of dropdown items
      el.forEach((elem, index) => {
        if (elem.scrollWidth > elem?.clientWidth) {
          newTooltips[index] = true;
        }
      });
      setTooltips(newTooltips);
    }
  }, [toggleDropdown]);

  return (
    <Dropdown
      isOpen={toggleDropdown}
      toggle={() => setToggleDropdown((prev) => !prev)}
      size="sm"
      className={classNames(styles.store_dropdown)}
    >
      <DropdownToggle
        tag="div"
        className={classNames(
          'd-flex align-items-center justify-content-between',
          styles.store_dropdown_toggle
        )}
        disabled={!activeOrg}
        data-testid="selectedStoreBtn"
      >
        <span id={activeOrg?.name} className="mr-2">
          {activeOrg?.name || 'Select Store'}
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="#4D5664"
          viewBox="0 0 256 256"
        >
          <path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,48,88H208a8,8,0,0,1,5.66,13.66Z"></path>
        </svg>
      </DropdownToggle>
      <DropdownMenu id="storeName" className={styles.store_dropdown_menu}>
        <div className="p-2" data-testid="storeDropdownList">
          {activeStoresList?.map((item, index) => (
            <>
              <DropdownItem
                id={`${item.organization_id}-tooltip`}
                key={item.organization_id}
                className={classNames(
                  styles.store_dropdown_item,
                  item.organization_id === activeOrg?.id
                    ? styles.active_store_dropdown_item
                    : ''
                )}
                onClick={() => onStoreSelected(item.organization_id)}
                data-testid="storeListItem"
              >
                <div>{item.dba_name}</div>
                {item.organization_id === activeOrg?.id && (
                  <i className="ph ph-check-bold"></i>
                )}
              </DropdownItem>
              {tooltips?.[index] && (
                <UncontrolledTooltip
                  placement="bottom"
                  target={`${item.organization_id}-tooltip`}
                  modifiers={{
                    preventOverflow: { boundariesElement: 'window' },
                  }}
                >
                  {item.dba_name}
                </UncontrolledTooltip>
              )}
            </>
          ))}
        </div>
        <DropdownItem divider className="m-0" />
        <div className="p-2">
          <DropdownItem
            tag="a"
            target="_blank"
            href={oneAppUrl(ENV || 'production')}
            className={classNames(
              styles.add_another_store,
              'd-flex align-items-center'
            )}
          >
            <i className="ph-bold ph-plus icon-lg mr-1"></i>
            Add another store
          </DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default StoreSwitch;
