import React, { useEffect, useState } from 'react';
import {
  Col,
  FormGroup,
  Input,
  Button,
  ModalFooter,
  Row,
  UncontrolledTooltip,
  Alert,
} from 'reactstrap';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import ReactDatetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import BootstrapTable from 'react-bootstrap-table-next';
import moment, { Moment } from 'moment';
import classNames from 'classnames';
import FormLabel from 'components/form-label';
import ErrorMessage from 'components/errorMessage';
import { PAYMENTLINKS } from 'state/paymentLinks/type';
import usePaymentLinks from 'state/paymentLinks/hooks/usePaymentLinks';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import { formatDate, toIsoStringWithTz } from 'utils/date-format';
import {
  EMAIL_VALIDATION,
  NAME_VALIDATION,
  NUMBER_VALIDATION,
  PHONE_NUMBER_VALIDATION,
} from 'utils/validation';
import CURRENCY from 'utils/currency';
import NumberFormat from 'react-number-format';
import AddProducts, { Products } from './addProducts';
import styles from '../addLinks.module.scss';

interface IProps {
  prefillData?: PAYMENTLINKS.ICreateDuplicatePaymentLink;
  onClose: () => void;
}

const CustomerDetails = ({ prefillData, onClose }: IProps) => {
  const {
    paymentLinks,
    toCreatePaymentLink,
    toVerifyPaymentLinks,
    resetVerification,
  } = usePaymentLinks();
  const { organisation } = useOrganisation();
  const { activeOrg } = organisation;

  const { errors, register, handleSubmit, control, getValues } = useForm({
    defaultValues: {
      ...prefillData,
      phone: prefillData?.phone,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'products',
  });
  const [invoiceDate, setInvoiceDate] = useState<string | undefined>(
    prefillData?.invoice_date || toIsoStringWithTz(new Date())
  );
  const [productError, setProductError] = useState<boolean>(false);
  const [createPLRequest, setCreatePLRequest] =
    useState<PAYMENTLINKS.ICreatePaymentLink>();

  const TABLE_COLUMNS = [
    {
      text: 'Name',
      sort: false,
      dataField: 'name',
      formatter: (cell: string) => {
        return <>{cell}</>;
      },
    },
    {
      text: 'Amount',
      sort: false,
      dataField: 'amount',
      formatter: (cell: number) => {
        return (
          <div className="currencyColumn">
            {CURRENCY.convertToMainUnit(cell)}
          </div>
        );
      },
    },
    {
      text: 'QTY',
      sort: false,
      dataField: 'quantity',
    },
    {
      text: 'Tax(%)',
      sort: false,
      dataField: 'tax',
      formatter: (cell: number) => {
        return <>{cell || 0}</>;
      },
    },
    {
      text: '',
      sort: false,
      dataField: 'dummy',
      events: {
        onClick: (
          e: unknown,
          column: unknown,
          columnIndex: number,
          row: unknown,
          rowIndex: number
        ) => {
          remove(rowIndex);
        },
      },
      formatter: () => (
        <div className="d-flex align-items-center">
          <Button
            size="sm"
            className="btn-text p-0 m-0 d-flex align-items-center"
          >
            <i className="ph-trash icon-md" />
          </Button>
        </div>
      ),
    },
  ];

  const onAddProducts = (products: Products) => {
    append(products);
  };

  type FormValues = {
    products: {
      name: string;
      amount: number;
      quantity: number;
      tax: number;
    }[];
  };

  useEffect(() => {
    if (paymentLinks.verified && createPLRequest) {
      if (paymentLinks.verifyInvoiceNumber.is_exist) {
        if (
          // eslint-disable-next-line no-alert
          window.confirm(
            'Invoice number should be unique for each payment link. Please change the invoice number. If you do not wish to change the invoice number, we will deactivate the previously created payment link with the same invoice number. Do you want to proceed?'
          ) // skipcq: JS-0052
        ) {
          toCreatePaymentLink(createPLRequest);
        } else {
          resetVerification();
        }
      } else {
        toCreatePaymentLink(createPLRequest);
      }
    }
  }, [paymentLinks.verified, paymentLinks.verifyInvoiceNumber.is_exist]);

  const onFormSubmit = (values: {
    [x: string]: string | number | FormValues;
  }) => {
    if (activeOrg && fields.length > 0) {
      setProductError(false);
      const request: PAYMENTLINKS.ICreatePaymentLink = {
        organization_id: activeOrg?.id,
        auto_charge: true,
        invoice_type: 'manual',
        invoice_number: values.invoice_number as string,
        consumer_details: {
          first_name: values.first_name as string,
          last_name: values.last_name as string,
          email: values.email as string,
          phone: values.phone as string,
        },
        product_details: fields as unknown as PAYMENTLINKS.IProductDetails[],
        invoice_date: invoiceDate as string,
        total_amount: CURRENCY.convertToSmallestUnit(
          parseFloat(values.total as string)
        ),
      };
      setCreatePLRequest(request);
      toVerifyPaymentLinks({
        store_id: activeOrg?.brand_settings?.store_identifier,
        invoice_number: values.invoice_number as string,
      });
    } else {
      setProductError(true);
    }
  };

  return (
    <div className="d-flex flex-column justify-content-between h-100">
      <div>
        <FormGroup className="m-5">
          <p className={classNames(styles.invoiceInfoText)}>
            Customer and Invoice Info
            <span id="tooltipProduct" className="pl-1">
              <i className="ph-info-fill icon-md text-muted" />
            </span>
            <UncontrolledTooltip placement="right" target="tooltipProduct">
              Required details for customer and product.
            </UncontrolledTooltip>
          </p>
          <Row className="mb-3">
            <Col xs="12" sm="6" className="pr-3">
              <FormLabel
                isRequired
                check
                element="first_name"
                label="First Name"
                className={classNames('body-subtext-medium', styles.fieldLabel)}
              ></FormLabel>
              <Input
                name="first_name"
                placeholder="Forrest"
                defaultValue={prefillData?.first_name || undefined}
                className={errors?.first_name ? styles.borderAlert : ''}
                innerRef={register({
                  pattern: NAME_VALIDATION,
                  validate: {
                    required: (value) => {
                      return value.length <= 0
                        ? 'Please enter a valid first name'
                        : true;
                    },
                  },
                })}
              />
              <div>
                {errors?.first_name && (
                  <ErrorMessage
                    isValidation
                    message="Please enter a valid first name"
                  />
                )}
              </div>
            </Col>
            <Col xs="12" sm="6" className="pl-3">
              <FormLabel
                isRequired
                check
                element="last_name"
                label="Last Name"
                className={classNames('body-subtext-medium', styles.fieldLabel)}
              ></FormLabel>
              <Input
                name="last_name"
                placeholder="Grump"
                defaultValue={prefillData?.last_name || undefined}
                className={errors.last_name ? styles.borderAlert : ''}
                innerRef={register({
                  pattern: NAME_VALIDATION,
                  validate: {
                    required: (value) => {
                      return value.length <= 0
                        ? 'Please enter a valid last name'
                        : true;
                    },
                  },
                })}
              />
              <div>
                {errors?.last_name && (
                  <ErrorMessage
                    isValidation
                    message="Please enter a valid last name"
                  />
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="6" className="pl-3">
              <FormLabel
                isRequired
                check
                element="email"
                label="Email"
                className={classNames('body-subtext-medium', styles.fieldLabel)}
              ></FormLabel>
              <Input
                name="email"
                defaultValue={prefillData?.email || undefined}
                placeholder="abc@workemail.com"
                className={errors.email ? styles.borderAlert : ''}
                innerRef={register({
                  pattern: EMAIL_VALIDATION,
                  validate: {
                    required: (value) => {
                      return value.length <= 0
                        ? 'Please enter a valid email'
                        : true;
                    },
                  },
                })}
              />
              <div>
                {errors?.email && (
                  <ErrorMessage
                    isValidation
                    message="Please enter a valid email"
                  />
                )}
              </div>
            </Col>
            <Col xs="12" sm="6" className="pl-3">
              <FormLabel
                isRequired
                element="phone"
                label="Phone number"
                className={classNames('body-subtext-medium', styles.fieldLabel)}
              ></FormLabel>
              <Controller
                control={control}
                name="phone"
                id="phone"
                rules={{
                  required: true,
                  pattern: PHONE_NUMBER_VALIDATION,
                }}
                defaultValue={getValues('phone_number') ?? ''}
                render={(field) => (
                  <NumberFormat
                    format="+#-###-###-####"
                    id="phone"
                    name="phone"
                    mask="_"
                    placeholder="+1-555-555-5555"
                    className={classNames(
                      'd-block form-control',
                      errors.phone && styles.borderAlert
                    )}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      field.onChange(e);
                    }}
                    value={field.value}
                    getInputRef={field.ref}
                  />
                )}
              />
              <div>
                {errors?.phone && (
                  <ErrorMessage
                    isValidation
                    message="Please enter a valid phone number"
                  />
                )}
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs="12" sm="6" className="pr-3">
              <FormLabel
                isRequired
                check
                element="invoice_number"
                label="Invoice number"
                className={classNames('body-subtext-medium', styles.fieldLabel)}
              ></FormLabel>
              <Input
                defaultValue={prefillData?.invoice_number || undefined}
                name="invoice_number"
                placeholder="In - 111107283y21y39"
                className={errors?.invoice_number ? styles.borderAlert : ''}
                innerRef={register({
                  validate: {
                    required: (value) => {
                      return value.length <= 0
                        ? 'Please enter a valid invoice number'
                        : true;
                    },
                  },
                })}
              />
              <div>
                {errors?.invoice_number && (
                  <ErrorMessage
                    isValidation
                    message="Please enter a valid invoice number"
                  />
                )}
              </div>
            </Col>
            <Col xs="12" sm="6" className="pl-3">
              <FormLabel
                isRequired
                check
                element="invoiceDate"
                label="Invoice date"
                className={classNames('body-subtext-medium', styles.fieldLabel)}
              ></FormLabel>
              <br />
              <div
                className={classNames(
                  styles.container,
                  'd-inline-flex align-items-center'
                )}
              >
                <ReactDatetime
                  dateFormat="DD MMM YYYY"
                  defaultValue={formatDate(invoiceDate || '', 'dd MMM yyyy')}
                  inputProps={{
                    placeholder: 'Date',
                  }}
                  onChange={(e: Moment | string) => {
                    if (e instanceof moment) {
                      const dateString = moment(
                        (e as Moment).format()
                      ).toISOString(true);
                      setInvoiceDate(dateString);
                    }
                  }}
                  timeFormat={false}
                  closeOnSelect
                />
              </div>
            </Col>
          </Row>
        </FormGroup>
        <div className="m-5">
          <p className={classNames(styles.invoiceInfoText)}>
            Product details
            <span id="tooltip" className="pl-1">
              <i className="ph-info-fill icon-md text-muted" />
            </span>
            <UncontrolledTooltip placement="right" target="tooltip">
              Required details for product.
            </UncontrolledTooltip>
          </p>
          {fields.length > 0 && (
            <BootstrapTable
              wrapperClasses="table-responsive"
              keyField="_id"
              data={fields}
              columns={TABLE_COLUMNS}
              condensed
              hover
              bordered={false}
              noDataIndication={
                <p className="text-center text-regular">No results found</p>
              }
            />
          )}
          <AddProducts
            onAdd={onAddProducts}
            errorControl={setProductError}
            productError={productError}
          />
        </div>
        <FormGroup className="m-5">
          <Row>
            <Col xs="12" sm="6" className="pr-3">
              <FormLabel
                check
                element="discount"
                label="Discount(%)"
                className={classNames('body-subtext-medium', styles.fieldLabel)}
              ></FormLabel>
              <Input
                name="discount"
                type="number"
                placeholder="0"
                defaultValue={0}
                innerRef={register({
                  pattern: NUMBER_VALIDATION,
                })}
                className={errors.discount ? styles.borderAlert : ''}
              />
              <div>
                {errors?.discount && (
                  <ErrorMessage isValidation message="Please enter a number" />
                )}
              </div>
            </Col>
            <Col xs="12" sm="6" className="pl-3">
              <FormLabel
                isRequired
                element="total"
                label="Total"
                className={classNames('body-subtext-medium', styles.fieldLabel)}
              ></FormLabel>
              <Input
                name="total"
                type="number"
                placeholder="0"
                min={0}
                className={errors.total ? styles.borderAlert : ''}
                innerRef={register({
                  pattern: NUMBER_VALIDATION,
                  validate: {
                    required: (value) => {
                      return value < 1
                        ? 'Please enter a valid total amount'
                        : true;
                    },
                  },
                })}
              />
              <div>
                {errors?.total && (
                  <ErrorMessage
                    isValidation
                    message="Please enter a valid total amount"
                  />
                )}
              </div>
            </Col>
          </Row>
        </FormGroup>
      </div>
      <div>
        {paymentLinks.error?.createPaymentLink &&
          paymentLinks.error?.createPaymentLink.type === 'manual' && (
            <Alert
              color="danger"
              className="d-flex justify-content-around align-items-center mx-5 my-2"
            >
              <i className="ph-warning-circle mr-3"></i>
              <span className="flex-grow-1 text-capitalize">
                {paymentLinks.error?.createPaymentLink.message ||
                  'Something went wrong! Please try again.'}
              </span>
            </Alert>
          )}
        <ModalFooter>
          <div>
            <Button variant="text" onClick={onClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              className="btn btn-primary"
              onClick={handleSubmit(onFormSubmit)}
            >
              Create Link
            </Button>
          </div>
        </ModalFooter>
      </div>
    </div>
  );
};

export default CustomerDetails;
