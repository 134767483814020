import classNames from 'classnames';
import { DropdownConfig } from 'components/selectDropdown';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import useBanking from 'state/banking/hooks/useBanking';
import {
  IActiveState,
} from 'components/modals/banking/payouts/payout';
import { BANKING } from 'state/banking/type';
import ToastContainer from 'components/toasts/container';
import AddBankAccount from './addBank';
import styles from '../../styles.module.scss';
import ListBankAccounts from './listBank';

interface Iprops {
  setActiveState: Dispatch<SetStateAction<IActiveState>>;
  activeState: IActiveState;
}

const BankDetails = ({ activeState, setActiveState }: Iprops): JSX.Element => {
  const { banking, getVendorPayoutAccountList } = useBanking();
  const { vendor_id } = banking;

  const initialDropdownConfig: DropdownConfig<BANKING.IVendorAccountList> = {
    isSelected: false,
    options: undefined,
    selectedItemName: undefined,
    selectedItemData: undefined,
    addNewItem: false,
    updateItem: false,
  };

  const [dropdownConfig, setDropdownConfig] = useState(initialDropdownConfig);

  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (vendor_id) {
      getVendorPayoutAccountList(vendor_id);
    }
  }, []);

  useEffect(() => {
    if (banking.vendorAccountList) {
      dropdownConfig.options = banking.vendorAccountList;
      setDropdownConfig({ ...dropdownConfig });
    }
  }, [banking.vendorAccountList]);

  return (
    <>
      <div
        className={classNames(styles.formContainer, 'h-100', 'hide-scrollbars')}
      >
        <h1 className={classNames(styles.heading)}>Bank details</h1>
        <h2 className={classNames(styles.subHeading, 'body-subtext-regular')}>
          Add your vendor&apos;s bank account to make payment. You can either
          search for an already added bank account or add a new bank account.
        </h2>

        {!dropdownConfig.addNewItem && !dropdownConfig.updateItem ? (
          <div>
            <ListBankAccounts
              dropdownConfig={dropdownConfig}
              activeState={activeState}
              setActiveState={setActiveState}
              setDropdownConfig={setDropdownConfig}
            />
          </div>
        ) : (
          <AddBankAccount
            setActiveState={setActiveState}
            activeState={activeState}
            dropdownConfig={dropdownConfig}
            setDropdownConfig={setDropdownConfig}
            showToast={showSuccess}
            setShowToast={setShowSuccess}
          />
        )}
      </div>
      <ToastContainer
        showToast={showSuccess}
        onClose={() => setShowSuccess(false)}
        message={'Bank account added successfully'}
      />
    </>
  );
};

export default BankDetails;
