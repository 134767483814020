import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import BootstrapTable from 'react-bootstrap-table-next';
import CURRENCY from 'utils/currency';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Tooltip,
  UncontrolledDropdown,
} from 'reactstrap';
import Pagination from 'components/pagination';
import { formatToTz } from 'utils/date-format';
import CopyToClipboard from 'react-copy-to-clipboard';
import DeleteModal from 'components/modals/delete';
import { PAYMENTLINKS } from 'state/paymentLinks/type';
import usePaymentLinks from 'state/paymentLinks/hooks/usePaymentLinks';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import ToastContainer, { ToastType } from 'components/toasts/container';
import AddPaymentLink from './addPaymentLink';
import CreatePLSuccess from './addPaymentLink/createPLSuccess';
import styles from './paymentlinks.module.scss';

interface ModalData {
  first_name: string;
  last_name: string;
  email: string;
  store_id: string;
  invoice_number: string;
  invoice_date: string;
  phone: string;
  organization_id: string;
  discount?: number;
  total: number;
  invoice_type: 'manual';
}
interface IProps {
  dateFrom?: string;
  dateTo?: string;
  invoiceType?: string;
  amountOption?: string;
  firstAmount?: string;
  secondAmount?: string;
  invoiceOrderId?: string;
  sendEmail: (arg: string, name?: string) => void;
  sendSMS: (arg: string, name?: string) => void;
  disableSMS: boolean;
  disableEmail: boolean;
}

const PaymentLinksTab = ({
  dateFrom,
  dateTo,
  invoiceType,
  amountOption,
  firstAmount,
  secondAmount,
  invoiceOrderId,
  sendEmail,
  sendSMS,
  disableSMS,
  disableEmail,
}: IProps): JSX.Element => {
  const {
    paymentLinks,
    toFetchPaymentLinks,
    toDeletePaymentLink,
    resetCreatePaymentLink,
  } = usePaymentLinks();

  const { error } = paymentLinks;

  const { organisation } = useOrganisation();
  const { settings } = organisation;

  // pagination values
  const [prevKey, setPrevKey] = useState<string | null>(null);
  const [nextKey, setNextKey] = useState<string | null>(null);
  const [numResults, setNumResults] = useState(0);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const [copied, setCopied] = useState<boolean>(false);

  const [rowId, setRowId] = useState<string>('');
  const setCopiedWithTooltip = (state: boolean, rowContent: string) => {
    setRowId(rowContent);
    setCopied(state);
  };
  const [deleteRowId, setDeleteRowId] = useState<string>('');
  const onDelete = (id: string) => {
    toDeletePaymentLink(id);
    setDeleteModal(false);
  };

  const setDelete = (e: boolean, id: string) => {
    setDeleteModal(e);
    setDeleteRowId(id);
  };

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [paymentLinkCreated, setPaymentLinkCreated] = useState<string | null>(
    null
  );

  const [modalData, setModalData] = useState<ModalData>();

  // function to duplicate a particular payment link with all values prefilled in modal
  const duplicateLink = (duplicateData: PAYMENTLINKS.IPaymentLinksResult) => {
    setShowModal(true);
    setModalData({
      first_name: duplicateData.customer_json.first_name,
      last_name: duplicateData.customer_json.last_name,
      email: duplicateData.customer_json.email,
      store_id: duplicateData.store_id,
      invoice_number: duplicateData.external_ticket_id,
      invoice_date: duplicateData.invoice_date,
      organization_id: duplicateData.organization_id,
      phone: duplicateData.customer_json.phone,
      discount: duplicateData.discount,
      total: duplicateData.total_amount / 100,
      invoice_type: 'manual',
    });
  };

  // change icon back to copy
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  useEffect(() => {
    toFetchPaymentLinks({
      start_date: dateFrom || undefined,
      end_date: dateTo || undefined,
      amount_1: firstAmount,
      amount_2: secondAmount,
      condition: amountOption,
      invoice_type: invoiceType,
      invoice_order_id: invoiceOrderId || undefined,
    });
  }, [dateFrom, dateTo]);

  // get to prev page
  const prevPage = () => {
    toFetchPaymentLinks({
      key: prevKey,
      start_date: dateFrom || undefined,
      end_date: dateTo || undefined,
      amount_1: firstAmount,
      amount_2: secondAmount,
      condition: amountOption,
      invoice_type: invoiceType,
      invoice_order_id: invoiceOrderId || undefined,
    });
  };

  // get to next page
  const nextPage = () => {
    toFetchPaymentLinks({
      key: nextKey,
      start_date: dateFrom || undefined,
      end_date: dateTo || undefined,
      amount_1: firstAmount,
      amount_2: secondAmount,
      condition: amountOption,
      invoice_type: invoiceType,
      invoice_order_id: invoiceOrderId || undefined,
    });
  };

  // set pagination results
  useEffect(() => {
    if (paymentLinks.paymentLinksList?.items) {
      setNextKey(paymentLinks.paymentLinksList.next_key);
      setPrevKey(paymentLinks.paymentLinksList.prev_key);
      setNumResults(paymentLinks.paymentLinksList.total_count);
    }
  }, [paymentLinks.paymentLinksList]);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Something went wrong');

  const updateError = () => {
    if ((error?.sendSMS || error?.sendEmail) && !showSuccessModal) {
      setShowError(true);
      if (
        error.sendEmail === 'payment_link_expired' ||
        error.sendSMS === 'payment_link_expired'
      ) {
        setErrorMessage('Payment link expired.');
      } else if (
        error.sendEmail === 'payment_link_completed' ||
        error.sendSMS === 'payment_link_completed'
      ) {
        setErrorMessage('Payment link already paid.');
      }
    } else if (error?.sendEmail === undefined || error?.sendSMS === undefined) {
      setShowError(false);
    }
  };

  useEffect(() => {
    updateError();
  }, [error]);

  /**
   * If the cell content is longer than 70 characters, return the first 70 characters of the cell
   * content with an ellipsis, otherwise return the cell content
   * @param {string} cellContent - The content of the cell.
   * @returns A function that takes a string as an argument and returns a string.
   */
  const truncate = (cellContent: string) => {
    if (cellContent.length > 62) {
      return `${cellContent.substring(0, 62)}...`;
    }
    return cellContent;
  };

  const TABLE_COLUMNS = [
    {
      text: 'Created on',
      sort: true,
      dataField: 'create_date',
      formatter: (cell: string) => {
        return (
          <>
            {cell
              ? formatToTz(cell, settings?.timezone, 'MMM dd yyyy, hh:mm a')
              : ''}
          </>
        );
      },
    },
    {
      text: 'Link',
      sort: false,
      dataField: 'payment_link',
      formatter: (
        cellContent: string,
        rowIndex: PAYMENTLINKS.IPaymentLinksResult
      ) => (
        <>
          <span>{truncate(cellContent)}</span>
          <span
            id={`${rowIndex.id}-tooltipCopy`}
            onClick={() => {
              setCopiedWithTooltip(true, rowIndex.id);
            }}
            className="pl-1"
          >
            <CopyToClipboard text={cellContent || 'NA'}>
              <i
                className={classNames(
                  styles.copyBtn,
                  'ph-copy-simple-light ml-1 icon-xl align-middle'
                )}
              />
            </CopyToClipboard>
          </span>
        </>
      ),
    },
    {
      text: 'Order ID',
      sort: true,
      dataField: 'external_ticket_id',
    },
    {
      text: 'Amount',
      sort: true,
      dataField: 'total_amount',
      formatter: (cell: number) => {
        return (
          <div className="currencyColumn">
            {CURRENCY.convertToMainUnit(cell)}
          </div>
        );
      },
    },
    {
      text: 'Invoice Type',
      sort: true,
      dataField: 'invoice_type',
      formatter: (cell: string) => {
        return <div className="text-capitalize">{cell}</div>;
      },
    },
    {
      dataField: 'dummy',
      text: 'Action',
      sort: false,
      formatter: (
        cellContent: string,
        row: PAYMENTLINKS.IPaymentLinksResult
      ) => (
        <>
          <UncontrolledDropdown>
            <DropdownToggle data-toggle="dropdown" tag="span">
              <span className={classNames(styles.filterText)}>
                <i className="ph-dots-three" />
              </span>
            </DropdownToggle>
            <DropdownMenu right className={classNames(styles.filterMenu)}>
              <DropdownItem
                disabled={disableSMS}
                onClick={() => {
                  sendSMS(row.id, row.customer_json.first_name);
                }}
                className={classNames(styles.dropdownTextDuplicate)}
              >
                Send SMS
              </DropdownItem>
              <DropdownItem
                disabled={disableEmail}
                onClick={() => {
                  sendEmail(row.id, row.customer_json.first_name);
                }}
                className={classNames(
                  styles.dropdownTextDuplicate,
                  disableEmail ? styles.disableEvents : ''
                )}
              >
                Send Email
              </DropdownItem>
              <DropdownItem divider className={classNames(styles.divider)} />
              <DropdownItem
                onClick={() => {
                  duplicateLink(row);
                }}
                className={classNames(styles.dropdownTextDuplicate)}
              >
                Duplicate
              </DropdownItem>
              <DropdownItem
                disabled={row.status !== null}
                onClick={() => {
                  setDelete(true, row.id);
                }}
                className={classNames(
                  styles.dropdownTextDuplicate,
                  row.status !== null ? styles.disabled : ''
                )}
              >
                Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      ),
    },
  ];

  return (
    <>
      <BootstrapTable
        wrapperClasses={classNames('table-responsive', styles.paymentLinkTable)}
        keyField="_id"
        data={paymentLinks.paymentLinksList.items || []}
        columns={TABLE_COLUMNS}
        condensed
        bordered={false}
        noDataIndication={
          <p className="text-center text-regular">No results found</p>
        }
      />
      <Pagination
        results={numResults}
        prevPage={prevPage}
        nextPage={nextPage}
        prevKey={prevKey}
        nextKey={nextKey}
      />
      <DeleteModal
        showModal={deleteModal}
        setShowModal={setDeleteModal}
        warningStatement={'Are you sure you want to delete this payment link?'}
        tag={'payment link'}
        action={() => onDelete(deleteRowId)}
      />
      {showModal && (
        <AddPaymentLink
          showModal={showModal}
          setShowModal={setShowModal}
          prefillData={modalData}
          onSuccess={(paymentLink: string) => {
            setShowSuccessModal(true);
            setPaymentLinkCreated(paymentLink);
          }}
        />
      )}
      {showSuccessModal && paymentLinkCreated && (
        <CreatePLSuccess
          paymentLink={paymentLinkCreated}
          showModal={showSuccessModal}
          onClose={() => {
            setShowSuccessModal(false);
            setPaymentLinkCreated(null);
            resetCreatePaymentLink();
          }}
          sendEmail={sendEmail}
          sendSMS={sendSMS}
          paymentLinkID={paymentLinks.createPaymentLink.id}
          errors={paymentLinks.error}
          disableEmail={disableEmail}
          disableSMS={disableSMS}
        />
      )}
      <ToastContainer
        type={ToastType.error}
        showToast={showError}
        onClose={() => setShowError(!showError)}
        message={errorMessage}
      />
      {copied && (
        <Tooltip
          placement="bottom"
          isOpen={copied}
          target={`${rowId}-tooltipCopy`}
        >
          Copied!
        </Tooltip>
      )}
    </>
  );
};
export default PaymentLinksTab;
