import React from 'react';
import PropTypes from 'prop-types';
import usePayment from 'state/payment/hooks/usePayment';
import useSettings from 'state/settings/hooks/useSettings';
import classNames from 'classnames';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import useStore from 'state/store/hooks/useStore';
import useWebhooks from 'state/webhooks/hooks/useWebhooks';
import usePaymentLinks from 'state/paymentLinks/hooks/usePaymentLinks';
import useStats from 'state/stats/hooks/useStats';
import useAuth from 'state/auth/hooks/useAuth';
import useRefunds from 'state/refunds/hooks/useRefunds';
import { Spinner } from 'reactstrap';
import useActivation from 'state/kybActivation/hooks/useActivation';
import useEvents from 'state/events/hooks/useEvents';
import useConsumer from 'state/consumer/hooks/useConsumer';
import useTerminal from 'state/terminal/hooks/useTerminal';
import useBanking from 'state/banking/hooks/useBanking';
import usePartnerStores from 'state/partnerStores/hooks/usePartnerStores';
import styles from './spinner.module.scss';
import usePaymentConfig from 'state/paymentConfig/hooks/usePaymentConfig';

interface IProps {
  isLoading: boolean;
}

const GlobalSpinner = ({ isLoading }: IProps): JSX.Element | null => {
  const { auth } = useAuth();
  const { payment } = usePayment();
  const { settings } = useSettings();
  const { organisation } = useOrganisation();
  const { store } = useStore();
  const { webhooks } = useWebhooks();
  const { paymentLinks } = usePaymentLinks();
  const { stats } = useStats();
  const { refunds } = useRefunds();
  const { kybActivation } = useActivation();
  const { events } = useEvents();
  const { consumers } = useConsumer();
  const { terminal } = useTerminal();
  const { banking } = useBanking();
  const { partnerStores } = usePartnerStores();
  const { paymentConfig } = usePaymentConfig();

  const showSpinner =
    isLoading ||
    auth?.isLoading ||
    payment?.isLoading ||
    settings?.isLoading ||
    organisation?.isLoading ||
    store?.isLoading > 0 ||
    webhooks?.isLoading ||
    paymentLinks.isLoading ||
    stats?.isLoading ||
    refunds?.isLoading ||
    kybActivation?.isLoading ||
    events?.isLoading ||
    consumers?.isLoading ||
    terminal?.isLoading ||
    banking?.isLoading ||
    partnerStores?.isLoading ||
    paymentConfig?.isLoading;

  return showSpinner ? (
    <div className={classNames(styles.spinnerContainer)}>
      <Spinner
        data-testid="global-spinner"
        color="primary"
        className={classNames(styles.spinner)}
      />
    </div>
  ) : null;
};

GlobalSpinner.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};
export default GlobalSpinner;
