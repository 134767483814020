import React from 'react';
import CURRENCY from 'utils/currency';
import { PAYMENTS } from 'state/payment/type';
import styles from '../summary.module.scss';

interface IProps {
  row: PAYMENTS.ITransactionSummaryBreakdown;
}

const BankingIncomingAccordion = ({ row }: IProps): JSX.Element => {
  return (
    <>
      <div>
        <p className={styles.dropDownInfo}>
          <span>Order amount</span>
          <span data-testid="order_amount">
            {CURRENCY.convertToMainUnit(row?.order_amount) || '$00.00'}
          </span>
        </p>
        <p className={styles.dropDownInfo}>
          <span>Tip amount</span>
          <span data-testid="tip_amount">
            {CURRENCY.convertToMainUnit(row?.tip_amount) || '$00.00'}
          </span>
        </p>
        <p className={styles.dropDownInfo}>
          <span>Convenience fee</span>
          <span data-testid="convenience_fee_amount">
            {CURRENCY.convertToMainUnit(row?.convenience_fee_amount) ||
              '$00.00'}
          </span>
        </p>
        <p className={styles.dropDownInfo}>
          <span>Other incoming transactions</span>
          <span data-testid="other_incoming">
            {CURRENCY.convertToMainUnit(row?.other_incoming)}
          </span>
        </p>
        <p className={styles.dropDownGross}>
          <span>Total income</span>
          <span data-testid="gross_amount">
            {CURRENCY.convertToMainUnit(row?.gross_amount) || '$00.00'}
          </span>
        </p>
      </div>
    </>
  );
};

export default BankingIncomingAccordion;
