import React, { Dispatch, SetStateAction, useState } from 'react';
import classNames from 'classnames';
import ErrorMessage from 'components/errorMessage';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styles from './notes.module.scss';

interface IProps {
  inputValue: string;
  noteAreaOpen: boolean;
  setInputValue: Dispatch<SetStateAction<string>>;
  closeNoteArea: () => void;
  updateNote: () => void;
}

const Notes = ({
  inputValue,
  noteAreaOpen,
  setInputValue,
  closeNoteArea,
  updateNote,
}: IProps): JSX.Element => {
  const [confirmationModal, showConfirmationModal] = useState(false);
  const addNote = () => {
    showConfirmationModal(true);
  };
  const handleModal = (param: boolean) => {
    if (param) {
      updateNote();
    }
    showConfirmationModal(false);
  };

  return noteAreaOpen ? (
    <>
      <div className={classNames('pb-1 mb-4')}>
        <textarea
          id="notes"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className={classNames(
            styles.notes_textarea,
            inputValue.length > 20 && styles.notes_error
          )}
          rows={4}
          placeholder="Add a note..."
          required
        ></textarea>
        {inputValue.length > 20 && (
          <ErrorMessage
            isValidation
            className="mt-1"
            message="Maximum characters exceeded"
            preIcon={
              <i className="ph-warning-circle-fill icon-md dangerIcon"></i>
            }
          />
        )}
        <div className="d-flex justify-content-end mt-1">
          <Button
            size="sm"
            className={classNames('btn-text mr-2')}
            onClick={closeNoteArea}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            disabled={inputValue.length === 0 || inputValue.length > 20}
            color="secondary"
            onClick={addNote}
            className={'m-0'}
          >
            Add
          </Button>
        </div>
      </div>
      {confirmationModal ? (
        <Modal
          isOpen={confirmationModal}
          backdrop
          size="xl"
          keyboard={false}
          centered
        >
          <ModalHeader toggle={() => showConfirmationModal(false)}>
            Alert
          </ModalHeader>
          <ModalBody className={classNames(styles.content)}>
            This will overwrite your existing note for this transaction. Are you
            sure you want to proceed?
          </ModalBody>
          <ModalFooter>
            <div>
              <Button
                className={classNames('btn-text')}
                onClick={() => handleModal(false)}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                color="primary"
                onClick={() => handleModal(true)}
              >
                Confirm
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  );
};
export default Notes;
