import { PAYOUTTYPE } from 'components/modals/banking/payouts/payout';
import { string } from 'prop-types';
import { PAYMENTS } from 'state/payment/type';
import { SUMMARY } from 'state/summary/type';

// skipcq: JS-0337
export enum ConfigurationInputType {
  TEXT = 'text',
  PASSWORD = 'password',
  SELECT = 'select',
  TOGGLE = 'toggle',
}

export enum PaymentDeviceConfig {
  FULL = 'full',
  SIMPLE = 'simple',
  NONE = 'none',
}
export namespace PAYMENT_CONFIG {
  interface ErrorObject {
    loc: Array<string>;
    msg: string;
    type: string;
  }

  export interface Error {
    fetchPaymentSolutions?: {
      message?: string;
    };
    fetchDefaultProvider?: {
      message?: string;
    };
    getPaymentDevice?: {
      message?: string;
    };
    createPaymentDevice?: {
      message?: string;
    };
    editPaymentDevice?: {
      message?: string;
    };
    deviceStatus?: {
      message?: string;
    };
    deviceDeleted?: {
      message?: string;
    };
    getCardReaderValues?: {
      message?: string;
    };
    saveCardReader?: {
      message?: string;
    };
    saveDefaultCardReader?: {
      message?: string;
    };
    paymentConfiguration?: {
      message?: string;
    };
  }

  export interface IProcessors {
    processorId: string;
    isEnabled: boolean;
    processorName: string;
    paymentMethod: string;
  }

  export interface DefaultProcessorData {
    processorId: string;
    processorName: string;
    paymentType: string;
  }
  export interface IFetchDefaultProcessor {
    message: string;
    data: Array<DefaultProcessorData | null>;
  }
  export type ICreatePaymentDeviceSimple = {
    name: string;
    deviceregisterid: string;
    processorId: string;
  };

  export type ICreatePaymentDeviceFull = {
    name: string;
    deviceregisterid: string;
    printConfig: string | null;
    deviceauthcode: string | null;
    processorId: string;
    tpn?: string;
  };
  export interface IGetPaymentDeviceLocation {
    id: string;
    name: string;
    deviceregisterid: string;
    createdAt: string;
    processorId: string;
    processorName: string;
    updatedAt: string;
    deviceauthcode: string;
    printConfig: string;
    tpn: string;
  }

  export type PaymentDeviceRequestBody = {
    processorNames: string;
    processorId: string;
  };

  export interface IProcessorObject {
    processors: Array<IProcessors> | null;
  }

  export interface IPaymentSolutions {
    entityId: string;
    processor: IProcessorObject;
  }

  export type IGlobalCardReader = {
    name: string;
    category: string | null;
    code: string;
    value: string;
    id: string | undefined;
    isSecret: boolean;
    isRequired: boolean;
  };
  export type IGlobalCardReaderDefault = {
    name: string;
    category: string;
    processorId: string;
    code: string;
    value: string;
    isSecret: boolean;
    isRequired: boolean;
  };

  export interface IGetCardReaderValues {
    category: string;
    code: string;
    createdAt: string;
    entityId: string;
    id: string;
    isRequired: boolean;
    isSecret: boolean;
    name: string;
    processorId: string;
    value: string;
  }
  export interface IGetCardReaderList {
    items: IGetCardReaderValues[];
  }
  export interface IDeviceStatus {
    isOnline: boolean;
    raw?: string;
  }

  export interface IProcessorConfigurationObj {
    configurations: IProcessorConfiguration[];
    deviceConfigurationMode: PaymentDeviceConfig;
    id: string;
  }
  export interface ISelectedOptions {
    name: string;
    value: string;
  }
  export interface IProcessorConfiguration {
    displayName: string;
    inputType: ConfigurationInputType;
    isSecret: boolean;
    code: string;
    isRequired: boolean;
    defaultValue?: string;
    selectOptions?: ISelectedOptions[];
    description?: string;
    helpText?: string;
  }
  export interface PaymentConfigReducer {
    isLoading: boolean;
    error: Error;
    paymentSolutions: IPaymentSolutions;
    defaultProcessor: IFetchDefaultProcessor;
    filteredList: IProcessors[] | undefined;
    paymentDeviceList: IGetPaymentDeviceLocation[] | undefined;
    paymentDeviceCreated: boolean;
    paymentDeviceUpdated: boolean;
    deviceStatus: IDeviceStatus;
    deviceDeleted: boolean;
    getCardReaderValues: IGetCardReaderList;
    saveCardReader: boolean;
    saveDefaultCardReader: boolean;
    paymentConfiguration: IProcessorConfigurationObj;
  }
}
