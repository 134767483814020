import { AxiosInstance, AxiosResponse } from 'axios';
import { SPARC_BASE, ENV } from 'utils/config';
import { createApiInstance, verifyOrgHeader, authInterceptor } from 'utils/api';
import { treezAuthAPIBaseUrl } from 'utils/urlGenerator';

export const SPARC_BASE_SWIFTER =
  ENV === 'development' || ENV === 'local'
    ? 'https://api.dev.swifterhq.com/'
    : ENV === 'preprod'
    ? 'https://api.preprod.swifterhq.com/'
    : 'https://api.swifterhq.com/';

export const DASHBOARD_API_BASE = 'dashboard/v3.0/';
export const USER_API_BASE = 'user/v2.0/';
export const TREEZ_AUTH_BASE = treezAuthAPIBaseUrl(ENV || 'production');

/* A wrapper around the Axios library that allows us to make HTTP requests to our backend */
class Api {
  axiosInst: AxiosInstance;

  hasOrgId = false;

  hasAuth = true;

  setOrgHeader: (arg: string) => void;

  constructor(withOrg = false, hasAuth = true) {
    this.hasOrgId = withOrg;
    this.hasAuth = hasAuth;
    this.axiosInst = createApiInstance();

    this.setOrgHeader = (orgId) => {
      if (this.hasOrgId)
        this.axiosInst.defaults.headers.common['X-Organization-Id'] = orgId;
    };

    // Assumption: Dashboard APIs always require Org ID while User APIs don't
    if (this.hasOrgId) {
      this.axiosInst.defaults.baseURL =
        this.axiosInst.defaults.baseURL?.concat(DASHBOARD_API_BASE);
      this.axiosInst.interceptors.request.use(verifyOrgHeader, (error) => {
        Promise.reject(error);
      });
    } else {
      this.axiosInst.defaults.baseURL = `${SPARC_BASE}${USER_API_BASE}`;
    }

    if (this.hasAuth)
      this.axiosInst.interceptors.request.use(authInterceptor, (error) => {
        Promise.reject(error);
      });
  }

  /*
   * Changes the API instance base URL
   */
  changeAPIBase = (newBaseUrl: string): void => {
    this.axiosInst.defaults.baseURL = newBaseUrl;
  };

  get = <T>(
    url: string,
    data?: Record<string, unknown>,
    headers?: Record<string, unknown>
  ): Promise<AxiosResponse<T>> => {
    const options = { withCredentials: true, headers };
    return this.axiosInst.get(url, { params: data, ...options });
  };

  post = <T>(
    url: string,
    data?: Record<string, unknown>,
    headers?: Record<string, unknown>,
    withCredentialsCheck?: boolean
  ): Promise<AxiosResponse<T>> => {
    const options = { withCredentials: withCredentialsCheck ?? true, headers };
    return this.axiosInst.post(url, data, options);
  };

  put = <T>(
    url: string,
    data?: Record<string, unknown>,
    headers?: Record<string, unknown>
  ): Promise<AxiosResponse<T>> => {
    const options = { withCredentials: true, headers };
    return this.axiosInst
      .put(url, data, options)
      .then((res) => res.data)
      .catch((err) => err.response);
  };

  remove = <T>(url: string): Promise<AxiosResponse<T>> => {
    const options = { withCredentials: true };
    return this.axiosInst.delete(url, options);
  };
}

export default Api;
