import { KYB_BUSINESS_TYPE } from 'utils/constants';

export enum FORMS {
  GET_STARTED = 'get_started',
  BUSINESS_INFORMATION = 'business',
  CORPORATE_INFORMATION = 'corporate_information',
  BANK_INFORMATION = 'bank_information',
  BUSINESS_OWNERS = 'business_owners',
  LICENSE_INFORMATION = 'licence_information',
  SALES_INFORMATION = 'sales_information',
  CONFIRMATION = 'confirmation',
}

export enum PRODUCT_STAGE {
  PRODUCT_STAGE1 = 'product_stage1',
  PRODUCT_STAGE2 = 'product_stage2',
  PRODUCT_STAGE3 = 'product_stage3',
}

export enum BO_STAGE {
  'LIST_OWNERS',
  'ADD_OWNER',
  'EDIT_OWNER',
  'ADD_ENTITY',
  'EDIT_ENTITY',
}

export enum OWNER_TYPE {
  BUSINESS = 'Business',
  INDIVIDUAL = 'Individual',
}

export const CONFIG: Record<
  FORMS,
  { label: string; to: FORMS; order: number }
> = {
  [FORMS.GET_STARTED]: {
    label: 'Get started',
    to: FORMS.BUSINESS_INFORMATION,
    order: 1,
  },
  [FORMS.BUSINESS_INFORMATION]: {
    label: 'Business information',
    to: FORMS.CORPORATE_INFORMATION,
    order: 2,
  },
  [FORMS.CORPORATE_INFORMATION]: {
    label: 'Corporate information',
    to: FORMS.LICENSE_INFORMATION,
    order: 3,
  },
  [FORMS.LICENSE_INFORMATION]: {
    label: 'License information',
    to: FORMS.BANK_INFORMATION,
    order: 4,
  },
  [FORMS.BANK_INFORMATION]: {
    label: 'Bank information',
    to: FORMS.SALES_INFORMATION,
    order: 5,
  },
  [FORMS.SALES_INFORMATION]: {
    label: 'Sales information',
    to: FORMS.BUSINESS_OWNERS,
    order: 6,
  },
  [FORMS.BUSINESS_OWNERS]: {
    label: 'Owner/Admin information',
    to: FORMS.CONFIRMATION,
    order: 7,
  },
  [FORMS.CONFIRMATION]: {
    label: 'Confirmation',
    to: FORMS.CONFIRMATION,
    order: 8,
  },
};

export enum PRODUCT_EXCLUDE {
  ACCOUNT_DETAILS = 'account',
  BUSINESS_DETAILS = 'business',
  BUSINESS_OWNERS = 'business_owners',
  DOCUMENT_DETAILS = 'document',
  CONFIRMATION = 'submitted',
}

export const SOFTWARE_CONFIG: Record<
  Exclude<FORMS, FORMS.SALES_INFORMATION>,
  { label: string; to: Exclude<FORMS, FORMS.SALES_INFORMATION>; order: number }
> = {
  [FORMS.GET_STARTED]: {
    label: 'Get started',
    to: FORMS.BUSINESS_INFORMATION,
    order: 1,
  },
  [FORMS.BUSINESS_INFORMATION]: {
    label: 'Business information',
    to: FORMS.CORPORATE_INFORMATION,
    order: 2,
  },
  [FORMS.CORPORATE_INFORMATION]: {
    label: 'Corporate information',
    to: FORMS.BANK_INFORMATION,
    order: 3,
  },
  [FORMS.BANK_INFORMATION]: {
    label: 'Bank information',
    to: FORMS.BUSINESS_OWNERS,
    order: 4,
  },

  [FORMS.BUSINESS_OWNERS]: {
    label: 'Owner/Admin information',
    to: FORMS.LICENSE_INFORMATION,
    order: 5,
  },
  [FORMS.LICENSE_INFORMATION]: {
    label: 'Document details',
    to: FORMS.CONFIRMATION,
    order: 6,
  },
  [FORMS.CONFIRMATION]: {
    label: 'Confirmation',
    to: FORMS.CONFIRMATION,
    order: 7,
  },
};

export const getConfigKYB = (type?: string) => {
  if (!type || type === KYB_BUSINESS_TYPE[1].key) {
    return SOFTWARE_CONFIG;
  }
  return CONFIG;
};

export enum AUTO_SAVE_STATES {
  SAVING = 'SAVING',
  SAVED = 'SAVED',
}

export const KYB_VERSION = '4.8';
