import React, { useState } from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import AddBankAccount from 'components/modals/banking/addBankAcc';
import styles from '../styles.module.scss';

const AddBankAccountBanner = () => {
  const [addBankAccount, setAddBankAccount] = useState(false);
  return (
    <>
      <div
        className={classNames(
          'd-flex align-items-center justify-content-between mb-7',
          styles.addBankAcctBanner
        )}
        data-testid="add-bank-account-banner"
      >
        <span className="body-subtext-medium d-flex align-items-center justify-content-center">
          <i className="ph-info-fill icon-lg mr-2"></i>
          <span>Please add a bank account to pay out your USD Balance</span>
        </span>
        <Button
          className="d-flex align-items-center pl-2"
          onClick={() => setAddBankAccount(true)}
        >
          <i className="ph-plus-bold icon-lg mr-1 pl-1 d-flex align-items-center justify-items-center"></i>
          <div className="body-subtext-medium">Add bank account</div>
        </Button>
      </div>
      <AddBankAccount openModal={addBankAccount} setModal={setAddBankAccount} />
    </>
  );
};

export default AddBankAccountBanner;
