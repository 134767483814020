export const FETCH_CUSTOMERS = 'payments/FETCH_CUSTOMERS';
export const FETCH_CUSTOMERS_SUCCESS = 'payments/FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILURE = 'payments/FETCH_CUSTOMERS_FAILURE';

export const FETCH_INDIVIDUAL_CUSTOMER = 'payments/FETCH_INDIVIDUAL_CUSTOMER';
export const FETCH_INDIVIDUAL_CUSTOMER_SUCCESS =
  'payments/FETCH_INDIVIDUAL_CUSTOMER_SUCCESS';
export const FETCH_INDIVIDUAL_CUSTOMER_FAILURE =
  'payments/FETCH_INDIVIDUAL_CUSTOMER_FAILURE';

export const CLEAR_CUSTOMER_EXPORT = 'payments/CLEAR_EXPORT';

export const FETCH_CUSTOMERS_CSV = 'payments/FETCH_CUSTOMERS_CSV';
export const FETCH_CUSTOMERS_CSV_SUCCESS =
  'payments/FETCH_CUSTOMERS_CSV_SUCCESS';
export const FETCH_CUSTOMERS_CSV_FAILURE =
  'payments/FETCH_CUSTOMERS_CSV_FAILURE';

export const DELETE_CUSTOMER = 'organisations/DELETE_CUSTOMER';
export const DELETE_CUSTOMER_SUCCESS = 'organisations/DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAILURE = 'organisations/DELETE_CUSTOMER_FAILURE';

export const REINSTATE_CONSUMER = 'consumer/REINSTATE_CONSUMER';
export const REINSTATE_CONSUMER_SUCCESS = 'consumer/REINSTATE_CONSUMER_SUCCESS';
export const REINSTATE_CONSUMER_FAILURE = 'consumer/REINSTATE_CONSUMER_FAILURE';

export const action = (type: string): IAction => ({
  type,
});

export const actionSuccess = <T>(type: string, payload: T): IAction => ({
  type: `${type}_SUCCESS`,
  payload,
});

export const actionFailure = <T>(type: string, payload: T): IAction => ({
  type: `${type}_FAILURE`,
  payload,
});

export const fetchCustomersCSV = (): IAction => ({
  type: FETCH_CUSTOMERS_CSV,
});

export const fetchCustomersCSVSuccess = <T>(payload: T): IAction => ({
  type: FETCH_CUSTOMERS_CSV_SUCCESS,
  payload,
});

export const fetchCustomersCSVFailure = <T>(payload: T): IAction => ({
  type: FETCH_CUSTOMERS_CSV_FAILURE,
  payload,
});
