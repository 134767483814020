import React from 'react';
import CURRENCY from 'utils/currency';
import { PAYMENTS } from 'state/payment/type';
import styles from '../summary.module.scss';

interface IProps {
  row: PAYMENTS.ITransactionSummaryByPaymentType;
}

const TransactionsAccordion = ({ row }: IProps): JSX.Element => {
  return (
    <>
      <div className={styles.transactionsAccordion}>
        <div>
          <div>Order amount</div>
          <div>{CURRENCY.convertToMainUnit(row?.order_amount) || '$00.00'}</div>
        </div>
        <div>
          <div>Tip amount</div>
          <div>{CURRENCY.convertToMainUnit(row?.tip_amount) || '$00.00'}</div>
        </div>
        <div>
          <div>Processor fee</div>
          <div>
            {CURRENCY.convertToMainUnit(row?.provider_fee_amount) || '$00.00'}
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionsAccordion;
