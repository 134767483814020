import React, { useEffect } from 'react';
import { Card } from 'reactstrap';
import usePayment from 'state/payment/hooks/usePayment';
import useQuery from 'utils/hooks/useQuery';
import { useLocation } from 'react-router-dom';
import PartnerTransactionsList from './list';

const PartnerTransactions = (): JSX.Element => {
  const location = useLocation();
  const { toResetPayment } = usePayment();

  const query = useQuery(location?.search);
  // Filter params loaded into component
  const dates = query.get('dates')?.split(',') || [];
  const dateFrom = dates.length > 0 ? dates[0].replace(' ', '+') : '';
  const dateTo = dates.length > 1 ? dates[1].replace(' ', '+') : '';
  const amountOption = query.get('condition') || 'equal';
  const firstAmount = query.get('amount1') || undefined;
  const secondAmount = query.get('amount2') || undefined;
  const statusArray = query.get('status')?.split(',') || [];
  const sourceArray = query.get('source')?.split(',') || [];
  const storeArray = query.get('store')?.split(',') || [];

  useEffect(() => {
    return () => {
      toResetPayment();
    };
  }, []);

  return (
    <>
      <Card className="card-profile border-0">
        <PartnerTransactionsList
          dateFrom={dateFrom}
          dateTo={dateTo}
          amountOption={amountOption}
          firstAmount={firstAmount}
          secondAmount={secondAmount}
          statusArray={statusArray}
          storeArray={storeArray}
          sourceArray={sourceArray}
          queryParams={location?.search}
        />
      </Card>
    </>
  );
};

export default PartnerTransactions;
