import DownChevronIcon from 'components/icons/downchevron';
import React, { useEffect } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import { ORGANISATION } from 'state/organisation/type';
import useSettings from 'state/settings/hooks/useSettings';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import ErrorMessage from '../../../components/errorMessage';

const PROVIDERS = [{ key: 'treez', label: 'Treez' }];

interface IProps {
  activeOrg: ORGANISATION.IOrg | null;
}
const LogisticsProviderSettings = ({ activeOrg }: IProps): JSX.Element => {
  const { toUpdateLogisticsSettings, settings, toResetLogisticsUpdate } =
    useSettings();
  const { logisticsUpdated } = settings;
  const { toFetchActiveOrg } = useOrganisation();
  const provider = activeOrg?.logistic_provider;

  const { register, handleSubmit, control, watch, errors } = useForm();
  const formValues = watch();

  const onSubmit = (data: { [x: string]: string }) => {
    const { client_id, api_key, logistic_store_identifier } = data;
    if (activeOrg) {
      const request: ORGANISATION.ILogisticsProvider = {
        name: 'treez',
        client_id,
        api_key,
        store_id: activeOrg.brand_settings.store_identifier,
        organization_id: activeOrg.id,
        logistic_store_identifier,
      };
      toUpdateLogisticsSettings(request);
    }
  };

  useEffect(() => {
    if (logisticsUpdated) {
      toFetchActiveOrg();
      toResetLogisticsUpdate();
    }
  }, [logisticsUpdated]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col sm="6" xs="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="client_id">
              Provider
            </label>
            <div>
              <Controller
                name="name"
                control={control}
                render={(props: ControllerRenderProps) => (
                  <UncontrolledDropdown size="md">
                    <DropdownToggle>
                      {formValues.provider?.name || 'Treez'}
                      <DownChevronIcon />
                    </DropdownToggle>
                    <DropdownMenu>
                      {PROVIDERS.map((providerItem) => (
                        <DropdownItem
                          onClick={() => props.onChange(provider)}
                          key={providerItem.key}
                        >
                          {providerItem.label}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm="6" xs="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="client_id">
              Client ID
              <span id="clientidtooltip" className="pl-1">
                <i className="ph-info-fill icon-md text-muted" />
              </span>
              <UncontrolledTooltip placement="right" target="clientidtooltip">
                Enter the Client ID from your logistics provider settings page
              </UncontrolledTooltip>
            </label>
            <Input
              defaultValue={provider?.client_id}
              placeholder="Client ID"
              name="client_id"
              innerRef={register({ required: true })}
            />
            {errors.client_id && (
              <ErrorMessage
                isValidation
                message="Please enter a valid Client ID"
              />
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm="6" xs="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="api_key">
              API Key
              <span id="subjectTooltip" className="pl-1">
                <i className="ph-info-fill icon-md text-muted" />
              </span>
              <UncontrolledTooltip placement="right" target="subjectTooltip">
                Enter the API Key from your logistics provider settings page
              </UncontrolledTooltip>
            </label>

            <Input
              defaultValue={provider?.api_key}
              placeholder="API Key"
              name="api_key"
              innerRef={register({ required: true })}
            />
            {errors.api_key && (
              <ErrorMessage
                isValidation
                message="Please enter a valid API key"
              />
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm="6" xs="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="logistic_store_identifier"
            >
              Dispensary Name
              <span id="dispensaryTooltip" className="pl-1">
                <i className="ph-info-fill icon-md text-muted" />
              </span>
              <UncontrolledTooltip placement="right" target="dispensaryTooltip">
                This would be part of the payments link URL. The dispensary name
                is listed before treez.io in the URL. ie. partner.treez.io
                dispensary name is ‘partner’.
              </UncontrolledTooltip>
            </label>

            <Input
              defaultValue={provider?.logistic_store_identifier}
              placeholder="Short Name"
              name="logistic_store_identifier"
              innerRef={register({ required: true })}
            />
            {errors.api_key && (
              <ErrorMessage
                isValidation
                message="Please enter a valid short identifier"
              />
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <Button
            size="sm"
            color="primary"
            type="submit"
            className="btn btn-primary"
          >
            Save
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default LogisticsProviderSettings;
