import classNames from 'classnames';
import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import styles from '../../tips/tips.module.scss';

interface IProps {
  setSummartType: Dispatch<SetStateAction<string>>;
}

const TipSummaryBy = ({ setSummartType }: IProps): JSX.Element => {
  const [selectedSummaryType, setSelectedSummaryType] = useState(
    'By Employee details'
  );

  const enum summaryType {
    EMPLOYEE = 'employee',
    PAYMENT_TYPE = 'paymentType',
  }
  const [summaryTypeSelected, setSummaryTypeSelected] = useState<summaryType>(
    summaryType.EMPLOYEE
  );

  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle
          className={classNames(
            styles.dropdownToggle,
            'd-inline-flex align-items-center btn-sm'
          )}
        >
          <i
            className={classNames(
              styles.summaryByDropdownHeadingIcon,
              'ph-funnel-simple mr-1 icon-xl'
            )}
          />
          <span className={styles.summaryByDropdownHeadingText}>
            {selectedSummaryType}
          </span>
          <i
            className={classNames(
              styles.summaryByDropdownHeadingIcon,
              'ph-caret-down-light ml-1 icon-lg'
            )}
          ></i>
        </DropdownToggle>
        <DropdownMenu left className={classNames(styles.summaryByDropdownMenu)}>
          <DropdownItem
            onClick={() => {
              setSummartType('employee');
              setSelectedSummaryType('By Employee details');
              setSummaryTypeSelected(summaryType.EMPLOYEE);
            }}
            className={classNames(
              summaryTypeSelected === summaryType.EMPLOYEE
                ? styles.summaryByDropdownHeaderSelected
                : styles.summaryByDropdownHeader
            )}
          >
            <div className={classNames(styles.summaryByDropdownItem)}>
              By Employee details
            </div>
            {summaryTypeSelected === summaryType.EMPLOYEE ? (
              <i
                className={classNames('ph-bold ph-check', styles.selectedIcon)}
              />
            ) : (
              <></>
            )}
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              setSummartType('payment_type');
              setSelectedSummaryType('By Payment type');
              setSummaryTypeSelected(summaryType.PAYMENT_TYPE);
            }}
            className={classNames(
              summaryTypeSelected === summaryType.PAYMENT_TYPE
                ? styles.summaryByDropdownHeaderSelected
                : styles.summaryByDropdownHeader
            )}
          >
            <div className={classNames(styles.summaryByDropdownItem)}>
              By Payment type
            </div>
            {summaryTypeSelected === summaryType.PAYMENT_TYPE ? (
              <i className={classNames('ph-bold ph-check text-muted')} />
            ) : (
              <></>
            )}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

export default TipSummaryBy;
