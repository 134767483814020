import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Alert, Button } from 'reactstrap';
import classNames from 'classnames';
import styles from './styles.module.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import { paymentProviderStatusFormatter } from 'utils/status-formatter';
import usePaymentConfig from 'state/paymentConfig/hooks/usePaymentConfig';
import { PAYMENT_CONFIG } from 'state/paymentConfig/type';
import { PaymentSolutionTab } from '../paymentConfig';

interface IProps {
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<PaymentSolutionTab>>;
  defaultProvider: PAYMENT_CONFIG.IProcessors | undefined;
  setDefaultProvider: Dispatch<
    SetStateAction<PAYMENT_CONFIG.IProcessors | undefined>
  >;
}

export const checkEnabled = (
  provider: PAYMENT_CONFIG.DefaultProcessorData | undefined | null,
  list: PAYMENT_CONFIG.IProcessors[]
) => {
  if (list?.length > 0) {
    return list.some(
      (item) => item.processorName === provider?.processorName && item.isEnabled
    );
  }
  return false;
};
export const getPaymentFilterList = (
  list: PAYMENT_CONFIG.IProcessors[],
  filter: PaymentSolutionTab
) => {
  return list?.length > 0
    ? list?.filter((item) => {
        return item?.paymentMethod === filter && item?.isEnabled;
      })
    : [];
};
const Home = ({
  activeTab,
  setActiveTab,
  defaultProvider,
  setDefaultProvider,
}: IProps): JSX.Element => {
  const { paymentConfig, updatedFilterList } = usePaymentConfig();

  const { paymentSolutions, defaultProcessor } = paymentConfig;

  const [noProviderFlag, setNoProviderFlag] = useState(false);

  const [noApprovedProviderFlag, setNoApprovedProviderFlag] = useState(true);

  const changeTab = (tab: PaymentSolutionTab) => {
    setActiveTab(tab);
  };

  const getDefaultProcessorFilterList = (
    list: PAYMENT_CONFIG.IProcessors[],
    filter: string
  ) => {
    const filteredProcessorList = list?.find(
      (item) => item.processorId === filter && item.isEnabled
    );
    return filteredProcessorList;
  };

  const toggle = (
    tab: PaymentSolutionTab,
    processorId?: string,
    paymentType?: PaymentSolutionTab
  ) => {
    const paymentMethodFilter =
      paymentType !== undefined
        ? getPaymentFilterList(
            paymentSolutions?.processor?.processors !== null
              ? paymentSolutions?.processor?.processors
              : [],
            paymentType
          )
        : [];
    if (processorId) {
      const selectDefault = getDefaultProcessorFilterList(
        paymentMethodFilter,
        processorId
      );
      setDefaultProvider(selectDefault);
    }
    updatedFilterList(paymentMethodFilter);
    changeTab(tab);
  };

  const noApprovedProviderFlagCheck = (
    providerList: PAYMENT_CONFIG.IProcessors[]
  ) => {
    const hasAnyProvider = providerList.some((item) => item?.isEnabled);
    if (!hasAnyProvider) {
      setNoApprovedProviderFlag(true);
    }
  };

  const TABLE_COLUMNS = [
    {
      text: 'Payment Solution',
      sort: true,
      dataField: 'paymentMethod',
      formatter: (cell: string) => {
        return (
          <div className="text-capitalize">{`Integrated ${
            cell !== null ? cell : 'ATM'
          }`}</div>
        );
      },
    },
    {
      text: 'Payment provider',
      sort: true,
      dataField: 'processorName',
      formatter: (cell: string) => {
        return <div className="text-capitalize">{cell}</div>;
      },
    },
    {
      text: 'Status',
      sort: true,
      dataField: 'status',
      formatter: (cell: string, row: PAYMENT_CONFIG.IProcessors) => {
        return paymentProviderStatusFormatter(row.isEnabled);
      },
    },
    {
      text: 'Actions',
      sort: false,
      dataField: 'id',
      formatter: (cell: string, row: PAYMENT_CONFIG.IProcessors) => {
        return (
          <Button
            // disabled={checkStatus(client_id)}
            type="button"
            color="link"
            size="sm"
            disabled={!row.isEnabled}
            onClick={() =>
              toggle(
                row.paymentMethod as PaymentSolutionTab,
                row?.processorId,
                row?.paymentMethod as PaymentSolutionTab
              )
            }
          >
            Configure
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    if (
      paymentSolutions?.processor?.processors &&
      paymentSolutions?.processor?.processors?.length > 0
    ) {
      setNoProviderFlag(false);
    }
    if (paymentSolutions?.processor?.processors) {
      noApprovedProviderFlagCheck(paymentSolutions.processor.processors);
    }
    if (
      !paymentSolutions?.processor?.processors ||
      (paymentSolutions?.processor?.processors &&
        paymentSolutions?.processor?.processors?.length === 0)
    ) {
      setNoProviderFlag(true);
    }
  }, [paymentSolutions?.processor?.processors]);

  return (
    <>
      {noProviderFlag ? (
        <Alert
          color="info"
          isOpen
          className={classNames(styles.alertInfoSupport, 'mt-3')}
        >
          <i className="ph-info-fill icon-xl mr-2"></i>
          <div>
            You are currently not approved for payments. Please contact your
            TreezPay Account Manager or
            <a
              className="ml-1"
              target="_blank"
              href="https://support.treez.io/hc/en-us/requests/new"
            >
              contact support{' '}
            </a>
            in case there are any queries.
          </div>
        </Alert>
      ) : (
        <>
          <span className={styles.heading}>
            Payment solutions and providers
          </span>
          <p className={styles.subheading}>
            Below is an overview of the payment solutions you have opted for and
            their payment providers.
          </p>
          {noApprovedProviderFlag ?? (
            <Alert
              color="dark"
              isOpen
              toggle={() => {}}
              className={classNames(styles.alertInfo, 'mt-3')}
            >
              <i className="ph-info-fill icon-xl mr-2"></i>
              <div>
                You can start configuring the providers only after you have been
                approved.
              </div>
            </Alert>
          )}
          <div className="mt-3">
            {/* <Alert
            color="success"
            isOpen
            toggle={() => {}}
            className={classNames(styles.alertSuccess, 'mt-3')}
          >
            <i className="ph-check-circle-fill text-success icon-xl mr-2"></i>
            <div>
              <span>
                Congratulations! Your payment provider for Integrated ATM has
                been approved.
              </span>
              <p className={classNames(styles.configureButton, 'mt-2')}>
                <span>Configure provider</span>
              </p>
            </div>
          </Alert> */}
            <BootstrapTable
              id="tnx"
              wrapperClasses={styles.tableBorderedTop}
              keyField="_id"
              data={paymentSolutions?.processor?.processors || []}
              columns={TABLE_COLUMNS}
              noDataIndication={'No Provider available'}
              condensed
              bordered={false}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Home;
