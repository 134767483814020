export const FETCH_STORE = 'store/FETCH_STORE';
export const FETCH_STORE_SUCCESS = 'store/FETCH_STORE_SUCCESS';
export const FETCH_STORE_FAILURE = 'store/FETCH_STORE_FAILURE';

export const FETCH_STORES_LIST = 'store/FETCH_STORES_LIST';
export const FETCH_STORES_LIST_SUCCESS = 'store/FETCH_STORES_LIST_SUCCESS';
export const FETCH_STORES_LIST_FAILURE = 'store/FETCH_STORES_LIST_FAILURE';

export const FETCH_ACTIVE_STORES = 'store/FETCH_ACTIVE_STORES';
export const FETCH_ACTIVE_STORES_SUCCESS = 'store/FETCH_ACTIVE_STORES_SUCCESS';
export const FETCH_ACTIVE_STORES_FAILURE = 'store/FETCH_ACTIVE_STORES_FAILURE';

export const UPDATE_STORE = 'store/UPDATE_STORE';
export const UPDATE_STORE_SUCCESS = 'store/UPDATE_STORE_SUCCESS';
export const UPDATE_STORE_FAILURE = 'store/UPDATE_STORE_FAILURE';

export const SET_ACTIVE_STORE = 'store/SET_ACTIVE_STORE';
export const RESET = 'store/RESET';

export const action = (type: string): IAction => ({
  type,
});

export const actionSuccess = <T>(type: string, payload: T): IAction => ({
  type: `${type}_SUCCESS`,
  payload,
});

export const actionFailure = <T>(type: string, payload: T): IAction => ({
  type: `${type}_FAILURE`,
  payload,
});

export const setActiveStore = <T>(payload: T): IAction => ({
  type: SET_ACTIVE_STORE,
  payload,
});

export const reset = (): IAction => ({
  type: RESET,
});
