import React, { useState } from 'react';
import { formatToTz } from 'utils/date-format';
import classNames from 'classnames';
import { PAYMENTS } from 'state/payment/type';
import { Link } from 'react-router-dom';
import routeConfig from 'routes/config';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import styles from './styles.module.scss';

interface IProps {
  info: PAYMENTS.IIndividualTransactionResult;
}
const Summary = ({
  info = {} as PAYMENTS.IIndividualTransactionResult,
}: IProps): JSX.Element => {
  const { organisation } = useOrganisation();
  const { settings, activeOrgId } = organisation;

  return (
    <>
      <div className="mt-2">
        <hr className="my-0" />
        <div
          className={classNames(
            'd-flex align-items-center mb-5 pt-2 mt-1',
            styles.SummaryInfoList
          )}
        >
          <p className={classNames('SettlementSummaryPayment', 'pr-5')}>
            <div className={classNames('SettlementSummaryHeading')}>
              Return Date
            </div>
            <div className={classNames('SettlementHeadingData')}>
              {info.return_date
                ? formatToTz(
                    info.return_date,
                    settings?.timezone,
                    'MMM dd, hh:mm a'
                  )
                : 'NA'}
            </div>
          </p>
          <p className={classNames('SettlementSummaryPayment', 'pl-5', 'pr-5')}>
            <div className={classNames('SettlementSummaryHeading')}>
              Transaction date
            </div>
            <div className={classNames('SettlementHeadingData')}>
              {info.order_create_date
                ? formatToTz(
                    info.order_create_date,
                    settings?.timezone,
                    'MMM dd, hh:mm a'
                  )
                : 'NA'}
            </div>
          </p>
          <p className={classNames('SettlementSummaryPayment', 'pl-5', 'pr-5')}>
            <div className={classNames('SettlementSummaryHeading', 'pr-5')}>
              Customer
            </div>
            <div className={classNames('SettlementHeadingData')}>
              {
                // condition is when consumer_profile_id is deleted
                info.customer_first_name && info.customer_last_name ? (
                  <Link
                    to={`/${activeOrgId}/${routeConfig.CUSTOMERS.layout}/${info.consumer_profile_id}`}
                  >
                    {info.customer_first_name} {info.customer_last_name}
                  </Link>
                ) : (
                  'NA'
                )
              }
            </div>
          </p>
        </div>
      </div>
    </>
  );
};

export default Summary;
