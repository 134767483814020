import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Button } from 'reactstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import routeConfig from 'routes/config';

const NoAccessToStore = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const storeName = state?.storeName;
  const ownerEmail = state?.ownerEmail;

  if (!storeName) {
    navigate(`/${routeConfig.STORES.layout}/${routeConfig.STORES.LIST.path}`);
    return <></>;
  }

  return (
    <div className="mt-9" style={{ width: '512px' }}>
      <h1 className={classNames(styles.listHeading, 'mb-5')}>Stores</h1>
      <div className={classNames(styles.noAccessBanner, 'mb-10')}>
        <i className="ph-warning-circle-fill mr-10 icon-xl"></i>
        <span>
          You do not have access to{' '}
          <span
            style={state?.storeName && { fontWeight: 700, color: '#4D5664' }}
          >
            {storeName || 'this'}
          </span>{' '}
          store on TreezPay.
        </span>
      </div>
      <div className="body-subtext-regular mb-8" style={{ color: '#4D5664' }}>
        Please contact{' '}
        <span style={state?.ownerEmail && { fontWeight: 600 }}>
          {ownerEmail}
        </span>{' '}
        to get access to the store.
      </div>
      <Button
        onClick={() => {
          navigate(
            `/${routeConfig.STORES.layout}/${routeConfig.STORES.LIST.path}`
          );
        }}
        type="button"
        className={styles.treezBtnPrimary}
      >
        Go back
      </Button>
    </div>
  );
};

export default NoAccessToStore;
