import React, { useState, useEffect } from 'react';
import useWebhooks from 'state/webhooks/hooks/useWebhooks';
import Summary from './summary';
import WebhookAttempts from './webhookAttempts';

const Webhook = () => {
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const { webhooks } = useWebhooks();
  const { webhookDetails, error, isLoading } = webhooks;

  useEffect(() => {
    if (isLoading || error?.fetchWebhookDetails) {
      setIsDataAvailable(false);
    } else {
      setIsDataAvailable(true);
    }
  }, [error, isLoading]);

  return isDataAvailable && Object.keys(webhookDetails).length > 0 ? (
    <>
      <Summary info={webhookDetails} />
      <WebhookAttempts
        attempts={webhookDetails.attempts}
        webhookId={webhookDetails.id}
      />
    </>
  ) : (
    <div className="pt-2 pb-2">
      <h5 className="d-flex justify-content-center">No details available</h5>
    </div>
  );
};

export default Webhook;
