import { Button } from 'reactstrap';
import classNames from 'classnames';
import React from 'react';
import styles from './back-button.module.scss';

interface IProps {
  onBack?: () => void;
}

const BackButton = ({ onBack }: IProps): JSX.Element => {
  const routeBack = () => {
    window.history.back();
  };

  return (
    <Button
      data-testid="backButton"
      onClick={onBack || routeBack}
      className={classNames(
        'd-inline-flex btn-text align-items-center m-0 mb-2',
        styles.btn_back
      )}
    >
      <i className="ph-caret-left-bold" />
      Back
    </Button>
  );
};

export default BackButton;
