import { AxiosResponse } from 'axios';
import { apiWithOrg } from 'services/api';
import { useStateValue } from 'state';
import {
  action,
  actionFailure,
  actionSuccess,
  CREATE_WEBHOOK,
  DELETE_WEBHOOK,
  FETCH_WEBHOOKS,
  FETCH_WEBHOOK_DETAILS,
  REVEAL_WEBHOOK_SECRET,
} from '../actions';
import { WEBHOOKS } from '../type';

const useWebhooks = () => {
  const { state, dispatch } = useStateValue();
  const { webhooks } = state;

  const toFetchWebhooks = async (key?: string | null) => {
    dispatch(action(FETCH_WEBHOOKS));
    try {
      const response = await apiWithOrg.get(`/hooks`, { key });
      dispatch(actionSuccess(FETCH_WEBHOOKS, response.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_WEBHOOKS, e));
    }
  };

  const toCreateWebhook = async (request: WEBHOOKS.ICreateWebhooks) => {
    dispatch(action(CREATE_WEBHOOK));
    try {
      const response = await apiWithOrg.post(`/hooks`, request);
      dispatch(actionSuccess(CREATE_WEBHOOK, response.data));
    } catch (e) {
      dispatch(actionFailure(CREATE_WEBHOOK, e));
    }
  };

  const toFetchWebhookDetails = async (
    webhookId: string,
    key?: string | null
  ) => {
    dispatch(action(FETCH_WEBHOOK_DETAILS));
    try {
      const [webhook, attempts] = await Promise.all([
        apiWithOrg.get(`/hooks/${webhookId}`),
        apiWithOrg.get(`/hooks/${webhookId}/deliveries`, {
          key,
        }),
      ]);
      const payload = {
        webhook: webhook.data,
        attempts: attempts.data,
      };
      dispatch(actionSuccess(FETCH_WEBHOOK_DETAILS, payload));
    } catch (e) {
      dispatch(actionFailure(FETCH_WEBHOOK_DETAILS, e));
    }
  };

  const toDeleteWebhook = async (webhookId: string) => {
    dispatch(action(DELETE_WEBHOOK));
    try {
      const response: AxiosResponse = await apiWithOrg.remove(
        `/hooks/${webhookId}`
      );
      dispatch(actionSuccess(DELETE_WEBHOOK, response.data));
      toFetchWebhooks();
    } catch (e) {
      dispatch(actionFailure(DELETE_WEBHOOK, e));
    }
  };

  const toRevealWebhookSecret = async (webhookId: string) => {
    dispatch(action(REVEAL_WEBHOOK_SECRET));
    try {
      const response = await apiWithOrg.post(`/hooks/${webhookId}/reveal`);
      dispatch(actionSuccess(REVEAL_WEBHOOK_SECRET, response.data));
    } catch (e) {
      dispatch(actionFailure(REVEAL_WEBHOOK_SECRET, e));
    }
  };

  return {
    webhooks,
    toFetchWebhooks,
    toCreateWebhook,
    toFetchWebhookDetails,
    toDeleteWebhook,
    toRevealWebhookSecret,
  };
};
export default useWebhooks;
