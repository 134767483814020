import React from 'react';
import classNames from 'classnames';
import BankingBalanceAccordion from 'components/summary-tab/accordions/banking-balance';
import BankingIncomingAccordion from 'components/summary-tab/accordions/banking-incoming';
import BankingOutgoingAccordion from 'components/summary-tab/accordions/banking-outgoing';
import { EFilterConfigType, IFilterConfig } from 'components/filter/types';
import { BANKING } from 'state/banking/type';
import { SUMMARY } from 'state/summary/type';
import CURRENCY from 'utils/currency';
import { bankingFilterTypes } from 'utils/constants';
import { toIsoStringWithTz } from 'utils/date-format';
import styles from '../styles.module.scss';

export const summaryProps = {
  [BANKING.BankingTxnType.ALL]: {
    expandRowFormatter: (
      row: SUMMARY.ISummaryData<BANKING.IBankSummaryBreakdown>
    ) => (
      <BankingBalanceAccordion
        row={row.value as unknown as BANKING.IBalanceSummaryBreakdown}
      />
    ),
    rowHeading: 'Closing balance',
    rowHeaderValue: 'value.closing_balance',
    description: () => (
      <div className={classNames('body-subtext', styles.summaryDescription)}>
        This summary gives a high level of
        <strong className="body-subtext-semibold ml-1 mr-1">
          Closing balances
        </strong>
        for selected date range. It further gives a more detailed breakdown of
        opening balance, total incoming and total outgoing.
      </div>
    ),
  },
  [BANKING.BankingTxnType.INCOMING]: {
    expandRowFormatter: (
      row: SUMMARY.ISummaryData<BANKING.IBankSummaryBreakdown>
    ) => (
      <BankingIncomingAccordion
        row={row.value as unknown as BANKING.IIncomingSummaryBreakdown}
      />
    ),
    rowHeading: 'Total income',
    rowHeaderValue: 'value.gross_amount',
    description: () => (
      <div className={classNames('body-subtext', styles.summaryDescription)}>
        This summary gives a high level of total funds
        <strong className="body-subtext-semibold ml-1 mr-1">coming into</strong>
        the Swifter account. It gives further details like order amount, tip
        amount and convenience fee.
      </div>
    ),
  },
  [BANKING.BankingTxnType.OUTGOING]: {
    expandRowFormatter: (
      row: SUMMARY.ISummaryData<BANKING.IBankSummaryBreakdown>
    ) => (
      <BankingOutgoingAccordion
        row={row.value as unknown as BANKING.IOutgoingSummaryBreakdown}
      />
    ),
    rowHeading: 'Total outgoing',
    rowHeaderValue: 'value.total_outgoing',
    description: () => (
      <div className={classNames('body-subtext', styles.summaryDescription)}>
        This summary gives a high level of total funds
        <strong className="body-subtext-semibold ml-1 mr-1">going out</strong>
        of the Swifter account. It gives further platform fee, refunds, tax and
        payouts.
      </div>
    ),
  },
};

export const getBankingTabs = (stats: BANKING.IBankingStats | null) => [
  {
    id: BANKING.BankingTxnType.ALL,
    title: 'Total balance',
    tooltip: 'This is the total amount of funds currently in your Swifter account.',
    value: CURRENCY.getCompactNotation(stats?.total_balance),
  },
  {
    id: BANKING.BankingTxnType.INCOMING,
    title: 'Total incoming',
    tooltip:
      'This is the total funds coming into your Swifter account.',
    value: CURRENCY.getCompactNotation(stats?.total_incoming),
  },
  {
    id: BANKING.BankingTxnType.OUTGOING,
    title: 'Total outgoing',
    tooltip:
      'This is the total funds going out of your Swifter account.',
    value: CURRENCY.getCompactNotation(stats?.total_outgoing),
  },
];

export const fromDate = toIsoStringWithTz(
  new Date(
    new Date(new Date().setDate(new Date().getDate() - 30)).setHours(0, 0, 0, 0)
  )
);
export const todayDate = toIsoStringWithTz(
  new Date(new Date().setDate(new Date().getDate()))
);

const dateValues: string[] = [];

dateValues[0] = fromDate;
dateValues[1] = todayDate;

export const getBankingFilterConfig = ({
  amount,
  type,
  activeTab,
}: {
  amount: string[];
  type: string[];
  activeTab: string;
}) => {
  let filters: IFilterConfig[] = [
    {
      key: 'amount',
      label: 'Amount',
      type: EFilterConfigType.number,
      data: {
        values: amount,
      },
      selected: false,
    },
    {
      key: 'dates',
      label: 'Dates',
      type: EFilterConfigType.date,
      data: {
        values: dateValues,
      },
      selected: false,
    },
  ];

  switch (activeTab) {
    case BANKING.BankingTxnType.ALL:
      filters = filters.concat([
        {
          key: 'transaction_type',
          label: 'Type',
          type: EFilterConfigType.list,
          data: {
            options: bankingFilterTypes.balance,
            values: type,
          },
          selected: false,
        },
      ]);
      break;

    case BANKING.BankingTxnType.INCOMING:
      filters = filters.concat([
        {
          key: 'transaction_type',
          label: 'Type',
          type: EFilterConfigType.list,
          data: {
            options: bankingFilterTypes.incoming,
            values: type,
          },
          selected: false,
        },
      ]);
      break;

    case BANKING.BankingTxnType.OUTGOING:
      filters = filters.concat([
        {
          key: 'transaction_type',
          label: 'Type',
          type: EFilterConfigType.list,
          data: {
            options: bankingFilterTypes.outgoing,
            values: type,
          },
          selected: false,
        },
      ] as IFilterConfig[]);
      break;
    default:
      break;
  }

  return filters;
};
