import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import styles from './container.module.scss';

export enum ToastType {
  success = 'success',
  warning = 'warning',
  error = 'error',
}

interface IProps {
  showToast: boolean;
  onClose: (arg: boolean) => void;
  message: string;
  details?: JSX.Element;
  type?: ToastType;
  showCross?: boolean;
  id?: string;
  className?: string;
}

const ToastContainer = ({
  showToast,
  onClose,
  type = ToastType.success,
  message,
  details,
  showCross = true,
  id = '',
  className = '',
}: IProps): JSX.Element => {
  useEffect(() => {
    if (showToast) {
      const timeout = setTimeout(() => {
        onClose(false);
        clearTimeout(timeout);
      }, 8000);
    }
  }, [showToast]);

  const getIcon = () => {
    return type === ToastType.success
      ? 'ph-check-circle-fill'
      : type === ToastType.error
      ? 'ph-x-circle-fill'
      : 'ph-warning-circle-fill';
  };
  return (
    <>
      <Toast
        className={classNames(styles.toast, `${styles[type]}`, className)}
        isOpen={showToast}
        data-testid={`${`${id}-`}toast-${type}`}
      >
        <ToastHeader
          className="mt-3 d-flex align-items-center"
          icon={
            <i
              className={classNames(
                `${styles[`${type}Icon`]}`,
                type === ToastType.success,
                getIcon(),
                'ph-check-circle-fill ml-2'
              )}
            ></i>
          }
          close={
            showCross ? (
              <i
                className={classNames(
                  styles.crossIcon,
                  'ph-x ml-6 align-middle mr-2'
                )}
                onClick={() => onClose(false)}
              ></i>
            ) : (
              <i className="ml-4 align-middle"></i>
            )
          }
        >
          <small
            className={classNames(
              styles.toastText,
              details ? styles.headingFontWeight : styles.textFontWeight
            )}
          >
            {message}
          </small>
        </ToastHeader>
        <ToastBody
          className={classNames(
            'mb-3 d-flex align-items-center',
            styles.toastBody
          )}
        >
          {details}
        </ToastBody>
      </Toast>
    </>
  );
};
export default ToastContainer;
