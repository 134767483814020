import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Layout from './components/layout';
import usePaymentConfig from 'state/paymentConfig/hooks/usePaymentConfig';
import { PAYMENT_CONFIG } from 'state/paymentConfig/type';
import DeviceConfig from './components/deviceConfig';
import DeviceStatus from './components/modals/deviceStatus';
import { PaymentSolutionTab } from '../paymentConfig';
import { initialDeviceConfig } from './atm';

interface IProps {
  defaultProvider: PAYMENT_CONFIG.IProcessors | undefined;
  setDefaultProvider: Dispatch<
    SetStateAction<PAYMENT_CONFIG.IProcessors | undefined>
  >;
  activeTab: PaymentSolutionTab;
}
const IntegratedDebit = ({
  defaultProvider,
  setDefaultProvider,
  activeTab,
}: IProps): JSX.Element => {
  const {
    paymentConfig,
    setGlobalCardReaderDefault,
  } = usePaymentConfig();

  const [deviceConfig, setDeviceConfig] = useState(initialDeviceConfig);

  const { deviceStatus } = paymentConfig;

  const [deviceStatusType, setDeviceStatusType] = useState(false);
  const [showDeviceStatus, setShowDeviceStatus] = useState(false);

  useEffect(() => {
    if (activeTab === PaymentSolutionTab.DEBIT) {
      if (deviceStatus?.isOnline === true) {
        setDeviceStatusType(true);
        setShowDeviceStatus(true);
      } else if (deviceStatus?.isOnline === false) {
        setDeviceStatusType(false);
        setShowDeviceStatus(true);
      } else {
        setShowDeviceStatus(false);
      }
    }
  }, [deviceStatus]);
  return (
    <>
      <Layout
        defaultProvider={defaultProvider}
        setDefaultProvider={setDefaultProvider}
        activeTab={activeTab}
        localTab={PaymentSolutionTab.DEBIT}
      />
      <DeviceConfig
        defaultProvider={defaultProvider}        
        activeTab={activeTab}
        localTab={PaymentSolutionTab.DEBIT}
        deviceConfig={deviceConfig}
        setDeviceConfig={setDeviceConfig}
      />
      {showDeviceStatus && (
        <DeviceStatus
          showModal={showDeviceStatus}
          setShowModal={setShowDeviceStatus}
          status={deviceStatusType}
        />
      )}
    </>
  );
};

export default IntegratedDebit;
