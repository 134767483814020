import classNames from 'classnames';
import React from 'react';
import { Card } from 'reactstrap';
import styles from '../customers.module.scss';

/* Customer Details - Not found page */
const CustomerNotFound = (): JSX.Element => {
  return (
    <>
      <Card className={classNames('border-0', styles.container)}>
        <div className={classNames('text-center', styles.content)}>
          <h2>Customer not found</h2>
          <p className="pt-3 text-regular">
            Please verify the customer information.
          </p>
          <p className="text-regular">
            Contact us at{' '}
            <a
              className={classNames(styles.link)}
              href="mailto:hello@swifterhq.com"
            >
              hello@swifterhq.com
            </a>
          </p>
        </div>
      </Card>
    </>
  );
};

export default CustomerNotFound;
