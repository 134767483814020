import { api } from 'services/api';
import accessLocalStorage from 'services/localStorage';
import { AUTH } from 'state/auth/type';

// fetch id from backend
export const toFetchTenant = async (): Promise<AUTH.IUser> => {
  // calling the backend API for getting the tenant Id
  const response = await api.get<AUTH.IUser>('/profile');
  const memberships = response?.data?.roles;
  if (memberships && memberships.length > 0) {
    // setting the value in the local storage
    accessLocalStorage.setTenantId(memberships[0]?.tenant_id);
  }
  return response.data;
};
