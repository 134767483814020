import {
  FETCH_EVENTS,
  FETCH_EVENTS_FAILURE,
  FETCH_EVENTS_SESSION,
  FETCH_EVENTS_SESSION_FAILURE,
  FETCH_EVENTS_SESSION_SUCCESS,
  FETCH_EVENTS_SUCCESS,
  FETCH_INDIVIDUAL_EVENTS,
  FETCH_INDIVIDUAL_EVENTS_FAILURE,
  FETCH_INDIVIDUAL_EVENTS_SUCCESS,
} from './actions';
import { EVENTS } from './type';

export const INITIAL_STATE: EVENTS.EventsReducer = {
  isLoading: false,
  sessionEvents: {} as EVENTS.ISessionEvents,
  list: {} as EVENTS.IEvents,
  individualEvent: {} as EVENTS.IEventsDetailsResult,
  error: {} as EVENTS.Error,
};

const eventsReducer = (
  action: IAction,
  state = INITIAL_STATE
): EVENTS.EventsReducer => {
  switch (action.type) {
    case FETCH_EVENTS:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: {},
      };
    case FETCH_EVENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { fetchEvents: action.payload.message },
      };

    case FETCH_INDIVIDUAL_EVENTS:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case FETCH_INDIVIDUAL_EVENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        individualEvent: action.payload,
        error: {},
      };
    case FETCH_INDIVIDUAL_EVENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { individualEvents: action.payload.message },
      };

    case FETCH_EVENTS_SESSION:
      return {
        ...state,
        isLoading: true,
        sessionEvents: {} as EVENTS.ISessionEvents,
        error: {},
      };
    case FETCH_EVENTS_SESSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: {},
        sessionEvents: action.payload,
      };
    case FETCH_EVENTS_SESSION_FAILURE:
      return {
        ...state,
        isLoading: false,
        sessionEvents: {} as EVENTS.ISessionEvents,
        error: { fetchSessionEvents: action.payload.message },
      };

    default:
      return state;
  }
};
export default eventsReducer;
