import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classNames from 'classnames';
import useStats from 'state/stats/hooks/useStats';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import styles from '../dashboard.module.scss';
import { TABS, CUSTOMER_INFO_TABS, getColumnConfig, sort } from './config';

interface IProps {
  dates: {
    from: string;
    to: string;
  };
}

const CustomerInfo = ({ dates }: IProps) => {
  const {
    stats: {
      customerInfo: { topOrders, freqOrders, freqReturns },
    },
    toFetchCustomerInfo,
  } = useStats();
  const [activeTab, setActiveTab] = useState(CUSTOMER_INFO_TABS[0].label);
  const { organisation } = useOrganisation();
  const { settings } = organisation;

  useEffect(() => {
    if (dates.from && dates.to) {
      toFetchCustomerInfo({
        start_date: dates.from,
        end_date: dates.to,
        limit: 5,
      });
    }
  }, [dates.from, dates.to]);

  return (
    <div id="dashboard-customer-info">
      <h2 className={classNames(styles.customerInfoHeading, 'mb-3')}>
        ACH Customers information
      </h2>
      <Nav tabs className="mb-3">
        {CUSTOMER_INFO_TABS.map((item) => {
          return (
            <>
              <NavItem>
                <NavLink
                  className={classNames({
                    active: item?.label === activeTab,
                  })}
                  onClick={() => {
                    setActiveTab(item?.label);
                  }}
                >
                  {item?.label}
                </NavLink>
              </NavItem>
            </>
          );
        })}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={TABS.HIGHEST_ORDER_VALUE}>
          <BootstrapTable
            wrapperClasses="table-responsive"
            keyField="_id"
            data={topOrders?.items || []}
            columns={getColumnConfig(
              TABS.HIGHEST_ORDER_VALUE,
              settings?.timezone
            )}
            defaultSorted={sort.topOrders}
            condensed
            bordered={false}
            noDataIndication={
              <p className="text-center text-regular">No results found</p>
            }
          />
        </TabPane>
        <TabPane tabId={TABS.FREQUENT_ORDERS}>
          <BootstrapTable
            wrapperClasses="table-responsive"
            keyField="_id"
            data={freqOrders?.items || []}
            columns={getColumnConfig(TABS.FREQUENT_ORDERS, settings?.timezone)}
            defaultSorted={sort.freqOrders}
            condensed
            bordered={false}
            noDataIndication={
              <p className="text-center text-regular">No results found</p>
            }
          />
        </TabPane>
        <TabPane tabId={TABS.FREQUENT_RETURNS}>
          <BootstrapTable
            wrapperClasses="table-responsive"
            keyField="_id"
            data={freqReturns?.items || []}
            columns={getColumnConfig(TABS.FREQUENT_RETURNS, settings?.timezone)}
            defaultSorted={sort.freqReturns}
            condensed
            bordered={false}
            noDataIndication={
              <p className="text-center text-regular">No results found</p>
            }
          />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default CustomerInfo;
