import { ACTIVATION } from 'state/kybActivation/type';

export enum piiTypes {
  ssn = 'SSN',
  driving_license = 'driving license',
  bank_account_number = 'bank_account_number',
}
export const removeDash = (value: string) => {
  let formattedStr = value;
  if (value.includes('-')) {
    formattedStr = value.replaceAll('-', '');
  }
  return formattedStr;
};

export const formatSSN = (value: string) => {
  return value
    .replace(/[^\d*]/g, '')
    .replace(
      /^(.{1,3})(.{1,2})?(.{1,4})?.*$/,
      (_, x, y, z) => x + (y ? `-${y}` : '') + (z ? `-${z}` : '')
    );
};

export const formatEIN = (value: string | number) => {
  if (value) {
    const val = value.toString() as string;
    return val
      .replace(/[^\d*]/g, '')
      .replace(/^(.{1,2})(.{1,7})?.*$/, (_, x, y) => x + (y ? `-${y}` : ''));
  }
  return '';
};

const prefillData = (
  type: piiTypes,
  piiData?:
    | string
    | {
        encrypted: string;
        last_four: string;
      }
) => {
  if (piiData) {
    if (typeof piiData === 'string') {
      return piiData;
    }

    const maskedData = `*****${piiData?.last_four}`;
    if (type === piiTypes.ssn) {
      return formatSSN(maskedData);
    }
    return maskedData;
  }
  return '';
};

export const maskedData = (shortened_id: string | undefined) => {
  if (shortened_id) {
    return `**** ${shortened_id}`;
  }
  return undefined;
};

export const extractDigitValues = (maskedId: string | undefined) => {
  if (maskedId) {
    return maskedId?.replace(/[^0-9]/g, '');
  }
  return undefined;
};

export const hasExistingPiiValue = (value: string) => {
  return value.includes('*');
};
export default prefillData;
