import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip } from 'reactstrap';
import styles from './style.module.scss';

interface IProps {
  id: string;
  className?: string;
}

const CopyIDToClipboard = ({ id, className }: IProps): JSX.Element => {
  const [copied, setCopied] = useState(false);
  const ref = useRef(null);
  const setCopiedWithTooltip = (state: boolean) => {
    setCopied(state);
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  return (
    <div
      className={classNames(
        styles.container,
        'd-flex align-items-center',
        className
      )}
    >
      <div className="pb-1 pt-1">{id}</div>
      <span
        data-testid="clipboard"
        ref={ref}
        onClick={() => {
          setCopiedWithTooltip(true);
        }}
        className="pl-1"
        aria-describedby={`${id}-tooltipCopy`}
        aria-hidden
        role="tooltip"
      >
        <CopyToClipboard text={id || 'NA'}>
          <i
            className={classNames(
              styles.copyBtn,
              'ph-copy-simple-light icon-lg align-middle'
            )}
          />
        </CopyToClipboard>
      </span>
      {copied && (
        <Tooltip placement="bottom" isOpen target={ref}>
          Copied!
        </Tooltip>
      )}
    </div>
  );
};

export default CopyIDToClipboard;
