import React, { useEffect } from 'react';
import { CardTab, CardTabNav } from 'components/card-tab';
import CURRENCY from 'utils/currency';
import useStats from 'state/stats/hooks/useStats';
import { getPercentChange } from 'utils/dashboard';
import { STATS } from 'state/stats/type';
import { useSearchParams } from 'react-router-dom';

interface IProps {
  currentDates: {
    from: string;
    to: string;
  };
  prevDates: {
    from: string;
    to: string;
  };
}

const Cards = ({ currentDates, prevDates }: IProps): JSX.Element => {
  const {
    stats: {
      activeSummaryTab,
      summaryCard: { current, previous },
    },
    toFetchSummaryCard,
    toSetSummaryTab,
  } = useStats();
  const [searchParams] = useSearchParams();
  const method = searchParams.get('method') || '';
  const aovValue = current?.gross_amount / current?.payments || 0;
  const aovPrevValue = previous?.gross_amount / previous?.payments || 0;

  useEffect(() => {
    if (currentDates.from && currentDates.to) {
      toFetchSummaryCard({
        start_date: currentDates.from,
        end_date: currentDates.to,
        prev_start_date: prevDates.from,
        prev_end_date: prevDates.to,
        payment_type: method != STATS.IPaymentType.ALL ? method : undefined,
      });
    }
  }, [currentDates.from, currentDates.to, method]);

  const getSummaryCards = () => [
    {
      id: STATS.graphType.gross_amount,
      title: 'Gross Sales',
      value: CURRENCY.getCompactNotation(current?.gross_amount),
      percentChange: getPercentChange(
        current.gross_amount,
        previous.gross_amount
      ),
      tooltip: 'Total sales before deducting any fees, refunds or returns',
    },
    {
      id: STATS.graphType.payments,
      title: 'Total Transactions',
      value: current?.payments || 0,
      percentChange: getPercentChange(current.payments, previous.payments),
      tooltip:
        'Total payments successfully processed during a specific timeframe',
    },
    {
      id: STATS.graphType.aov,
      title: 'AOV',
      value: CURRENCY.getCompactNotation(aovValue || 0),
      percentChange: getPercentChange(aovValue, aovPrevValue),
      tooltip:
        'AOV (Average Order Value) measures the average dollar value per ticket when compared to the total dollar value of the tickets within a time period',
    },
  ];

  return (
    <CardTabNav className="mb-3">
      {current &&
        previous &&
        getSummaryCards().map((item) => {
          return (
            <CardTab
              id={item.id}
              title={item.title}
              tooltip={item.tooltip}
              value={item.value}
              onClick={() => toSetSummaryTab(item.id)}
              isActive={item?.id === activeSummaryTab}
              percentChange={item.percentChange}
            />
          );
        })}
    </CardTabNav>
  );
};
export default Cards;
