import React, { Dispatch, SetStateAction, useState } from 'react';
import Select, {
  components,
  MenuListProps,
  GroupBase,
  SingleValueProps,
  ClearIndicatorProps,
} from 'react-select';
import classNames from 'classnames';
import styles from './select-dropdown.module.scss';

export interface DropdownConfig<T> {
  options: any | Record<string, unknown>;
  selectedItemName: string | undefined;
  isSelected: boolean;
  selectedItemData: T | undefined;
  addNewItem: boolean;
  updateItem: boolean;
}
interface IProps<T> {
  placeHolder: string;
  isSearchable?: boolean;
  isClearable?: boolean;
  buttonText: string;
  dropdownConfig: DropdownConfig<T>;
  setDropdownConfig: Dispatch<SetStateAction<DropdownConfig<T>>>;
  onSelect: (option: T | null) => void;
  getLabel: (option: T) => string;
  getOptionValue: (option: T) => string;
  addScreen: () => void;
  multiLineOption?: boolean;
  dropdownValue?: (obj: any) => JSX.Element;
  renderValue?: (obj: any) => JSX.Element;
}

const SelectDropdown = <T,>({
  placeHolder,
  isSearchable = true,
  isClearable = true,
  buttonText,
  dropdownConfig,
  setDropdownConfig,
  onSelect,
  getLabel,
  getOptionValue,
  addScreen,
  dropdownValue,
  renderValue,
  multiLineOption = false,
}: IProps<T>): JSX.Element => {
  const SelectMenuButton = (props: MenuListProps<T, false, GroupBase<T>>) => {
    return (
      <components.MenuList {...props} className="w-100 ">
        {props.children}
        <hr className="m-0 mt-2 p-0" />
        <span
          className={classNames(
            styles.create_vendor_btn,
            'align-items-center d-flex'
          )}
          onClick={addScreen}
        >
          <i className={classNames(styles.icon, 'ph ph-bold ph-plus')}></i>
          {buttonText}
        </span>
      </components.MenuList>
    );
  };

  const CustomClearIndicator = (
    props: ClearIndicatorProps<T, false, GroupBase<T>>
  ) => {
    return (
      <components.ClearIndicator {...props}>
        <div>
          <i className="ph-x-bold icon-lg d-flex text-muted"></i>
        </div>
      </components.ClearIndicator>
    );
  };

  const ValueOptionLabel = (
    props: SingleValueProps<T, false, GroupBase<T>>
  ) => (
    <components.SingleValue {...props}>
      <span>{renderValue ? renderValue(props) : props.children}</span>
    </components.SingleValue>
  );

  return (
    <>
      <Select
        className={classNames(styles.select_dropdown)}
        placeholder={placeHolder}
        value={dropdownConfig.selectedItemData}
        // isOptionSelected={() => !dropdownConfig.selectedItemData}
        options={dropdownConfig.options}
        onChange={onSelect}
        components={
          multiLineOption
            ? {
                MenuList: SelectMenuButton,
                Option: dropdownValue,
                SingleValue: ValueOptionLabel,
                ClearIndicator: CustomClearIndicator,
                DropdownIndicator: () => (
                  <div>
                    <i className="ph-caret-down-bold icon-lg d-flex text-muted mx-2"></i>
                  </div>
                ),
              }
            : {
                MenuList: SelectMenuButton,
                ClearIndicator: CustomClearIndicator,
                DropdownIndicator: () => (
                  <div>
                    <i className="ph-caret-down-bold icon-lg d-flex text-muted mx-2"></i>
                  </div>
                ),
              }
        }
        defaultInputValue={dropdownConfig.selectedItemName}
        isClearable={isClearable}
        isSearchable={isSearchable}
        styles={{
          indicatorSeparator: () => ({ display: 'none' }),
          option: (base) => ({
            ...base,
            marginLeft: '8px',
            color: '#333D4E',
            width: '562px',
            height: '36px',
            borderRadius: '4px',
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#F2F6FE',
            primary: '#F2F6FE',
            primary50: '#F2F6FE',
            neutral20: '#CCCFD3',
          },
        })}
        getOptionLabel={getLabel}
        getOptionValue={getOptionValue}
      />
    </>
  );
};

export default SelectDropdown;
