/**
 * Downloads the given file
 * @param {string} filePath - URL file path
 * @param {string} filename - name of downloaded file
 * @param {string} extension - valid file extension
 */
export const downloadFile = (
  filePath: string,
  filename: string,
  extension: string
) => {
  const link = document.createElement('a');
  link.href = filePath;
  link.download = `${filename}.${extension}`;

  link.click();
  link.parentNode?.removeChild(link);
};

/**
 * A wrapper to download QR code as a png file
 * @param {HTMLCanvasElement} canvas - HTMLCanvasElement - used to generate a QR code image.
 */
export const downloadQR = (canvas: HTMLCanvasElement) => {
  const pngUrl = canvas?.toDataURL('image/png');
  pngUrl?.replace('image/png', 'image/octet-stream');

  downloadFile(pngUrl, 'QRCode', 'png');
};
