import classNames from 'classnames';
import { IFilterConfig, IFilterConfigNumber } from 'components/filter/types';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Input } from 'reactstrap';
import styles from './export.module.scss';

interface IProps {
  item: IFilterConfig;
  setValue: (arg1: string, arg2: number, arg3: string) => void;
  setSelected: (arg1: string, arg2: boolean) => void;
  setShowAmountError: Dispatch<SetStateAction<boolean>>;
  amountError: boolean;
  setSubmitExport: Dispatch<SetStateAction<boolean>>;
  submitExport: boolean;
}

const NumberExport = ({
  item,
  setValue,
  setSelected,
  setShowAmountError,
  amountError,
  setSubmitExport,
  submitExport,
}: IProps): JSX.Element => {
  const { key, label, data, selected } = item;
  const { values } = data as IFilterConfigNumber;
  const condition = values[0];
  const firstNumber = values[1];
  const secondNumber = values[2];
  const subLabel = 'Filter by amount (Gross)';

  useEffect(() => {
    if (selected && submitExport) {
      if (condition !== 'between' && !values[1]) {
        setShowAmountError(true);
        setSubmitExport(false);
      } else if (condition === 'between' && (!values[1] || !values[2])) {
        setShowAmountError(true);
        setSubmitExport(false);
      } else {
        setShowAmountError(false);
        setSubmitExport(false);
      }
    }
  }, [values[1], values[2]]);

  const setCondition = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== 'between') {
      setValue(key, 0, e.target.value);
      setValue(key, 2, '');
    } else {
      setValue(key, 0, 'between');
    }
  };
  return (
    <>
      <div className={classNames(styles.label)}>{label}</div>
      <div className={classNames(styles.checkboxContainer, styles.container)}>
        <Input
          type="checkbox"
          className={classNames(styles.checkbox)}
          checked={selected}
          onChange={(e) => {
            setSelected(key, e.currentTarget.checked);
          }}
          id={label}
          data-testid="amount-check"
        />
        <label
          htmlFor={label}
          className={classNames('body-subtext-regular', styles.checkbox_label)}
        >
          {subLabel}
        </label>
      </div>
      {selected && (
        <div className="mb-2">
          {/* <UncontrolledButtonDropdown>
            <DropdownToggle className={styles.dropdown}>
              {condition || 'equal'}
              <DownChevronIcon />
            </DropdownToggle>
            <DropdownMenu className={classNames('dropdown-full')}>
              <DropdownItem
                onClick={() => {
                  setValue(key, 0, 'equal');
                  setValue(key, 2, '');
                }}
              >
                equal
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setValue(key, 0, 'between');
                }}
              >
                between
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setValue(key, 0, 'greater than');
                  setValue(key, 2, '');
                }}
              >
                greater than
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setValue(key, 0, 'less than');
                  setValue(key, 2, '');
                }}
              >
                less than
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown> */}

          <select
            data-testid="select-box"
            value={condition}
            className={styles.dropdown}
            onChange={(e) => setCondition(e)}
          >
            <option value="equal">equal</option>
            <option value="between">between</option>
            <option value="greater than">greater than</option>
            <option value="less than">less than</option>
          </select>

          <div className={classNames(styles.inputContainer)}>
            <Input
              data-testid="amount-field"
              type="number"
              placeholder="$0.00"
              className={
                amountError && !values[1] ? styles.errorInput : styles.input
              }
              defaultValue={firstNumber}
              onChange={(e) => {
                setValue(key, 0, values[0]);
                setValue(key, 1, e.target.value);
              }}
            />

            {condition === 'between' && (
              <>
                <span className="d-flex px-2">and</span>
                <Input
                  data-testid="second"
                  type="number"
                  placeholder="$0.00"
                  className={
                    amountError && !values[2] ? styles.errorInput : styles.input
                  }
                  defaultValue={secondNumber}
                  onChange={(e) => {
                    setValue(key, 0, values[0]);
                    setValue(key, 2, e.target.value);
                  }}
                />
              </>
            )}
          </div>
          {amountError && (
            <>
              <br />
              <p
                className="mt-1 mb-3 text-sm d-flex text-danger"
                data-testid="amount-error"
              >
                {' '}
                <span id={`settle-tooltip`} className={classNames('pl-1')}>
                  <i
                    className={classNames(
                      'ph-info-fill icon-md pr-2 dangerIcon'
                    )}
                  />
                </span>
                Please provide the amount to filter your results or uncheck the
                amount box
              </p>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default NumberExport;
