import SuccessIcon from 'assets/img/icons/success';
import classNames from 'classnames';
import React, { Dispatch, SetStateAction } from 'react';
import { Button } from 'reactstrap';
import useBanking from 'state/banking/hooks/useBanking';
import ModalContainer from '../container';

import styles from './styles.module.scss';

interface IProps {
  openModal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
}
const SuccessAddBank = (props: IProps): JSX.Element => {
  const { openModal, setModal } = props;
  const { clearAddBankAccountError } = useBanking();

  const toggle = () => {
    clearAddBankAccountError();
    setModal(false);
  };
  return (
    <div>
      <ModalContainer
        testId="modal-addBank"
        isOpen={openModal}
        onClose={toggle}
        header={false}
        isFullHeight={false}
        footer={
          <div>
            <Button data-testid="close" onClick={toggle} color="primary">
              Close
            </Button>
          </div>
        }
      >
        <>
          <div className={classNames(styles.confirmCloseModal)}>
            <SuccessIcon />
            <div className="ml-3">
              <h1 className="mb-2 body-semibold">Bank account added!</h1>
              <p className="body-subtext">
                Your bank account has been added successfully. Funds from your{' '}
                <span className="body-semibold">total available balance </span>
                will be transferred to this bank account when you initate a
                payout.
              </p>
            </div>
          </div>
        </>
      </ModalContainer>
    </div>
  );
};

export default SuccessAddBank;
