import React, { Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';
import { Button, Nav } from 'reactstrap';
import { CREATE_PAYOUTS, PAYOUTS_NAV_CONFIG, IActiveState } from './payout';
import styles from '../styles.module.scss';

interface IProps {
  activeState: IActiveState;
  setActiveState: Dispatch<SetStateAction<IActiveState>>;
}

const Sidebar = ({ activeState, setActiveState }: IProps) => {
  const NavItemIcon = ({ item }: { item: CREATE_PAYOUTS }) => {
    if (activeState.activeForm === item)
      return <i className="ph-caret-right icon-md p-2"></i>;
    if (PAYOUTS_NAV_CONFIG[item].isCompleted)
      return (
        <i
          data-cy="success_icon"
          className="ph-check-circle-fill text-success icon-xl"
        ></i>
      );
    return <></>;
  };

  return (
    <div className={classNames(styles.navContainer)}>
      <Nav vertical data-testid="payout-sidebar">
        {activeState.config &&
          (Object.keys(activeState.config) as CREATE_PAYOUTS[]).map((item) => {
            return (
              <>
                <span
                  className={classNames(
                    PAYOUTS_NAV_CONFIG[item].isCompleted && styles.completed,
                    item === activeState.activeForm && styles.navItemActive,
                    styles.navItem,
                    'm-1'
                  )}
                  // onClick={}
                >
                  <span
                    className={classNames(
                      item === activeState.activeForm
                        ? styles.activeNavText
                        : '',
                      styles.navText
                    )}
                  >
                    {PAYOUTS_NAV_CONFIG[item].label}
                  </span>
                  <NavItemIcon item={item} />
                </span>
              </>
            );
          })}
      </Nav>
    </div>
  );
};

export default Sidebar;
