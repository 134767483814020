import React from 'react';
import useBanking from 'state/banking/hooks/useBanking';
import Summary from './summary';
import Timeline from './timeline';
import PayoutDetails from './payout-details';
import AccountDetails from './account-details';
import VendorDetails from './vendor-details';

const IndividualPayout = (): JSX.Element => {
  const {
    banking: { payoutDetails, error },
  } = useBanking();
  const isDataAvailable = payoutDetails?.payout && !error?.fetchPayout;

  return (
    <>
      {isDataAvailable ? (
        <>
          <Summary info={payoutDetails.payout} />
          <Timeline
            events={payoutDetails.events}
            payout={payoutDetails.payout}
          />
          <VendorDetails info={payoutDetails.payout} />
          <PayoutDetails info={payoutDetails.payout} />
          <AccountDetails info={payoutDetails.payout} />
        </>
      ) : (
        <div className="pt-2 pb-2">
          <h5 className="d-flex justify-content-center">
            No details available
          </h5>
        </div>
      )}
    </>
  );
};

export default IndividualPayout;
