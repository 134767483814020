import SelectDropdown, { DropdownConfig } from 'components/selectDropdown';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, FormGroup } from 'reactstrap';
import FormLabel from 'components/form-label';
import classNames from 'classnames';
import ErrorMessage from 'components/errorMessage';
import {
  EXTERNAL_PAYOUT_STAGES,
  IActiveState,
  PAYOUTS_NAV_CONFIG,
  PAYOUT_STAGE,
} from 'components/modals/banking/payouts/payout';
import useBanking from 'state/banking/hooks/useBanking';
import { BANKING } from 'state/banking/type';
import styles from '../../styles.module.scss';
import AddVendor from './addVendor';
import ListVendor from './listVendor';

interface Iprops {
  setActiveState: Dispatch<SetStateAction<IActiveState>>;
  activeState: IActiveState;
}

const VendorDetails = ({
  setActiveState,
  activeState,
}: Iprops): JSX.Element => {
  const { banking, toListVendors, resetUpdateVendor, setVendorId } =
    useBanking();
  const { vendors } = banking;
  const modalTitle = 'Vendor details';
  const [error, setError] = useState(false);

  const initialDropdownConfig: DropdownConfig<BANKING.IVendor> = {
    isSelected: false,
    options: undefined,
    selectedItemName: undefined,
    selectedItemData: undefined,
    addNewItem: false,
    updateItem: false,
  };
  const [dropdownConfig, setDropdownConfig] = useState(initialDropdownConfig);

  useEffect(() => {
    toListVendors();
  }, []);

  useEffect(() => {
    if (vendors?.vendors_list) {
      dropdownConfig.options = vendors.vendors_list;
      setDropdownConfig({ ...dropdownConfig });
    }
  }, [vendors?.vendors_list]);

  useEffect(() => {
    if (banking.update_vendor || banking.vendor_added) {
      dropdownConfig.addNewItem = false;
      dropdownConfig.updateItem = false;
      dropdownConfig.isSelected = false;
      dropdownConfig.selectedItemData = undefined;
      dropdownConfig.selectedItemName = undefined;
      setDropdownConfig({ ...dropdownConfig });
      activeState.payoutStage = PAYOUT_STAGE.external;
      activeState.activeForm = EXTERNAL_PAYOUT_STAGES.VENDOR_DETAILS;
      PAYOUTS_NAV_CONFIG[EXTERNAL_PAYOUT_STAGES.VENDOR_DETAILS].isCompleted =
        false;
      setActiveState({ ...activeState });
      resetUpdateVendor();
      toListVendors();
    }
  }, [banking.update_vendor, banking.vendor_added]);

  useEffect(() => {
    if (vendors?.vendors_list) {
      dropdownConfig.options = vendors.vendors_list;
      setDropdownConfig({ ...dropdownConfig });
    }
  }, [vendors?.vendors_list]);

  return (
    <>
      <div
        className={classNames(
          styles.formContainer,
          'h-100',
          'hide-scrollbars overflow-hidden'
        )}
      >
        <h1 className={classNames(styles.heading, 'mb-8 pb-2')}>
          {modalTitle}
        </h1>
        {!dropdownConfig.addNewItem && !dropdownConfig.updateItem ? (
          <div>
            <ListVendor
              activeState={activeState}
              setActiveState={setActiveState}
              dropdownConfig={dropdownConfig}
              setDropdownConfig={setDropdownConfig}
            />
          </div>
        ) : (
          <AddVendor
            activeState={activeState}
            setActiveState={setActiveState}
            dropdownConfig={dropdownConfig}
            setDropdownConfig={setDropdownConfig}
          />
        )}
      </div>
    </>
  );
};

export default VendorDetails;
