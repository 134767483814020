import React, { SyntheticEvent, useEffect, useState } from 'react';
import Filter from 'components/filter';
import { EFilterConfigType, IFilterConfig } from 'components/filter/types';
import PageHeading from 'components/heading';
import ExportModal from 'components/modals/export';
import Pagination from 'components/pagination';
import Search from 'components/search';
import ExportBtn from 'components/modals/export/exportBtn';
import BootstrapTable, { SortOrder } from 'react-bootstrap-table-next';
import { useNavigate } from 'react-router-dom';
import { TabContent, TabPane } from 'reactstrap';
import routeConfig from 'routes/config';
import usePayment from 'state/payment/hooks/usePayment';
import { PAYMENTS } from 'state/payment/type';
import CURRENCY from 'utils/currency';
import { formatToTz } from 'utils/date-format';
import useQuery from 'utils/hooks/useQuery';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import { transactionStatusFormatter } from '../../../utils/status-formatter';

interface IProps {
  dateFrom?: string;
  dateTo?: string;
  statusArray?: string[];
  sourceArray?: string[];
  amountOption?: string;
  firstAmount?: string;
  secondAmount?: string;
  storeArray?: string[];
  queryParams: string;
}

const PartnerTransactionsList = ({
  dateFrom,
  dateTo,
  statusArray = [],
  sourceArray = [],
  amountOption,
  firstAmount,
  secondAmount,
  storeArray = [],
  queryParams,
}: IProps): JSX.Element => {
  const heading = 'Transactions';
  const subHeading =
    'View details of transactions happening throughout your connected stores.';

  const urlParams = useQuery(queryParams);

  const { organisation } = useOrganisation();
  const { settings } = organisation;

  const searchString = urlParams.get('search') || undefined;

  const dateValues: string[] = [];
  if (dateFrom) {
    dateValues[0] = dateFrom;
  }
  if (dateTo) {
    dateValues[1] = dateTo;
  }

  const amountValues = ['equal'];
  if (amountOption) {
    amountValues[0] = amountOption;
  }
  if (firstAmount) {
    amountValues[1] = firstAmount;
  }
  if (secondAmount) {
    amountValues[2] = secondAmount;
  }

  const getFilterConfig = () => {
    return [
      {
        key: 'dates',
        label: 'Dates',
        type: EFilterConfigType.date,
        data: {
          keys: [],
          values: dateValues,
        },
        selected: false,
      },
      {
        key: 'amount',
        label: 'Order Amount',
        type: EFilterConfigType.number,
        data: {
          keys: ['condition'],
          values: amountValues,
        },
        selected: false,
      },
    ];
  };

  const defaultFilterConfig: IFilterConfig[] = getFilterConfig();

  const navigate = useNavigate();

  const {
    payment,
    toFetchPartnerTransactions,
    toFetchPartnerCSVTransactions,
    toClearTransactionsExport,
  } = usePayment();
  const { url, error } = payment;

  const filterConfig = defaultFilterConfig;

  // show Date modal
  const [showModal, setShowModal] = useState(false);

  // Table data
  const [products, setProducts] = useState([{}]);

  // Next and prev key
  const [prevKey, setPrevKey] = useState<string | null>(null);
  const [nextKey, setNextKey] = useState<string | null>(null);

  // Number of results
  const [numResults, setNumResults] = useState(0);

  // get to prev page
  const prevPage = () => {
    toFetchPartnerTransactions({
      key: prevKey,
      start_date: dateFrom || undefined,
      end_date: dateTo || undefined,
      amount_1: firstAmount,
      amount_2: secondAmount,
      condition: amountOption || undefined,
      store: storeArray.length > 0 ? storeArray.join(',') : undefined,
      status: statusArray.length > 0 ? statusArray.join(',') : undefined,
      source: sourceArray.length > 0 ? sourceArray.join(',') : undefined,
      search_string: searchString,
    });
  };

  // get to next page
  const nextPage = () => {
    toFetchPartnerTransactions({
      key: nextKey,
      start_date: dateFrom || undefined,
      end_date: dateTo || undefined,
      amount_1: firstAmount,
      amount_2: secondAmount,
      condition: amountOption || undefined,
      store: storeArray.length > 0 ? storeArray.join(',') : undefined,
      status: statusArray.length > 0 ? statusArray.join(',') : undefined,
      source: sourceArray.length > 0 ? sourceArray.join(',') : undefined,
      search_string: searchString,
    });
  };

  useEffect(() => {
    toFetchPartnerTransactions({
      start_date: dateFrom || undefined,
      end_date: dateTo || undefined,
      amount_1: firstAmount,
      amount_2: secondAmount,
      condition: amountOption || undefined,
      store: storeArray.length > 0 ? storeArray.join(',') : undefined,
      status: statusArray.length > 0 ? statusArray.join(',') : undefined,
      source: sourceArray.length > 0 ? sourceArray.join(',') : undefined,
      search_string: searchString,
    });
  }, [queryParams]);

  useEffect(() => {
    if (payment.transactions?.items?.length > 0) {
      const tempTransactions = payment.transactions.items;
      // setting next and prev key
      setNextKey(payment.transactions.next_key);
      setPrevKey(payment.transactions.prev_key);

      const transformedProducts = tempTransactions.map(
        (item: PAYMENTS.ITransactionResult) => {
          // modifying the status
          let status =
            item.transaction_status !== null
              ? item.transaction_status
              : item.order_status;

          if (
            status === 'inited' &&
            (String(item.ach_debit_error_count) === '1' ||
              String(item.ach_debit_error_count) === '2')
          ) {
            status = `${status} Retry ${String(item.ach_debit_error_count)}`;
          }
          const tempObj = {
            reference:
              item.track_id !== null ? item.track_id?.toUpperCase() : 'na',
            id: item.charge_id !== null ? item.charge_id : 'na',
            date: item.order_create_date,
            charge_amount: item.charge_amount || item.calculated_total,
            customer_first_name: item.customer_first_name,
            customer_last_name: item.customer_last_name,
            external_short_identifier: item.external_short_identifier,
            status,
            source: item.source,
            _id: item.id,
            order_id: item.order_id,
            store_id: item.store_id,
            store_name: item.store_name,
          };
          return tempObj;
        }
      );

      setProducts(transformedProducts);
      setNumResults(payment.transactions.total_count);
    } else {
      setProducts([]);
      setNumResults(0);
    }
  }, [payment.transactions]);

  const defaultSorted: [{ dataField: string; order: SortOrder }] = [
    {
      dataField: 'date',
      order: 'desc',
    },
  ];

  const columns = [
    {
      dataField: 'date',
      text: 'Date',
      sort: true,

      formatter: (cell: string) => {
        return (
          <>
            {cell
              ? formatToTz(cell, settings?.timezone, 'MMM dd, hh:mm a')
              : ''}
          </>
        );
      },
    },
    {
      dataField: 'charge_amount',
      text: 'Order Amount',
      sort: true,
      formatter: (cell: number) => {
        return (
          <div className={'currencyColumn'}>
            {CURRENCY.convertToMainUnit(cell)}
          </div>
        );
      },
      // headerAlign: 'right',
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: transactionStatusFormatter,
    },
    {
      dataField: 'customer_first_name',
      text: 'Customer',
      sort: true,
      formatter: (cell: string, row: PAYMENTS.ITransactionResult) => {
        // when consumer_profile_id is deleted
        // change name to [DELETED]
        if (
          row.customer_first_name === row.customer_last_name &&
          cell === row.customer_last_name
        ) {
          return <>{cell}</>;
        }
        return (
          <>
            {cell} {row.customer_last_name}
          </>
        );
      },
    },
    {
      dataField: 'store_name',
      text: 'Store',
      formatter: (cell: string) => {
        return <>{cell || ''}</>;
      },
    },
    {
      dataField: 'store_id',
      text: 'Store ID',
      formatter: (cell: string) => {
        return <>{cell || ''}</>;
      },
    },
  ];

  // Taking actions on row events
  const rowEvents = {
    onClick: (
      e: SyntheticEvent<Element, Event>,
      row: PAYMENTS.ITransactionResult
    ) => {
      navigate(row.order_id);
    },
  };

  return (
    <>
      <PageHeading heading={heading} subHeading={subHeading} className="mb-7" />
      <TabContent>
        <TabPane>
          <div className="d-flex mb-3">
            <span className="mr-auto">
              <Search queryParams={queryParams} />
            </span>

            {filterConfig && (
              <>
                <Filter
                  config={filterConfig}
                  queryParams={queryParams}
                ></Filter>
                <ExportBtn
                  onClick={() => setShowModal(true)}
                  id="tnx-export-btn"
                />
              </>
            )}
          </div>
          <BootstrapTable
            id="tnx"
            wrapperClasses="table-responsive"
            keyField="_id"
            data={products}
            columns={columns}
            rowEvents={rowEvents}
            defaultSorted={defaultSorted}
            hover
            condensed
            bordered={false}
            noDataIndication={
              <p className="text-center text-regular">No results found</p>
            }
          />
          <Pagination
            results={numResults}
            prevPage={prevPage}
            nextPage={nextPage}
            prevKey={prevKey}
            nextKey={nextKey}
          />
          <ExportModal
            showModal={showModal}
            setShowModal={setShowModal}
            config={filterConfig}
            heading="Transaction Summary"
            onExport={toFetchPartnerCSVTransactions}
            reportUrl={url}
            label="Transactions"
            errorMessage={error.addUrl?.message ?? undefined}
            toClearExport={toClearTransactionsExport}
          />
        </TabPane>
      </TabContent>
    </>
  );
};

export default PartnerTransactionsList;
