import React, {
  createContext,
  Dispatch,
  ReactChild,
  ReactChildren,
  Reducer,
  useContext,
  useReducer,
} from 'react';
import { INITIAL_STATE as AUTH_INITIAL_STATE } from './auth/reducers';
import { INITIAL_STATE as PAYMENT_INITIAL_STATE } from './payment/reducers';
import { INITIAL_STATE as SETTINGS_INITIAL_STATE } from './settings/reducers';
import { INITIAL_STATE as STORE_INITIAL_STATE } from './store/reducers';
import { INITIAL_STATE as ORGANISATION_INITIAL_STATE } from './organisation/reducers';
import { INITIAL_STATE as WEBHOOKS_INITIAL_STATE } from './webhooks/reducers';
import { INITIAL_STATE as PAYMENTLINK_INITIAL_STATE } from './paymentLinks/reducers';
import { INITIAL_STATE as REFUNDS_INITIAL_STATE } from './refunds/reducers';
import { INITIAL_STATE as TERMINAL_INITIAL_STATE } from './terminal/reducers';
import { INITIAL_STATE as STATS_INITIAL_STATE } from './stats/reducers';
import { INITIAL_STATE as CONSUMERS_INITIAL_STATE } from './consumer/reducers';
import { INITIAL_STATE as EVENTS_INITIAL_STATE } from './events/reducers';
import { INITIAL_STATE as KYBACTIVATION_INITIAL_STATE } from './kybActivation/reducers';
import { INITIAL_STATE as BANKING_INITIAL_STATE } from './banking/reducers';
import { INITIAL_STATE as PARTNER_STORES_INITIAL_STATE } from './partnerStores/reducers';
import { INITIAL_STATE as PAYMENT_CONFIG_INITIAL_STATE } from './paymentConfig/reducers';

import { IReducers } from './reducers';

export const defaultState = {
  auth: AUTH_INITIAL_STATE,
  organisation: ORGANISATION_INITIAL_STATE,
  terminal: TERMINAL_INITIAL_STATE,
  consumers: CONSUMERS_INITIAL_STATE,
  payment: PAYMENT_INITIAL_STATE,
  settings: SETTINGS_INITIAL_STATE,
  store: STORE_INITIAL_STATE,
  webhooks: WEBHOOKS_INITIAL_STATE,
  paymentLinks: PAYMENTLINK_INITIAL_STATE,
  refunds: REFUNDS_INITIAL_STATE,
  stats: STATS_INITIAL_STATE,
  kybActivation: KYBACTIVATION_INITIAL_STATE,
  events: EVENTS_INITIAL_STATE,
  banking: BANKING_INITIAL_STATE,
  partnerStores: PARTNER_STORES_INITIAL_STATE,
  paymentConfig: PAYMENT_CONFIG_INITIAL_STATE,
};

interface IProps {
  reducer: Reducer<IReducers, IAction>;
  initialState: IReducers;
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
}
interface IContextProps {
  state: IReducers;
  dispatch: Dispatch<IAction>;
}
const StateContext = createContext({} as IContextProps);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const StateProvider = ({ reducer, initialState, children }: IProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <StateContext.Provider value={value}>{children}</StateContext.Provider>
  );
};

export const useStateValue = (): IContextProps => useContext(StateContext);
