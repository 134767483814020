import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface IProps {
  message: string;
  isValidation?: boolean;
  className?: string;
  preIcon?: JSX.Element;
  testId?: string;
}

const ErrorMessage = ({
  className,
  message,
  isValidation = false,
  preIcon,
  testId,
}: IProps): JSX.Element => {
  return (
    <div
      data-testid={`error-text${testId ? `-${testId}` : ''}`}
      className={classNames(
        isValidation
          ? `body-subtext-regular ${styles.error_text} ${className || ''}`
          : 'text-capitalize',
        preIcon && `${styles.preIcon}`
      )}
    >
      {preIcon}
      <span>{message}</span>
    </div>
  );
};
export default ErrorMessage;
