import classNames from 'classnames';
import ErrorMessage from 'components/errorMessage';
import ToastContainer from 'components/toasts/container';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  UncontrolledTooltip,
} from 'reactstrap';
import useBanking from 'state/banking/hooks/useBanking';
import FormLabel from 'components/form-label';
import { Controller, useForm } from 'react-hook-form';
import CURRENCY from 'utils/currency';
import NumberFormat from 'react-number-format';
import {
  CREATE_PAYOUTS,
  Headings,
  IActiveState,
  PAYOUTS_NAV_CONFIG,
  PAYOUTTYPE,
  PAYOUT_STAGE,
  SELF_PAYOUT_STAGES,
  SubHeadings,
} from 'components/modals/banking/payouts/payout';
import styles from '../styles.module.scss';

interface Iprops {
  setActiveState: Dispatch<SetStateAction<IActiveState>>;
  activeState: IActiveState;
}
const PayoutAmount = ({ setActiveState, activeState }: Iprops): JSX.Element => {
  const { errors, handleSubmit, setError, control } = useForm();

  const { banking, resetPayout, setPayoutAmount, toFetchExternalAccount } =
    useBanking();

  const { external_account, stats } = banking;

  const [availableBalance, setAvailableBalance] = useState<number>(
    CURRENCY.totalAvailableBalance(stats.total_balance, stats.holding_balance)
  );

  const payoutBalanceCheck = (amount: string) => {
    const amountNumber = parseFloat(amount.substring(1));
    return amountNumber <= availableBalance / 100;
  };

  const payoutZeroBalanceCheck = (amount: string) => {
    const amountNumber = parseInt(amount.substring(1), 10);
    return amountNumber >= 10;
  };
  const formSubmit = (values: { [x: string]: string }) => {
    if (payoutZeroBalanceCheck(values.payout_amount)) {
      if (payoutBalanceCheck(values.payout_amount)) {
        setPayoutAmount(values.payout_amount);
        activeState.activeForm = SELF_PAYOUT_STAGES.REVIEW;
        PAYOUTS_NAV_CONFIG[CREATE_PAYOUTS.PAYOUT_DETAILS].isCompleted = true;
        setActiveState({ ...activeState });
      } else {
        setError('payout_amount', {
          type: 'custom',
          message:
            'The payout amount should not exceed the total available balance (should be at least $10.00).',
        });
      }
    } else {
      setError('payout_amount', {
        type: 'custom',
        message: 'The payout amount should be at least $10.00',
      });
    }
  };

  const formSubmission = () => {
    handleSubmit(formSubmit)();
  };

  const backButton = () => {
    if (
      activeState.config !== null &&
      Object.keys(activeState.config).includes(CREATE_PAYOUTS.BANK_DETAILS)
    ) {
      activeState.payoutStage = PAYOUT_STAGE.self;
      activeState.activeForm = CREATE_PAYOUTS.BANK_DETAILS;
      PAYOUTS_NAV_CONFIG[CREATE_PAYOUTS.PAYOUT_DETAILS].isCompleted = false;
      setActiveState({ ...activeState });
    } else {
      PAYOUTS_NAV_CONFIG[CREATE_PAYOUTS.PAYOUT_DETAILS].isCompleted = false;
      activeState.payoutStage = PAYOUT_STAGE.info;
      activeState.config = null;
      activeState.heading = Headings.info;
      activeState.subheading = SubHeadings.stage_1;
      activeState.activeForm = undefined;
      setActiveState({ ...activeState });
    }
  };

  useEffect(() => {
    const footerElement = (
      <>
        <Button
          className="btn btn-primary mr-2"
          type="submit"
          onClick={() => backButton()}
        >
          <span className="d-flex">Back</span>
        </Button>
        <Button
          color="primary"
          className="btn btn-primary"
          type="submit"
          onClick={() => formSubmission()}
        >
          <span className="d-flex">
            Next <i className="ph-arrow-right pl-1"></i>
          </span>
        </Button>
      </>
    );
    activeState.footer = footerElement;
    setActiveState({ ...activeState });
  }, []);

  useEffect(() => {
    if (banking.add_bank_account) {
      toFetchExternalAccount();
    }
  }, [banking?.add_bank_account]);

  useEffect(() => {
    setAvailableBalance(
      CURRENCY.totalAvailableBalance(stats.total_balance, stats.holding_balance)
    );
  }, [stats.holding_balance, stats.total_balance]);
  return (
    <>
      <div
        className={classNames(
          styles.formContainer,
          'hide-scrollbars overflow-hidden'
        )}
      >
        <h1 className={classNames(styles.heading, 'mb-8 pb-2')}>
          Payout details
        </h1>

        <div className={styles.payoutBalance}>
          <span className="d-flex">Total balance</span>
          <span className="d-flex">
            {' '}
            {CURRENCY.convertToMainUnit(stats?.total_balance || 0)}
          </span>
        </div>
        <div className={classNames(styles.payoutBalance)}>
          <span
            id="holdingInfo"
            className={classNames(styles.dashedBorder, 'd-flex')}
          >
            Holding balance
          </span>
          <span className="d-flex">
            ({CURRENCY.convertToMainUnit(stats?.holding_balance || 0)})
          </span>
        </div>
        <div
          className={classNames(
            styles.payoutBalance,
            styles.upperBorder,
            'mt-2 pt-1'
          )}
        >
          <span className="d-flex">Total available balance</span>
          <span className="d-flex">
            {CURRENCY.convertToMainUnit(availableBalance)}
          </span>
        </div>
        <div className={classNames(styles.sendDetailsText, 'mt-7')}>
          <div>
            <Form role="form" onSubmit={handleSubmit(formSubmit)}>
              <FormGroup>
                <FormLabel
                  isRequired
                  element="payout_amount"
                  label="Payout amount"
                  className={styles.fieldLabel}
                />
                <Controller
                  control={control}
                  name="payout_amount"
                  id="payout_amount"
                  rules={{ required: true }}
                  defaultValue={banking.payout_amount}
                  render={(field) => (
                    <NumberFormat
                      name="payout_amount"
                      id="payout_amount"
                      allowNegative={false}
                      fixedDecimalScale
                      prefix={'$'}
                      decimalScale={2}
                      className={classNames(
                        'd-block form-control',
                        errors?.payout_amount && styles.borderAlert
                      )}
                      onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        field.onChange(e);
                      }}
                      value={field.value}
                      placeholder="$0.00"
                      getInputRef={field.ref}
                    />
                  )}
                />
                {errors?.payout_amount ? (
                  <ErrorMessage
                    isValidation
                    message={
                      errors.payout_amount.message ||
                      'Please enter a payout amount.'
                    }
                    preIcon={
                      <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
                    }
                  />
                ) : (
                  <span className={styles.subLabel}>
                    The payout amount should not exceed the total available
                    balance (should be at least $10.00).
                  </span>
                )}
              </FormGroup>
            </Form>
          </div>
          <UncontrolledTooltip placement="right" target="holdingInfo">
            <span>
              The funds are on hold to maintain a minimum balance to cover
              future refunds, possibility of negative balance, etc.
            </span>
          </UncontrolledTooltip>
        </div>
      </div>
    </>
  );
};

export default PayoutAmount;
