import moment from 'moment';
import { formatDate, isSameDay } from 'utils/date-format';

const joinWithoutTrailingDelimiter = (
  input: string[],
  delimiter = ','
): string => {
  let queryString = input.join(delimiter);
  if (queryString.charAt(queryString.length - 1) === delimiter) {
    queryString = queryString.slice(0, -1);
  }
  return queryString;
};

const hyphenate = (input: string, delimiter = ' '): string => {
  return input.toLowerCase().replace(delimiter, '-');
};

const getFullName = (fname?: string, lname?: string) => {
  if (!fname && !lname) return undefined;
  if (!fname) return lname;
  if (!lname) return fname;
  return `${fname} ${lname}`;
};

const capitalizeFirstLetter = (message?: string) => {
  return message
    ? message.charAt(0).toUpperCase() + message.slice(1)
    : undefined;
};

const formatPhoneNumber = (phone: string, includeCountryCode?: boolean) => {
  let formattedStr = phone;
  if (phone && phone.includes('-')) {
    formattedStr = phone.replaceAll('-', '');
  }
  if (includeCountryCode === false)
    formattedStr = formattedStr.replaceAll('+1', '');

  if (
    includeCountryCode &&
    Number(formattedStr) &&
    formattedStr.length === 10 &&
    !phone.includes('+1')
  ) {
    formattedStr = `+1${formattedStr}`;
  }
  return formattedStr;
};

/**
 * The function `nullCheckInArray` checks if any value in an object is null, undefined, or an empty
 * string.
 * @param list - The `list` parameter is an object with string keys and any values.
 * @returns The function `nullCheckInArray` returns a boolean value. It returns `true` if any of the
 * values in the input `list` object are `null`, `undefined`, or an empty string. Otherwise, it returns
 * `false`.
 */
const nullCheckInArray = (list: Record<string, any>) => {
  const values = Object.values(list);
  if (values?.length === 0) return true;
  return values.some(
    (item) => item === null || item === undefined || item === ''
  );
};

/**
 * Formats a given phone number string into a US phone number format
 * (555)-785-8243
 * @param {string} phone - phone number string
 * @returns formatted phone number or null if input is not valid
 */
const formatToUSPhoneNumber = (phone: string) => {
  // extract last 10 digits to ignore country code
  const cleaned = phone.replace(/\D/g, '').slice(-10);
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]})-${match[2]}-${match[3]}`;
  }
  return null;
};

const getTreezTicketIDFromReferenceNumber = (referenceNum?: string) => {
  if ( referenceNum && referenceNum?.indexOf('-') < 0) { 
    return referenceNum
  }
  const arr = referenceNum?.split('-');
  return Array.isArray(arr) ? arr[arr.length - 2] || '' : '';
};

/**
 * Constructs a formatted date string for comparison between two dates.
 *
 * If the fromDate and toDate represent the same day, returns a string in the format:
 * "MMM DD, YYYY hh:mm A - hh:mm A".
 * Otherwise, returns a string with fromDate and toDate formatted as:
 * "MMM dd yyyy, hh:mm a - MMM dd yyyy, hh:mm a".
 *
 * @param {string} fromDate - The starting date in ISO 8601 format
 * @param {string} toDate - The ending date in ISO 8601 format
 * @returns {string} Formatted date string
 */
const getComparisonDateString = (fromDate: string, toDate: string) => {
  if (isSameDay(moment(fromDate), moment(toDate))) {
    return `${moment(fromDate).format('MMM DD, YYYY')} ${moment(
      fromDate
    ).format('hh:mm A')} - ${moment(toDate).format('hh:mm A')}`;
  }

  return `${formatDate(fromDate, 'MMM dd yyyy, hh:mm a')} - ${formatDate(
    toDate,
    'MMM dd yyyy, hh:mm a'
  )}`;
};

const STRING = {
  joinWithoutTrailingDelimiter,
  hyphenate,
  getFullName,
  capitalizeFirstLetter,
  formatPhoneNumber,
  formatToUSPhoneNumber,
  nullCheckInArray,
  getTreezTicketIDFromReferenceNumber,
  getComparisonDateString,
};

export default STRING;
