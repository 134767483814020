import React from 'react';
import { PAYMENTS } from 'state/payment/type';
import AmountDetails from 'components/amount-details';
import BackButton from 'components/backButton';
import PaymentDetails from './paymentDetails';
import Summary from './summary';
import styles from './styles.module.scss';

interface props {
  heading: string;
  info: PAYMENTS.IIndividualTransactionResult;
  onBack?: () => void;
}
const FailedTxnDetails = ({
  heading,
  info = {} as PAYMENTS.IIndividualTransactionResult,
  onBack,
}: props): JSX.Element => {
  return (
    <>
      <div data-testid="details-failed-txn">
        {onBack && <BackButton onBack={onBack} />}
        <div className="body-subtext mb-2" style={{ color: '#4D5664' }}>
          {heading}
        </div>
        <AmountDetails
          amount={info?.charge_amount || 0}
          className={styles.PaymentDetailsAmount}
        />
        <Summary info={info} />
        <PaymentDetails info={info} />
      </div>
    </>
  );
};

export default FailedTxnDetails;
