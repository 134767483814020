import differenceInDays from 'date-fns/differenceInDays';
import { TimeUnit } from 'chart.js';
import { STATS } from 'state/stats/type';
import moment from 'moment';
import { format, isAfter } from 'date-fns';
import {
  formatStartOfDay,
  formatEndOfDay,
  getEndOfWeek,
} from 'utils/date-format';
import routeConfig from 'routes/config';

const { IIntervalType: INTERVAL } = STATS;

/**
 * Given two dates, return the number of days between them
 * @param {string} date1 - Date string
 * @param {string} date2 - Date string.
 * @returns The number of days between the two dates (inclusive).
 */
export const intervalInDays = (date1: string, date2: string) => {
  try {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const [earlier, later] = isAfter(d1, d2) ? [d2, d1] : [d1, d2];
    return differenceInDays(later, earlier) + 1 || 0;
  } catch (error) {
    return 0;
  }
};

/**
 * Given an interval, return a list of frequencies that are valid for that interval
 * @param {number} interval - The number of days in the interval.
 * @returns An array of interval types.
 */
export const mapIntervalToFrequency = (
  interval: number
): STATS.IIntervalType[] => {
  if (interval === 1) return [INTERVAL.HOURLY];
  if (interval > 1 && interval <= 13) return [INTERVAL.DAILY];
  if (interval >= 14 && interval <= 61)
    return [INTERVAL.DAILY, INTERVAL.WEEKLY];
  if (interval >= 62 && interval <= 90)
    return [INTERVAL.WEEKLY, INTERVAL.MONTHLY];

  if (interval > 90) return [INTERVAL.MONTHLY];
  return [];
};

/**
 * Given an interval in days and a frequency type, return the step size for the time axis
 * @param {number} intervalDays - the number of days in the interval
 * @param freqType - STATS.IIntervalType - breakdown type
 * @returns a number that represents the step size for the time axis.
 */
export const getTimeAxisStepSize = (
  intervalDays: number,
  freqType: STATS.IIntervalType
): number => {
  if (freqType === INTERVAL.HOURLY) {
    if (intervalDays === 1) return 4 * 60; // minutes
    if (intervalDays === 2) return 12 * 60;
  }
  if (intervalDays >= 21 && intervalDays <= 29 && freqType === INTERVAL.DAILY)
    return 2;
  if (intervalDays >= 30 && intervalDays <= 62 && freqType === INTERVAL.DAILY)
    return 10;
  if (freqType === INTERVAL.WEEKLY) return 1;
  return 1;
};

/**
 * Given a frequency, return the chart.js time unit that should be used for the time axis
 * @param freq - STATS.IIntervalType
 * @returns The time unit for the frequency of the data.
 */
export const getTimeAxisUnit = (freq: STATS.IIntervalType): TimeUnit => {
  switch (freq) {
    case INTERVAL.HOURLY:
      return 'minute';
    case INTERVAL.WEEKLY:
      return 'day';
    case INTERVAL.MONTHLY:
      return 'month';
    case INTERVAL.DAILY:
    default:
      return 'day';
  }
};

/**
 * Given a frequency, return the appropriate time format for the tooltip
 * @param freq - STATS.IIntervalType
 * @returns A string that represents the time format for the tooltip.
 */
export const getTooltipTimeFormat = (freq: STATS.IIntervalType): string => {
  switch (freq) {
    case INTERVAL.HOURLY:
      return 'h a';
    case INTERVAL.WEEKLY:
      return 'd MMM';
    case INTERVAL.MONTHLY:
      return 'MMM';
    case INTERVAL.DAILY:
    default:
      return 'd MMM';
  }
};

/**
 * Return the percent change between the current and previous values
 * @param {number} current - The current value of the metric.
 * @param {number} previous - The previous value of the metric.
 * @returns an integer showing percent change.
 */
export const getPercentChange = (
  current: number,
  previous: number
): number | null => {
  const percent = ((current - previous) / previous) * 100;
  return Number.isFinite(percent) ? percent : null;
};

/**
 * Given a date range, return the previous date range
 * @param {string} dateFrom - The start date of the date range.
 * @param {string} dateTo - The end date of the date range.
 * @returns an array containing the start and end dates of the previous range.
 */
export const getComparisonDate = (dateFrom: string, dateTo: string) => {
  try {
    const from = new Date(dateFrom).getTime();
    const to = new Date(dateTo).getTime();
    const oneDayOffset = 24 * 60 * 60 * 1000;
    const interval = moment(to).isAfter(from, 'day')
      ? Math.abs(to - from - oneDayOffset)
      : 0;
    const prevFrom = formatStartOfDay(new Date(from - interval - oneDayOffset));

    const prevTo = formatEndOfDay(new Date(from - oneDayOffset));
    return [prevFrom, prevTo];
  } catch (error) {
    return null;
  }
};

export const getComparisonDateTime = (dateFrom: string, dateTo: string) => {
  try {
    const from = new Date(dateFrom).getTime();
    const to = new Date(dateTo).getTime();

    const oneDayOffset = 24 * 60 * 60 * 1000;

    const interval = Math.ceil(Math.abs((to - from) / oneDayOffset));

    const todate = new Date(dateTo);
    todate.setDate(todate.getDate() - interval);

    const fromdate = new Date(dateFrom);
    fromdate.setDate(fromdate.getDate() - interval);

    const prevTo = todate.toISOString();
    const prevFrom = fromdate.toISOString();

    return [prevFrom, prevTo];
  } catch (error) {
    return null;
  }
};

/**
 * Given a metric, return the path to redirect to
 * @param metric - STATS.graphType
 * @returns the absolute pathname to redirect to.
 */
export const getRedirectPath = (metric: STATS.graphType): string => {
  switch (metric) {
    case STATS.graphType.gross_amount:
    case STATS.graphType.payments:
    case STATS.graphType.aov:
      return `${routeConfig.PAYMENTS.layout}/${routeConfig.PAYMENTS.transactions.path}`;
    default:
      return '/';
  }
};

/**
 * Return the week range for the chart.js tooltip for weekly breakdown data
 * @param {Date | string} date - Date
 * @returns A string that represents the date range containing the date.
 */
export const getTooltipWeekRange = (
  date: Date | string,
  formatStr = 'd MMM'
): string => {
  const dateObj = new Date(date);
  const endOfWeek = getEndOfWeek(dateObj);
  const today = new Date();

  return dateObj.getDay() === 0
    ? format(dateObj, formatStr)
    : `${format(dateObj, formatStr)} - ${format(
        isAfter(endOfWeek, today) ? today : endOfWeek,
        formatStr
      )}`;
};

export const getPaymentTypeDisplayText = (
  value: STATS.IPaymentType
): string => {
  switch (value) {
    case STATS.IPaymentType.ALL:
      return 'Total';
    case STATS.IPaymentType.ACH:
      return 'ACH';
    case STATS.IPaymentType.CATM:
      return 'CATM';
    case STATS.IPaymentType.DEBIT:
      return 'Debit';
  }
};
