import { PAYMENTS } from 'state/payment/type';
import { SUMMARY } from 'state/summary/type';
import {
  FETCH_REFUNDS,
  INITIATE_REFUND,
  INITIATE_REFUND_FAILURE,
  INITIATE_REFUND_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_FAILURE,
  VERIFY_OTP_SUCCESS,
  RETRY_TRANSACTION,
  RETRY_TRANSACTION_SUCCESS,
  RETRY_TRANSACTION_FAILURE,
  GET_REFUND_LIST,
  GET_REFUND_LIST_SUCCESS,
  GET_REFUND_LIST_FAILURE,
  RESET_RETRY_STATE,
  RESET_REFUND_STATE,
  FETCH_REFUNDS_SUMMARY_DATA,
  FETCH_REFUNDS_SUMMARY_DATA_SUCCESS,
  FETCH_REFUNDS_SUMMARY_DATA_FAILURE,
  FETCH_CSV,
  FETCH_CSV_FAILURE,
  FETCH_CSV_SUCCESS,
  CLEAR_EXPORT,
} from './actions';
import { REFUNDS } from './type';

export const INITIAL_STATE: REFUNDS.RefundsReducer = {
  isLoading: false,
  error: {} as REFUNDS.Error,
  otpVerification: undefined,
  refundCreated: {} as REFUNDS.ICreateRefundResponse,
  refundCreationSuccess: false,
  retryTransactionResponse: {} as REFUNDS.IRetryResponse,
  retrySuccess: false,
  refundList: {} as IPaginatedResponse<REFUNDS.ICreateRefundResponse>,
  summaryData:
    {} as SUMMARY.ISummaryResponse<PAYMENTS.ITransactionSummaryBreakdown>,
  url: '',
};

const refundsReducer = (
  action: IAction,
  state = INITIAL_STATE
): REFUNDS.RefundsReducer => {
  switch (action.type) {
    case FETCH_REFUNDS:
      return {
        ...state,
        isLoading: true,
      };
    case VERIFY_OTP:
      return {
        ...state,
        isLoading: true,
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        otpVerification: action.payload,
        error: {},
      };
    case VERIFY_OTP_FAILURE:
      return {
        ...state,
        isLoading: false,
        otpVerification: 'false',
        error: { errorOtp: action.payload.message },
      };
    case INITIATE_REFUND:
      return {
        ...state,
        isLoading: true,
        refundCreated: {} as REFUNDS.ICreateRefundResponse,
        refundCreationSuccess: false,
        error: {},
      };
    case INITIATE_REFUND_SUCCESS:
      return {
        ...state,
        isLoading: false,
        refundCreated: action.payload,
        refundCreationSuccess: true,
        error: {},
      };
    case INITIATE_REFUND_FAILURE:
      return {
        ...state,
        isLoading: false,
        refundCreated: {} as REFUNDS.ICreateRefundResponse,
        refundCreationSuccess: false,
        error: { initiateRefund: action.payload },
      };
    case RETRY_TRANSACTION:
      return {
        ...state,
        isLoading: true,
        retryTransactionResponse: {} as REFUNDS.IRetryResponse,
        retrySuccess: false,
        error: {},
      };
    case RETRY_TRANSACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        retryTransactionResponse: action.payload,
        retrySuccess: true,
        error: {},
      };
    case RETRY_TRANSACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        retryTransactionResponse: {} as REFUNDS.IRetryResponse,
        retrySuccess: false,
        error: { retryTransaction: action.payload.message },
      };
    case GET_REFUND_LIST:
      return {
        ...state,
        isLoading: true,
        refundList: {} as IPaginatedResponse<REFUNDS.ICreateRefundResponse>,
        error: {},
      };
    case GET_REFUND_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        refundList: action.payload,
        error: {},
      };
    case GET_REFUND_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        refundList: {} as IPaginatedResponse<REFUNDS.ICreateRefundResponse>,
        error: { refundList: action.payload.message },
      };
    case RESET_REFUND_STATE:
      return {
        ...state,
        refundCreationSuccess: false,
        error: { initiateRefund: undefined },
      };
    case RESET_RETRY_STATE:
      return {
        ...state,
        retrySuccess: false,
        error: { retryTransaction: undefined },
      };
    case FETCH_REFUNDS_SUMMARY_DATA:
      return {
        ...state,
        isLoading: true,
        summaryData:
          {} as SUMMARY.ISummaryResponse<PAYMENTS.ITransactionSummaryBreakdown>,
      };
    case FETCH_REFUNDS_SUMMARY_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        summaryData: action.payload,
      };
    case FETCH_REFUNDS_SUMMARY_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        summaryData:
          {} as SUMMARY.ISummaryResponse<PAYMENTS.ITransactionSummaryBreakdown>,

        error: { fetchRefundsSummaryData: action.payload.message },
      };
    case FETCH_CSV:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case FETCH_CSV_SUCCESS:
      return {
        ...state,
        isLoading: false,
        url: action.payload.url,
        error: {},
      };
    case FETCH_CSV_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { addUrl: action.payload.data },
      };

    case CLEAR_EXPORT:
      return {
        ...state,
        url: '',
        error: {
          ...state.error,
          addUrl: undefined,
        },
      };
    default:
      return state;
  }
};
export default refundsReducer;
