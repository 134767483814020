import {
  RESET_LOGISTICS_UPDATE,
  UPDATE_LOGISTICS_SETTINGS,
  UPDATE_LOGISTICS_SETTINGS_FAILURE,
  UPDATE_LOGISTICS_SETTINGS_SUCCESS,
} from './actions';
import { SETTINGS } from './type';

export const INITIAL_STATE: SETTINGS.SettingsReducer = {
  isLoading: false,
  error: {} as SETTINGS.Error,
  store: {} as SETTINGS.IStore,
  logisticsMappingAdded: false,
  settingsAdded: false,
  logisticsUpdated: false,
  storeUpdated: false,
  configUpdate: false,
};

const settingsReducer = (
  action: IAction,
  state = INITIAL_STATE
): SETTINGS.SettingsReducer => {
  switch (action.type) {
    case UPDATE_LOGISTICS_SETTINGS:
      return {
        ...state,
        isLoading: true,
        logisticsUpdated: false,
        error: {},
      };
    case UPDATE_LOGISTICS_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        logisticsUpdated: true,
        error: {},
      };
    case UPDATE_LOGISTICS_SETTINGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        logisticsUpdated: false,
        error: { updateLogistics: action.payload.message },
      };
    case RESET_LOGISTICS_UPDATE:
      return {
        ...state,
        logisticsUpdated: false,
      };
    default:
      return state;
  }
};
export default settingsReducer;
