import React, { useState } from 'react';
import { Card } from 'reactstrap';
import * as util from 'utils/dashboard';
import PageHeading from 'components/heading';
import Graph from './graph';
import Cards from './summaryCards';
import CustomerInfo from './customerInfo';
import DashboardPaymentMethodFilter from './filters';
import { useSearchParams } from 'react-router-dom';
import DateTimeRangePicker from 'components/calender/rangePicker';
import moment, { Moment } from 'moment';
import styles from './dashboard.module.scss';
import STRING from 'utils/string';

const Dashboard = (): JSX.Element => {
  const defaultFromDate = moment().startOf('day');
  const defaultToDate = moment().endOf('day');

  const [dateFrom, setDateFrom] = useState<Moment>(defaultFromDate);
  const [dateTo, setDateTo] = useState<Moment>(defaultToDate);

  const [prevFrom, prevTo] =
    util.getComparisonDateTime(dateFrom.toISOString(), dateTo.toISOString()) ||
    [];

  const [searchParams] = useSearchParams();
  const method = searchParams.get('method') || '';

  return (
    <>
      <Card className="card-profile border-0">
        <PageHeading heading="Analytics" className="mb-5" />
        <div className="d-flex align-items-center mb-5">
          <DateTimeRangePicker
            initialStartValue={dateFrom}
            initialEndValue={dateTo}
            setStartDatetime={setDateFrom}
            setEndDatetime={setDateTo}
          />
          <div
            className={styles.comparisonDateText}
          >{`compared to ${STRING.getComparisonDateString(
            prevFrom,
            prevTo
          )}`}</div>
        </div>
        <div className="d-flex align-items-center mb-5">
          <p className="pr-2" style={{ fontWeight: 500, fontSize: '14px' }}>
            Filter by
          </p>
          <DashboardPaymentMethodFilter />
        </div>
        <Cards
          currentDates={{
            from: dateFrom.toISOString(),
            to: dateTo.toISOString(),
          }}
          prevDates={{ from: prevFrom, to: prevTo }}
        />
        <Graph
          currentDates={[dateFrom.toISOString(), dateTo.toISOString()]}
          prevDates={[prevFrom, prevTo]}
        />
        {method == 'ach' && (
          <CustomerInfo
            dates={{ from: dateFrom.toISOString(), to: dateTo.toISOString() }}
          />
        )}
      </Card>
    </>
  );
};
export default Dashboard;
