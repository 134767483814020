import React from 'react';
import { Badge } from 'reactstrap';
import {
  REFUND_STATUS,
  STATUS_AUTHORIZED,
  STATUS_CANCELED,
  STATUS_COMPLETED,
  STATUS_INITIATED,
  STATUS_REFUND_FAILED,
  STATUS_REFUND_INITIATED,
  STATUS_REFUND_SUCCESSFUL,
  STATUS_RETURNED,
  STATUS_RISKY,
  STATUS_SETTLED,
  STATUS_SETTLEMENT_INITED,
  STATUS_SETTLEMENT_SUBMITTED,
  STATUS_SUCCESS,
  STATUS_SUSPENDED,
  STATUS_PAYOUT_INITED,
  STATUS_PAYOUT_SUBMITTED,
  STATUS_PAYOUT_SETTLED,
  STATUS_PAYOUT_FAILED,
  STATUS_WRITE_OFF,
  STATUS_MEDIUM_RISK,
  GTW_STATUS,
} from './constants';

export const payoutsStatusFormatter = (
  cell: string | undefined
): JSX.Element => {
  if (cell === STATUS_PAYOUT_INITED) {
    return <Badge color="info">Pending</Badge>;
  }
  if (cell === STATUS_PAYOUT_SUBMITTED) {
    return <Badge color="info">Initiated</Badge>;
  }
  if (cell === STATUS_PAYOUT_SETTLED) {
    return <Badge color="success">Successful</Badge>;
  }
  if (cell === STATUS_PAYOUT_FAILED) {
    return <Badge color="danger">Failed</Badge>;
  }
  return <Badge color="info">Pending</Badge>;
};

export const gatewatTransactionStatusFormatter = (status?: string) => {
  switch (status) {
    case GTW_STATUS.PENDING:
      return <Badge color="info">Initiated</Badge>;
    case GTW_STATUS.SUCCESS:
      return <Badge color="success">Successful</Badge>;
    case GTW_STATUS.REFUNDED:
      return <Badge color="success">Refunded</Badge>;
    case GTW_STATUS.CANCELED:
    case GTW_STATUS.VOIDED:
      return <Badge color="disabled">Canceled</Badge>;
    case GTW_STATUS.DECLINED:
    case GTW_STATUS.ERROR:
      return <Badge color="danger">Failed</Badge>;
    case GTW_STATUS.NOT_FOUND:
      return <Badge color="danger">Lost</Badge>;
    
    default:
      return <></>;
  }
};

export const transactionStatusFormatter = (
  cell: string | undefined
): JSX.Element => {
  if (cell === STATUS_INITIATED) {
    return <Badge color="info">ACH Initiated</Badge>;
  }
  if (cell === STATUS_AUTHORIZED) {
    return <Badge color="progress">ACH Authorized</Badge>;
  }

  if (
    cell === STATUS_SUCCESS ||
    cell === 'settled' ||
    cell === 'finalized ' ||
    cell === 'finalized' ||
    cell === 'success'
  ) {
    return <Badge color="success">Successful</Badge>;
  }
  if (cell === STATUS_RETURNED || cell === STATUS_WRITE_OFF) {
    return <Badge color="danger">ACH Returned</Badge>;
  }
  if (cell === STATUS_CANCELED) {
    return <Badge color="disabled">Canceled</Badge>;
  }
  if (cell === STATUS_COMPLETED) {
    return <Badge color="danger">Lost</Badge>;
  }
  if (cell === STATUS_REFUND_INITIATED) {
    return <Badge color="info">Refund Initiated</Badge>;
  }
  if (cell === STATUS_REFUND_SUCCESSFUL) {
    return <Badge color="success">Refund Successful</Badge>;
  }
  if (cell === STATUS_REFUND_FAILED) {
    return <Badge color="danger">Refund Failed</Badge>;
  }
  return <Badge color="progress">Dropped</Badge>;
};

export const settlementStatusFormatter = (
  cell: string | undefined
): JSX.Element => {
  if (
    cell === STATUS_SETTLEMENT_INITED ||
    cell === STATUS_SETTLEMENT_SUBMITTED
  ) {
    return <Badge color="info">ACH Initiated</Badge>;
  }
  if (cell === STATUS_SETTLED) {
    return <Badge color="success">Successful</Badge>;
  }
  return <Badge color="danger">Failed</Badge>;
};

export const customerStatusFormatter = (
  cell: string | undefined
): JSX.Element => {
  if (cell === STATUS_SUSPENDED) {
    return <Badge color="progress">Suspended</Badge>;
  }
  if (cell === STATUS_RISKY) {
    return <Badge color="danger">High Risk</Badge>;
  }
  if (cell === STATUS_MEDIUM_RISK) {
    return <Badge color="danger">Medium Risk</Badge>;
  }
  return <Badge color="info">Active</Badge>;
};

export const APIKeysStatusFormatter = (
  cell: string | undefined
): JSX.Element => {
  if (cell === 'active') {
    return <Badge color="info">ACTIVE</Badge>;
  }
  return <Badge color="danger">DELETED</Badge>;
};
export const webhooksStatusFormatter = (
  cell: string | undefined
): JSX.Element => {
  if (cell === 'active') {
    return <Badge color="info">ACTIVE</Badge>;
  }
  return <Badge color="disabled">INACTIVE</Badge>;
};

export const paymentProviderStatusFormatter = (cell: boolean): JSX.Element => {
  if (cell) {
    return <Badge color="info">APPROVED</Badge>;
  }
  return <Badge color="progress">DISABLED</Badge>;
};
// export const paymentLinkStatusFormatter = (
//   cell: string | undefined
// ): JSX.Element => {
//   if (cell === 'successful') {
//     return <Badge color="info">SUCCESSFUL</Badge>;
//   }
//   return <Badge color="progress">PAUSED</Badge>;
// };

export const WebhookEventStatusFormatter = (
  cell: string | undefined
): JSX.Element => {
  if (cell === 'success') {
    return <Badge color="success">Successful</Badge>;
  }
  return <Badge color="danger">Failed</Badge>;
};

export const refundsStatusFormatter = (
  cell: string | undefined
): JSX.Element => {
  if (cell === REFUND_STATUS.SUCCEEDED) {
    return <Badge color="success">Successful</Badge>;
  }
  if (cell === REFUND_STATUS.PENDING) {
    return <Badge color="info">Initiated</Badge>;
  }
  return <Badge color="danger">Failed</Badge>;
};

export const payoutStatusFormatter = (cell: string): JSX.Element => {
  switch (cell) {
    /**
     * initiated & pending mean the same - two statuses used by Synctera
     * Both are mapped to 'initiated' on FE
     */
    case 'inited':
      return <Badge color="info">Initiated</Badge>;
    case 'settled':
      return <Badge color="success">Successful</Badge>;
    case 'failed':
      return <Badge color="disabled">Failed</Badge>;
    default:
      return <></>;
  }
};

export const AmountChangePercentLabel = ({ value = 0 }): JSX.Element => {
  const floatValue = `${value.toFixed(1).replace('-', '')}%`;

  return value > 0 ? (
    <Badge color="success" className="statSummaryPercentLabel">
      <i className="ph-arrow-up icon-md"></i>
      <span>{floatValue}</span>
    </Badge>
  ) : value === 0 ? (
    <Badge color="disabled" className=" statSummaryPercentLabel">
      <span>{floatValue}</span>
    </Badge>
  ) : (
    <Badge color="danger" className="statSummaryPercentLabel">
      <i className="ph-arrow-down icon-md"></i>
      <span>{floatValue}</span>
    </Badge>
  );
};
