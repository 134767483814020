import React from 'react';
import classNames from 'classnames';
import CURRENCY from 'utils/currency';
import { BANKING } from 'state/banking/type';
import styles from '../summary.module.scss';

interface IProps {
  row: BANKING.IBalanceSummaryBreakdown;
}

const BankingBalanceAccordion = ({ row }: IProps): JSX.Element => {
  return (
    <>
      <div>
        <p className={classNames(styles.dropDownInfo, 'pt-0')}>
          <span>Opening balance</span>
          <span data-testid="opening_balance">
            {CURRENCY.convertToMainUnit(row?.opening_balance) || '$00.00'}
          </span>
        </p>
        <p className={styles.dropDownInfo}>
          <span>Total incoming</span>
          <span data-testid="total_incoming">
            {CURRENCY.convertToMainUnit(row?.total_incoming) || '$00.00'}
          </span>
        </p>
        <p className={styles.dropDownInfo}>
          <span>Total outgoing</span>
          <span data-testid="total_outgoing">
            {CURRENCY.convertToMainUnit(row?.total_outgoing) || '$00.00'}
          </span>
        </p>
        <p className={classNames(styles.dropDownGross, 'pb-0')}>
          <span>Closing balance</span>
          <span data-testid="closing_balance">
            {CURRENCY.convertToMainUnit(row?.closing_balance) || '$00.00'}
          </span>
        </p>
      </div>
    </>
  );
};

export default BankingBalanceAccordion;
