import { WarningIcon } from 'assets/img/icons/warning';
import classNames from 'classnames';
import React, { Dispatch, SetStateAction } from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import useBanking from 'state/banking/hooks/useBanking';
import CURRENCY from 'utils/currency';
import ModalContainer from '../container';
import styles from './styles.module.scss';

interface IProps {
  openModal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  externalPayoutError?: boolean;
  availableBalance: number;
}
const PayoutBalanceError = ({
  externalPayoutError = false,
  openModal,
  setModal,
  availableBalance,
}: IProps): JSX.Element => {
  const { banking } = useBanking();

  const { stats } = banking;

  const toggle = () => {
    setModal(false);
  };

  return (
    <div>
      <ModalContainer
        data-testid="modal"
        isOpen={openModal}
        onClose={toggle}
        header={false}
        isFullHeight={false}
        footer={
          <div>
            <Button data-testid="Close" onClick={toggle} color="primary">
              Close
            </Button>
          </div>
        }
      >
        <>
          <div className={classNames(styles.confirmCloseModal)}>
            <WarningIcon width="70px" height="70px" viewBox="0 0 32 82" />
            <div className="ml-3">
              <h1 className="mb-2 body-semibold">Unable to initiate payout</h1>
              {!externalPayoutError ? (
                <p className="body-subtext">
                  Payouts can only be made when the total available balance is
                  at least <span className="body-subtext-semibold">$10.00</span>
                </p>
              ) : (
                <>
                  <p className="body-subtext">
                    The{' '}
                    <span className="body-subtext-semibold">Payout amount</span>{' '}
                    entered exceeds the{' '}
                    <span className="body-subtext-semibold">
                      total available balance
                    </span>
                    .
                  </p>
                  <p className="body-subtext">
                    Please enter an amount which is less than or equal to the
                    total available balance (should be at least $10.00).
                  </p>
                </>
              )}
              <div className={classNames(styles.payoutBalance, 'mt-3 ')}>
                <span className="d-flex">
                  Total balance{' '}
                  {!externalPayoutError && (
                    <i
                      className={classNames(
                        'ph-warning-fill align-items-center ml-1',
                        styles.warningBalance
                      )}
                    ></i>
                  )}
                </span>
                {CURRENCY.convertToMainUnit(stats?.total_balance || 0)}
              </div>
              <div className={classNames(styles.payoutBalance)}>
                <span className={styles.dashedBorder} id="holdingInfo">
                  Holding balance
                </span>
                ({CURRENCY.convertToMainUnit(stats?.holding_balance || 0)})
              </div>
              <div
                className={classNames(styles.payoutAvailableBalance, 'mt-2')}
              >
                <span className="d-flex pt-1">Total available balance</span>
                <span className="pt-1">
                  {CURRENCY.convertToMainUnit(availableBalance || 0)}
                </span>
              </div>
            </div>
          </div>
          <UncontrolledTooltip placement="right" target="holdingInfo">
            The funds are on hold to maintain a minimum balance to cover future
            refunds, possibility of negative balance, etc.
          </UncontrolledTooltip>
        </>
      </ModalContainer>
    </div>
  );
};

export default PayoutBalanceError;
