import ErrorMessage from 'components/errorMessage';
import FormLabel from 'components/form-label';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Col, FormGroup, Input, Row } from 'reactstrap';
import { ORGANISATION } from 'state/organisation/type';
import useStore from 'state/store/hooks/useStore';
import { CHECKOUT_HOST } from 'utils/config';
import {
  EMAIL_VALIDATION,
  IMAGE_URL_VALIDATION,
  URL_VALIDATION,
} from 'utils/validation';

interface IProps {
  data: ORGANISATION.IBrandSettings | null;
  logisticsProvider: ORGANISATION.ILogisticsProvider | null;
}

const BrandingSettings = ({ data, logisticsProvider }: IProps): JSX.Element => {
  const { toUpdateStore } = useStore();
  const { register, handleSubmit, errors, setValue } = useForm();
  const {
    name,
    brand_name: brandName,
    contact_email: contactEmail,
    logo_url: logoUrl,
    privacy_policy_url: privacyPolicyUrl,
    tos_url: tosUrl,
  } = data || {};

  useEffect(() => {
    setValue('name', name);
    setValue('brand_name', brandName);
    setValue('contact_email', contactEmail);
    setValue('logo_url', logoUrl);
    setValue('privacy_policy_url', privacyPolicyUrl);
    setValue('tos_url', tosUrl);
  }, [data]);

  // const [brandLogo, setBrandLogo] = useState();

  // const onDrop = async (pictures: Blob[]) => {
  //   if (pictures.length > 0) {
  //     const loadedFile = await fileToBase64(pictures[0]);
  //     setBrandLogo(loadedFile);
  //   }
  // };

  const onFormSubmit = (values: { [x: string]: string }) => {
    if (data) {
      const { id } = data;
      const request = {
        brand_name: values.brand_name || undefined,
        name: values.name || undefined,
        contact_email: values.contact_email || undefined,
        logo_url: values.logo_url || undefined,
        privacy_policy_url: values.privacy_policy_url || undefined,
        tos_url: values.tos_url || undefined,
      };
      toUpdateStore(id, request as ORGANISATION.IBrandSettings);
    }
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className="pt-2">
      <Row>
        <Col sm="6" xs="6">
          <FormGroup>
            <FormLabel label="Store Name (required)" element="name" />
            <Input
              defaultValue={name}
              placeholder="Store Name"
              name="name"
              innerRef={register({ required: true })}
            />
            {errors.name && (
              <ErrorMessage
                isValidation
                message="Please enter a valid store name"
              />
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm="6" xs="6">
          <FormGroup>
            <FormLabel label="Brand Name" element="brand_name" />
            <Input
              defaultValue={brandName || ''}
              placeholder="Brand Name"
              name="brand_name"
              innerRef={register}
            />
            {errors.brand_name && (
              <ErrorMessage
                isValidation
                message="Please enter a valid brand name"
              />
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm="6" xs="6">
          <FormGroup>
            <FormLabel
              label="Contact Email"
              tooltip="Support email for your customers"
              element="contact_email"
            />

            <Input
              defaultValue={contactEmail}
              placeholder="Contact email"
              name="contact_email"
              innerRef={register({
                pattern: EMAIL_VALIDATION,
              })}
            />
            {errors.contact_email && (
              <ErrorMessage
                isValidation
                message="Please enter a valid email address"
              />
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm="6" xs="6">
          <FormGroup>
            <FormLabel
              label="Logo URL"
              tooltip="Logo on the Checkout page"
              element="logo_url"
            />

            <Input
              defaultValue={logoUrl}
              placeholder="Logo image"
              name="logo_url"
              innerRef={register({
                pattern: IMAGE_URL_VALIDATION,
              })}
            />
            {errors.logo_url && (
              <ErrorMessage
                isValidation
                message="Please enter a valid image URL"
              />
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm="6" xs="6">
          <FormGroup>
            <FormLabel
              label="Privacy Policy"
              tooltip="Privacy Policy URL on the Checkout page"
              element="privacy_policy_url"
            />
            <Input
              defaultValue={privacyPolicyUrl}
              placeholder="URL of the Privacy Policy page"
              name="privacy_policy_url"
              innerRef={register({ pattern: URL_VALIDATION })}
            />
            {errors.privacy_policy_url && (
              <ErrorMessage isValidation message="Please enter a valid URL" />
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm="6" xs="6">
          <FormGroup>
            <FormLabel
              label="Terms of Service"
              tooltip="Terms of Service on the Checkout page"
              element="tos_url"
            />
            <Input
              defaultValue={tosUrl}
              placeholder="Terms of Service URL"
              name="tos_url"
              innerRef={register({ pattern: URL_VALIDATION })}
            />
            {errors.tos_url && (
              <ErrorMessage isValidation message="Please enter a valid URL" />
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <Button
            size="sm"
            color="secondary"
            disabled={!data?.sub_domain}
            onClick={() => {
              window.open(
                `http://${data?.sub_domain ?? ''}.${CHECKOUT_HOST}/ach/${
                  logisticsProvider?.logistic_store_identifier ?? ''
                }`,
                '_blank'
              );
            }}
          >
            Preview
          </Button>
          <Button
            size="sm"
            color="primary"
            type="submit"
            className="btn btn-primary"
          >
            Save
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default BrandingSettings;
