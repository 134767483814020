import React, { Dispatch, SetStateAction } from 'react';
import {
  CREATE_PAYOUTS,
  IActiveState,
  PAYOUTTYPE,
  PAYOUT_STAGE,
} from 'components/modals/banking/payouts/payout';
import { BANKING } from 'state/banking/type';
import { IPayoutConfig } from 'components/modals/banking/payouts';
import useBanking from 'state/banking/hooks/useBanking';
import AddBankAccount from './bankDetails';
import PayoutAmount from './payoutAmount';
import Review from '../review';
import Confirmation from '../confirmation';

interface IProps {
  setActiveState: Dispatch<SetStateAction<IActiveState>>;
  activeState: IActiveState;
}

const SelfPayout = ({ setActiveState, activeState }: IProps): JSX.Element => {
  const { banking } = useBanking();
  const { external_account } = banking;
  const hasBankAccount = () => external_account?.receiver_account_details;

  return (
    <>
      {!hasBankAccount() &&
        activeState.activeForm === CREATE_PAYOUTS.BANK_DETAILS && (
          <AddBankAccount
            activeState={activeState}
            setActiveState={setActiveState}
          />
        )}
      {activeState.activeForm === CREATE_PAYOUTS.PAYOUT_DETAILS && (
        <PayoutAmount
          activeState={activeState}
          setActiveState={setActiveState}
        />
      )}
      {activeState.activeForm === CREATE_PAYOUTS.REVIEW && (
        <Review activeState={activeState} setActiveState={setActiveState} />
      )}
      {activeState.activeForm === CREATE_PAYOUTS.CONFIRMATION && (
        <Confirmation
          activeState={activeState}
          setActiveState={setActiveState}
        />
      )}
    </>
  );
};

export default SelfPayout;
