import { show } from "@intercom/messenger-js-sdk";

const setting = {
  color: {
    theme: '#0055e5',
    launcher: '#0055e5',
    launcherText: '#FFF',
  },
  launcher: {
    chatLabel: {
      'en-US': 'Need Help',
    },
  },
};

const SupportForm = {
  setting,
  openForm: (): void => {
    show();
  },
  /* Not implemented */
  prefill: (fields: Record<string, unknown>): void => {},
};

export default SupportForm;
