import React from 'react';
import { useParams } from 'react-router-dom';
import { BANKING } from 'state/banking/type';
import CURRENCY from 'utils/currency';

interface props {
  info: BANKING.IPayout;
}
const PayoutDetails = ({
  info = {} as BANKING.IPayout,
}: props): JSX.Element => {
  const { balTransactionId } = useParams<{ balTransactionId: string }>();

  return (
    <>
      <div className="mb-9" style={{ paddingTop: '18px' }}>
        <h3 className="mb-2 PaymentDetailsHeading">Payout details</h3>
        <hr className="mt-0 mb-10" />
        <div>
          <div className="mb-2">
            <span className="PaymentDetailsSummaryHeading">Transaction ID</span>
            <span className="PaymentDetailsHeadingData">
              {balTransactionId || ''}
            </span>
          </div>
          <div className="mb-2">
            <span className="PaymentDetailsSummaryHeading">Payout amount</span>
            <span className="PaymentDetailsHeadingData">
              {CURRENCY.convertToMainUnit(info?.amount)}
            </span>
          </div>
          <div className="mb-2">
            <span className="PaymentDetailsSummaryHeading">Return Code</span>
            <span className="PaymentDetailsHeadingData">
              {info?.return_code || 'NA'}
            </span>
          </div>
          <div className="mb-2">
            <span className="PaymentDetailsSummaryHeading">Return Date</span>
            <span className="PaymentDetailsHeadingData">
              {info?.return_date || 'NA'}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayoutDetails;
