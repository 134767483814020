import { apiWithOrg } from 'services/api';
import { useStateValue } from '../..';
import {
  action,
  actionFailure,
  actionSuccess,
  CLEAR_CUSTOMER_EXPORT,
  DELETE_CUSTOMER,
  FETCH_CUSTOMERS,
  FETCH_INDIVIDUAL_CUSTOMER,
  fetchCustomersCSV,
  fetchCustomersCSVFailure,
  fetchCustomersCSVSuccess,
  REINSTATE_CONSUMER,
} from '../actions';
import { CONSUMERS } from '../type';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useConsumer = () => {
  const { state, dispatch } = useStateValue();
  const { consumers } = state;

  const toFetchCustomers = async (values: CONSUMERS.ICustomersParams) => {
    dispatch(action(FETCH_CUSTOMERS));
    try {
      const response = await apiWithOrg.get(`/consumer_profiles`, values);
      dispatch(actionSuccess(FETCH_CUSTOMERS, response?.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_CUSTOMERS, e));
    }
  };

  const toFetchIndividualCustomer = async (customer_id: string) => {
    dispatch(action(FETCH_INDIVIDUAL_CUSTOMER));
    try {
      const response = await apiWithOrg.get(
        `/consumer_profiles/${customer_id}`
      );
      dispatch(actionSuccess(FETCH_INDIVIDUAL_CUSTOMER, response?.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_INDIVIDUAL_CUSTOMER, e));
    }
  };

  const toRemoveCustomer = async (customerProfileId: string) => {
    dispatch(action(DELETE_CUSTOMER));
    try {
      const response = await apiWithOrg.remove<{
        items: CONSUMERS.ICustomerProfile;
      }>(`/consumer_profiles/${customerProfileId}`);
      toFetchCustomers({});

      dispatch(actionSuccess(DELETE_CUSTOMER, response?.data.items));
    } catch (e) {
      dispatch(actionFailure(DELETE_CUSTOMER, e));
    }
  };

  // fetch details of Customers
  const toFetchCSVConsumers = async (values: CONSUMERS.IFetchCSVCustomers) => {
    dispatch(fetchCustomersCSV());
    try {
      values.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await apiWithOrg.get('consumer_profiles/export', values);
      if (response.status === 204) {
        dispatch(
          fetchCustomersCSVFailure({
            data: { message: 'No records available for given date range' },
          })
        );
      } else {
        dispatch(fetchCustomersCSVSuccess(response?.data));
      }
    } catch (e) {
      dispatch(fetchCustomersCSVFailure(e));
    }
  };

  const toReinstateConsumer = async (consumerProfileId: string) => {
    dispatch(action(REINSTATE_CONSUMER));
    try {
      const response = await apiWithOrg.put(
        `consumer_profiles/${consumerProfileId}/activate`
      );
      dispatch(actionSuccess(REINSTATE_CONSUMER, response.data));
      return 1;
    } catch (e) {
      dispatch(actionFailure(REINSTATE_CONSUMER, e));
      return 0;
    }
  };

  const toClearCustomersExport = () => {
    dispatch(action(CLEAR_CUSTOMER_EXPORT));
  };

  return {
    consumers,
    toFetchCustomers,
    toFetchCSVConsumers,
    toFetchIndividualCustomer,
    toRemoveCustomer,
    toClearCustomersExport,
    toReinstateConsumer,
  };
};

export default useConsumer;
