import React, { SyntheticEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  Button,
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  Row,
  NavLink,
  TabPane,
  TabContent,
} from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import CopyToClipboard from 'react-copy-to-clipboard';
import { transactionSourceFormatter } from 'utils/source-formatter';
import { formatToTz } from 'utils/date-format';
import BackButton from 'components/backButton';
import JSONPretty from 'react-json-pretty';
import BootstrapTable from 'react-bootstrap-table-next';
import routeConfig from 'routes/config';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import { ToolkitContextType } from 'react-bootstrap-table2-toolkit';
import { webhooksStatusFormatter } from 'utils/status-formatter';
import useEvents from 'state/events/hooks/useEvents';
import { EVENTS } from 'state/events/type';
import styles from './developers.module.scss';

const EventsDetails = (): JSX.Element => {
  const navigate = useNavigate();

  const [eventidcopy, seteventidcopy] = useState(false);
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [btnText, setbtnText] = useState('More');
  const [btnIcon, setbtnIcon] = useState(
    <i className="ph-caret-down-light ph-sm" />
  );
  const [clicked, setClicked] = useState(true);
  const more = 'height:280px;overflow: hidden;font-size:12px';
  const less = 'height:176px;overflow: hidden;font-size:12px';
  const [dataStyle, setDataStyle] = useState(less);

  const { id } = useParams<{ id: string }>();

  const { organisation } = useOrganisation();
  const { activeOrg, settings, activeOrgId } = organisation;

  const { events, toFetchIndividualEvent } = useEvents();

  const TABLE_COLUMNS = [
    {
      text: 'Created date',
      sort: true,
      dataField: 'webhook.create_date',
      formatter: (cell: string) => {
        return (
          <>
            {cell
              ? formatToTz(cell, settings?.timezone, 'MMM dd yyyy, hh:mm a')
              : ''}
          </>
        );
      },
    },
    {
      text: 'URL',
      sort: false,
      dataField: 'webhook.endpoint',
    },

    {
      text: 'Status',
      sort: true,
      dataField: 'webhook.status',
      formatter: webhooksStatusFormatter,
    },
  ];

  const WEBHOOK_TABS = [
    {
      label: 'All',
      filters: [],
    },
    {
      label: 'Succeeded',
      filters: [],
    },
    {
      label: 'Failed',
      filters: [],
    },
  ];

  const tabNames = WEBHOOK_TABS;

  const [activeTab, setActiveTab] = useState(
    (tabNames.length > 0 && tabNames[0].label) || ''
  );

  const rowEvents = {
    onClick: (
      e: SyntheticEvent<Element, Event>,
      row: EVENTS.EventsWebhookdeliveries
    ) => {
      navigate(`../${routeConfig.DEVELOPERS.WEBHOOK.path}/${row.webhook_id}`);
    },
  };

  useEffect(() => {
    if (Object.keys(events?.individualEvent).length !== 0) {
      setIsDataAvailable(true);
    } else {
      setIsDataAvailable(false);
    }
  }, [events?.individualEvent]);

  useEffect(() => {
    if (events.error?.individualEvents) {
      setIsDataAvailable(false);
    }
  }, [events.error]);

  useEffect(() => {
    if (activeOrg && id) {
      toFetchIndividualEvent(id);
    }
  }, [activeOrg]);

  useEffect(() => {
    if (eventidcopy) {
      setTimeout(() => {
        seteventidcopy(false);
      }, 2000);
    }
  }, [eventidcopy]);

  return (
    <>
      <Card className="card-profile border-0">
        <CardBody className="bg-white border-0">
          <BackButton />
          <div className="mb-2 pb-1">
            <span className="TimelineHeading">
              {events?.individualEvent?.name}
            </span>
          </div>
          {/* <div
            className={classNames(
              styles.EventsId,
              'mb-2 d-flex align-items-center'
            )}
          >
            {subHeading}
          </div> */}
          <span
            className={classNames(
              styles.EventsId,
              'mb-2 d-flex align-items-center'
            )}
          >
            {id}
            <CopyToClipboard
              onCopy={() => {
                seteventidcopy(true);
              }}
              text={id || 'NA'}
            >
              {!eventidcopy ? (
                <i className="ph-copy-simple-light ml-1 icon-xl align-middle" />
              ) : (
                <i className="ph-check-circle-fill ml-1 align-middle" />
              )}
            </CopyToClipboard>
          </span>
          <hr className="mt-2 mb-0" />
          <Row className="pt-3 pl-4 pb-3">
            <p className={classNames(styles.EventsSummary, 'pr-5')}>
              <div className={classNames(styles.EventsSummaryHeading)}>
                Date
              </div>{' '}
              <div className={classNames(styles.EventHeadingData)}>
                {events.individualEvent.create_date
                  ? formatToTz(
                      events.individualEvent.create_date,
                      settings?.timezone,
                      'MMM dd, hh:mm a'
                    )
                  : 'na'}
              </div>
            </p>
            <p className={classNames('pl-5', 'pr-5')}>
              <div className={classNames(styles.EventsSummaryHeading)}>
                Source
              </div>
              <div
                className={classNames(
                  styles.summaryResult,
                  styles.SettlementHeadingData
                )}
              >
                {transactionSourceFormatter('E-Commerce')}
              </div>
            </p>
          </Row>
          <div className="mb-4">
            <Row className="pt-3 pb-10 H56">
              <Col className="Height32">
                <span className="TimelineHeading">Event data</span>
              </Col>
            </Row>
            <hr className="mt-0 mb-4" />
          </div>

          {isDataAvailable ? (
            <div>
              <JSONPretty
                id="json-pretty"
                style={{
                  marginLeft: '48px',
                  marginBottom: '0px',
                  fontSize: '12px',
                }}
                valueStyle="color: #1A2538"
                stringStyle="color: #1A2538"
                booleanStyle="color: #1A2538"
                mainStyle={dataStyle}
                data={events.individualEvent.payload}
                space="7"
              ></JSONPretty>
              {clicked ? (
                <Button
                  onClick={() => {
                    setDataStyle(more);
                    setbtnText('Less');
                    setbtnIcon(<i className="ph-caret-up-light ph-sm" />);
                    setClicked(false);
                  }}
                  className={classNames('actionBtn', styles.eventsMoreButton)}
                >
                  {btnText}
                  {btnIcon}
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setDataStyle(less);
                    setbtnText('More');
                    setbtnIcon(<i className="ph-caret-down-light ph-sm" />);
                    setClicked(true);
                  }}
                  className={classNames('actionBtn', styles.eventsLessButton)}
                >
                  {btnText}
                  {btnIcon}
                </Button>
              )}
            </div>
          ) : (
            <div className="pt-2 pb-2">
              <h5 className="d-flex justify-content-center">
                No details available
              </h5>
            </div>
          )}
          <div>
            <div className=" mt-7 pb-1 mb-2">
              <span className="TimelineHeading">
                {'Webhooks subscribed to:'}
              </span>
            </div>
          </div>
          <Nav tabs>
            {tabNames.map((item) => {
              return (
                <>
                  <NavItem>
                    <NavLink
                      className={classNames({
                        active: item?.label === activeTab,
                      })}
                      onClick={() => {
                        setActiveTab(item?.label);
                      }}
                    >
                      {item?.label}
                    </NavLink>
                  </NavItem>
                </>
              );
            })}
          </Nav>
          <TabContent activeTab={activeTab}>
            {tabNames.map((item) => {
              return (
                <TabPane tabId={item?.label} key={item.label}>
                  <ToolkitProvider
                    keyField="id"
                    search
                    data={events.individualEvent.webhook_deliveries || []}
                    columns={TABLE_COLUMNS}
                  >
                    {(props: ToolkitContextType) => (
                      <div>
                        <BootstrapTable
                          wrapperClasses="table-responsive"
                          {...props.baseProps}
                          hover
                          condensed
                          rowEvents={rowEvents}
                          bordered={false}
                          noDataIndication={
                            <p className="text-center text-regular">
                              No results found
                            </p>
                          }
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </TabPane>
              );
            })}
          </TabContent>
        </CardBody>
      </Card>
    </>
  );
};
export default EventsDetails;
