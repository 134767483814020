import React, { useEffect, useState } from 'react';
import usePayment from 'state/payment/hooks/usePayment';
import GatewaySummary from './gatewayTransactionCards/summary';
import GatewayPaymentDetails from './gatewayTransactionCards/paymentDetails';
import GatewayPaymentMethod from './gatewayTransactionCards/paymentMethod';

const GatewayTransactionDetails = (): JSX.Element => {
  // checking data availability
  const [isDataAvailable, setIsDataAvailable] = useState(false);

  const { payment } = usePayment();

  useEffect(() => {
    if (Object.keys(payment?.gatewayTransaction).length) {
      setIsDataAvailable(true);
    } else {
      setIsDataAvailable(false);
    }
  }, [payment.gatewayTransaction]);

  useEffect(() => {
    if (payment.error?.fetchGatewayTransaction) {
      setIsDataAvailable(false);
    }
  }, [payment.error]);

  return (
    <>
      {isDataAvailable ? (
        <>
          <GatewaySummary info={payment?.gatewayTransaction} />
          <GatewayPaymentDetails info={payment?.gatewayTransaction} />
          <GatewayPaymentMethod info={payment?.gatewayTransaction} />
        </>
      ) : (
        <div className="pt-2 pb-2">
          <h5 className="d-flex justify-content-center">
            No details available
          </h5>
        </div>
      )}
    </>
  );
};

export default GatewayTransactionDetails;
