import { AxiosInstance, AxiosResponse } from 'axios';
import { SPARC_BASE, ENV, MSO_GATEWAY_BASE_URL } from 'utils/config';
import {
  createApiInstance,
  verifyOrgHeader,
  authInterceptor,
  verifyEntityHeader,
} from 'utils/api';
// import { v3 as uuidv3 } from 'uuid';
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';
import { treezAuthAPIBaseUrl } from 'utils/urlGenerator';

const myuuid = uuidv4();

export const TREEZ_AUTH_BASE = treezAuthAPIBaseUrl(ENV || 'production');

/* A wrapper around the Axios library that allows us to make HTTP requests to our backend */
class MsoApi {
  axiosInst: AxiosInstance;

  hasEntityId = false;

  hasAuth = true;

  setEntityHeader: (arg: string) => void;

  constructor(withEntity = false, hasAuth = true) {
    this.hasEntityId = withEntity;
    this.hasAuth = hasAuth;
    this.axiosInst = createApiInstance();

    this.setEntityHeader = (entityId) => {
      if (this.hasEntityId)
        this.axiosInst.defaults.headers.common['entity-id'] = entityId;
      //   this.axiosInst.defaults.headers.common['user-agent'] = 'Swifter';
    };
    this.axiosInst.defaults.headers.common.IdempotencyId = uuidv4();

    if (this.hasEntityId) {
      this.axiosInst.defaults.baseURL = this.axiosInst.defaults.baseURL;
      this.axiosInst.interceptors.request.use(verifyEntityHeader, (error) => {
        Promise.reject(error);
      });
    }

    if (this.hasAuth)
      this.axiosInst.interceptors.request.use(authInterceptor, (error) => {
        Promise.reject(error);
      });
    // this.axiosInst.defaults.headers.common['Access-Control-Allow-Origin'] =
    //   'http://localhost:3000';
  }

  changeAPIBase = (newBaseUrl: string): void => {
    this.axiosInst.defaults.baseURL = newBaseUrl;
  };

  get = <T>(
    url: string,
    data?: Record<string, unknown>,
    headers?: Record<string, unknown>
  ): Promise<AxiosResponse<T>> => {
    const options = { withCredentials: false, headers };
    return this.axiosInst.get(url, { params: data, ...options });
  };

  post = <T>(
    url: string,
    data?: Record<string, unknown>,
    headers?: Record<string, unknown>,
    withCredentialsCheck?: boolean
  ): Promise<AxiosResponse<T>> => {
    const options = { withCredentials: false, headers };
    return this.axiosInst.post(url, data, options);
  };

  put = <T>(
    url: string,
    data?: Record<string, unknown>,
    headers?: Record<string, unknown>
  ): Promise<AxiosResponse<T>> => {
    const options = { withCredentials: false, headers };
    return this.axiosInst
      .put(url, data, options)
      .then((res) => res.data)
      .catch((err) => err.response);
  };

  remove = <T>(
    url: string,
    headers?: Record<string, unknown>
  ): Promise<AxiosResponse<T>> => {
    const options = { withCredentials: false, headers };
    return this.axiosInst.delete(url, options);
  };
}

export default MsoApi;
