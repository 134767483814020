export const FETCH_BANKING_STATS = 'banking/FETCH_BANKING_STATS';
export const FETCH_BANKING_STATS_SUCCESS =
  'banking/FETCH_BANKING_STATS_SUCCESS';
export const FETCH_BANKING_STATS_FAILURE =
  'banking/FETCH_BANKING_STATS_FAILURE';

export const FETCH_STATEMENT_TOTAL_BALANCE = 'banking/FETCH_STATEMENT_TOTAL_BALANCE';
export const FETCH_STATEMENT_TOTAL_BALANCE_SUCCESS =
  'banking/FETCH_STATEMENT_TOTAL_BALANCE_SUCCESS';
export const FETCH_STATEMENT_TOTAL_BALANCE_FAILURE =
  'banking/FETCH_STATEMENT_TOTAL_BALANCE_FAILURE';
  

export const FETCH_DAILY_BANKING_SUMMARIES =
  'banking/FETCH_DAILY_BANKING_SUMMARIES';
export const FETCH_DAILY_BANKING_SUMMARIES_SUCCESS =
  'banking/FETCH_DAILY_BANKING_SUMMARIES_SUCCESS';
export const FETCH_DAILY_BANKING_SUMMARIES_FAILURE =
  'banking/FETCH_DAILY_BANKING_SUMMARIES_FAILURE';

export const FETCH_TOTAL_BALANCE_DETAILS =
  'banking/FETCH_TOTAL_BALANCE_DETAILS';
export const FETCH_TOTAL_BALANCE_DETAILS_SUCCESS =
  'banking/FETCH_TOTAL_BALANCE_DETAILS_SUCCESS';
export const FETCH_TOTAL_BALANCE_DETAILS_FAILURE =
  'banking/FETCH_TOTAL_BALANCE_DETAILS_FAILURE';

export const FETCH_CREDIT_DETAILS = 'banking/FETCH_CREDIT_DETAILS';
export const FETCH_CREDIT_DETAILS_SUCCESS =
  'banking/FETCH_CREDIT_DETAILS_SUCCESS';
export const FETCH_CREDIT_DETAILS_FAILURE =
  'banking/FETCH_CREDIT_DETAILS_FAILURE';

export const FETCH_DEBIT_DETAILS = 'banking/FETCH_DEBIT_DETAILS';
export const FETCH_DEBIT_DETAILS_SUCCESS =
  'banking/FETCH_DEBIT_DETAILS_SUCCESS';
export const FETCH_DEBIT_DETAILS_FAILURE =
  'banking/FETCH_DEBIT_DETAILS_FAILURE';

export const FETCH_EXPORT = 'banking/FETCH_EXPORT';
export const FETCH_EXPORT_SUCCESS = 'banking/FETCH_EXPORT_SUCCESS';
export const FETCH_EXPORT_FAILURE = 'banking/FETCH_EXPORT_FAILURE';

export const FETCH_BANKING_SUMMARY_EXPORT = 'banking/FETCH_BANKING_SUMMARY_EXPORT';
export const FETCH_BANKING_SUMMARY_EXPORT_SUCCESS = 'banking/FETCH_BANKING_SUMMARY_EXPORT_SUCCESS';
export const FETCH_BANKING_SUMMARY_EXPORT_FAILURE = 'banking/FETCH_BANKING_SUMMARY_EXPORT_FAILURE';

export const INITIATE_SELF_PAYOUT = 'banking/INITIATE_SELF_PAYOUT';
export const INITIATE_SELF_PAYOUT_SUCCESS =
  'banking/INITIATE_SELF_PAYOUT_SUCCESS';
export const INITIATE_SELF_PAYOUT_FAILURE =
  'banking/INITIATE_SELF_PAYOUT_FAILURE';
export const RESET_PAYOUT = 'banking/RESET_PAYOUT';

export const INITIATE_EXTERNAL_PAYOUT = 'banking/INITIATE_EXTERNAL_PAYOUT';
export const INITIATE_EXTERNAL_PAYOUT_SUCCESS =
  'banking/INITIATE_EXTERNAL_PAYOUT_SUCCESS';
export const INITIATE_EXTERNAL_PAYOUT_FAILURE =
  'banking/INITIATE_EXTERNAL_PAYOUT_FAILURE';

export const FETCH_EXTERNAL_ACCOUNT = 'banking/FETCH_EXTERNAL_ACCOUNT';
export const FETCH_EXTERNAL_ACCOUNT_SUCCESS =
  'banking/FETCH_EXTERNAL_ACCOUNT_SUCCESS';
export const FETCH_EXTERNAL_ACCOUNT_FAILURE =
  'banking/FETCH_EXTERNAL_ACCOUNT_FAILURE';
export const ADD_BANK_ACCOUNT = 'banking/ADD_BANK_ACCOUNT';
export const ADD_BANK_ACCOUNT_SUCCESS = 'banking/ADD_BANK_ACCOUNT_SUCCESS';
export const ADD_BANK_ACCOUNT_FAILURE = 'banking/ADD_BANK_ACCOUNT_FAILURE';

export const ADD_VENDOR_BANK_ACCOUNT = 'banking/ADD_VENDOR_BANK_ACCOUNT';
export const ADD_VENDOR_BANK_ACCOUNT_SUCCESS =
  'banking/ADD_VENDOR_BANK_ACCOUNT_SUCCESS';
export const ADD_VENDOR_BANK_ACCOUNT_FAILURE =
  'banking/ADD_VENDOR_BANK_ACCOUNT_FAILURE';
export const RESET_ADD_VENDOR_ACCOUNT = 'banking/RESET_ADD_VENDOR_ACCOUNT';
export const CLEAR_EXPORT = 'banking/CLEAR_EXPORT';
export const CLEAR_ADD_BANK_ACCOUNT = 'banking/CLEAR_ADD_BANK_ACCOUNT';

export const FETCH_PAYOUT_DETAILS = 'banking/FETCH_PAYOUT_DETAILS';
export const FETCH_PAYOUT_DETAILS_SUCCESS =
  'banking/FETCH_PAYOUT_DETAILS_SUCCESS';
export const FETCH_PAYOUT_DETAILS_FAILURE =
  'banking/FETCH_PAYOUT_DETAILS_FAILURE';

export const FETCH_PAYOUTS = 'banking/FETCH_PAYOUTS';
export const FETCH_PAYOUTS_SUCCESS = 'banking/FETCH_PAYOUTS_SUCCESS';
export const FETCH_PAYOUTS_FAILURE = 'banking/FETCH_PAYOUTS_FAILURE';
export const SET_PAYOUT_AMOUNT = 'banking/SET_PAYOUT_AMOUNT';
export const SET_VENDOR_ID = 'banking/SET_VENDOR_ID';
export const SET_VENDOR_ACCOUNT = 'banking/SET_VENDOR_ACCOUNT';

export const RESET_PAYOUT_FLOW = 'banking/RESET_PAYOUT_FLOW';
export const RESET_PAYOUT_SUCCESS = 'banking/RESET_PAYOUT_SUCCESS';

export const LIST_VENDORS = 'banking/LIST_VENDORS';
export const LIST_VENDORS_SUCCESS = 'banking/LIST_VENDORS_SUCCESS';
export const LIST_VENDORS_FAILURE = 'banking/LIST_VENDORS_FAILURE';

export const CREATE_VENDOR = 'banking/CREATE_VENDOR';
export const CREATE_VENDOR_SUCCESS = 'banking/CREATE_VENDOR_SUCCESS';
export const CREATE_VENDOR_FAILURE = 'banking/CREATE_VENDOR_FAILURE';

export const UPDATE_VENDOR = 'banking/UPDATE_VENDOR';
export const UPDATE_VENDOR_SUCCESS = 'banking/UPDATE_VENDOR_SUCCESS';
export const UPDATE_VENDOR_FAILURE = 'banking/UPDATE_VENDOR_FAILURE';

export const SET_VENDOR_NOTES = 'banking/SET_VENDOR_NOTES';

export const GET_VENDOR_PAYOUT_ACCOUNT_LIST =
  'banking/GET_VENDOR_PAYOUT_ACCOUNT_LIST';
export const GET_VENDOR_PAYOUT_ACCOUNT_LIST_SUCCESS =
  'banking/GET_VENDOR_PAYOUT_ACCOUNT_LIST_SUCCESS';
export const GET_VENDOR_PAYOUT_ACCOUNT_LIST_FAILURE =
  'banking/GET_VENDOR_PAYOUT_ACCOUNT_LIST_FAILURE';

export const GET_SESSION_STEP_UP_AUTH = 'banking/GET_SESSION_STEP_UP_AUTH';
export const GET_SESSION_STEP_UP_AUTH_SUCCESS =
  'banking/GET_SESSION_STEP_UP_AUTH_SUCCESS';
export const GET_SESSION_STEP_UP_AUTH_FAILURE =
  'banking/GET_SESSION_STEP_UP_AUTH_FAILURE';

export const STEP_UP_VERIFY = 'banking/STEP_UP_VERIFY';
export const STEP_UP_VERIFY_SUCCESS = 'banking/STEP_UP_VERIFY_SUCCESS';
export const STEP_UP_VERIFY_FAILURE = 'banking/STEP_UP_VERIFY_FAILURE';

export const RESET_VENDOR_UPDATE = 'banking/RESET_VENDOR_UPDATE';
export const RESET_VENDOR_ADD = 'banking/RESET_VENDOR_ADD';

export const SET_INVOICE_DETAILS = 'banking/SET_INVOICE_DETAILS';

export const SET_PAYOUT_TYPE = 'banking/SET_PAYOUT_TYPE';

export const FETCH_PAYOUTS_SUMMARY_DATA = 'banking/FETCH_PAYOUTS_SUMMARY_DATA';
export const FETCH_PAYOUTS_SUMMARY_DATA_SUCCESS =
  'banking/FETCH_PAYOUTS_SUMMARY_DATA_SUCCESS';
export const FETCH_PAYOUTS_SUMMARY_DATA_FAILURE =
  'banking/FETCH_PAYOUTS_SUMMARY_DATA_FAILURE';

export const FETCH_TOTAL_FAILED_AMOUNT = 'banking/FETCH_TOTAL_FAILED_AMOUNT';
export const FETCH_TOTAL_FAILED_AMOUNT_SUCCESS =
  'banking/FETCH_TOTAL_FAILED_AMOUNT_SUCCESS';
export const FETCH_TOTAL_FAILED_AMOUNT_FAILURE =
  'banking/FETCH_TOTAL_FAILED_AMOUNT_FAILURE';

export const LIST_FAILED_TRANSACTIONS = 'banking/LIST_FAILED_TRANSACTIONS';
export const LIST_FAILED_TRANSACTIONS_SUCCESS =
  'banking/LIST_FAILED_TRANSACTIONS_SUCCESS';
export const LIST_FAILED_TRANSACTIONS_FAILURE =
  'banking/LIST_FAILED_TRANSACTIONS_FAILURE';

export const LIST_WRITE_OFF_TRANSACTIONS =
  'banking/LIST_WRITE_OFF_TRANSACTIONS';
export const LIST_WRITE_OFF_TRANSACTIONS_SUCCESS =
  'banking/LIST_WRITE_OFF_TRANSACTIONS_SUCCESS';
export const LIST_WRITE_OFF_TRANSACTIONS_FAILURE =
  'banking/LIST_WRITE_OFF_TRANSACTIONS_FAILURE';

export const INITIATE_BATCH_RETRY = 'banking/INITIATE_BATCH_RETRY';
export const INITIATE_BATCH_RETRY_SUCCESS =
  'banking/INITIATE_BATCH_RETRY_SUCCESS';
export const INITIATE_BATCH_RETRY_FAILURE =
  'banking/INITIATE_BATCH_RETRY_FAILURE';

export const INITIATE_WRITE_OFFS = 'banking/INITIATE_WRITE_OFFS';
export const INITIATE_WRITE_OFFS_SUCCESS =
  'banking/INITIATE_WRITE_OFFS_SUCCESS';
export const INITIATE_WRITE_OFFS_FAILURE =
  'banking/INITIATE_WRITE_OFFS_FAILURE';

export const RECORD_CASH = 'banking/RECORD_CASH';
export const RECORD_CASH_SUCCESS = 'banking/RECORD_CASH_SUCCESS';
export const RECORD_CASH_FAILURE = 'banking/RECORD_CASH_FAILURE';

export const action = <T>(type: string, payload?: T): IAction => ({
  type,
  payload,
});

export const actionSuccess = <T>(type: string, payload: T): IAction => ({
  type: `${type}_SUCCESS`,
  payload,
});

export const actionFailure = <T>(type: string, payload: T): IAction => ({
  type: `${type}_FAILURE`,
  payload,
});
