import {
  ChartOptions,
  Chart,
  ChartTypeRegistry,
  ScatterDataPoint,
  BubbleDataPoint,
  TimeUnit,
  TooltipItem,
} from 'chart.js';
import { add, isBefore, sub } from 'date-fns';
import { STATS } from 'state/stats/type';
import CURRENCY from 'utils/currency';
import { getTooltipWeekRange } from 'utils/dashboard';
import { ORG_TIMEZONE_DEFAULT } from 'utils/constants';
import { formatToTz } from 'utils/date-format';
import { DateTime } from 'luxon';
import styles from '../dashboard.module.scss';

/**
 * Retrieves a specific value from a `STATS.ValuePoint` object based on the payment method parameter
 *
 * @param param - The type of graph for which the value is to be retrieved. It should be one of the values from `STATS.graphType`
 * @param valueObj - An object representing a data point in the dataset
 *
 * @returns The value corresponding to the graph type
 */
const getPaymentMethodValueFromObject = (
  param: STATS.graphType,
  valueObj: STATS.ValuePoint
) => {
  switch (param) {
    case STATS.graphType.payments:
      return valueObj?.transaction_count || 0;
    case STATS.graphType.gross_amount:
      return valueObj?.gross_amount || 0;
    case STATS.graphType.aov:
      return valueObj?.transaction_count
        ? valueObj?.gross_amount / valueObj?.transaction_count
        : 0;
  }
};

/**
 * Generates tooltip callbacks for chart tooltips based on the chart type - multiline vs single line chart
 * @param isMultiChart - A boolean indicating whether the chart is a multi-chart.
 * @param datasets - An array of dataset objects, where each object contains a `type` and a `value` property.
 * @param param - The type of graph being displayed, which affects how the tooltip values are formatted. It should be one of the values from `STATS.graphType`.
 *
 * @returns An object with callback functions for tooltip
 */
const tooltipCallbacks = (
  isMultiChart: boolean,
  datasets: { type: string; value: STATS.ValuePoint[] }[],
  param: STATS.graphType
) => {
  return isMultiChart
    ? {
        afterTitle: (tooltipItem: TooltipItem<keyof ChartTypeRegistry>[]) => {
          const dataIndex = tooltipItem[0].dataIndex;
          const dataObject = datasets.find(({ type }) => type === 'Total')
            ?.value?.[dataIndex];
          const totalValue = dataObject
            ? getPaymentMethodValueFromObject(param, dataObject)
            : 0;

          return param !== STATS.graphType.payments
            ? CURRENCY.convertToMainUnit(totalValue)
            : String(totalValue);
        },
        label:
          param !== STATS.graphType.payments
            ? (tooltipItem: TooltipItem<keyof ChartTypeRegistry>) => {
                return `${
                  tooltipItem?.dataset?.label
                }:  ${CURRENCY.convertToMainUnit(tooltipItem?.parsed?.y)}`;
              }
            : undefined,
      }
    : {
        label: (tooltipItem: TooltipItem<keyof ChartTypeRegistry>) => {
          const value = tooltipItem.parsed.y;
          return param !== STATS.graphType.payments
            ? CURRENCY.convertToMainUnit(value)
            : String(value);
        },
        footer:
          param !== STATS.graphType.payments && param !== STATS.graphType.aov
            ? (tooltipItem: TooltipItem<keyof ChartTypeRegistry>[]) => {
                const index = tooltipItem[0].dataIndex;
                const data = datasets.find(
                  ({ type }) => type === tooltipItem[0].dataset?.label
                );
                const point = data?.value?.[index];
                let tip;
                let fee;

                if (point && 'tip' in point && 'fee' in point) {
                  tip = point?.tip;
                  fee = point?.fee;
                }
                return `Tip  : ${CURRENCY.convertToMainUnit(
                  tip
                )}\nFee : ${CURRENCY.convertToMainUnit(fee)}`;
              }
            : undefined,
      };
};

export const getChartOptions = (
  activeFreq: STATS.IIntervalType,
  timeAxisStepSize: number,
  timeAxisUnit: TimeUnit,
  maxXTicksLimit: number,
  param: STATS.graphType,
  datasets: { type: string; value: STATS.ValuePoint[] }[],
  timezone = ORG_TIMEZONE_DEFAULT,
  isMultiLineChart = false
): ChartOptions => {
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  const adapter = require('chartjs-adapter-luxon').default; // skipcq: JS-0356

  return {
    interaction: {
      intersect: false,
      mode: 'index',
    },
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: isMultiLineChart,
        position: 'bottom',
        align: 'end',
        onClick: () => {},
        labels: {
          boxHeight: 16,
          boxWidth: 20,
          font: {
            size: 14,
            weight: 'normal',
            lineHeight: 2,
          },
          padding: 32,
        },
      },
      tooltip: {
        intersect: false,
        backgroundColor: styles.tooltipBgColor,
        titleColor: styles.tooltipTextColor,
        titleFont: {
          size: Number(styles.tooltipFontSize),
          weight: 'bold',
        },
        bodyColor: styles.tooltipTextColor,
        bodyFont: {
          size: Number(styles.tooltipFontSize),
          weight: isMultiLineChart ? 'normal' : 'bold',
        },
        footerColor: styles.tooltipTextColor,
        footerMarginTop: 18,
        footerFont: {
          size: Number(styles.tooltipFontSize),
          weight: 'normal',
        },
        displayColors: isMultiLineChart,
        titleMarginBottom: Number(styles.tooltipTitleMarginBottom),
        padding: {
          top: Number(styles.tooltipPaddingY),
          right: Number(styles.tooltipPaddingX),
          bottom: Number(styles.tooltipPaddingY),
          left: Number(styles.tooltipPaddingX),
        },
        filter: (item) => item.dataset?.label != 'Total',
        callbacks: {
          title: (tooltipItem) => {
            const date = new Date(tooltipItem?.[0]?.parsed?.x)?.toISOString();
            switch (activeFreq) {
              case 'hourly':
                return formatToTz(date, timezone, 'd MMM, hh:mm a');
              case 'weekly':
                return getTooltipWeekRange(date);
              case 'monthly':
                return formatToTz(date, timezone, 'd MMM yy');
              case 'daily':
              default:
                return formatToTz(date, timezone, 'd MMM');
            }
          },
          ...tooltipCallbacks(isMultiLineChart, datasets, param),
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
      line: {
        borderColor: styles.chartLineColor,
        borderWidth: Number(styles.chartLineWidth),
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: styles.chartGridColor,
          borderColor: styles.chartGridColor,
          drawTicks: false,
        },
        ticks: {
          precision: param === STATS.graphType.payments ? 0 : undefined,
          color: styles.chartAxisTicksColor,
          padding: Number(styles.chartAxisTicksPadding),
          callback: (value) => {
            return Number(value) > 0 && param !== STATS.graphType.payments
              ? CURRENCY.getCompactNotation(Number(value))
              : value;
          },
        },
      },
      x: {
        type: 'timeseries',
        // min: format(new Date(dateFrom), 'dd MMM yyyy'),
        // max: format(new Date(dateTo), 'dd MMM yyyy'),
        time: {
          minUnit: 'second',
          unit: timeAxisUnit,
          stepSize: timeAxisStepSize,
          round: 'second',
          displayFormats: {
            minute: 'hh:mm a',
            hour: 'hh a',
            day: 'd MMM',
            month: 'MMM',
            year: 'YYYY',
            week: 'd MMM',
          },
          parser: (value: unknown) => {
            return new Date(value as string).valueOf();
          },
        },
        adapters: {
          date: {
            zone: timezone,
          },
        },
        grid: {
          color: styles.chartGridColor,
          borderColor: styles.chartGridColor,
          drawTicks: false,
        },
        ticks: {
          autoSkip: false,
          source: 'data',
          color: styles.chartAxisTicksColor,
          padding: Number(styles.chartAxisTicksPadding),
          maxTicksLimit: maxXTicksLimit,
        },
      },
    },
  };
};

export const verticalAnnotation = {
  id: 'tooltipVerticalAnnotation',
  beforeDraw: (
    chart: Chart<
      keyof ChartTypeRegistry,
      (number | ScatterDataPoint | BubbleDataPoint)[],
      unknown
    >
  ): void => {
    const activeEl = chart?.tooltip?.getActiveElements();
    if (activeEl?.length) {
      const { ctx } = chart;
      const activePoint = activeEl[0];

      ctx.beginPath();
      ctx.setLineDash([6, 6]);
      ctx.lineWidth = 1;
      ctx.strokeStyle = styles.chartVerticalHoverLineColor;
      ctx.moveTo(activePoint.element.x, chart.chartArea?.top);
      ctx.lineTo(activePoint.element.x, chart.chartArea?.bottom);
      ctx.stroke();
      ctx.restore();
    }
  },
};

export function timeUnitConverter(unit: STATS.IIntervalType) {
  if (unit === STATS.IIntervalType.DAILY) {
    return 'day';
  }
  if (unit === STATS.IIntervalType.HOURLY) {
    return 'hour';
  }
  if (unit === STATS.IIntervalType.MONTHLY) {
    return 'month';
  }
  if (unit === STATS.IIntervalType.WEEKLY) {
    return 'week';
  }
  return 'day';
}

// add zero valued data points for the points where no data is available
/**
 * It takes a data object with x, y, and value properties, and fills in the gaps in the x array with
 * zeros
 * @param data - { x: Date[]; y: number[]; value: STATS.ValuePoint[] }
 * @param {string} dateFrom - The start date of the data
 * @param {string} dateTo - The end date of the data you want to fill in.
 * @param {'day' | 'hour' | 'month' | 'week'} timeUnit - 'day' | 'hour' | 'month' | 'week'
 * @param timezone - The timezone of the data.
 * @returns An object with three properties: x, y, and value.
 */
export function fillInTheGapsUtil(
  data: { x: Date[]; y: number[]; value: STATS.ValuePoint[] },
  dateFrom: string,
  dateTo: string,
  timeUnit: 'day' | 'hour' | 'month' | 'week',
  timezone = ORG_TIMEZONE_DEFAULT
) {
  // getting the starting and ending point for the loop to create data points

  const min = DateTime.fromISO(dateFrom, { zone: timezone })
    .startOf(timeUnit)
    .toJSDate();

  const max = DateTime.fromISO(dateTo, { zone: timezone })
    .endOf(timeUnit)
    .toJSDate();

  const assoc: Record<string, number> = {};
  const assocValue: Record<string, STATS.ValuePoint> = {};
  for (let i = 0; i < data.x.length; i += 1) {
    /* Store all in assoc array */
    const index = DateTime.fromJSDate(data.x[i], { zone: 'UTC' }).toISO();
    if (index) {
      assoc[index] = data.y[i];
      assocValue[index] = data.value[i];
    }
  }

  const newX: Date[] = [];
  const newY: number[] = [];
  const valueFilled: STATS.ValuePoint[] = [];

  const isMinDST = DateTime.fromJSDate(min, { zone: timezone }).startOf(
    timeUnit
  ).isInDST;

  const isMaxDST = DateTime.fromJSDate(max, { zone: timezone }).startOf(
    timeUnit
  ).isInDST;

  /* Looping through the dates from the start date to the end date, and adding a zero value for each
  date that is not represented in the data. */
  for (let d = min; isBefore(d, max); d = add(d, { [`${timeUnit}s`]: 1 })) {
    let key = d;
    const isDst = DateTime.fromJSDate(d, { zone: timezone }).startOf(
      timeUnit
    ).isInDST;
    /* Checking if the start and end dates are in DST, and if they are, it is adding an hour to the
    date if it is not in DST, and subtracting an hour if it is in DST. */
    if (isMaxDST && isMinDST) {
      key = !isDst ? add(d, { hours: 1 }) : d;
    } else if (isMinDST) {
      key = !isDst ? add(d, { hours: 1 }) : d;
    } else if (isMaxDST) {
      key = isDst ? sub(d, { hours: 1 }) : d;
    } else {
      key = isDst ? sub(d, { hours: 1 }) : d;
    }

    newX.push(key);

    const keystr = DateTime.fromJSDate(key, { zone: 'UTC' }).toISO() || '';

    /* Checking if the date is represented in the data, and if it is not, it is adding a zero
    value for that date. */
    if (keystr) {
      if (!assoc[keystr]) {
        // const temp: STATS.IDataPointValue = {};
        newY.push(0);
        valueFilled.push({} as STATS.ValuePoint);
      } else {
        newY.push(assoc[keystr]);
        valueFilled.push(assocValue[keystr]);
      }
    }
  }

  return { x: newX, y: newY, value: valueFilled };
}

/**
 * Each entry in this object defines the colors used for a specific dataset in the chart.
 *
 * @type {Record<string, Record<string, unknown>>}
 * An object where:
 *  - The keys are dataset names ('Total', 'ACH', 'CATM', 'Debit').
 *  - The values are objects containing color settings for that dataset/category:
 *    - `borderColor`: line color.
 *    - `backgroundColor`: chart background color.
 */
export const multiLineColorConfig: Record<string, Record<string, unknown>> = {
  Total: {
    borderColor: '#4080EC',
    backgroundColor: '#4080EC',
  },
  ACH: {
    borderColor: '#8ED2BA',
    backgroundColor: '#8ED2BA',
  },
  CATM: {
    borderColor: '#F49191',
    backgroundColor: '#F49191',
  },
  Debit: {
    borderColor: '#FDC25F',
    backgroundColor: '#FDC25F',
  },
};
