import classNames from 'classnames';
import DatePicker from 'components/calender/date';
import React from 'react';
import { Input } from 'reactstrap';
import { formatDate, toIsoStringWithTz } from 'utils/date-format';
import styles from './filters.module.scss';
import { IFilterConfig, IFilterConfigDate } from './types';

interface IProps {
  item: IFilterConfig;
  setSelected: (arg1: string, arg2: boolean) => void;
  setValue: (arg1: string, arg2: number, arg3: string) => void;
}

const DateFilter = ({ item, setSelected, setValue }: IProps): JSX.Element => {
  const { selected, key, label, data } = item;
  const { values } = data as IFilterConfigDate;
  const dateFrom =
    values[0] ||
    toIsoStringWithTz(new Date(new Date().setDate(new Date().getDate() - 30)));
  const dateTo =
    values[1] ||
    toIsoStringWithTz(new Date(new Date().setDate(new Date().getDate())));
  const applyDateFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(key, e.currentTarget.checked);
    setValue(key, 0, dateFrom);
    setValue(key, 1, dateTo);
  };
  return (
    <>
      <div className={classNames(styles.filterMenu_item)}>
        <Input
          data-testid="date-checkbox"
          type="checkbox"
          data-cy="dateFilters"
          className={classNames(styles.checkbox)}
          checked={selected}
          onChange={(e) => {
            //   toggleDate();
            applyDateFilter(e);
          }}
          id={label}
        />
        <label htmlFor={label} className={classNames('body-subtext-regular')}>
          {label}
        </label>
      </div>
      {selected && (
        <div className={classNames(styles.datepickerContainer)}>
          <DatePicker
            initialValue={dateFrom ? formatDate(dateFrom, 'MM-dd-yyyy') : ''}
            setDate={(val: string) => setValue(key, 0, val)}
            beforeDate={formatDate(dateTo || '', "MMM dd yyyy")}
            format="MM-DD-YYYY"
          />
          <small className="text-muted px-2">-</small>
          <DatePicker
            initialValue={dateTo ? formatDate(dateTo, 'MM-dd-yyyy') : ''}
            setDate={(val: string) => setValue(key, 1, val)}
            isEndDate
            afterDate={formatDate(dateFrom || '', "MMM dd yyyy")}
            format="MM-DD-YYYY"
          />
        </div>
      )}
    </>
  );
};
export default DateFilter;
