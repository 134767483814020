import React, { useEffect } from 'react';
import { EVENT_SIGN_IN_SUCCESS } from 'services/analytics/events';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import { IOrgStatus } from 'state/organisation/type';
import URL from 'utils/url';
import { TRACK } from './services/analytics';
import useAuth from './state/auth/hooks/useAuth';

const Tracking = (): JSX.Element => {
  const { auth } = useAuth();
  const { user } = auth;
  const { organisation } = useOrganisation();
  const { activeOrg } = organisation;

  useEffect(() => {
    TRACK.initialize();
  }, []);

  useEffect(() => {
    // sign in of new/existing user
    if (URL.parseOrgId(window.location.pathname) && auth.loggedIn) {
      TRACK.identify(user.user_info.email, {
        last_login: new Date().toISOString().replace(/Z/, '+00:00'),
      });
      TRACK.event(EVENT_SIGN_IN_SUCCESS, auth?.isSandbox);
    }
  }, [auth.loggedIn]);

  useEffect(() => {
    // account ID naming for Swifter stores
    const accountData = activeOrg?.short_identifier
      ? {
          id: `sftr_${activeOrg.short_identifier}`,
          store_id: activeOrg?.brand_settings?.store_identifier,
          live: activeOrg?.is_payment_enabled || false,
          create_date: activeOrg?.create_date,
        }
      : undefined;

    if (user && user.user_info) {
      TRACK.identify(
        user.user_info.email,
        {
          user_id: user.id,
          create_date: user.user_info?.create_date || null,
          roles: user.roles.map((role) => role.role),
        },
        accountData
      );
    }

    if (activeOrg?.status && activeOrg?.status !== IOrgStatus.ACTIVATED) {
      localStorage.setItem('awaiting_activation', 'true');
    }
  }, [activeOrg]);

  return (
    <></>
  );
};

export default Tracking;
