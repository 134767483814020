import React, { useState } from 'react';
import BackButton from 'components/backButton';
import CopyIDToClipboard from 'components/copyToClipboard';
import {
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { WEBHOOKS } from 'state/webhooks/type';
import classNames from 'classnames';
import DeleteModal from 'components/modals/delete';
import { useNavigate } from 'react-router-dom';
import routeConfig from 'routes/config';
import useWebhooks from 'state/webhooks/hooks/useWebhooks';
import { customerStatusFormatter } from 'utils/status-formatter';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import RevealSecret from './revealSecret';
import styles from '../webhooks.module.scss';

interface IProps {
  info: WEBHOOKS.IWebhook;
}

const Summary = ({ info }: IProps) => {
  const { id, endpoint, description, status, event } = info;
  const [deleteWebhook, setDeleteWebhook] = useState(false);
  const { toDeleteWebhook } = useWebhooks();
  const navigate = useNavigate();
  const {
    organisation: { activeOrgId },
  } = useOrganisation();

  const handleDeleteWebhook = () => {
    toDeleteWebhook(id);
    navigate(
      `/${activeOrgId}/${routeConfig.DEVELOPERS.layout}/${routeConfig.DEVELOPERS.WEBHOOK.path}`
    );
  };

  return (
    <>
      <BackButton />
      <div className={styles.webhookSummaryHeader}>
        <h1>{endpoint}</h1>
        {description && <p className="mt-1">{description}</p>}
      </div>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <CopyIDToClipboard id={id} className="m-0" />
        <UncontrolledDropdown>
          <DropdownToggle className="actionBtn">
            <span>Actions</span>
            <i className="ph-caret-down"></i>
          </DropdownToggle>
          <DropdownMenu right className={classNames(styles.webhookActionsMenu)}>
            <DropdownItem onClick={() => setDeleteWebhook(true)}>
              Delete
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
      <hr className="m-0" style={{ height: '2px' }} />
      <div className="d-flex align-items-center mb-7 pt-2 mt-1">
        <p className={classNames('SettlementSummaryPayment', 'pr-5')}>
          <div className={classNames('SettlementSummaryHeading')}>Status</div>
          <div className={classNames('SettlementHeadingData')}>
            {customerStatusFormatter(status)}
          </div>
        </p>
        <p className={classNames('SettlementSummaryPayment', 'pl-5', 'pr-5')}>
          <div className={classNames('SettlementSummaryHeading', 'pr-5')}>
            Listening
          </div>
          <div className={classNames('SettlementHeadingData')}>{event}</div>
        </p>
        <p className={classNames('SettlementSummaryPayment', 'pl-5', 'pr-5')}>
          <div className={classNames('SettlementSummaryHeading')}>HMAC key</div>
          <RevealSecret />
        </p>
      </div>
      <DeleteModal
        showModal={deleteWebhook}
        setShowModal={setDeleteWebhook}
        warningStatement={
          'After deleting this endpoint no future webhook will be sent to its URL.'
        }
        tag={'webhook'}
        action={handleDeleteWebhook}
      />
    </>
  );
};

export default Summary;
