import { useLocation, useNavigate } from 'react-router-dom';

export default (parameterName: string): [string, () => void] => {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);

  const getValue = () => urlParams.get(parameterName) || '';

  const setValue = (val = '') => {
    if (!val) {
      urlParams.delete(parameterName);
    } else if (urlParams.has(parameterName)) {
      urlParams.set(parameterName, val);
    } else {
      urlParams.append(parameterName, val);
    }

    navigate(
      {
        ...location,
        search: decodeURIComponent(parameterName.toString()),
      },
      { replace: true }
    );
    return urlParams;
  };

  return [getValue(), setValue];
};
