import axios from 'axios';
import { apiWithOrg } from 'services/api';
import { useStateValue } from 'state';
import {
  action,
  actionFailure,
  actionSuccess,
  CREATE_PAYMENTLINK,
  DELETE_PAYMENTLINK,
  FETCH_PAYMENTLINK_SESSIONS,
  LIST_PAYMENTLINK,
  RESET_CREATE_PAYMENTLINK,
  RESET_SEND_EMAIL,
  RESET_SEND_SMS,
  SEND_EMAIL,
  SEND_SMS,
  VERIFY_PAYMENTLINK,
  VERIFY_PAYMENTLINK_RESET,
} from '../actions';
import { PAYMENTLINKS } from '../type';

const usePaymentLinks = () => {
  const { state, dispatch } = useStateValue();
  const { paymentLinks } = state;

  const toCreatePaymentLink = async (
    values: PAYMENTLINKS.ICreatePaymentLink
  ) => {
    dispatch(action(CREATE_PAYMENTLINK));
    try {
      const response = await apiWithOrg.post(`/payment_links`, values);
      dispatch(actionSuccess(CREATE_PAYMENTLINK, response?.data));
    } catch (e: any) {
      let errorMessage = axios.isAxiosError(e)
        ? e.response?.data?.detail?.[0]?.msg
        : e instanceof Error
        ? e.message
        : null;

      if (e?.response?.data?.error_code === 'role_based_permission_denied')
        errorMessage = 'role_based_permission_denied';

      dispatch(
        actionFailure(CREATE_PAYMENTLINK, {
          message: errorMessage,
          type: 'manual',
        })
      );
    }
  };

  const toCreatePaymentLinkWithTreez = async (
    organization_id: string,
    invoice_number: string,
    auto_charge: boolean
  ) => {
    dispatch(action(CREATE_PAYMENTLINK));
    try {
      const request = {
        organization_id,
        invoice_number,
        invoice_type: 'treez',
        auto_charge,
      };
      const response = await apiWithOrg.post('/payment_links', request);
      dispatch(actionSuccess(CREATE_PAYMENTLINK, response?.data));
    } catch (e) {
      const errorMessage = axios.isAxiosError(e)
        ? e.response?.data
        : e instanceof Error
        ? e.message
        : null;

      dispatch(
        actionFailure(CREATE_PAYMENTLINK, {
          error_obj: errorMessage?.message ?? errorMessage,
          type: 'treez',
        })
      );
    }
  };

  const toDeletePaymentLink = async (payment_link_id: string) => {
    dispatch(action(DELETE_PAYMENTLINK));
    try {
      const response = await apiWithOrg.remove(
        `/payment_links/${payment_link_id}`
      );
      dispatch(actionSuccess(DELETE_PAYMENTLINK, response?.data));
    } catch (e) {
      dispatch(actionFailure(DELETE_PAYMENTLINK, e));
    }
  };

  const toSendSMS = async (payment_link_id: string) => {
    dispatch(action(SEND_SMS));
    try {
      const request = {
        notification_channels: ['sms'],
      };
      const response = await apiWithOrg.post(
        `/payment_links/${payment_link_id}/notify`,
        request
      );
      dispatch(actionSuccess(SEND_SMS, response?.data));
    } catch (e) {
      dispatch(actionFailure(SEND_SMS, e));
    }
  };

  const resetSendSMS = () => {
    dispatch(action(RESET_SEND_SMS));
  };

  const toSendEmail = async (payment_link_id: string) => {
    dispatch(action(SEND_EMAIL));
    try {
      const request = {
        notification_channels: ['email'],
      };
      const response = await apiWithOrg.post(
        `/payment_links/${payment_link_id}/notify`,
        request
      );
      dispatch(actionSuccess(SEND_EMAIL, response?.data));
    } catch (e) {
      const errorMessage = axios.isAxiosError(e)
        ? e.response?.data.error_property
        : e instanceof Error
        ? e.message
        : null;

      dispatch(actionFailure(SEND_EMAIL, errorMessage));
    }
  };

  const resetSendEmail = () => {
    dispatch(action(RESET_SEND_EMAIL));
  };

  const toFetchPaymentLinks = async (
    values: PAYMENTLINKS.IFetchPaytmentLinks
  ) => {
    dispatch(action(LIST_PAYMENTLINK));
    try {
      const response = await apiWithOrg.get(`/payment_links`, values);
      dispatch(actionSuccess(LIST_PAYMENTLINK, response?.data));
    } catch (e) {
      dispatch(actionFailure(LIST_PAYMENTLINK, e));
    }
  };

  const toFetchSessionsPaymentLinks = async (
    values: PAYMENTLINKS.IFetchPaytmentLinksSessions
  ) => {
    dispatch(action(FETCH_PAYMENTLINK_SESSIONS));
    try {
      const response = await apiWithOrg.get(`payment_links/sessions`, values);
      dispatch(actionSuccess(FETCH_PAYMENTLINK_SESSIONS, response?.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_PAYMENTLINK_SESSIONS, e));
    }
  };

  const toVerifyPaymentLinks = async (
    values: PAYMENTLINKS.IVerifyPaytmentLinks
  ) => {
    dispatch(action(VERIFY_PAYMENTLINK));
    try {
      const response = await apiWithOrg.get(
        `/payment_links/verification`,
        values
      );
      dispatch(actionSuccess(VERIFY_PAYMENTLINK, response?.data));
    } catch (e) {
      dispatch(actionFailure(VERIFY_PAYMENTLINK, e));
    }
  };

  const resetVerification = () => {
    dispatch(action(VERIFY_PAYMENTLINK_RESET));
  };

  const resetCreatePaymentLink = () => {
    dispatch(action(RESET_CREATE_PAYMENTLINK));
  };

  return {
    paymentLinks,
    toCreatePaymentLink,
    toCreatePaymentLinkWithTreez,
    toFetchPaymentLinks,
    toDeletePaymentLink,
    toVerifyPaymentLinks,
    toFetchSessionsPaymentLinks,
    resetVerification,
    resetCreatePaymentLink,
    toSendSMS,
    resetSendSMS,
    toSendEmail,
    resetSendEmail,
  };
};
export default usePaymentLinks;
