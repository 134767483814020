import React from 'react';

export enum NotRetryableReason {
  EXCEEDED_RETRY_WINDOW = 'exceeded_retry_window',
  EXCEEDED_MAX_RETRY_ATTEMPT = 'exceeded_max_retry_attempt',
  RETURN_CODE_NOT_RETRYABLE = 'return_code_not_retryable',
}

export const notRetryableReason = (row: any) => {
  if (row.not_retryable_reason === NotRetryableReason.EXCEEDED_RETRY_WINDOW) {
    return <div>Transaction has exceeded the 30 day retry window.</div>;
  }
  if (
    row.not_retryable_reason === NotRetryableReason.EXCEEDED_MAX_RETRY_ATTEMPT
  ) {
    return (
      <div>
        You have exceeded the maximum attempts to retry this transaction.
      </div>
    );
  }
  if (
    row.not_retryable_reason === NotRetryableReason.RETURN_CODE_NOT_RETRYABLE
  ) {
    return (
      <div>
        Transaction failed for reasons other than "Insufficient Balance". Any
        subsequent retry would result in failure, therefore Retry is not
        possible.
        <br />
        <br />
        Please use an alternate method to collect the payment.
      </div>
    );
  }
  return <></>;
};
