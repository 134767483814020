import React from 'react';
import CURRENCY from 'utils/currency';
import { BANKING } from 'state/banking/type';
import styles from './payouts.module.scss';

interface IProps {
  row: BANKING.IPayoutSummaryBreakdown;
}

const PayoutAccordion = ({ row }: IProps): JSX.Element => {
  return (
    <>
      <div>
        <p className={styles.dropDownInfo}>
          <span>Self Payouts</span>
          <span>
            {CURRENCY.convertToMainUnit(row?.self_payout_amount) || '$00.00'}
          </span>
        </p>
        <p className={styles.dropDownInfo}>
          <span>External Payouts</span>
          <span>
            {CURRENCY.convertToMainUnit(row?.external_payout_amount) ||
              '$00.00'}
          </span>
        </p>
        <p className={styles.dropDownInfo}>
          <span>Total Payouts</span>
          <span>
            {CURRENCY.convertToMainUnit(row?.total_payout_amount) || '$00.00'}
          </span>
        </p>
      </div>
    </>
  );
};

export default PayoutAccordion;
