import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'reactstrap';
import useEvents from 'state/events/hooks/useEvents';
import SessionDetails from './sessionDetails';

const Session = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const { toFetchEventsSessions } = useEvents();

  useEffect(() => {
    if (id) toFetchEventsSessions(id);
  }, []);

  return (
    <>
      <Card className="card-profile border-0">
        <SessionDetails />
      </Card>
    </>
  );
};

export default Session;
