import classNames from 'classnames';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, FormGroup, Input, ModalBody } from 'reactstrap';
import styles from '../../styles.module.scss';
import ModalContainer from 'components/modals/container';
import FormLabel from 'components/form-label';
import { PAYMENT_CONFIG, PaymentDeviceConfig } from 'state/paymentConfig/type';
import FullDeviceConfig from '../fullDeviceConfig';
import SimpleDeviceConfig from '../simpleDeviceConfig';
import { useForm } from 'react-hook-form';

export enum ModalType {
  EDIT = 'edit',
  SAVE = 'save',
}
interface IProps {
  modal: boolean;
  setModal: (show: boolean, type?: ModalType) => void;
  type: ModalType;

  submitCall: (
    name:
      | PAYMENT_CONFIG.ICreatePaymentDeviceSimple
      | PAYMENT_CONFIG.ICreatePaymentDeviceFull,
    id?: string
  ) => void;
  processorId: string;
  deviceId: string | undefined;
  deviceConfigType: PaymentDeviceConfig;
  deviceConfig: Record<string, string>;
  setDeviceConfig: Dispatch<SetStateAction<Record<string, string>>>;
}

export const encryptedValueSetter = (value: string, compareString: string) => {
  if (value === `%${compareString}%`) {
    return undefined;
  } else if (value === '') {
    return null;
  } else {
    return value;
  }
};

const AddPaymentDevice = (props: IProps): JSX.Element => {
  const {
    modal,
    setModal,
    type,
    submitCall,
    processorId,
    deviceId,
    deviceConfigType,
    deviceConfig,
    setDeviceConfig,
  } = props;

  const [disabledButton, setDisabledButton] = useState(true);

  const { getValues, register, setValue } = useForm();

  const editDeviceFunction = () => {
    if (deviceConfig.deviceName?.length !== 0) {
      submitCall(
        {
          deviceregisterid: deviceConfig.registerId,
          name: deviceConfig.deviceName,
          processorId: processorId,
          deviceauthcode: encryptedValueSetter(
            getValues('authCode'),
            deviceConfig.registerId
          ),
          printConfig:
            deviceConfig.printConfig !== 'NONE'
              ? deviceConfig.printConfig
              : null,
          tpn: deviceConfig?.tpn,
        },
        deviceId
      );
    }
  };
  const addDeviceFunction = () => {
    if (deviceConfig.deviceName?.length !== 0) {
      submitCall(
        {
          deviceregisterid: deviceConfig.registerId,
          name: deviceConfig.deviceName,
          processorId: processorId,
        },
        deviceId
      );
    }
  };
  useEffect(() => {
    if (deviceConfig?.deviceName && deviceConfig?.registerId) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [deviceConfig]);

  return (
    <div>
      <ModalContainer
        testId="add-payment-device-modal"
        isOpen={modal}
        onClose={() => {}}
        header={false}
        nopadding={true}
        isFullHeight={false}
        footer={
          <div>
            <Button onClick={() => setModal(false)} color="secondary">
              Cancel
            </Button>
            <Button
              data-testid="paymentDeviceCTA"
              color="primary"
              onClick={
                type === ModalType.EDIT ? editDeviceFunction : addDeviceFunction
              }
              disabled={disabledButton}
            >
              {type === ModalType.SAVE ? 'Add' : 'Edit'} payment device
            </Button>
          </div>
        }
      >
        <>
          {type === ModalType.SAVE && (
            <div className="px-4 pb-2 pt-4">
              <span className={classNames(styles.modalHeader)}>
                Add a new payment device
              </span>
              <ModalBody className="p-0 pt-4">
                <form role="form">
                  <FormGroup>
                    <FormLabel
                      element="device_name"
                      label="Name of device"
                      className={styles.fieldLabel}
                    />
                    <Input
                      name="device_name"
                      id="device_name"
                      onChange={(e) => {
                        deviceConfig.deviceName = e.currentTarget.value;
                        setDeviceConfig({ ...deviceConfig });
                      }}
                      defaultValue={deviceConfig.deviceName}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormLabel
                      element="register_id"
                      label="Register Id"
                      className={styles.fieldLabel}
                    />
                    <Input
                      name="register_id"
                      id="register_id"
                      onChange={(e) => {
                        deviceConfig.registerId = e.currentTarget.value;
                        setDeviceConfig({ ...deviceConfig });
                      }}
                      defaultValue={deviceConfig.registerId}
                    />
                  </FormGroup>
                </form>
              </ModalBody>
            </div>
          )}
          {type === ModalType.EDIT && (
            <div className="px-4 pb-2 pt-4">
              <span className={classNames(styles.modalHeader)}>
                Edit payment device
              </span>
              <FormGroup className="pt-4 mb-3">
                <FormLabel
                  element="device_name"
                  label="Name of device"
                  className={styles.fieldLabel}
                />
                <Input
                  name="device_name"
                  id="device_name"
                  onChange={(e) => {
                    deviceConfig.deviceName = e.currentTarget.value;
                    setDeviceConfig({ ...deviceConfig });
                  }}
                  defaultValue={deviceConfig.deviceName}
                />
              </FormGroup>
              {deviceConfigType === PaymentDeviceConfig.SIMPLE && (
                <SimpleDeviceConfig
                  setDeviceConfig={setDeviceConfig}
                  disabled={false}
                  inModal
                  deviceConfig={deviceConfig}
                />
              )}
              {deviceConfigType == PaymentDeviceConfig.FULL && (
                <FullDeviceConfig
                  deviceConfig={deviceConfig}
                  setDeviceConfig={setDeviceConfig}
                  disabled={false}
                  register={register}
                  setValue={setValue}
                />
              )}
            </div>
          )}
        </>
      </ModalContainer>
    </div>
  );
};

export default AddPaymentDevice;
