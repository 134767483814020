import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import accessLocalStorage from 'services/localStorage';
import { SPARC_BASE } from 'utils/config';
import { TREEZ_AUTH_BASE } from 'services/api/api';
import routeConfig from 'routes/config';
import {
  hasTokenExpired,
  isAccessTokenPresent,
} from 'services/auth';

interface IAxiosResolve {
  (value: AxiosRequestConfig | PromiseLike<AxiosRequestConfig>): void;
}

interface IAxiosReject {
  (reason?: string | Error): void;
}

/**
 * Creates an Axios instance with base URL configured.
 * @returns An AxiosInstance
 */
export const createApiInstance = (): AxiosInstance => {
  const baseURL = SPARC_BASE;
  const config = { baseURL };
  return axios.create(config);
};


/**
 * Checks if the request has org ID header. Aborts request if absent.
 * @param {AxiosRequestConfig} config - request config object
 * @returns a promise that resolves to an AxiosRequestConfig object.
 */
export const verifyOrgHeader = async (config: AxiosRequestConfig) => {
  return new Promise<AxiosRequestConfig>((resolve, reject) => {
    if (!config.headers.common['X-Organization-Id']) {
      // skipcq: JS-0002
      console.log({
        message: 'Org ID header not found',
        url: config.url,
      });
      return reject(new Error('Organization ID not found'));
    }

    return resolve(config);
  });
};
export const verifyEntityHeader = async (config: AxiosRequestConfig) => {
  return new Promise<AxiosRequestConfig>((resolve, reject) => {
    if (!config.headers.common['entity-id']) {
      // skipcq: JS-0002
      console.log({
        message: 'Entity ID header not found',
        url: config.url,
      });
      return reject(new Error('Entity ID not found'));
    }

    return resolve(config);
  });
};

const toRefreshSSOToken = async (
  accessToken: string,
  refreshToken: string,
  config: AxiosRequestConfig,
  resolve: IAxiosResolve
) => {
  const headers = {
    'x-application': 'MSO',
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  const apiConfig = {
    headers: headers,
    baseURL: TREEZ_AUTH_BASE,
  };
  const body = new URLSearchParams({ accessToken, refreshToken });

  try {
    const response = await axios.post('/tokens/refresh', body, apiConfig);
    const tokens = response?.data;
    if (tokens) {
      const user = {
        accessToken: tokens.accessToken,
        refreshToken: tokens.refreshToken,
        expiresIn: tokens?.expiresIn,
        idToken: tokens?.idToken,
      };
      accessLocalStorage.setUser(user);
      config.headers.Authorization = `Bearer ${tokens.accessToken}`;
      return resolve(config);
    }
  } catch (e) {
    accessLocalStorage.clear();
    window.location.href = routeConfig.ROOT;
    return;
  }
};

/**
 * Request interceptor to set access token in API call header
 * @param {AxiosRequestConfig} config - request config object
 * @returns a promise that resolves to an AxiosRequestConfig object.
 */
export const authInterceptor = async (config: AxiosRequestConfig) => {
  return new Promise<AxiosRequestConfig>(async (resolve, reject) => {
    const userObject = accessLocalStorage.getUser();

    if (isAccessTokenPresent(userObject)) {
      const { accessToken, refreshToken } = userObject;
      // for refreshing token
      if (hasTokenExpired(accessToken)) {
        return toRefreshSSOToken(accessToken, refreshToken, config, resolve);
      }

      config.headers.Authorization = `Bearer ${userObject.accessToken}`;
    }

    return resolve(config);
  });
};
