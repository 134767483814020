import { BANKING } from 'state/banking/type';
import { PAYMENTS } from '../state/payment/type';

export const WARNINGS = {
  EMPTY_FIELDS_WARNING: 'Kindly fill all the required fields',
  EMAIL_VALIDATION: 'Kindly enter a valid email',
  CODE_VALIDATION: 'Kindly enter the 6 digit code',
  PASSOWRD_MISMATCH_WARNING: 'The passwords entered are different.',
  INVALID_CREDENTIALS: 'Error! Invalid email or password.',
  CODE_ERROR: 'Error! Something went wrong. Try again later',
  VERIFY_ACCOUNT_MESSAGE:
    'Kindly verify your account. Check your inbox for the verification email.',
  USER_NOT_FOUND: 'User not found! Please sign up.',
  SUCCESS_RESET_PASSWORD:
    'Your password has been reset successfully. You can now sign in with your new password',
};

/* Error messages */
export const errorMessage = {
  GENERIC: 'An unknown error occurred!',
};

/* Different password reset stages */
export const passwordResetStages = {
  STAGE_1: 'enterEmail',
  STAGE_2: 'enterCode',
  STAGE_3: 'enterpassword',
};

/* Email Regex */
export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

/* pagination limits */
export const ALLOWED_PAGINATION_LIMITS = [5, 10, 15];

export const TRANSACTION_DETAILS_HEADINGS = {
  HEADINGS_1: 'Amount',
  HEADINGS_2: 'Payment Status',
  HEADINGS_3: 'Created At',
  HEADINGS_4: 'Merchant Fee',
  HEADINGS_5: 'Statement Reference',
  HEADINGS_6: 'Authorized At',
  HEADINGS_7: 'Treez Ticket ID',
  HEADINGS_8: 'Swifter Fee',
  HEADINGS_9: 'Captured At',
  HEADINGS_10: 'Tip Amount',
  HEADINGS_11: 'Order Amount',
};

export const SETTLEMENT_DETAILS_HEADINGS = {
  HEADINGS_1: 'Net Amount',
  HEADINGS_2: 'Gross Amount',
  HEADINGS_3: 'Date',
  HEADINGS_4: 'Description',
};
export const PAYMENT_DETAILS_HEADINGS = {
  HEADINGS_1: 'Payment Source Type',
  HEADINGS_2: 'Label',
  HEADINGS_3: 'User ID',
};

export const REFUND_STATUS = {
  SUCCEEDED: 'succeeded',
  PENDING: 'pending',
  FAILED: 'failed',
};

export const PAYOUT_STATUS = {
  SUCCEEDED: 'succeeded',
  PENDING: 'pending',
  FAILED: 'failed',
};

export const STATUS_SUCCESS = 'successful';
export const STATUS_AUTHORIZED = 'authorized';
export const STATUS_INITIATED = 'ach initiated';
export const STATUS_CANCELED = 'canceled';
export const STATUS_RETURNED = 'failed';
export const STATUS_PENDING = 'pending';
export const STATUS_REVERSED = 'reversed';
export const STATUS_PAYOUT_INITED = 'inited';
export const STATUS_PAYOUT_SUBMITTED = 'submitted';
export const STATUS_PAYOUT_SETTLED = 'settled';
export const STATUS_PAYOUT_FAILED = 'failed';
export const STATUS_FAILED = 'failed';
export const STATUS_WRITE_OFF = 'write_off';

export const STATUS_SETTLED = 'settled';
export const STATUS_SETTLEMENT_INITED = 'inited';
export const STATUS_SETTLEMENT_SUBMITTED = 'submitted';

export const STATUS_STARTED = 'started';
export const STATUS_COMPLETED = 'completed';
export const STATUS_FINALIZED = 'finalized ';
export const STATUS_REFUND_INITIATED = 'refund_initiated';
export const STATUS_REFUND_SUCCESSFUL = 'refund_successful';
export const STATUS_REFUND_FAILED = 'refund_failed';

export const STATUS_SUSPENDED = 'suspended';
export const STATUS_ACTIVE = 'active';
export const STATUS_RISKY = 'high_risk';
export const STATUS_MEDIUM_RISK = 'medium_risk';

// payment links invoice type
export const INVOICE_TYPE_TREEZ = 'treez';
export const INVOICE_TYPE_MANUAL = 'manual';

// aggregates and details listing
export const TRANSACTION_TABS = [
  {
    label: 'Summary',
    filters: [],
  },
  {
    label: 'Details',
    filters: [],
  },
];

export const TIPS_TABS = [
  {
    label: 'Summary',
    filters: [],
  },
  {
    label: 'Details',
    filters: [],
  },
];

export const BANKING_TABS = [
  {
    label: 'All',
    filters: [],
  },
  {
    label: 'Incoming',
    filters: [],
  },
  {
    label: 'Outgoing',
    filters: [],
  },
];

/* Tabs to filter based on the status */
export const CUSTOMER_DETAILS_TABS = [
  {
    label: 'All',
    filters: [],
  },
  {
    label: 'Successful',
    filters: [STATUS_SUCCESS, STATUS_REFUND_SUCCESSFUL],
  },
  {
    label: 'In Progress',
    filters: [
      STATUS_PENDING,
      STATUS_INITIATED,
      STATUS_AUTHORIZED,
      STATUS_REFUND_INITIATED,
    ],
    tooltip: '',
  },
  {
    label: 'Failed',
    filters: [STATUS_RETURNED, STATUS_REFUND_FAILED],
  },
];

/* Charges Table tabs */
export const CHARGES_TABLE_COLUMNS = [
  { label: 'All', filters: [] },
  { label: 'Successful', filters: [STATUS_FINALIZED] },
  { label: 'Attempted', filters: [STATUS_STARTED] },
];

// Webhooks details tabs
export const WEBHOOK_TABS = [
  {
    label: 'All',
    filters: [],
  },
  {
    label: 'Successful',
    filters: ['success'],
  },
  {
    label: 'Failed',
    filters: ['failed'],
  },
];

export enum GTW_STATUS {
  UNDEFINED = '',
  PENDING = 'pending',
  SUCCESS = 'success',
  CANCELED = 'canceled',
  DECLINED = 'declined',
  NOT_FOUND = 'not found',
  ERROR = 'error',
  COMPLETED = 'completed',
  AUTHORIZED = 'authorized',
  REFUNDED = 'refunded',
  VOIDED = 'voided',
}

// All possible status
export const statusList = [
  { name: 'ACH Authorized', value: STATUS_AUTHORIZED },
  { name: 'ACH Initiated', value: STATUS_INITIATED },
  { name: 'Initiated', value: GTW_STATUS.PENDING },
  { name: 'Successful', value: [STATUS_SUCCESS, GTW_STATUS.SUCCESS] },
  { name: 'ACH Returned', value: STATUS_RETURNED },
  { name: 'Failed', value: [GTW_STATUS.ERROR, GTW_STATUS.DECLINED] },
  { name: 'Cancelled', value: [STATUS_CANCELED, GTW_STATUS.VOIDED] },
  { name: 'Refunded', value: GTW_STATUS.REFUNDED },
];

export const achStatusList = [
  { name: 'ACH Authorized', value: STATUS_AUTHORIZED },
  { name: 'ACH Initiated', value: STATUS_INITIATED },
  { name: 'Successful', value: STATUS_SUCCESS },
  { name: 'ACH Returned', value: STATUS_RETURNED },
  { name: 'Cancelled', value: STATUS_CANCELED },
];

export const gatewayStatusList = [
  { name: 'Initiated', value: GTW_STATUS.PENDING },
  { name: 'Successful', value: GTW_STATUS.SUCCESS },
  { name: 'Failed', value: [GTW_STATUS.ERROR, GTW_STATUS.DECLINED] },
  { name: 'Cancelled', value: [STATUS_CANCELED, GTW_STATUS.VOIDED] },
];

export const paymentTypeList = [
  { name: 'ACH', value: 'ach' },
  { name: 'CATM', value: 'ATM' },
  { name: 'Debit', value: 'Debit' },
];

export const paymentTypeTipList = [
  { name: 'CATM', value: 'ATM' },
  { name: 'Debit', value: 'Debit' },
];

export const InvoiceTypeList = [
  { name: 'Treez', value: INVOICE_TYPE_TREEZ },
  { name: 'Manual', value: INVOICE_TYPE_MANUAL },
];

export const statusListRefunds = [
  { name: 'Successful', value: REFUND_STATUS.SUCCEEDED },
  { name: 'Pending', value: REFUND_STATUS.PENDING },
  { name: 'Failed', value: REFUND_STATUS.FAILED },
];
export const statusListPayouts = [
  { name: 'Initiated', value: STATUS_PAYOUT_INITED },
  { name: 'Successful', value: STATUS_PAYOUT_SETTLED },
  { name: 'Failed', value: STATUS_PAYOUT_FAILED },
];

export const statusListConsumer = [
  { name: 'Active', value: STATUS_ACTIVE },
  { name: 'Suspended', value: STATUS_SUSPENDED },
  { name: 'Medium Risk', value: STATUS_MEDIUM_RISK },
  { name: 'High Risk', value: STATUS_RISKY },
];

export const bankingFilterTypes = {
  incoming: [
    { name: 'Retail income', value: 'CHARGE' },
    { name: 'Others', value: 'OTHER' },
  ],
  outgoing: [
    { name: 'Self Payout', value: BANKING.OutgoingTypes.SELF_PAYOUT },
    { name: 'External Payout', value: BANKING.OutgoingTypes.EXTERNAL_PAYOUT },
    { name: 'Fee', value: BANKING.OutgoingTypes.FEE },
    { name: 'Return', value: BANKING.OutgoingTypes.RETURN },
    { name: 'Refund', value: BANKING.OutgoingTypes.REFUND },
    { name: 'Others', value: 'OTHER' },
  ],
  balance: [
    { name: 'Self Payout', value: BANKING.OutgoingTypes.SELF_PAYOUT },
    { name: 'External Payout', value: BANKING.OutgoingTypes.EXTERNAL_PAYOUT },
    { name: 'Fee', value: BANKING.OutgoingTypes.FEE },
    { name: 'Return', value: BANKING.OutgoingTypes.RETURN },
    { name: 'Refund', value: BANKING.OutgoingTypes.REFUND },
    { name: 'Retail income', value: 'CHARGE' },
    { name: 'Others', value: 'OTHER' },
  ],
};

export const sourceList = [
  { name: 'E-Commerce', value: PAYMENTS.ITransactionSource.WEB },
  { name: 'Payment Link', value: PAYMENTS.ITransactionSource.PAYMENTLINK },
  { name: 'In-Store', value: PAYMENTS.ITransactionSource.INSTORE },
];

export const PAGE_SIZE = 25;

export const KYB_STATE = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Texas',
  'Tennessee',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Washington DC',
  'Wisconsin',
  'Wyoming',
];

export const KYB_LEGAL_BUSINESS_STRUCTURE = [
  'Sole proprietorship',
  'Partnership',
  'LLC',
  'Corporation',
  'S Corporation',
  'Non-profit',
  'Others',
];

export const KYB_MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const KYB_BUSINESS_TYPE = [
  { key: 'dispensary', value: 'Dispensary' },
  { key: 'software_business', value: 'Software business' },
  { key: 'cannabis_delivery_business', value: 'Cannabis delivery business' },
];

export const KYB_BUSINESS_OWNER_ROLE = [
  { key: 'Individual owner', value: 'Individual owner' },
  { key: 'The CEO', value: 'CEO' },
  { key: 'The Treasurer/CFO', value: 'Treasurer' },
  { key: 'The Head of Operations', value: 'Financial controller' },
  { key: 'Others', value: 'Others' },
];

export const ORG_TIMEZONE_DEFAULT = 'America/Los_Angeles';

export const tipsThresholdDate = new Date('2024-06-06T18:30:00.000Z');   // corrupt tips data before June 07