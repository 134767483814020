import React from 'react';
import { NavbarBrand, Navbar, Button } from 'reactstrap';
import logoImg from 'assets/img/brand/treez-nav.svg';
import classNames from 'classnames';
import styles from './navbar.module.scss';
import routeConfig from 'routes/config';
import useAuth from 'state/auth/hooks/useAuth';
import { ENV } from 'utils/config';
import { treezLoginPageUrl } from 'utils/urlGenerator';

const AuthNavbar = (): JSX.Element => {
  const pathname = window.location.pathname;
  const showSignOut = pathname.includes(
    `${routeConfig.STORES.layout}/${routeConfig.STORES.LIST.path}`
  );
  const { toLogout, auth } = useAuth();

  const logout = () => {
    toLogout();
    if (auth.isSSOLogin) {
      const env = ENV || 'production';
      const treezLogoutUrl = `${treezLoginPageUrl(env)}/signout`;
      window.open(treezLogoutUrl, '_blank');
    }
  };

  return (
    <>
      <Navbar
        className={classNames(
          'navbar-top navbar-horizontal navbar-dark',
          styles.authNavbar
        )}
        expand="md"
      >
        <NavbarBrand>
          <a
            href="https://www.treez.io/payments"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="..." src={logoImg} />
          </a>
        </NavbarBrand>
        {showSignOut && (
          <Button
            className={styles.signOutBtn}
            type="button"
            size="lg"
            onClick={logout}
          >
            <i className="ph-sign-out icon-xl m-0 p-0"></i>
            <div className="body-subtext-medium">Sign out</div>
          </Button>
        )}
      </Navbar>
    </>
  );
};

export default AuthNavbar;
