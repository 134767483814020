import React, { FC } from 'react';
import {
  NavLink as NavLinkRRD,
  useParams,
  generatePath,
} from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import { IView } from 'views';

interface IProps {
  options: IView;
  closeCollapse: () => void;
}

const NavitemSidebar: FC<IProps> = ({ options, closeCollapse }) => {
  const { orgId } = useParams();

  const isExternalLink = (url: string): boolean => {
    let url_string;
    try {
      url_string = new URL(url);
    } catch (_) {
      return false;
    }
    return url_string?.protocol === 'http:' || url_string.protocol === 'https:';
  };

  const getPath = () => {
    return generatePath(
      `/:orgId/${options.layout}/${options.path}${
        options.search ? `${options.search}` : ''
      }`,
      { orgId: orgId || '' }
    ).replace(/\/$/, '');
  };

  return (
    <NavItem
      key={options.name}
      id={options.name.toLowerCase()}
      itemID={options.name.toLowerCase()}
    >
      {isExternalLink(options.path) ? (
        <NavLink target="_blank" href={options.path + (options.search || '')}>
          {options.icon && <i className={options.icon} />}
          <span>{options.name}</span>
        </NavLink>
      ) : (
        <NavLink
          to={getPath()}
          tag={NavLinkRRD}
          onClick={() => closeCollapse}
          activeClass="active"
        >
          {options.icon && <i className={options.icon} />}
          <span>{options.name}</span>
        </NavLink>
      )}
    </NavItem>
  );
};

export default NavitemSidebar;
