import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
} from 'reactstrap';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import DownChevronIcon from 'components/icons/downchevron';
import { PAYMENT_CONFIG } from 'state/paymentConfig/type';
import usePaymentConfig from 'state/paymentConfig/hooks/usePaymentConfig';

interface IProps {
  defaultProvider: PAYMENT_CONFIG.IProcessors | undefined;
  setDefaultProvider: Dispatch<
    SetStateAction<PAYMENT_CONFIG.IProcessors | undefined>
  >;
}

const ProviderDropdown = ({ defaultProvider, setDefaultProvider }: IProps) => {
  const { paymentConfig } = usePaymentConfig();
  const { filteredList } = paymentConfig;

  const [paymentProviderOptions, setPaymentProviderOptions] = useState<
    PAYMENT_CONFIG.IProcessors[]
  >([]);

  useEffect(() => {
    if (filteredList && filteredList?.length > 0) {
      setPaymentProviderOptions(filteredList);
    } else {
      setPaymentProviderOptions([]);
    }
  }, [filteredList]);

  return (
    <>
      <span className={classNames(styles.heading)}>Payment provider</span>

      <FormGroup className="w-60 mt-2">
        <div>
          <UncontrolledDropdown>
            <DropdownToggle
              data-testid="provider_dropdown"
              className={classNames(
                styles.dropdownToggle,
                'd-inline-flex align-items-center '
              )}
            >
              {defaultProvider?.processorName || 'Please select'}
              <DownChevronIcon />
            </DropdownToggle>
            <DropdownMenu>
              {paymentProviderOptions?.map((item) => (
                <DropdownItem onClick={() => setDefaultProvider(item)}>
                  {item?.processorName}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </FormGroup>
    </>
  );
};

export default ProviderDropdown;
