import parseISO from 'date-fns/parseISO';
import moment, { Moment } from 'moment';
import { startOfDay, endOfDay, format, isPast } from 'date-fns';
import { ORG_TIMEZONE_DEFAULT } from 'utils/constants';

/**
 * Given date and format strings, return a formatted date string
 * For format strings, refer: https://date-fns.org/v2.29.3/docs/format
 * @param {string} dateString - The date string to be formatted.
 * @param {string} formatStyle - The format of the date.
 * @returns A string.
 */
export const formatDate = (
  dateString: string,
  formatStyle = 'MM/dd/yyyy'
): string => {
  return format(parseISO(dateString), formatStyle, {
    // eslint-disable-next-line global-require
    locale: require(`date-fns/locale/en-US/index.js`),
  });
};

/**
 * Given a date, returns a date string in the given timezone & format.
 * To be used only to render a date in a specific timezone/format.
 * Does NOT return standard date formats like ISO
 * @param {string} date - The date string to be formatted.
 * @param {string} tzString - IANA timezone string.
 * @param {string} formatStyle - The format of the date.
 * @returns A string.
 */
export const formatToTz = (
  date: Date | string,
  tzString = ORG_TIMEZONE_DEFAULT,
  formatStr = 'MM/dd/yyyy'
) => {
  try {
    const newTZDate = (
      typeof date === 'string' ? new Date(date) : date
    ).toLocaleString('en-US', {
      timeZone: tzString,
    });

    return formatDate(new Date(newTZDate).toISOString(), formatStr);
  } catch (e) {
    return '';
  }
};

/**
 * Converts a date to an ISO string with a timezone offset
 * @param {Date} date - The date to convert to an ISO string.
 * @returns The date in ISO format with the timezone offset.
 */
export const toIsoStringWithTz = (date: Date): string => {
  // const tzoffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
  const localISOTime = moment(date).toISOString(true);
  return localISOTime;
};

/**
 * Given a date and a format style, return a string representation of the date in the format style
 * @param {Date} date - The date to be formatted.
 * @param {string} formatStyle - The format of the date.
 * @returns A string.
 */
export const dateToFormattedString = (
  date: Date,
  formatStyle = 'MM/dd/yyyy'
): string => {
  return format(date, formatStyle);
};

/**
 * Given a date string, format it to the given format style
 * @param {string} [dateString] - A string representing a date in ISO format.
 * @param {string} formatStyle - The format of the date.
 * @returns formatted date string.
 */
export const formatFilterDate = (
  dateString?: string,
  formatStyle = 'MM/dd/yyyy'
): string => {
  const date = dateString ? parseISO(dateString) : new Date();
  return format(date, formatStyle);
};

/**
 * Given a date, return a string representing the start of that day
 * @param {Moment | Date | string} date - Moment | Date | string
 * @returns The start of the day (with 00:00:00 timestamp).
 */
export const formatStartOfDay = (date: Moment | Date | string): string => {
  if (moment.isMoment(date)) {
    return date.startOf('day').toISOString();
  }
  const startDate = new Date(date);
  return toIsoStringWithTz(startOfDay(startDate));
};

/**
 * Given a date, return a string representing the end of the day
 * @param {Moment | Date | string} date - Moment | Date | string
 * @returns The end of the day (with 23:59:59 timestamp).
 */
export const formatEndOfDay = (date: Moment | Date | string): string => {
  if (moment.isMoment(date)) {
    return date.endOf('day').toISOString();
  }
  const endDate = new Date(date);
  return toIsoStringWithTz(endOfDay(endDate));
};

/**
 * Returns the start of the week (monday) for the given date
 * @param {Date} date - The date to get the start of the week for.
 * @returns The start of the week.
 */
export const getStartOfWeek = (date: Date): Date => {
  const d = new Date(date);
  const startDay = d.getDate() - d.getDay() + (d.getDay() === 0 ? -6 : 1);
  return new Date(d.setDate(startDay));
};

/**
 * Given a date, return the last day (sunday) of that week
 * @param {Date} date - The date to start counting from.
 * @returns The last day of the week.
 */
export const getEndOfWeek = (date: Date): Date => {
  const d = new Date(date);
  if (d.getDay() === 0) return date;
  return new Date(d.setDate(d.getDate() - d.getDay() + 7));
};

/**
 * Get the first and last day of the week containing a given date
 * @param {Date} date - Date
 * @returns An array of two dates.
 */
export const getWeekRange = (date: Date): Date[] => {
  // first day of week: monday, last day: sunday
  const firstDate = getStartOfWeek(date);
  const lastDate = getEndOfWeek(date);

  return [firstDate, lastDate];
};

export const isDateInRange = (
  date: Moment,
  after?: string,
  before?: string
) => {
  if (after && !date.isAfter(moment(after).subtract(1, 'day'))) return false;
  if (before && !date.isBefore(moment(before).add(1, 'day'))) return false;
  return true;
};

export const isDateInPast = (date: Moment) => {
  return isPast(date.toDate());
};

export const generateListOfYears = (length: number) => {
  let startYear = new Date().getFullYear();
  const years = [startYear];

  for (let i = 0; i < length - 1; i++) years.push(startYear--);

  return years;
};

export const formatDateWithYear = (
  dateString: string,
  formatStyle = 'p PP'
): string => {
  if (dateString) {
    return format(parseISO(dateString), formatStyle, {
      // eslint-disable-next-line global-require
      locale: require(`date-fns/locale/en-US/index.js`),
    });
  }
  return '';
};

export const isSameDay = (fromMoment: Moment, toMoment: Moment) => {
  const from = moment(fromMoment);
  const to = moment(toMoment);
  const fromDateDay = from.startOf('day');
  const toDateDay = to.startOf('day');

  return fromDateDay.isSame(toDateDay);
};
