import React from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';

interface IProps {
  onClick: () => void | Promise<void>;
  id?: string;
}

const PrimaryExportBtn = ({ id, onClick }: IProps) => {
  return (
    <Button
      id={id || 'export-btn'}
      className={classNames('ml-2 btn-icon-left exportBtnPrimary')}
      onClick={onClick}
    >
      <i className="ph-export icon-xl" />
      Export
    </Button>
  );
};

export default PrimaryExportBtn;
