export const CREATE_TERMINAL = 'payments/CREATE_TERMINAL';
export const CREATE_TERMINAL_SUCCESS = 'payments/CREATE_TERMINAL_SUCCESS';
export const CREATE_TERMINAL_FAILURE = 'payments/CREATE_TERMINAL_FAILURE';

export const FETCH_INSTORE_TERMINALS = 'payments/FETCH_INSTORE_TERMINALS';
export const FETCH_INSTORE_TERMINALS_SUCCESS =
  'payments/FETCH_INSTORE_TERMINALS_SUCCESS';
export const FETCH_INSTORE_TERMINALS_FAILURE =
  'payments/FETCH_INSTORE_TERMINALS_FAILURE';

export const FETCH_INSTORE_CASH_DRAWER = 'payments/FETCH_INSTORE_CASH_DRAWER';
export const FETCH_INSTORE_CASH_DRAWER_FAILURE =
  'payments/FETCH_INSTORE_CASH_DRAWER_FAILURE';
export const FETCH_INSTORE_CASH_DRAWER_SUCCESS =
  'payments/FETCH_INSTORE_CASH_DRAWER_SUCCESS';

export const FETCH_INSTORE_SESSIONS = 'payments/FETCH_INSTORE_SESSIONS';
export const FETCH_INSTORE_SESSIONS_FAILURE =
  'payments/FETCH_INSTORE_SESSIONS_FAILURE';
export const FETCH_INSTORE_SESSIONS_SUCCESS =
  'payments/FETCH_INSTORE_SESSIONS_SUCCESS';

export const UPDATE_SESSION_TICKET = 'payments/UPDATE_SESSION_TICKET';
export const UPDATE_SESSION_TICKET_FAILURE =
  'payments/UPDATE_SESSION_TICKET_FAILURE';
export const UPDATE_SESSION_TICKET_SUCCESS =
  'payments/UPDATE_SESSION_TICKET_SUCCESS';

export const RESET = 'payments/RESET';

export const action = (type: string): IAction => ({
  type,
});

export const actionSuccess = <T>(type: string, payload: T): IAction => ({
  type: `${type}_SUCCESS`,
  payload,
});

export const actionFailure = <T>(type: string, payload: T): IAction => ({
  type: `${type}_FAILURE`,
  payload,
});

export const reset = (): IAction => ({
  type: RESET,
});
