import React from 'react';
import classNames from 'classnames';
import { Row } from 'reactstrap';
import { PAYMENTS } from 'state/payment/type';
import { formatToTz } from 'utils/date-format';
import { findEvent } from 'utils/timelineEvents';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import styles from '../../sessions.module.scss';

interface IProps {
  events?: PAYMENTS.IIndividualSession[];
}
export enum LogType {
  session = 'session.log',
}
export enum payloadLevels {
  error = 'error',
  success = 'info',
  bug = 'bug',
  general = 'general',
}
const Timeline = ({
  events = {} as PAYMENTS.IIndividualSession[],
}: IProps): JSX.Element => {
  const getEventDetails = (event: PAYMENTS.IIndividualSession) => {
    const { eventName, eventIcon } = findEvent.findEventName(event);
    return { eventName, eventIcon };
  };
  const { organisation } = useOrganisation();
  const { settings } = organisation;
  // for using unique entries in events
  // const uniqueEvents = events.length > 1 ? events?.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i) : [];
  // const uniqueEvents = events?.reverse();
  return (
    <>
      <div className="mb-7">
        <h3 className="mb-2 PaymentDetailsHeading">Timeline</h3>
        <hr className="mt-0 mb-4" />
        {events.length >= 1 && (
          <>
            {events
              .slice()
              .reverse()
              .map((e, index) => {
                const sessionEventClass = getEventDetails(e);
                return (
                  <>
                    {e.create_date && sessionEventClass.eventName && (
                      <Row className="pl-4">
                        <div className="pr-5 d-flex PL6">
                          <div
                            className={
                              index === events.length - 1
                                ? classNames(styles.timelineIcon, styles.last)
                                : classNames(styles.timelineIcon)
                            }
                          >
                            <i
                              className={classNames(
                                sessionEventClass.eventIcon
                              )}
                            />
                          </div>
                          <div className="pb-4 DIB">
                            <div className="TimelineSummaryHeading">
                              {sessionEventClass.eventName}
                            </div>
                            <div className="TimelineHeadingData">
                              {formatToTz(
                                e.create_date,
                                settings?.timezone,
                                'MMM dd, hh:mm a'
                              )}
                            </div>
                          </div>
                        </div>
                      </Row>
                    )}
                  </>
                );
              })}
          </>
        )}
      </div>
    </>
  );
};

export default Timeline;
