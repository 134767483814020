import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import CURRENCY from 'utils/currency';
import { BANKING } from 'state/banking/type';
import styles from '../summary.module.scss';

interface IProps {
  row: BANKING.IOutgoingSummaryBreakdown;
}

const BankingOutgoingAccordion = ({ row }: IProps): JSX.Element => {
  return (
    <>
      <div>
        <p className={styles.dropDownInfo}>
          <span className={styles.underline} id="payouts-accord">
            Payouts
          </span>
          <UncontrolledTooltip
            placement="right"
            target="payouts-accord"
            style={{ maxWidth: '190px' }}
          >
            Payouts are generally settled in 2-3 business days (excluding bank
            holidays)
          </UncontrolledTooltip>
          <span data-testid="payout">
            {CURRENCY.convertToMainUnit(row?.payout)}
          </span>
        </p>
        <p className={styles.dropDownInfo}>
          <span className={styles.underline} id="platform-fee-accord">
            Platform fee
          </span>
          <UncontrolledTooltip
            placement="right"
            target="platform-fee-accord"
            style={{ minWidth: '270px' }}
          >
            <div>Total fee = N x ($0.5) + B x (2.5%)</div>
            <div className="mt-1 font-italic" style={{ color: '#666E7A' }}>
              <div>N = no. of transactions</div>
              <div>B = Payout amount (bulk)</div>
              <div className="mt-3">$0.5 {'->'} fee per transaction</div>
              <div>2.5% {'->'} charge on bulk payout</div>
            </div>
          </UncontrolledTooltip>
          <span data-testid="platform_fee">
            {CURRENCY.convertToMainUnit(row?.platform_fee)}
          </span>
        </p>
        <p className={styles.dropDownInfo}>
          <span>Total returns</span>
          <span data-testid="return_amount">
            {CURRENCY.convertToMainUnit(row?.return_amount)}
          </span>
        </p>
        <p className={styles.dropDownInfo}>
          <span>Total refunds</span>
          <span data-testid="total_refund">
            {CURRENCY.convertToMainUnit(row?.total_refund)}
          </span>
        </p>
        <p className={styles.dropDownInfo}>
          <span className={styles.underline} id="tax-accord">
            Tax
          </span>
          <UncontrolledTooltip
            placement="right"
            target="tax-accord"
            style={{ maxWidth: '190px' }}
          >
            This is the tax levied on the total platform fee that is charged for
            using our service
          </UncontrolledTooltip>
          <span data-testid="tax">{CURRENCY.convertToMainUnit(row?.tax)}</span>
        </p>
        {/* {Boolean(other) && ( */}
        <p className={styles.dropDownInfo}>
          <span>Other outgoing transactions</span>
          <span data-testid="other_outgoing">
            {CURRENCY.convertToMainUnit(row?.other_outgoing) || '$00.00'}
          </span>
        </p>
        {/* )} */}
        <p className={styles.dropDownGross}>
          <span>Total outgoing</span>
          <span data-testid="total_outgoing">
            {CURRENCY.convertToMainUnit(row?.total_outgoing)}
          </span>
        </p>
      </div>
    </>
  );
};

export default BankingOutgoingAccordion;
