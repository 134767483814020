import classNames from 'classnames';
import React from 'react';
import styles from './heading.module.scss';
import PageHeading, { IHeaderProps } from '.';

interface props extends Omit<IHeaderProps, 'overrideContainerClass'> {
  cta?: string | JSX.Element;
}

// NOTE html is abstracted from Payment Links Header
// TODO refactor payment links to use this component
const CTAPageHeading = ({
  heading,
  subHeading,
  className,
  tooltipText,
  level = 'h1',
  cta,
}: props): JSX.Element => {
  return (
    <div className={classNames(styles.container, className)}>
      <div className="d-flex flex-row">
        <div className="mr-auto">
          <PageHeading
            className={className}
            heading={heading}
            subHeading={subHeading}
            tooltipText={tooltipText}
            level={level}
            overrideContainerClass
          />
        </div>

        <div className="align-self-center">{cta}</div>
      </div>
    </div>
  );
};

export default CTAPageHeading;
