export const CREATE_PAYMENTLINK = 'channels/CREATE_PAYMENTLINK';
export const CREATE_PAYMENTLINK_SUCCESS = 'channels/CREATE_PAYMENTLINK_SUCCESS';
export const CREATE_PAYMENTLINK_FAILURE = 'channels/CREATE_PAYMENTLINK_FAILURE';

export const DELETE_PAYMENTLINK = 'channels/DELETE_PAYMENTLINK';
export const DELETE_PAYMENTLINK_SUCCESS = 'channels/DELETE_PAYMENTLINK_SUCCESS';
export const DELETE_PAYMENTLINK_FAILURE = 'channels/DELETE_PAYMENTLINK_FAILURE';

export const LIST_PAYMENTLINK = 'channels/LIST_PAYMENTLINK';
export const LIST_PAYMENTLINK_SUCCESS = 'channels/LIST_PAYMENTLINK_SUCCESS';
export const LIST_PAYMENTLINK_FAILURE = 'channels/LIST_PAYMENTLINK_FAILURE';

export const VERIFY_PAYMENTLINK = 'channels/VERIFY_PAYMENTLINK';
export const VERIFY_PAYMENTLINK_SUCCESS = 'channels/VERIFY_PAYMENTLINK_SUCCESS';
export const VERIFY_PAYMENTLINK_FAILURE = 'channels/VERIFY_PAYMENTLINK_FAILURE';
export const VERIFY_PAYMENTLINK_RESET = 'channels/VERIFY_PAYMENTLINK_RESET';

export const FETCH_PAYMENTLINK_SESSIONS = 'channels/FETCH_PAYMENTLINK_SESSIONS';
export const FETCH_PAYMENTLINK_SESSIONS_SUCCESS =
  'channels/FETCH_PAYMENTLINK_SESSIONS_SUCCESS';
export const FETCH_PAYMENTLINK_SESSIONS_FAILURE =
  'channels/FETCH_PAYMENTLINK_SESSIONS_FAILURE';

export const SEND_SMS = 'channels/SEND_SMS';
export const SEND_SMS_SUCCESS = 'channels/SEND_SMS_SUCCESS';
export const SEND_SMS_FAILURE = 'channels/SEND_SMS_FAILURE';
export const SEND_EMAIL = 'channels/SEND_EMAIL';
export const SEND_EMAIL_SUCCESS = 'channels/SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'channels/SEND_EMAIL_FAILURE';
export const RESET_SEND_SMS = 'channels/RESET_SEND_SMS';
export const RESET_SEND_EMAIL = 'channels/RESET_SEND_EMAIL';
export const RESET_CREATE_PAYMENTLINK = 'channels/RESET_CREATE_PAYMENTLINK';

export const action = (type: string): IAction => ({
  type,
});

export const actionSuccess = <T>(type: string, payload: T): IAction => ({
  type: `${type}_SUCCESS`,
  payload,
});

export const actionFailure = <T>(type: string, payload: T): IAction => ({
  type: `${type}_FAILURE`,
  payload,
});
