import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import usePayment from 'state/payment/hooks/usePayment';
import useQuery from 'utils/hooks/useQuery';
import { useLocation, useNavigate } from 'react-router-dom';
import DispensaryTransactionsList from './summary';
import PageHeading from 'components/heading';
import { TRANSACTION_TABS } from 'utils/constants';
import TransactionDetailsTab from './details';
import DateTimeRangePicker from 'components/calender/rangePicker';
import { Moment } from 'moment';
import moment from 'moment';
import styles from '../transactions.module.scss';
import STRING from 'utils/string';
import { getComparisonDateTime } from 'utils/dashboard';
import QUERY from 'utils/query';

const RefreshPageAction = ({
  activeTab,
  setTriggerRefresh,
}: {
  activeTab: string;
  setTriggerRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [timer, setTimer] = useState(0);
  const [lastUpdateMessage, setLastUpdateMessage] = useState(
    'Last updated a few seconds ago'
  );

  useEffect(() => {
    const second = timer < 60 ? timer : 0;
    const minute = timer >= 60 && timer <= 3540 ? Math.floor(timer / 60) : 0;
    if (second != 0) {
      setLastUpdateMessage('Last updated a few seconds ago');
    } else if (minute) {
      setLastUpdateMessage(
        `Last updated ${minute} minute${minute > 1 ? 's' : ''} ago`
      );
    }
    const timerInterval = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [timer]);

  useEffect(() => {
    setTimer(0);
  }, [activeTab]);

  return (
    <div className="d-flex align-items-center ml-4">
      <i className={classNames('ph-clock icon-lg', styles.lastUpdatedAt)}></i>
      <div className={classNames('ml-1 mr-10', styles.lastUpdatedAt)}>
        {lastUpdateMessage}
      </div>
      <div
        className={styles.refreshButton}
        onClick={() => {
          setTriggerRefresh((value) => !value);
          setTimer(0);
        }}
      >
        <i className={classNames('ph-arrows-clockwise-bold icon-lg')} />
        Refresh
      </div>
    </div>
  );
};

const DispensaryTransactions = (): JSX.Element => {
  const { payment } = usePayment();
  const location = useLocation();
  const tabNames = TRANSACTION_TABS;
  const query = useQuery(location?.search);
  const urlParams = useQuery(location.search);

  const [triggerRefresh, setTriggerRefresh] = useState(false);

  // Filter params loaded into component
  const dates = query.get('dates')?.split(',') || [];
  const urlDateFrom = dates.length > 0 ? dates[0].replace(' ', '+') : '';
  const urlDateTo = dates.length > 1 ? dates[1].replace(' ', '+') : '';

  const [defaultFromDate, defaultToDate] =
    urlDateFrom && urlDateTo
      ? [moment(urlDateFrom), moment(urlDateTo)]
      : [moment().startOf('day'), moment().endOf('day')];

  const [dateFrom, setDateFrom] = useState<Moment>(defaultFromDate);
  const [dateTo, setDateTo] = useState<Moment>(defaultToDate);
  const [prevFrom, prevTo] =
    getComparisonDateTime(dateFrom.toISOString(), dateTo.toISOString()) || [];
  const dateFromISO = dateFrom.toISOString();
  const dateToISO = dateTo.toISOString();

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(
    urlParams.get('tab')?.toLowerCase() ||
      (tabNames.length > 0 && tabNames[0].label) ||
      ''
  );

  useEffect(() => {
    const queryTabParam = urlParams.get('tab')?.toLowerCase();
    const tabFromUrl = tabNames.find(
      (t) => t.label.toLowerCase() === queryTabParam
    )?.label;

    if (location.search && tabFromUrl) {
      setActiveTab(tabFromUrl);
    } else {
      navigate({
        search: QUERY.update(location.search, {
          tab: 'summary',
          dates: [dateFrom.toISOString(), dateTo.toISOString()].join(),
        }),
      });
    }
  }, [location.search]);

  useEffect(() => {
    if (dateFrom && dateTo) {
      navigate({
        search: QUERY.update(location.search, {
          dates: [dateFrom.toISOString(), dateTo.toISOString()].join(),
        }),
      });
    }
  }, [dateFrom, dateTo]);

  return (
    <>
      <Card className="card-profile border-0">
        <PageHeading
          heading="Transactions"
          subHeading="View summary and details of your transactions here"
          className="mb-7"
          headingAppendRight={
            !payment.isLoading ? (
              <RefreshPageAction
                setTriggerRefresh={setTriggerRefresh}
                activeTab={activeTab}
              />
            ) : undefined
          }
        />
        <div className="d-flex align-items-center mb-5">
          <DateTimeRangePicker
            initialStartValue={dateFrom}
            initialEndValue={dateTo}
            setStartDatetime={setDateFrom}
            setEndDatetime={setDateTo}
          />
          <div
            className={styles.comparisonDateText}
          >{`compared to ${STRING.getComparisonDateString(
            prevFrom,
            prevTo
          )}`}</div>
        </div>
        <Nav
          tabs
          id="transaction-table-tabs"
          className={styles.transactionsTabs}
        >
          {tabNames.map((item) => {
            return (
              <>
                <NavItem>
                  <NavLink
                    className={classNames({
                      active: item?.label === activeTab,
                    })}
                    data-testid={`${item.label.toLowerCase()}-tab`}
                    id={`${item.label.toLowerCase()}-tab`}
                    onClick={() => {
                      navigate({
                        search: QUERY.update(location.search, {
                          tab: item.label.toLowerCase(),
                        }),
                      });
                    }}
                  >
                    {item?.label}
                  </NavLink>
                </NavItem>
              </>
            );
          })}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={tabNames[0].label} key={tabNames[0].label}>
            <DispensaryTransactionsList
              dateFrom={dateFromISO}
              dateTo={dateToISO}
              prevDateFrom={prevFrom}
              prevDateTo={prevTo}
              triggerRefresh={triggerRefresh}
              isActiveTab={activeTab === tabNames[0].label}
            />
          </TabPane>
          <TabPane tabId={tabNames[1].label} key={tabNames[1].label}>
            <TransactionDetailsTab
              dateFrom={dateFromISO}
              dateTo={dateToISO}
              triggerRefresh={triggerRefresh}
              isActiveTab={activeTab === tabNames[1].label}
            />
          </TabPane>
        </TabContent>
      </Card>
    </>
  );
};

export default DispensaryTransactions;
