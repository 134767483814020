import { apiWithOrg } from 'services/api';
import { useStateValue } from '../..';
import {
  action,
  actionFailure,
  actionSuccess,
  FETCH_EVENTS,
  FETCH_EVENTS_SESSION,
  FETCH_INDIVIDUAL_EVENTS,
} from '../actions';
import { EVENTS } from '../type';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useEvents = () => {
  const { state, dispatch } = useStateValue();
  const { events } = state;

  const toFetchEvent = async (values: EVENTS.IFetchEvents) => {
    dispatch(action(FETCH_EVENTS));
    try {
      const response = await apiWithOrg.get(`/events`, values);
      dispatch(actionSuccess(FETCH_EVENTS, response?.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_EVENTS, e));
    }
  };

  const toFetchIndividualEvent = async (event_id: string) => {
    dispatch(action(FETCH_INDIVIDUAL_EVENTS));
    try {
      const response = await apiWithOrg.get(`/events/${event_id}`);
      dispatch(actionSuccess(FETCH_INDIVIDUAL_EVENTS, response?.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_INDIVIDUAL_EVENTS, e));
    }
  };

  const toFetchEventsSessions = async (session_id: string) => {
    dispatch(action(FETCH_EVENTS_SESSION));
    try {
      const response = await apiWithOrg.get(`sessions/${session_id}`);
      dispatch(actionSuccess(FETCH_EVENTS_SESSION, response?.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_EVENTS_SESSION, e));
    }
  };

  return {
    events,
    toFetchEventsSessions,
    toFetchEvent,
    toFetchIndividualEvent,
  };
};

export default useEvents;
