import {
  CREATE_WEBHOOK,
  CREATE_WEBHOOK_FAILURE,
  CREATE_WEBHOOK_SUCCESS,
  FETCH_WEBHOOKS,
  FETCH_WEBHOOKS_FAILURE,
  FETCH_WEBHOOKS_SUCCESS,
  FETCH_WEBHOOK_DETAILS,
  FETCH_WEBHOOK_DETAILS_SUCCESS,
  FETCH_WEBHOOK_DETAILS_FAILURE,
  DELETE_WEBHOOK,
  DELETE_WEBHOOK_SUCCESS,
  DELETE_WEBHOOK_FAILURE,
  REVEAL_WEBHOOK_SECRET,
  REVEAL_WEBHOOK_SECRET_SUCCESS,
  REVEAL_WEBHOOK_SECRET_FAILURE,
} from './actions';
import { WEBHOOKS } from './type';

export const INITIAL_STATE: WEBHOOKS.WebhooksReducer = {
  isLoading: false,
  error: {} as WEBHOOKS.Error,
  webhooksList: {} as IPaginatedResponse<WEBHOOKS.IWebhooksItems>,
  createWebhook: false,
  createWebhookResponse: {} as WEBHOOKS.IWebhooksItems,
  webhookDetails: {} as WEBHOOKS.IWebhook,
};

const webhooksReducer = (
  action: IAction,
  state = INITIAL_STATE
): WEBHOOKS.WebhooksReducer => {
  switch (action.type) {
    case FETCH_WEBHOOKS:
      return {
        ...state,
        isLoading: true,
        webhooksList: {} as IPaginatedResponse<WEBHOOKS.IWebhooksItems>,
        error: {},
      };
    case FETCH_WEBHOOKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        webhooksList: action.payload,
        error: {},
      };
    case FETCH_WEBHOOKS_FAILURE:
      return {
        ...state,
        isLoading: false,
        webhooksList: {} as IPaginatedResponse<WEBHOOKS.IWebhooksItems>,
        error: { webhooks: action.payload.message },
      };

    case CREATE_WEBHOOK:
      return {
        ...state,
        isLoading: true,
        error: {},
        createWebhook: false,
        createWebhookResponse: action.payload,
      };
    case CREATE_WEBHOOK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createWebhook: true,
        createWebhookResponse: action.payload,
        error: {},
      };
    case CREATE_WEBHOOK_FAILURE:
      return {
        ...state,
        isLoading: false,
        createWebhook: false,
        createWebhookResponse: {} as WEBHOOKS.IWebhooksItems,
        error: { addWebhook: action.payload.message },
      };

    case FETCH_WEBHOOK_DETAILS:
      return {
        ...state,
        isLoading: true,
        webhookDetails: {} as WEBHOOKS.IWebhook,
      };
    case FETCH_WEBHOOK_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        webhookDetails: {
          ...action.payload.webhook,
          attempts: action.payload.attempts,
        },
        error: {},
      };
    case FETCH_WEBHOOK_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        webhookDetails: {} as WEBHOOKS.IWebhook,
        error: { fetchWebhookDetails: action.payload.message },
      };
    case DELETE_WEBHOOK:
      return { ...state, isLoading: true };
    case DELETE_WEBHOOK_SUCCESS:
      return { ...state, isLoading: false };
    case DELETE_WEBHOOK_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { deleteWebhook: action.payload.message },
      };
    case REVEAL_WEBHOOK_SECRET:
      return state;
    case REVEAL_WEBHOOK_SECRET_SUCCESS:
      return {
        ...state,
        webhookDetails: {
          ...state.webhookDetails,
          ...action.payload,
        },
      };
    case REVEAL_WEBHOOK_SECRET_FAILURE:
      return {
        ...state,
        error: { revealWebhookSecret: action.payload.message },
      };

    default:
      return state;
  }
};
export default webhooksReducer;
