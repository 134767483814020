import React from 'react';
import classNames from 'classnames';
import { UncontrolledTooltip } from 'reactstrap';
import CURRENCY from 'utils/currency';
import { BANKING } from 'state/banking/type';
import styles from '../styles.module.scss';
import { formatDateWithYear } from 'utils/date-format';

interface IProps {
  totalBalance: BANKING.IBankingTotalBalance;
}

const TotalBalanceSummary = ({ totalBalance }: IProps) => {
  const totalAvailBalance = CURRENCY.totalAvailableBalance(
    totalBalance?.total_balance,
    totalBalance?.holding_balance
  );
  const lastUpdated = totalBalance?.last_update_date;
  return (
    <div
      className={classNames('align-items-center mb-9', styles.summaryBanner)}
    >
      <div className={classNames('d-flex align-items-center mb-2')}>
        <span className={classNames(styles.bannarText)}>
          Total available balance
        </span>
        <i
          id="balance-tooltip"
          className={classNames(
            'ph-info-fill icon-md text-muted',
            styles.infoIcon
          )}
        />
        <UncontrolledTooltip placement="bottom" target={'balance-tooltip'}>
          {
            'This is the total amount of funds currently in your Swifter account.'
          }
        </UncontrolledTooltip>
        <span className={classNames(styles.bannarInfoText)}>
          last updated{' '}
          {lastUpdated && (
            <span className={classNames(styles.bannarInfoDateText)}>
              {formatDateWithYear(lastUpdated)}
            </span>
          )}
        </span>
      </div>
      <span className={classNames(styles.bannarAmount)}>
        {CURRENCY.convertToMainUnit(
          totalAvailBalance > 0 ? totalAvailBalance : 0
        )}
      </span>
    </div>
  );
};

export default TotalBalanceSummary;
