import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'reactstrap';
import usePayment from 'state/payment/hooks/usePayment';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import TransactionDetails from './tnxDispensaryDetails';
import TransactionPartnerDetails from './tnxPartnerDetails';
import GatewayTransactionDetails from './gatewayTransaction';

const Transaction = (): JSX.Element => {
  const { transactionId } = useParams<{ transactionId: string }>();

  const {
    toFetchIndividualTransaction,
    isAchOrderID,
    toFetchGatewayTransaction,
  } = usePayment();

  const { organisation } = useOrganisation();

  const { activeOrg } = organisation;

  useEffect(() => {
    if (transactionId) {
      if (isAchOrderID(transactionId)) {
        toFetchIndividualTransaction(transactionId);
      } else {
        toFetchGatewayTransaction(transactionId);
      }
    }
  }, [transactionId]);

  return (
    <>
      <Card className="card-profile border-0">
        {activeOrg?.is_partner ? (
          <TransactionPartnerDetails />
        ) : isAchOrderID(transactionId || '') ? (
          <TransactionDetails />
        ) : (
          <GatewayTransactionDetails />
        )}
      </Card>
    </>
  );
};

export default Transaction;
