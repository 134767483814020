import FormLabel from 'components/form-label';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import classNames from 'classnames';
import { AxiosResponse } from 'axios';
import { Form, Button, FormGroup, Input, Row } from 'reactstrap';
import ReactS3Uploader, { S3Response } from 'react-s3-uploader';
import { Controller, useForm } from 'react-hook-form';
import ErrorMessage from 'components/errorMessage';
import LocalSpinner from 'components/spinners/local';
import useActivation from 'state/kybActivation/hooks/useActivation';
import { apiOrgLive } from 'services/api';
import mime from 'mime-types';
import {
  CREATE_PAYOUTS,
  EXTERNAL_PAYOUT_STAGES,
  IActiveState,
  PAYOUTS_NAV_CONFIG,
} from 'components/modals/banking/payouts/payout';
import DatePicker from 'components/calender/date';
import NumberFormat from 'react-number-format';
import useBanking from 'state/banking/hooks/useBanking';
import { prefillFile } from 'utils/fileUploader';
import styles from '../styles.module.scss';
import CURRENCY from 'utils/currency';
import PayoutBalanceError from 'components/modals/banking/payoutError';

export type Document = {
  file: File | undefined;
  key: string;
  url: string | undefined;
  uploadId: string | undefined;
};

interface Iprops {
  setActiveState: Dispatch<SetStateAction<IActiveState>>;
  activeState: IActiveState;
}

const InvoiceDetails = ({ setActiveState, activeState }: Iprops) => {
  const initialInvoiceData: Document = {
    file: undefined,
    key: '',
    url: undefined,
    uploadId: undefined,
  };

  const [invoiceDetails, setInvoiceDetails] =
    useState<Document>(initialInvoiceData);

  const [uploadStatus, setUploadStatus] = useState({
    show: false,
    status: '',
    percent: 0,
  });

  const { banking, resetPayout, setPayoutAmount, setInvoiceDetail } =
    useBanking();

  const { external_account, stats, invoice_details } = banking;

  const [availableBalance, setAvailableBalance] = useState<number>(
    CURRENCY.totalAvailableBalance(stats.total_balance, stats.holding_balance)
  );

  useEffect(() => {
    setAvailableBalance(
      CURRENCY.totalAvailableBalance(stats.total_balance, stats.holding_balance)
    );
  }, [stats.holding_balance, stats.total_balance]);

  const payoutBalanceCheck = (amount: string) => {
    const amountNumber = parseFloat(amount.substring(1));
    return amountNumber <= availableBalance / 100;
  };

  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    setValue,
    getValues,
    control,
  } = useForm({
    defaultValues: {
      invoice_number: invoice_details?.id,
      invoice_date: invoice_details?.date,
      payout_amount: banking.payout_amount,
      invoice_doc: undefined,
    },
    mode: 'onSubmit',
  });

  useEffect(() => {
    const fileInput = document.getElementById(
      'invoice_doc'
    ) as HTMLInputElement;

    // check for url and name of file and prefill only if they exist
    if (invoice_details?.fileName && invoice_details?.fileURL) {
      const dataTransfer =
        new ClipboardEvent('').clipboardData || new DataTransfer();
      dataTransfer.items.add(
        new File(['invoice_doc'], invoice_details.fileName)
      );

      fileInput.files = dataTransfer.files;
      invoiceDetails.file = dataTransfer.files?.[0];
      invoiceDetails.uploadId = invoice_details.fileUploadId;
      invoiceDetails.url = invoice_details.fileURL;
    }
  }, []);

  const payoutZeroBalanceCheck = (amount: string) => {
    const amountNumber = parseFloat(amount.substring(1));
    return amountNumber >= 10;
  };

  const [payoutError, setPayoutError] = useState(false);

  const onFormSubmit = (values: { [x: string]: string }) => {
    if (payoutZeroBalanceCheck(values.payout_amount)) {
      if (payoutBalanceCheck(values.payout_amount)) {
        setPayoutAmount(values.payout_amount);
        if (invoiceDetails.uploadId) {
          setInvoiceDetail({
            date: values.invoice_date,
            id: values.invoice_number,
            upload_id: invoiceDetails.uploadId,
            fileName: invoiceDetails.file?.name || '',
            fileUploadId: invoiceDetails.uploadId,
            fileURL: invoiceDetails.url || '',
          });
        }
        activeState.activeForm = EXTERNAL_PAYOUT_STAGES.BANK_DETAILS;
        PAYOUTS_NAV_CONFIG[CREATE_PAYOUTS.INVOICE_DETAILS].isCompleted = true;
        setActiveState({ ...activeState });
      } else {
        setPayoutError(true);
      }
    } else {
      setError('payout_amount', {
        type: 'custom',
        message: 'The payout amount should be at least $10.00',
      });
    }
  };

  const formSubmission = () => {
    handleSubmit(onFormSubmit)();
  };

  const fileUpload = (file: File) => {
    clearErrors('invoice_doc');
    const acceptedMimeTypes = ['image/png', 'image/jpeg', 'application/pdf'];
    const maxFileSize = 5 * 1024 * 1024;
    const randomString = Math.random().toString(36);
    if (!acceptedMimeTypes.includes(file.type)) {
      invoiceDetails.key = randomString;
      setInvoiceDetails({ ...invoiceDetails });
      setError('invoice_doc', {
        type: 'custom',
        message: 'Please upload a document of type pdf, jpeg or png',
      });
      return false;
    }
    if (file.size > maxFileSize) {
      invoiceDetails.key = randomString;
      setInvoiceDetails({ ...invoiceDetails });

      setError('invoice_doc', {
        type: 'custom',
        message: 'Please upload a document of size less than 5MB.',
      });
      return false;
    }
    return true;
  };

  // make the loader invisible once the upload is complete
  useEffect(() => {
    if (uploadStatus.status === 'Upload completed') {
      setTimeout(() => setUploadStatus({ ...uploadStatus, show: false }), 5000);
    }
  }, [uploadStatus.status]);

  const { deleteDocument } = useActivation();
  const fileExistCheck = () => {
    if (invoiceDetails.uploadId) {
      deleteDocument(invoiceDetails.uploadId);
    }
  };

  // get signing url from backend
  const getSignedUrl = async (
    file: File,
    callback: (params: S3Response) => void
  ) => {
    fileExistCheck();

    if (fileUpload(file)) {
      const params = {
        file_name: `${
          file.name.split('.')[0]
        }_invoice_document.${file.name.slice(file.name.lastIndexOf('.') + 1)}`,
        file_type: mime.contentType(file.name) as string,
        file_size: file.size,
        name: 'invoiceDocument',
      };
      try {
        const response: AxiosResponse = await apiOrgLive.post(
          '/uploads/sign',
          params
        );
        invoiceDetails.url = response.data.object_url;
        invoiceDetails.file = file;
        setInvoiceDetails({ ...invoiceDetails });
        const data: S3Response = {
          signedUrl: response.data.signed_url,
          publicUrl: response.data.object_url || '',
          filename: file.name,
          fileKey: response.data.success_token,
        };
        callback(data);
      } catch (e) {
        console.log(e);
      }
    } else {
      setUploadStatus({ show: false, status: '', percent: 0 });
    }
  };

  const onUploadFinish = async (result: S3Response, file: File) => {
    const uploaded: AxiosResponse = await apiOrgLive.get(
      `/uploads/confirm?success_token=${result.fileKey}`
    );
    if (uploaded) {
      invoiceDetails.file = file;
      invoiceDetails.uploadId = uploaded?.data?.id;
      setInvoiceDetails({ ...invoiceDetails });
    }
  };

  const onProgress = (percent: number, status: string, _: File) => {
    setUploadStatus({
      show: true,
      status,
      percent,
    });
  };

  const clearFile = () => {
    const randomString = Math.random().toString(36);
    setUploadStatus({ ...uploadStatus, show: false });
    invoiceDetails.key = randomString;
    invoiceDetails.file = undefined;
    deleteDocument(invoiceDetails?.uploadId);
    invoiceDetails.uploadId = undefined;
    setInvoiceDetails({ ...invoiceDetails });
    setValue('invoice_doc', undefined);
  };

  const onError = (message: string) => {
    setUploadStatus({ ...uploadStatus, show: false });
    clearFile();
    setError('invoice_doc', {
      type: 'custom',
      message: `${message} Please try again`,
    });
  };

  const backButton = () => {
    PAYOUTS_NAV_CONFIG[CREATE_PAYOUTS.INVOICE_DETAILS].isCompleted = false;
    activeState.activeForm = CREATE_PAYOUTS.VENDOR_DETAILS;
    setActiveState({ ...activeState });
  };

  useEffect(() => {
    const footerElement = (
      <>
        <Button
          className="btn btn-primary mr-2"
          type="submit"
          onClick={() => backButton()}
        >
          <span className="d-flex">Back</span>
        </Button>
        <Button
          color="primary"
          className="btn btn-primary"
          type="submit"
          onClick={() => formSubmission()}
        >
          <span className="d-flex">
            Next <i className="ph-arrow-right pl-1"></i>
          </span>
        </Button>
      </>
    );
    activeState.footer = footerElement;
    setActiveState({ ...activeState });
  }, []);

  useEffect(() => {
    const footerElement = (
      <>
        <Button
          className="btn btn-primary mr-2"
          type="submit"
          onClick={() => backButton()}
        >
          <span className="d-flex">Back</span>
        </Button>
        <Button
          color="primary"
          className="btn btn-primary"
          type="submit"
          disabled={uploadStatus.show}
          onClick={() => formSubmission()}
        >
          <span className="d-flex">
            Next <i className="ph-arrow-right pl-1"></i>
          </span>
        </Button>
      </>
    );
    activeState.footer = footerElement;
    setActiveState({ ...activeState });
  }, [uploadStatus.show]);

  return (
    <>
      <div
        className={classNames(
          styles.formContainer,
          'hide-scrollbars overflow-hidden'
        )}
        style={{ height: '90%' }}
      >
        <h1 className={classNames(styles.heading, 'mb-8 pb-2')}>
          Invoice details
        </h1>
        <Form role="form" onSubmit={handleSubmit(onFormSubmit)}>
          <Row>
            <FormGroup className="pl-3 mr-3">
              <FormLabel
                isRequired
                data-testid="invoice_date"
                for="invoice_date"
                htmlFor="invoice_date"
                element="invoice_date"
                label="Invoice Date"
                className={classNames(
                  'body-subtext-medium d-block',
                  styles.fieldLabel
                )}
              ></FormLabel>
              <Controller
                id="invoice_date"
                control={control}
                name="invoice_date"
                rules={{ required: true }}
                defaultValue={''}
                render={(field) => (
                  <DatePicker
                    format="MM-DD-YYYY"
                    beforeDate={new Date().toISOString()}
                    placeholder="mm-dd-yyyy"
                    className={classNames(
                      styles.inputDate,
                      styles.fixedWidth,
                      errors?.invoice_date && styles.borderAlert
                    )}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    ref={field.ref}
                    value={field.value}
                    name="invoice_date"
                  />
                )}
              />

              {errors?.invoice_date && (
                <ErrorMessage
                  isValidation
                  message="Enter invoice date"
                  preIcon={
                    <i className="ph-warning-circle-fill icon-md dangerIcon"></i>
                  }
                  testId="invoice_date"
                />
              )}
            </FormGroup>
            <FormGroup>
              <FormLabel
                isRequired
                check
                element="invoice_number"
                label="Invoice number"
                className={classNames(
                  'body-subtext-medium ',
                  styles.fieldLabel
                )}
              ></FormLabel>
              <Input
                style={{ width: '281px' }}
                name="invoice_number"
                id="invoice_number"
                className={errors.invoice_number ? styles.borderAlert : ''}
                innerRef={register({
                  validate: {
                    required: (value) => {
                      return !(value.length <= 0);
                    },
                  },
                })}
              />
              <div>
                {errors.invoice_number && (
                  <>
                    <span className="d-flex">
                      <i className="ph-warning-circle-fill icon-md dangerIcon pt-2 pr-1"></i>
                      <ErrorMessage
                        isValidation
                        message="Enter an invoice number"
                      />
                    </span>
                  </>
                )}
              </div>
            </FormGroup>
          </Row>
          <FormGroup>
            <FormLabel
              isRequired
              element="payout_amount"
              label="Payout amount"
              className={styles.fieldLabel}
            />
            <Controller
              control={control}
              name="payout_amount"
              id="payout_amount"
              rules={{ required: true }}
              render={(field) => (
                <NumberFormat
                  name="payout_amount"
                  id="payout_amount"
                  fixedDecimalScale
                  allowNegative={false}
                  prefix={'$'}
                  decimalScale={2}
                  className={classNames(
                    'd-block form-control',
                    errors?.payout_amount && styles.borderAlert
                  )}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    field.onChange(e);
                  }}
                  value={field.value}
                  placeholder="$5000.00"
                  getInputRef={field.ref}
                />
              )}
            />
            {errors?.payout_amount && (
              <ErrorMessage
                isValidation
                message={
                  errors.payout_amount.message ||
                  'Please enter a payout amount.'
                }
                preIcon={
                  <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
                }
              />
            )}
            <span className={styles.subLabel}>
              The Payout amount should be less than or equal to the total
              available balance
            </span>
          </FormGroup>
          <FormGroup>
            <FormLabel
              isRequired
              element="invoice_doc"
              label="Invoice"
              className={classNames('body-subtext-medium', styles.fieldLabel)}
            ></FormLabel>

            <div className={classNames('d-flex')} style={{ width: '578px' }}>
              <ReactS3Uploader
                name="invoice_doc"
                id="invoice_doc"
                getSignedUrl={getSignedUrl}
                onFinish={onUploadFinish}
                key={initialInvoiceData.key}
                multiple={false}
                className={classNames(
                  errors?.invoice_doc && styles.borderAlert,
                  uploadStatus.show && styles.disabledInput
                )}
                inputRef={register({
                  validate: {
                    required: (value) => {
                      return !(value.length <= 0);
                    },
                  },
                })}
                accept="image/png,image/jpeg, application/pdf"
                onProgress={onProgress}
                onError={onError}
                uploadRequestHeaders={{}}
                contentDisposition="auto"
              />

              {invoiceDetails.file ? (
                <i
                  onClick={() => clearFile()}
                  className={classNames(
                    'ph-x icon-md d-flex p-2',
                    styles.crossBtn
                  )}
                ></i>
              ) : (
                <i
                  className={classNames(
                    'icon-md d-flex p-2',
                    styles.crossBtn,
                    errors.invoice_doc && styles.alertCrossBtn
                  )}
                ></i>
              )}
            </div>
            {uploadStatus.show && (
              <div
                className="d-flex align-items-center justify-content-between pt-2"
                style={{ width: '578px' }}
              >
                <span className={classNames(styles.uploadState)}>
                  {uploadStatus.status} - {uploadStatus.percent}% uploaded
                </span>

                {uploadStatus.status === 'Upload completed' ? (
                  <i className="ph-check-circle-fill text-success icon-xl"></i>
                ) : (
                  <LocalSpinner size="sm" color="gray" isLoading />
                )}
              </div>
            )}
            {errors?.invoice_doc && (
              <ErrorMessage
                isValidation
                message={
                  errors?.invoice_doc.message ||
                  'Please upload the required documents'
                }
                preIcon={
                  <i className="ph-warning-circle-fill icon-md dangerIcon"></i>
                }
                testId="invoice_doc"
              />
            )}
            {!uploadStatus.show && !errors.invoice_doc && (
              <div
                className={classNames(
                  'mt-1 body-subtext-regular',
                  styles.fileUploadInfo
                )}
              >
                The document should be in pdf, jpeg or png format (max. size
                5MB)
              </div>
            )}
          </FormGroup>
        </Form>
        {payoutError && (
          <PayoutBalanceError
            openModal={payoutError}
            setModal={setPayoutError}
            externalPayoutError={true}
            availableBalance={availableBalance}
          />
        )}
      </div>
    </>
  );
};

export default InvoiceDetails;
