import React, { Dispatch, SetStateAction, useState } from 'react';
import useAuth from 'state/auth/hooks/useAuth';
import VerifyOtpContainer from 'components/verifyOTP';
import styles from './styles.module.scss';

export interface IProps {
  otp: string;
  setOTP: Dispatch<SetStateAction<string>>;
  onWriteOffSubmit: () => void;
}

const WriteOffAuth = ({ otp, setOTP, onWriteOffSubmit }: IProps) => {
  const {
    auth: { user },
  } = useAuth();
  const [hasOtpErrored, setOtpError] = useState<boolean>(false);

  const maskEmail = (arg: string) => {
    const maskedEmail = arg.replace(
      /^(.)(.*)(.@.*)$/,
      (_, a, b, c) => a + b.replace(/./g, '*') + c
    );
    return maskedEmail;
  };

  return (
    <div className={styles.writeOffAuth} data-testid="write-off-otp-modal">
      <h1 className="body-subtext-semibold mb-1">Please check your email</h1>
      <p className="mb-7 pb-1 body-subtext subHeading">
        Enter the 6-digit code we just sent you on your registered email id{' '}
        {maskEmail(user?.user_info?.email)} to write off.
      </p>
      <VerifyOtpContainer
        formSubmit={onWriteOffSubmit}
        showError={hasOtpErrored}
        otp={otp}
        setOtp={setOTP}
        setError={setOtpError}
      />
    </div>
  );
};

export default WriteOffAuth;
