export const EMAIL_VALIDATION =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/;
export const NAME_VALIDATION = /^[A-Za-z'-]+([ A-Za-z][A-Za-z'-]+)*$/;
export const FULL_NAME_VALIDATION = /^[A-Za-z\s]+$/;
export const IFSC_VALIDATION = /([A-Za-z]{4})([a-zA-Z0-9]{7})$/;
export const URL_VALIDATION =
  /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;
export const BUSINESS_URL_VALIDATION =
  /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;
export const IMAGE_URL_VALIDATION =
  /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)\.(jpe?g|png|gif|bmp)$/;
export const NUMBER_VALIDATION = /^\d+([.]\d{1,2})?$/;
export const SESSION_VALIDATION = /^sess_[a-zA-Z0-9]{5,}$/;
export const WHOLE_NUMBER_VALIDATION = /^[1-9][0-9]*$/;
export const PASSWORD_VALIDATION = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;
export const BANK_ROUTING_NUMBER = /^[0-9]{9}$/;
export const BANK_ACCOUNT_NUMBER = /^[a-zA-Z0-9]{4,17}$/;
export const MASKED_BANK_ACCOUNT_NUMBER = /^[*]{3,16}[a-zA-Z0-9]{4}$/;
export const ZIP_CODE_VALIDATION = /^[0-9]{5}$/;
export const KYB_PHONE_NUMBER_VALIDATION = /^(\d{3}-\d{3}-\d{4})|(\d{10})$/;
export const PHONE_NUMBER_VALIDATION = /^(\+1-\d{3}-\d{3}-\d{4})|(\d{10})$/;
export const SSN_VALIDATION = /^[0-9*]{3}[-][0-9*]{2}[-][0-9]{4}$/;
export const EIN_VALIDATION = /^\d{2}[-]\d{7}$/;
export const MASKED_DL_ID_VALIDATION = /^[*]{1,5}[a-zA-Z0-9]+$/;
export const DL_ID_VALIDATION = /^[a-zA-Z0-9]+$/;
