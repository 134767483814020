import classNames from 'classnames';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Button } from 'reactstrap';
import {
  IActiveState,
  PAYOUTTYPE,
  PAYOUT_STAGE,
  SELF_PAYOUT_STAGES,
} from 'components/modals/banking/payouts/payout';
import useBanking from 'state/banking/hooks/useBanking';
import CURRENCY from 'utils/currency';
import useAuth from 'state/auth/hooks/useAuth';
import VerifyOtpContainer from 'components/verifyOTP';
import styles from './styles.module.scss';

interface Iprops {
  setActiveState: Dispatch<SetStateAction<IActiveState>>;
  activeState: IActiveState;
}
const Confirmation = (props: Iprops): JSX.Element => {
  // const { modal, setModal, otp, setOtp, showToast } = props;
  const { setActiveState, activeState } = props;

  const { banking, toInitiateSelfPayout, toInitiateExternalPayout } =
    useBanking();

  const { auth, verifyOtpChallenge, resetOtp } = useAuth();
  const { error, user } = auth;
  const {
    external_account,
    payout_amount,
    invoice_details,
    vendor_id,
    vendor_bank_account,
    notes,
  } = banking;

  const [otp, setOtp] = useState('');
  const userEmail = user?.user_info?.email ?? '';

  const [showError, setError] = useState(false);

  const formSubmit = () => {
    if (
      external_account.business_fi_account_id &&
      payout_amount &&
      activeState.payoutStage === PAYOUT_STAGE.self
    ) {
      setError(false);
      toInitiateSelfPayout({
        account_id: external_account.business_fi_account_id,
        type: PAYOUTTYPE.SELF,
        amount: CURRENCY.convertToSmallestUnit(
          CURRENCY.getValueFromCurrencyStr(payout_amount)
        ),
        notes,
      });
    } else if (
      activeState.payoutStage === PAYOUT_STAGE.external &&
      payout_amount &&
      invoice_details?.id &&
      invoice_details?.upload_id &&
      invoice_details?.date &&
      vendor_bank_account?.account_id &&
      vendor_id
    ) {
      setError(false);
      toInitiateExternalPayout({
        amount: CURRENCY.convertToSmallestUnit(
          CURRENCY.getValueFromCurrencyStr(payout_amount)
        ),
        type: PAYOUTTYPE.EXTERNAL,
        account_id: vendor_bank_account.account_id,
        invoice_id: invoice_details?.id,
        invoice_upload_id: invoice_details?.upload_id,
        invoice_date: invoice_details?.date,
        notes,
      });
    } else {
      setError(true);
    }
  };

  const sendOTPforVerification = () => {
    if (banking?.auth_session?.challenge_data?.challenge_id) {
      verifyOtpChallenge({
        challenge_id: banking.auth_session.challenge_data?.challenge_id,
        challenge_related_info: { submitted_otp: otp },
      });
    } else {
      setError(true);
    }
  };

  const submitForm = (otpValue: string) => {
    if (otpValue) {
      if (otpValue?.length === 6) {
        sendOTPforVerification();
      }
    } else {
      setError(true);
    }
  };

  const maskEmail = (email: string) => {
    const maskedEmail = email.replace(
      /^(.)(.*)(.@.*)(.*)$/,
      (_, a, b, c) => a + b.replace(/./g, '*') + c
    );
    return maskedEmail;
  };

  useEffect(() => {
    const footerElement = (
      <>
        <Button
          // color="primary"
          className="btn btn-primary mr-2"
          type="submit"
          onClick={() => {
            activeState.activeForm = SELF_PAYOUT_STAGES.REVIEW;
            setActiveState({ ...activeState });
          }}
        >
          <span className="d-flex">Back</span>
        </Button>
        <Button
          color="primary"
          className="btn btn-primary"
          type="submit"
          data-testid="payout-btn"
          onClick={() => submitForm(otp)}
        >
          <span className="d-flex">
            Initiate payout <i className="ph-arrow-right pl-1"></i>
          </span>
        </Button>
      </>
    );
    const disableFooter = (
      <>
        <Button
          // color="primary"
          className="btn btn-primary mr-2"
          type="submit"
          onClick={() => {
            activeState.activeForm = SELF_PAYOUT_STAGES.REVIEW;
            setActiveState({ ...activeState });
          }}
        >
          <span className="d-flex">Back</span>
        </Button>
        <Button
          color="primary"
          className="btn btn-primary"
          type="submit"
          disabled
          onClick={() => submitForm(otp)}
        >
          <span className="d-flex">
            Initiate payout <i className="ph-arrow-right pl-1"></i>
          </span>
        </Button>
      </>
    );
    if (otp.length === 6) {
      activeState.footer = footerElement;
      setActiveState({ ...activeState });
    }
    if (
      otp.length !== 6 ||
      error?.otpChallengeVerify?.error_code ===
        'MAX_CHALLENGE_VALIDATION_LIMIT_EXCEEDED'
    ) {
      activeState.footer = disableFooter;
      setActiveState({ ...activeState });
    }
  }, [otp, error?.otpChallengeVerify, banking.auth_session]);

  return (
    <div
      className={classNames(
        styles.formContainer,
        'hide-scrollbars overflow-hidden'
      )}
    >
      <h1 className={classNames(styles.heading, 'mb-6 pb-2')}>Confirmation</h1>

      <p className={styles.emailInfo}>Please check your email</p>
      <p className={classNames(styles.otpBodyText, 'mb-8')}>
        To authorise the payout, please enter the 6-digit code we just sent you
        on your registered email id {maskEmail(userEmail)}
      </p>

      <VerifyOtpContainer
        formSubmit={() => formSubmit()}
        showError={showError}
        otp={otp}
        setOtp={setOtp}
        setError={setError}
      />
      <div className="pt-5">
        <div className="mb-3">
          <span className={classNames(styles.otpInfo)}>
            By initiating this ACH payout, you authorise us to electronically
            debit your account and credit the recipient's account for the amount
            specified. You are responsible for ensuring that the recipient's
            account information is accurate. You also understand that this
            Payout is subject to the rules and regulations of NACHA. Kindly
            note, once initiated the transaction cannot be cancelled.
          </span>
        </div>
        <span className={classNames(styles.otpInfo)}>
          By continuing with this transaction, you agree to these terms and
          conditions.
        </span>
      </div>
    </div>
  );
};

export default Confirmation;
