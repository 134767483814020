import React from 'react';
import styles from './styles.module.scss';
import PaymentIcon from 'assets/img/store/payment.svg';
import IntegrationIcon from 'assets/img/store/integration.svg';
import BarChartIcon from 'assets/img/store/barChart.svg';
import AovGraph from 'assets/img/store/AOV-graph.svg';

const Info = () => {
  const InfoPoint = ({
    imgBg,
    icon,
    boldText,
    text,
  }: {
    imgBg: string;
    icon: string;
    boldText: string;
    text: string;
  }) => {
    return (
      <div className={styles.infoPoint}>
        <div style={{ backgroundColor: imgBg }}>
          <img src={icon} />
        </div>
        <div className="pl-4 flex-grow-0">
          <span className={styles.oneAppLink}>
            <span className={styles.oneAppText}>{boldText}</span>
            {text}
          </span>
        </div>
      </div>
    );
  };

  const PaymentOption = ({ name, icon }: { name: string; icon: string }) => {
    return (
      <div className={styles.paymentOptionItem}>
        <img src={icon} />
        <div className="body-subtext-medium">{name}</div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.storeFooter}>
        <span>Need assistance?</span>
        <div>
          <span className={styles.oneAppLink}>
            Setup a meeting with a{' '}
            <a
              target="_blank"
              href="https://calendly.com/treezpay"
              style={{ color: '#1A4007', textDecoration: 'underline' }}
            >
              Treezpay representative
            </a>{' '}
          </span>
        </div>
      </div>
      <div className={styles.requestStoreInfo}>
        <div style={{ width: '600px' }}>
          <div className="d-flex flex-column mb-9" style={{ gap: '20px' }}>
            <InfoPoint
              imgBg="#CFDEED"
              icon={PaymentIcon}
              boldText="Greatest number of compliant and redundant "
              text="solutions in cannabis payments with new providers added frequently"
            />
            <InfoPoint
              imgBg="#FBF1CF"
              icon={IntegrationIcon}
              boldText="Fast and frictionless onboarding "
              text="of all solutions via one, universal application called TreezPay OneApp"
            />
            <InfoPoint
              imgBg="#F1D9D9"
              icon={BarChartIcon}
              boldText="Fully Integrated "
              text="transactions allow for 100% reporting visibility and seamless reconciliation of all payment types via TreezPay Portal"
            />
          </div>
          <div className={styles.paymentOptions}>
            <div
              className="mb-5 body-subtext-medium"
              style={{ fontSize: '15px', color: '#595959' }}
            >
              Unlock higher AOV with cashless payments
            </div>
            <div
              className="d-flex flex-row mb-9 justify-content-between"
              style={{ gap: '12px' }}
            >
              <img src={AovGraph} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Info;
