import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/plugins/nucleo/css/nucleo.css';
import 'assets/scss/swifter.scss';
import React, { useEffect, useRef } from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import routeConfig from 'routes/config';
import useAuth from 'state/auth/hooks/useAuth';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import { getConfig, getRoutes } from 'layouts/helper';
import views from 'views';
import Layout from 'layouts/layout';
import AuthLayout from 'layouts/auth';
import PrivateRoute from './private';
import DashboardLayout from 'layouts/dashboard';

const AppRoutes = (): JSX.Element => {
  const { organisation } = useOrganisation();
  const { activeOrg, activeOrgId } = organisation;
  const { auth } = useAuth();
  const { roles } = auth.user;
  const view = activeOrg?.is_partner ? views.partner : (activeOrg?.is_payment_enabled && activeOrg.is_gateway_payment_enabled) || activeOrg?.is_payment_enabled ? views.dispensary : views.nonAchDispensaryViews;

  const renderRoutes = (layout: string, isPublic = false) => {
    const userRoles = isPublic ? [] : roles;

    return (
      <Routes>
        {getRoutes(userRoles, activeOrgId || '', getConfig(layout, view))}
      </Routes>
    );
  };

  return (
    <>
      <Routes>
        {/* authenticated routes */}
        <Route element={<PrivateRoute />}>
          {/** landing page */}
          <Route
            path="/"
            element={
              <Navigate
                to={`/${routeConfig.STORES.layout}/${routeConfig.STORES.LIST.path}`}
                replace
              />
            }
          />
          <Route element={<Layout />}>
            {/* store listing */}
            <Route
              path={`${routeConfig.STORES.layout}/*`}
              element={renderRoutes(routeConfig.STORES.layout)}
            />
          </Route>
          {/* dashboard routes  */}
          <Route path="/:orgId" element={<DashboardLayout />}>
            {activeOrg?.is_partner && (
              <>
                <Route
                  path={`${routeConfig.PARTNER_STORES.layout}/*`}
                  element={renderRoutes(routeConfig.PARTNER_STORES.layout)}
                />
                <Route
                  path={`${routeConfig.PARTNER_TRANSACTIONS.layout}/*`}
                  element={renderRoutes(
                    routeConfig.PARTNER_TRANSACTIONS.layout
                  )}
                />
                <Route
                  index
                  element={
                    <Navigate
                      to={`${routeConfig.PARTNER_STORES.layout}/${routeConfig.PARTNER_STORES.stores.path}`}
                      replace
                    />
                  }
                />
              </>
            )}
            {!activeOrg?.is_partner && (
              <>
                <Route
                  path={`${routeConfig.ANALYTICS.layout}/*`}
                  element={renderRoutes(routeConfig.ANALYTICS.layout)}
                />
                <Route
                  path={`${routeConfig.PAYMENTS.layout}/*`}
                  element={renderRoutes(routeConfig.PAYMENTS.layout)}
                />
                <Route
                  path={`${routeConfig.PAYMENT_LINKS.layout}/*`}
                  element={renderRoutes(routeConfig.PAYMENT_LINKS.layout)}
                />
                <Route
                  path={`${routeConfig.CUSTOMERS.layout}/*`}
                  element={renderRoutes(routeConfig.CUSTOMERS.layout)}
                />
                <Route
                  path={`${routeConfig.BANKING.layout}/*`}
                  element={renderRoutes(routeConfig.BANKING.layout)}
                />
                <Route
                  path={`${routeConfig.PAYOUTS.layout}/*`}
                  element={renderRoutes(routeConfig.PAYOUTS.layout)}
                />
                <Route
                  index
                  element={
                    <Navigate
                      to={`${routeConfig.ANALYTICS.layout}/${routeConfig.ANALYTICS.HOME.path}`}
                      replace
                    />
                  }
                />
                <Route
                  path={`${routeConfig.SETTINGS.layout}/*`}
                  element={renderRoutes(routeConfig.SETTINGS.layout)}
                />
              </>
            )}
            <Route
              path={`${routeConfig.DEVELOPERS.layout}/*`}
              element={renderRoutes(routeConfig.DEVELOPERS.layout)}
            />
          </Route>
        </Route>

        {/* unauthenticated routes */}
        <Route element={<Layout />}>
          {/** /auth */}
          <Route
            path={`${routeConfig.AUTH.layout}/*`}
            element={<AuthLayout />}
          />
          {/** email verification */}
          <Route
            path={`${routeConfig.VERIFICATION.path}`}
            element={
              <Navigate
                to={`${routeConfig.AUTH.layout}${routeConfig.AUTH.LOGIN.path}`}
                replace
              />
            }
          />
        </Route>
        <Route path="*" element={<Navigate to={routeConfig.ROOT} replace />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
