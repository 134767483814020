import { PAYMENTS as IPAYMENTS } from 'state/payment/type';

// skipcq: JS-0337
export namespace STATS {
  export type ISummary = Record<graphType, number>;

  export interface ISummaryCard {
    current: ISummary;
    previous: ISummary;
  }

  export type ValuePoint = {
    gross_amount: number;
    transaction_count: number;
    tip: number;
    fee: number;
  };

  // payment method type values used on backend
  export enum IPaymentType {
    ALL = 'All',
    DEBIT = 'Debit',
    ACH = 'ACH',
    CATM = 'ATM',
  }

  export enum graphType {
    gross_amount = 'gross_amount',
    payments = 'payments',
    aov = 'aov',
  }

  export enum IIntervalType {
    HOURLY = 'hourly',
    DAILY = 'daily',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
  }

  export type IDataPointValue = {
    date: string;
    value: Array<ValuePoint>;
  };

  export type IPaymentTypeGraph = {
    payment_type: IPaymentType;
    [IIntervalType.HOURLY]: IDataPointValue[];
    [IIntervalType.DAILY]: IDataPointValue[];
    [IIntervalType.WEEKLY]: IDataPointValue[];
    [IIntervalType.MONTHLY]: IDataPointValue[];
  };

  export interface ICustomerTopOrder {
    date: string;
    gross_amount: number;
    status: IPAYMENTS.ITransactionStatus;
    customer: string;
    consumer_profile_id: string | null;
    external_id: string;
    source: IPAYMENTS.ITransactionSource;
  }

  export interface ICustomerFrequentOrder {
    gross_amount: number;
    net_amount: number;
    customer: string;
    consumer_profile_id: string | null;
    number_of_orders: number;
  }

  export interface ICustomerFrequentReturn {
    customer: string;
    number_of_returns: number;
    returned_amount: number;
    consumer_profile_id: string | null;
    amount_recovered: number;
  }

  export interface ICustomerInfo {
    topOrders: IPaginatedResponse<ICustomerTopOrder>;
    freqOrders: IPaginatedResponse<ICustomerFrequentOrder>;
    freqReturns: IPaginatedResponse<ICustomerFrequentReturn>;
  }

  export interface IError {
    summaryCard?: {
      message: string;
    } | null;
    graph?: {
      message: string;
    } | null;
    customerInfo?: {
      message: string;
    } | null;
  }

  export type IGraphRequest = {
    start_date: string;
    end_date: string;
    interval_types: string;
    payment_type?: string;
  };

  export type ISummaryCardRequest = {
    start_date: string;
    end_date: string;
    prev_start_date: string;
    prev_end_date: string;
    payment_type?: string;
  };

  export type ICustomerInfoRequest = {
    start_date: string;
    end_date: string;
    key?: string;
    limit?: number;
  };

  export interface IStatsReducer {
    isLoading: number;
    activeSummaryTab: graphType;
    intervalTypes: STATS.IIntervalType[];
    summaryCard: ISummaryCard;
    graph: IPaymentTypeGraph[];
    customerInfo: ICustomerInfo;
    error: IError;
  }
}
