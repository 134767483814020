import React from 'react';
import { ROLES } from 'components/rbac/type';
import routeConfig from 'routes/config';
import CustomerDetails from 'views/customers/details';
import Customers from 'views/customers/index';
import Dashboard from 'views/dashboard';
import APIKeys from 'views/developers/APIKeys';
import EventsDetails from 'views/developers/eventdetails';
import Events from 'views/developers/events';
import Webhook from 'views/developers/webhooks/webhook';
import WebhookDetails from 'views/developers/webhooks/details';
import PaymentSessions from 'views/sessions';
import Session from 'views/sessions/details';
import GeneralSettings from 'views/settings';
import LogisticsSettings from 'views/settings/logistics';
import Transaction from 'views/transactions/details/index';
import PaymentLinks from 'views/products/paymentLinks';
import PaymentRefunds from 'views/payments/refunds/refunds';
import BankingStatements from 'views/banking/statements';
import Payout from 'views/payoutsList/payout';
import PartnerStores from 'views/partnerStores';
import DispensaryTransactions from 'views/transactions/dispensaryList';
import PartnerTransactions from 'views/transactions/PartnerList';
import PayoutList from 'views/payoutsList';
import FailedTransactionsList from 'views/failedTransactions';
import Login from 'views/auth/login';
import Stores from 'views/stores';
import NoAccessToStore from 'views/stores/noAccess';
import PaymentConfig from 'views/settings/config';
import Tips from 'views/tips';

export interface IView {
  path: string;
  name: string; // skipcq: JS-0122
  icon?: string;
  component?: JSX.Element;
  layout?: string;
  hasSidebar?: boolean;
  roles?: Array<ROLES>;
  children?: Array<IView>;
  search?: string;
  title: string;
}

const commonViews: IView[] = [
  {
    path: routeConfig.AUTH.LOGIN.path,
    name: 'Login',
    icon: 'ni ni-key-25 text-info',
    component: <Login />,
    // component: <LoginSwifter />,
    layout: routeConfig.AUTH.layout,
    hasSidebar: false,
    title: 'Log in',
  },
  // {
  //   path: routeConfig.AUTH.RESET.path,
  //   name: 'Reset',
  //   icon: 'ni ni-circle-08 text-pink',
  //   component: <Reset />,
  //   layout: routeConfig.AUTH.layout,
  //   hasSidebar: false,
  //   title: 'Reset Password',
  // },
  {
    path: routeConfig.STORES.LIST.path,
    name: 'Stores',
    icon: 'ph-gear-six',
    component: <Stores />,
    layout: routeConfig.STORES.layout,
    hasSidebar: false,
    title: 'Stores',
    children: [
      {
        path: routeConfig.STORES.LIST.path,
        name: 'Stores',
        component: <Stores />,
        layout: routeConfig.STORES.layout,
        hasSidebar: false,
        title: 'Stores',
      },
      // {
      //   path: routeConfig.STORES.NEW.path,
      //   name: 'Stores',
      //   component: <RequestNewStore />,
      //   layout: routeConfig.STORES.layout,
      //   hasSidebar: false,
      //   title: 'Request New Store',
      // },
      {
        path: routeConfig.STORES.ERROR_ACCESS_DENIED.path,
        name: 'Stores',
        component: <NoAccessToStore />,
        layout: routeConfig.STORES.layout,
        hasSidebar: false,
        title: 'No access',
      },
    ],
  },
];

const partnerViews: IView[] = [
  ...commonViews,
  {
    path: routeConfig.PARTNER_STORES.stores.path,
    name: 'Stores',
    icon: 'ph-storefront',
    component: <PartnerStores />,
    layout: routeConfig.PARTNER_STORES.layout,
    hasSidebar: true,
    roles: [ROLES.ADMIN, ROLES.MANAGER, ROLES.ANALYST],
    title: 'Stores',
  },
  {
    path: `${routeConfig.PARTNER_TRANSACTIONS.transactions.path}`,
    name: 'Transactions',
    icon: 'ph-currency-dollar',
    component: <PartnerTransactions />,
    layout: routeConfig.PARTNER_TRANSACTIONS.layout,
    hasSidebar: true,
    roles: [ROLES.ADMIN, ROLES.MANAGER, ROLES.ANALYST],
    title: 'Transactions',
  },
  // {
  //   path: routeConfig.SETTINGS.TEAM.path,
  //   name: 'Settings',
  //   icon: 'ph-gear-six',
  //   component: <GeneralSettings />,
  //   layout: routeConfig.SETTINGS.layout,
  //   hasSidebar: true,
  //   title: 'Settings',
  //   children: [
  //     {
  //       path: routeConfig.SETTINGS.TEAM.path,
  //       name: 'Team',
  //       component: <TeamSettings />,
  //       layout: routeConfig.SETTINGS.layout,
  //       hasSidebar: true,
  //       title: 'Team',
  //     },
  //   ],
  // },
  {
    path: routeConfig.DEVELOPERS.API_KEYS.path,
    name: 'Developers',
    icon: 'ph-code',
    component: <APIKeys />,
    layout: routeConfig.DEVELOPERS.layout,
    hasSidebar: true,
    roles: [ROLES.ADMIN, ROLES.MANAGER, ROLES.DEVELOPER],
    title: 'Developers',
    children: [
      {
        path: routeConfig.DEVELOPERS.API_KEYS.path,
        name: 'API Keys',
        component: <APIKeys />,
        layout: routeConfig.DEVELOPERS.layout,
        hasSidebar: true,
        title: 'API Keys',
      },
      {
        path: routeConfig.DEVELOPERS.DOCUMENTATION.path,
        name: 'Documentation',
        hasSidebar: true,
        title: 'Documentation',
      },
    ],
  },
];

const dispensaryViews: IView[] = [
  ...commonViews,
  {
    path: routeConfig.ANALYTICS.HOME.path,
    name: 'Analytics',
    icon: 'ph-chart-bar',
    component: <Dashboard />,
    layout: routeConfig.ANALYTICS.layout,
    hasSidebar: true,
    roles: [],
    title: 'Analytics',
  },
  {
    path: `${routeConfig.CUSTOMERS.customers.path}`,
    name: 'Customers',
    icon: 'ph-user-list',
    component: <Customers />,
    layout: routeConfig.CUSTOMERS.layout,
    hasSidebar: true,
    roles: [ROLES.ADMIN, ROLES.MANAGER, ROLES.ANALYST],
    title: 'Customers',
  },
  {
    path: `${routeConfig.CUSTOMERS.customers.path}/:id`,
    name: 'Customers',
    component: <CustomerDetails />,
    layout: routeConfig.CUSTOMERS.layout,
    title: 'Customers',
  },
  {
    path: `${routeConfig.PAYMENTS.transactions.path}`,
    name: 'Payments',
    icon: 'ph-currency-dollar',
    component: <DispensaryTransactions />,
    layout: routeConfig.PAYMENTS.layout,
    hasSidebar: true,
    roles: [ROLES.ADMIN, ROLES.MANAGER, ROLES.ANALYST],
    title: 'Payments',
    children: [
      {
        path: `${routeConfig.PAYMENTS.transactions.path}`,
        name: 'Transactions',
        component: <DispensaryTransactions />,
        layout: routeConfig.PAYMENTS.layout,
        hasSidebar: true,
        search: '?tab=summary',
        title: 'Transactions',
      },
      {
        path: `${routeConfig.PAYMENTS.tips.path}`,
        name: 'Tips',
        component: <Tips />,
        layout: routeConfig.PAYMENTS.layout,
        hasSidebar: true,
        search: '?tab=summary',
        title: 'Tips',
      },
      // {
      //   path: `${routeConfig.PAYMENTS.refunds.path}`,
      //   name: 'Refunds',
      //   component: <PaymentRefunds />,
      //   layout: routeConfig.PAYMENTS.layout,
      //   hasSidebar: true,
      //   search: '?tab=summary',
      //   title: 'Refunds',
      // },
      {
        path: `${routeConfig.PAYMENTS.transactions.path}/:transactionId`,
        name: 'Transaction Details',
        component: <Transaction />,
        layout: routeConfig.PAYMENTS.layout,
        hasSidebar: false,
        title: 'Transaction Details',
      },
    ],
  },
  {
    path: `${routeConfig.PAYMENT_LINKS.paymentlinks.path}`,
    name: 'Payment Links',
    icon: 'ph-link',
    component: <PaymentLinks />,
    layout: routeConfig.PAYMENT_LINKS.layout,
    hasSidebar: true,
    title: 'Payment Links',
  },
  {
    path: `${routeConfig.BANKING.STATEMENTS.path}`,
    name: 'Account',
    icon: 'ph-bank',
    component: <BankingStatements />,
    layout: routeConfig.BANKING.layout,
    hasSidebar: true,
    roles: [ROLES.ADMIN, ROLES.MANAGER, ROLES.ANALYST],
    title: 'Account',
    children: [
      // {
      //   path: `${routeConfig.BANKING.SUMMARY.path}`,
      //   name: 'Summary',
      //   component: <BankingSummary />,
      //   layout: routeConfig.BANKING.layout,
      //   hasSidebar: true,
      //   title: 'Summary',
      // },
      {
        path: `${routeConfig.BANKING.STATEMENTS.path}`,
        name: 'Statements',
        component: <BankingStatements />,
        layout: routeConfig.BANKING.layout,
        hasSidebar: true,
        search: '?tab=all',
        title: 'Statements',
      },
      {
        path: `${routeConfig.BANKING.FAILED_TRANSACTIONS.path}`,
        name: 'Failed transactions',
        component: <FailedTransactionsList />,
        layout: routeConfig.BANKING.layout,
        hasSidebar: true,
        title: 'Failed transactions',
        search: '?tab=failed_ach',
      },
      {
        path: `${routeConfig.BANKING.PAYOUTS.path}`,
        name: 'Payouts',
        component: <PayoutList />,
        layout: routeConfig.BANKING.layout,
        hasSidebar: true,
        title: 'Payouts',
      },
    ],
  },
  {
    path: `${routeConfig.PAYOUTS.payout.path}/:balTransactionId`,
    name: 'Payout Details',
    component: <Payout />,
    layout: routeConfig.PAYOUTS.layout,
    roles: [ROLES.ADMIN, ROLES.MANAGER, ROLES.ANALYST],
    title: 'Payout Details',
  },
  {
    path: routeConfig.SETTINGS.ORGANISATION.path,
    name: 'Settings',
    icon: 'ph-gear-six',
    component: <GeneralSettings />,
    layout: routeConfig.SETTINGS.layout,
    hasSidebar: true,
    title: 'Settings',
    children: [
      {
        path: routeConfig.SETTINGS.ORGANISATION.path,
        name: 'Store',
        component: <GeneralSettings />,
        layout: routeConfig.SETTINGS.layout,
        hasSidebar: true,
        title: 'Store',
      },
      {
        path: routeConfig.SETTINGS.CONFIGURATION.path,
        name: 'Payment Configuration',
        component: <PaymentConfig />,
        layout: routeConfig.SETTINGS.layout,
        hasSidebar: true,
        title: 'Payment Configuration',
      },
      {
        path: routeConfig.SETTINGS.LOGISTICS.path,
        name: 'Logistics',
        component: <LogisticsSettings />,
        layout: routeConfig.SETTINGS.layout,
        hasSidebar: true,
        title: 'Logistics',
      },
      // {
      //   path: routeConfig.SETTINGS.TEAM.path,
      //   name: 'Team',
      //   component: <TeamSettings />,
      //   layout: routeConfig.SETTINGS.layout,
      //   hasSidebar: true,
      //   title: 'Team',
      // },
      // {
      //   path: routeConfig.SETTINGS.INVITES.path,
      //   name: 'Invites',
      //   component: <InviteSettings />,
      //   layout: routeConfig.SETTINGS.layout,
      //   hasSidebar: false,
      //   title: 'Invites',
      // },
    ],
  },
  {
    path: routeConfig.DEVELOPERS.API_KEYS.path,
    name: 'Developers',
    icon: 'ph-code',
    component: <APIKeys />,
    layout: routeConfig.DEVELOPERS.layout,
    hasSidebar: true,
    roles: [ROLES.ADMIN, ROLES.MANAGER, ROLES.DEVELOPER],
    title: 'Developers',
    children: [
      {
        path: routeConfig.DEVELOPERS.API_KEYS.path,
        name: 'API Keys',
        component: <APIKeys />,
        layout: routeConfig.DEVELOPERS.layout,
        hasSidebar: true,
        title: 'API Keys',
      },
      {
        path: `${routeConfig.DEVELOPERS.SESSIONS.path}`,
        name: 'Sessions',
        component: <PaymentSessions />,
        layout: routeConfig.DEVELOPERS.layout,
        hasSidebar: true,
        title: 'Sessions',
      },
      {
        path: `${routeConfig.DEVELOPERS.SESSIONS.path}/:id`,
        name: 'Session Details',
        component: <Session />,
        layout: routeConfig.DEVELOPERS.layout,
        hasSidebar: false,
        title: 'Session Details',
      },
      {
        path: `${routeConfig.DEVELOPERS.WEBHOOK.path}`,
        name: 'Webhooks',
        component: <Webhook />,
        layout: routeConfig.DEVELOPERS.layout,
        hasSidebar: true,
        title: 'Webhooks',
      },
      {
        path: `${routeConfig.DEVELOPERS.WEBHOOK.path}/:webhookId`,
        name: 'Webhook Details',
        component: <WebhookDetails />,
        layout: routeConfig.DEVELOPERS.layout,
        hasSidebar: false,
        title: 'Webhook Details',
      },
      {
        path: routeConfig.DEVELOPERS.EVENTS.path,
        name: 'Events',
        component: <Events />,
        layout: routeConfig.DEVELOPERS.layout,
        hasSidebar: true,
        title: 'Events',
      },
      {
        path: `${routeConfig.DEVELOPERS.EVENTS.path}/:id`,
        name: 'Event Details',
        component: <EventsDetails />,
        layout: routeConfig.DEVELOPERS.layout,
        hasSidebar: false,
        title: 'Event Details',
      },
      {
        path: routeConfig.DEVELOPERS.DOCUMENTATION.path,
        name: 'Documentation',
        hasSidebar: true,
        title: 'Documentation',
      },
    ],
  },
];

const nonAchDispensaryViews: IView[] = [
  ...commonViews,
  {
    path: routeConfig.ANALYTICS.HOME.path,
    name: 'Analytics',
    icon: 'ph-house',
    component: <Dashboard />,
    layout: routeConfig.ANALYTICS.layout,
    hasSidebar: true,
    roles: [],
    title: 'Analytics',
  },
  {
    path: `${routeConfig.PAYMENTS.transactions.path}`,
    name: 'Payments',
    icon: 'ph-currency-dollar',
    component: <DispensaryTransactions />,
    layout: routeConfig.PAYMENTS.layout,
    hasSidebar: true,
    roles: [ROLES.ADMIN, ROLES.MANAGER, ROLES.ANALYST],
    title: 'Payments',
    children: [
      {
        path: `${routeConfig.PAYMENTS.transactions.path}`,
        name: 'Transactions',
        component: <DispensaryTransactions />,
        layout: routeConfig.PAYMENTS.layout,
        hasSidebar: true,
        search: '?tab=summary',
        title: 'Transactions',
      },
      {
        path: `${routeConfig.PAYMENTS.tips.path}`,
        name: 'Tips',
        component: <Tips />,
        layout: routeConfig.PAYMENTS.layout,
        hasSidebar: true,
        search: '?tab=summary',
        title: 'Tips',
      },
      {
        path: `${routeConfig.PAYMENTS.transactions.path}/:transactionId`,
        name: 'Transaction Details',
        component: <Transaction />,
        layout: routeConfig.PAYMENTS.layout,
        hasSidebar: false,
        title: 'Transaction Details',
      },
    ],
  },
  {
    path: routeConfig.SETTINGS.ORGANISATION.path,
    name: 'Settings',
    icon: 'ph-gear-six',
    component: <GeneralSettings />,
    layout: routeConfig.SETTINGS.layout,
    hasSidebar: true,
    title: 'Settings',
    children: [
      {
        path: routeConfig.SETTINGS.ORGANISATION.path,
        name: 'Store details',
        component: <GeneralSettings />,
        layout: routeConfig.SETTINGS.layout,
        hasSidebar: true,
        title: 'Store',
      },
      {
        path: routeConfig.SETTINGS.CONFIGURATION.path,
        name: 'Payment Configuration',
        component: <PaymentConfig />,
        layout: routeConfig.SETTINGS.layout,
        hasSidebar: true,
        title: 'Payment Configuration',
      },
    ],
  },
];

export default {
  partner: partnerViews,
  dispensary: dispensaryViews,
  nonAchDispensaryViews: nonAchDispensaryViews,
};
