import React, { Dispatch, SetStateAction } from 'react';
import {
  CREATE_PAYOUTS,
  IActiveState,
} from 'components/modals/banking/payouts/payout';
import InvoiceDetails from './invoiceDetails';
import VendorDetails from './vendors';
import BankDetails from './bankAccount';
import Review from '../review';
import Confirmation from '../confirmation';

interface IProps {
  setActiveState: Dispatch<SetStateAction<IActiveState>>;
  activeState: IActiveState;
}

const ExternalPayout = ({
  setActiveState,
  activeState,
}: IProps): JSX.Element => {
  return (
    <>
      {activeState.activeForm === CREATE_PAYOUTS.VENDOR_DETAILS && (
        <VendorDetails
          activeState={activeState}
          setActiveState={setActiveState}
        />
      )}
      {activeState.activeForm === CREATE_PAYOUTS.INVOICE_DETAILS && (
        <InvoiceDetails
          activeState={activeState}
          setActiveState={setActiveState}
        />
      )}
      {activeState.activeForm === CREATE_PAYOUTS.BANK_DETAILS_EXT && (
        <BankDetails
          activeState={activeState}
          setActiveState={setActiveState}
        />
      )}
      {activeState.activeForm === CREATE_PAYOUTS.REVIEW && (
        <Review activeState={activeState} setActiveState={setActiveState} />
      )}
      {activeState.activeForm === CREATE_PAYOUTS.CONFIRMATION && (
        <Confirmation
          activeState={activeState}
          setActiveState={setActiveState}
        />
      )}
    </>
  );
};

export default ExternalPayout;
