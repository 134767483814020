import React, { useEffect, useState, SyntheticEvent } from 'react';
import BootstrapTable, { SortOrder } from 'react-bootstrap-table-next';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { WEBHOOK_TABS } from 'utils/constants';
import { formatToTz } from 'utils/date-format';
import { WebhookEventStatusFormatter } from 'utils/status-formatter';
import classNames from 'classnames';
import { WEBHOOKS } from 'state/webhooks/type';
import routeConfig from 'routes/config';
import { useNavigate } from 'react-router-dom';
import Pagination from 'components/pagination';
import useWebhooks from 'state/webhooks/hooks/useWebhooks';
import useOrganisation from 'state/organisation/hooks/useOrganisation';

interface IProps {
  attempts: IPaginatedResponse<WEBHOOKS.IWebhookAttempt>;
  webhookId: string;
}

const WebhookAttempts = ({ attempts, webhookId }: IProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const [data, setData] = useState<WEBHOOKS.IWebhookAttempt[]>(attempts?.items);
  const [prevKey, setPrevKey] = useState<string | null>(null);
  const [nextKey, setNextKey] = useState<string | null>(null);
  const [numResults, setNumResults] = useState(0);
  const { toFetchWebhookDetails } = useWebhooks();
  const { organisation } = useOrganisation();
  const { settings } = organisation;

  const navigate = useNavigate();

  useEffect(() => {
    setData(attempts?.items);
  }, [attempts?.items]);

  useEffect(() => {
    if (attempts?.items) {
      setNextKey(attempts.next_key);
      setPrevKey(attempts.prev_key);
      setNumResults(attempts.total_count);
    }
  }, [attempts]);

  const prevPage = () => {
    if (webhookId) {
      toFetchWebhookDetails(webhookId, prevKey);
    }
  };

  // get to next page
  const nextPage = () => {
    if (webhookId) {
      toFetchWebhookDetails(webhookId, nextKey);
    }
  };

  const columns = [
    {
      dataField: 'create_date',
      text: 'Date',
      sort: true,
      formatter: (cell: string) => {
        return (
          <>
            {cell
              ? formatToTz(cell, settings?.timezone, 'MMM dd, hh:mm a')
              : ''}
          </>
        );
      },
    },
    {
      dataField: 'event.entity_type',
      text: 'Type',
      sort: true,
      formatter: (cell: string) => (
        <div className="text-capitalize">{cell}</div>
      ),
    },
    {
      dataField: 'event.name',
      text: 'Event',
      sort: true,
    },
    {
      dataField: 'event.id',
      text: 'Event ID',
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: WebhookEventStatusFormatter,
    },
  ];

  const defaultSorted: [{ dataField: string; order: SortOrder }] = [
    {
      dataField: 'event.create_date',
      order: 'desc',
    },
  ];

  const rowEvents = {
    onClick: (
      e: SyntheticEvent<Element, Event>,
      row: WEBHOOKS.IWebhookAttempt
    ) => {
      navigate(`../${routeConfig.DEVELOPERS.EVENTS.path}/${row.event_id}`);
    },
  };

  const filterData = (filters: string[]) => {
    if (filters.length > 0) {
      const filtered = attempts?.items?.filter(({ status }) =>
        filters.includes(status)
      );
      setData(filtered);
    } else setData(attempts?.items);
  };

  return (
    <>
      <Nav tabs className="mb-3">
        {WEBHOOK_TABS.map((item, index) => {
          return (
            <>
              <NavItem>
                <NavLink
                  className={classNames({
                    active: index === activeTab,
                  })}
                  onClick={() => {
                    setActiveTab(index);
                    filterData(item.filters);
                  }}
                >
                  {item?.label}
                </NavLink>
              </NavItem>
            </>
          );
        })}
      </Nav>
      <TabContent activeTab={activeTab}>
        {WEBHOOK_TABS.map((item, index) => {
          return (
            <TabPane tabId={index} key={item.label}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                keyField="_id"
                data={data}
                columns={columns}
                rowEvents={rowEvents}
                defaultSorted={defaultSorted}
                hover
                condensed
                bordered={false}
                noDataIndication={
                  <p className="text-center text-regular">No results found</p>
                }
              />
            </TabPane>
          );
        })}
      </TabContent>
      <Pagination
        results={numResults}
        prevPage={prevPage}
        nextPage={nextPage}
        prevKey={prevKey}
        nextKey={nextKey}
      />
    </>
  );
};

export default WebhookAttempts;
