import PageHeading from 'components/heading';
import React from 'react';
import {
  Card,
} from 'reactstrap';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import LogisticsProviderSettings from './provider';

const LogisticsSettings = (): JSX.Element => {
  const { organisation } = useOrganisation();
  const { activeOrg } = organisation;

  return (
    <>
      <Card className="card-profile border-0">
        <PageHeading heading="Logistics" />
        <LogisticsProviderSettings activeOrg={activeOrg ?? null} />
      </Card>
    </>
  );
};

export default LogisticsSettings;
