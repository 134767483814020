// skipcq: JS-0337
export namespace SUMMARY {
  export type ISummaryRequest = {
    start_date: string;
    end_date: string;
    interval_types: string;
  };

  export enum IIntervalType {
    HOURLY = 'hourly',
    DAILY = 'daily',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
  }

  export type ISummaryResponse<T> = Record<IIntervalType, ISummaryData<T>[]>;

  export interface ISummaryData<T> {
    date: string;
    value: T;
  }
}
