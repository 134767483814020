import React from 'react';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import { PAYMENTS } from 'state/payment/type';
import CURRENCY from 'utils/currency';
import STRING from 'utils/string';

interface props {
  info: PAYMENTS.IGatewayTransaction;
}
const GatewayPaymentDetails = ({
  info = {} as PAYMENTS.IGatewayTransaction,
}: props): JSX.Element => {
  const {
    organisation: { settings },
  } = useOrganisation();
  const grossAmount = info.original_amount + info.fee_amount + info.tip_amount;
  const treezTicketID = STRING.getTreezTicketIDFromReferenceNumber(
    info.reference_number
  );

  return (
    <>
      <div className="mb-9">
        <h3 className="mb-2 PaymentDetailsHeading">Payment details</h3>
        <hr className="mt-0 mb-2 pb-1" />
        <div>
          <div className="pb-1">
            <span className="PaymentDetailsSummaryHeading">Transaction ID</span>
            <span className="PaymentDetailsHeadingData">
              {info.gateway_id || 'NA'}
            </span>
          </div>
          <div className="mb-10">
            <span className="PaymentDetailsSummaryHeading">
              External ID (short)
            </span>
            <span className="PaymentDetailsHeadingData">{treezTicketID}</span>
          </div>
          <div className="pb-1">
            <span className="PaymentDetailsSummaryHeading">Order Amount</span>
            <span className="PaymentDetailsHeadingData">
              {CURRENCY.convertToMainUnit(info.original_amount)}
            </span>
          </div>
          <div className="pb-1">
            <span className="PaymentDetailsSummaryHeading">Tip</span>
            <span className="PaymentDetailsHeadingData">
              {CURRENCY.convertToMainUnit(info.tip_amount)}
            </span>
          </div>
          <div className="pb-1">
            <span className="PaymentDetailsSummaryHeading">Processor Fee</span>
            <span className="PaymentDetailsHeadingData">
              {CURRENCY.convertToMainUnit(info.fee_amount)}
            </span>
          </div>
          <div className="pb-1 mb-2">
            <span className="PaymentDetailsSummaryHeading">Total</span>
            <span className="PaymentDetailsHeadingData">
              {CURRENCY.convertToMainUnit(grossAmount)}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default GatewayPaymentDetails;
