import { WarningIcon } from 'assets/img/icons/warning';
import classNames from 'classnames';
import React, { Dispatch, SetStateAction } from 'react';
import { Button } from 'reactstrap';
import styles from '../../styles.module.scss';
import ModalContainer from 'components/modals/container';
import SuccessIcon from 'assets/img/icons/success';

interface IProps {
  status: boolean;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}
const DeviceStatus = ({
  status,
  setShowModal,
  showModal,
}: IProps): JSX.Element => {
  const renderStatusSuccess = () => {
    return (
      <>
        <SuccessIcon height="32" width="32" viewBox="0 0 32 32" />
        <div className="ml-3">
          <h1 className="mb-2 body-semibold">Device Online</h1>
          <span>The payment device is online.</span>
        </div>
      </>
    );
  };

  const renderStatusFailure = () => {
    return (
      <>
        <WarningIcon viewBox="0 0 32 32" />
        <div className="ml-3">
          <h1 className="mb-2 body-semibold">Device Offline</h1>
          <span>The payment device is currently offline.</span>
        </div>
      </>
    );
  };
  return (
    <div>
      <ModalContainer
        testId="device-status-modal"
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        header={false}
        isFullHeight={false}
        footer={
          <div>
            <Button
              data-testid="closeDeviceStatus"
              onClick={() => setShowModal(false)}
              color="primary"
            >
              Close
            </Button>
          </div>
        }
      >
        <>
          <div className={classNames(styles.confirmCloseModal)}>
            {status ? renderStatusSuccess() : renderStatusFailure()}
          </div>
        </>
      </ModalContainer>
    </div>
  );
};

export default DeviceStatus;
