import React from 'react';
import { Button } from 'reactstrap';
import useAuth from 'state/auth/hooks/useAuth';
import ModalContainer from 'components/modals/container';
import { WarningIcon } from 'assets/img/icons/warning';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import { useNavigate } from 'react-router-dom';
import routeConfig from 'routes/config';

const PermissionDeniedError = () => {
  const { toSetPermissionError, auth } = useAuth();
  const { organisation } = useOrganisation();
  const { activeOrg, activeOrgId } = organisation;
  const ownerEmail = activeOrg?.settings?.owner_email;
  const navigate = useNavigate();

  const onClose = () => {
    toSetPermissionError(false);
    navigate(
      `/${activeOrgId}/${routeConfig.ANALYTICS.layout}/${routeConfig.ANALYTICS.HOME.path}`
    );
  };

  return (
    <ModalContainer
      isOpen={auth.permissionError}
      onClose={onClose}
      footer={
        <Button color="primary" className="btn btn-primary" onClick={onClose}>
          Go to Home
        </Button>
      }
      header={false}
      isFullHeight={false}
    >
      <div className="d-flex align-items-start">
        <div style={{ width: '32px', height: '32px' }}>
          <WarningIcon width="32" height="32" viewBox="0 0 32 32" />
        </div>
        <div className="ml-3">
          <h1 className="mb-2 body-semibold">Oops! Can't Access This Yet</h1>
          <div className="body-subtext" style={{ color: '#4D5664' }}>
            We're sorry, but it seems like you don't have permission to access
            this part of the application. If you need access or have any
            questions, please contact your owner{ownerEmail ? ' ' : '.'}
            {ownerEmail && (
              <span style={{ fontWeight: 600 }}>{ownerEmail}.</span>
            )}
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default PermissionDeniedError;
