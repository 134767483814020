import { PDFDownloadLink } from '@react-pdf/renderer';
import classNames from 'classnames';
import Invoice, { IInvoice } from 'components/invoice';
import PreviewPdf from 'components/modals/previewPdf';
import Pagination from 'components/pagination';
import React, { useEffect, useState } from 'react';
import BootstrapTable, { SortOrder } from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import routeConfig from 'routes/config';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import usePayment from 'state/payment/hooks/usePayment';
import { PAYMENTS } from 'state/payment/type';
import { CUSTOMER_DETAILS_TABS } from 'utils/constants';
import CURRENCY from 'utils/currency';
import { formatToTz } from 'utils/date-format';
import { transactionStatusFormatter } from 'utils/status-formatter';
import styles from '../customers.module.scss';

interface IProps {
  customerProfileId?: string;
}

/* Transactions tab on Customer Details page */
const CustomerDetailsTransactions = ({
  customerProfileId,
}: IProps): JSX.Element => {
  const [activeTab, setActiveTab] = useState(0);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [modalData, setModalData] = useState<IInvoice>({} as IInvoice);
  const [prevKey, setPrevKey] = useState<string | null>(null);
  const [nextKey, setNextKey] = useState<string | null>(null);
  const [data, setData] = useState<PAYMENTS.ITransactionResult[]>([]);

  const { payment, toFetchTransactions } = usePayment();
  const { transactions } = payment;
  const { organisation } = useOrganisation();
  const { settings } = organisation;

  useEffect(() => {
    toFetchTransactions({
      consumer_profile_id: customerProfileId,
    });
  }, []);

  useEffect(() => {
    if (transactions?.items) {
      setData(transactions.items);
      setNextKey(transactions.next_key);
      setPrevKey(transactions.prev_key);
    }
  }, [transactions]);

  const filterData = (filters: string[]) => {
    if (filters.length > 0) {
      const filtered = transactions?.items?.filter(
        (d: PAYMENTS.ITransactionResult) =>
          filters.includes(d.transaction_status || '')
      );
      setData(filtered);
    } else {
      setData(transactions?.items);
    }
  };

  const columns = [
    {
      dataField: 'order_create_date',
      text: 'Date',
      sort: true,
      formatter: (cell: string) => {
        return (
          <>
            {cell
              ? formatToTz(cell, settings?.timezone, 'MMM dd, hh:mm a')
              : ''}
          </>
        );
      },
    },
    {
      dataField: 'calculated_total',
      text: 'Amount',
      sort: true,
      formatter: (cell: number) => {
        return (
          <div className="currencyColumn">
            {CURRENCY.convertToMainUnit(cell)}
          </div>
        );
      },
      // // headerAlign: 'right',
    },
    {
      dataField: 'transaction_status',
      text: 'Status',
      sort: true,
      formatter: transactionStatusFormatter,
    },
    {
      dataField: 'track_id',
      text: 'Reference ID',
      sort: true,
      formatter: (cell: string, row: PAYMENTS.ITransactionResult) => {
        return (
          <Link
            to={`../../${routeConfig.PAYMENTS.layout}/${routeConfig.PAYMENTS.transactions.path}/${row.order_id}`}
          >
            {cell?.toUpperCase()}
          </Link>
        );
      },
    },
    {
      dataField: 'external_short_identifier',
      text: 'External ID',
      sort: true,
    },
    {
      dataField: 'dummy',
      text: 'Invoice',
      formatter: (cellContent: string, row: PAYMENTS.ITransactionResult) => (
        <div className="d-flex align-items-center">
          <Button
            id="view-invoice"
            size="sm"
            className="btn-text p-0 m-0 d-flex align-items-center"
            onClick={() => {
              setInvoiceModal(!invoiceModal);
              setModalData({
                shopEmail: row.store_email,
                trackNumber: row.track_id,
                storeName: row.store_name,
                createDate: formatToTz(
                  row.order_create_date,
                  settings?.timezone
                ),
                orderAmount: row.order_amount,
                tipAmount: row.tip_amount,
                convenienceFee: row.order_fee_amount,
                totalAmount: row.calculated_total,
              });
            }}
          >
            <i className={classNames('ph-eye icon-lg', styles.icon_btn)}></i>
          </Button>
          <Button
            size="sm"
            className="btn-text p-0 ml-2 pl-1 d-flex align-items-center"
          >
            <PDFDownloadLink
              className="d-flex align-items-center"
              document={
                <Invoice
                  data={{
                    totalAmount: row.calculated_total,
                    orderAmount: row.order_amount,
                    tipAmount: row.tip_amount,
                    convenienceFee: row.order_fee_amount,
                    shopEmail: row.store_email,
                    trackNumber: row.track_id,
                    storeName: row.store_name,
                    createDate: formatToTz(
                      row.order_create_date,
                      settings?.timezone
                    ),
                  }}
                />
              }
              fileName={`Invoice.Swifter.${row.track_id}.pdf`}
            >
              {({ loading }) =>
                !loading && (
                  <i
                    className={classNames(
                      'ph-file-arrow-down icon-lg',
                      styles.icon_btn
                    )}
                  ></i>
                )
              }
            </PDFDownloadLink>
          </Button>
        </div>
      ),
      align: 'right',
    },
  ];
  const defaultSorted: [{ dataField: string; order: SortOrder }] = [
    {
      dataField: 'order_create_dates',
      order: 'desc',
    },
  ];

  const prevPage = () => {
    toFetchTransactions({
      key: prevKey,
      consumer_profile_id: customerProfileId,
    });
  };

  // get to next page
  const nextPage = () => {
    toFetchTransactions({
      key: nextKey,
      consumer_profile_id: customerProfileId,
    });
  };

  return (
    <>
      <div className={classNames(styles.sessions_txn_tab, 'pb-7')}>
        <h3 className={classNames(styles.customer_details_heading)}>
          Transactions
        </h3>
        <Nav tabs className="mb-5">
          {CUSTOMER_DETAILS_TABS.map((item, index) => {
            return (
              <>
                <NavItem>
                  <NavLink
                    className={classNames({
                      active: index === activeTab,
                    })}
                    onClick={() => {
                      setActiveTab(index);
                      filterData(item.filters);
                    }}
                    id={`Tooltip${String(index)}`}
                  >
                    {item?.label}
                  </NavLink>
                </NavItem>
              </>
            );
          })}
        </Nav>
        <TabContent activeTab={activeTab}>
          {CUSTOMER_DETAILS_TABS.map((item, index) => {
            return (
              <TabPane tabId={index}>
                {transactions?.items && (
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    keyField="_id"
                    data={data}
                    columns={columns}
                    defaultSorted={defaultSorted}
                    condensed
                    bordered={false}
                    noDataIndication={
                      <p className="text-center text-regular">
                        No results found
                      </p>
                    }
                  />
                )}
              </TabPane>
            );
          })}
        </TabContent>
        <Pagination
          results={data?.length}
          prevPage={prevPage}
          nextPage={nextPage}
          prevKey={prevKey}
          nextKey={nextKey}
        />
      </div>

      <PreviewPdf
        modal={invoiceModal}
        setModal={setInvoiceModal}
        data={modalData}
      />
    </>
  );
};

export default CustomerDetailsTransactions;
