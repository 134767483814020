import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import styles from '../tips/tips.module.scss';
import SupportForm from 'services/support';

interface IProps {
  isDismissable?: boolean;
}

const TipInAccurateDataBanner = ({ isDismissable = true }: IProps): JSX.Element => {
  const [dismissed, setDismissed] = useState(false);


  const outsideDateRangeSubText = (
    <div className={classNames(styles.subTextNoButton)}>
      You have selected a date range that includes dates before{' '}
      <span className={classNames(styles.dateText)}>June 7, 2024</span>. Data
      for these dates may be inaccurate. Please adjust the date range. If you
      have any questions or encounter further issues, please{' '}
      <span
        className={classNames(styles.contactSupport)}
        onClick={
          SupportForm.openForm
        }
      >
        Contact Support
      </span>
      .
    </div>
  );

  const insideDateRangeSubText = (
    <>
      <div className={classNames(styles.subText)}>
        Data before{' '}
        <span className={classNames(styles.dateText)}>June 7, 2024</span>, may
        be inaccurate. To ensure accurate tips related information, please
        select a date range starting from June 7, 2024. If you have any
        questions or encounter further issues, please{' '}
        <span
          className={classNames(styles.contactSupport)}
          onClick={
            SupportForm.openForm
          }
        >
          Contact Support
        </span>
        .
      </div>
      <div className={classNames(styles.button)} onClick={() => {}}>
        Understood
      </div>
    </>
  );


  return (
    <>
      {!dismissed && (
        <div className={classNames(styles.container)}>
          <div>
            <i className={classNames('ph-info-fill', styles.infoIcon)} />
          </div>
          <div>
            <div className={classNames(styles.bannerHeading)}>
              <div>Inaccurate Data</div>
              {isDismissable && <div
                onClick={() => {
                  setDismissed(true);
                }}
              >
                <i className={classNames('ph ph-x', styles.xIcon)} />
              </div>}
            </div>
            {outsideDateRangeSubText}
          </div>
        </div>
      )}
    </>
  );
};

export default TipInAccurateDataBanner;
