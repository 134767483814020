import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { STORE } from 'state/store/type';
import routeConfig from 'routes/config';
import Info from './info';
import EmptyStoresList from './emptyStores';
import URL from 'utils/url';
import { oneAppUrl } from 'utils/urlGenerator';
import Store from 'assets/img/store/Store.svg';
import { ENV } from 'utils/config';
import UtilisationInfo from './utilisationInfo';

interface IProps {
  storesList: STORE.IStore[] | null;
}

const ListStores = ({ storesList }: IProps): JSX.Element => {
  const navigate = useNavigate();

  const navigateToRequestNewStore = () => {
    const redirectPath = `${window.location.origin}/${routeConfig.STORES.layout}/${routeConfig.STORES.NEW.path}`;
    window.open(redirectPath, '_blank', 'rel=noopener noreferrer');
  };
  const navigateToDashboard = (orgId: string) => {
    navigate(`/${orgId}`);
  };
  const navigateToNoAccess = (storeName: string, ownerEmail: string) => {
    navigate(
      `/${routeConfig.STORES.layout}/${routeConfig.STORES.ERROR_ACCESS_DENIED.path}`,
      {
        state: {
          storeName,
          ownerEmail,
        },
      }
    );
  };

  const handleStoreOnClick = (store: STORE.IStore) => {
    return !store.is_activated
      ? navigateToRequestNewStore()
      : !store.has_access
      ? navigateToNoAccess(store.dba_name, store.owner_email)
      : navigateToDashboard(store.organization_id);
  };

  const getStoreLabel = (store: STORE.IStore) => {
    const [label, style] = !store.is_activated
      ? ['store not activated', styles.labelNotActivated]
      : !store.has_access
      ? ['no access', styles.labelNoAccess]
      : ['access', styles.labelAccess];

    return (
      <div
        className={classNames(
          styles.storeItemLabel,
          style,
          'd-flex align-items-center'
        )}
        style={{ lineHeight: '24px' }}
      >
        {label}
      </div>
    );
  };

  const getAddress = (store: STORE.IStore) => {
    let address;
    if (store.adr_line_1 && store.adr_line_2 && store.city && store.state) {
      address =
        store.adr_line_1 +
        ', ' +
        store.adr_line_2 +
        ', ' +
        store.city +
        ', ' +
        store.state;
    } else if (
      store.adr_line_1 &&
      !store.adr_line_2 &&
      store.city &&
      store.state
    ) {
      address = store.adr_line_1 + ', ' + store.city + ', ' + store.state;
    } else {
      address = '-';
    }

    return address;
  };

  const StoreCard = ({ store }: { store: STORE.IStore }) => {
    return (
      <div
        className={classNames(store.is_activated ? styles.storeCard : styles.deactivatedStoreCard)}
        onClick={() => (store.is_activated ? handleStoreOnClick(store) : {})}
        data-testid="storeCard"
      >
        <div className="d-flex justify-content-between align-items-center h-100">
          <div className={classNames(styles.imageLogo, 'mr-2')}>
            <img src={Store} />
          </div>
          <div className="flex-grow-1">
            <div className="d-flex align-items-center mb-1">
              <div
                className="mr-2 body-subtext-semibold"
                style={{ color: '#333D4E', fontSize: '16px' }}
              >
                {store.dba_name}
              </div>
              {store.is_activated && store.has_access && store.role_name && (
                <div className="mr-2 d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4"
                    height="4"
                    viewBox="0 0 4 4"
                    fill="none"
                    className="mr-2"
                  >
                    <circle cx="2" cy="2" r="2" fill="#666E7A" />
                  </svg>
                  <span
                    style={{ color: '#4D5664' }}
                    className="text-capitalize body-subtext-regular"
                  >
                    {store.role_name}
                  </span>
                </div>
              )}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="4"
                height="4"
                viewBox="0 0 4 4"
                fill="none"
                className="mr-2"
              >
                <circle cx="2" cy="2" r="2" fill="#666E7A" />
              </svg>
              {getStoreLabel(store)}
            </div>
            <div className="d-flex align-items-end">
              <i
                className="ph-bold ph-map-pin-fill mr-1 icon-lg"
                style={{ color: '#808690' }}
              ></i>
              <span className="footnote" style={{ color: '#666E7A' }}>
                {getAddress(store)}
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <i
              className="ph-bold ph-caret-right-bold icon-xl"
              style={{ color: '#666E7A' }}
            ></i>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        <div className={classNames(styles.requestStoreContainer)}>
          {!storesList || storesList.length < 1 ? (
            <EmptyStoresList />
          ) : (
            <div className={styles.storeListContainer}>
              <div
                className="d-flex justify-content-between"
                style={{
                  width: '512px',
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                }}
              >
                <span>
                  {/* <div className="mt-7 mb-3" style={{ width: '512px' }}> */}
                  <span className={styles.listHeading}>Stores</span>
                  <div className={styles.listSubHeading}>
                    Your account is linked to {storesList.length}{' '}
                    {storesList.length > 1 ? 'stores' : 'store'} below:
                  </div>
                </span>
                <span>
                  <Button
                    variant="text"
                    onClick={() =>
                      URL.openInNewTab(oneAppUrl(ENV || 'production'))
                    }
                    className={classNames(styles.addStoreButton, 'mt-2')}
                  >
                    <i className={classNames('ph-plus-bold icon-lg pr-1 ')} />
                    Add a store
                  </Button>
                </span>
              </div>
              <div
                className={classNames(
                  'mb-4 d-flex flex-column',
                  styles.storeItem
                )}
                style={{
                  gap: '12px',
                  width: '512px',
                }}
              >
                {Array.isArray(storesList) &&
                  storesList.map((item: STORE.IStore) => (
                    <StoreCard store={item} />
                  ))}
              </div>
            </div>
          )}

          {!storesList || storesList.length < 1 ? (
            <Info />
          ) : (
            <>
              <div className={styles.storeFooter}>
                <span>Need assistance?</span>
                <div>
                  <span className={styles.oneAppLink}>
                    Setup a meeting with a{' '}
                    <a
                      target="_blank"
                      href="https://calendly.com/treezpay"
                      style={{ color: '#1A4007', textDecoration: 'underline' }}
                    >
                      Treezpay representative
                    </a>{' '}
                  </span>
                </div>
              </div>
              <UtilisationInfo />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ListStores;
