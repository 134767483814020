import React, { SyntheticEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import Filter from 'components/filter';
import { EFilterConfigType, IFilterConfig } from 'components/filter/types';
import PageHeading from 'components/heading';
import Pagination from 'components/pagination';
import Search from 'components/search';
import BootstrapTable, { SortOrder } from 'react-bootstrap-table-next';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import { ToolkitContextType } from 'react-bootstrap-table2-toolkit';
import { useNavigate, useLocation } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import useConsumer from 'state/consumer/hooks/useConsumer';
import { CONSUMERS } from 'state/consumer/type';
import {
  statusListConsumer,
  STATUS_ACTIVE,
  STATUS_RISKY,
  STATUS_MEDIUM_RISK,
  STATUS_SUSPENDED,
} from 'utils/constants';
import CURRENCY from 'utils/currency';
import useQuery from 'utils/hooks/useQuery';
import { formatToTz } from 'utils/date-format';
import QUERY from 'utils/query';
import { customerStatusFormatter } from 'utils/status-formatter';
import useOrganisation from 'state/organisation/hooks/useOrganisation';

import ExportBtn from 'components/modals/export/exportBtn';
import ExportModal from 'components/modals/export';

interface IProps {
  filters: ICustomerFilters;
  queryParams: string;
}

export interface ICustomerFilters {
  email?: string | null;
  dateFrom?: string;
  dateTo?: string;
  amountOption?: string;
  firstAmount?: string;
  secondAmount?: string;
  status?: string;
}

export const CUSTOMER_TABS = [
  {
    label: 'All',
    filters: [],
  },
  {
    label: 'Active',
    filters: [STATUS_ACTIVE],
  },
  {
    label: 'Suspended',
    filters: [STATUS_SUSPENDED],
  },
  {
    label: 'Medium Risk',
    filters: [STATUS_MEDIUM_RISK],
  },
  {
    label: 'High Risk',
    filters: [STATUS_RISKY],
  },
];

const CustomersList = ({ filters, queryParams }: IProps): JSX.Element => {
  const heading = 'Customers';
  const subHeading = 'View your customers here.';
  const tabNames = CUSTOMER_TABS;
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = useQuery(queryParams);
  const { organisation } = useOrganisation();
  const { settings } = organisation;

  const dates = urlParams.get('dates')?.split(',') || [];
  const dateFrom = dates.length > 0 ? dates[0].replace(' ', '+') : '';
  const dateTo = dates.length > 1 ? dates[1].replace(' ', '+') : '';
  const statusArray = urlParams.get('status')?.split(',') || [];

  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState(
    (tabNames.length > 0 && tabNames[0].label) || ''
  );

  const {
    consumers,
    toFetchCSVConsumers,
    toClearCustomersExport,
    toFetchCustomers,
  } = useConsumer();
  const { customers, url, error } = consumers;

  const [products, setProducts] = useState([{}]);
  const [numResults, setNumResults] = useState(0);

  const searchString = urlParams.get('search') || undefined;
  const { email, amountOption, firstAmount, secondAmount, status } = filters;
  const emailValues: string[] = [];
  if (email) {
    emailValues[0] = email;
  }

  const amountValues = ['equal'];
  if (amountOption) {
    amountValues[0] = amountOption;
  }
  if (firstAmount) {
    amountValues[1] = firstAmount;
  }
  if (secondAmount) {
    amountValues[2] = secondAmount;
  }

  const getFilterConfig = () => [
    {
      key: 'dates',
      label: 'Created On',
      type: EFilterConfigType.date,
      data: {
        values: [],
      },
      selected: false,
    },
    {
      key: 'amount',
      label: 'Spend',
      type: EFilterConfigType.number,
      data: {
        values: amountValues,
      },
      selected: false,
    },
    // {
    //   key: 'email',
    //   label: 'Email',
    //   type: EFilterConfigType.string,
    //   data: {
    //     values: emailValues,
    //   },
    //   selected: false,
    // },
    {
      key: 'status',
      label: 'Status',
      type: EFilterConfigType.list,
      data: {
        key: 'status',
        options: statusListConsumer,
        values: statusArray,
      },
      selected: false,
    },
  ];
  const defaultFilterConfig: IFilterConfig[] = getFilterConfig();

  const [filterConfig, setFilterConfig] =
    useState<IFilterConfig[]>(defaultFilterConfig);

  useEffect(() => {
    if (customers.items?.length > 0) {
      setFilterConfig(getFilterConfig());
    }
  }, [customers]);

  useEffect(() => {
    const tab = tabNames.filter(
      (currentTab) => currentTab.filters.join() === [statusArray[0]].join()
    );
    if (tab.length > 0) {
      setActiveTab(tab[0].label);
    }

    toFetchCustomers({
      email,
      start_date: dateFrom || undefined,
      end_date: dateTo || undefined,
      amount_1: firstAmount,
      amount_2: secondAmount,
      condition: amountOption || undefined,
      search_string: searchString || undefined,
      status: statusArray.length > 0 ? statusArray.join(',') : undefined,
    });
  }, [queryParams]);

  const prevPage = () => {
    toFetchCustomers({
      key: customers.prev_key,
      start_date: dateFrom || undefined,
      end_date: dateTo || undefined,
      email,
      amount_1: firstAmount,
      amount_2: secondAmount,
      condition: amountOption || undefined,
      search_string: searchString || undefined,
      status: statusArray.length > 0 ? statusArray.join(',') : undefined,
    });
  };

  const nextPage = () => {
    toFetchCustomers({
      key: customers.next_key,
      start_date: dateFrom || undefined,
      end_date: dateTo || undefined,
      email,
      amount_1: firstAmount,
      amount_2: secondAmount,
      condition: amountOption || undefined,
      search_string: searchString || undefined,
      status: statusArray.length > 0 ? statusArray.join(',') : undefined,
    });
  };

  useEffect(() => {
    if (customers.items) {
      setProducts(customers.items);
      setNumResults(customers.total_count);
    } else {
      setProducts([]);
      setNumResults(0);
    }
  }, [customers]);

  const defaultSorted: [{ dataField: string; order: SortOrder }] = [
    {
      dataField: 'create_date',
      order: 'desc',
    },
  ];

  const rowEvents = {
    onClick: (
      e: SyntheticEvent<Element, Event>,
      row: CONSUMERS.ICustomersItems
    ) => {
      navigate(row.id);
    },
  };

  const columns = [
    {
      dataField: 'create_date',
      text: 'Created On',
      sort: true,
      formatter: (cell: string) => {
        return (
          <>
            {cell
              ? formatToTz(cell, settings?.timezone, 'MMM dd, hh:mm a')
              : ''}
          </>
        );
      },
    },
    {
      dataField: 'first_name',
      text: 'Name',
      sort: true,
      formatter: (cell: string, row: CONSUMERS.ICustomersItems) => {
        return <>{`${cell} ${row.last_name}`}</>;
      },
    },
    {
      dataField: 'spent_amount',
      text: 'Spend',
      sort: true,
      formatter: (cell: number) => {
        return (
          <div className="currencyColumn">
            {CURRENCY.convertToMainUnit(cell)}
          </div>
        );
      },
      // // headerAlign: 'right',
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true,
      formatter: (cell: string) => {
        return <>{cell || ''}</>;
      },
    },
    {
      dataField: 'phone',
      text: 'Phone',
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: customerStatusFormatter,
    },
  ];

  const applyStatusFilter = (tabfilters: Array<string>) => {
    const updatedFilters = filterConfig.map((item) => {
      if (item.key === 'status') {
        item.data.values = tabfilters;
        item.selected = true;
      }
      return item;
    });

    const query: {
      [key: string]: string;
    } = {};
    updatedFilters.forEach((item) => {
      const { key, data, selected, type } = item;
      if (selected) {
        if (type === EFilterConfigType.number) {
          // const status = data.values.shift();
          query.status = status || '';
        }
        query[`${key}`] = data.values.join();
      }
    });
    navigate({
      search: QUERY.update(location.search, query),
    });
  };
  return (
    <>
      <PageHeading heading={heading} subHeading={subHeading} />

      <Nav tabs id="customer-table-tabs">
        {tabNames.map((item) => {
          return (
            <>
              <NavItem>
                <NavLink
                  className={classNames({
                    active: item?.label === activeTab,
                  })}
                  onClick={() => {
                    setActiveTab(item.label);
                    applyStatusFilter(item.filters);
                  }}
                >
                  {item?.label}
                </NavLink>
              </NavItem>
            </>
          );
        })}
      </Nav>

      <TabContent activeTab={activeTab}>
        <div className="d-flex mb-2">
          <span className="mr-auto">
            <Search queryParams={queryParams} />
          </span>
          <span>
            {filterConfig && (
              <Filter config={filterConfig} queryParams={queryParams}></Filter>
            )}
          </span>
          <ExportBtn onClick={() => setShowModal(true)} id="tnx-export-btn" />
        </div>
        {tabNames.map((item) => (
          <TabPane tabId={item?.label} key={item.label}>
            <ToolkitProvider
              keyField="_id"
              search
              data={products}
              columns={columns}
            >
              {(props: ToolkitContextType) => (
                <div>
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    {...props.baseProps}
                    defaultSorted={defaultSorted}
                    rowEvents={rowEvents}
                    hover
                    condensed
                    bordered={false}
                    noDataIndication={
                      <p className="text-center text-regular">
                        No results found
                      </p>
                    }
                  />
                </div>
              )}
            </ToolkitProvider>
          </TabPane>
        ))}
      </TabContent>

      <Pagination
        results={numResults}
        prevPage={prevPage}
        nextPage={nextPage}
        prevKey={customers.prev_key}
        nextKey={customers.next_key}
      />
      <ExportModal
        showModal={showModal}
        setShowModal={setShowModal}
        config={filterConfig}
        heading="Customers Summary"
        onExport={toFetchCSVConsumers} // skipcq JS-0336
        reportUrl={url}
        label="Customers"
        errorMessage={error.addUrl?.message ?? undefined}
        toClearExport={toClearCustomersExport}
      />
    </>
  );
};

export default CustomersList;
