import React, { useEffect, useState } from 'react';
import { Card } from 'reactstrap';
import classNames from 'classnames';
import Chart from 'chart.js/auto';
import { defaults } from 'chart.js';
import { Chart as ChartReact } from 'react-chartjs-2';
import FrequencyButtonGroup from 'components/summary-tab/freq-button-group';
import useStats from 'state/stats/hooks/useStats';
import { STATS } from 'state/stats/type';
import * as util from 'utils/dashboard';
import CURRENCY from 'utils/currency';
import { Link } from 'react-router-dom';
import { formatToTz } from 'utils/date-format';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import styles from '../dashboard.module.scss';
import {
  verticalAnnotation,
  getChartOptions,
  fillInTheGapsUtil,
  timeUnitConverter,
  multiLineColorConfig,
} from './config';
import useQuery from 'utils/hooks/useQuery';

interface IProps {
  currentDates: [string, string];
  prevDates: [string, string];
}

const GraphLastUpdatedTimer = () => {
  const { stats } = useStats();
  const { graph, isLoading } = stats;
  const [timer, setTimer] = useState(0);
  const [lastUpdateMessage, setLastUpdateMessage] = useState(
    'Graph updated a few seconds ago'
  );

  useEffect(() => {
    setTimer(0);
  }, [graph]);

  useEffect(() => {
    const second = timer < 60 ? timer : 0;
    const minute = timer >= 60 && timer <= 3540 ? Math.floor(timer / 60) : 0;
    if (second) {
      setLastUpdateMessage('Graph updated a few seconds ago');
    } else if (minute) {
      setLastUpdateMessage(
        `Graph updated ${minute} minute${minute > 1 ? 's' : ''} ago`
      );
    }
    const timerInterval = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [timer]);

  return (
    <div className="d-flex justify-content-center">
      {!isLoading && (
        <>
          <i className={classNames('ph-clock icon-lg mr-1')}></i>
          <div className={styles.graphLastUpdateText}>{lastUpdateMessage}</div>
        </>
      )}
    </div>
  );
};

const Graph = ({ currentDates, prevDates }: IProps): JSX.Element => {
  type IDataset = {
    x: Date[];
    y: number[];
    type: string;
    value: STATS.ValuePoint[];
  };

  defaults.font.family = 'Inter, sans-serif';

  const [dateFrom, dateTo] = currentDates;
  const [prevFrom, prevTo] = prevDates;
  const { stats, toFetchGraphPoints } = useStats();
  const { activeSummaryTab, summaryCard, graph, isLoading } = stats;
  const { organisation } = useOrganisation();
  const { settings } = organisation;

  const interval = util.intervalInDays(dateFrom, dateTo);
  const intervalTypes = util.mapIntervalToFrequency(interval);
  const [activeFreq, setActiveFreq] = useState(intervalTypes[0]);
  const urlParams = useQuery(location?.search);
  const paymentType = urlParams.get('method') || undefined;
  const [datasets, setDatasets] = useState<IDataset[]>([]);
  const isMultiLineChart = paymentType === STATS.IPaymentType.ALL;

  const getComparisonData = () => {
    const { current, previous } = summaryCard;
    if (!current || !previous) return [0, 0];

    if (activeSummaryTab === STATS.graphType.payments)
      return [current[activeSummaryTab], previous[activeSummaryTab]];
    else if (activeSummaryTab === STATS.graphType.aov)
      return [
        CURRENCY.getCompactNotation(
          current?.payments ? current?.gross_amount / current?.payments : 0
        ),
        CURRENCY.getCompactNotation(
          previous?.payments ? previous?.gross_amount / previous?.payments : 0
        ),
      ];
    else
      return [
        CURRENCY.getCompactNotation(current[activeSummaryTab]),
        CURRENCY.getCompactNotation(previous[activeSummaryTab]),
      ];
  };

  const SummaryWithComparison = () => {
    const formatString = 'dd MMM yyyy';

    const fromStr = formatToTz(dateFrom, settings?.timezone, formatString);
    const toStr = formatToTz(dateTo, settings?.timezone, formatString);
    const prevFromStr = formatToTz(prevFrom, settings?.timezone, formatString);
    const prevToStr = formatToTz(prevTo, settings?.timezone, formatString);

    const [value, prevValue] = getComparisonData();

    return (
      <div className={styles.graphSummary}>
        <p data-cy="current-date-summary">
          <span className="mr-2 footnote-medium">{`${fromStr} - ${toStr} :`}</span>
          <span className="body-subtext-semibold">{value}</span>
        </p>
        <p data-cy="prev-date-summary">
          <span className="mr-2 footnote-medium">{`${prevFromStr} - ${prevToStr} :`}</span>
          <span className="body-subtext-semibold">{prevValue}</span>
        </p>
      </div>
    );
  };

  useEffect(() => {
    if (Array.isArray(graph) && graph.length > 0) {
      const datasetsPerType: {
        x: Date[];
        y: number[];
        type: string;
        value: STATS.ValuePoint[];
      }[] = [];

      graph.forEach((g: STATS.IPaymentTypeGraph) => {
        const data: {
          x: Date[];
          y: number[];
          value: STATS.ValuePoint[];
        } = {
          x: [],
          y: [],
          value: [],
        };

        g[activeFreq].forEach((point: STATS.IDataPointValue) => {
          data.x.push(new Date(point.date));

          if (
            activeSummaryTab == STATS.graphType.gross_amount &&
            point.value[0] &&
            'gross_amount' in point.value[0]
          ) {
            const value = point.value[0]?.gross_amount || 0;

            data.y.push(value);
          } else if (
            activeSummaryTab == STATS.graphType.payments &&
            point.value[0] &&
            'transaction_count' in point.value[0]
          ) {
            const value = (point.value[0]?.transaction_count || 0) as number;
            data.y.push(value);
          } else if (
            activeSummaryTab == STATS.graphType.aov &&
            point.value[0] &&
            'transaction_count' in point.value[0] &&
            'gross_amount' in point.value[0]
          ) {
            const value = point.value[0]?.transaction_count
              ? point.value[0]?.gross_amount / point.value[0]?.transaction_count
              : (0 as number);
            data.y.push(value);
          } else data.y.push(0);

          data.value.push(point.value[0]);
        });

        const convertedTimeUnit = timeUnitConverter(activeFreq);
        const filledDataObject = fillInTheGapsUtil(
          data,
          dateFrom,
          dateTo,
          convertedTimeUnit,
          settings?.timezone
        );
        (filledDataObject as IDataset).type = util.getPaymentTypeDisplayText(
          g.payment_type
        );

        datasetsPerType.push(filledDataObject as IDataset);
      });
      setDatasets(datasetsPerType);
    }
  }, [graph, activeSummaryTab, activeFreq, dateFrom, dateTo]);

  useEffect(() => {
    Chart.register(verticalAnnotation);
  }, []);

  useEffect(() => {
    if (activeSummaryTab) {
      toFetchGraphPoints({
        start_date: dateFrom,
        end_date: dateTo,
        interval_types: intervalTypes.join(),
        payment_type:
          paymentType === STATS.IPaymentType.ALL
            ? Object.values(STATS.IPaymentType).join(',')
            : paymentType,
      });
    }
  }, [dateFrom, dateTo, paymentType]);

  useEffect(() => {
    setActiveFreq(intervalTypes[0]);
  }, [dateFrom, dateTo]);

  return (
    <Card
      id="dashboard-graph"
      className={classNames(styles.graphContainer, 'p-5 mb-7')}
    >
      <div className="d-flex justify-content-between mb-6">
        <SummaryWithComparison />
        <FrequencyButtonGroup
          enabledFreq={intervalTypes}
          activeFreq={activeFreq}
          setActiveFreq={setActiveFreq}
        />
      </div>
      {Array.isArray(graph) && graph?.length > 0 && (
        <div id="chartContainer" className={styles.chartContainer}>
          <ChartReact
            type="line"
            options={getChartOptions(
              activeFreq,
              util.getTimeAxisStepSize(interval, activeFreq),
              util.getTimeAxisUnit(activeFreq),
              datasets.length,
              activeSummaryTab,
              datasets.map(({ value, type }) => ({ type, value })),
              settings?.timezone,
              isMultiLineChart
            )}
            data={{
              labels: datasets[0]?.x,
              datasets: datasets.map((d) => ({
                label: d.type,
                data: d.y,
                ...(isMultiLineChart
                  ? multiLineColorConfig[d.type]
                  : multiLineColorConfig?.Total),
              })),
            }}
          />
        </div>
      )}
      <div
        className={classNames(
          'd-flex justify-content-between mt-5',
          styles.graphFooter
        )}
      >
        <div className="d-flex justify-content-center">
          <GraphLastUpdatedTimer />
        </div>
        <Link
          className={styles.graphDetailsLink}
          to={`../../${util.getRedirectPath(
            activeSummaryTab
          )}?tab=summary&dates=${dateFrom},${dateTo}`}
        >
          View transactions from this date range
        </Link>
      </div>
    </Card>
  );
};

export default Graph;
