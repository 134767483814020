import jwt_decode from 'jwt-decode';
interface IJwt {
  exp: number;
  iss: string;
}

/**
 * Returns true if the user object has any keys, false otherwise.
 * @param userObject - The user object.
 */
export const isAccessTokenPresent = (userObject: Record<string, unknown>) =>
  Object.keys(userObject).length &&
  userObject?.accessToken &&
  userObject.refreshToken;

/**
 * Checks if the given JWT has expired
 * @param {string} accessToken - JWT
 * @returns A boolean value
 */
export const hasTokenExpired = (accessToken: string): boolean => {
  // 60 is for making current time forward by 60 secs
  const currentTime = Date.now() / 1000 + 60;
  const decoded: IJwt = jwt_decode(accessToken);
  return currentTime > decoded?.exp;
};