import React, { useEffect, useState } from 'react';
import BootstrapTable, {
  ExpandColumnRendererProps,
} from 'react-bootstrap-table-next';
import classNames from 'classnames';
import DatePicker from 'components/calender/date';
import * as util from 'utils/dashboard'; // skipcq: JS-C1003
import * as utils from 'utils/summary'; // skipcq: JS-C1003
import CURRENCY from 'utils/currency';
import { SUMMARY } from 'state/summary/type';
import QUERY from 'utils/query';
import { useNavigate, useLocation } from 'react-router-dom';
import { getMonthlyRange } from 'utils/summary';
import useBanking from 'state/banking/hooks/useBanking';
import Pagination from 'components/pagination';
import { BANKING } from 'state/banking/type';
import FrequencyButtonGroup from './freq-button-group';
import { formatDate, toIsoStringWithTz } from '../../utils/date-format';
import styles from './summary.module.scss';
import NoSummaryFound from './no-summary-found';
/* eslint-disable no-debugger */
interface PaginationProps {
  id: string;
  numResults: number;
  prevKey: string;
  nextKey: string;
  loadPage: (paginationKey?: string) => void;
}
interface IProps<T> {
  rowHeading: string;
  dateFrom?: string;
  dateTo?: string;
  addDateFilter?: boolean;
  description?: () => JSX.Element;
  summaryData: SUMMARY.ISummaryResponse<T>;
  expandRowFormatter: (items: SUMMARY.ISummaryData<T>) => JSX.Element;
  rowHeaderValue: string;
  intervalTypes: SUMMARY.IIntervalType[];
  toSetIntervalTypes: React.Dispatch<
    React.SetStateAction<SUMMARY.IIntervalType[]>
  >;
  excludeHourly?: boolean;
  testId?: string;
  paginationProp?: PaginationProps | undefined;
  showIntervalButton?: boolean;
}

export const fromDate = toIsoStringWithTz(
  new Date(
    new Date(new Date().setDate(new Date().getDate() - 30)).setHours(0, 0, 0, 0)
  )
);
export const todayDate = toIsoStringWithTz(
  new Date(new Date().setDate(new Date().getDate()))
);

const Summary = <T,>({
  rowHeading,
  dateFrom,
  dateTo,
  addDateFilter = false,
  summaryData,
  expandRowFormatter,
  description,
  rowHeaderValue,
  intervalTypes,
  toSetIntervalTypes,
  excludeHourly = false,
  testId,
  paginationProp,
  showIntervalButton = true,
}: IProps<T>): JSX.Element => {
  const [dateFromValue, setDateFrom] = useState<string | undefined>(dateFrom);
  const [dateToValue, setDateTo] = useState<string | undefined>(dateTo);
  const [activeFreq, setActiveFreq] = useState<SUMMARY.IIntervalType>(
    SUMMARY.IIntervalType.DAILY
  );

  // add one column to the data which will work as a key for the table,
  // the key will contain combination of date and interval
  const newProducts = (
    data: SUMMARY.ISummaryResponse<T>,
    interval: SUMMARY.IIntervalType
  ) => {
    const indexedData = data[interval] || [];

    return indexedData.map((a, index) => {
      return { ...a, key: `${a.date}-${interval}-${index}` };
    });
  };

  // Table data
  const [products, setProducts] = useState(
    newProducts(summaryData, intervalTypes[0])
  );

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (intervalTypes.length > 0) {
      setActiveFreq(intervalTypes[0]);
    }
  }, [intervalTypes]);

  useEffect(() => {
    setProducts(newProducts(summaryData, activeFreq));
  }, [summaryData, activeFreq]);

  const columns = [
    {
      dataField: 'date',
      text: `${rowHeading}`,
      headerAttrs: {
        hidden: true,
      },
      formatter: (cell: string) => {
        return (
          <>
            {cell && (
              <div>
                <span className={styles.rowHeader}>{rowHeading}</span>
                <span className={classNames(styles.tableDateText, 'pl-7')}>
                  {activeFreq === SUMMARY.IIntervalType.DAILY
                    ? `on ${formatDate(cell, 'dd MMM, yyyy')}`
                    : activeFreq === SUMMARY.IIntervalType.WEEKLY
                    ? `from (${util.getTooltipWeekRange(
                        new Date(cell),
                        'dd MMM, yyyy'
                      )})`
                    : `from (${getMonthlyRange(new Date(cell))})`}
                </span>
              </div>
            )}
          </>
        );
      },
    },
    {
      dataField: `${rowHeaderValue}`,
      text: '',
      style: { width: 0 },
      headerAttrs: {
        hidden: true,
      },
      sort: true,
      formatter: (cell: number) => {
        return (
          <>
            <div
              className={classNames(
                'currencyColumn body-medium',
                styles.currencyRowColumn
              )}
            >
              {cell ? CURRENCY.convertToMainUnit(cell) : '$0.00'}
            </div>
          </>
        );
      },
    },
  ];

  /**
   * Add the date filter to the URL as param query
   * @param {Date[]} date[] - Date[]
   */
  const applyDateFilter = (dateFilter: Array<string>) => {
    if (dateFilter.length > 0) {
      if (dateFilter.length === 1) {
        if (dateFilter[0]) {
          dateFilter[1] = todayDate;
        } else if (dateFilter[1]) {
          dateFilter[0] = fromDate;
        }
      }
      navigate({
        search: QUERY.update(location.search, { dates: dateFilter.join() }),
      });
    }
  };

  const dateValues: string[] = [];

  useEffect(() => {
    if (dateFromValue) {
      dateValues[0] = dateFromValue;
    }
    if (dateToValue) {
      dateValues[1] = dateToValue;
    }
    if (dateValues.length > 0) {
      applyDateFilter(dateValues);
    }
    const interval = util.intervalInDays(
      dateFromValue || fromDate,
      dateToValue || todayDate
    );

    // set possible interval types according to date range

    if (excludeHourly) {
      toSetIntervalTypes(utils.mapIntervalToFrequencyBanking(interval));
    } else {
      toSetIntervalTypes(util.mapIntervalToFrequency(interval));
    }
    if (intervalTypes.length > 0) {
      setActiveFreq(intervalTypes[0]);
    }
  }, [dateFromValue, dateToValue]);

  const expandRow = {
    className: 'dropdownArea',
    renderer: (row: SUMMARY.ISummaryData<T>) => {
      return <div className="w-100">{expandRowFormatter(row)}</div>;
    },
    expandColumnRenderer: ({ expanded }: ExpandColumnRendererProps) => {
      if (expanded) {
        return (
          <span className="react-bootstrap-table-sort-order dropup d-flex justify-content-center align-items-center">
            <i className="ph-caret-up-fill icon-md"></i>
          </span>
        );
      }
      return (
        <span className="react-bootstrap-table-sort-order d-flex justify-content-center align-items-center">
          <i className="ph-caret-down-fill icon-md"></i>
        </span>
      );
    },
    expandColumnPosition: 'right' as const,
  };

  return (
    <div
      className={classNames(
        styles.summaryTabContainer,
        products.length === 0 ? styles.emptySummaryTab : ''
      )}
    >
      <div className="d-flex justify-content-between">
        {description && products.length > 0 && (
          <div className="mb-3 flex-grow-1">{description()}</div>
        )}
        {addDateFilter ? (
          <div className="d-flex align-items-center mb-5">
            <DatePicker
              data-testid="from-date-picker"
              initialValue={formatDate(
                dateFromValue || fromDate,
                'MMM dd yyyy'
              )}
              setDate={setDateFrom}
              format="MMM DD YYYY"
              beforeDate={formatDate(dateToValue || todayDate, 'MMM dd yyyy')}
            />
            <span className={classNames('mx-2', styles.dateSeparator)}>-</span>
            <DatePicker
              data-testid="to-date-picker"
              initialValue={formatDate(dateToValue || todayDate, 'MMM dd yyyy')}
              setDate={setDateTo}
              isEndDate
              // isLocalTime
              format="MMM DD YYYY"
              afterDate={formatDate(dateFromValue || fromDate, 'MMM dd yyyy')}
            />
          </div>
        ) : null}
        {(addDateFilter || products.length > 0) && showIntervalButton && (
          <div className="ml-auto">
            <FrequencyButtonGroup
              enabledFreq={intervalTypes}
              activeFreq={activeFreq}
              setActiveFreq={setActiveFreq}
            />
          </div>
        )}
      </div>

      <BootstrapTable
        wrapperClasses="table-responsive"
        keyField="key"
        data={products}
        headerClasses="hideHeader"
        expandRow={expandRow}
        columns={columns}
        hover
        bootstrap4
        bordered={false}
        noDataIndication={<NoSummaryFound />}
        id={testId}
      />
      {paginationProp && (
        <Pagination
          pageId={paginationProp.id}
          results={paginationProp.numResults}
          prevPage={() => paginationProp.loadPage(paginationProp.prevKey)}
          nextPage={() => paginationProp.loadPage(paginationProp.nextKey)}
          prevKey={paginationProp.prevKey}
          nextKey={paginationProp.nextKey}
        />
      )}
    </div>
  );
};

export default Summary;
