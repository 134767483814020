import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { PAYMENTS } from 'state/payment/type';
import CURRENCY from 'utils/currency';
import classNames from 'classnames';
import styles from '../../tips/tips.module.scss';
import { getPercentChange } from 'utils/dashboard';
import { AmountChangePercentLabel } from 'utils/status-formatter';
import { UncontrolledTooltip } from 'reactstrap';
import { formatDate } from 'utils/date-format';

interface IProps {
  summary_data: PAYMENTS.ISummaryData;
  previousStartDate: string;
  previousEndDate: string;
}

const TotalTipSummary = ({
  summary_data,
  previousStartDate,
  previousEndDate,
}: IProps) => {
  const tooltipText = `Compared to previous period: ${formatDate(
    previousStartDate,
    'MMM dd yyyy, hh:mm a'
  )} - ${formatDate(previousEndDate, 'MMM dd yyyy, hh:mm a')}`;

  const totalTipPercentChange = getPercentChange(
    summary_data.total_tip,
    summary_data.previous_total_tip
  );

  const numberOfTipPercentChange = getPercentChange(
    summary_data.number_of_tips,
    summary_data.previous_number_of_tips
  );

  const averageOfTipPercentChange = getPercentChange(
    summary_data.average_tip_amount,
    summary_data.previous_average_tip_amount
  );

  const products = [
    {
      text: 'TOTALS',
      total_tip: summary_data.total_tip,
      number_of_tips: summary_data.number_of_tips,
      average_tip_amount: summary_data.average_tip_amount,
    },
  ];

  const columns = [
    {
      dataField: 'text',
      text: '',
      style: {
        width: '35%',
      },
      headerStyle: {
        height: '48px',
      },
      formatter: (cell: string) => {
        return (
          <div className={classNames(styles.totalTextEmployeeSummary)}>
            {cell ? cell.toUpperCase() : '' || ''}
          </div>
        );
      },
    },
    {
      dataField: 'total_tip',
      text: 'Tips',
      formatter: (cell: number) => {
        return (
          <div
            className={classNames(
              'currencyColumn body-medium text-left',
              styles.currencyRowColumnTipSummary,
              styles.totalTextEmployeeSummary
            )}
          >
            {cell ? CURRENCY.convertToMainUnit(cell) : '$0.00'}
            <span
              className={classNames(styles.tipPercentChange)}
              id="totaltiptooltip"
            >
              {(totalTipPercentChange || totalTipPercentChange === 0) && (
                <AmountChangePercentLabel value={totalTipPercentChange} />
              )}
              {tooltipText && (
                <>
                  <UncontrolledTooltip
                    placement="bottom"
                    target="totaltiptooltip"
                    modifiers={{
                      preventOverflow: { boundariesElement: 'window' },
                    }}
                  >
                    {tooltipText}
                  </UncontrolledTooltip>
                </>
              )}
            </span>
          </div>
        );
      },
      style: {
        width: '21.67%',
        height: '64px',
      },
    },
    {
      dataField: 'number_of_tips',
      text: 'No of Tips',
      style: {
        width: '21.67%',
      },
      formatter: (cell: number) => {
        return (
          <div
            className={classNames(
              'currencyColumn body-medium text-left',
              styles.currencyRowColumnTipSummary,
              styles.totalTextEmployeeSummary
            )}
          >
            {cell}
            <span
              className={classNames(styles.tipPercentChange)}
              id="numoftiptooltip"
            >
              {(numberOfTipPercentChange || numberOfTipPercentChange === 0) && (
                <AmountChangePercentLabel value={numberOfTipPercentChange} />
              )}
              {tooltipText && (
                <>
                  <UncontrolledTooltip
                    placement="bottom"
                    target="numoftiptooltip"
                    modifiers={{
                      preventOverflow: { boundariesElement: 'window' },
                    }}
                  >
                    {tooltipText}
                  </UncontrolledTooltip>
                </>
              )}
            </span>
          </div>
        );
      },
    },
    {
      dataField: 'average_tip_amount',
      text: 'Average Tip Amount',
      formatter: (cell: number) => {
        return (
          <div
            className={classNames(
              'currencyColumn body-medium text-left',
              styles.currencyRowColumnTipSummary,
              styles.totalTextEmployeeSummary
            )}
          >
            {cell ? CURRENCY.convertToMainUnit(cell) : '$0.00'}
            <span
              className={classNames(styles.tipPercentChange)}
              id="avgoftiptooltip"
            >
              {(averageOfTipPercentChange ||
                averageOfTipPercentChange === 0) && (
                <AmountChangePercentLabel value={averageOfTipPercentChange} />
              )}
              {tooltipText && (
                <>
                  <UncontrolledTooltip
                    placement="bottom"
                    target="avgoftiptooltip"
                    modifiers={{
                      preventOverflow: { boundariesElement: 'window' },
                    }}
                  >
                    {tooltipText}
                  </UncontrolledTooltip>
                </>
              )}
            </span>
          </div>
        );
      },
      style: {
        width: '21.67%',
      },
    },
  ];

  return (
    <BootstrapTable
      id="total_tip_summary"
      wrapperClasses="table-responsive"
      keyField="id"
      data={products}
      columns={columns}
      bordered={false}
      hover
      condensed
      bootstrap4
      classes={classNames(styles.tableCustomStyle)}
      rowStyle={(row, rowIndex) => {
        if (rowIndex === 0) {
          return { backgroundColor: '#f7f8f8' };
        }
        return {};
      }}
    />
  );
};

export default TotalTipSummary;
