import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import PageHeading from 'components/heading';
import { toIsoStringWithTz } from 'utils/date-format';
import { BANKING_TABS } from 'utils/constants';
import useQuery from 'utils/hooks/useQuery';
import QUERY from 'utils/query';
import useBanking from 'state/banking/hooks/useBanking';
import { SUMMARY } from 'state/summary/type';
import TotalBalanceSummary from './total-balance-summary';
import BankingDetails from './bankingDetails';

export const fromDate = toIsoStringWithTz(
  new Date(
    new Date(new Date().setDate(new Date().getDate() - 30)).setHours(0, 0, 0, 0)
  )
);
export const todayDate = toIsoStringWithTz(
  new Date(new Date().setDate(new Date().getDate()))
);

const BankingStatements = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery(location.search);
  const [intervalTypes, setIntervalTypes] = useState<SUMMARY.IIntervalType[]>(
    []
  );

  const firstAmount = query.get('amount1') || undefined;
  const secondAmount = query.get('amount2') || undefined;
  const amountOption = query.get('condition') || 'equal';
  const typeOption = query.get('transaction_type')?.split(',') || [];

  const { banking, toFetchBankingTotalBalance } = useBanking();

  const [activeTab, setActiveTab] = useState(
    query.get('tab')?.toLowerCase() ||
      (BANKING_TABS.length > 0 && BANKING_TABS[0].label.toLowerCase()) ||
      ''
  );

  useEffect(() => {
    const queryTabParam = query.get('tab')?.toLowerCase();
    if (location.search && queryTabParam) {
      setActiveTab(
        BANKING_TABS.find(
          (t) => t.label.toLowerCase() === queryTabParam
        )?.label.toLowerCase() || ''
      );
    } else {
      navigate({
        search: '?tab=all',
      });
    }
  }, [location.search]);

  useEffect(() => {
    toFetchBankingTotalBalance();
  }, []);

  return (
    <>
      <PageHeading heading="Statements" className="mb-5" />
      {
        <TotalBalanceSummary
          totalBalance={banking?.total_balance}
        />
      }
      <Nav tabs id="banking-statements-tabs" className="mb-5">
        {BANKING_TABS.map((item) => {
          return (
            <>
              <NavItem>
                <NavLink
                  className={classNames({
                    active: item?.label.toLowerCase() === activeTab,
                  })}
                  id={`${item.label.toLowerCase()}-tab`}
                  onClick={() => {
                    navigate({
                      search: QUERY.update(location.search, {
                        tab: item.label.toLowerCase(),
                      }),
                    });
                  }}
                >
                  {item?.label}
                </NavLink>
              </NavItem>
            </>
          );
        })}
      </Nav>
      <TabContent activeTab={activeTab}>
        <BankingDetails
          queryParams={location.search}
          firstAmount={firstAmount}
          secondAmount={secondAmount}
          activeTab={activeTab}
          amountOption={amountOption}
          typeOption={typeOption}
        />
        {/* </TabPane> */}
      </TabContent>
    </>
  );
};

export default BankingStatements;
