import { PAYOUTTYPE } from 'components/modals/banking/payouts/payout';
import { PAYMENTS } from 'state/payment/type';
import { SUMMARY } from 'state/summary/type';

// skipcq: JS-0337
export namespace BANKING {
  interface ErrorObject {
    loc: Array<string>;
    msg: string;
    type: string;
  }
  export interface Error {
    fetchStats?: {
      message?: string;
    };
    fetchSummaries?: {
      message?: string;
    };
    fetchGrossDetails?: {
      message?: string;
    };
    fetchPayout?: {
      message?: string;
    };
    fetchVendors?: {
      message?: string;
    };
    createVendor?: {
      detail: [ErrorObject];
    };
    creditDetails?: {
      message?: string;
    };
    debitDetails?: {
      message?: string;
    };
    exportUrl?: {
      message?: string;
    };
    bankingSummaryExportUrl?: {
      message?: string;
    };
    initiateSelfPayout?: {
      detail: [ErrorObject];
    };
    initiateExternalPayout?: {
      detail: [ErrorObject];
    };
    externalAccount?: {
      message?: string;
    };
    addBankAccount?: {
      detail: [ErrorObject];
    };
    addVendorAccount?: {
      detail: [ErrorObject];
    };
    fetchPayouts?: {
      message?: string;
    };
    vendorAccountList?: {
      message?: string;
    };
    fetchPayoutsSummaryData?: {
      message?: string;
    };
    totalFailedAmount?: {
      message?: string;
    };
    fetchFailedTransactions?: {
      message?: string;
    };
    fetchWriteOffs?: {
      message?: string;
    };
    batchRetry?: {
      message?: string;
    };
    writeoff?: {
      message?: string;
      error_code?: string;
    };
    cashRecord?: {
      message?: string;
      getSessionStepUpAuth?: {
        detail: [ErrorObject];
      };
      verifyOtp?: {
        detail: [ErrorObject];
      };
    };
  }

  export enum BankingTxnType {
    ALL = 'all',
    INCOMING = 'incoming',
    OUTGOING = 'outgoing',
  }

  export enum BankingFailedTxnType {
    FAILED_ACH = 'failed_ach',
    CASH_RECORDED = 'cash',
    WRITE_OFF = 'writeoff',
  }

  export enum PayoutEvents {
    INITIATED = 'payout.created',
    FAILED = 'payout.failed',
    SUCCESSFUL = 'payout.succeeded',
  }

  export enum TransactionFeeEvents {
    CREATED = 'transaction_fee.created',
  }

  export enum OutgoingTypes {
    FEE = 'FEE',
    RETURN = 'return',
    SELF_PAYOUT = 'SELF_PAYOUT',
    EXTERNAL_PAYOUT = 'EXTERNAL_PAYOUT',
    REFUND = 'REFUND',
  }

  export enum IPayoutType {
    SELF_PAYOUT = 'self_payout',
    EXTERNAL_PAYOUT = 'external_payout',
  }

  export type IAddBankAccount = {
    account_holder_name: string;
    institution_name: string;
    routing_number: string;
    account_number: string;
    re_account_number: string;
    account_nick_name?: string;
  };

  export type IAddVendorBankAccount = {
    beneficiary_name: string;
    bank_name: string;
    routing_number: string;
    account_number: string;
    re_account_number: string;
    account_nick_name?: string;
    vendor_id: string;
  };

  export type IAddVendor = {
    name: string;
    email: string;
    phone: string;
    proof_id: string;
  };

  export type IFetchBankingStatsRequest = {
    start_date: string;
    end_date: string;
  };

  export type IFetchBankingSummariesRequest = {
    start_date: string;
    end_date: string;
    interval_types: string;
  };

  export type IFetchBankingDailySummariesRequest = {
    key?: string;
  };

  export type IFetchBankingDetails = {
    key?: string | null;
    amount_1?: string;
    amount_2?: string;
    search_string?: string;
    type?: string;
    start_date?: string;
    end_date?: string;
    condition?: string;
    status?: string;
    direction?: string;
    outgoing_type?: string;
  };

  export enum ITransactionSource {
    WEB = 'web',
    PAYMENTLINK = 'payment_link',
    INSTORE = 'in_store',
  }

  export type ICreator = {
    create_date: string;
    email: string;
    first_name: string;
    id: string;
    last_name: string;
    name: string;
  };

  export type IIndividualTransactionResult = {
    ach_upload_date: string;
    id: string;
    organization_id: string;
    store_id: string;
    store_name: string;
    consumer_id: string;
    customer_first_name: string;
    customer_last_name: string;
    customer_phone: string;
    customer_email: string;
    customer_create_date: string;
    consumer_bank_name: string;
    consumer_account: string;
    consumer_profile_id: string;
    expected_settlement_date: string;
    order_id: string;
    order_create_date: string;
    order_amount: number;
    track_id: string;
    order_status: string;
    tip_amount: number;
    order_fee_amount: number;
    transaction_fee_amount: number;
    creator_user: ICreator;
    charge_id: string;
    charge_amount: number;
    charge_status: string;
    charge_create_date: string;
    payment_type: string;
    external_id?: string;
    external_short_identifier: string;
    create_date: string;
    calculated_total: number;
    transaction_status: string;
    update_date?: string;
    ach_debit_id_count: number;
    ach_debit_error_count: number;
    return_code: string;
    return_date: string;
    return_reason: string;
    source: ITransactionSource;
    transaction_canceled_date: string;
    timeline_notes: string;
    updated_by_username: string;
    notes_added_date: string;
    refund_create_date: string;
    refunded_by: string;
    refund_id: string;
    refund_status: string;
    cash_recorded_date: string;
    is_retryable: boolean;
  };

  export type IWriteOffTransaction = {
    id: string;
    organization_id: string;
    amount: number;
    name: string;
    swifter_id: string;
    external_id: string;
    write_off_date: string;
    return_date: string;
    transaction_date: string;
    order_id: string;
    charge_id: string;
    status: string;
    create_date: string;
    update_date: string;
  };

  export type IVendorAccountList = {
    id: string;
    vendor_id: string;
    name: string;
    ach_account_id_external: string;
    status: string;
    beneficiary_name: string;
    account_short_name: string;
    create_date: string;
    update_date: string;
    last_4: string;
  };
  export interface IPayout {
    id: string;
    customer_id: string;
    create_date: string;
    customer_create_date: string;
    customer_email: string;
    customer_first_name: string;
    customer_last_name: string;
    customer_phone: string;
    masked_account_number: string;
    institution_name: string;
    account_holders_name: string;
    payment_method: string;
    type: string;
    payout_status: string;
    amount: number;
    return_code: string | null;
    return_date: string | null;
    return_reason: string | null;
    creator_user: ICreator;
    invoice_date: string | null;
    invoice_number: string | null;
    vendor_name: string | null;
    vendor_proof_id: string | null;
  }

  export type IIndividualDetail = IIndividualTransactionResult | IPayout;
  export type IBankStatement = {
    id: string;
    ach_id: string;
    ach_account_id: string;
    baas_account_id: string;
    baas_reference_id: string;
    original_baas_transaction_id: string;
    internal_reference_id: string;
    ach_account_number_last4: string;
    account_number_last4: string;
    amount: number;
    direction: string;
    balance: number;
    available_balance: number;
    is_tagged: boolean;
    provider: string;
    payment_rail: string;
    resource: string;
    create_date: string;
    update_date: string;
    transaction_create_date: string;
    resource_object: IIndividualDetail;
  };

  export type IFetchCSVTransactions = {
    start_date?: string;
    end_date?: string;
    status?: string; // skipcq: JS-0122
    store_name?: string;
    condition?: string;
    amount?: string;
    timezone?: string;
  };

  export type IFetchBankingSummay = {
    start_date?: string;
    end_date?: string;
    export_type: string;
    timezone?: string;
  };

  export interface IBalanceSummaryBreakdown {
    opening_balance: number;
    total_incoming: number;
    total_outgoing: number;
    closing_balance: number;
  }

  export interface IIncomingSummaryBreakdown {
    gross_amount: number;
    order_amount: number;
    tip_amount: number;
    convenience_fee_amount: number;
  }

  export interface IOutgoingSummaryBreakdown {
    platform_fee: number;
    total_refund: number;
    tax: number;
    payout: number;
    total_outgoing: number;
    return_amount: number;
    other_outgoing: number;
  }

  export interface IBankingStats {
    total_balance: number;
    total_incoming: number;
    total_outgoing: number;
    holding_balance: number;
  }

  export interface IBankingTotalBalance {
    total_balance: number;
    holding_balance: number;
    last_update_date: string;
  }

  export type IBankSummaryBreakdown =
    | IBalanceSummaryBreakdown
    | IOutgoingSummaryBreakdown
    | PAYMENTS.ITransactionSummaryBreakdown;

  export interface IPayoutPayload {
    amount: number;
  }

  export interface IPayoutEvent {
    actor_id: string;
    create_date: string;
    entity_id: string;
    entity_type: string;
    payload: IPayoutPayload;
    id: string;
    name: PayoutEvents | TransactionFeeEvents; // skipcq: JS-0122
    organization_id: string;
  }

  export interface IPayoutDetails {
    payout: IPayout;
    events: IPayoutEvent[]; // skipcq: JS-0122
  }

  export type IPayoutsSearch = {
    start_date?: string;
    end_date?: string;
    status?: string;
    invoice_start_date?: string;
    invoice_end_date?: string;
    search_string?: string;
    key?: string | null;
  };

  export interface IFetchBusinessFiAccount {
    id: string;
    ach_account_id_external: string;
    create_date: string;
    status: string;
    provider: string;
    application_id: string;
    account_id: string;
    organization_id: string;
    type: string;
    update_date: string;
  }

  export type ISelfPayout = {
    amount: number;
    type: PAYOUTTYPE;
    account_id: string;
    notes?: string;
  };

  export type IExternalPayout = {
    amount: number;
    type: PAYOUTTYPE;
    account_id: string;
    invoice_id: string;
    invoice_upload_id: string;
    invoice_date: string;
    notes?: string;
  };

  export interface ICustomer {
    baas_reference_id: string;
    create_date: string;
    first_name: string;
    id: string;
    internal_reference_id: string;
    last_name: string;
    phone_number: string;
    provider: string;
    status: string;
    type: string;
    update_date: string;
  }

  export interface IFetchAccountDetails {
    account_name: string;
    institution_name: string;
    masked_account_number: string;
  }

  export interface IStoreVendorAccount {
    account_name: string;
    institution_name: string;
    masked_account_number: string;
    account_id: string;
  }

  export type IFetchBankAccount = {
    business_fi_account_id: string;
    receiver_account_details: IFetchAccountDetails;
    sender_account_details: IFetchAccountDetails;
  };

  export type IFetchVendors = {
    start_date?: string;
  };

  export type IVendor = {
    id: string;
    organization_id: string;
    create_date: string;
    update_date: string;
    name: string;
    email: string;
    phone: string;
    status: string;
    proof_id:
      | string
      | {
          encrypted: string;
          last_four: string;
        };
  };
  export interface IListVendors {
    vendors_list: IVendor[];
  }
  export interface IInvoiceDetails {
    date: string;
    upload_id: string;
    id: string;
    fileURL: string;
    fileName: string;
    fileUploadId: string;
  }

  export interface Payouts {
    create_date?: Date;
    invoice_date?: Date;
    invoice_number?: string;
    status?: string;
  }

  export interface TotalFailedSummaryData {
    total_failed: number;
    total_cash_recorded: number;
    total_write_off: number;
  }

  export type IFetchFailedTransactionsRequest = {
    start_date?: string | null;
    end_date?: string | null;
    key?: string;
    amount_1?: string;
    amount_2?: string;
    condition?: string;
    search_string?: string;
    type: string;
    transaction_date?: string;
    return_date?: string;
    cash_record_date?: string;
  };

  export type IFetchWriteOffTransactionsRequest = {
    start_date?: string | null;
    end_date?: string | null;
    key?: string;
    amount_1?: string;
    amount_2?: string;
    condition?: string;
    search_string?: string;
    transaction_date?: string;
    return_date?: string;
    write_off_date?: string;
  };

  export type IBatchRetryRequest = {
    charge_ids: string[];
  };
  export type IBatchWriteOffRequest = {
    charge_ids: string[];
  };

  export type FailedChargeDetail = {
    charge_id: string;
    error_code: string;
    error_code_message: string;
  };

  export type retried_charges = {
    id: string;
    create_date: string;
    type: string;
    status: string;
    amount: number;
    balance_transaction_id: string;
    consumer_fi_account_id: string;
    business_fi_account_id: string;
    organization_id: string;
    marketplace_id?: string;
    track_number: string;
  };

  export type IBatchRetryResult = {
    retried_charges: retried_charges[];
    failed_charges: FailedChargeDetail[];
  };

  export interface IPayoutSummaryBreakdown {
    self_payout_amount: number;
    external_payout_amount: number;
    total_payout_amount: number;
  }

  export interface IChallengeData {
    challenge_id: string;
    user_id: string;
    challenge_type: string;
    public: { email: string };
    validation_attempts: number;
  }

  export interface IGetUserSession {
    id?: string;
    create_date?: string;
    available_on_date?: string;
    status?: string;
    amount?: number;
    type?: string;
    sub_type?: string;
    organization_id?: string;
    business_fi_account_id?: string;
    track_number?: string;
    charge_id?: string;
    direction?: string;
    challenge_data?: IChallengeData;
    session?: {
      key?: string;
      level?: number;
    };
    error?: boolean;
    error_str?: string;
    error_code?: string;
  }

  export type PendingTransactionSummaryData = {
    ach: number;
    cash: number;
  };

  export type SuccessfullDepositSummaryData = {
    ach: number;
    cash: number;
    retried_ach: number;
    total_deposit: number;
  };

  export type DebitSummaryData = {
    successful_debit: SuccessfullDepositSummaryData;
    cash_failed_ach: number;
    pending_transaction: PendingTransactionSummaryData;
    total_pending: number;
    other_incoming: number;
    total_debit: number;
  };

  export type PayoutSummaryData = {
    external: number;
    self: number;
    total_payouts: number;
  };

  export type CreditSummaryData = {
    failed_ach: number;
    write_offs: number;
    payouts: PayoutSummaryData;
    total_payouts: number;
    fee: number;
    refunds: number;
    other_outgoing: number;
    withdrawals: number;
    total_credit: number;
  };

  export type IBankingStatsData = {
    available_balance: number;
    holding_balance: number;
    total_balance: number;
    opening_balance: number;
    closing_balance: number;
    total_incoming: number; // this is same as total debit column in UI
    total_outgoing: number; // this is for total credit column in UI
    last_update_balance: string;
    debit: DebitSummaryData;
    credit: CreditSummaryData;
  };

  export interface BankingReducer {
    isLoading: boolean;
    error: Error;
    stats: IBankingStatsData;
    transaction_summary_daily: Record<
      BANKING.BankingTxnType,
      IPaginatedResponse<SUMMARY.ISummaryData<BANKING.IBankSummaryBreakdown>>
    >;
    total_gross_details: IPaginatedResponse<IBankStatement>;
    credit_details: IPaginatedResponse<IBankStatement>;
    debit_details: IPaginatedResponse<IBankStatement>;
    export_url: string;
    external_account: IFetchBankAccount;
    fetchExternalAccount: boolean;
    add_bank_account: boolean;
    add_vendor_account: boolean;
    payoutDetails: IPayoutDetails;
    payoutList: IPaginatedResponse<Payouts>;
    payout_amount: string;
    vendors: IListVendors;
    update_vendor: boolean;
    vendor_updated: IVendor | undefined;
    vendor_added: IVendor | undefined;
    invoice_details: IInvoiceDetails | undefined;
    vendor_id: string | undefined;
    payout_type: PAYOUTTYPE | undefined;
    vendor_bank_account: IStoreVendorAccount | undefined;
    vendorAccountList: IVendorAccountList[];
    notes: string | undefined;
    payout_summary: SUMMARY.ISummaryResponse<IPayoutSummaryBreakdown>;
    failed_transaction_summary: TotalFailedSummaryData;
    failed_transactions: IPaginatedResponse<IIndividualTransactionResult>;
    write_off_transactions: IPaginatedResponse<IWriteOffTransaction>;
    batch_retry: IBatchRetryResult;
    initiate_write_off: IWriteOffTransaction;
    cash_recorded: PAYMENTS.IIndividualTransactionResult;
    auth_session: IGetUserSession;
    banking_summary_export_url: string;
    total_balance: IBankingTotalBalance;
  }
}
