import React, { useEffect, useState } from 'react';
import usePayment from 'state/payment/hooks/usePayment';
import Timeline from './timeline';
import Orders, { IOrderInfo } from './transactionDetailsCard/order';
import PaymentDetails from './transactionDetailsCard/paymentDetails';
import PaymentMethod from './transactionDetailsCard/paymentMethod';
import Summary from './transactionDetailsCard/summary';

const TransactionDetails = (): JSX.Element => {
  const [transactionInfo, setTransactionInfo] = useState({} as IOrderInfo);

  // checking data availability
  const [isDataAvailable, setIsDataAvailable] = useState(false);

  // payment object and hooks to fetch transactions
  const { payment } = usePayment();

  useEffect(() => {
    if (Object.keys(payment?.individualTransaction).length) {
      if (payment.individualTransaction.transaction) {
        setIsDataAvailable(true);
      } else {
        setTransactionInfo({
          charge: 'na',
        });
        setIsDataAvailable(true);
      }
    } else {
      setIsDataAvailable(false);
    }
  }, [payment.individualTransaction]);

  useEffect(() => {
    if (payment.error?.addIndividualTransaction) {
      setIsDataAvailable(false);
    }
  }, [payment.error]);
  return (
    <>
      {isDataAvailable ? (
        <>
          {transactionInfo.charge !== 'na' ? (
            <>
              <Summary info={payment?.individualTransaction?.transaction} />
              <PaymentDetails
                info={payment?.individualTransaction?.transaction}
              />
              <PaymentMethod
                info={payment?.individualTransaction?.transaction}
              />
              <Timeline
                info={payment?.individualTransaction?.transaction}
                events={payment.individualTransaction.events}
              />
            </>
          ) : (
            <>
              <Orders info={transactionInfo} />
            </>
          )}
        </>
      ) : (
        <div className="pt-2 pb-2">
          <h5 className="d-flex justify-content-center">
            No details available
          </h5>
        </div>
      )}
    </>
  );
};

export default TransactionDetails;
