import React from 'react';
import classNames from 'classnames';
import CURRENCY from 'utils/currency';
import styles from './amount-details.module.scss';

interface IProps {
  amount?: number;
  unit?: string;
  statusBadge?: JSX.Element;
  className?: string;
  props?: Record<string, string>;
}

const AmountDetails = ({
  amount,
  unit,
  statusBadge,
  className = '',
  props,
}: IProps): JSX.Element => {
  return (
    <div
      className={classNames(
        'd-flex align-items-center',
        styles.transaction_amount,
        className
      )}
      {...props}
    >
      {(amount || amount === 0) && (
        <>
          <span className={classNames(styles.amount)} id="amount-converted">
            {CURRENCY.convertToMainUnit(amount)}
          </span>
          <span className={classNames(styles.amount_unit)}>{unit}</span>
        </>
      )}
      {statusBadge}
    </div>
  );
};

export default AmountDetails;
