import React from 'react';
import { Alert, Card } from 'reactstrap';
import classNames from 'classnames';
import { Routes } from 'react-router-dom';
import { getinviteParams } from 'utils/inviteUrl';
import routeConfig from 'routes/config';
import styles from './layouts.module.scss';
import { getConfig, getRoutes } from './helper';

const AuthLayout = (): JSX.Element => {
  // render auth routes + handler for invalid invite link

  const { invite } = getinviteParams(window.location.search);
  const isInvalidInvite = invite === 'DECLINED' || invite === 'EXPIRED';

  const renderInvalidMessage = () => {
    // route: /auth/?invite={invite_status}

    return (
      <Card className={classNames('border-0', styles.verifyAccountCard)}>
        <h1>Invalid invite!</h1>
        <Alert
          isOpen
          className={classNames(
            'd-flex align-items-start p-0 pt-2 px-2 m-0 mt-6 ',
            styles.warningBox
          )}
        >
          <i className="ph-warning-circle-fill pl-1 warningIcon"></i>
          <div className="ml-2 pr-1">
            <strong className="body-subtext-semibold">
              This invite is invalid. It may be because of one of the following
              reasons:
            </strong>
            <ul className="pl-5">
              <li>Your invite link has expired</li>
              <li>Your invite link was not found</li>
              <li>You had declined the invite previously</li>
            </ul>
          </div>
        </Alert>
        <p className="mt-5 body-subtext-regular">
          Please contact your
          <strong className="body-subtext-semibold ml-1">admin</strong> for a
          new invite link.
        </p>
      </Card>
    );
  };

  return (
    <>
      {isInvalidInvite ? (
        renderInvalidMessage()
      ) : (
        <Routes>{getRoutes([], '', getConfig(routeConfig.AUTH.layout))}</Routes>
      )}
    </>
  );
};

export default AuthLayout;
