import React, { Dispatch, SetStateAction } from 'react';
import Layout from './components/layout';
import usePaymentConfig from 'state/paymentConfig/hooks/usePaymentConfig';
import { PAYMENT_CONFIG } from 'state/paymentConfig/type';
import { PaymentSolutionTab } from '../paymentConfig';

export const initialDeviceConfig: Record<string, string> = {
  deviceName: '',
  registerId: '',
  printConfig: 'NONE',
  deviceAuthCode: '',
};
interface IProps {
  defaultProvider: PAYMENT_CONFIG.IProcessors | undefined;
  setDefaultProvider: Dispatch<
    SetStateAction<PAYMENT_CONFIG.IProcessors | undefined>
  >;
  activeTab: PaymentSolutionTab;
}
const IntegratedAch = ({
  defaultProvider,
  setDefaultProvider,
  activeTab,
}: IProps): JSX.Element => {

  return (
    <>
      <Layout
        defaultProvider={defaultProvider}
        setDefaultProvider={setDefaultProvider}
        activeTab={activeTab}
        localTab={PaymentSolutionTab.ACH}
      />
    </>
  );
};

export default IntegratedAch;
