import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  IActiveState,
  PAYOUT_STAGE,
} from 'components/modals/banking/payouts/payout';
import RadioButtonCard, { IRadioConfig } from 'components/card-radio-input';
import { Button } from 'reactstrap';
import styles from './styles.module.scss';

interface IProps {
  activeState: IActiveState;
  setActiveState: Dispatch<SetStateAction<IActiveState>>;
  setNavbarConfig: () => void;
}
const PayoutOptions = ({
  activeState,
  setActiveState,
  setNavbarConfig,
}: IProps): JSX.Element | null => {
  const [payoutType, setPayoutType] = useState<string>();
  const setPayoutStage = () => {
    if (payoutType === 'external_payout') {
      activeState.payoutStage = PAYOUT_STAGE.external;
      setActiveState({ ...activeState });
    } else {
      activeState.payoutStage = PAYOUT_STAGE.self;
      setActiveState({ ...activeState });
    }
    setNavbarConfig();
  };

  useEffect(() => {
    const footerElement = (
      <>
        <Button
          color="primary"
          className="btn btn-primary"
          disabled={payoutType === undefined}
          type="submit"
          onClick={() => setPayoutStage()}
        >
          <span className="d-flex">
            Continue<i className="ph-arrow-right pl-1"></i>
          </span>
        </Button>
      </>
    );
    activeState.footer = footerElement;
    setActiveState({ ...activeState });
  }, [payoutType]);

  const radioOptions = [
    {
      detailsElement: (
        <>
          <div className="keyHeading body-subtext-semibold mt-4">
            External Payouts
          </div>
          <div className="keySubHeading footnote">
            Make payment to vendors, suppliers and service providers
          </div>
        </>
      ),
      value: 'external_payout',
      selected: payoutType === 'external_payout',
      onChangeHandler: (e: string) => {
        setPayoutType('external_payout');
      },
      icon: (
        <>
          <div className={styles.iconContainer}>
            <i
              className={classNames(styles.icon, 'ph ph-handshake d-flex')}
            ></i>
          </div>
        </>
      ),
    },
    {
      detailsElement: (
        <>
          <div className="keyHeading body-subtext-semibold mt-6">
            Self Payouts
          </div>
          <div className="keySubHeading footnote">
            Pay out funds to your external account
          </div>
        </>
      ),
      value: 'self_payout',
      selected: payoutType === 'self_payout',
      onChangeHandler: (e: string) => {
        setPayoutType('self_payout');
      },
      icon: (
        <>
          <div className={styles.iconContainer}>
            <i
              className={classNames(styles.icon, 'ph ph-user-switch d-flex')}
            ></i>
          </div>
        </>
      ),
    },
  ];

  const featurePointConfig = [
    {
      featurePointText:
        'Please make sure that you have enough balance to make a payment',
      icon: 'ph ph-currency-dollar',
    },
    {
      featurePointText: 'Payouts take 2-3 banking business days to settle',
      icon: 'ph ph-bank-fill',
    },
  ];

  return (
    <>
      <div
        className={classNames(
          styles.formContainer,
          styles.formInfoContainer,
          'overflow-hidden hide-scrollbars'
        )}
      >
        <h1 className={classNames(styles.headingInfo, 'mb-5')}>
          Select type of Payout
        </h1>

        <br />
        <div className="d-flex pb-7">
          {radioOptions.map((option: IRadioConfig) => (
            <RadioButtonCard
              config={option}
              name="PayoutType"
              cardStyles={{
                marginRight: '24px',
                width: '281px',
                height: '144px',
              }}
            />
          ))}
        </div>
        <div className="mb-9 mt-8 d-flex">
          <div style={{ float: 'left' }}>
            <span className={classNames(styles.featureHeading, 'd-flex mb-3')}>
              Before you get started
            </span>
            {featurePointConfig.map((item) => (
              <div className="d-flex align-items-center mt-3">
                <div className={styles.featureIconContainer}>
                  <span>
                    <i
                      className={classNames(
                        styles.featureIcon,
                        item.icon,
                        'd-flex'
                      )}
                    ></i>
                  </span>
                </div>
                <div className={classNames(styles.featurePoint)}>
                  {item.featurePointText}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="mt-5"></div>
        </div>
      </div>
    </>
  );
};

export default PayoutOptions;
