import React, { SyntheticEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Card, UncontrolledTooltip } from 'reactstrap';
import { formatToTz } from 'utils/date-format';
import BootstrapTable from 'react-bootstrap-table-next';
import Filter from 'components/filter';
import Pagination from 'components/pagination';
import Search from 'components/search';
import { EFilterConfigType, IFilterConfig } from 'components/filter/types';
import { useLocation, useNavigate } from 'react-router-dom';
import useEvents from 'state/events/hooks/useEvents';
import { EVENTS } from 'state/events/type';
import useQuery from 'utils/hooks/useQuery';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import styles from './developers.module.scss';

export const SESSION = 'session';
export const CHARGE = 'charge';
export const ORDER = 'order';
export const INFO = 'info';
export const ERROR = 'error';
export const WARNING = 'warning';

export const entityTypeList = [
  { name: 'session', value: SESSION },
  { name: 'charge', value: CHARGE },
  { name: 'order', value: ORDER },
];

const Events = (): JSX.Element => {
  const location = useLocation();
  const queryParams = location?.search;
  const query = useQuery(queryParams);

  const entityType = query.get('entity_type')?.split(',') || [];
  const dates = query.get('dates')?.split(',') || [];
  const dateFrom = dates.length > 0 ? dates[0].replace(' ', '+') : '';
  const dateTo = dates.length > 1 ? dates[1].replace(' ', '+') : '';
  const searchString = query.get('search') || undefined;
  const actorId = query.get('actor_id') || undefined;
  const eventId = query.get('event_id') || undefined;

  const entityValues: string[] = [];
  const dateValues: string[] = [];
  const eventIdValue: string[] = [];

  const { events, toFetchEvent } = useEvents();
  const { organisation } = useOrganisation();
  const { settings } = organisation;

  const [prevKey, setPrevKey] = useState<string | null>(null);
  const [nextKey, setNextKey] = useState<string | null>(null);
  const [numResults, setNumResults] = useState(0);

  const heading = 'Events';
  const subHeading = 'View events here';
  const TABLE_COLUMNS = [
    {
      text: 'date',
      sort: true,
      dataField: 'create_date',
      formatter: (cell: string) => {
        return (
          <>
            {cell
              ? formatToTz(cell, settings?.timezone, 'MMM dd yyyy, hh:mm a')
              : ''}
          </>
        );
      },
    },
    { text: 'Type', sort: true, dataField: 'entity_type' },
    { text: 'Event', sort: true, dataField: 'name' },
    { text: 'Event Id', sort: true, dataField: 'id' },
  ];

  const navigate = useNavigate();

  const getFilterConfig = () => {
    return [
      {
        key: 'dates',
        label: 'Dates',
        type: EFilterConfigType.date,
        data: {
          keys: [],
          values: dateValues,
        },
        selected: false,
      },
      {
        key: 'entity_type',
        label: 'Event Type',
        type: EFilterConfigType.list,
        data: {
          key: 'entity_type',
          options: entityTypeList,
          values: entityValues,
        },
        selected: false,
      },

      {
        key: 'event_id',
        label: 'Event Id',
        type: EFilterConfigType.string,
        data: {
          key: 'event_id',
          values: eventIdValue,
          placeholder: 'evt_xxxxxyyyyyzzzzz',
        },
        selected: false,
      },
    ];
  };

  const defaultFilterConfig: IFilterConfig[] = getFilterConfig();

  // const filterConfig = defaultFilterConfig;

  if (dateFrom) {
    dateValues[0] = dateFrom;
  }
  if (dateTo) {
    dateValues[1] = dateTo;
  }
  const [filterConfig, setFilterConfig] =
    useState<IFilterConfig[]>(defaultFilterConfig);

  useEffect(() => {
    if (events?.list) {
      setFilterConfig(getFilterConfig());
    }
  }, [events]);

  useEffect(() => {
    toFetchEvent({
      start_date: dateFrom || undefined,
      end_date: dateTo || undefined,
      search_string: searchString,
      event_id: eventId || undefined,
      actor_id: actorId,
      entity_type: entityType.length > 0 ? entityType.join(',') : undefined,
    });
  }, [queryParams]);

  // get to prev page
  const prevPage = () => {
    toFetchEvent({
      key: prevKey,
      start_date: dateFrom || undefined,
      end_date: dateTo || undefined,
      search_string: searchString,
      event_id: eventId || undefined,
      actor_id: actorId,
      entity_type: entityType.length > 0 ? entityType.join(',') : undefined,
    });
  };

  // get to next page
  const nextPage = () => {
    toFetchEvent({
      key: nextKey,
      start_date: dateFrom || undefined,
      end_date: dateTo || undefined,
      search_string: searchString,
      event_id: eventId || undefined,
      actor_id: actorId,
      entity_type: entityType.length > 0 ? entityType.join(',') : undefined,
    });
  };

  useEffect(() => {
    if (events?.list?.items?.length > 0) {
      // setting next and prev key
      setNextKey(events.list.next_key);
      setPrevKey(events.list.prev_key);
      setNumResults(events.list.total_count);
    } else {
      setNumResults(0);
    }
  }, [events]);

  // Taking actions on row events
  const rowEvents = {
    onClick: (e: SyntheticEvent<Element, Event>, row: EVENTS.IEventsResult) => {
      navigate(row.id);
    },
  };

  return (
    <>
      <Card className="card-profile border-0">
        <div className={classNames(styles.heading)}>
          <span id="page-heading">{heading}</span>
        </div>
        <div className={classNames(styles.subHeading)}>
          <label htmlFor="event_id">
            {subHeading}
            <span id="eventstooltip" className="pl-1">
              <i className="ph-info-fill icon-md text-muted" />
            </span>
            <UncontrolledTooltip placement="right" target="eventstooltip">
              List of all events.
            </UncontrolledTooltip>
          </label>
        </div>
        <div className="d-flex mb-3 ml-3">
          <span className="mr-auto">
            <Search queryParams={queryParams} />
          </span>
          <span>
            {filterConfig && (
              <Filter config={filterConfig} queryParams={queryParams}></Filter>
            )}
          </span>
        </div>
        <div className="ml-3">
          <BootstrapTable
            wrapperClasses="table-responsive"
            keyField="id"
            data={events.list.items || []}
            columns={TABLE_COLUMNS}
            rowEvents={rowEvents}
            hover
            condensed
            bordered={false}
            noDataIndication={
              <p className="text-center text-regular">No results found</p>
            }
          ></BootstrapTable>

          <Pagination
            results={numResults}
            prevPage={prevPage}
            nextPage={nextPage}
            prevKey={prevKey}
            nextKey={nextKey}
          />
        </div>
      </Card>
    </>
  );
};

export default Events;
