import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import FormLabel from 'components/form-label';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Button, FormGroup, Input, Form, Alert, Label } from 'reactstrap';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import CURRENCY from 'utils/currency';
import styles from './styles.module.scss';
import NumberFormat from 'react-number-format';
import { FULFILMENT_STAGES } from 'state/organisation/type';

const Notifications = (): JSX.Element => {
  const { organisation, toUpdateOrgSettings } = useOrganisation();
  const { settings } = organisation;
  const DEFAULT_ORDER_VALUE = 5000; // cents
  const [showAlert, setShowAlert] = useState(true);

  const FulFilmentStageMap: Record<FULFILMENT_STAGES, string> = {
    [FULFILMENT_STAGES.AWAITING_PROCESSING]: 'Awaiting Processing',
    [FULFILMENT_STAGES.IN_PROCESS]: 'In Process',
    [FULFILMENT_STAGES.PACKED_READY]: 'Packed & Ready',
  };

  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: {
      enable_auto_paylink: settings?.enable_auto_paylink,
      auto_paylink_min_order_value: CURRENCY.convertToMainUnit(
        settings?.auto_paylink_min_order_value || DEFAULT_ORDER_VALUE
      ),
      auto_paylink_fulfilment_stage:
        settings?.auto_paylink_fulfilment_stage ?? undefined,
    },
    shouldUnregister: true,
  });

  const enable_auto_paylink: boolean = useWatch({
    name: 'enable_auto_paylink',
    control,
    defaultValue: settings?.enable_auto_paylink || false,
  });
  const auto_paylink_min_order_value = useWatch({
    name: 'auto_paylink_min_order_value',
    control,
    defaultValue: CURRENCY.convertToMainUnit(
      settings?.auto_paylink_min_order_value || DEFAULT_ORDER_VALUE
    ),
  });
  const auto_paylink_fulfilment_stage = useWatch({
    name: 'auto_paylink_fulfilment_stage',
    control,
    defaultValue: settings?.auto_paylink_fulfilment_stage ?? undefined,
  });

  const validateOrderValue = (amount: string) => {
    if (amount) {
      const value = CURRENCY.getValueFromCurrencyStr(amount);
      return value >= 5;
    }
    return false;
  };

  const disableSaveBtn = () =>
    !(
      enable_auto_paylink &&
      auto_paylink_min_order_value &&
      validateOrderValue(auto_paylink_min_order_value) &&
      auto_paylink_fulfilment_stage
    );

  const getCurrencyValue = (input: string) =>
    CURRENCY.convertToSmallestUnit(CURRENCY.getValueFromCurrencyStr(input));

  const onFormSubmit = (data: Record<string, unknown>) => {
    if (
      data?.enable_auto_paylink === true &&
      !data?.auto_paylink_min_order_value &&
      !data?.auto_paylink_fulfilment_stage
    )
      return;

    toUpdateOrgSettings({
      enable_auto_paylink: data?.enable_auto_paylink as boolean,
      auto_paylink_min_order_value: data?.enable_auto_paylink
        ? getCurrencyValue(data?.auto_paylink_min_order_value as string)
        : undefined,
      auto_paylink_fulfilment_stage: data?.enable_auto_paylink
        ? auto_paylink_fulfilment_stage
        : undefined,
      convenience_fee: settings?.convenience_fee,
      has_tip: settings?.has_tip,
      timezone: settings?.timezone,
      auto_retry: settings?.auto_retry,
    });
  };

  const setFormValues = () => {
    if (settings) {
      setValue('enable_auto_paylink', settings?.enable_auto_paylink);
      setValue(
        'auto_paylink_min_order_value',
        CURRENCY.convertToMainUnit(settings?.auto_paylink_min_order_value)
      );
      setValue(
        'auto_paylink_fulfilment_stage',
        settings?.auto_paylink_fulfilment_stage
      );
    }
  };

  useEffect(() => {
    setFormValues();
  }, [settings]);

  useEffect(() => {
    if (enable_auto_paylink) {
      if (auto_paylink_min_order_value) setShowAlert(true);
      if (
        !settings?.enable_auto_paylink &&
        settings?.auto_paylink_min_order_value &&
        settings?.auto_paylink_fulfilment_stage
      ) {
        onFormSubmit({
          enable_auto_paylink,
          auto_paylink_fulfilment_stage,
          auto_paylink_min_order_value,
        });
      }
    } else if (settings?.enable_auto_paylink) {
      onFormSubmit({
        enable_auto_paylink,
        auto_paylink_fulfilment_stage,
        auto_paylink_min_order_value,
      });
    }
  }, [enable_auto_paylink]);

  return (
    <div className={styles.storeNotifications}>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <h2 className="mb-7">Global setting</h2>
        <FormGroup>
          <div className="d-flex justify-content-between mb-9">
            <div style={{ width: '720px' }}>
              <h3>Enable automatic payment links</h3>
              <div className={styles.storeNotificationsSubtext}>
                You have the flexibility to set various conditions for when SMS
                payment links should be sent to your customers automatically.
                You can enable or disable them at any point of time.
              </div>
            </div>
            <label
              className="custom-toggle paylinks-toggle"
              htmlFor="enable_auto_paylink"
            >
              <Input
                type="checkbox"
                name="enable_auto_paylink"
                id="enable_auto_paylink"
                data-testid="enable_auto_paylink"
                innerRef={register}
              />
              <span className="custom-toggle-slider paylinks-toggle-slider"></span>
            </label>
          </div>
        </FormGroup>
        {enable_auto_paylink && (
          <div>
            <h2 className="mb-1">SMS Triggers</h2>
            <div
              className={classNames(styles.storeNotificationsSubtext, 'mb-5')}
            >
              Customize the criteria that trigger the sending of payment links
              based on your preferences.
            </div>
            <FormGroup className="my-5">
              <h3 className="mb-1">Minimum order value</h3>
              {showAlert && !settings?.auto_paylink_min_order_value && (
                <Alert
                  color="dark"
                  className={classNames(
                    styles.storeNotifLimitBanner,
                    'mt-2 mb-10'
                  )}
                >
                  <div className="d-flex align-items-center">
                    <i className="ph-info-fill icon-xl mr-10"></i>
                    <div className="body-subtext">
                      The minimum order value is set to $50 as a default. You
                      can set it according to your preferences.
                    </div>
                  </div>
                  <i
                    onClick={() => setShowAlert(false)}
                    className="ph-x-bold icon-lg pointer"
                  ></i>
                </Alert>
              )}
              <div
                className={classNames(
                  styles.storeNotificationsSubtext,
                  'mb-10'
                )}
              >
                You can set a minimum order value after which your customer
                would receive a SMS payment link on their phone. This allows
                them to pre-pay for their order.
              </div>
              <FormLabel
                element="auto_paylink_min_order_value"
                label="Minimum order value"
                className="body-subtext-medium mb-1"
                style={{ color: '#333D4E' }}
              ></FormLabel>
              <Controller
                control={control}
                name="auto_paylink_min_order_value"
                id="auto_paylink_min_order_value"
                rules={{
                  required: true,
                }}
                render={(field) => (
                  <NumberFormat
                    name="auto_paylink_min_order_value"
                    id="auto_paylink_min_order_value"
                    data-testid="auto_paylink_min_order_value"
                    prefix="$"
                    fixedDecimalScale
                    decimalScale={2}
                    placeholder="$50"
                    className={classNames(
                      styles.storePaymentInput,
                      'd-block form-control'
                    )}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      field.onChange(e);
                    }}
                    value={field.value}
                    getInputRef={field.ref}
                  />
                )}
              />
              <div className="body-subtext mt-1" style={{ color: '#666E7A' }}>
                The minimum order value cannot be lower than $5.
              </div>
            </FormGroup>
            <FormGroup className="my-5">
              <h3 className="mb-1">Fulfilment stage</h3>
              <div
                className={classNames(styles.storeNotificationsSubtext, 'mb-4')}
              >
                You can select a stage in your Treez fulfilment dashboard for
                which an SMS Payment link would be triggered.
              </div>
              {Object.entries(FulFilmentStageMap).map(([k, v]) => (
                <Label
                  className={classNames('d-block ml-3 mb-2', styles.radioInput)}
                  check
                >
                  <Input
                    type="radio"
                    name="auto_paylink_fulfilment_stage"
                    id="auto_paylink_fulfilment_stage"
                    value={k}
                    innerRef={
                      register &&
                      register({
                        required: true,
                      })
                    }
                  />
                  <span style={{ color: '#333D4E' }} className="body-subtext">
                    {v}
                  </span>
                </Label>
              ))}
            </FormGroup>
            <Button
              size="md"
              color="primary"
              type="submit"
              className="btn btn-primary my-10"
              style={{ width: '174px' }}
              disabled={disableSaveBtn()}
            >
              Save
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};
export default Notifications;
