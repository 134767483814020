import React, { useState } from 'react';
import classNames from 'classnames';
import ErrorMessage from 'components/errorMessage';
import FormLabel from 'components/form-label';
import 'react-datetime/css/react-datetime.css';
import { useForm } from 'react-hook-form';
import { Button, Col, FormGroup, Input, Row } from 'reactstrap';
import CURRENCY from 'utils/currency';
import { NUMBER_VALIDATION, WHOLE_NUMBER_VALIDATION } from 'utils/validation';
import styles from '../addLinks.module.scss';

export interface Products {
  name: string; // skipcq JS-0122
  amount: number;
  quantity: number;
  tax: number;
}
type IProps = {
  onAdd: (products: Products) => void;
  errorControl: (val: boolean) => void;
  productError: boolean;
};

const AddProducts = ({
  onAdd,
  errorControl,
  productError,
}: IProps): JSX.Element => {
  const { errors, register, handleSubmit, clearErrors } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });
  const [name, setName] = useState('');
  const [price, setPrice] = useState<string>('');
  const [qty, setQty] = useState<string>('');
  const [tax, setTax] = useState<number>(0);

  const clearProductFields = () => {
    setName('');
    setPrice('');
    setQty('');
    setTax(0);
  };

  const addProducts = handleSubmit((products: Products) => {
    const req = {
      name: products.name,
      amount: CURRENCY.convertToSmallestUnit(parseFloat(price)),
      quantity: products.quantity,
      tax: products.tax,
    };
    onAdd(req);
    errorControl(false);
    clearErrors();
    clearProductFields();
  });

  return (
    <FormGroup className="mb-3">
      <FormLabel
        isRequired
        className={classNames('body-subtext-medium', styles.fieldLabel)}
        check
        element="name"
        label="Product"
      ></FormLabel>
      <Input
        name="name"
        placeholder="Product Name"
        innerRef={register({
          validate: {
            required: (value) => {
              return value.length <= 0 ? 'Please enter a product name' : true;
            },
          },
        })}
        className={classNames('w-50', errors.name ? styles.borderAlert : '')}
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />
      <div>
        {errors.name && (
          <ErrorMessage isValidation message="Please enter a product name" />
        )}
      </div>
      <Row className="mt-3">
        <Col xs="12" sm="4" className="pr-3">
          <FormLabel
            check
            isRequired
            element="amount"
            label="Price($)"
            className={classNames('body-subtext-medium', styles.fieldLabel)}
          ></FormLabel>
          <Input
            name="amount"
            type="number"
            placeholder="0"
            min={0}
            defaultValue={price}
            value={price}
            className={errors.amount ? styles.borderAlert : ''}
            innerRef={register({
              pattern: NUMBER_VALIDATION,
              validate: {
                required: (value) => {
                  return value <= 0 ? 'Please enter a number' : true;
                },
              },
            })}
            onChange={(e) => {
              setPrice(e.target.value);
            }}
          />
          <div>
            {errors.amount && (
              <ErrorMessage isValidation message="Please enter a price" />
            )}
          </div>
        </Col>
        <Col xs="12" sm="4" className="pl-3">
          <FormLabel
            isRequired
            className={classNames('body-subtext-medium', styles.fieldLabel)}
            element="quantity"
            label="Qty"
          ></FormLabel>
          <Input
            name="quantity"
            placeholder="0"
            min={1}
            value={qty}
            type="number"
            defaultValue={qty}
            className={errors.quantity ? styles.borderAlert : ''}
            innerRef={register({
              pattern: WHOLE_NUMBER_VALIDATION,
              valueAsNumber: true,
              validate: {
                required: (value) => {
                  return value <= 0 ? 'Please enter a valid quantity' : true;
                },
              },
            })}
            onChange={(e) => setQty(e.target.value)}
          />
          <div>
            {errors.quantity && (
              <ErrorMessage
                isValidation
                message="Please enter a valid quantity"
              />
            )}
          </div>
        </Col>
        <Col xs="12" sm="4" className="pl-3">
          <FormLabel
            className={classNames('body-subtext-medium', styles.fieldLabel)}
            check
            element="tax"
            label="Tax(%)"
          ></FormLabel>
          <Input
            name="tax"
            type="number"
            min={0}
            defaultValue={tax}
            value={tax}
            placeholder="0"
            innerRef={register({
              pattern: NUMBER_VALIDATION,
              valueAsNumber: true,
            })}
            onChange={(e) => setTax(Number(e.target.value))}
            className={errors.tax ? styles.borderAlert : ''}
          />
          <div>
            {errors.tax && (
              <ErrorMessage isValidation message="Please enter a number" />
            )}
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <span className="mr-auto">
          <Col xs="12" sm="4" className="pl-3">
            <Button
              variant="secondary"
              className={classNames(
                styles.modalAddBtn,
                productError && styles.highlightButton
              )}
              onClick={() => addProducts()}
            >
              <i className="ph-plus-fill mr-1" />
              Save product
            </Button>
          </Col>
          <div className="ml-3">
            {productError && (
              <ErrorMessage
                isValidation
                message="Please save the product to create a payment link."
                preIcon={
                  <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
                }
              />
            )}
          </div>
        </span>
      </Row>
    </FormGroup>
  );
};

export default AddProducts;
