import { STATS } from './type';

export const FETCH_SUMMARY_CARDS = 'analytics/FETCH_SUMMARY_CARDS';
export const FETCH_SUMMARY_CARDS_SUCCESS =
  'analytics/FETCH_SUMMARY_CARDS_SUCCESS';
export const FETCH_SUMMARY_CARDS_FAILURE =
  'analytics/FETCH_SUMMARY_CARDS_FAILURE';

export const FETCH_GRAPH_DATA = 'analytics/FETCH_GRAPH_DATA';
export const FETCH_GRAPH_DATA_SUCCESS = 'analytics/FETCH_GRAPH_DATA_SUCCESS';
export const FETCH_GRAPH_DATA_FAILURE = 'analytics/FETCH_GRAPH_DATA_FAILURE';

export const FETCH_CUSTOMER_INFO = 'analytics/FETCH_CUSTOMER_INFO';
export const FETCH_CUSTOMER_INFO_SUCCESS =
  'analytics/FETCH_CUSTOMER_INFO_SUCCESS';
export const FETCH_CUSTOMER_INFO_FAILURE =
  'analytics/FETCH_CUSTOMER_INFO_FAILURE';

export const SET_SUMMARY_TAB = 'analytics/SET_SUMMARY_TAB';

export const SET_INTERVAL_TYPES = 'analytics/SET_INTERVAL_TYPES';

export const action = (type: string): IAction => ({
  type,
});

export const actionSuccess = <T>(type: string, payload: T): IAction => ({
  type: `${type}_SUCCESS`,
  payload,
});

export const actionFailure = <T>(type: string, payload: T): IAction => ({
  type: `${type}_FAILURE`,
  payload,
});

export const setSummaryTab = (payload: STATS.graphType): IAction => ({
  type: SET_SUMMARY_TAB,
  payload,
});

export const setIntervalTypes = (payload: STATS.IIntervalType[]): IAction => ({
  type: SET_INTERVAL_TYPES,
  payload,
});
