import DownChevronIcon from 'components/icons/downchevron';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from 'reactstrap';
import { withAdminRole, withManagerRole } from 'components/rbac/helper';
import useRefunds from 'state/refunds/hooks/useRefunds';
import ToastContainer, { ToastType } from 'components/toasts/container';
import usePayment from 'state/payment/hooks/usePayment';
import {
  STATUS_AUTHORIZED,
  STATUS_RETURNED,
  STATUS_SUCCESS,
  STATUS_WRITE_OFF,
} from 'utils/constants';
import useBanking from 'state/banking/hooks/useBanking';
import { toIsoStringWithTz } from 'utils/date-format';
import ConfirmRefund from 'components/modals/confirmRefund';
import styles from '../../transactions.module.scss';

const boxshadowStyle = {
  boxShadow: `0px 1px 5px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.05)`,
};
interface IProps {
  chargeId: string;
  orderId: string;
  transaction_status: string;
  amount: number;
  payment_type: string;
  is_retryable: boolean;
}

enum TOAST_TYPE {
  CASH = 'CASH',
  RETRY = 'SINGLE_RETRY',
  CANCEL = 'CANCEL',
  REFUND = 'REFUND',
  FAILED_RETRY = 'FAILED_RETRY',
  FAILED_CASH = 'FAILED_CASH',
  FAILED_CANCEL = 'FAILED_CANCEL',
  FAILED_REFUND = 'FAILED_REFUND',
}

const RefundActionsComponent = (props: IProps): JSX.Element => {
  const {
    chargeId,
    orderId,
    transaction_status,
    amount,
    payment_type,
    is_retryable,
  } = props;
  const { retryTransaction, refunds, resetRefundState, resetRetryState } =
    useRefunds();
  const fromDate = toIsoStringWithTz(
    new Date(
      new Date(new Date().setDate(new Date().getDate() - 30)).setHours(
        0,
        0,
        0,
        0
      )
    )
  );
  const todayDate = toIsoStringWithTz(
    new Date(new Date().setDate(new Date().getDate()))
  );
  const { banking, toFetchBankingStats } = useBanking();
  const { refundCreationSuccess, retrySuccess } = refunds;

  const [toastType, setToastType] = useState<TOAST_TYPE | null>(null);
  const [warningType, setWarningType] = useState<ToastType | undefined>(
    ToastType.success
  );
  const successRetryMessage = 'Retry initiated successfully';
  const successCashRecordedMessage = 'Cash payment recorded successfully';
  const successCancelMessage = 'Transaction cancelled';
  const successRefundMessage = 'Refund initiated successfully';

  const getSubDetails = () => {
    if (
      toastType === TOAST_TYPE.FAILED_CANCEL ||
      toastType === TOAST_TYPE.FAILED_CASH ||
      toastType === TOAST_TYPE.FAILED_RETRY
    ) {
      return (
        <p>
          We are unable to perform <br /> intended action. Please try again.
        </p>
      );
    }
    return <></>;
  };

  const getToastMessage = () => {
    if (toastType === TOAST_TYPE.CASH) return successCashRecordedMessage;
    if (toastType === TOAST_TYPE.RETRY) return successRetryMessage;
    if (toastType === TOAST_TYPE.CANCEL) return successCancelMessage;
    if (toastType === TOAST_TYPE.REFUND) return successRefundMessage;
    if (
      toastType === TOAST_TYPE.FAILED_CANCEL ||
      toastType === TOAST_TYPE.FAILED_CASH ||
      toastType === TOAST_TYPE.FAILED_RETRY
    )
      return 'An unexpected error occured';
    return '';
  };

  const {
    toFetchIndividualTransaction,
    toCancelTransaction,
    toResetCancelTransaction,
    payment,
  } = usePayment();

  const { toRecordCash } = useBanking();

  const [showRefund, setShowRefund] = useState(false);

  const { cancelTransaction } = payment;
  const refundCall = () => {
    setShowRefund(true);
  };

  const cashRecordedCall = async () => {
    const response = await toRecordCash(chargeId);
    if (response) {
      setToastType(TOAST_TYPE.CASH);
    } else {
      setToastType(TOAST_TYPE.FAILED_CASH);
      setWarningType(ToastType.error);
    }
  };

  const cancelTransactionCall = async () => {
    const response = await toCancelTransaction(orderId);
    if (response) {
      setToastType(TOAST_TYPE.CANCEL);
    } else {
      setToastType(TOAST_TYPE.FAILED_CANCEL);
      setWarningType(ToastType.error);
    }
  };
  const [disableRefund, setDisableRefund] = useState(false);

  const refundBalanceCheck = () => {
    if (banking.stats) {
      return (
        banking.stats?.holding_balance < banking.stats?.total_balance &&
        banking.stats?.total_balance > amount
      );
    }
    return false;
  };

  useEffect(() => {
    if (cancelTransaction) {
      setToastType(TOAST_TYPE.CANCEL);
      toResetCancelTransaction();
      const timeoutCancel = setTimeout(
        () => toFetchIndividualTransaction(orderId),
        3000
      );
      clearTimeout(timeoutCancel);
    }
  }, [cancelTransaction]);

  useEffect(() => {
    toFetchBankingStats({ start_date: fromDate, end_date: todayDate });
  }, []);

  useEffect(() => {
    if (!refundBalanceCheck() && banking.stats) {
      setDisableRefund(true);
    } else {
      setDisableRefund(false);
    }
  }, [banking.stats]);

  useEffect(() => {
    if (refundCreationSuccess) {
      setToastType(TOAST_TYPE.REFUND);
      const timeoutRefund = setTimeout(() => {
        toFetchIndividualTransaction(orderId);
        clearTimeout(timeoutRefund);
      }, 3000);
      resetRefundState();
    }
  }, [refundCreationSuccess]);

  const retryCall = async () => {
    const response = await retryTransaction(chargeId);
    if (!response) {
      setToastType(TOAST_TYPE.FAILED_RETRY);
      setWarningType(ToastType.error);
    }
  };
  useEffect(() => {
    if (retrySuccess) {
      setToastType(TOAST_TYPE.RETRY);
      resetRetryState();
      const timeoutRetry = setTimeout(
        () => toFetchIndividualTransaction(orderId),
        3000
      );
      clearTimeout(timeoutRetry);
    }
  }, [retrySuccess]);
  const tooltipNote =
    'Refunds can only be initiated when the available balance is more than the refund amount';

  const refundComponent = () => (
    <UncontrolledDropdown className="mb-2">
      <DropdownToggle
        data-testid="refund_action_toggle"
        style={boxshadowStyle}
        className={classNames(styles.dropdownToggle, 'mr-0')}
      >
        <span className={classNames(styles.filterText)}>Actions</span>
        <DownChevronIcon />
      </DropdownToggle>
      <DropdownMenu right className={classNames(styles.dropdown)}>
        <DropdownItem
          data-testid="action_retry"
          onClick={retryCall}
          className={classNames(
            (transaction_status !== STATUS_RETURNED &&
              transaction_status !== STATUS_WRITE_OFF) ||
              payment_type === 'CASH' ||
              !is_retryable
              ? styles.disabled
              : styles.dropdownButton
          )}
          disabled={
            (transaction_status !== STATUS_RETURNED &&
              transaction_status !== STATUS_WRITE_OFF) ||
            payment_type === 'CASH' ||
            !is_retryable
          }
        >
          <div className="d-flex">
            <i
              className={
                (transaction_status !== STATUS_RETURNED &&
                  transaction_status !== STATUS_WRITE_OFF) ||
                payment_type === 'CASH' ||
                !is_retryable
                  ? classNames(
                      'ph-bold ph-arrows-clockwise align-items-center text-muted mr-1 ',
                      styles.disabledIcon
                    )
                  : classNames(
                      'ph-bold ph-arrows-clockwise align-items-center text-muted mr-1 '
                    )
              }
            />
            Retry
          </div>
        </DropdownItem>
        <DropdownItem
          data-testid="action_cash_record"
          onClick={cashRecordedCall}
          className={classNames(
            (transaction_status !== STATUS_RETURNED &&
              transaction_status !== STATUS_WRITE_OFF) ||
              payment_type === 'CASH'
              ? styles.disabled
              : styles.dropdownButton
          )}
        >
          <div className="d-flex">
            <i
              className={
                (transaction_status !== STATUS_RETURNED &&
                  transaction_status !== STATUS_WRITE_OFF) ||
                payment_type === 'CASH'
                  ? classNames(
                      'ph-bold ph-currency-circle-dollar text-muted mr-1',
                      styles.disabledIcon
                    )
                  : classNames(
                      'ph-bold ph-currency-circle-dollar text-muted mr-1'
                    )
              }
            />
            Record cash payment
          </div>
        </DropdownItem>
        <DropdownItem
          id="refundNote"
          onClick={refundCall}
          className={classNames(
            transaction_status !== STATUS_SUCCESS ||
              disableRefund ||
              payment_type === 'CASH'
              ? styles.disabled
              : styles.dropdownButton
          )}
          disabled={
            transaction_status !== STATUS_SUCCESS ||
            disableRefund ||
            payment_type === 'CASH'
          }
        >
          <div className="d-flex">
            <i
              className={
                transaction_status !== STATUS_SUCCESS ||
                disableRefund ||
                payment_type === 'CASH'
                  ? classNames(
                      'ph-bold ph-arrow-counter-clockwise text-muted mr-1',
                      styles.disabledIcon
                    )
                  : classNames(
                      'ph-bold ph-arrow-counter-clockwise text-muted mr-1'
                    )
              }
            />
            Refund
          </div>
          {(transaction_status !== STATUS_SUCCESS || disableRefund) && (
            <UncontrolledTooltip
              placement="right"
              target={'refundNote'}
              style={{ width: '240px' }}
            >
              {tooltipNote}
            </UncontrolledTooltip>
          )}
        </DropdownItem>
        <DropdownItem
          onClick={cancelTransactionCall}
          className={classNames(
            transaction_status !== STATUS_AUTHORIZED || payment_type === 'CASH'
              ? styles.disabled
              : styles.dropdownButton
          )}
          disabled={
            transaction_status !== STATUS_AUTHORIZED || payment_type === 'CASH'
          }
        >
          <div className="d-flex">
            <i
              className={
                transaction_status !== STATUS_AUTHORIZED ||
                payment_type === 'CASH'
                  ? classNames(
                      'ph-bold ph-x align-items-center text-muted mr-1',
                      styles.disabledIcon
                    )
                  : classNames(
                      'ph-bold ph-x align-items-center text-muted mr-1'
                    )
              }
            ></i>
            Cancel
          </div>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const refundDropdown =
    withAdminRole(refundComponent) || withManagerRole(refundComponent);
  return (
    <>
      {refundDropdown}
      <ToastContainer
        type={warningType}
        showToast={Boolean(toastType)}
        onClose={() => setToastType(null)}
        message={getToastMessage()}
        id={toastType?.toLowerCase() || ''}
        details={getSubDetails()}
      />
      <ConfirmRefund
        modal={showRefund}
        setModal={setShowRefund}
        chargeId={chargeId}
      />
    </>
  );
};
export default RefundActionsComponent;
