import React from 'react';
import { formatToTz } from 'utils/date-format';
import classNames from 'classnames';
import { PAYMENTS } from 'state/payment/type';
import BackButton from 'components/backButton';
import AmountDetails from 'components/amount-details';
import CopyIDToClipboard from 'components/copyToClipboard';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import { gatewatTransactionStatusFormatter } from '../../../../utils/status-formatter';
import STRING from 'utils/string';

interface IProps {
  info: PAYMENTS.IGatewayTransaction;
}
const GatewaySummary = ({
  info = {} as PAYMENTS.IGatewayTransaction,
}: IProps): JSX.Element => {
  const { organisation } = useOrganisation();
  const { settings } = organisation;
  const grossAmount = info.original_amount + info.fee_amount + info.tip_amount;
  const treezTicketID = STRING.getTreezTicketIDFromReferenceNumber(
    info.reference_number
  );

  return (
    <>
      <BackButton />
      <AmountDetails
        amount={grossAmount}
        unit="USD"
        statusBadge={gatewatTransactionStatusFormatter(info.status)}
      />
      <div className="d-flex align-items-center">
        <span className="mr-auto">
          <CopyIDToClipboard id={info.gateway_id || 'NA'} />
        </span>
      </div>
      <hr className="my-0" />

      <div className="d-flex align-items-center mb-9 pt-2 mt-1">
        <p className={classNames('SettlementSummaryPayment', 'pr-5')}>
          <div className={classNames('SettlementSummaryHeading')}>Date</div>
          <div className={classNames('SettlementHeadingData')}>
            {info.create_date
              ? formatToTz(
                  info.create_date,
                  settings?.timezone,
                  'MMM dd, hh:mm a'
                )
              : 'NA'}
          </div>
        </p>
        <p className={classNames('SettlementSummaryPayment', 'pl-5', 'pr-5')}>
          <div className={classNames('SettlementSummaryHeading', 'pr-5')}>
            Ticket ID
          </div>
          <div className={classNames('SettlementHeadingData')}>
            {treezTicketID}
          </div>
        </p>
        <p className={classNames('SettlementSummaryPayment', 'pl-5', 'pr-5')}>
          <div className={classNames('SettlementSummaryHeading')}>
            Payment method
          </div>
          <div className={classNames('SettlementHeadingData')}>
            {info.method}
          </div>
        </p>
      </div>
    </>
  );
};

export default GatewaySummary;
