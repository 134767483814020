import classNames from 'classnames';
import React from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import styles from './filters.module.scss';
import { IFilterConfig, IFilterConfigNumber } from './types';

interface IProps {
  item: IFilterConfig;
  setSelected: (arg1: string, arg2: boolean) => void;
  setValue: (arg1: string, arg2: number, arg3: string) => void;
}

const NumberFilter = ({ item, setSelected, setValue }: IProps): JSX.Element => {
  const { selected, key, label, data } = item;
  const { values } = data as IFilterConfigNumber;
  const condition = values[0];
  const firstNumber = values[1];
  const secondNumber = values[2];
  return (
    <>
      <div className={classNames(styles.filterMenu_item)}>
        <Input
          data-testid="number-checkbox"
          type="checkbox"
          data-cy="amountFilters"
          className={classNames(styles.checkbox)}
          checked={selected}
          onChange={(e) => {
            setSelected(key, e.currentTarget.checked);
          }}
          id={label}
          name={label}
        />
        <label htmlFor={label} className={classNames('body-subtext-regular')}>
          {label}
        </label>
      </div>

      {selected && (
        <div className={classNames(styles.filterMenu_dropdown)}>
          <UncontrolledButtonDropdown
            className={classNames(styles.numberDropdown)}
          >
            <DropdownToggle
              caret
              className={classNames(
                styles.dropdownToggle,
                'd-inline-flex align-items-center justify-content-between'
              )}
            >
              {condition || 'equal'}
            </DropdownToggle>
            <DropdownMenu
              className={classNames(styles.dropdownMenu, 'dropdown-full')}
            >
              <DropdownItem
                data-testid="equal"
                className={classNames(styles.dropdown_item)}
                onClick={() => {
                  setValue(key, 0, 'equal');
                  setValue(key, 2, '');
                }}
              >
                equal
              </DropdownItem>
              <DropdownItem
                data-testid="between"
                className={classNames(styles.dropdown_item)}
                onClick={() => {
                  setValue(key, 0, 'between');
                }}
              >
                between
              </DropdownItem>
              <DropdownItem
                data-testid="greater-than"
                className={classNames(styles.dropdown_item)}
                onClick={() => {
                  setValue(key, 0, 'greater than');
                  setValue(key, 2, '');
                }}
              >
                greater than
              </DropdownItem>
              <DropdownItem
                data-testid="less-than"
                className={classNames(styles.dropdown_item)}
                onClick={() => {
                  setValue(key, 0, 'less than');
                  setValue(key, 2, '');
                }}
              >
                less than
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>

          <div className={classNames(styles.inputContainer)}>
            <Input
              data-testid="first"
              placeholder="0"
              type="number"
              className={classNames(styles.input)}
              defaultValue={firstNumber}
              onChange={(e) => {
                setValue(key, 0, values[0]);
                setValue(key, 1, e.target.value);
              }}
            />
            {condition === 'between' && (
              <>
                <span className="text-muted mx-3">and</span>
                <Input
                  data-testid="second"
                  className={classNames(styles.input)}
                  type="number"
                  defaultValue={secondNumber}
                  onChange={(e) => {
                    setValue(key, 0, values[0]);
                    setValue(key, 2, e.target.value);
                  }}
                />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default NumberFilter;
