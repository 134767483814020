import React from 'react';
import { formatToTz } from 'utils/date-format';
import classNames from 'classnames';
import BackButton from 'components/backButton';
import AmountDetails from 'components/amount-details';
import CopyIDToClipboard from 'components/copyToClipboard';
import { payoutStatusFormatter } from 'utils/status-formatter';
import { BANKING } from 'state/banking/type';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import { payoutTypeFormatter } from 'utils/source-formatter';

interface IProps {
  info: BANKING.IPayout;
}

const Summary = ({ info = {} as BANKING.IPayout }: IProps): JSX.Element => {
  const { organisation } = useOrganisation();
  const { settings } = organisation;

  return (
    <div className="pb-2">
      <BackButton />
      <AmountDetails
        amount={info?.amount}
        unit="USD"
        statusBadge={payoutStatusFormatter(info?.payout_status)}
        props={{ 'data-testid': 'payout-amount-header' }}
      />
      <div className="d-flex align-items-center">
        <span className="mr-auto">
          <CopyIDToClipboard id={info?.id || 'NA'} className="pb-1" />
        </span>
      </div>
      <hr className="my-0" />
      <div className="d-flex align-items-center mb-9 mt-10">
        <p className={classNames('SettlementSummaryPayment', 'pr-5')}>
          <div className={classNames('SettlementSummaryHeading')}>Date</div>
          <div className={classNames('SettlementHeadingData')}>
            {info?.create_date
              ? formatToTz(
                  info?.create_date,
                  settings?.timezone,
                  'MMM dd, hh:mm a'
                )
              : 'NA'}
          </div>
        </p>
        <p className={classNames('SettlementSummaryPayment', 'pl-5', 'pr-5')}>
          <div className={classNames('SettlementSummaryHeading')}>
            Payment method
          </div>
          <div
            className={classNames('SettlementHeadingData')}
            data-testid="payout_payment_method"
          >
            {info?.payment_method || 'NA'}
          </div>
        </p>
        <p className={classNames('SettlementSummaryPayment', 'pl-5', 'pr-5')}>
          <div className={classNames('SettlementSummaryHeading')}>Type</div>
          <div className={classNames('SettlementHeadingData')}>
            {payoutTypeFormatter(info.type)}
          </div>
        </p>
      </div>
    </div>
  );
};

export default Summary;
