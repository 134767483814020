import { ORGANISATION } from 'state/organisation/type';

export const signUpURL = (ENV: string, activeOrg: ORGANISATION.IOrg | null) => {
  const subDomain = activeOrg?.brand_settings.sub_domain;
  const checkout_domain = activeOrg?.settings.checkout_domain;
  const checkout_domain_status = activeOrg?.settings.checkout_domain_status;
  const envCheckoutDomainMap: any = {
    development: '.dev.swiftercheckout.com',
    preprod: '.preprod.swiftercheckout.com',
    production: '.swiftercheckout.com',
  };
  const domain = envCheckoutDomainMap[ENV] ?? '.swiftercheckout.com';
  const path = '/signup';

  if (checkout_domain && checkout_domain_status == 'connected') {
    return 'https://' + checkout_domain + path;
  } else {
    return 'https://' + subDomain + domain + path;
  }
};

export const onboardURL = (
  env: string,
  org_id: string,
  email_id: string,
  org_name: string
) => {
  if (env === 'production') {
    return `https://app.treezpay.com/auth/register/?org_id=${org_id}&email=${email_id}&org_name=${org_name}`;
  }
  if (env === 'development') {
    return `https://app.sandbox.treezpay.com/auth/register/?org_id=${org_id}&email=${email_id}&org_name=${org_name}`;
  }
  if (env === 'preprod') {
    return `https://app.dev.treezpay.com/auth/register/?org_id=${org_id}&email=${email_id}&org_name=${org_name}`;
  }
  return `https://app.${env}.swifterhq.com/auth/register/?org_id=${org_id}&email=${email_id}&org_name=${org_name}`;
};

export const ssoLoginCallback = (env: string) => {
  if (env === 'production') {
    return `https://app.treezpay.com/`;
  }
  if (env === 'development') {
    return `https://app.sandbox.treezpay.com/`;
  }

  if (env === 'preprod') {
    return `https://app.dev.treezpay.com/`;
  }

  if (env === 'local') {
    return `http://localhost:3000/`;
  }
  return `https://app.${env}.swifterhq.com/`;
};

export const treezLoginPageUrl = (env: string) => {
  if (env === 'development' || env === 'local') {
    return 'https://login.sandbox.treez.io';
  }
  if (env === 'preprod') {
    return 'https://login.dev.treez.io';
  }
  return 'https://login.treez.io';
};

export const treezAuthAPIBaseUrl = (env: string) => {
  if (env === 'development' || env === 'local') {
    return 'https://api.sandbox.treez.io/auth-v2';
  }
  if (env === 'preprod') {
    return 'https://api.dev.treez.io/auth-v2';
  }
  return 'https://api.mso.treez.io/auth-v2';
};

export const oneAppUrl = (env: string) => {
  if (env === 'development' || env === 'local') {
    return 'https://onboarding.sandbox.treezpay.com';
  }
  if (env === 'preprod') {
    return 'https://onboarding.dev.treezpay.com';
  }
  return 'https://onboarding.treezpay.com';
};


export const treezDashboardUrl = (env: string) => {
  if (env === 'production') {
    return `https://app.treez.io/dashboard`;
  }
  if (env === 'development') {
    return `https://app.sandbox.treez.io/dashboard`;
  }

  if (env === 'preprod') {
    return `https://app.dev.treez.io/dashboard`;
  }

  if (env === 'local') {
    return `https://app.sandbox.treez.io/dashboard`;
  }
  return `https://app.${env}.swifterhq.com/`;
};