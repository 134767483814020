import { SUMMARY } from 'state/summary/type';

// skipcq: JS-0337
export namespace PAYMENTS {
  export interface Error {
    fetchUsers?: {
      message: string;
    };
    addUser?: {
      message: string;
    };
    fetchKeys?: {
      message: string;
    };
    fetchEvents?: {
      message: string;
    };
    individualEvents?: {
      message: string;
    };
    addKeySelf?: {
      message: string;
      error_code?: string;
    };
    addKeyPartner?: {
      message: string;
      error_code?: string;
    };
    addNotes?: {
      message: string;
    };
    addTerminal?: {
      message: string;
    };
    addTransactions?: {
      message: string;
    };
    fetchTransactions?: {
      message: string;
    };
    fetchSessions?: {
      message: string;
    };
    addIndividualTransaction?: {
      message: string;
    };
    addStores?: {
      message: string;
    };
    addUrl?: {
      message: string;
    };
    fetchAnalytics?: {
      message: string;
    };
    charges?: {
      message: string;
    };
    fetchOrgs?: { message: string };
    addIndividualSettlement?: { message: string };
    addIndividualUpload?: { message: string };
    fetchSettlements?: {
      message: string;
    };
    addIndividualCustomer?: {
      message: string;
    };
    fetchIndividualCsv?: {
      message: string;
    };
    fetchInstoreSessions?: {
      message: string;
    };
    updateSessionTicket?: {
      message: string;
    };
    fetchSessionEvents?: {
      message: string;
    };
    deleteKey?: {
      message: string;
    };
    addSession?: { message: string };
    fetchSummaryData?: { message: string };
    cancelTransaction?: { message: string };
    fetchGatewayTransaction?: { message: string };
    tip?: { message: string };
  }

  interface IRole {
    id: string;
    create_date: string;
    role_name: string;
    organization_id: string;
    internal_user_id: string;
  }
  export interface IOrgUser {
    id: string;
    create_date: string;
    name: string; // skipcq JS-0122
    email: string;
    roles: IRole[];
  }
  export interface IApiKey {
    creator_name: string;
    id: string;
    create_date: string;
    status: string; // skipcq JS-0122
    client_id: string;
    organization_id: string;
  }

  export interface IApiKeyResponse {
    client_id: string;
    client_secret: string;
  }
  export interface IInternalUserRequest {
    name: string; // skipcq JS-0122
    email: string;
    id_token: string;
    access_token: string;
  }
  export interface IInternalUser {
    id: string;
    name: string; // skipcq JS-0122
    email: string;
    create_date: string;
  }
  export interface IOrg {
    id: string;
    name: string; // skipcq JS-0122
    create_date: string;
  }
  export interface IAchUploadItems {
    id: string;
    organization_id: string;
    store_id: string;
    settlement_window_id: string;
    store_name: string;
    transaction_count: string;
    amount: number;
    order_gross_amount: string;
    tip_amount: string;
    fee_amount: string;
    initiated_date: string;
    settlement_date: string;
    upload_date: string;
  }

  export interface ISessionContext {
    [arg: string]: string;
  }
  export interface IIndividualPayload {
    code: string;
    message: string;
    description: string;
    status?: string;
    request_id?: string;
  }
  export interface IIndividualSessionPayload {
    context: ISessionContext;
    level: string;
    payload: IIndividualPayload;
    timestamp: string;
    type: string;
    version: string;
    name?: string;
    amount?: number;
  }

  export interface IIndividualSession {
    id: string;
    organization_id: string;
    entity_type: string;
    entity_id: string;
    name: string; // skipcq JS-0122
    create_date: string;
    payload?: IIndividualSessionPayload;
  }

  export type IIndividualTransactionResult = {
    ach_upload_date: string;
    id: string;
    organization_id: string;
    store_id: string;
    store_name: string;
    consumer_id: string;
    customer_first_name: string;
    customer_last_name: string;
    customer_phone: string;
    customer_email: string;
    customer_create_date: string;
    consumer_bank_name: string;
    consumer_account: string;
    consumer_profile_id: string;
    expected_settlement_date: string;
    order_id: string;
    order_create_date: string;
    order_amount: number;
    track_id: string;
    order_status: string;
    tip_amount: number;
    order_fee_amount: number;
    transaction_fee_amount: number;
    charge_id: string;
    charge_amount: number;
    charge_status: string;
    charge_create_date: string;
    payment_type: string;
    external_short_identifier: string;
    create_date: Date;
    calculated_total: number;
    transaction_status: string;
    update_date?: string;
    ach_debit_id_count: number;
    ach_debit_error_count: number;
    return_code: string;
    return_date: string;
    return_reason: string;
    source: ITransactionSource;
    transaction_canceled_date: string;
    timeline_notes: string;
    updated_by_username: string;
    notes_added_date: string;
    refund_create_date: string;
    refunded_by: string;
    refund_id: string;
    refund_status: string;
    cash_collected_by?: string;
    external_id?: string;
    is_retryable: boolean;
  };
  export interface IIndividualTransaction {
    transaction: IIndividualTransactionResult;
    events: IIndividualSession[];
  }

  export type IFetchRequest = {
    orgId: string;
    startDate?: string;
    endDate?: string;
    store_id?: string;
    key?: string;
    offset?: number;
  };

  export interface IChargeItems {
    consent_text: string;
    consumer_profile_id: string;
    create_date: string;
    email: string;
    fees: number;
    first_name: string;
    id: string;
    is_sufficient_funds: boolean;
    is_valid_bank_account_selected: boolean;
    is_valid_consumer: boolean;
    last_name: string;
    order_id: string;
    organization_id: string;
    phone: string;
    status: string; // skipcq JS-0122
    total_amount: number;
    track_number: string;
    type: string;
  }
  export type IChargeParams = {
    next_key?: string;
    prev_key?: string;
    consumer_profile_id?: string;
  };
  export type ISessionParams = {
    organization_id: string;
    amount: number;
    type: string;
    consumer_info: {
      first_name: string;
      last_name: string;
      email: string;
      phone?: string;
      drivers_license: string;
    };
  };
  export interface ICharge {
    prev_key: string;
    items: Array<IChargeItems>;
    next_key: string;
  }

  export type IFetchAPIKeys = {
    key?: string | undefined;
  };
  export type IFetchAnalyticsParams = {
    start_date: string;
    end_date: string;
    status: string; // skipcq JS-0122
  };
  export type IFetchTransactions = {
    key?: string | null;
    start_date?: string | null;
    end_date?: string | null;
    consumer_profile_id?: string;
    store?: string;
    status?: string; // skipcq JS-0122
    source?: string;
    condition?: string;
    amount_1?: string;
    amount_2?: string;
    search_string?: string;
    payment_type?: string;
  };
  export type IFetchSessions = {
    key?: string | null;
    start_date?: string | null;
    end_date?: string | null;
    consumer_id?: string;
    search_string?: string;
    session_status?: string;
    is_valid_consumer?: boolean;
    source?: string;
  };

  export type IFetchInStoreTerminals = {
    start_date?: string;
    end_date?: string;
    key?: string | null;
    offset?: number;
    limit?: number;
  };

  export type IFetchInStoreCashDrawers = {
    start_date?: string;
    end_date?: string;
  };

  export type IFetchInstoreSessions = {
    start_date?: string;
    end_date?: string;
    status?: string; // skipcq JS-0122
    key?: string;
    offset?: number;
    limit?: number;
  };

  export type IFetchCSVTransactions = {
    start_date?: string;
    end_date?: string;
    status?: string; // skipcq JS-0122
    store_name?: string;
    condition?: string;
    amount?: string;
    timezone?: string;
  };

  export type IFetchStoresParams = {
    org_id: string;
    start_date?: string;
    end_date?: string;
  };

  export enum ITransactionSource {
    WEB = 'web',
    PAYMENTLINK = 'payment_link',
    INSTORE = 'in_store',
  }

  export enum ITransactionStatus {
    PENDING = 'pending',
    AUTHORIZED = 'authorized',
    'ACH INITIATED' = 'ach initiated',
    CANCELED = 'canceled',
    SUCCESSFUL = 'successful',
    FAILED = 'failed',
  }

  export interface ITransactionResult {
    id: string;
    ach_debit_error_count: string;
    ach_debit_id_count: string;
    ach_upload_date: string;
    consumer_id: string;
    bank_name: string;
    account: string;
    card_number: string;
    order_id: string;
    consumer_email: string;
    customer_first_name?: string;
    customer_last_name?: string;
    external_short_identifier: string;
    phone_number: string;
    consumer_create_date: string;
    order_status: string;
    order_amount: number;
    order_create_date: string;
    order_delivered_date: string;
    charge_id: string;
    charge_amount: number;
    transaction_status: string;
    charge_create_date: string;
    payment_type: string;
    store_email: string;
    store_name: string;
    store_id: string;
    org_name: string;
    org_id: string;
    tenant_id: string;
    payment_provider: string;
    track_id: string;
    order_fee_id: string;
    order_fee_amount: number;
    order_fee_type: string;
    order_tip_id: string;
    source: ITransactionSource;
    tip_amount: number;
    balance_transaction_id: string;
    transaction_fee_id: string;
    platform_fee: number;
    transaction_fee_type: string;
    calculated_total: number;
    return_date: string;
    consumer_profile_id: string | null;
    external_id: string | null;
    is_retryable: string;
    create_date: string;
    update_date: string;
    reference_number?: string;
    method: string;
    processor_name: string;
    gateway_status: string;
    gateway_id?: string;
  }
  export interface IInStoreTerminalResultStats {
    fee_amount: number;
    gross_amount: number;
    order_amount: number;
    tip_amount: number;
    fee: number;
    terminal_id: string;
  }
  export interface IInStoreTerminalResult {
    id: string;
    name: string; // skipcq JS-0122
    terminal_url: string;
    create_date: string;
    organization_id: string;
    qr_link: string;
    stats: IInStoreTerminalResultStats;
    status: string; // skipcq JS-0122
  }

  export interface IInStoreTerminals {
    items: IInStoreTerminalResult[];
    next_key: string;
    prev_key: string;
    total_count: number;
  }
  export interface IInstoreCashDrawer {
    cash_drawer_name: string;
    gross_amount: number;
    order_amount: number;
    tip_amount: number;
    fee_amount: number;
  }

  export interface IInstoreCashDrawers {
    cash_drawer_name: string;
    gross_amount: number;
    order_amount: number;
    tip_amount: number;
    fee_amount: number;
    breakdown: IInstoreCashDrawer[];
  }

  export interface IInStoreCashDrawerResult {
    items: IInstoreCashDrawers[];
  }

  export interface IInStoreSessions {
    id: string;
    create_date: string;
    status: string; // skipcq JS-0122
    organization_id: string;
    type: string;
    total_amount: number;
    is_valid_consumer: boolean;
    is_valid_bank_account_selected: boolean;
    is_sufficient_funds: boolean;
    consent_text: string;
    fees: number;
    track_number: string;
    in_store_session_id: string;
    order_id: string;
    consumer_id: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
  }

  export interface IPaginatedResponse<T> {
    items: T[];
    next_key: string;
    prev_key: string;
    total_count: number;
  }

  export interface ITransactions {
    items: ITransactionResult[];
    next_key: string;
    prev_key: string;
    total_count: number;
  }

  export interface ISessionResult {
    id: string;
    create_date: string;
    external_ticket_id: string;
    status: string; // skipcq JS-0122
    organization_id: string;
    type: string;
    total_amount: number;
    is_valid_consumer: boolean;
    is_valid_bank_account_selected: boolean;
    is_sufficient_funds: boolean;
    consent_text: string;
    fees: number;
    track_number: string;
    order_id: string;
    consumer_profile_id: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
  }

  export interface ISessions {
    items: ISessionResult[];
    next_key: string;
    prev_key: string;
    total_count: number;
  }

  export interface IConsumerInfo {
    id: string;
    session_id: string;
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    drivers_license: string;
    create_date: string;
    consumer_id: string;
    consumer_profile_id: string | null;
    consumer_fi_account_id: string;
    institution_name: string;
    account_name: string;
    order_id: string;
  }

  export interface ITransactionSummaryBreakdown {
    gross_amount?: number;
    order_amount?: number;
    tip_amount?: number;
    convenience_fee_amount?: number;
    other_incoming?: number;
  }
  export interface IGatewayTransaction {
    id: string;
    gateway_id: string;
    entity_id: string;
    organization_id: string;
    create_date: string;
    update_date: string;
    tip_amount: number;
    fee_amount: number;
    original_amount: number;
    method: string;
    status: string;
    reference_number: string;
    account_name: string;
    account_number: string;
    processor_name: string;
    processor_payment_id?: string;
    employee_reference_id: string;
    associate_id: string;
    customer_first_name: string;
    customer_last_name: string;
    customer_phone: string;
    customer_email: string;
  }

  export type ITipsResult = {
    transaction_date: string;
    ticket_id?: string;
    treez_employee_name?: string;
    treez_employee_id?: string;
    tip_amount: number;
    payment_type?: string;
    transaction_id: string;
  };

  export interface ITips {
    items: ITipsResult[];
    next_key: string;
    prev_key: string;
    total_count: number;
  }

  export type IFetchTips = {
    key?: string | null;
    start_date?: string | null;
    end_date?: string | null;
    condition?: string;
    amount_1?: string;
    amount_2?: string;
    search_string?: string;
    payment_type?: string;
  };

  export type TotalTipAggregateBreakdown = {
    tip_amount: number;
    number_of_tips: number;
    average_tip_amount: number;
  };

  export type TipByPaymentTypeAggregateBreakdown = {
    payment_type: string;
    tip_amount: number;
    number_of_tips: number;
    average_tip_amount: number;
  };

  export type TipByPaymentTypeAggregateItem = {
    date: string;
    value: TotalTipAggregateBreakdown;
    tip_breakup: TipByPaymentTypeAggregateBreakdown[];
  };
  export interface ISummaryData {
    total_tip: number;
    number_of_tips: number;
    average_tip_amount: number;
    previous_total_tip: number;
    previous_number_of_tips: number;
    previous_average_tip_amount: number;
  }

  export type TipByPaymentTypeAggregate = Record<
    SUMMARY.IIntervalType,
    TipByPaymentTypeAggregateItem[]
  > &
    ISummaryData;

  export type TipByEmployeeAggregateItem = {
    treez_employee_name: string;
    treez_employee_id: string | null;
    total_tip_amount: number;
  };

  export interface TipByEmployeeAggregate {
    employee_tips: TipByEmployeeAggregateItem[];
    total_tip: number;
    number_of_tips: number;
    previous_total_tip: number;
    previous_number_of_tips: number;
  }

  export type IFetchTipsSummary = {
    interval_types?: string | null;
    start_date?: string | null;
    end_date?: string | null;
    prev_start_date?: string | null;
    prev_end_date?: string | null;
    summary_type?: string | null;
    search_string?: string | null;
  };

  export type IFetchCSVTips = {
    start_date?: string | null;
    end_date?: string | null;
    condition?: string;
    amount_1?: string;
    amount_2?: string;
    payment_type?: string;
    timezone?: string;
  };

  export type ITransactionSummaryByPaymentType = {
    method: string;
    transaction_count: number;
    gross_amount: number;
    order_amount: number;
    tip_amount: number;
    provider_fee_amount: number;
  };

  export type ITransactionSummary = {
    aggregate_transaction_amount: ITransactionSummaryByPaymentType[];
    total_amount: number;
    total_count: number;
    prev_total_amount: number;
    prev_total_count: number;
  };

  export type ITransactionSummaryRequest = {
    start_date: string;
    end_date: string;
    prev_start_date: string;
    prev_end_date: string;
  };

  export interface PaymentReducer {
    isLoading: boolean;
    error: Error;
    created: boolean;
    charges: ICharge;
    keys: IPaginatedResponse<IApiKey>;
    key: IApiKeyResponse;
    addedUser: boolean;
    addedKey: boolean;
    revokeKey: boolean;
    addedNote: boolean;
    transactions: ITransactions;
    sessions: ISessions;
    individualTransaction: IIndividualTransaction;
    url: string;
    addedSession: ISessionResult | null;
    sessionResults: ISessionResult;
    transactionSummary: ITransactionSummary;
    cancelTransaction: boolean;
    gatewayTransaction: IGatewayTransaction;
    tips: ITips;
    tipsSummaryPaymentType: TipByPaymentTypeAggregate;
    tipsSummaryEmployeeType: TipByEmployeeAggregate;
  }
  export type IAddNotes = {
    notes_data: string;
  };
  export type ICreateTerminal = {
    name: string; // skipcq JS-0122
    organization_id: string | undefined;
  };

  export type ICreateAPIKeys = {
    partner: {
      id: string;
      name: string;
    };
    description?: string;
  };

  export interface ICreateTerminalResponse {
    id: string;
    name: string; // skipcq JS-0122
    status: string; // skipcq JS-0122
    organization_id: string;
    terminal_url: string;
    qr_link: null;
    create_date: string;
  }
}
