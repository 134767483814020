/* NOTE: If using nested routes, define in decreasing order of hierarchy with base route at last in routes.js  */
const routeConfig = {
  ROOT: '/',
  AUTH: {
    layout: '/auth',
    LOGIN: {
      path: '/login',
    },
    // RESET: {
    //   path: '/reset',
    // },
  },
  SETTINGS: {
    layout: 'settings',
    LOGISTICS: {
      path: 'logistics',
    },
    ORGANISATION: {
      path: 'organisation',
    },
    CONFIGURATION: {
      path: 'configuration',
    },
    ACCOUNT: {
      path: 'account',
    },
    INTEGRATIONS: {
      path: 'integrations',
    },
    TEAM: {
      path: 'team',
    },
    INVITES: {
      path: 'invites',
    },
    NOTIFICATION: {
      path: 'notification',
    },
    PRODUCTS: {
      path: 'products',
    },
    DEVELOPERS: {
      path: 'developers',
    },
  },
  STORES: {
    layout: 'stores',
    LIST: {
      path: 'list',
    },
    NEW: {
      path: 'new',
    },
    ERROR_ACCESS_DENIED: {
      path: 'err_access_denied',
    },
  },
  ANALYTICS: {
    layout: 'dashboard',
    HOME: {
      path: 'home',
    },
    PROFILE: {
      path: 'profile',
    },
  },
  PAYMENT_LINKS:{
    layout: 'paymentlinks',
    paymentlinks: {
      path: '',
    },
  },
  CUSTOMERS: {
    layout: 'customers',
    customers: {
      path: '',
    },
  },
  PAYMENTS: {
    layout: 'payments',
    transactions: {
      path: 'transactions',
    },
    tips: {
      path: 'tips',
    },
    refunds: {
      path: 'refunds',
    },
  },
  BANKING: {
    layout: 'banking',
    SUMMARY: {
      path: 'summary',
    },
    STATEMENTS: {
      path: 'statements',
    },
    FAILED_TRANSACTIONS: {
      path: 'failed_transactions',
    },
    PAYOUTS: {
      path: 'payouts',
    },
  },
  PAYOUTS: {
    layout: 'payouts',
    payout: {
      path: '',
    },
  },
  DEVELOPERS: {
    layout: 'developers',
    API_KEYS: {
      path: 'keys',
    },
    SESSIONS: { path: 'sessions' },
    EVENTS: {
      path: 'events',
    },
    WEBHOOK: {
      path: 'webhooks',
    },
    DOCUMENTATION: {
      path: 'https://payswifter.readme.io/',
    },
  },
  VERIFICATION: {
    path: '/verification',
  },
  PARTNER_STORES: {
    layout: 'partner_stores',
    stores: {
      path: '',
    },
  },
  PARTNER_TRANSACTIONS: {
    layout: 'transactions',
    transactions: {
      path: '',
    },
  },
};
export default routeConfig;
