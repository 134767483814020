import ErrorMessage from 'components/errorMessage';
import FormLabel from 'components/form-label';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Col, FormGroup, Input, Row } from 'reactstrap';
import { ORGANISATION } from 'state/organisation/type';
import useStore from 'state/store/hooks/useStore';
import classNames from 'classnames';
import styles from '../styles.module.scss';



interface IProps {
  data: ORGANISATION.IBrandSettings | null;
  logisticsProvider: ORGANISATION.ILogisticsProvider | null;
}

const NonAchBrandingSettings = ({ data, logisticsProvider }: IProps): JSX.Element => {
  const { toUpdateStore } = useStore();
  const { register, handleSubmit, errors, setValue } = useForm();
  const {
    name,
    brand_name: brandName,
    contact_email: contactEmail,
    logo_url: logoUrl,
    privacy_policy_url: privacyPolicyUrl,
    tos_url: tosUrl,
  } = data || {};

  useEffect(() => {
    setValue('name', name);
    setValue('brand_name', brandName);
    setValue('contact_email', contactEmail);
    setValue('logo_url', logoUrl);
    setValue('privacy_policy_url', privacyPolicyUrl);
    setValue('tos_url', tosUrl);
  }, [data]);


  const onFormSubmit = (values: { [x: string]: string }) => {
    if (data) {
      const { id } = data;
      const request = {
        brand_name: values.brand_name || undefined,
        name: values.name || undefined,
        contact_email: values.contact_email || undefined,
        logo_url: values.logo_url || undefined,
        privacy_policy_url: values.privacy_policy_url || undefined,
        tos_url: values.tos_url || undefined,
      };
      toUpdateStore(id, request as ORGANISATION.IBrandSettings);
    }
  };

  return (
    <form className="pt-2">
      <Row>
        <Col sm="6" xs="6">
          <FormGroup>
            <FormLabel label="Store Name" element="name" />
            <Input
              defaultValue={name}
              placeholder="Store Name"
              name="name"
              innerRef={register({ required: true })}
            />
            {errors.name && (
              <ErrorMessage
                isValidation
                message="Please enter a valid store name"
              />
            )}
          </FormGroup>
          <div
            className={classNames(styles.nonAchBranding)}
            onClick={handleSubmit(onFormSubmit)}
          >
            Save
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default NonAchBrandingSettings;
