export const FETCH_REFUNDS = 'refunds/FETCH_REFUNDS';
export const FETCH_REFUNDS_SUCCESS = 'refunds/FETCH_REFUNDS_SUCCESS';
export const FETCH_REFUNDS_FAILURE = 'refunds/FETCH_REFUNDS_FAILURE';

export const GET_REFUND_LIST = 'refunds/GET_REFUND_LIST';
export const GET_REFUND_LIST_SUCCESS = 'refunds/GET_REFUND_LIST_SUCCESS';
export const GET_REFUND_LIST_FAILURE = 'refunds/GET_REFUND_LIST_FAILURE';

export const INITIATE_REFUND = 'refunds/INITIATE_REFUND';
export const INITIATE_REFUND_SUCCESS = 'refunds/INITIATE_REFUND_SUCCESS';
export const INITIATE_REFUND_FAILURE = 'refunds/INITIATE_REFUND_FAILURE';

export const RETRY_TRANSACTION = 'refunds/RETRY_TRANSACTION';
export const RETRY_TRANSACTION_SUCCESS = 'refunds/RETRY_TRANSACTION_SUCCESS';
export const RETRY_TRANSACTION_FAILURE = 'refunds/RETRY_TRANSACTION_FAILURE';

export const VERIFY_OTP = 'refunds/VERIFY_OTP';
export const VERIFY_OTP_SUCCESS = 'refunds/VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILURE = 'refunds/VERIFY_OTP_FAILURE';

export const RESET_REFUND_STATE = 'refunds/RESET_REFUND_STATE';
export const RESET_RETRY_STATE = 'refunds/RESET_RETRY_STATE';

export const FETCH_REFUNDS_SUMMARY_DATA = 'refunds/FETCH_REFUNDS_SUMMARY_DATA';
export const FETCH_REFUNDS_SUMMARY_DATA_SUCCESS =
  'refunds/FETCH_REFUNDS_SUMMARY_DATA_SUCCESS';
export const FETCH_REFUNDS_SUMMARY_DATA_FAILURE =
  'refunds/FETCH_REFUNDS_SUMMARY_DATA_FAILURE';

export const FETCH_CSV = 'refunds/FETCH_CSV';
export const FETCH_CSV_SUCCESS = 'refunds/FETCH_CSV_SUCCESS';
export const FETCH_CSV_FAILURE = 'refunds/FETCH_CSV_FAILURE';

export const CLEAR_EXPORT = 'refunds/CLEAR_EXPORT';

export const action = (type: string): IAction => ({
  type,
});

export const actionSuccess = <T>(type: string, payload: T): IAction => ({
  type: `${type}_SUCCESS`,
  payload,
});

export const actionFailure = <T>(type: string, payload: T): IAction => ({
  type: `${type}_FAILURE`,
  payload,
});
