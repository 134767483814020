import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import classNames from 'classnames';
import * as util from 'utils/dashboard'; // skipcq: JS-C1003
import * as utils from 'utils/summary'; // skipcq: JS-C1003
import { SUMMARY } from 'state/summary/type';
import QUERY from 'utils/query';
import { useNavigate, useLocation } from 'react-router-dom';
import FrequencyButtonGroup from '../../../components/summary-tab/freq-button-group';
import styles from '../../tips/tips.module.scss';
import TipSummaryBy from './tip-summary-by-dropdown';
import { PAYMENTS } from 'state/payment/type';
import TipSummaryByPaymentType from './tip-summary-by-payment-type';
import TipssAccordion from '../../../components/summary-tab/accordions/tips';
import TipSummaryByEmployee from './tip-summary-by-employee';
import moment, { Moment } from 'moment';
import DateTimeRangePicker from 'components/calender/rangePicker';
import { TIPS_TABS } from 'utils/constants';
import useQuery from 'utils/hooks/useQuery';
import usePayment from 'state/payment/hooks/usePayment';
import STRING from 'utils/string';
import Search from 'components/search';

interface PaginationProps {
  id: string;
  numResults: number;
  prevKey: string;
  nextKey: string;
  loadPage: (paginationKey?: string) => void;
}
interface IProps<T> {
  excludeHourly?: boolean;
  testId?: string;
  paginationProp?: PaginationProps | undefined;
  showIntervalButton?: boolean;
  toSetSummaryType: Dispatch<SetStateAction<string>>;
  summaryType: string;
  activeTab: string;
  intervalTypes: SUMMARY.IIntervalType[];
  toSetIntervalTypes: React.Dispatch<
    React.SetStateAction<SUMMARY.IIntervalType[]>
  >;
}

const TipSummary = <T,>({
  excludeHourly = true,
  showIntervalButton = true,
  toSetSummaryType,
  summaryType,
  activeTab,
  intervalTypes,
  toSetIntervalTypes,
}: IProps<T>): JSX.Element => {
  const fromDate = moment().subtract(7, 'day').startOf('day');
  const todayDate = moment().endOf('day')

  const [dateFromValue, setDateFrom] = useState<Moment>(fromDate);
  const [dateToValue, setDateTo] = useState<Moment>(todayDate);
  const [activeFreq, setActiveFreq] = useState<SUMMARY.IIntervalType>(
    SUMMARY.IIntervalType.DAILY
  );
  const [comparisonDate, setComapaDate] = useState(
    util.getComparisonDateTime(
      dateFromValue.toISOString(),
      dateToValue.toISOString()
    ) || []
  );

  useEffect(() => {
    if (activeTab === TIPS_TABS[1].label) {
      setDateFrom(fromDate);
      setDateTo(todayDate);
    }
  }, [activeTab]);

  const { payment, toFetchTipsSummary } = usePayment();

  const navigate = useNavigate();
  const location = useLocation();

  const query = useQuery(location?.search);

  const dates = query.get('dates')?.split(',') || [];
  const dateFrom = dates.length > 0 ? dates[0].replace(' ', '+') : '';
  const dateTo = dates.length > 1 ? dates[1].replace(' ', '+') : '';
  const searchString = query.get('search') || undefined;

  useEffect(() => {
    if (intervalTypes.length > 0 && activeTab === TIPS_TABS[0].label) {
      toFetchTipsSummary({
        start_date: dateFrom || dateFromValue.toISOString(),
        end_date: dateTo || dateToValue.toISOString(),
        summary_type: summaryType,
        interval_types: intervalTypes.join(),
        search_string: searchString,
        prev_start_date: comparisonDate[0],
        prev_end_date: comparisonDate[1],
      });
    }
  }, [dateFrom, dateTo, intervalTypes, activeTab, summaryType, searchString]);

  useEffect(() => {
    const interval = util.intervalInDays(
      dateFrom || dateToValue.toISOString(),
      dateTo || dateToValue.toISOString()
    );

    // set possible interval types according to date range
    toSetIntervalTypes(util.mapIntervalToFrequency(interval));
  }, []);

  const newProducts = (
    data: PAYMENTS.TipByPaymentTypeAggregate,
    interval: SUMMARY.IIntervalType
  ) => {
    const indexedData = data[interval] || [];

    return indexedData.map((a, index) => {
      return { ...a, key: `${a.date}-${interval}-${index}` };
    });
  };

  // Table data
  const [products, setProducts] = useState(
    payment?.tipsSummaryPaymentType[intervalTypes[0]]
  );

  useEffect(() => {
    if (intervalTypes.length > 0) {
      setActiveFreq(intervalTypes[0]);
    }
  }, [intervalTypes]);

  useEffect(() => {
    setProducts(newProducts(payment.tipsSummaryPaymentType, activeFreq));
  }, [payment.tipsSummaryPaymentType, activeFreq]);

  /**
   * Add the date filter to the URL as param query
   * @param {Date[]} date[] - Date[]
   */
  const applyDateFilter = (dateFilter: Array<Moment>) => {
    if (dateFilter.length > 0) {
      if (dateFilter.length === 1) {
        if (dateFilter[0]) {
          dateFilter[1] = dateToValue;
        } else if (dateFilter[1]) {
          dateFilter[0] = dateFromValue;
        }
      }
      navigate({
        search: QUERY.update(location.search, {
          dates: [
            dateFilter[0].toISOString(),
            dateFilter[1].toISOString(),
          ].join(),
        }),
      });
    }
  };

  const dateValues: Moment[] = [];

  useEffect(() => {
    if (dateFromValue) {
      dateValues[0] = dateFromValue;
    }
    if (dateToValue) {
      dateValues[1] = dateToValue;
    }
    if (dateValues.length > 0 && activeTab === TIPS_TABS[0].label) {
      applyDateFilter(dateValues);
    }
    const interval = util.intervalInDays(
      dateFromValue.toISOString(),
      dateToValue.toISOString()
    );

    // set possible interval types according to date range

    if (excludeHourly) {
      toSetIntervalTypes(utils.mapIntervalToFrequencyBanking(interval));
    } else {
      toSetIntervalTypes(util.mapIntervalToFrequency(interval));
    }
    if (intervalTypes.length > 0) {
      setActiveFreq(intervalTypes[0]);
    }
  }, [dateFromValue, dateToValue]);

  useEffect(() => {
    setComapaDate(
      util.getComparisonDateTime(
        dateFromValue.toISOString(),
        dateToValue.toISOString()
      ) || []
    );
  }, [dateFromValue, dateToValue]);

  return (
    <div className={classNames(styles.summaryTabContainer)}>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <div className={classNames(styles.segmentedByText)}>Segmented by</div>
          <TipSummaryBy setSummartType={toSetSummaryType} />
          {summaryType === 'employee' && (
            <Search
              queryParams={location.search}
              placeHolders="Search by Employee name, Employee ID"
              className={styles.searchStyleSummary}
            />
          )}
        </div>
        {products?.length > 0 &&
        showIntervalButton &&
        summaryType === 'payment_type' ? (
          <div className="ml-auto">
            <FrequencyButtonGroup
              enabledFreq={intervalTypes}
              activeFreq={activeFreq}
              setActiveFreq={setActiveFreq}
            />
          </div>
        ) : (
          <></>
        )}
      </div>

      {summaryType === 'payment_type' ? (
        <TipSummaryByPaymentType
          summaryIntervalData={products}
          summaryTotalData={payment.tipsSummaryPaymentType}
          expandRowFormatter={(rowData) => (
            <>{<TipssAccordion row={rowData} />}</>
          )}
          activeFreq={activeFreq}
          comparisonDate={comparisonDate}
        />
      ) : (
        <TipSummaryByEmployee
          summaryData={payment.tipsSummaryEmployeeType}
          previousStartDate={comparisonDate[0]}
          previousEndDate={comparisonDate[1]}
        />
      )}
    </div>
  );
};

export default TipSummary;
