import React from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import { WriteOffIcon } from 'assets/img/icons/writeoff';
import styles from './styles.module.scss';

export interface IProps {
  numberSelected: number;
  onRetry?: () => void;
  onWriteOff?: () => void;
  onClear?: () => void;
}

const FailedPaymentSelectOptions = ({
  numberSelected,
  onWriteOff,
  onRetry,
  onClear,
}: IProps): JSX.Element => {
  return (
    <>
      <div className="mr-auto" data-testid="failed_payment_multi_select">
        <div className={classNames(styles.selected, 'm-0')}>
          {numberSelected} Selected
        </div>
        <Button
          className={classNames(styles.retry)}
          onClick={onRetry}
          data-testid="retry_btn"
        >
          <i
            className={classNames(
              'ph-bold ph-arrows-clockwise align-items-center text-muted'
            )}
          />
          Retry
        </Button>
        <Button
          className={classNames(styles.writeOff)}
          onClick={onWriteOff}
          data-testid="write_off_btn"
        >
          <span>
            <WriteOffIcon />
            Write-off
          </span>
        </Button>

        <Button
          className={classNames(styles.clearSelection)}
          onClick={onClear}
          data-testid="clear_btn"
        >
          <i
            className={classNames(
              'ph-bold ph-x align-items-center text-muted',
              styles.icon
            )}
          ></i>
          Clear selection
        </Button>
      </div>
    </>
  );
};

export default FailedPaymentSelectOptions;
