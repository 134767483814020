import classNames from 'classnames';
import React from 'react';
import { PAYMENTS } from 'state/payment/type';
import styles from '../../transactions.module.scss';

interface props {
  info: PAYMENTS.IIndividualTransactionResult;
}

const PaymentMethod = ({
  info = {} as PAYMENTS.IIndividualTransactionResult,
}: props): JSX.Element => {
  return (
    <>
      <div className="mb-9 pb-2">
        <h3 className="mb-2 PaymentDetailsHeading">Payment method</h3>
        <hr className="mt-0 pb-1 mb-2" />
        <div>
          <div className={classNames(styles.SummaryInfo, 'pt-0')}>
            <span className={classNames(styles.SummaryHeading)}>Method</span>
            <span className={classNames(styles.HeadingData)}>
              {info.payment_type}
            </span>
          </div>
          <div className={classNames(styles.SummaryInfo)}>
            <span className={classNames(styles.SummaryHeading)}>
              Card Issuer / Bank / Wallet
            </span>
            <span className={classNames(styles.HeadingData)}>
              {info.consumer_bank_name || 'na'}
            </span>
          </div>
          <div className={classNames(styles.SummaryInfo, 'pb-0')}>
            <span className={classNames(styles.SummaryHeading)}>
              Account / Card Number
            </span>
            <span className={classNames(styles.HeadingData)}>
              {info.consumer_account}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentMethod;
