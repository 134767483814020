import React, { useEffect, useState } from 'react';
import PrivateLayout from './private';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { setAPIEntityHeader, setAPIOrgHeader } from 'services/api';
import accessLocalStorage from 'services/localStorage';
import routeConfig from 'routes/config';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import useStore from 'state/store/hooks/useStore';

const DashboardLayout = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { orgId } = useParams();
  const { toFetchActiveOrg, organisation } = useOrganisation();
  const { activeOrg } = organisation;
  const { store } = useStore();
  const { storesList } = store;

  const validateOrgID = () => {
    // to handle invalid org IDs from URL
    return storesList?.find((org) => org.organization_id === orgId);
  };

  const appInit = async () => {
    if (orgId) {
      setAPIOrgHeader(orgId);
      accessLocalStorage.setOrgId(orgId);

      await toFetchActiveOrg();
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    if (orgId) {
      appInit();
    }
  }, [orgId]);

  useEffect(() => {
    if (activeOrg?.treez_entity_id) {
      accessLocalStorage.setEntityId(activeOrg.treez_entity_id);
      setAPIEntityHeader(activeOrg.treez_entity_id);
    }
  }, [activeOrg]);

  // to handle invalid org IDs in URL
  if (!orgId || !validateOrgID()) {
    return (
      <Navigate
        to={`${routeConfig.ROOT}${routeConfig.STORES.layout}/${routeConfig.STORES.LIST.path}`}
        replace
      />
    );
  }
  if (!isLoaded) return null;
  return (
    <PrivateLayout>
      <Outlet />
    </PrivateLayout>
  );
};

export default DashboardLayout;
