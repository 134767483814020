import classNames from 'classnames';
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import styles from './heading.module.scss';

export interface IHeaderProps {
  heading?: string | JSX.Element;
  subHeading?: string | JSX.Element;
  className?: string;
  tooltipText?: string;
  level?: 'h1' | 'h2';
  overrideContainerClass?: boolean;
  headingAppendRight?: JSX.Element;
}

const PageHeading = ({
  heading,
  subHeading,
  className,
  tooltipText,
  level = 'h1',
  overrideContainerClass = false,
  headingAppendRight,
}: IHeaderProps): JSX.Element => {
  return (
    <div
      className={classNames(
        overrideContainerClass ? '' : styles.container,
        className
      )}
    >
      <div className="d-flex align-items-center">
        {heading && (
          <>
            {level === 'h1' && (
              <h1
                id="page-heading"
                className={classNames(styles.heading1, subHeading && 'mb-1')}
              >
                {heading}
              </h1>
            )}
            {level === 'h2' && (
              <h2
                id="page-heading"
                className={classNames(styles.heading2, subHeading && 'mb-1')}
              >
                {heading}
              </h2>
            )}
          </>
        )}
        {headingAppendRight}
      </div>
      {subHeading && (
        <h2 className={classNames(styles.subheading)}>
          {subHeading}
          {tooltipText && (
            <>
              <span id="tooltip" className="pl-1">
                <i
                  data-testid="tooltip-icon"
                  className="ph-info-fill icon-md text-muted"
                />
              </span>
              <UncontrolledTooltip placement="right" target="tooltip">
                {tooltipText}
              </UncontrolledTooltip>
            </>
          )}
        </h2>
      )}
    </div>
  );
};

export default PageHeading;
