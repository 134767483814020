import { apiWithOrg } from 'services/api';
import { AxiosError } from 'axios';
import { useStateValue } from '../..';
import {
  FETCH_PARTNERS,
  FETCH_STORES,
  RESET,
  action,
  actionFailure,
  actionSuccess,
} from '../actions';
import { PARTNER_STORES } from '../type';

const usePartnerStores = () => {
  const { state, dispatch } = useStateValue();
  const { partnerStores } = state;

  const toFetchStores = async (values: PARTNER_STORES.IFetchStoresRequest) => {
    dispatch(action(FETCH_STORES));
    try {
      const response = await apiWithOrg.get('partners/stores', values);
      dispatch(actionSuccess(FETCH_STORES, response.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_STORES, e));
    }
  };

  const toExportStores = async (): Promise<PARTNER_STORES.IExportResponse> => {
    try {
      const response = await apiWithOrg.get<PARTNER_STORES.IExportResponse>(
        'partners/stores/export'
      );
      return response.data;
    } catch (e) {
      const err = e as AxiosError;
      return err;
    }
  };

  const toFetchPartnersList = async () => {
    dispatch(action(FETCH_PARTNERS));
    try {
      const response = await apiWithOrg.get<PARTNER_STORES.IPartnersList>(
        'partners/available'
      );
      dispatch(actionSuccess(FETCH_PARTNERS, response.data));
      return true;
    } catch (e) {
      dispatch(actionFailure(FETCH_PARTNERS, e));
      return false;
    }
  };

  const toResetPartner = () => {
    dispatch(action(RESET));
  };

  return {
    partnerStores,
    toFetchStores,
    toExportStores,
    toFetchPartnersList,
    toResetPartner,
  };
};

export default usePartnerStores;
