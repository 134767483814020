import PageHeading from 'components/heading';
import classNames from 'classnames';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import styles from 'views/failedTransactions/styles.module.scss';
import CURRENCY from 'utils/currency';
import {
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from 'reactstrap';
import BootstrapTable, { SortOrder } from 'react-bootstrap-table-next';
import Filter from 'components/filter';
import Search from 'components/search';
import {
  EFilterConfigType,
  IFilterConfig,
  IFilterConfigDate,
  IFilterConfigNumber,
} from 'components/filter/types';
import Pagination from 'components/pagination';
import useBanking from 'state/banking/hooks/useBanking';
import { formatToTz } from 'utils/date-format';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import { BANKING } from 'state/banking/type';
import FailedPaymentSelectOptions from 'components/failed-payment-select-options';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ToastContainer from 'components/toasts/container';
import { WriteOffIcon } from 'assets/img/icons/writeoff';
import routeConfig from 'routes/config';
import useRefunds from 'state/refunds/hooks/useRefunds';
import useQuery from 'utils/hooks/useQuery';
import QUERY from 'utils/query';
import { notRetryableReason } from 'utils/notRetryableTooltip';
import MultiRetryModal from './retryModal';
import MultiWriteOffModal from './writeOffModal';

enum TOAST_TYPE {
  WRITE_OFF = 'WRITE_OFF', // batch writeoff
  SINGLE_WRITEOFF = 'SINGLE_WRITEOFF',
  RETRY = 'RETRY', // batch retry
  SINGLE_RETRY = 'SINGLE_RETRY',
}

const FailedTransactionsList = (): JSX.Element => {
  const [failedTransactions, setFailedTransactions] =
    useState<BANKING.IIndividualTransactionResult[]>();
  const [WriteOffTransactions, setWriteOffTransactions] =
    useState<BANKING.IWriteOffTransaction[]>();
  const [showRetryModal, setShowRetryModal] = useState<boolean>(false);
  const [showWriteOffModal, setShowWriteOffModal] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<
    BANKING.IIndividualTransactionResult[]
  >([]);
  const [isSingleSelected, setIsSingleSelected] = useState<boolean>(false);
  const [singleSelectedRowData, setSingleSelectedRowData] = useState<
    BANKING.IIndividualTransactionResult[]
  >([]);
  const [toastType, setToastType] = useState<TOAST_TYPE | null>(null);
  const [toastTxnId, setToastTxnId] = useState<string | undefined>();

  const {
    banking,
    toFetchTotalFailed,
    toFetchFailedTransactions,
    toFetchWriteOffTransactions,
    toInitiateBatchRetry,
  } = useBanking();

  const navigate = useNavigate();
  const location = useLocation();

  const { organisation } = useOrganisation();
  const { retryTransaction } = useRefunds();
  const { settings, activeOrgId } = organisation;
  const query = useQuery(location.search);
  const [filterValues, setFilterValues] =
    useState<Record<string, string | undefined>>();

  const failedACHFilter: IFilterConfig[] = [
    {
      key: 'return_dates',
      label: 'Return date',
      type: EFilterConfigType.date,
      data: {
        keys: [],
        values: [
          filterValues?.return_start_date,
          filterValues?.return_end_date,
        ],
      } as IFilterConfigDate,
      selected: false,
    },
    {
      key: 'create_dates',
      label: 'Transaction date',
      type: EFilterConfigType.date,
      data: {
        keys: [],
        values: [filterValues?.start_date, filterValues?.end_date],
      } as IFilterConfigDate,
      selected: false,
    },
    {
      key: 'amount',
      label: 'Amount',
      type: EFilterConfigType.number,
      data: {
        keys: ['condition'],
        values: [
          filterValues?.condition,
          filterValues?.amount_1,
          filterValues?.amount_2,
        ],
      } as IFilterConfigNumber,
      selected: false,
    },
  ];
  const writeOffFilter = [
    {
      key: 'write_off_dates',
      label: 'Write off date',
      type: EFilterConfigType.date,
      data: {
        keys: [],
        values: [
          filterValues?.write_off_start_date,
          filterValues?.write_off_end_date,
        ],
      },
      selected: false,
    } as IFilterConfig,
  ].concat(failedACHFilter);
  const cashRecordFilter = [
    {
      key: 'cash_record_dates',
      label: 'Recorded date',
      type: EFilterConfigType.date,
      data: {
        keys: [],
        values: [
          filterValues?.cash_record_start_date,
          filterValues?.cash_record_end_date,
        ],
      },
      selected: false,
    } as IFilterConfig,
  ].concat(failedACHFilter);

  const [filterConfig, setFilterConfig] =
    useState<IFilterConfig[]>(failedACHFilter);

  const subDetailsRetry = (
    <>
      <Link
        className={styles.subDetails}
        to={`/${activeOrgId}/${routeConfig.PAYMENTS.layout}/${routeConfig.PAYMENTS.transactions.path}?tab=details`}
      >
        View
      </Link>
    </>
  );
  const subDetailsSingleRetry = (
    <>
      <Link
        className={styles.subDetails}
        to={`/${activeOrgId}/${routeConfig.PAYMENTS.layout}/${
          routeConfig.PAYMENTS.transactions.path
        }/${toastTxnId ?? '?tab=details'}`}
      >
        View details
      </Link>
    </>
  );
  const subDetailsSingleWriteoff = (
    <>
      <Link
        className={styles.subDetails}
        to={
          toastTxnId
            ? `/${activeOrgId}/${routeConfig.PAYMENTS.layout}/${routeConfig.PAYMENTS.transactions.path}/${toastTxnId}`
            : `/${activeOrgId}/${routeConfig.BANKING.layout}/${routeConfig.BANKING.FAILED_TRANSACTIONS.path}?tab=write_offs`
        }
      >
        View details
      </Link>
    </>
  );
  const subDetailsWriteOff = (
    <>
      <Link
        className={styles.subDetails}
        to={`/${activeOrgId}/${routeConfig.BANKING.layout}/${routeConfig.BANKING.FAILED_TRANSACTIONS.path}?tab=write_offs`}
      >
        View
      </Link>
    </>
  );

  const successSingleRetryMessage = 'Retry initiated successfully';
  const successMultiRetryMessage = `Retry initiated successfully for the ${selectedRows.length} transactions`;
  const successMultiWriteoffMessage = `${selectedRows.length} transactions written-off successfully`;
  const successSingleWriteOffMessage = 'Transaction written-off successfully';
  const heading = 'Failed transactions';
  const subHeading =
    'Consolidated view of all failed transactions, and those which have been settled in cash or written off.';

  const tabNames = [
    {
      label: 'Failed ACH',
      filters: [],
      tooltip: 'List of all failed ACH transactions',
      urlParam: 'failed_ach',
    },
    {
      label: 'Cash payments recorded',
      filters: [],
      tooltip:
        'List of all failed transactions for which cash payment was recorded',
      urlParam: 'cash_payments_recorded',
    },
    {
      label: 'Write offs',
      filters: [],
      tooltip:
        'List of all failed transactions which were written off as bad debt and for which money could not be collected from the customer',
      urlParam: 'write_offs',
    },
  ];

  const [activeTab, setActiveTab] = useState(
    (tabNames.length > 0 && tabNames[0].label) || ''
  );

  // Next and prev key
  const [prevKey, setPrevKey] = useState<string | null>(null);
  const [nextKey, setNextKey] = useState<string | null>(null);

  // Number of results
  const [numResults, setNumResults] = useState(0);

  // get to prev page
  const prevPage = () => {
    if (activeTab === tabNames[0].label) {
      toFetchFailedTransactions({
        key: banking.failed_transactions.prev_key,
        type: BANKING.BankingFailedTxnType.FAILED_ACH,
        ...filterValues,
      });
    } else if (activeTab === tabNames[1].label) {
      toFetchFailedTransactions({
        key: banking.failed_transactions.prev_key,
        type: BANKING.BankingFailedTxnType.CASH_RECORDED,
        ...filterValues,
      });
    } else if (activeTab === tabNames[2].label) {
      toFetchWriteOffTransactions({
        key: banking.write_off_transactions.prev_key,
        ...filterValues,
      });
    }
  };

  const getToastMessage = () => {
    if (toastType === TOAST_TYPE.SINGLE_RETRY) return successSingleRetryMessage;
    if (toastType === TOAST_TYPE.RETRY) return successMultiRetryMessage;
    if (toastType === TOAST_TYPE.WRITE_OFF) return successMultiWriteoffMessage;
    if (toastType === TOAST_TYPE.SINGLE_WRITEOFF)
      return successSingleWriteOffMessage;
    return '';
  };

  const getToastSubDetails = () => {
    if (toastType === TOAST_TYPE.SINGLE_RETRY) return subDetailsSingleRetry;
    if (toastType === TOAST_TYPE.RETRY) return subDetailsRetry;
    if (toastType === TOAST_TYPE.SINGLE_WRITEOFF)
      return subDetailsSingleWriteoff;
    if (toastType === TOAST_TYPE.WRITE_OFF) return subDetailsWriteOff;
    return <></>;
  };

  // get to next page
  const nextPage = () => {
    if (activeTab === tabNames[0].label) {
      toFetchFailedTransactions({
        key: banking.failed_transactions.next_key,
        type: BANKING.BankingFailedTxnType.FAILED_ACH,
        ...filterValues,
      });
    } else if (activeTab === tabNames[1].label) {
      toFetchFailedTransactions({
        key: banking.failed_transactions.next_key,
        type: BANKING.BankingFailedTxnType.CASH_RECORDED,
        ...filterValues,
      });
    } else if (activeTab === tabNames[2].label) {
      toFetchWriteOffTransactions({
        key: banking.write_off_transactions.next_key,
        ...filterValues,
      });
    }
  };

  useEffect(() => {
    // set active tab from url param
    const urlActiveTab = query.get('tab');
    const urlTabItem = tabNames?.find((t) => t.urlParam === urlActiveTab);

    if (urlTabItem && activeTab !== urlTabItem?.label) {
      setActiveTab(urlTabItem.label);
    } else if (!urlActiveTab) {
      navigate({
        search: QUERY.update(location.search, { tab: tabNames?.[0].urlParam }),
      });
    }

    // parse filter config from URL on first load
    const dates = query.get('create_dates')?.split(',') || [];
    const [start_date, end_date] =
      dates.length > 0
        ? dates.map((d) => d.replace(' ', '+'))
        : [undefined, undefined];

    const returnDates = query.get('return_dates')?.split(',') || [];
    const [return_start_date, return_end_date] =
      returnDates.length > 0
        ? returnDates.map((d) => d.replace(' ', '+'))
        : [undefined, undefined];

    const writeOffDates = query.get('write_off_dates')?.split(',') || [];
    const [write_off_start_date, write_off_end_date] =
      writeOffDates.length > 0
        ? writeOffDates.map((d) => d.replace(' ', '+'))
        : [undefined, undefined];

    const cashRecordDates = query.get('cash_record_dates')?.split(',') || [];
    const [cash_record_start_date, cash_record_end_date] =
      cashRecordDates.length > 0
        ? cashRecordDates.map((d) => d.replace(' ', '+'))
        : [undefined, undefined];

    const condition = query.get('condition') || 'equal';
    const amount_1 = query.get('amount1') || undefined;
    const amount_2 = query.get('amount2') || undefined;

    const search_string = query.get('search') || undefined;

    setFilterValues(
      JSON.parse(
        JSON.stringify({
          condition,
          amount_1,
          amount_2,
          search_string,
          start_date,
          end_date,
          return_start_date,
          return_end_date,
          write_off_end_date,
          write_off_start_date,
          cash_record_start_date,
          cash_record_end_date,
        })
      )
    );
  }, [location?.search]);

  useEffect(() => {
    toFetchTotalFailed();
    if (activeTab === tabNames[0].label) {
      setFilterConfig(failedACHFilter);
      toFetchFailedTransactions({
        type: BANKING.BankingFailedTxnType.FAILED_ACH,
        ...filterValues,
      });
    } else if (activeTab === tabNames[1].label) {
      setFilterConfig(cashRecordFilter);
      toFetchFailedTransactions({
        type: BANKING.BankingFailedTxnType.CASH_RECORDED,
        ...filterValues,
      });
    } else if (activeTab === tabNames[2].label) {
      setFilterConfig(writeOffFilter);
      toFetchWriteOffTransactions({
        ...filterValues,
      });
    }
    setSelectedRows([]);
  }, [activeTab, filterValues]);

  useEffect(() => {
    if (activeTab === tabNames[0].label) {
      setFailedTransactions(banking.failed_transactions.items);
      setPrevKey(banking.failed_transactions.prev_key);
      setNextKey(banking.failed_transactions.next_key);
      setNumResults(banking.failed_transactions.total_count);
    }
    if (activeTab === tabNames[1].label) {
      setFailedTransactions(banking.failed_transactions.items);
      setPrevKey(banking.failed_transactions.prev_key);
      setNextKey(banking.failed_transactions.next_key);
      setNumResults(banking.failed_transactions.total_count);
    }
    if (activeTab === tabNames[2].label) {
      setWriteOffTransactions(banking.write_off_transactions.items);
      setPrevKey(banking.write_off_transactions.prev_key);
      setNextKey(banking.write_off_transactions.next_key);
      setNumResults(banking.write_off_transactions.total_count);
    }
  }, [banking.failed_transactions, banking.write_off_transactions]);

  const defaultSorted: [{ dataField: string; order: SortOrder }] = [
    {
      dataField: 'date',
      order: 'desc',
    },
  ];

  const handleOnSelect = (
    row: BANKING.IIndividualTransactionResult,
    isSelect: boolean
  ) => {
    if (isSelect) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(selectedRows.filter((x) => x.id !== row.id));
    }
  };

  const handleOnSelectAll = (
    isSelect: boolean,
    rows: BANKING.IIndividualTransactionResult[]
  ) => {
    if (isSelect) {
      // select all rows
      setSelectedRows(rows);
    } else if (
      !isSelect &&
      failedTransactions &&
      rows.length > 0 &&
      failedTransactions?.length !== rows.length
    ) {
      //  when rows are partially selected, select all rows
      setSelectedRows(failedTransactions);
    } else {
      // deselect all rows
      setSelectedRows([]);
    }
  };

  const getRetryAbleCount = (
    transactionsList: BANKING.IIndividualTransactionResult[]
  ) => transactionsList.filter((item) => item.is_retryable)?.length;

  const getListOfChargeIds = (
    transactionsList: BANKING.IIndividualTransactionResult[]
  ) => ({ charge_ids: transactionsList.map((item) => item.charge_id) });

  // Retry action
  const handleOnRetry = async (
    transaction?: BANKING.IIndividualTransactionResult
  ) => {
    const transactionsList = transaction ? [transaction] : selectedRows;
    const isRetryAbleCount = getRetryAbleCount(transactionsList);
    const chargeIds = getListOfChargeIds(transactionsList);

    // single retryable txn
    if (transactionsList.length === 1 && isRetryAbleCount === 1) {
      const response = await retryTransaction(transactionsList?.[0]?.charge_id);
      setToastTxnId(transactionsList?.[0]?.order_id);
      if (response) {
        toFetchFailedTransactions({
          type: BANKING.BankingFailedTxnType.FAILED_ACH,
          ...filterValues,
        });
        setSelectedRows([]);
        setSingleSelectedRowData([]);
        setIsSingleSelected(false);
        setToastType(TOAST_TYPE.SINGLE_RETRY);
      }
      return;
    }

    // batch retry when all txns are retryable
    if (
      transactionsList.length > 1 &&
      isRetryAbleCount === selectedRows.length
    ) {
      const response = await toInitiateBatchRetry(chargeIds);
      if (response) {
        setToastType(TOAST_TYPE.RETRY);
      }
      return;
    }

    // if atleast one non-retryable transaction
    setShowRetryModal(true);
  };

  const handleOnWriteOff = () => {
    setShowWriteOffModal(true);
  };

  const handleOnClear = () => {
    handleOnSelectAll(false, []);
  };

  // Taking actions on row events
  const rowEvents = {
    onClick: (
      _: SyntheticEvent<Element, Event>,
      row: BANKING.IIndividualTransactionResult
    ) => {
      navigate(
        `/${activeOrgId}/${routeConfig.PAYMENTS.layout}/${routeConfig.PAYMENTS.transactions.path}/${row?.order_id}`
      );
    },
  };

  const rowEventWriteOff = {
    onClick: (
      _: SyntheticEvent<Element, Event>,
      row: BANKING.IWriteOffTransaction
    ) => {
      navigate(
        `/${activeOrgId}/${routeConfig.PAYMENTS.layout}/${routeConfig.PAYMENTS.transactions.path}/${row?.order_id}`
      );
    },
  };

  // Columns
  const Failed_ach_columns = [
    {
      dataField: 'is_retryable',
      text: '',
      formatter: (_: boolean, row: BANKING.IIndividualTransactionResult) => {
        if (!row.is_retryable) {
          return (
            <>
              <i
                id={`${row.id}-tooltip`}
                className={classNames(
                  'ph-bold ph-x align-items-center',
                  styles.retryAbleCrossIcon
                )}
              />
              <UncontrolledTooltip
                modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
                placement="bottom-start"
                target={`${row.id}-tooltip`}
              >
                {notRetryableReason(row)}
              </UncontrolledTooltip>
            </>
          );
        }
        return <></>;
      },
    },
    {
      dataField: 'return_date',
      text: 'Return Date',
      sort: true,
      formatter: (cell: string) => {
        return (
          <>
            {cell
              ? formatToTz(cell, settings?.timezone, 'MMM dd, hh:mm a')
              : ''}
          </>
        );
      },
    },
    {
      dataField: 'create_date',
      text: 'Transaction Date',
      sort: true,
      formatter: (cell: string) => {
        return (
          <>
            {cell
              ? formatToTz(cell, settings?.timezone, 'MMM dd, hh:mm a')
              : ''}
          </>
        );
      },
    },
    {
      dataField: 'charge_amount',
      text: 'Gross Amount',
      sort: true,
      formatter: (cell: number) => {
        return (
          <div className="currencyColumn">
            {CURRENCY.convertToMainUnit(cell)}
          </div>
        );
      },
    },
    {
      dataField: 'customer_first_name',
      text: 'Customer',
      sort: true,
      formatter: (cell: string, row: BANKING.IIndividualTransactionResult) => {
        if (
          row.customer_first_name === row.customer_last_name &&
          cell === row.customer_last_name
        ) {
          return <>{cell}</>;
        }
        return (
          <>
            {cell} {row.customer_last_name}
          </>
        );
      },
    },
    {
      dataField: 'track_id',
      text: 'Swifter Id',
      sort: true,
    },
    {
      dataField: 'external_short_identifier',
      text: 'External Id',
      sort: true,
    },
    {
      dataField: 'actions',
      isDummyField: true,
      text: 'Actions',

      formatter: (_: any, row: BANKING.IIndividualTransactionResult) => (
        <>
          <div className="d-inline-flex align-items-center">
            <Button
              data-testid={`${row.id}_failed_transaction_single_retry_button`}
              disabled={
                !row.is_retryable || row.transaction_status === 'write_off'
              }
              id={`${row.id}-single-retry-tooltip`}
              className={
                row.is_retryable
                  ? classNames(
                      'p-0 m-0 d-flex align-items-center',
                      styles.retry_icon_btn
                    )
                  : classNames(
                      'p-0 m-0 d-flex align-items-center',
                      styles.disabled_icon_btn
                    )
              }
              onClick={(e) => {
                setSelectedRows([row]);
                handleOnRetry(row);
                e.stopPropagation();
              }}
            >
              <i className={classNames('ph-arrows-clockwise-bold icon-lg')}></i>
            </Button>
            {row.is_retryable && (
              <UncontrolledTooltip
                placement="bottom"
                target={`${row.id}-single-retry-tooltip`}
              >
                Retry
              </UncontrolledTooltip>
            )}
            <Button
              data-testid={`${row.id}-single-write-off-button`}
              id={`${row.id}-single-write-off-tooltip`}
              className={classNames(
                'p-0 ml-2 d-flex align-items-center',
                styles.write_off_icon_btn
              )}
              disabled={row.transaction_status === 'write_off'}
              onClick={(e) => {
                setIsSingleSelected(true);
                setSingleSelectedRowData([row]);
                handleOnWriteOff();
                e.stopPropagation();
              }}
            >
              <WriteOffIcon />
            </Button>
            <UncontrolledTooltip
              placement="bottom"
              target={`${row.id}-single-write-off-tooltip`}
            >
              Write-off
            </UncontrolledTooltip>
          </div>
        </>
      ),
      align: 'left',
    },
  ];
  const Cash_payment_recorded_columns = [
    {
      dataField: 'cash_recorded_date',
      text: 'Payment recorded Date',
      sort: true,
      formatter: (cell: string) => {
        return (
          <>
            {cell
              ? formatToTz(cell, settings?.timezone, 'MMM dd, hh:mm a')
              : ''}
          </>
        );
      },
    },
    {
      dataField: 'return_date',
      text: 'Return Date',
      sort: true,
      formatter: (cell: string) => {
        return (
          <>
            {cell
              ? formatToTz(cell, settings?.timezone, 'MMM dd, hh:mm a')
              : ''}
          </>
        );
      },
    },
    {
      dataField: 'create_date',
      text: 'Transaction Date',
      sort: true,
      formatter: (cell: string) => {
        return (
          <>
            {cell
              ? formatToTz(cell, settings?.timezone, 'MMM dd, hh:mm a')
              : ''}
          </>
        );
      },
    },
    {
      dataField: 'charge_amount',
      text: 'Gross Amount',
      sort: true,
      formatter: (cell: number) => {
        return (
          <div className="currencyColumn">
            {CURRENCY.convertToMainUnit(cell)}
          </div>
        );
      },
    },
    {
      dataField: 'customer_first_name',
      text: 'Customer',
      sort: true,
      formatter: (cell: string, row: BANKING.IIndividualTransactionResult) => {
        if (
          row.customer_first_name === row.customer_last_name &&
          cell === row.customer_last_name
        ) {
          return <>{cell}</>;
        }
        return (
          <>
            {cell} {row.customer_last_name}
          </>
        );
      },
    },
    {
      dataField: 'track_id',
      text: 'Swifter Id',
      sort: true,
    },
    {
      dataField: 'external_short_identifier',
      text: 'External Id',
      sort: true,
    },
  ];
  const Write_offs_columns = [
    {
      dataField: 'write_off_date',
      text: 'Write Off Date',
      sort: true,
      formatter: (cell: string) => {
        return (
          <>
            {cell
              ? formatToTz(cell, settings?.timezone, 'MMM dd, hh:mm a')
              : ''}
          </>
        );
      },
    },
    {
      dataField: 'return_date',
      text: 'Return Date',
      sort: true,
      formatter: (cell: string) => {
        return (
          <>
            {cell
              ? formatToTz(cell, settings?.timezone, 'MMM dd, hh:mm a')
              : ''}
          </>
        );
      },
    },
    {
      dataField: 'transaction_date',
      text: 'Transaction Date',
      sort: true,
      formatter: (cell: string) => {
        return (
          <>
            {cell
              ? formatToTz(cell, settings?.timezone, 'MMM dd, hh:mm a')
              : ''}
          </>
        );
      },
    },
    {
      dataField: 'amount',
      text: 'Gross Amount',
      sort: true,
      formatter: (cell: number) => {
        return (
          <div className="currencyColumn">
            {CURRENCY.convertToMainUnit(cell)}
          </div>
        );
      },
    },
    {
      dataField: 'name',
      text: 'Customer',
      sort: true,
    },
    {
      dataField: 'swifter_id',
      text: 'Swifter Id',
      sort: true,
    },
    {
      dataField: 'external_id',
      text: 'External Id',
      sort: true,
    },
  ];

  return (
    <>
      <PageHeading heading={heading} subHeading={subHeading} />
      <div
        className={classNames('align-items-center mb-9', styles.summaryBanner)}
      >
        <div
          className={classNames(
            'd-flex align-items-center justify-content-between mb-1'
          )}
        >
          <span>{"Total due from failed ACH's"}</span>
          <span>
            {CURRENCY.convertToMainUnit(
              banking?.failed_transaction_summary?.total_failed
            )}
          </span>
        </div>
        <div
          className={classNames(
            'd-flex align-items-center justify-content-between mb-1'
          )}
        >
          <span>Total cash payments recorded</span>
          <span>
            {CURRENCY.convertToMainUnit(
              banking?.failed_transaction_summary?.total_cash_recorded
            )}
          </span>
        </div>
        <div
          className={classNames(
            'd-flex align-items-center justify-content-between'
          )}
        >
          <span>Total written-off amount</span>
          <span>
            {CURRENCY.convertToMainUnit(
              banking?.failed_transaction_summary?.total_write_off
            )}
          </span>
        </div>
      </div>
      <div className={classNames(styles.tabContainer)}>
        <Nav tabs>
          {tabNames.map((item) => {
            return (
              <>
                <NavItem>
                  <NavLink
                    className={classNames({
                      active: item?.label === activeTab,
                    })}
                    onClick={() => {
                      setActiveTab(item?.label);
                      navigate({
                        search: `?tab=${item.urlParam}`,
                      });
                    }}
                  >
                    {item?.label}
                    {item?.tooltip && (
                      <>
                        <span
                          id={`${item?.label.split('')[0]}-tooltip`}
                          className="pl-1"
                        >
                          <i className="ph-info-fill icon-md text-muted" />
                        </span>
                        <UncontrolledTooltip
                          placement="right"
                          target={`${item?.label.split('')[0]}-tooltip`}
                        >
                          {item?.tooltip}
                        </UncontrolledTooltip>
                      </>
                    )}
                  </NavLink>
                </NavItem>
              </>
            );
          })}
        </Nav>
        <TabContent activeTab={activeTab}>
          <div className="d-flex mb-2">
            {selectedRows.length > 0 ? (
              <FailedPaymentSelectOptions
                numberSelected={selectedRows.length}
                onRetry={() => handleOnRetry()}
                onWriteOff={handleOnWriteOff}
                onClear={handleOnClear}
              />
            ) : (
              <span className="mr-auto">
                <Search queryParams={location.search} />
              </span>
            )}
            <span>
              <Filter
                config={filterConfig}
                queryParams={location.search}
              ></Filter>
            </span>
          </div>
          <TabPane tabId={tabNames[0].label} key={tabNames[0].label}>
            <BootstrapTable
              data-testid="failed_ach_table"
              id={'failed_ach_table'}
              wrapperClasses="table-responsive table-sticky-header-60"
              keyField="id"
              data={failedTransactions || []}
              columns={Failed_ach_columns}
              defaultSorted={defaultSorted}
              rowEvents={rowEvents}
              condensed
              bordered={false}
              noDataIndication={
                <p className="text-center text-regular">No results found</p>
              }
              selectRow={{
                mode: 'checkbox',
                selected: selectedRows.map((r) => r.id),
                clickToSelect: true,
                onSelect: handleOnSelect,
                onSelectAll: handleOnSelectAll,
              }}
            />
          </TabPane>
          <TabPane tabId={tabNames[1]?.label} key={tabNames[1].label}>
            <BootstrapTable
              data-testid="cash_payments_table"
              id={tabNames[1].label}
              wrapperClasses="table-responsive"
              keyField="id"
              data={failedTransactions || []}
              columns={Cash_payment_recorded_columns}
              defaultSorted={defaultSorted}
              rowEvents={rowEvents}
              hover
              condensed
              bordered={false}
              noDataIndication={
                <p className="text-center text-regular">No results found</p>
              }
            />
          </TabPane>
          <TabPane tabId={tabNames[2]?.label} key={tabNames[2].label}>
            <BootstrapTable
              data-testid="writeoff_transactions_table"
              id={tabNames[2].label}
              wrapperClasses="table-responsive"
              keyField="id"
              data={WriteOffTransactions || []}
              columns={Write_offs_columns}
              defaultSorted={defaultSorted}
              rowEvents={rowEventWriteOff}
              hover
              condensed
              bordered={false}
              noDataIndication={
                <p className="text-center text-regular">No results found</p>
              }
            />
          </TabPane>
          <Pagination
            results={numResults}
            prevPage={prevPage}
            nextPage={nextPage}
            prevKey={prevKey}
            nextKey={nextKey}
          />
        </TabContent>
        <ToastContainer
          showToast={Boolean(toastType)}
          onClose={() => {
            setToastType(null);
            setToastTxnId(undefined);
          }}
          message={getToastMessage()}
          details={getToastSubDetails()}
          id={toastType?.toLowerCase() || ''}
        />
        {showRetryModal && (
          <MultiRetryModal
            showModal={showRetryModal}
            setShowModal={setShowRetryModal}
            data={selectedRows}
          />
        )}
        {showWriteOffModal && (
          <MultiWriteOffModal
            showModal={showWriteOffModal}
            toShowToast={() => {
              if (isSingleSelected || selectedRows.length === 1) {
                {
                  setToastType(TOAST_TYPE.SINGLE_WRITEOFF);
                  const transactionId =
                    selectedRows.length === 1
                      ? selectedRows?.[0]?.order_id
                      : singleSelectedRowData.length === 1
                      ? singleSelectedRowData?.[0]?.order_id
                      : undefined;
                  setToastTxnId(transactionId);
                }
              } else setToastType(TOAST_TYPE.WRITE_OFF);
            }}
            setShowModal={(arg) => {
              if (!arg) {
                setIsSingleSelected(false);
                if (activeTab === tabNames[0].label) {
                  setTimeout(() => {
                    toFetchFailedTransactions({
                      type: BANKING.BankingFailedTxnType.FAILED_ACH,
                    });
                  }, 1000);
                }
              }
              setShowWriteOffModal(arg);
            }}
            data={isSingleSelected ? singleSelectedRowData : selectedRows}
          />
        )}
      </div>
    </>
  );
};

export default FailedTransactionsList;
