import React from 'react';
import { BANKING } from 'state/banking/type';
import { PAYMENTS } from 'state/payment/type';
import CURRENCY from 'utils/currency';

export const transactionSourceFormatter = (
  cell: string | undefined
): JSX.Element => {
  if (cell === PAYMENTS.ITransactionSource.PAYMENTLINK) {
    return <>Payment Link</>;
  }
  if (cell === PAYMENTS.ITransactionSource.INSTORE) {
    return <>In-Store</>;
  }
  // If source is 'web'
  return <>E-Commerce</>;
};

export const parseDeletedCustomerName = (customerName: string): string => {
  return customerName.split(' ')[0];
};

export const bankingSourceFormatter = (cell: string | undefined): string => {
  if (cell === PAYMENTS.ITransactionSource.PAYMENTLINK) {
    return 'Payment Link';
  }
  if (cell === PAYMENTS.ITransactionSource.INSTORE) {
    return 'In-Store';
  }
  // If source is 'web'
  return 'E-Commerce';
};

export const payoutTypeFormatter = (cell: string | undefined): JSX.Element => {
  if (cell === BANKING.IPayoutType.EXTERNAL_PAYOUT) {
    return <>External Payout</>;
  }
  if (cell === BANKING.IPayoutType.SELF_PAYOUT) {
    return <>Self Payout</>;
  }
  // Default type
  return <>Self Payout</>;
};

export const bankingTypeFormatter = (
  type: string,
  direction: string | undefined,
  values: BANKING.IBankStatement
): string => {
  if (direction === 'debit') {
    if (type === BANKING.OutgoingTypes.FEE) {
      return 'Fee';
    }
    if (type === BANKING.OutgoingTypes.SELF_PAYOUT) {
      return 'Self Payout';
    }
    if (type === BANKING.OutgoingTypes.EXTERNAL_PAYOUT) {
      return 'External Payout';
    }
    if (type === BANKING.OutgoingTypes.RETURN) {
      return 'Return';
    }
    if (type === BANKING.OutgoingTypes.REFUND) {
      return 'Refund';
    }
    return 'Other outgoing';
  }
  if (direction === 'credit') {
    if (!values.resource) return 'Other incoming';
    if (values.resource === 'CHARGE') return 'Retail income';
    return 'Other incoming';
  }
  return '';
};

export const bankingDescriptionFormatter = (
  values: BANKING.IBankStatement
): string | JSX.Element => {
  const { resource, direction, resource_object } = values;

  if (direction === 'credit') {
    if (resource_object && 'source' in resource_object)
      return (
        <>Source of payment: {bankingSourceFormatter(resource_object.source)}</>
      );
  }
  if (resource === BANKING.OutgoingTypes.REFUND)
    return (
      <>
        <span>Initiated towards **** {values.account_number_last4} </span>
      </>
    );

  switch (resource) {
    case BANKING.OutgoingTypes.SELF_PAYOUT:
    case BANKING.OutgoingTypes.EXTERNAL_PAYOUT:
      return (
        <>
          {values.account_number_last4 && (
            <>
              <span>
                Paid to **** {values.account_number_last4}, initiated by{' '}
                {values?.resource_object?.creator_user?.name}
              </span>
              {values.resource_object?.customer_first_name && (
                <span className="text-capitalize">
                  {values.resource_object.customer_first_name}
                </span>
              )}
            </>
          )}
        </>
      );
    case BANKING.OutgoingTypes.FEE:
      if (values.resource_object !== null && "type" in values?.resource_object) {
        return (
          <>
            <span>Fees collected for Tx ID </span>
            {values.resource_object.id && (
              <span className="text-uppercase">
                {values.id} : {CURRENCY.convertToMainUnit(values.amount)}
              </span>
            )}
          </>
        );
      }

      return (
        <>
          <span>Fees collected for Tx ID </span>
          {values.id && (
            <span className="text-uppercase">
              {values.resource_object?.external_short_identifier || values.resource_object?.external_id} :{" "}
              {CURRENCY.convertToMainUnit(values.amount)}
            </span>
          )}
        </>
      );
    case BANKING.OutgoingTypes.RETURN:
      return (
        <>
          Refund
          <span className="text-uppercase ml-1 mr-1">{values.id}</span>
          failed with
          {values.resource_object?.return_code && (
            <>
              {` with Return code`}
              <span className="text-uppercase ml-1">
                {values.resource_object.return_code}
              </span>
            </>
          )}
        </>
      );
    default:
      return <></>;
  }
};
