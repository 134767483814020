import React from 'react';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import moment, { Moment } from 'moment';
import styles from './styles.module.scss';
import { isSameDay } from 'utils/date-format';

interface IProps {
  setStartDatetime: React.Dispatch<React.SetStateAction<moment.Moment>>;
  setEndDatetime: React.Dispatch<React.SetStateAction<moment.Moment>>;
  initialStartValue?: Moment;
  initialEndValue?: Moment;
}

const DateTimeRangePicker = ({
  initialStartValue,
  initialEndValue,
  setStartDatetime,
  setEndDatetime,
}: IProps) => {
  const startDatetime = initialStartValue || moment(new Date()).startOf('day');
  const endDatetime =
    initialEndValue || moment(new Date()).subtract(30, 'days').endOf('day');
  const now = new Date();
  const maxDate = moment(now).endOf('day');

  const applyCallback = (startDate: Moment, endDate: Moment) => {
    setStartDatetime(startDate);
    setEndDatetime(endDate);
  };

  const ranges = {
    Today: [moment(now).startOf('day'), moment(now).endOf('day')],
    Yesterday: [
      moment(now).subtract(1, 'days').startOf('day'),
      moment(now).subtract(1, 'days').endOf('day'),
    ],
    'Last 7 Days': [
      moment(now).subtract(7, 'days').startOf('day'),
      moment(now).subtract(1, 'days').endOf('day'),
    ],
    'Last 30 Days': [
      moment(now).subtract(30, 'days').startOf('day'),
      moment(now).endOf('day'),
    ],
    'Last 90 Days': [
      moment(now).subtract(90, 'days').startOf('day'),
      moment(now).endOf('day'),
    ],
    'Last week': [
      moment(now).subtract(1, 'weeks').startOf('week'),
      moment(now).subtract(1, 'weeks').endOf('week'),
    ],
    'Last month': [
      moment(now).subtract(1, 'months').startOf('month'),
      moment(now).subtract(1, 'months').endOf('month'),
    ],
  };

  const local = {
    format: 'MM/DD/YYYY hh:mm A',
    sundayFirst: true,
  };

  const style = {
    betweenDates: { color: '#333D4E', backgroundColor: '#F2F3F4' },
    hoverCell: { color: '#333D4E', backgroundColor: '#F2F3F4' },
    customRangeButtons: { backgroundColor: '#0055E5', color: '#FFFFFF' },
    fromDate: { backgroundColor: '#0055E5', color: '#FFFFFF' },
    toDate: { backgroundColor: '#0055E5', color: '#FFFFFF' },
    customRangeSelected: { backgroundColor: '#0055E5', color: '#FFFFFF' },
    standaloneLayout: { display: 'flex', maxWidth: 'fit-content' },
  };

  const getDisplayDateRange = () => {
    const dateFormat = 'MMM DD, YYYY';
    const timeFormat = 'hh:mm A';
    const datetimeFormat = 'MMM DD, YYYY hh:mm A';

    if (isSameDay(startDatetime, endDatetime)) {
      return `${moment(startDatetime).format(dateFormat)} ${moment(
        startDatetime
      ).format(timeFormat)} - ${moment(endDatetime).format(timeFormat)}`;
    }

    return `${moment(startDatetime).format(datetimeFormat)} - ${moment(
      endDatetime
    ).format(datetimeFormat)}`;
  };

  return (
    <div className={styles.datetimeRangePicker}>
      <DateTimeRangeContainer
        ranges={ranges}
        start={startDatetime}
        end={endDatetime}
        local={local}
        applyCallback={applyCallback}
        noMobileMode
        twelveHoursClock
        style={style}
        linkedCalendars={true}
        showDropdowns={false}
        maxDate={maxDate}
        smartMode
        pastSearchFriendly
      >
        <div>
          <i className="ph-calendar-blank"></i>
          <div className={styles.datetimeRangePickerInput}>
            {getDisplayDateRange()}
          </div>
        </div>
      </DateTimeRangeContainer>
    </div>
  );
};

export default DateTimeRangePicker;
