import React from 'react';
import CURRENCY from 'utils/currency';
import { PAYMENTS } from 'state/payment/type';
import styles from '../summary.module.scss';
import classNames from 'classnames';

interface IProps {
  row: PAYMENTS.TipByPaymentTypeAggregateItem;
}

const TipssAccordion = ({ row }: IProps): JSX.Element => {
  return (
    <>
      <div>
        {row.tip_breakup.map((value, index, array) => {
          return (
            <p
              className={classNames(
                styles.dropDownInfo,
                styles.dropDownTipSummary
              )}
            >
              <span>{value.payment_type === 'ATM' ? "CATM": value.payment_type}</span>
              <span>
                {CURRENCY.convertToMainUnit(value.tip_amount) || '$0.00'}
              </span>
              <span>{value.number_of_tips}</span>
              <span>
                {CURRENCY.convertToMainUnit(value.average_tip_amount) ||
                  '$0.00'}
              </span>
            </p>
          );
        })}
      </div>
    </>
  );
};

export default TipssAccordion;
