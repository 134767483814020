import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';
import classNames from 'classnames';
import styles from '../filters/payment-filters.module.scss';
import { STATS } from 'state/stats/type';
import useQuery from 'utils/hooks/useQuery';
import QUERY from 'utils/query';

const DashboardPaymentMethodFilter = ({}) => {
  const query = useQuery(location.search);
  const defaultMethod = STATS.IPaymentType.ALL;
  const [selected, setSelected] = useState(defaultMethod);
  const navigate = useNavigate();
  const options: Record<STATS.IPaymentType, string> = {
    [STATS.IPaymentType.ALL]: 'All',
    [STATS.IPaymentType.ACH]: 'ACH',
    [STATS.IPaymentType.CATM]: 'CATM',
    [STATS.IPaymentType.DEBIT]: 'Debit',
  };

  const handleSelect = (option: React.SetStateAction<STATS.IPaymentType>) => {
    setSelected(option);
    navigate({
      search: QUERY.update(location.search, {
        method: option,
      }),
    });
  };

  useEffect(() => {
    const queryMethod = query?.get('method');
    const defaultSelect =
      queryMethod && queryMethod in STATS.IPaymentType
        ? (queryMethod as STATS.IPaymentType)
        : STATS.IPaymentType.ALL;
    setSelected(defaultSelect);

    navigate({
      search: QUERY.update(location.search, {
        method: defaultSelect,
      }),
    });
  }, []);

  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle
          className={classNames(
            styles.dropdownToggle,
            'd-inline-flex align-items-center btn-sm'
          )}
        >
          <i
            className={classNames(
              styles.summaryByDropdownHeadingIcon,
              'ph-funnel-simple mr-1 icon-xl'
            )}
          />
          <span className={styles.summaryByDropdownHeadingText}>
            <b className={classNames(styles.paymentTypeText)}>Payment type: </b>
            <b className={classNames(styles.optionText)}>
              {options[selected as STATS.IPaymentType]}
            </b>
          </span>
          <i
            className={classNames(
              styles.summaryByDropdownHeadingIcon,
              'ph-caret-down-light ml-1 icon-lg'
            )}
          ></i>
        </DropdownToggle>
        <DropdownMenu left className={classNames(styles.summaryByDropdownMenu)}>
          {Object.keys(options).map((option) => (
            <DropdownItem
              key={option}
              onClick={() => handleSelect(option as STATS.IPaymentType)}
              className={classNames(
                styles.summaryByDropdownHeader,
                selected === option
                  ? styles.summaryByDropdownHeaderSelected
                  : ''
              )}
            >
              <div className={classNames(styles.summaryByDropdownItem)}>
                {options[option as STATS.IPaymentType]}
              </div>
              {selected === option ? (
                <i className={classNames('ph-bold ph-check text-muted')} />
              ) : (
                <></>
              )}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

export default DashboardPaymentMethodFilter;
