import * as FullStory from '@fullstory/browser'; // skipcq: JS-C1003
import Intercom from '@intercom/messenger-js-sdk';
import { ENV, FULLSTORY_ORG_ID, INTERCOM_KEY, PENDO_KEY } from 'utils/config';

const isProd = (ENV || '') === 'production';

/* User identification for analytics */
export const identify = (
  email: string,
  visitorData?: pendo.Identity['visitor'],
  accountData?: pendo.Identity['account']
): void => {
  try {
    if (FULLSTORY_ORG_ID) FullStory.identify(email, { displayName: email });

    if (PENDO_KEY) {
      pendo?.identify({
        visitor: {
          id: email,
          ...visitorData,
        },
        account: accountData,
      });
    }

    if (INTERCOM_KEY) {
      Intercom({
        app_id: INTERCOM_KEY,
        email: email
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const event = (
  name: string,
  isSandboxMode = false,
  logTimestamp = true,
  metadata: pendo.Metadata = {}
): void => {
  // m_<event_name> : naming for merchant events

  if (logTimestamp && !metadata?.timestamp) {
    metadata.timestamp = new Date().toISOString().replace(/Z/, '+00:00');
    metadata.local_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  metadata.isSandboxMode = isSandboxMode;

  if (PENDO_KEY) pendo?.track(`m_${name}`, metadata);
};

export const reset = (): void => {
  if (isProd) {
    // TODO: Reset for analytics tools
  }
};

export const initialize = (): void => {
  try {
    if (FULLSTORY_ORG_ID)
      FullStory.init({ orgId: FULLSTORY_ORG_ID, devMode: !isProd });

    if (PENDO_KEY) pendo?.initialize();

    if (INTERCOM_KEY) {
      Intercom({
        app_id: INTERCOM_KEY
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const TRACK = {
  initialize,
  identify,
  event,
  reset,
};
