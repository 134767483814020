import classNames from 'classnames';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import useBanking from 'state/banking/hooks/useBanking';
import useAuth from 'state/auth/hooks/useAuth';
import styles from './verifyOtp.module.scss';

interface IProps {
  formSubmit: () => void;
  showError: boolean;
  setError: Dispatch<SetStateAction<boolean>>;
  otp: string;
  setOtp: Dispatch<SetStateAction<string>>;
  className?: string;
}

const VerifyOtpContainer = ({
  formSubmit,
  showError,
  setError,
  otp,
  setOtp,
  className = '',
}: IProps): JSX.Element => {
  const [allowResend, setAllowResend] = useState(true);
  const [showTimer, setShowTimer] = useState(false);
  const handleOtpChange = (code: string) => {
    setOtp(code);
  };
  const [timeLeft, setTimeLeft] = useState<string>('10');
  const [errorMessage, setErrorMessage] = useState(
    'Something went wrong. Please try again later!'
  );
  const { auth, resetOtp } = useAuth();
  const { banking } = useBanking();
  const { error } = auth;

  const resendOTPReset = () => {
    resetOtp();
    formSubmit();
  };

  const resendOTPforVerification = () => {
    resendOTPReset();
    setAllowResend(false);
    setShowTimer(true);
    setErrorMessage('');
  };

  const resetTimer = () => {
    setAllowResend(true);
    setShowTimer(false);
    setTimeLeft('10');
  };

  useEffect(() => {
    if (showTimer) {
      if (parseInt(timeLeft, 10) > 0 && !banking.isLoading) {
        const int = setInterval(() => {
          const tempTtime = `0${parseInt(timeLeft, 10) - 1}`;
          setTimeLeft(tempTtime);
        }, 1000);
        return () => clearInterval(int);
      }
      if (parseInt(timeLeft, 10) <= 0) {
        resetTimer();
        return () => null;
      }
    }
    return () => null;
  }, [timeLeft, banking?.isLoading]);

  useEffect(() => {
    if (banking?.auth_session?.challenge_data) {
      setTimeLeft('10');
    }
  }, [banking?.auth_session?.challenge_data]);

  useEffect(() => {
    if (
      error?.otpChallengeVerify?.error_code ===
      'MAX_CHALLENGE_VALIDATION_LIMIT_EXCEEDED'
    ) {
      setAllowResend(false);
      setShowTimer(false);
      setError(true);
      setErrorMessage('Maximum limit reached. Please try after sometime!');
    }
    if (
      error?.otpChallengeVerify?.error_code === 'CHALLENGE_VALIDATION_FAILED'
    ) {
      setErrorMessage('Invalid code! Please try again.');
      setError(true);
    }
    if (!error?.otpChallengeVerify) {
      if (!showTimer) {
        setAllowResend(true);
      }
      setErrorMessage('Something went wrong. Please try again later!');
      setError(false);
    }
  }, [error?.otpChallengeVerify]);

  useEffect(() => {
    if (showTimer) {
      if (parseInt(timeLeft, 10) > 0 && !auth.isLoading) {
        const int = setInterval(() => {
          const tempTtime = `0${parseInt(timeLeft, 10) - 1}`;
          setTimeLeft(tempTtime);
        }, 1000);
        return () => clearInterval(int);
      }
      if (parseInt(timeLeft, 10) <= 0) {
        resetTimer();
        return () => null;
      }
    }
    return () => null;
  }, [timeLeft, auth?.isLoading]);

  useEffect(() => {
    if (auth?.otp_challenge_verified?.is_authorized) {
      formSubmit();
    }
  }, [auth?.otp_challenge_verified?.is_authorized]);

  useEffect(() => {
    // get challenge id
    resetOtp();
    formSubmit();
  }, []);

  return (
    <>
      <OtpInput
        data-testid="otp-input"
        value={otp}
        isInputNum
        onChange={handleOtpChange}
        numInputs={6}
        inputStyle={
          showError ? styles['inputOtp-containerError'] : `${styles.inputOtp}`
        }
        containerStyle={className || styles['inputOtp-container']}
        hasErrored
      />

      {showError && (
        <p
          data-testid="error-text"
          className={classNames(styles.errorText, 'text-danger')}
        >
          {errorMessage}
        </p>
      )}
      {allowResend && (
        <div className="mb-4">
          <span className={styles.resendCode}>Didn&apos;t get a code?</span>
          <span className={styles.resendBtn} onClick={resendOTPforVerification}>
            Resend
          </span>
        </div>
      )}
      {showTimer && (
        <div className="mb-4">
          <span className={classNames('d-flex align-items-center')}>
            <i className="ph-arrow-clockwise mr-1 ph-bold text-muted"></i>
            <span className={styles.timer}>
              0:
              {timeLeft}
            </span>
          </span>
        </div>
      )}
    </>
  );
};
export default VerifyOtpContainer;
