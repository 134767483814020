import React from 'react';
import { Nav, NavItem, UncontrolledTooltip } from 'reactstrap';
import classNames from 'classnames';
import STRING from 'utils/string';
import { AmountChangePercentLabel } from 'utils/status-formatter';
import styles from './styles.module.scss';

interface IProps {
  id: string;
  title: string;
  value: string | number;
  onClick: () => void;
  percentChange?: number | null;
  tooltip?: JSX.Element | string;
  isActive?: boolean;
  className?: string;
}

const CardTab = ({
  id,
  title,
  tooltip,
  value,
  percentChange,
  onClick,
  isActive,
  className = '',
}: IProps): JSX.Element => {
  return (
    <NavItem
      id={id}
      className={classNames(styles.card, `p-3 m-0 ${className}`)}
      active={isActive}
      onClick={onClick}
    >
      <div className="d-flex justify-content-between align-items-center">
        <span className={styles.cardTitle}>{title}</span>
        <span className={styles.cardTooltip}>
          <i
            id={`${STRING.hyphenate(title)}-tooltip`}
            className="ph-info-fill"
          />
          <UncontrolledTooltip
            placement="right"
            target={`${STRING.hyphenate(title)}-tooltip`}
            style={{ minWidth: '270px' }}
          >
            {tooltip}
          </UncontrolledTooltip>
        </span>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <span className={styles.cardValue}>{value}</span>
        <span className={styles.cardPercent}>
          {(percentChange || percentChange === 0) && (
            <AmountChangePercentLabel value={percentChange} />
          )}
        </span>
      </div>
    </NavItem>
  );
};

const CardTabNav = ({
  children,
  className = '',
  testId,
}: {
  children: React.ReactNode;
  className?: string;
  testId?: string;
}): JSX.Element => {
  return (
    <div
      id="card-tab"
      className={classNames(styles.cardTabNav, className)}
      data-testid={testId}
    >
      <Nav tabs className={classNames('border-none mb-0')}>
        {children}
      </Nav>
    </div>
  );
};

export { CardTab, CardTabNav };
