import {
  CREATE_TERMINAL,
  CREATE_TERMINAL_FAILURE,
  CREATE_TERMINAL_SUCCESS,
  FETCH_INSTORE_CASH_DRAWER,
  FETCH_INSTORE_CASH_DRAWER_FAILURE,
  FETCH_INSTORE_CASH_DRAWER_SUCCESS,
  FETCH_INSTORE_SESSIONS,
  FETCH_INSTORE_SESSIONS_FAILURE,
  FETCH_INSTORE_SESSIONS_SUCCESS,
  FETCH_INSTORE_TERMINALS,
  FETCH_INSTORE_TERMINALS_FAILURE,
  FETCH_INSTORE_TERMINALS_SUCCESS,
  RESET,
  UPDATE_SESSION_TICKET,
  UPDATE_SESSION_TICKET_FAILURE,
  UPDATE_SESSION_TICKET_SUCCESS,
} from './actions';
import { TERMINAL } from './type';

export const INITIAL_STATE: TERMINAL.TerminalReducer = {
  isLoading: false,
  error: {} as TERMINAL.Error,
  createTerminal: false,
  isSessionUpdated: false,
  terminalResponse: {} as TERMINAL.ICreateTerminalResponse,
  inStoreTerminals: {} as TERMINAL.IInStoreTerminals,
  inStoreCashDrawers: {} as TERMINAL.IInStoreCashDrawerResult,
  instoreSessions: {} as IPaginatedResponse<TERMINAL.IInStoreSessions>,
};

const terminalReducer = (
  action: IAction,
  state = INITIAL_STATE
): TERMINAL.TerminalReducer => {
  switch (action.type) {
    case FETCH_INSTORE_TERMINALS:
      return {
        ...state,
        isLoading: true,
        inStoreTerminals: {} as TERMINAL.IInStoreTerminals,
        error: {},
      };
    case FETCH_INSTORE_TERMINALS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        inStoreTerminals: action.payload,
        error: {},
      };
    case FETCH_INSTORE_TERMINALS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { fetchInstoreTerminals: action.payload.message },
      };
    case FETCH_INSTORE_CASH_DRAWER:
      return {
        ...state,
        isLoading: true,
        inStoreCashDrawers: {} as TERMINAL.IInStoreCashDrawerResult,
        error: {},
      };
    case FETCH_INSTORE_CASH_DRAWER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        inStoreCashDrawers: action.payload,
        error: {},
      };
    case FETCH_INSTORE_CASH_DRAWER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { fetchInstoreCashDrawers: action.payload.message },
      };
    case FETCH_INSTORE_SESSIONS:
      return {
        ...state,
        isLoading: true,
        instoreSessions: {} as IPaginatedResponse<TERMINAL.IInStoreSessions>,
        error: {},
      };
    case FETCH_INSTORE_SESSIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        instoreSessions: action.payload,
        error: {},
      };
    case FETCH_INSTORE_SESSIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { updateSessionTicket: action.payload.message },
      };
    case UPDATE_SESSION_TICKET:
      return {
        ...state,
        isLoading: true,
        isSessionUpdated: false,
        error: {},
      };
    case UPDATE_SESSION_TICKET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSessionUpdated: true,
        error: {},
      };
    case UPDATE_SESSION_TICKET_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSessionUpdated: false,
        error: { updateSessionTicket: action.payload.message },
      };
    case CREATE_TERMINAL:
      return {
        ...state,
        isLoading: true,
        error: {},
        createTerminal: false,
        terminalResponse: action.payload,
      };
    case CREATE_TERMINAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createTerminal: true,
        terminalResponse: action.payload,
        error: {},
      };
    case CREATE_TERMINAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        terminalResponse: {} as TERMINAL.ICreateTerminalResponse,
        createTerminal: false,
        error: { addTerminal: action.payload.message },
      };
    case RESET:
      return {
        ...state,
        isLoading: false,
        isSessionUpdated: false,
        error: {},
      };
    default:
      return state;
  }
};
export default terminalReducer;
