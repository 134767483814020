import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import styles from './pagination.module.scss';

interface IProps {
  pageId?:string
  results: number;
  prevPage: () => void;
  nextPage: () => void;
  prevKey: string | null;
  nextKey: string | null;
}

const PAGE_SIZE = 25; // Default size in Sparc backend

const Pagination = ({
  pageId,
  results,
  prevPage,
  nextPage,
  prevKey,
  nextKey,
}: IProps): JSX.Element | null => {
  const [currentPage, setCurrentPage] = useState(0);

  const onPrevPage = () => {
    setCurrentPage(currentPage - 1);
    prevPage();
  };

  const onNextPage = () => {
    setCurrentPage(currentPage + 1);
    nextPage();
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [pageId]);

  const resultStart = currentPage * PAGE_SIZE + 1;
  const resultEnd = Math.min((currentPage + 1) * PAGE_SIZE, results);

  return (
    <div className="d-flex justify-content-between align-items-center mt-5 mb-7 w-100">
      <div className={classNames('body-subtext', styles.results)}>
        {results > 0 && (
          <>
            <span className="text-muted">Viewing </span>
            {resultStart} to {resultEnd}
            <span className="text-muted"> of </span>
            {results}
            <span className="text-muted"> results </span>
          </>
        )}
      </div>
      <div>
        <Button
          color="secondary"
          className={classNames('btn-pagination prev')}
          size="sm"
          onClick={onPrevPage}
          disabled={!prevKey}
        >
          Previous
        </Button>
        <Button
          color="secondary"
          className={classNames('btn-pagination next')}
          size="sm"
          onClick={onNextPage}
          disabled={!nextKey}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default Pagination;
