import {
  FETCH_SUMMARY_CARDS,
  FETCH_SUMMARY_CARDS_SUCCESS,
  FETCH_SUMMARY_CARDS_FAILURE,
  FETCH_GRAPH_DATA,
  FETCH_GRAPH_DATA_SUCCESS,
  FETCH_GRAPH_DATA_FAILURE,
  FETCH_CUSTOMER_INFO,
  FETCH_CUSTOMER_INFO_SUCCESS,
  FETCH_CUSTOMER_INFO_FAILURE,
  SET_SUMMARY_TAB,
  SET_INTERVAL_TYPES,
} from './actions';
import { STATS } from './type';
import { errorMessage } from '../../utils/constants';

export const INITIAL_STATE = {
  isLoading: 0,
  activeSummaryTab: STATS.graphType.gross_amount,
  intervalTypes: [] as STATS.IIntervalType[],
  summaryCard: {} as STATS.ISummaryCard,
  graph: [] as STATS.IPaymentTypeGraph[],
  customerInfo: {} as STATS.ICustomerInfo,
  error: {} as STATS.IError,
};

const statsReducer = (
  action: IAction,
  state = INITIAL_STATE
): STATS.IStatsReducer => {
  switch (action.type) {
    case FETCH_SUMMARY_CARDS:
      return {
        ...state,
        isLoading: state.isLoading + 1,
      };

    case FETCH_SUMMARY_CARDS_SUCCESS:
      return {
        ...state,
        isLoading: state.isLoading - 1,
        summaryCard: {
          ...action.payload,
          current: {
            ...action.payload.current,
            gross_volume: undefined,
            gross_amount: action.payload.current.gross_volume,
          },
          previous: {
            ...action.payload.previous,
            gross_volume: undefined,
            gross_amount: action.payload.previous.gross_volume,
          },
        },
        error: { ...state.error, summaryCard: null },
      };

    case FETCH_SUMMARY_CARDS_FAILURE:
      return {
        ...state,
        isLoading: state.isLoading - 1,
        error: {
          ...state.error,
          summaryCard: action.payload || errorMessage.GENERIC,
        },
      };

    case FETCH_GRAPH_DATA:
      return {
        ...state,
        isLoading: state.isLoading + 1,
      };

    case FETCH_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        isLoading: state.isLoading - 1,
        graph: action.payload,
        error: { ...state.error, graph: null },
      };

    case FETCH_GRAPH_DATA_FAILURE:
      return {
        ...state,
        isLoading: state.isLoading - 1,
        graph: [] as STATS.IPaymentTypeGraph[],
        error: {
          ...state.error,
          graph: action.payload || errorMessage.GENERIC,
        },
      };

    case FETCH_CUSTOMER_INFO:
      return {
        ...state,
        isLoading: state.isLoading + 1,
      };

    case FETCH_CUSTOMER_INFO_SUCCESS:
      return {
        ...state,
        isLoading: state.isLoading - 1,
        customerInfo: action.payload,
        error: { ...state.error, customerInfo: null },
      };

    case FETCH_CUSTOMER_INFO_FAILURE:
      return {
        ...state,
        isLoading: state.isLoading - 1,
        error: {
          ...state.error,
          customerInfo: action.payload || errorMessage.GENERIC,
        },
      };

    case SET_SUMMARY_TAB:
      return { ...state, activeSummaryTab: action.payload };

    case SET_INTERVAL_TYPES:
      return { ...state, intervalTypes: action.payload };

    default:
      return state;
  }
};

export default statsReducer;
