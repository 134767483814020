import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './auth.module.scss';
import classNames from 'classnames';
import LoginSSO from './login-sso';
import useAuth from 'state/auth/hooks/useAuth';
import routeConfig from 'routes/config';

const Login = (): JSX.Element => {
  const { auth } = useAuth();
  const navigate = useNavigate();

  // for redirection
  useEffect(() => {
    if (auth.loggedIn) {
      navigate(
        `${routeConfig.ROOT}${routeConfig.STORES.layout}/${routeConfig.STORES.LIST.path}`
      );
    }
  }, [auth.loggedIn]);

  return (
    <>
      <div className={classNames(styles.loginBox)}>
        <div id="SignInText" className={classNames(styles.LoginPageHeading)}>
          Log In To TreezPay
        </div>
        <LoginSSO />
        {/* <div className={classNames(styles.orContainer)}>
          <hr className={classNames(styles.signInDivider, 'my-0')} />
          <div className={classNames(styles.orText)}>OR</div>
          <hr className={classNames(styles.signInDivider, 'my-0')} />
        </div>
        <LoginSwifter /> */}
      </div>
    </>
  );
};

export default Login;
