import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';
import BootstrapTable, { SortOrder } from 'react-bootstrap-table-next';
import { formatToTz } from 'utils/date-format';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import CURRENCY from 'utils/currency';
import { PAYMENTS } from 'state/payment/type';
import { BANKING } from 'state/banking/type';
import styles from './styles.module.scss';

interface IProps {
  onRowClick: (arg: any) => void;
  data: BANKING.IIndividualTransactionResult[];
}

const WriteOffSelection = ({ onRowClick, data }: IProps) => {
  const { organisation } = useOrganisation();
  const { settings } = organisation;

  const infoText1 =
    'By writing-off the failed transactions you are aware that the amount could not be collected from the customer.';
  const infoText2 =
    "If there is a chance to recover the total amount in the future, don't worry! You can still collect money for the transactions mentioned above.";
  const infoBox = (
    <div className={classNames(styles.infoBox)}>
      <div className="d-flex">
        <i className="ph-bold ph-info-fill mr-10" />
        <div>
          <p className="mb-5">{infoText1}</p>
          <p>{infoText2}</p>
        </div>
      </div>
    </div>
  );

  const defaultSorted: [{ dataField: string; order: SortOrder }] = [
    {
      dataField: 'date',
      order: 'desc',
    },
  ];

  const rowEvents = {
    onClick: (
      _: SyntheticEvent<Element, Event>,
      row: PAYMENTS.IIndividualTransactionResult
    ) => {
      onRowClick(row);
    },
  };

  const totalWriteOffAmount = () =>
    data.reduce(
      (amount: number, value: BANKING.IIndividualTransactionResult) => {
        amount += value.charge_amount;
        return amount;
      },
      0
    );

  const columns = [
    {
      dataField: 'return_date',
      text: 'Return Date',
      sort: true,
      formatter: (cell: string) => {
        return (
          <>
            {cell
              ? formatToTz(cell, settings?.timezone, 'MMM dd, hh:mm a')
              : ''}
          </>
        );
      },
      footer: '',
    },
    {
      dataField: 'create_date',
      text: 'Transaction Date',
      sort: true,
      formatter: (cell: string) => {
        return (
          <>
            {cell
              ? formatToTz(cell, settings?.timezone, 'MMM dd, hh:mm a')
              : ''}
          </>
        );
      },
      footer: 'Total',
      footerStyle: {
        fontWeight: 600,
      },
    },
    {
      dataField: 'charge_amount',
      text: 'Gross Amount',
      sort: true,
      formatter: (cell: number) => {
        return (
          <div className="currencyColumn">
            {CURRENCY.convertToMainUnit(cell)}
          </div>
        );
      },
      footer: CURRENCY.convertToMainUnit(totalWriteOffAmount()),
    },
  ];

  return (
    <>
      <div
        className={classNames(
          styles.totalWriteOffAmountText,
          'body-subtext mt-1'
        )}
      >
        Total write-off amount
      </div>
      <div
        className={classNames(styles.totalWriteOffAmount, 'mt-1 heading-h2')}
      >
        {CURRENCY.convertToMainUnit(totalWriteOffAmount())}
      </div>
      <div className={classNames(styles.numberOfWriteOffTransactions)}>
        Writing-off{' '}
        <strong className="body-subtext-semibold pr-1">{data.length}</strong>
        transactions -
      </div>
      <BootstrapTable
        data-testid="write-off-modal-table"
        id="Write-off Modal Table"
        wrapperClasses="table-no-vertical-border"
        keyField="id"
        data={data}
        columns={columns}
        defaultSorted={defaultSorted}
        rowEvents={rowEvents}
        hover
        condensed
        bordered
        noDataIndication={
          <p className="text-center text-regular">No results found</p>
        }
      />
      {infoBox}
    </>
  );
};

export default WriteOffSelection;
