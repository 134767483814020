import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface IProps {
  heading_text: string;
  message1: string;
  message2: string;
}

const ConfirmCashPayment = ({ heading_text, message1, message2 }: IProps) => {
  return (
    <>
      <div className={classNames(styles.warningBox, 'p-1')}>
        <div className="d-flex">
          <i
            className={classNames(
              'ph-bold ph-warning-circle mr-2 pr-1',
              styles.warningIcon
            )}
          />
          <div>
            <h1 className={classNames(styles.warningBoxHeading)}>
              {heading_text}
            </h1>
            <p className={classNames(styles.warningText1)}>{message1}</p>
            <p className={classNames(styles.warningText2)}>{message2}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmCashPayment;
