import classNames from 'classnames';
import CTAPageHeading from 'components/heading/cta';
import DeleteModal from 'components/modals/delete';
import Pagination from 'components/pagination';
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import { ToolkitContextType } from 'react-bootstrap-table2-toolkit';
import { Button, Card } from 'reactstrap';
import useAuth from 'state/auth/hooks/useAuth';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import usePayment from 'state/payment/hooks/usePayment';
import { formatToTz } from 'utils/date-format';
import { APIKeysStatusFormatter } from 'utils/status-formatter';
import styles from '../developers.module.scss';
import CreateAPIKey from './createAPIKey';

const APIKeys = (): JSX.Element => {
  const [showCreateAPIKeyModal, setShowCreateAPIKeyModal] =
    useState<boolean>(false);
  const { payment, toFetchKeys, toRevokeKey } = usePayment();
  const { auth } = useAuth();
  const { isSandbox } = auth;
  const { addedKey, revokeKey, keys } = payment;
  const { organisation } = useOrganisation();
  const { settings, activeOrg } = organisation;

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteStatement = 'Are you sure you want to delete this API key?';
  const [APIkeyId, setKeyId] = useState<string>('');

  const onDeleteKeyAction = (APIkey: string) => {
    setKeyId(APIkey);
    setDeleteModal(true);
  };
  const deleteKey = () => {
    toRevokeKey(APIkeyId);
    setDeleteModal(false);
  };

  const checkStatus = (id: string) => {
    const status = keys.items?.find((keyID) => keyID.id === id)?.status;
    if (status === 'revoked') {
      return true;
    }
    return false;
  };

  useEffect(() => {
    toFetchKeys();
  }, []);

  const prevPage = () => {
    if (keys.prev_key) {
      toFetchKeys({
        key: keys.prev_key ?? null,
      });
    }
  };

  const nextPage = () => {
    if (keys.next_key) {
      toFetchKeys({
        key: keys.next_key ?? null,
      });
    }
  };

  useEffect(() => {
    if (addedKey) {
      toFetchKeys();
    }
  }, [addedKey]);

  useEffect(() => {
    if (revokeKey) {
      toFetchKeys();
    }
  }, [revokeKey]);

  const TABLE_COLUMNS = [
    { text: 'Client ID', sort: true, dataField: 'client_id' },
    {
      text: 'Partner',
      sort: true,
      dataField: 'partner_name',
      formatter: (cell: string) => {
        return <div className="text-capitalize">{cell || 'Self'}</div>;
      },
    },
    {
      text: 'Created by',
      sort: false,
      dataField: 'creator_name',
      formatter: (cell: string) => {
        return <>{cell || 'NA'}</>;
      },
    },
    {
      text: 'Created date',
      sort: true,
      dataField: 'create_date',
      formatter: (cell: string) => {
        return (
          <>
            {cell
              ? formatToTz(cell, settings?.timezone, 'MMM dd yyyy, hh:mm a')
              : ''}
          </>
        );
      },
    },
    {
      text: 'Status',
      sort: false,
      dataField: 'status',
      formatter: APIKeysStatusFormatter,
    },
    {
      text: 'Actions',
      sort: false,
      dataField: 'id',
      formatter: (client_id: string) => (
        <Button
          disabled={checkStatus(client_id)}
          type="button"
          color="link"
          size="sm"
          onClick={() => onDeleteKeyAction(client_id)}
        >
          <i className="ph-trash icon-lg text-muted" />
        </Button>
      ),
    },
  ];

  const heading = 'API Keys';
  const subHeading = 'These keys will allow you to authenticate API requests.';

  const CreateAPIKeyButton = (
    <Button
      color="primary"
      size="sm"
      onClick={() => setShowCreateAPIKeyModal(true)}
      className={classNames(styles.addKeyBtn)}
    >
      <i
        className={classNames(styles.addKeyBtn, 'ph-plus-fill icon-lg pr-1')}
      />
      Create {isSandbox ? 'test' : ''} API key
    </Button>
  );

  return (
    <>
      <Card className="card-profile border-0">
        <CTAPageHeading
          heading={heading}
          subHeading={subHeading}
          cta={(<></>)}
        />
        <div
          className={classNames(styles.sandboxToggleBox, 'd-flex mb-3')}
          style={{ backgroundColor: '#FAFAFB', height: '52px' }}
        >
          <i className={classNames('ph-info-fill icon-lg pr-2 text-muted')} />
          <span className={classNames(styles.sandboxInfo, 'mr-auto')}>
            You are viewing {isSandbox ? 'test' : 'live'} API Keys. Toggle to
            view {isSandbox ? 'live' : 'test'} keys.
          </span>
        </div>
        <ToolkitProvider
          keyField="_id"
          search
          data={keys.items || []}
          columns={TABLE_COLUMNS}
        >
          {(props: ToolkitContextType) => (
            <div>
              <BootstrapTable
                wrapperClasses="table-responsive"
                {...props.baseProps}
                hover
                condensed
                bordered={false}
                noDataIndication={
                  <p className="text-center text-regular">No results found</p>
                }
              />
              <Pagination
                results={keys.total_count}
                prevPage={prevPage}
                nextPage={nextPage}
                prevKey={keys.prev_key ?? null}
                nextKey={keys.next_key ?? null}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
      <DeleteModal
        showModal={deleteModal}
        setShowModal={setDeleteModal}
        tag="Secret key"
        warningStatement={deleteStatement}
        action={deleteKey}
      />
      {showCreateAPIKeyModal && (
        <CreateAPIKey
          openModal={showCreateAPIKeyModal}
          setModal={setShowCreateAPIKeyModal}
          isPartner={Boolean(activeOrg?.is_partner)}
        />
      )}
    </>
  );
};

export default APIKeys;
