import classNames from 'classnames';
import BackButton from 'components/backButton';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Tooltip,
} from 'reactstrap';
import useConsumer from 'state/consumer/hooks/useConsumer';
import { CONSUMERS } from 'state/consumer/type';
import CURRENCY from 'utils/currency';
import { customerStatusFormatter } from 'utils/status-formatter';
import styles from '../customers.module.scss';
import CustomerDetailsActions from './actions';
import CustomerNotFound from './not-found';
import CustomerDetailsSessions from './sessions';
import CustomerDetailsTransactions from './transactions';

/* Customer Details page */
const CustomerDetails = (): JSX.Element => {
  const { consumers, toFetchIndividualCustomer } = useConsumer();
  const { individualCustomer } = consumers;

  const [activeTab, setActiveTab] = useState('1');
  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { id: consumerProfileId } = useParams<{ id: string }>();

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleSpent = () => setTooltipOpen(!tooltipOpen);

  const {
    email,
    first_name: firstName,
    last_name: lastName,
    phone,
    spent_amount: spent,
  } = individualCustomer?.profile ?? {};

  useEffect(() => {
    if (consumerProfileId) toFetchIndividualCustomer(consumerProfileId);
  }, []);

  return individualCustomer?.profile ? (
    <>
      <Card className="card-profile border-0">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <BackButton />
          <CustomerDetailsActions />
        </div>
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          target="TooltipExample"
          toggle={toggleSpent}
        >
          {CURRENCY.convertToMainUnit(spent)} spent
          <br></br>
          This value is calculated once per day. Adjusted for refunds and
          disputes
        </Tooltip>
        <div className={styles.customer_details}>
          <div className={styles.customer_details_left}>
            <div className="mb-3">
              <h3 className="d-flex align-items-center justify-content-between mr-1">
                <span
                  className={styles.customerName}
                >{`${firstName} ${lastName}`}</span>
                {individualCustomer?.profile?.status && (
                  <span className="pl-2 ">
                    {customerStatusFormatter(individualCustomer.profile.status)}
                  </span>
                )}
              </h3>
            </div>
            <div className="mb-5">
              <div className={classNames(styles.CustomerDetailsSpent)}>
                Spent
              </div>
              <div
                className={classNames(styles.CustomerDetailsSpentValue)}
                id="TooltipExample"
              >
                {CURRENCY.convertToMainUnit(spent)}
              </div>
            </div>
            <hr className="my-0" />
            <div className="mt-3">
              <div
                className={classNames(
                  styles.CustomerDetailsSubHeading,
                  'd-flex align-items-center mb-1'
                )}
              >
                <i className="ph-envelope-thin" />
                <span>{email}</span>
              </div>
              <div
                className={classNames(
                  styles.CustomerDetailsSubHeading,
                  'd-flex align-items-center mb-1'
                )}
              >
                <i className="ph-phone-thin" />
                <span>{phone}</span>
              </div>
              <div
                className={classNames(
                  styles.CustomerDetailsSubHeading,
                  'd-flex align-items-top mb-1'
                )}
              >
                <i
                  className={classNames(
                    styles.ConsumerIdIcon,
                    'ph-identification-card-thin'
                  )}
                ></i>
                <span>{consumerProfileId}</span>
              </div>
            </div>
          </div>
          <div className={styles.customer_details_right}>
            <Nav tabs className="mb-7">
              <NavItem>
                <NavLink
                  className={classNames({ active: activeTab === '1' })}
                  onClick={() => {
                    toggle('1');
                  }}
                >
                  Overview
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classNames({ active: activeTab === '2' })}
                  onClick={() => {
                    toggle('2');
                  }}
                >
                  Sessions and logs
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <CustomerDetailsTransactions
                  customerProfileId={consumerProfileId}
                />
                <div>
                  <h3
                    className={classNames(
                      styles.customer_details_heading,
                      'm-0'
                    )}
                  >
                    Payment methods
                  </h3>
                  <hr className="mt-1 mb-3" />
                  {individualCustomer.accounts &&
                  individualCustomer.accounts.length > 0 ? (
                    individualCustomer.accounts.map(
                      (item: CONSUMERS.ICustomerAccount) => {
                        return (
                          <div
                            className={classNames(
                              styles.CustomerDetailsPaymentMethod,
                              'd-flex mb-2'
                            )}
                          >
                            <div className="d-flex align-items-center justify-content-center mr-3">
                              <i className="ph-bank"></i>
                            </div>
                            <div>
                              <div
                                className={classNames(
                                  styles.CustomerDetailsBankHeading
                                )}
                              >
                                {item.bank_name}
                              </div>
                              <div
                                className={classNames(
                                  styles.CustomerDetailsBankDate
                                )}
                              >
                                {item.account_name}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )
                  ) : (
                    <div className={classNames(styles.noPaymentMethods)}>
                      No payment methods
                    </div>
                  )}
                </div>
              </TabPane>
              <TabPane tabId="2">
                <CustomerDetailsSessions
                  customerProfileId={consumerProfileId}
                />
                <div>
                  <h3
                    className={classNames(
                      styles.customer_details_heading,
                      'm-0'
                    )}
                  >
                    Logs
                  </h3>
                  <hr className="mt-1 mb-2" />
                  <div className={styles.noPaymentMethods}>No logs</div>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Card>
    </>
  ) : (
    <CustomerNotFound />
  );
};

export default CustomerDetails;
