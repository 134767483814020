import React from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import { STATS } from 'state/stats/type';
import styles from './summary.module.scss';

interface IProps {
  enabledFreq: STATS.IIntervalType[];
  activeFreq: STATS.IIntervalType;
  setActiveFreq: React.Dispatch<React.SetStateAction<STATS.IIntervalType>>;
}

const FrequencyButtonGroup = ({
  enabledFreq,
  activeFreq,
  setActiveFreq,
}: IProps) => {
  const { IIntervalType: INTERVAL } = STATS;

  return (
    <div className={classNames(styles.freqButtonGroup, 'd-flex')}>
      <Button
        id="freq-daily"
        data-testid="freq-daily"
        onClick={() => setActiveFreq(INTERVAL.DAILY)}
        disabled={!enabledFreq.includes(INTERVAL.DAILY)}
        variant="secondary"
        className={activeFreq === INTERVAL.DAILY ? 'btn-freq-selected' : ''}
      >
        Daily
      </Button>
      <Button
        id="freq-weekly"
        data-testid="freq-weekly"
        onClick={() => setActiveFreq(INTERVAL.WEEKLY)}
        disabled={!enabledFreq.includes(INTERVAL.WEEKLY)}
        variant="secondary"
        className={activeFreq === INTERVAL.WEEKLY ? 'btn-freq-selected' : ''}
      >
        Weekly
      </Button>
      <Button
        id="freq-monthly"
        data-testid="freq-monthly"
        onClick={() => setActiveFreq(INTERVAL.MONTHLY)}
        disabled={!enabledFreq.includes(INTERVAL.MONTHLY)}
        variant="secondary"
        className={activeFreq === INTERVAL.MONTHLY ? 'btn-freq-selected' : ''}
      >
        Monthly
      </Button>
    </div>
  );
};

export default FrequencyButtonGroup;
