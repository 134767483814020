import React from 'react';
import BootstrapTable, {
  ExpandColumnRendererProps,
} from 'react-bootstrap-table-next';
import classNames from 'classnames';
import * as util from 'utils/dashboard'; // skipcq: JS-C1003
import * as utils from 'utils/summary'; // skipcq: JS-C1003
import CURRENCY from 'utils/currency';
import { SUMMARY } from 'state/summary/type';
import { formatDate } from '../../../utils/date-format';
import styles from '../../tips/tips.module.scss';
import NoSummaryFound from '../../../components/summary-tab/no-summary-found';
import { PAYMENTS } from 'state/payment/type';
import TotalTipSummary from './total-tip-summary';
interface IProps {
  summaryIntervalData: PAYMENTS.TipByPaymentTypeAggregateItem[];
  summaryTotalData: PAYMENTS.ISummaryData;
  expandRowFormatter: (
    items: PAYMENTS.TipByPaymentTypeAggregateItem
  ) => JSX.Element;
  activeFreq: SUMMARY.IIntervalType;
  comparisonDate: string[];
}

const TipSummaryByPaymentType = ({
  summaryIntervalData,
  summaryTotalData,
  expandRowFormatter,
  activeFreq,
  comparisonDate,
}: IProps): JSX.Element => {
  const TipBy = () => {
    if (activeFreq === SUMMARY.IIntervalType.DAILY) {
      return 'Day';
    } else if (activeFreq === SUMMARY.IIntervalType.WEEKLY) {
      return 'Week';
    } else if (activeFreq === SUMMARY.IIntervalType.MONTHLY) {
      return 'Month';
    }
    return 'Hour';
  };

  const columns = [
    {
      dataField: 'date',
      text: `Tips by "${TipBy()}"`,
      headerStyle: {
        height: '64px',
        fontSize: '16px',
        lineHeight: '24px',
        paddingLeft: '0px',
        background: '#ffffff',
      },
      headerFormatter: () => (
        <span style={{ textTransform: 'none' }}>{`Tips by "${TipBy()}"`}</span>
      ),
      formatter: (cell: string) => {
        return (
          <>
            {cell && (
              <div>
                <span className={classNames(styles.tableDateText)}>
                  {activeFreq === SUMMARY.IIntervalType.DAILY
                    ? `on ${formatDate(cell, 'dd MMM, yyyy')}`
                    : activeFreq === SUMMARY.IIntervalType.WEEKLY
                    ? `from (${util.getTooltipWeekRange(
                        new Date(cell),
                        'dd MMM, yyyy'
                      )})`
                    : `from (${utils.getMonthlyRange(new Date(cell))})`}
                </span>
              </div>
            )}
          </>
        );
      },
      style: {
        width: '32%',
        paddingLeft: '0px',
        height: '52px',
      },
    },
    {
      dataField: 'value.tip_amount',
      text: '',
      headerStyle: {
        background: '#ffffff',
      },
      formatter: (cell: number) => {
        return (
          <>
            <div
              className={classNames(
                'currencyColumn text-left',
                styles.currencyRowColumnTipSummary
              )}
            >
              {cell ? CURRENCY.convertToMainUnit(cell) : '$0.00'}
            </div>
          </>
        );
      },
      style: {
        width: '21.67%',
      },
    },
    {
      dataField: 'value.number_of_tips',
      text: '',
      headerStyle: {
        background: '#ffffff',
      },
      style: {
        width: '21.67%',
      },
    },
    {
      dataField: 'value.average_tip_amount',
      text: '',
      headerStyle: {
        background: '#ffffff',
      },
      formatter: (cell: number) => {
        return (
          <>
            <div
              className={classNames(
                'currencyColumn text-left',
                styles.currencyRowColumnTipSummary
              )}
            >
              {cell ? CURRENCY.convertToMainUnit(cell) : '$0.00'}
            </div>
          </>
        );
      },
      style: {
        width: '21.67%',
      },
    },
  ];

  const expandRow = {
    className: 'dropdownArea',
    renderer: (row: PAYMENTS.TipByPaymentTypeAggregateItem) => {
      return <div className="w-100 pl-0">{expandRowFormatter(row)}</div>;
    },
    expandHeaderColumnRenderer: () => {
      return <></>;
    },
    expandColumnRenderer: ({ expanded }: ExpandColumnRendererProps) => {
      if (expanded) {
        return <i className="ph-caret-up-fill icon-md"></i>;
      }
      return <i className="ph-caret-down-fill icon-md"></i>;
    },
    showExpandColumn: true, // Set this to false to remove expand column from the header
    expandColumnPosition: 'left' as const,
  };

  return (
    <div
      className={classNames(
        styles.summaryTabContainer
        // products.length === 0 ? styles.emptySummaryTab : ''
      )}
    >
      <div className={classNames('mt-3')}>
        <div>
          <div className={classNames('mb-3')}>
            <TotalTipSummary
              summary_data={summaryTotalData}
              previousStartDate={comparisonDate[0]}
              previousEndDate={comparisonDate[1]}
            />
          </div>
          <BootstrapTable
            wrapperClasses="table-responsive"
            keyField="key"
            data={summaryIntervalData || []}
            expandRow={expandRow}
            columns={columns}
            hover
            bootstrap4
            bordered={false}
            classes={classNames(styles.tableCustomStyle)}
            noDataIndication={<NoSummaryFound />}
          />
        </div>
      </div>
    </div>
  );
};

export default TipSummaryByPaymentType;
