import classNames from 'classnames';
import DatePicker from 'components/calender/date';
import { IFilterConfig, IFilterConfigDate } from 'components/filter/types';
import React from 'react';
import { formatDate, toIsoStringWithTz } from 'utils/date-format';

import styles from './export.module.scss';
interface IProps {
  item: IFilterConfig;
  setValue: (arg1: string, arg2: number, arg3: string) => void;
}

const DateExport = ({ item, setValue }: IProps): JSX.Element => {
  const { key, data } = item;
  const { values } = data as IFilterConfigDate;
  const dateFrom =
    values[0] ||
    toIsoStringWithTz(new Date(new Date().setDate(new Date().getDate() - 30)));
  const dateTo =
    values[1] ||
    toIsoStringWithTz(new Date(new Date().setDate(new Date().getDate())));

  return (
    <>
      <div
        className={classNames('d-flex', 'align-items-center', styles.container)}
      >
        <span className="mr-8">
          <div className={classNames(styles.dateLabel)}>Start date</div>

          <DatePicker
            initialValue={dateFrom ? formatDate(dateFrom, 'MM-dd-yyyy') : ''}
            setDate={(val: string) => setValue(key, 0, val)}
            // isLocalTime
            beforeDate={formatDate(dateTo, 'dd MMM yyyy')}
            format="MM-DD-YYYY"
          />
        </span>

        <span>
          <div className={classNames(styles.dateLabel)}>End date</div>

          <DatePicker
            initialValue={dateTo ? formatDate(dateTo, 'MM-dd-yyyy') : ''}
            setDate={(val: string) => setValue(key, 1, val)}
            afterDate={formatDate(dateFrom || '', 'dd MMM yyyy')}
            isEndDate
            // isLocalTime
            format="MM-DD-YYYY"
          />
        </span>
      </div>
    </>
  );
};

export default DateExport;
