import { apiWithOrg } from 'services/api';
import { SUMMARY } from 'state/summary/type';
import { useStateValue } from '../..';
import {
  action,
  actionFailure,
  actionSuccess,
  addKey,
  addKeyFailure,
  addKeySuccess,
  addNotes,
  addNotesFailure,
  addNotesSuccess,
  CANCEL_TRANSACTION,
  CLEAR_EXPORT,
  CREATE_SESSION,
  DELETE_KEY,
  fetchCharges,
  fetchChargesFailure,
  fetchChargesSuccess,
  fetchCSV,
  fetchCSVFailure,
  fetchCSVSuccess,
  fetchIndividualTransaction,
  fetchIndividualTransactionFailure,
  fetchIndividualTransactionSuccess,
  fetchKeys,
  fetchKeysFailure,
  fetchKeysSuccess,
  fetchTransactions,
  fetchTransactionsFailure,
  fetchTransactionsSuccess,
  FETCH_SESSIONS,
  FETCH_TRANSACTIONS_SUMMARY,
  reset,
  RESET_CANCEL_TRANSACTION,
  FETCH_GATEWAY_TRANSACTION,
  TIPS,
  TIPS_SUMMARY,
} from '../actions';
import { PAYMENTS } from '../type';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const usePayment = () => {
  const { state, dispatch } = useStateValue();
  const { payment } = state;

  // fetch all transactions of the merchant
  const toFetchTransactions = async (values: PAYMENTS.IFetchTransactions) => {
    dispatch(fetchTransactions());
    try {
      const response = await apiWithOrg.get(
        `transaction_reports/new/transactions`,
        values
      );
      dispatch(fetchTransactionsSuccess(response?.data));
    } catch (e) {
      dispatch(fetchTransactionsFailure(e));
    }
  };

  const toFetchSessions = async (values: PAYMENTS.IFetchSessions) => {
    dispatch(action(FETCH_SESSIONS));
    try {
      values.is_valid_consumer = true;
      const response = await apiWithOrg.get(`sessions`, values);
      dispatch(actionSuccess(FETCH_SESSIONS, response?.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_SESSIONS, e));
    }
  };

  // fetch details of Individual transactions of the merchant
  const toFetchIndividualTransaction = async (id: string) => {
    dispatch(fetchIndividualTransaction());
    try {
      const response = await apiWithOrg.get(`transaction_reports/${id}`);

      dispatch(fetchIndividualTransactionSuccess(response?.data));
    } catch (e) {
      dispatch(fetchIndividualTransactionFailure(e));
    }
  };

  const toFetchGatewayTransaction = async (id: string) => {
    dispatch(action(FETCH_GATEWAY_TRANSACTION));
    try {
      const response = await apiWithOrg.get(
        `transaction_reports/gateway/${id}`
      );

      dispatch(actionSuccess(FETCH_GATEWAY_TRANSACTION, response?.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_GATEWAY_TRANSACTION, e));
    }
  };

  const toFetchTipsList = async (values: PAYMENTS.IFetchTips) => {
    dispatch(action(TIPS));
    try {
      const response = await apiWithOrg.get(`/tips/details`, values);
      dispatch(actionSuccess(TIPS, response?.data));
    } catch (e) {
      dispatch(actionFailure(TIPS, e));
    }
  };
  const toFetchTipsSummary = async (values: PAYMENTS.IFetchTipsSummary) => {
    dispatch(action(TIPS_SUMMARY));
    try {
      const response = await apiWithOrg.get(
        `/tips/summary_by_payment_type`,
        values
      );
      dispatch(
        actionSuccess(TIPS_SUMMARY, {
          data: response?.data,
          type: values.summary_type,
        })
      );
    } catch (e) {
      dispatch(actionFailure(TIPS_SUMMARY, e));
    }
  };

  const toFetchTipsDetailsCSV = async (values: PAYMENTS.IFetchCSVTips) => {
    dispatch(fetchCSV());
    try {
      values.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await apiWithOrg.get('/tips/details/export', values);
      if (response.status === 204) {
        dispatch(
          fetchCSVFailure({
            data: { message: 'No records available for given date range' },
          })
        );
      } else {
        dispatch(fetchCSVSuccess(response?.data));
      }
    } catch (e) {
      dispatch(fetchCSVFailure(e));
    }
  };

  const toClearTipsExport = () => {
    dispatch(action(CLEAR_EXPORT));
  };

  const toFetchTipsSummaryCSV = async (values: PAYMENTS.IFetchCSVTips) => {
    dispatch(fetchCSV());
    try {
      values.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await apiWithOrg.get(
        '/tips/summary_by_payment_type/export',
        values
      );
      if (response.status === 204) {
        dispatch(
          fetchCSVFailure({
            data: { message: 'No records available for given date range' },
          })
        );
      } else {
        dispatch(fetchCSVSuccess(response?.data));
      }
    } catch (e) {
      dispatch(fetchCSVFailure(e));
    }
  };

  const toClearTipsSummaryExport = () => {
    dispatch(action(CLEAR_EXPORT));
  };

  // fetch details of Individual transactions of the dispensary
  const toFetchCSVTransactions = async (
    values: PAYMENTS.IFetchCSVTransactions
  ) => {
    dispatch(fetchCSV());
    try {
      values.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await apiWithOrg.get(
        `transaction_reports/new/export`,
        values
      );
      if (response.status === 204) {
        dispatch(
          fetchCSVFailure({
            data: { message: 'No records available for given date range' },
          })
        );
      } else {
        dispatch(fetchCSVSuccess(response?.data));
      }
    } catch (e) {
      dispatch(fetchCSVFailure(e));
    }
  };

  // fetch details of transactions of this partner's connected dispensaries
  const toFetchPartnerCSVTransactions = async (
    values: PAYMENTS.IFetchCSVTransactions
  ) => {
    dispatch(fetchCSV());
    try {
      values.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await apiWithOrg.get(
        'partners/transactions/export',
        values
      );
      if (response.status === 204) {
        dispatch(
          fetchCSVFailure({
            data: { message: 'No records available for given date range' },
          })
        );
      } else {
        dispatch(fetchCSVSuccess(response?.data));
      }
    } catch (e) {
      dispatch(fetchCSVFailure(e));
    }
  };

  const toFetchKeys = async (values?: PAYMENTS.IFetchAPIKeys) => {
    dispatch(fetchKeys());
    try {
      const response = await apiWithOrg.get(`/api_keys`, values);
      dispatch(fetchKeysSuccess(response?.data));
    } catch (e) {
      dispatch(fetchKeysFailure(e));
    }
  };

  const toAddKey = async (request?: PAYMENTS.ICreateAPIKeys) => {
    dispatch(addKey());
    try {
      const response = await apiWithOrg.post(`/api_keys`, request);
      dispatch(addKeySuccess(response?.data));
      return true;
    } catch (e) {
      dispatch(addKeyFailure(e, Boolean(request?.partner)));
      return false;
    }
  };

  const toRevokeKey = async (apiKey: string) => {
    dispatch(action(DELETE_KEY));
    try {
      const response = await apiWithOrg.put(`/api_keys/${apiKey}/revoke`);
      dispatch(actionSuccess(DELETE_KEY, response?.data));
    } catch (e) {
      dispatch(actionFailure(DELETE_KEY, e));
    }
  };

  //  Add notes
  const toAddNotes = async (
    report_id: string,
    notes_data: PAYMENTS.IAddNotes
  ) => {
    dispatch(addNotes());
    try {
      const response = await apiWithOrg.put(
        `/transaction_reports/${report_id}/notes`,
        notes_data
      );
      dispatch(addNotesSuccess(response?.data));
    } catch (e) {
      dispatch(addNotesFailure(e));
    }
  };

  // Fetch charges data from sparc pay
  const toFetchCharges = async (values: PAYMENTS.IChargeParams) => {
    dispatch(fetchCharges());
    try {
      const response = await apiWithOrg.get(`/sessions`, values);

      dispatch(fetchChargesSuccess(response?.data));
    } catch (e) {
      dispatch(fetchChargesFailure(e));
    }
  };

  const toCreateSession = async (values: PAYMENTS.ISessionParams) => {
    dispatch(action(CREATE_SESSION));
    try {
      const response = await apiWithOrg.post(`/sessions/demo`, values);
      dispatch(actionSuccess(CREATE_SESSION, response?.data));
    } catch (e) {
      dispatch(actionFailure(CREATE_SESSION, e));
    }
  };

  const toCancelTransaction = async (order_id: string) => {
    dispatch(action(CANCEL_TRANSACTION));
    try {
      const response = await apiWithOrg.post(`/orders/${order_id}/cancel`);
      dispatch(actionSuccess(CANCEL_TRANSACTION, response?.data));
      return true;
    } catch (e) {
      dispatch(actionFailure(CANCEL_TRANSACTION, e));
      return false;
    }
  };

  const toResetCancelTransaction = () => {
    dispatch(action(RESET_CANCEL_TRANSACTION));
  };

  const toResetPayment = () => {
    dispatch(reset());
  };

  const toClearTransactionsExport = () => {
    dispatch(action(CLEAR_EXPORT));
  };

  const toFetchTransactionSummary = async (
    values: PAYMENTS.ITransactionSummaryRequest
  ) => {
    dispatch(action(FETCH_TRANSACTIONS_SUMMARY));
    try {
      const response = await apiWithOrg.get(
        `/transaction_reports/new/aggregate/payment_type`,
        values
      );
      dispatch(actionSuccess(FETCH_TRANSACTIONS_SUMMARY, response?.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_TRANSACTIONS_SUMMARY, e));
    }
  };

  // partner dashboard APIs
  const toFetchPartnerTransactions = async (
    values: PAYMENTS.IFetchTransactions
  ) => {
    dispatch(fetchTransactions());
    try {
      const response = await apiWithOrg.get('/partners/transactions', values);
      dispatch(fetchTransactionsSuccess(response?.data));
    } catch (e) {
      dispatch(fetchTransactionsFailure(e));
    }
  };

  const isGatewayTransactionID = (id: string) => {
    return id.startsWith('gt_way');
  };

  const isAchOrderID = (id: string) => {
    return id.startsWith('order_');
  };

  return {
    payment,
    toFetchTransactions,
    toFetchSessions,
    toFetchCSVTransactions,
    toFetchIndividualTransaction,
    toFetchGatewayTransaction,
    toAddKey,
    toRevokeKey,
    toAddNotes,
    toFetchKeys,
    toResetPayment,
    toFetchCharges,
    toCreateSession,
    toClearTransactionsExport,
    toFetchTransactionSummary,
    toCancelTransaction,
    toResetCancelTransaction,
    toFetchPartnerTransactions,
    toFetchPartnerCSVTransactions,
    isGatewayTransactionID,
    isAchOrderID,
    toFetchTipsList,
    toFetchTipsSummary,
    toFetchTipsDetailsCSV,
    toClearTipsExport,
    toClearTipsSummaryExport,
    toFetchTipsSummaryCSV,
  };
};

export default usePayment;
