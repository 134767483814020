import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PageHeading from 'components/heading';
import Search from 'components/search';
import Filter from 'components/filter';
import { EFilterConfigType, IFilterConfig } from 'components/filter/types';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Card } from 'reactstrap';
import usePaymentLinks from 'state/paymentLinks/hooks/usePaymentLinks';
import useQuery from 'utils/hooks/useQuery';
import { InvoiceTypeList } from 'utils/constants';
import QUERY from 'utils/query';
import ToastContainer from 'components/toasts/container';
import AddPaymentLink from './addPaymentLink';
import CreatePLSuccess from './addPaymentLink/createPLSuccess';
import PaymentLinksTab from './paymentLinks';
import styles from './paymentlinks.module.scss';

const PaymentLinks = (): JSX.Element => {
  const {
    paymentLinks,
    toFetchPaymentLinks,
    toSendSMS,
    toSendEmail,
    resetCreatePaymentLink,
  } = usePaymentLinks();
  const location = useLocation();
  const navigate = useNavigate();

  // getting date from url if any
  const queryParams = location?.search;
  const query = useQuery(queryParams);
  const dates = query.get('dates')?.split(',') || [];
  const dateFrom = dates.length > 0 ? dates[0].replace(' ', '+') : '';
  const dateTo = dates.length > 1 ? dates[1].replace(' ', '+') : '';
  const amountOption = query.get('condition') || 'equal';
  const firstAmount = query.get('amount1') || undefined;
  const secondAmount = query.get('amount2') || undefined;
  const invoiceType = query.get('invoice')?.split(',') || [];
  const searchString = query.get('search') || undefined;

  const dateValues: string[] = [];

  if (dateFrom) {
    dateValues[0] = dateFrom;
  }
  if (dateTo) {
    dateValues[1] = dateTo;
  }

  const amountValues = ['equal'];
  if (amountOption) {
    amountValues[0] = amountOption;
  }
  if (firstAmount) {
    amountValues[1] = firstAmount;
  }
  if (secondAmount) {
    amountValues[2] = secondAmount;
  }

  const [sendNotificationSuccess, setsendNotificationSuccess] =
    useState<boolean>(false);

  const [channel, setChannel] = useState<string>();
  const [customerName, setCustomerName] = useState<string | undefined>();

  const getFilterConfig = () => {
    return [
      {
        key: 'dates',
        label: 'Dates',
        type: EFilterConfigType.date,
        data: {
          keys: [],
          values: dateValues,
        },
        selected: false,
      },
      {
        key: 'amount',
        label: 'Amount',
        type: EFilterConfigType.number,
        data: {
          keys: ['condition'],
          values: amountValues,
        },
        selected: false,
      },
      {
        key: 'invoice',
        label: 'Invoice Type',
        type: EFilterConfigType.list,
        data: {
          key: 'invoice',
          options: InvoiceTypeList,
          values: invoiceType,
        },
        selected: false,
      },
    ];
  };

  const defaultFilterConfig: IFilterConfig[] = getFilterConfig();

  const [filterConfig, setFilterConfig] =
    useState<IFilterConfig[]>(defaultFilterConfig);

  useEffect(() => {
    if (paymentLinks?.paymentLinksList) {
      setFilterConfig(getFilterConfig());
    }
  }, [paymentLinks]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [paymentLinkCreated, setPaymentLinkCreated] = useState<string | null>(
    null
  );

  const { sendSMSStatus, sendEmailStatus, error } = paymentLinks;

  const [notifySMSDisable, setNotifySMSDisable] = useState(false);
  const [notifyEmailDisable, setNotifyEmailDisable] = useState(false);

  const OnSendSMS = (id: string, name?: string) => {
    setNotifySMSDisable(true);
    setCustomerName(
      paymentLinks?.createPaymentLink?.customer_json?.first_name || name
    );
    setChannel('Phone');
    toSendSMS(id);
  };

  useEffect(() => {
    if (sendSMSStatus?.status === true) {
      setsendNotificationSuccess(true);
      // setShowToastMessage(sendSMSToastMessage, true)
    }
  }, [sendSMSStatus]);

  const OnSendEmail = (id: string, name?: string) => {
    setNotifyEmailDisable(true);
    setCustomerName(
      paymentLinks?.createPaymentLink?.customer_json?.first_name || name
    );
    setChannel('Email');
    toSendEmail(id);
  };

  useEffect(() => {
    if (notifyEmailDisable) {
      const timeout = setTimeout(() => {
        setNotifyEmailDisable(false);
        clearTimeout(timeout);
      }, 60000);
    }
  }, [notifyEmailDisable]);

  useEffect(() => {
    if (notifySMSDisable) {
      const timeout = setTimeout(() => {
        setNotifySMSDisable(false);
        clearTimeout(timeout);
      }, 60000);
    }
  }, [notifySMSDisable]);

  useEffect(() => {
    if (sendEmailStatus?.status === true) {
      setsendNotificationSuccess(true);
    }
  }, [sendEmailStatus]);

  // function to add dates in url as a filter
  const applyDateFilter = (dateFilter: Array<string>) => {
    if (dateFilter.length > 0) {
      navigate({
        search: QUERY.update(location.search, { dates: dateFilter.join() }),
      });
    }
  };

  useEffect(() => {
    if (dateFrom) {
      dateValues[0] = dateFrom;
    }
    if (dateTo) {
      dateValues[1] = dateTo;
    }
    if (dateValues.length > 0) {
      applyDateFilter(dateValues);
    }
  }, [dateFrom, dateTo]);

  const heading = 'Payment Links';
  const subHeading = 'View payment links here';
  const tooltipText = 'List of all payment links and sessions related.';

  useEffect(() => {
    toFetchPaymentLinks({
      start_date: dateFrom || undefined,
      end_date: dateTo || undefined,
      amount_1: firstAmount,
      amount_2: secondAmount,
      condition: amountValues[0] || undefined,
      invoice_type: invoiceType.length > 0 ? invoiceType.join(',') : undefined,
      invoice_order_id: searchString || undefined,
    });
  }, [queryParams]);

  useEffect(() => {
    if (paymentLinks.isCreatedPaymentLink || paymentLinks.isDeleted) {
      toFetchPaymentLinks({
        start_date: dateFrom || undefined,
        end_date: dateTo || undefined,
        amount_1: firstAmount,
        amount_2: secondAmount,
        condition: amountValues[0] || undefined,
        invoice_type:
          invoiceType.length > 0 ? invoiceType.join(',') : undefined,
        invoice_order_id: searchString || undefined,
      });
    }
  }, [paymentLinks.isCreatedPaymentLink, paymentLinks.isDeleted, queryParams]);

  return (
    <>
      <Card className="card-profile border-0">
        <div className="d-flex">
          <span>
            <PageHeading
              heading={heading}
              subHeading={subHeading}
              tooltipText={tooltipText}
            />
          </span>
          
          {/* <span className={classNames(styles.addLinkBtn, 'ml-auto')}>
            <div className="d-flex align-items-center">
              <Button
                color="primary"
                onClick={() => setShowModal(true)}
                className={classNames(
                  'd-flex align-items-center',
                  styles.createDemoButton
                )}
              >
                <i className="ph-plus-fill mr-1" />
                Create Payment link
              </Button>
            </div>
          </span> */}
        </div>
        <div className="mr-auto">
          <div className="d-flex align-items-center mt-3 mb-5">
            <span className="mr-auto">
              <Search queryParams={queryParams} />
            </span>
            <div className="d-flex mb-3">
              {filterConfig && (
                <>
                  <Filter
                    config={filterConfig}
                    queryParams={queryParams}
                  ></Filter>
                </>
              )}
            </div>
          </div>
        </div>
        <PaymentLinksTab
          dateFrom={dateFrom}
          dateTo={dateTo}
          amountOption={amountValues[0]}
          firstAmount={firstAmount}
          secondAmount={secondAmount}
          invoiceOrderId={searchString}
          sendEmail={OnSendEmail}
          sendSMS={OnSendSMS}
          disableSMS={notifySMSDisable}
          disableEmail={notifyEmailDisable}
        />
      </Card>
      {showModal && (
        <AddPaymentLink
          showModal={showModal}
          setShowModal={setShowModal}
          onSuccess={(paymentLink: string, first_name: string) => {
            setShowSuccessModal(true);
            setCustomerName(first_name);
            setPaymentLinkCreated(paymentLink);
          }}
        />
      )}
      {showSuccessModal && paymentLinkCreated && (
        <CreatePLSuccess
          paymentLink={paymentLinkCreated}
          showModal={showSuccessModal}
          onClose={() => {
            setShowSuccessModal(false);
            setPaymentLinkCreated(null);
            resetCreatePaymentLink();
            window.location.reload();
          }}
          sendEmail={OnSendEmail}
          sendSMS={OnSendSMS}
          paymentLinkID={paymentLinks.createPaymentLink.id}
          errors={error}
          disableEmail={notifyEmailDisable}
          disableSMS={notifySMSDisable}
        />
      )}
      {customerName && channel && (
        <ToastContainer
          showToast={sendNotificationSuccess}
          onClose={() => setsendNotificationSuccess(!sendNotificationSuccess)}
          message={`Payment link sent to ${customerName}'s ${channel}`}
        />
      )}
    </>
  );
};

export default PaymentLinks;
