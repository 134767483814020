import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import QUERY from 'utils/query';
import STRING from 'utils/string';
import useQuery from 'utils/hooks/useQuery';
import DateFilter from './date';
import styles from './filters.module.scss';
import ListFilter from './list';
import NumberFilter from './number';
import StringFilter from './string';
import { EFilterConfigType, IFilterConfig } from './types';

interface IProps {
  config: IFilterConfig[];
  queryParams?: string; // TODO: Optional till full migration
  changeKey?: string;
  changeKeyUpdateHandlerCb?: (arg: IFilterConfig[]) => IFilterConfig[]; // callback to update filter config based on changeKey updates
}

const Filter = ({
  config,
  queryParams = '',
  changeKey,
  changeKeyUpdateHandlerCb,
}: IProps): JSX.Element => {
  const changeIndex = config.findIndex((i) => i.key === changeKey);
  const navigate = useNavigate();
  const location = useLocation();

  const [numFilters, setNumFilters] = useState(0);
  const [items, setItems] = useState(config);

  useEffect(() => {
    if (changeKey && changeKeyUpdateHandlerCb) {
      setItems(changeKeyUpdateHandlerCb(items));
    }
  }, [items?.[changeIndex]?.data?.values?.length]);

  const urlParams = useQuery(queryParams);

  const clearAll = () => {
    const cleared = items.map((item) => {
      item.selected = false;
      item.data.values = [];
      return item;
    });

    const clearedFilterQuery = items.reduce(
      (obj: Record<string, string | null>, item) => {
        obj[item.key] = null;

        return obj;
      },
      {}
    );

    clearedFilterQuery.amount1 = null;
    clearedFilterQuery.amount2 = null;
    clearedFilterQuery.condition = null;

    navigate({
      search: QUERY.update(location.search, clearedFilterQuery),
    });
    setItems(cleared);
  };

  const [toggleDropdown, controlToggle] = useState(false);

  const applyFilter = () => {
    const query: {
      [key: string]: string;
    } = { tab: urlParams.get('tab') || 'details' };
    items.forEach((item) => {
      const { key, data, selected, type } = item;
      if (selected && data.values.length > 0) {
        if (type === EFilterConfigType.number) {
          const condition = data.values.shift();
          query.condition = condition || '';
          const firstValue = data.values.shift();
          const secondValue = data.values.shift();
          if (firstValue) {
            query[`${key}1`] = firstValue;
          }
          if (secondValue) {
            query[`${key}2`] = secondValue;
          }
        } else {
          const queryString = STRING.joinWithoutTrailingDelimiter(data.values);
          query[key] = queryString;
        }
      }
    });
    navigate({ search: `${QUERY.build(query)}` });
    controlToggle(!toggleDropdown);
  };

  const getAppliedFromQuery = (configuration: IFilterConfig[]) =>
    configuration.map((item) => {
      const values = urlParams.get(item.key) || urlParams.get(`${item.key}1`);
      if (values) {
        if (item.type === EFilterConfigType.number) {
          const condition = urlParams.get('condition') || 'equal';
          item.data.values = [condition];

          const firstValue = urlParams.get(`${item.key}1`);
          const secondValue = urlParams.get(`${item.key}2`);
          if (firstValue) item.data.values.push(firstValue);
          if (secondValue) item.data.values.push(secondValue);
        } else {
          let val = values?.split(',');
          // Hack for URL encoding/decoding not working with React Router to add timezone
          if (item.type === EFilterConfigType.date) {
            val = val.map((v) => v.replace(' ', '+'));
          }
          item.data.values = val;
        }
        item.selected = true;
      }
      return item;
    });

  useEffect(() => {
    const prefilled = getAppliedFromQuery(config);
    setItems(prefilled);
  }, [config]);

  const setSelected = (key: string, value: boolean) => {
    const updatedItems = items.map((item) => {
      if (item.key === key) {
        item.selected = value;
      }
      return item;
    });
    setItems(updatedItems);
  };

  useEffect(() => {
    const numberSelected = items.reduce(
      (all, current) => (current.selected ? all + 1 : all),
      0
    );
    setNumFilters(numberSelected);
  }, [items]);

  /*
   *  Set single value in an array to generate comma-separated values
   */
  const setValue = (key: string, index: number, value: string) => {
    const updatedItems = items.map((item) => {
      if (item.key === key) {
        item.data.values[index] = value;
      }
      return item;
    });
    setItems(updatedItems);
  };

  /*
   *  Set entire array to generate comma-separated values
   */
  const setValues = (key: string, values: string[]) => {
    const updatedItems = items.map((item) => {
      if (item.key === key) {
        item.data.values = values;
      }
      return item;
    });
    setItems(updatedItems);
  };

  const getFilter = (item: IFilterConfig): JSX.Element => {
    switch (item.type) {
      case EFilterConfigType.date:
        return (
          <DateFilter
            setSelected={setSelected}
            item={item}
            setValue={setValue}
          />
        );
      case EFilterConfigType.number:
        return (
          <NumberFilter
            setSelected={setSelected}
            item={item}
            setValue={setValue}
          ></NumberFilter>
        );
      case EFilterConfigType.list:
        return (
          <ListFilter
            setSelected={setSelected}
            item={item}
            setValues={setValues}
          ></ListFilter>
        );
      case EFilterConfigType.string:
        return (
          <StringFilter
            setSelected={setSelected}
            item={item}
            setValue={setValue}
          ></StringFilter>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <UncontrolledDropdown
        isOpen={toggleDropdown}
        toggle={() => controlToggle(!toggleDropdown)}
      >
        <DropdownToggle
          data-testid="filter_button"
          className={classNames(
            styles.dropdownToggle,
            'd-inline-flex align-items-center btn-sm'
          )}
        >
          <i
            className={classNames(
              styles.filterIcon,
              'ph-funnel-simple mr-1 icon-xl'
            )}
          />
          <span className={styles.filterText}>
            Filter{numFilters > 0 && ` (${numFilters})`}
          </span>
          <i
            className={classNames(
              styles.filterIcon,
              'ph-caret-down-light ml-1 icon-lg'
            )}
          ></i>
        </DropdownToggle>
        <DropdownMenu right className={classNames(styles.filterMenu)}>
          <DropdownItem text className={classNames(styles.filterHeader)}>
            <Button
              data-testid="clear"
              color="secondary"
              size="sm"
              onClick={clearAll}
            >
              Clear
            </Button>
            <div>Filters</div>
            <Button
              data-testid="done"
              color="primary"
              size="sm"
              onClick={applyFilter}
            >
              Done
            </Button>
          </DropdownItem>

          <DropdownItem divider className={classNames(styles.divider)} />
          {items.map((item, index) => (
            <>
              {index > 0 && (
                <DropdownItem divider className={classNames(styles.divider)} />
              )}
              {getFilter(item)}
            </>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

export default Filter;
