import { ROLES } from 'components/rbac/type';
import React, { ReactElement } from 'react';
import { Route, Navigate } from 'react-router-dom';
import Page from 'routes/page';
import { AUTH } from 'state/auth/type';
import views, { IView } from 'views';
import NotAuthorizedError from 'views/error/notauthorized';

/**
 * Given a list of user roles and a list of page roles, return true if the user has at least one of the
 * page roles
 * @param {AUTH.IRole[]} userRoles - AUTH.IRole[] = [],
 * @param {ROLES[]} pageConfig - ROLES[]
 * @param {string} orgId - The organization ID of the user.
 */
export const hasCommon = (
  pageConfig: ROLES[],
  orgId: string,
  userRoles: AUTH.IRole[] = []
) => {
  return userRoles
    .filter((item: AUTH.IRole) => item.tenant_id === orgId)
    .some((item: AUTH.IRole) => pageConfig.includes(item.role));
};

/**
 * Given a layout, return the views that are associated with that layout
 * @param {string} layout - The layout name.
 */
export const getConfig = (layout: string, view = views.dispensary || views.nonAchDispensaryViews): IView[] =>
  view.filter((prop: IView) => prop.layout === layout);

/**
 * It renders a page if the user is authorized to view it
 * @param {IView} child - IView - this is the child object that is passed in from the parent.
 * @param {boolean} isAuthorized - boolean - This is a boolean value that determines whether the user
 * is authorized to view the page.
 * @returns A Route component that will render a Page component if the user is authorized.
 */
export const renderPage = (child: IView, isAuthorized: boolean) => {
  return child.component ? (
    <Route
      path={child.path}
      element={
        isAuthorized ? (
          <Page title={`${child.title} - TreezPay`}>{child.component}</Page>
        ) : (
          <NotAuthorizedError />
        )
      }
    />
  ) : null;
};

/*
 * Get routes for specified layout's config object
 */
export const getRoutes = (
  roles: AUTH.IRole[],
  orgId: string,
  config: IView[] = []
): ReactElement<string, string>[] | null => {
  let isAuthorized = true;

  return config.map((view: IView) => {
    /* Checking if the user has the role to access the page. */
    if (
      view.roles &&
      view.roles.length > 0 &&
      !hasCommon(view.roles, orgId, roles)
    ) {
      isAuthorized = false;
    }

    return view.children && view.children.length > 0 ? (
      <>
        {view.children.map((child) => {
          return renderPage(child, isAuthorized);
        })}
        <Route path="*" element={<Navigate to={view.path} replace />} />
      </>
    ) : (
      <>
        <Route
          path={view.path}
          element={
            isAuthorized && view.component ? (
              <Page title={`${view.title} - TreezPay`}>{view.component}</Page>
            ) : (
              <NotAuthorizedError />
            )
          }
        />
        <Route path="*" element={<Navigate to={view.path} replace />} />
      </>
    );
  });
};
