
export const LOGIN = 'auth/LOGIN';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'auth/LOGIN_FAILURE';

export const REGISTER = 'auth/REGISTER';
export const REGISTER_SUCCESS = 'auth/REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'auth/REGISTER_FAILURE';

export const LOGOUT = 'auth/LOGOUT';
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'auth/LOGOUT_FAILURE';

export const FETCH_USER = 'auth/FETCH_USER';
export const FETCH_USER_SUCCESS = 'auth/FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'auth/FETCH_USER_FAILURE';

export const FETCH_USER_INTEGRATIONS = 'auth/FETCH_USER_INTEGRATIONS';
export const FETCH_USER_INTEGRATIONS_SUCCESS =
  'auth/FETCH_USER_INTEGRATIONS_SUCCESS';
export const FETCH_USER_INTEGRATIONS_FAILURE =
  'auth/FETCH_USER_INTEGRATIONS_FAILURE';

export const EDIT = 'auth/EDIT';
export const EDIT_SUCCESS = 'auth/EDIT_SUCCESS';
export const EDIT_FAILURE = 'auth/EDIT_FAILURE';

export const CODE = 'auth/CODE';
export const CODE_SUCCESS = 'auth/CODE_SUCCESS';
export const CODE_FAILURE = 'auth/CODE_FAILURE';

export const REQUEST_RESET = 'auth/REQUEST_RESET';
export const REQUEST_RESET_SUCCESS = 'auth/REQUEST_RESET_SUCCESS';
export const REQUEST_RESET_FAILURE = 'auth/REQUEST_RESET_FAILURE';

export const SET_VIEW = 'auth/SET_VIEW';
export const SET_MODE = 'auth/SET_MODE';
export const SET_MODE_SUCCESS = 'auth/SET_MODE_SUCCESS';

export const SET_PERMISSIONS = 'auth/SET_PERMISSIONS';

export const RESET_USER_SESSION = 'auth/RESET_USER_SESSION';

export const VERIFY_OTP_CHALLENGE = 'auth/VERIFY_OTP_CHALLENGE';
export const VERIFY_OTP_CHALLENGE_SUCCESS = 'auth/VERIFY_OTP_CHALLENGE_SUCCESS';
export const VERIFY_OTP_CHALLENGE_FAILURE = 'auth/VERIFY_OTP_CHALLENGE_FAILURE';

export const RESEND_VERIFY_EMAIL = 'auth/RESEND_VERIFY_EMAIL';
export const RESEND_VERIFY_EMAIL_SUCCESS = 'auth/RESEND_VERIFY_EMAIL_SUCCESS';
export const RESEND_VERIFY_EMAIL_FAILURE = 'auth/RESEND_VERIFY_EMAIL_FAILURE';

export const FETCH_TENANT = 'auth/FETCH_TENANT';
export const FETCH_TENANT_SUCCESS = 'auth/FETCH_TENANT_SUCCESS';
export const FETCH_TENANT_FAILURE = 'auth/FETCH_TENANT_FAILURE';

export const SET_PERMISSION_ERROR = 'auth/SET_PERMISSION_ERROR';

export const SET_SSO_LOGIN = 'auth/SET_SSO_LOGIN';

export const fetchUser = (): IAction => ({
  type: FETCH_USER,
});

export const fetchUserSuccess = <T>(payload: T): IAction => ({
  type: FETCH_USER_SUCCESS,
  payload,
});

export const fetchUserFailure = <T>(payload: T): IAction => ({
  type: FETCH_USER_FAILURE,
  payload,
});

export const edit = (): IAction => ({
  type: EDIT,
});

export const editSuccess = <T>(payload: T): IAction => ({
  type: EDIT_SUCCESS,
  payload,
});

export const editFailure = <T>(payload: T): IAction => ({
  type: EDIT_FAILURE,
  payload,
});

export const setView = (payload: string): IAction => ({
  type: SET_VIEW,
  payload,
});

export const setPermissions = <T>(payload: T): IAction => ({
  type: SET_PERMISSIONS,
  payload,
});


export const action = <T>(type: string, payload?: T): IAction => ({
  type,
  payload,
});

export const actionSuccess = <T>(type: string, payload: T): IAction => ({
  type: `${type}_SUCCESS`,
  payload,
});

export const actionFailure = <T>(type: string, payload: T): IAction => ({
  type: `${type}_FAILURE`,
  payload,
});
