import React from 'react';

export const WriteOffIcon = (): JSX.Element => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4004 7.25V8.15"
      stroke="#808690"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4004 12.6484V13.5484"
      stroke="#808690"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4 15.8C13.3823 15.8 15.8 13.3823 15.8 10.4C15.8 7.41766 13.3823 5 10.4 5C7.41766 5 5 7.41766 5 10.4C5 13.3823 7.41766 15.8 10.4 15.8Z"
      stroke="#808690"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.04961 12.6484H11.0746C11.373 12.6484 11.6591 12.5299 11.8701 12.3189C12.0811 12.108 12.1996 11.8218 12.1996 11.5234C12.1996 11.2251 12.0811 10.9389 11.8701 10.7279C11.6591 10.517 11.373 10.3984 11.0746 10.3984H9.72461C9.42624 10.3984 9.14009 10.2799 8.92911 10.0689C8.71814 9.85795 8.59961 9.57181 8.59961 9.27344C8.59961 8.97507 8.71814 8.68892 8.92911 8.47794C9.14009 8.26696 9.42624 8.14844 9.72461 8.14844H11.7496"
      stroke="#808690"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.6 12C14.888 12 14.192 12.2111 13.5999 12.6067C13.0079 13.0023 12.5465 13.5645 12.274 14.2223C12.0016 14.8802 11.9303 15.604 12.0692 16.3023C12.2081 17.0007 12.5509 17.6421 13.0544 18.1456C13.5579 18.6491 14.1993 18.9919 14.8977 19.1308C15.596 19.2697 16.3198 19.1984 16.9777 18.926C17.6355 18.6535 18.1977 18.1921 18.5933 17.6001C18.9889 17.008 19.2 16.312 19.2 15.6C19.1982 14.6458 18.8183 13.7312 18.1436 13.0564C17.4688 12.3817 16.5542 12.0018 15.6 12ZM16.9708 16.005L15.7973 17.1819C15.7444 17.2331 15.6736 17.2617 15.6 17.2617C15.5264 17.2617 15.4556 17.2331 15.4027 17.1819L14.2292 16.005C14.1815 15.9523 14.1559 15.8833 14.1576 15.8122C14.1594 15.7412 14.1884 15.6735 14.2386 15.6232C14.2889 15.573 14.3566 15.544 14.4276 15.5422C14.4987 15.5405 14.5677 15.5661 14.6204 15.6138L15.3231 16.3165V14.2154C15.3231 14.1419 15.3523 14.0715 15.4042 14.0196C15.4561 13.9676 15.5266 13.9385 15.6 13.9385C15.6734 13.9385 15.7439 13.9676 15.7958 14.0196C15.8477 14.0715 15.8769 14.1419 15.8769 14.2154V16.3165L16.5796 15.6138C16.6323 15.5661 16.7013 15.5405 16.7724 15.5422C16.8434 15.544 16.9111 15.573 16.9614 15.6232C17.0116 15.6735 17.0406 15.7412 17.0424 15.8122C17.0441 15.8833 17.0185 15.9523 16.9708 16.005Z"
      fill="#808690"
      id="downArrowPath"
    />
  </svg>
);
