import ErrorMessage from 'components/errorMessage';
import FormLabel from 'components/form-label';
import { withAdminRole } from 'components/rbac/helper';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Alert, Button, Col, FormGroup, Input, Row } from 'reactstrap';
import useAuth from 'state/auth/hooks/useAuth';
import { ORGANISATION } from 'state/organisation/type';
import useStore from 'state/store/hooks/useStore';
import { STORE } from 'state/store/type';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface IProps {
  settings: ORGANISATION.IBrandSettings | null;
  toUpdateStore: (
    storeId: string,
    values: STORE.IStoreRequest
  ) => Promise<void>;
}

const General = ({ settings, toUpdateStore }: IProps): JSX.Element => {
  const { sub_domain } = settings || {};
  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const { auth } = useAuth();
  const { isSandbox } = auth;
  const { store } = useStore();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: { [x: string]: string | undefined }) => {
    data.domain = data.domain === '' ? undefined : data.domain;
    if (settings && data.sub_domain) {
      toUpdateStore(settings.store_identifier, {
        id: settings.store_identifier,
        sub_domain: data.sub_domain,
        domain: data.domain,
      });
    }

    setTimeout(() => {
      setVisible(false);
    }, 3000);
  };
  const [copied, setCopied] = useState(false);
  const [orgcopy, setOrgcopy] = useState(false);
  const [isStoreCopied, setIsStoreCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  useEffect(() => {
    if (orgcopy) {
      setTimeout(() => {
        setOrgcopy(false);
      }, 2000);
    }
  }, [orgcopy]);

  useEffect(() => {
    if (isStoreCopied) {
      setTimeout(() => {
        setIsStoreCopied(false);
      }, 2000);
    }
  }, [isStoreCopied]);

  const SaveButton = () => (
    <Row>
      <Col sm="12">
        <Button size="md" color="primary" type="submit">
          Save
        </Button>
      </Col>
    </Row>
  );

  const saveButton = withAdminRole(SaveButton);

  return (
    <>
      <Alert
        color="danger"
        isOpen={visible}
        toggle={onDismiss}
        className="mt-3"
      >
        Sub domain field cannot be empty
      </Alert>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-2">
          <Col sm="6" xs="6">
            <FormGroup>
              <FormLabel label="Sub-domain" element="name" isRequired />

              <Input
                defaultValue={sub_domain || ''}
                placeholder="dispensaryname"
                name="sub_domain"
                innerRef={register}
                disabled={isSandbox}
                className={classNames(
                  'd-block form-control',
                  store.error.updateStore?.error_code ===
                    'subdomain_not_available'
                    ? styles.borderAlert
                    : ''
                )}
              />

              {store.error.updateStore?.error_code ===
                'subdomain_not_available' && (
                <ErrorMessage
                  isValidation
                  message="Sorry, the sub-domain name you entered is already taken. Please choose a unique sub-domain name for your store."
                  preIcon={
                    <i className="ph-warning-circle-fill icon-md dangerIcon"></i>
                  }
                />
              )}
              <div className="body-subtext text-muted mt-1">
                Your customers will access checkout with this custom URL on
                subdomain.swiftercheckout.com.
              </div>
            </FormGroup>
          </Col>
        </Row>
        {saveButton}
        {/* errors will return when field validation fails  */}
        {errors.exampleRequired && <span>This field is required</span>}
      </form>
    </>
  );
};

export default General;
