// eslint-disable-next-line import/no-extraneous-dependencies
import numeral from 'numeral';
/**
 * Convert an amount to the currency unit
 * @param [amount=0] - The amount of money to be converted in cents.
 * @param [currency=USD] - The currency code, e.g. USD, EUR, JPY.
 * @returns the formatted currency string
 */
const convertToMainUnit = (
  amount = 0,
  dollar_sign = true,
  currency = 'USD'
): string =>
  new Intl.NumberFormat(
    'en-US',
    dollar_sign
      ? {
          style: 'currency',
          currency,
        }
      : { minimumFractionDigits: 2, maximumFractionDigits: 2 }
  ).format(amount / 100);

/**
 * Convert the given amount to the smallest unit of currency
 * @param [amount=0] - The amount you want to convert.
 * @returns The amount in cents.
 */
const convertToSmallestUnit = (amount = 0): number => {
  const n = numeral(amount).multiply(100).value();
  return n || 0;
};

/**
 * Given an amount in cents, return the amount in dollars in compact notation.
 * @param [amount=0] - The amount to be formatted.
 * @returns A string with the currency suffix($) and appropriate prefix (K, M, etc).
 */
const getCompactNotation = (amount = 0): string => {
  const amountInDollars = amount / 100;
  const lookup = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'K' },
  ];
  const trailingZeroesRegex = /\.0+$/;
  const item = lookup.find((l) => amountInDollars >= l.value);
  const digitsAfterDecimal = item && item.value > 1 ? 1 : 2;

  return `$${
    item
      ? (amountInDollars / item.value)
          .toFixed(digitsAfterDecimal)
          .replace(trailingZeroesRegex, '') + item.symbol
      : amountInDollars.toFixed(2)
  }`;
};

/**
 * Returns the numeric value for a given currency string
 * Used to format the currency values of NumberFormat input component
 * Handles $ prefix, thousand separator & decimal values
 * @param {string} - Currency string
 * @returns Number
 */
const getValueFromCurrencyStr = (value: string) => {
  const num = value.split('$');
  return num.length > 1 ? Number(num[1].replace(/,/g, '')) || 0 : 0;
};

const totalAvailableBalance = (
  total_balance: number | undefined = 0,
  holding_balance: number | undefined = 0
) => {
  let calculatedAmount = 0;
  calculatedAmount = total_balance - holding_balance < 0 ? 0 : total_balance - holding_balance;
  return calculatedAmount;
};

const CURRENCY = {
  convertToMainUnit,
  convertToSmallestUnit,
  getCompactNotation,
  getValueFromCurrencyStr,
  totalAvailableBalance,
};

export default CURRENCY;
