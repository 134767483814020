import { KYB_BUSINESS_TYPE } from 'utils/constants';
import { IOrgStatus } from './type';

export const FETCH_USERS = 'organisations/FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'organisations/FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'organisations/FETCH_USERS_FAILURE';

export const FETCH_INVITES = 'organisations/FETCH_INVITES';
export const FETCH_INVITES_SUCCESS = 'organisations/FETCH_INVITES_SUCCESS';
export const FETCH_INVITES_FAILURE = 'organisations/FETCH_INVITES_FAILURE';

export const FETCH_USER_INVITES = 'organisations/FETCH_USER_INVITES';
export const FETCH_USER_INVITES_SUCCESS =
  'organisations/FETCH_USER_INVITES_SUCCESS';
export const FETCH_USER_INVITES_FAILURE =
  'organisations/FETCH_USER_INVITES_FAILURE';

export const DELETE_INVITE = 'organisations/DELETE_INVITE';
export const DELETE_INVITE_SUCCESS = 'organisations/DELETE_INVITE_SUCCESS';
export const DELETE_INVITE_FAILURE = 'organisations/DELETE_INVITE_FAILURE';

export const ACT_INVITE = 'organisations/ACT_INVITE';
export const ACT_INVITE_SUCCESS = 'organisations/ACT_INVITE_SUCCESS';
export const ACT_INVITE_FAILURE = 'organisations/ACT_INVITE_FAILURE';

export const ADD_USER = 'organisations/ADD_USER';
export const ADD_USER_SUCCESS = 'organisations/ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'organisations/ADD_USER_FAILURE';

export const EDIT_USER = 'organisations/EDIT_USER';
export const EDIT_USER_SUCCESS = 'organisations/EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'organisations/EDIT_USER_FAILURE';

export const FETCH_ROLES = 'organisations/FETCH_ROLES';
export const FETCH_ROLES_SUCCESS = 'organisations/FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAILURE = 'organisations/FETCH_ROLES_FAILURE';

export const REMOVE_ROLE = 'organisations/REMOVE_ROLE';
export const REMOVE_ROLE_SUCCESS = 'organisations/REMOVE_ROLE_SUCCESS';
export const REMOVE_ROLE_FAILURE = 'organisations/REMOVE_ROLE_FAILURE';

export const UPDATE_ROLE = 'organisations/UPDATE_ROLE';
export const UPDATE_ROLE_SUCCESS = 'organisations/UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILURE = 'organisations/UPDATE_ROLE_FAILURE';

export const CREATE_INTERNAL_USER = 'organisations/CREATE_INTERNAL_USER';
export const CREATE_INTERNAL_USER_SUCCESS =
  'organisations/CREATE_INTERNAL_USER_SUCCESS';
export const CREATE_INTERNAL_USER_FAILURE =
  'organisations/CREATE_INTERNAL_USER_FAILURE';

export const FETCH_ACTIVE_ORG = 'organisations/FETCH_ACTIVE_ORG';
export const FETCH_ACTIVE_ORG_SUCCESS =
  'organisations/FETCH_ACTIVE_ORG_SUCCESS';
export const FETCH_ACTIVE_ORG_FAILURE =
  'organisations/FETCH_ACTIVE_ORG_FAILURE';

export const UPDATE_ORG_SETTINGS = 'payments/UPDATE_ORG_SETTINGS';
export const UPDATE_ORG_SETTINGS_SUCCESS =
  'payments/UPDATE_ORG_SETTINGS_SUCCESS';
export const UPDATE_ORG_SETTINGS_FAILURE =
  'payments/UPDATE_ORG_SETTINGS_FAILURE';

export const SET_ACTIVE_ORG = 'organisations/SET_ACTIVE_ORG';
export const RESET = 'organisations/RESET';
export const RESET_ACTIVE_ORG = 'organisations/RESET_ACTIVE_ORG';

export const FETCH_ORG_ROLE_ID = 'organizations/FETCH_ORG_ROLE_ID';
export const FETCH_ORG_ROLE_ID_SUCCESS =
  'organizations/FETCH_ORG_ROLE_ID_SUCCESS';
export const FETCH_ORG_ROLE_ID_FAILURE =
  'organizations/FETCH_ORG_ROLE_ID_FAILURE';

export const fetchUsers = (): IAction => ({
  type: FETCH_USERS,
});

export const fetchUsersSuccess = <T>(payload: T): IAction => ({
  type: FETCH_USERS_SUCCESS,
  payload,
});

export const fetchUsersFailure = <T>(payload: T): IAction => ({
  type: FETCH_USERS_FAILURE,
  payload,
});

export const addUser = (): IAction => ({
  type: ADD_USER,
});

export const addUserSuccess = <T>(payload: T): IAction => ({
  type: ADD_USER_SUCCESS,
  payload,
});

export const addUserFailure = <T>(payload: T): IAction => ({
  type: ADD_USER_FAILURE,
  payload,
});

export const setActiveOrg = <T>(payload: T): IAction => ({
  type: SET_ACTIVE_ORG,
  payload,
});

export const reset = (): IAction => ({
  type: RESET,
});

export const resetActiveOrg = (): IAction => ({
  type: RESET_ACTIVE_ORG,
});

export const action = (type: string): IAction => ({
  type,
});

export const actionSuccess = <T>(type: string, payload: T): IAction => ({
  type: `${type}_SUCCESS`,
  payload,
});

export const actionFailure = <T>(type: string, payload: T): IAction => ({
  type: `${type}_FAILURE`,
  payload,
});

export const getOrgStatus = (
  is_kyb_collected: boolean,
  is_payment_enabled: boolean
  // is_partner = false
): IOrgStatus => {
  if (is_kyb_collected) {
    // const isSoftwareBusinessActivated =
    //   business_type === KYB_BUSINESS_TYPE[1].key &&
    //   is_kyb_collected === true &&
    //   is_partner === true;

    // const isDispensaryOrDeliveryBzActivated =
    //   // Right now we have a check that says not equal to software business, in future it might change if new business are added based on the requirement
    //   business_type !== KYB_BUSINESS_TYPE[1].key &&
    //   is_payment_enabled === true &&
    //   is_kyb_collected === true &&
    //   is_partner === false;

    /**
     * if kyb not collected -> KYB_PENDING
     * if kyb collected and payment is enabled -> ACTIVATED
     * if kyb collected and payment not enabled -> ACTIVATION_PENDING
     */

    return is_payment_enabled
      ? IOrgStatus.ACTIVATED
      : IOrgStatus.ACTIVATION_PENDING;
  }
  return IOrgStatus.KYB_PENDING;
};
