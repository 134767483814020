import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Layout from './components/layout';
import usePaymentConfig from 'state/paymentConfig/hooks/usePaymentConfig';
import { PAYMENT_CONFIG } from 'state/paymentConfig/type';
import DeviceConfig from './components/deviceConfig';
import DeviceStatus from './components/modals/deviceStatus';
import { PaymentSolutionTab } from '../paymentConfig';

export const initialDeviceConfig: Record<string, string> = {
  deviceName: '',
  registerId: '',
  printConfig: 'NONE',
  deviceAuthCode: '',
  tpn: '',
};
interface IProps {
  defaultProvider: PAYMENT_CONFIG.IProcessors | undefined;
  setDefaultProvider: Dispatch<
    SetStateAction<PAYMENT_CONFIG.IProcessors | undefined>
  >;
  activeTab: PaymentSolutionTab;
}
const IntegratedAtm = ({
  defaultProvider,
  setDefaultProvider,
  activeTab,
}: IProps): JSX.Element => {
  const {
    paymentConfig,
  } = usePaymentConfig();

  const { deviceStatus } = paymentConfig;

  const [deviceConfig, setDeviceConfig] = useState(initialDeviceConfig);

  const [deviceStatusType, setDeviceStatusType] = useState(false);
  const [showDeviceStatus, setShowDeviceStatus] = useState(false);

  useEffect(() => {
    if (activeTab === PaymentSolutionTab.ATM) {
      if (deviceStatus?.isOnline === true || deviceStatus?.raw === 'Online') {
        setDeviceStatusType(true);
        setShowDeviceStatus(true);
      } else if (
        deviceStatus?.isOnline === false ||
        deviceStatus?.raw === 'Offline'
      ) {
        setDeviceStatusType(false);
        setShowDeviceStatus(true);
      } else {
        setShowDeviceStatus(false);
      }
    }
  }, [deviceStatus]);

  return (
    <>
      <Layout
        defaultProvider={defaultProvider}
        setDefaultProvider={setDefaultProvider}
        activeTab={activeTab}
        localTab={PaymentSolutionTab.ATM}
      />
      <DeviceConfig
        defaultProvider={defaultProvider}
        activeTab={activeTab}
        localTab={PaymentSolutionTab.ATM}
        deviceConfig={deviceConfig}
        setDeviceConfig={setDeviceConfig}
      />
      {showDeviceStatus && (
        <DeviceStatus
          showModal={showDeviceStatus}
          setShowModal={setShowDeviceStatus}
          status={deviceStatusType}
        />
      )}
    </>
  );
};

export default IntegratedAtm;
