import React, { Dispatch, SetStateAction, useState } from 'react';
import FormLabel from 'components/form-label';
import { FormGroup, Input, Row } from 'reactstrap';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import { UseFormMethods } from 'react-hook-form';

interface IProps {
  deviceConfig: Record<string, string>;
  disabled?: boolean;
  inModal?: boolean;
  setDeviceConfig?: Dispatch<SetStateAction<Record<string, string>>>;
  register?: UseFormMethods['register'];
  setValue?: UseFormMethods['setValue'];
}

export const CUSTOM_RECIEPT_OPTIONS = [
  {
    name: 'Customer Payment Receipt',
    value: 'CUSTOMER',
  },
  {
    name: 'Merchant Payment Receipt',
    value: 'MERCHANT',
  },
  {
    name: 'Both Payment Receipts',
    value: 'BOTH',
  },
  {
    name: 'Only Treez Receipt',
    value: 'TREEZ',
  },
  {
    name: 'None',
    value: 'NONE',
  },
];
const FullDeviceConfig = ({
  deviceConfig,
  disabled = true,
  inModal = true,
  setDeviceConfig,
  register,
  setValue,
}: IProps) => {
  const [printOptionSetting, setPrintOptionSetting] = useState(
    deviceConfig.printConfig ? 'Custom' : 'Default'
  );
  const [authCodeSetting, setAuthCodeSetting] = useState(
    deviceConfig?.deviceAuthCode ? 'Custom' : 'Default'
  );

  return (
    <>
      <div className={!inModal ? 'ml-4 pt-2' : ''}>
        <FormGroup className="mb-3">
          <FormLabel
            element="register_id"
            label="Register Id"
            className={styles.fieldLabel}
          />
          <Input
            name="register_id"
            id="register_id"
            className={classNames(
              inModal ? 'w-100' : styles.fieldWidth,
              'mt-1'
            )}
            disabled={disabled}
            defaultValue={deviceConfig.registerId}
            onChange={(e) => {
              if (inModal && setDeviceConfig) {
                deviceConfig.registerId = e.currentTarget.value;
                setDeviceConfig({ ...deviceConfig });
              }
            }}
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <FormLabel
            element="tpn"
            label="Device TPN"
            className={styles.fieldLabel}
          />
          <Input
            name="tpn"
            id="tpn"
            className={classNames(
              inModal ? 'w-100' : styles.fieldWidth,
              'mt-1'
            )}
            disabled={disabled}
            defaultValue={deviceConfig.tpn}
            onChange={(e) => {
              if (inModal && setDeviceConfig) {
                deviceConfig.tpn = e.currentTarget.value;
                setDeviceConfig({ ...deviceConfig });
              }
            }}
          />
        </FormGroup>
        <Row className="pl-3">
          <FormGroup className="mb-3">
            <FormLabel
              element="type_printing_option"
              label="Payment Receipt Printing Option"
              className={styles.fieldLabel}
            />
            <Input
              type="select"
              name="type_printing_option"
              id="type_printing_option"
              className={classNames(
                inModal ? styles.fieldWidthModal : styles.fieldWidth,
                'mt-1 py-2'
              )}
              disabled={disabled}
              onChange={(e) => {
                if (setDeviceConfig && e.currentTarget.value === 'Default') {
                  deviceConfig.printConfig = 'NONE';
                  setDeviceConfig({ ...deviceConfig });
                }
                setPrintOptionSetting(e.currentTarget.value);
              }}
              defaultValue={printOptionSetting}
            >
              <option value="Default">Default</option>
              <option value="Custom">Custom</option>
            </Input>
          </FormGroup>
          <FormGroup className="mb-3">
            <Input
              type="select"
              name="printing_option"
              id="printing_option"
              className={classNames(
                inModal ? styles.fieldWidthModal : styles.fieldWidth,
                'mt-6 ml-5 py-2'
              )}
              disabled={disabled || printOptionSetting === 'Default'}
              value={deviceConfig?.printConfig || 'NONE'}
              onChange={(e) => {
                if (inModal && setDeviceConfig) {
                  deviceConfig.printConfig = e.currentTarget.value;
                  setDeviceConfig({ ...deviceConfig });
                }
              }}
            >
              {CUSTOM_RECIEPT_OPTIONS.map((item) => (
                <option value={item.value}>{item.name}</option>
              ))}
            </Input>
          </FormGroup>
        </Row>

        <Row className="pl-3">
          <FormGroup className="mb-3">
            <FormLabel
              element="auth_code_type"
              label="Auth Code"
              className={styles.fieldLabel}
            />
            <Input
              type="select"
              name="auth_code_type"
              id="auth_code_type"
              className={classNames(
                inModal ? styles.fieldWidthModal : styles.fieldWidth,
                'mt-1 py-2'
              )}
              disabled={disabled}
              onChange={(e) => {
                if (setDeviceConfig && e.currentTarget.value === 'Default') {
                  setValue?.('authCode', '');
                  deviceConfig.deviceAuthCode = '';
                  setDeviceConfig({ ...deviceConfig });
                }
                setAuthCodeSetting(e.currentTarget.value);
              }}
              defaultValue={authCodeSetting}
            >
              <option value="Default">Default</option>
              <option value="Custom">Custom</option>
            </Input>
          </FormGroup>
          <FormGroup className="mb-3">
            <Input
              type="password"
              name="authCode"
              id="authCode"
              className={classNames(
                inModal ? styles.fieldWidthModal : styles.fieldWidth,
                'mt-6 ml-5'
              )}
              innerRef={register && register()}
              disabled={disabled || authCodeSetting === 'Default'}
              defaultValue={
                deviceConfig?.deviceAuthCode
                  ? `%${deviceConfig.registerId}%`
                  : ''
              }
              onChange={(e) => {
                if (inModal && setDeviceConfig) {
                  deviceConfig.deviceAuthCode = e.currentTarget.value;
                  setDeviceConfig({ ...deviceConfig });
                }
              }}
            />
          </FormGroup>
        </Row>
      </div>
    </>
  );
};

export default FullDeviceConfig;
