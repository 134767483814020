import React from 'react';
import classNames from 'classnames';
import { Input, Label } from 'reactstrap';
import styles from './styles.module.scss';

export interface IRadioConfig {
  detailsElement: JSX.Element;
  value: string;
  onChangeHandler: (arg: string) => void;
  selected: boolean;
  label?: string;
  icon?: JSX.Element;
}

const RadioButtonCard = ({
  config,
  name,
  cardStyles,
}: {
  config: IRadioConfig;
  name: string;
  cardStyles?: Record<string, string>;
}) => (
  <Label
    for={config.value}
    className={classNames(styles.card, config.selected && styles.cardSelected)}
    style={cardStyles}
    onClick={() => config.onChangeHandler(config.value)}
  >
    <div className="flex-grow-1">
      {config?.icon}
      <span>{config.detailsElement}</span>
    </div>
    <div>
      <Input
        type="radio"
        name={name}
        id={config.value}
        value={config.value}
        className="m-0"
        onChange={(e) => config.onChangeHandler(e.target.value)}
        checked={config.selected}
        aria-label={config.label}
      />
    </div>
  </Label>
);

export default RadioButtonCard;
