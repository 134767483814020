import axios from 'axios';
import { apiWithOrg } from 'services/api';
import { useStateValue } from 'state';
import { SUMMARY } from 'state/summary/type';
import {
  action,
  actionFailure,
  actionSuccess,
  FETCH_CSV,
  FETCH_REFUNDS_SUMMARY_DATA,
  GET_REFUND_LIST,
  INITIATE_REFUND,
  RESET_REFUND_STATE,
  RESET_RETRY_STATE,
  RETRY_TRANSACTION,
  VERIFY_OTP,
  CLEAR_EXPORT,
} from '../actions';
import { REFUNDS } from '../type';

const useRefunds = () => {
  const { state, dispatch } = useStateValue();
  const { refunds } = state;

  const verifyOtp = async (values: REFUNDS.IFetchOtpVerification) => {
    dispatch(action(VERIFY_OTP));
    try {
      await apiWithOrg.post(`otp_verification`, values);
      dispatch(actionSuccess(VERIFY_OTP, 'true'));
    } catch (e) {
      dispatch(actionFailure(VERIFY_OTP, e));
    }
  };

  const initiateRefund = async (charge_id: string) => {
    dispatch(action(INITIATE_REFUND));
    try {
      const response = await apiWithOrg.post(`/refunds`, { charge_id });
      dispatch(actionSuccess(INITIATE_REFUND, response));
    } catch (e) {
      const errorMessage = axios.isAxiosError(e)
        ? e.response?.data.error_code
        : e instanceof Error
        ? e.message
        : null;

      dispatch(
        actionFailure(INITIATE_REFUND, {
          message: errorMessage,
        })
      );
    }
  };

  const retryTransaction = async (charge_id: string) => {
    dispatch(action(RETRY_TRANSACTION));
    try {
      const response = await apiWithOrg.post(`/charges/${charge_id}/retry`);
      dispatch(actionSuccess(RETRY_TRANSACTION, response));
      return true;
    } catch (e) {
      dispatch(actionFailure(RETRY_TRANSACTION, e));
      return false;
    }
  };

  const toFetchRefundsList = async (values: REFUNDS.IFetchRefunds) => {
    dispatch(action(GET_REFUND_LIST));
    try {
      const response = await apiWithOrg.get(`/refunds`, values);
      dispatch(actionSuccess(GET_REFUND_LIST, response?.data));
    } catch (e) {
      dispatch(actionFailure(GET_REFUND_LIST, e));
    }
  };

  const toFetchRefundsSummaryData = async (values: SUMMARY.ISummaryRequest) => {
    dispatch(action(FETCH_REFUNDS_SUMMARY_DATA));
    try {
      const response = await apiWithOrg.get(`/refunds/aggregate`, values);
      dispatch(actionSuccess(FETCH_REFUNDS_SUMMARY_DATA, response?.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_REFUNDS_SUMMARY_DATA, e));
    }
  };

  const toFetchRefundsCSV = async (values: REFUNDS.IFetchCSVTransactions) => {
    dispatch(action(FETCH_CSV));
    try {
      values.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await apiWithOrg.get(`refunds/export`, values);
      if (response.status === 204) {
        dispatch(
          actionFailure(FETCH_CSV, {
            data: { message: 'No records available for given date range' },
          })
        );
      } else {
        dispatch(actionSuccess(FETCH_CSV, response?.data));
      }
    } catch (e) {
      dispatch(actionFailure(FETCH_CSV, e));
    }
  };

  const toClearRefundsExport = () => {
    dispatch(action(CLEAR_EXPORT));
  };

  const resetRefundState = () => {
    dispatch(action(RESET_REFUND_STATE));
  };
  const resetRetryState = () => {
    dispatch(action(RESET_RETRY_STATE));
  };

  return {
    refunds,
    verifyOtp,
    initiateRefund,
    retryTransaction,
    toFetchRefundsList,
    resetRefundState,
    resetRetryState,
    toFetchRefundsSummaryData,
    toFetchRefundsCSV,
    toClearRefundsExport,
  };
};
export default useRefunds;
