import {
  FETCH_CUSTOMERS,
  FETCH_CUSTOMERS_FAILURE,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_INDIVIDUAL_CUSTOMER,
  FETCH_INDIVIDUAL_CUSTOMER_FAILURE,
  FETCH_INDIVIDUAL_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAILURE,
  FETCH_CUSTOMERS_CSV_FAILURE,
  FETCH_CUSTOMERS_CSV_SUCCESS,
  FETCH_CUSTOMERS_CSV,
  CLEAR_CUSTOMER_EXPORT,
  REINSTATE_CONSUMER,
  REINSTATE_CONSUMER_SUCCESS,
  REINSTATE_CONSUMER_FAILURE,
} from './actions';
import { CONSUMERS } from './type';

export const INITIAL_STATE: CONSUMERS.ConsumerReducer = {
  isLoading: false,
  error: {} as CONSUMERS.Error,
  customers: {} as CONSUMERS.ICustomers,
  individualCustomer: {} as CONSUMERS.IIndividualCustomer,
  removedCustomer: false,
  url: '',
};

const consumerReducer = (
  action: IAction,
  state = INITIAL_STATE
): CONSUMERS.ConsumerReducer => {
  switch (action.type) {
    case FETCH_INDIVIDUAL_CUSTOMER:
      return {
        ...state,
        isLoading: true,
        individualCustomer: {} as CONSUMERS.IIndividualCustomer,
        error: {},
      };
    case FETCH_INDIVIDUAL_CUSTOMER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        individualCustomer: action.payload,
        error: {},
      };
    case FETCH_INDIVIDUAL_CUSTOMER_FAILURE:
      return {
        ...state,
        isLoading: false,
        individualCustomer: {} as CONSUMERS.IIndividualCustomer,
        error: { addIndividualCustomer: action.payload.message },
      };
    case FETCH_CUSTOMERS:
      return {
        ...state,
        isLoading: true,
        customers: {} as CONSUMERS.ICustomers,
        error: {},
      };
    case FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        customers: action.payload,
        error: {},
      };
    case FETCH_CUSTOMERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { customers: action.payload.message },
      };
    case DELETE_CUSTOMER:
      return {
        ...state,
        isLoading: true,
        removedCustomer: false,
        error: {},
      };
    case DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        removedCustomer: true,
        error: {},
      };
    case DELETE_CUSTOMER_FAILURE:
      return {
        ...state,
        isLoading: false,
        removedCustomer: false,
        error: { removedCustomer: action.payload },
      };
    case FETCH_CUSTOMERS_CSV:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case FETCH_CUSTOMERS_CSV_SUCCESS:
      return {
        ...state,
        isLoading: false,
        url: action.payload.url,
        error: {},
      };
    case FETCH_CUSTOMERS_CSV_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { addUrl: action.payload.data },
      };
    case CLEAR_CUSTOMER_EXPORT:
      return {
        ...state,
        url: '',
        error: {
          ...state.error,
          addUrl: undefined,
        },
      };
    case REINSTATE_CONSUMER:
      return { ...state, isLoading: true };
    case REINSTATE_CONSUMER_SUCCESS:
      return { ...state, isLoading: false };
    case REINSTATE_CONSUMER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: {
          ...state.error,
          reinstateConsumer: action.payload.message,
        },
      };
    default:
      return state;
  }
};
export default consumerReducer;
