import React from 'react';
import styles from './styles.module.scss';
import IncentivizeIcon from 'assets/img/store/Incentivize_tips.svg';
import BoostIcon from 'assets/img/store/Boost_avg.svg';
import CheckoutIcon from 'assets/img/store/Faster-checkout.svg';
import BlogIcon from 'assets/img/store/utilisationBlog.png';
import classNames from 'classnames';

const UtilisationInfo = () => {
  const InfoPoint = ({
    icon,
    heading,
    subHeading,
  }: {
    icon: string;
    heading: string;
    subHeading: JSX.Element;
  }) => {
    return (
      <div className={styles.infoPoint}>
        <div>
          <img src={icon} />
        </div>
        <div className="pl-4 flex-grow-0">
          <h2>{heading}</h2>
          <h3>{subHeading}</h3>
        </div>
      </div>
    );
  };

  const InfoPointObj = [
    {
      icon: BoostIcon,
      heading: 'Boost Average Order Value',
      subHeading: (
        <span>
          <span className={styles.oneAppText}>Increase AOV </span>
          by an average of <span className={styles.oneAppText}>20%</span>{' '}
          through the TreezPay integrated payment suite
        </span>
      ),
    },
    {
      icon: CheckoutIcon,
      heading: 'Faster Checkout',
      subHeading: (
        <span>
          <span className={styles.oneAppText}>Expedite </span>
          the checkout process through pushing payments directly through Treez
          POS
        </span>
      ),
    },
    {
      icon: IncentivizeIcon,
      heading: 'Incentivize upselling through tips',
      subHeading: (
        <span>
          Boost morale and incentivize upselling by enabling{' '}
          <span className={styles.oneAppText}>integrated payment tipping </span>{' '}
          functionality
        </span>
      ),
    },
  ];

  const PaymentOption = ({ name, icon }: { name: string; icon: string }) => {
    return (
      <div className={styles.paymentOptionItem}>
        <img src={icon} />
        <div className="body-subtext-medium">{name}</div>
      </div>
    );
  };

  return (
    <div className={styles.requestStoreInfo}>
      <div style={{ width: '600px' }}>
        <div className="d-flex flex-column mb-8" style={{ gap: '20px' }}>
          {InfoPointObj.map((item, ind) => {
            return (
              <InfoPoint
                icon={item.icon}
                heading={item.heading}
                subHeading={item.subHeading}
              />
            );
          })}
        </div>
        <div>
          <div
            className="mb-2 body-subtext-medium"
            style={{ fontSize: '15px', color: '#404040' }}
          >
            Don't just take our word for it -
          </div>
          <div
            className={classNames('d-block mb-3 ', styles.utilisationInfoText)}
          >
            “<span className={styles.infoText}>Higher Level</span> has increased
            its <span className={styles.infoText}>Average Order Value</span>,
            for <span className={styles.infoText}>debit by 20%</span> and for{' '}
            <span className={styles.infoText}>ACH</span> transactions by more
            than <span className={styles.infoText}>30%</span>. Getting{' '}
            <span className={styles.infoText}>budtenders on board</span> to
            educate end-consumers has been simple too. Budtenders have seen an{' '}
            <span className={styles.infoText}>increase in tips</span> which has
            boosted morale, and adherence to store{' '}
            <span className={styles.infoText}>upselling procedures.</span>”
          </div>
          <div className={styles.blogContainer}>
            <span className="pr-3">
              <img src={BlogIcon} />
            </span>
            <span className="d-inline-grid">
              <span className={styles.blogText}>
                How Higher Level Increased Repeat Customers and Were
                Successfully Acquired with Treez
              </span>
              <a
                target="_blank"
                href={'https://www.treez.io/case-studies/higher-level'}
                style={{ color: '#1A4007', fontSize: '14px' }}
                className="d-flex align-items-center mt-2"
              >
                Read here
                <i className="ph-arrow-right pl-1"></i>
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UtilisationInfo;
