import React from 'react';
import classNames from 'classnames';
import { BANKING } from 'state/banking/type';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import { formatToTz } from 'utils/date-format';
import { maskedData } from 'utils/pii';
import styles from '../../banking/styles.module.scss';

interface props {
  info: BANKING.IPayout;
}

const VendorDetails = ({
  info = {} as BANKING.IPayout,
}: props): JSX.Element => {
  const { organisation } = useOrganisation();
  const { settings } = organisation;
  return (
    <>
      <div className="mb-9">
        <h3 className="mb-2 PaymentDetailsHeading">
          Invoice and vendor details
        </h3>
        <hr className="mt-0 mb-10" />
        <div className={classNames(styles.SummaryInfo, 'p-0 mb-2')}>
          <span className={classNames(styles.SummaryHeading)}>
            Invoice date
          </span>
          <span className={classNames(styles.HeadingData)}>
            {formatToTz(
              info?.invoice_date || '',
              settings?.timezone,
              'MMM dd, hh:mm a'
            ) || 'NA'}
          </span>
        </div>
        <div className={classNames(styles.SummaryInfo, 'p-0 mb-2')}>
          <span className={classNames(styles.SummaryHeading)}>
            Invoice number
          </span>
          <span className={classNames(styles.HeadingData)}>
            {info?.invoice_number || 'NA'}
          </span>
        </div>
        <div className={classNames(styles.SummaryInfo, 'p-0 mb-2')}>
          <span className={classNames(styles.SummaryHeading)}>
            Vendors Name
          </span>
          <div
            className={classNames(
              styles.HeadingData,
              'd-inline-block text-capitalize'
            )}
          >
            {info?.vendor_name || 'NA'}
          </div>
        </div>
        <div className={classNames(styles.SummaryInfo, 'p-0 mb-2')}>
          <span className={classNames(styles.SummaryHeading)}>
            Vendors EIN/SSN
          </span>
          <span className={classNames(styles.HeadingData)}>
            {maskedData(info?.vendor_proof_id || undefined) || 'NA'}
          </span>
        </div>
        <div className={classNames(styles.SummaryInfo, 'p-0 mb-2')}>
          <span className={classNames(styles.SummaryHeading)}>Email</span>
          <span className={classNames(styles.HeadingData)}>
            {info?.customer_first_name === 'auto_pay'
              ? 'NA'
              : info?.customer_email}
          </span>
        </div>
        <div className={classNames(styles.SummaryInfo, 'p-0 mb-2')}>
          <span className={classNames(styles.SummaryHeading)}>
            Phone number
          </span>
          <span className={classNames(styles.HeadingData)}>
            {info?.customer_phone || 'NA'}
          </span>
        </div>
      </div>
    </>
  );
};

export default VendorDetails;
