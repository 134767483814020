import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { UncontrolledTooltip } from 'reactstrap';
import CURRENCY from 'utils/currency';
import classNames from 'classnames';
import styles from '../transactions.module.scss';
import { getPercentChange } from 'utils/dashboard';
import { AmountChangePercentLabel } from 'utils/status-formatter';
import usePayment from 'state/payment/hooks/usePayment';
import { formatDate } from 'utils/date-format';

interface IProps {
  prevFromDate: string;
  prevToDate: string;
}

const TotalTransactionSummary = ({ prevFromDate, prevToDate }: IProps) => {
  const { payment } = usePayment();
  const { transactionSummary: summary } = payment;
  const tooltipText = `Compared to previous period: ${formatDate(
    prevFromDate,
    'MMM dd yyyy, hh:mm a'
  )} - ${formatDate(prevToDate, 'MMM dd yyyy, hh:mm a')}`;

  const totalAmountPercentChange = getPercentChange(
    summary.total_amount,
    summary.prev_total_amount
  );

  const totalTransactionsPercentChange = getPercentChange(
    summary.total_count,
    summary.prev_total_count
  );

  const aovPercentChange = getPercentChange(
    summary.total_amount / summary.total_count,
    summary.prev_total_amount / summary.prev_total_count
  );

  const products = [
    {
      text: 'TOTALS',
      totalAmount: summary.total_amount,
      totalTransactions: summary.total_count,
      aov: summary.total_amount / summary.total_count,
    },
  ];

  const columns = [
    {
      dataField: 'text',
      text: '',
      style: {
        width: '35%',
      },
      headerStyle: {
        height: '48px',
      },
      formatter: (cell: string) => {
        return (
          <div className={classNames(styles.totalSummaryHeader)}>
            {cell ? cell.toUpperCase() : '' || ''}
          </div>
        );
      },
    },
    {
      dataField: 'totalAmount',
      text: 'GROSS SALES',
      formatter: (cell: number) => {
        return (
          <div
            className={classNames(
              'currencyColumn body-medium text-left d-flex',
              styles.totalSummaryHeader
            )}
          >
            {cell ? CURRENCY.convertToMainUnit(cell) : '$0.00'}
            <span
              className={classNames(styles.tipPercentChange)}
              id="grossSalesTooltip"
            >
              {(totalAmountPercentChange || totalAmountPercentChange === 0) && (
                <AmountChangePercentLabel value={totalAmountPercentChange} />
              )}
              {tooltipText && (
                <>
                  <UncontrolledTooltip
                    placement="bottom"
                    target="grossSalesTooltip"
                    modifiers={{
                      preventOverflow: { boundariesElement: 'window' },
                    }}
                  >
                    {tooltipText}
                  </UncontrolledTooltip>
                </>
              )}
            </span>
          </div>
        );
      },
      style: {
        width: '21.67%',
        height: '64px',
      },
    },
    {
      dataField: 'totalTransactions',
      text: 'TOTAL TRANSACTIONS',
      style: {
        width: '21.67%',
      },
      formatter: (cell: number) => {
        return (
          <div
            className={classNames(
              'currencyColumn body-medium text-left d-flex',
              styles.totalSummaryHeader
            )}
          >
            {cell}
            <span
              className={classNames(styles.tipPercentChange)}
              id="totalTransactionsTooltip"
            >
              {(totalTransactionsPercentChange ||
                totalTransactionsPercentChange === 0) && (
                <AmountChangePercentLabel
                  value={totalTransactionsPercentChange}
                />
              )}
              {tooltipText && (
                <>
                  <UncontrolledTooltip
                    placement="bottom"
                    target="totalTransactionsTooltip"
                    modifiers={{
                      preventOverflow: { boundariesElement: 'window' },
                    }}
                  >
                    {tooltipText}
                  </UncontrolledTooltip>
                </>
              )}
            </span>
          </div>
        );
      },
    },
    {
      dataField: 'aov',
      text: 'AOV',
      formatter: (cell: number) => {
        return (
          <div
            className={classNames(
              'currencyColumn body-medium text-left d-flex',
              styles.totalSummaryHeader
            )}
          >
            {cell ? CURRENCY.convertToMainUnit(cell) : '$0.00'}
            <span
              className={classNames(styles.tipPercentChange)}
              id="aovTooltip"
            >
              {(aovPercentChange || aovPercentChange === 0) && (
                <AmountChangePercentLabel value={aovPercentChange} />
              )}
              {tooltipText && (
                <>
                  <UncontrolledTooltip
                    placement="bottom"
                    target="aovTooltip"
                    modifiers={{
                      preventOverflow: { boundariesElement: 'window' },
                    }}
                  >
                    {tooltipText}
                  </UncontrolledTooltip>
                </>
              )}
            </span>
          </div>
        );
      },
      style: {
        width: '21.67%',
      },
    },
  ];

  return (
    <div className={classNames(styles.tableRoundedBorder)}>
      <BootstrapTable
        id="totalTransactionSummary"
        wrapperClasses="table-responsive"
        keyField="id"
        data={products}
        columns={columns}
        bordered={false}
        hover
        condensed
        bootstrap4
        rowStyle={(_, rowIndex) => {
          if (rowIndex === 0) {
            return { backgroundColor: '#f7f8f8' };
          }
          return {};
        }}
      />
    </div>
  );
};

export default TotalTransactionSummary;
