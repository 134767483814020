import classNames from 'classnames';
import SelectDropdown, { DropdownConfig } from 'components/selectDropdown';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import useBanking from 'state/banking/hooks/useBanking';
import {
  CREATE_PAYOUTS,
  EXTERNAL_PAYOUT_STAGES,
  IActiveState,
  PAYOUTS_NAV_CONFIG,
  PAYOUT_STAGE,
} from 'components/modals/banking/payouts/payout';
import { BANKING } from 'state/banking/type';
import ErrorMessage from 'components/errorMessage';
import styles from '../../styles.module.scss';

interface Iprops {
  setActiveState: Dispatch<SetStateAction<IActiveState>>;
  activeState: IActiveState;
  dropdownConfig: DropdownConfig<BANKING.IVendorAccountList>;
  setDropdownConfig: Dispatch<
    SetStateAction<DropdownConfig<BANKING.IVendorAccountList>>
  >;
}

const ListBankAccounts = ({
  activeState,
  setActiveState,
  dropdownConfig,
  setDropdownConfig,
}: Iprops): JSX.Element => {
  const { setVendorAccount } = useBanking();

  const [error, setError] = useState(false);

  useEffect(() => {
    if (dropdownConfig.isSelected) {
      setError(false);
    }
  }, [dropdownConfig.isSelected]);

  const backButton = () => {
    dropdownConfig.isSelected = false;
    dropdownConfig.addNewItem = false;
    dropdownConfig.updateItem = false;
    dropdownConfig.selectedItemData = undefined;
    dropdownConfig.selectedItemName = undefined;
    setDropdownConfig({ ...dropdownConfig });
    PAYOUTS_NAV_CONFIG[CREATE_PAYOUTS.BANK_DETAILS_EXT].isCompleted = false;
    activeState.activeForm = CREATE_PAYOUTS.INVOICE_DETAILS;
    setActiveState({ ...activeState });
    // resetUpdateVendor();
  };

  const nextButton = () => {
    if (dropdownConfig.isSelected && dropdownConfig.selectedItemData) {
      activeState.payoutStage = PAYOUT_STAGE.external;
      activeState.activeForm = EXTERNAL_PAYOUT_STAGES.REVIEW;
      PAYOUTS_NAV_CONFIG[EXTERNAL_PAYOUT_STAGES.BANK_DETAILS].isCompleted =
        true;
      setVendorAccount({
        account_name: dropdownConfig.selectedItemData.beneficiary_name,
        masked_account_number: dropdownConfig.selectedItemData.last_4,
        institution_name: dropdownConfig.selectedItemData.name,
        account_id: dropdownConfig.selectedItemData.id,
      });
      setActiveState({ ...activeState });
    } else {
      setError(true);
    }
  };

  const onSelect = (option: BANKING.IVendorAccountList | null) => {
    if (option === null) {
      dropdownConfig.isSelected = false;
      dropdownConfig.selectedItemName = undefined;
      dropdownConfig.selectedItemData = undefined;
      setDropdownConfig({ ...dropdownConfig });
    } else {
      dropdownConfig.selectedItemName =
        option?.account_short_name || option?.name;
      dropdownConfig.isSelected = true;
      dropdownConfig.selectedItemData = option;
      setDropdownConfig({ ...dropdownConfig });
    }
  };

  useEffect(() => {
    const footerElement = (
      <>
        <Button
          // color="primary"
          className="btn btn-primary mr-2"
          type="submit"
          onClick={() => backButton()}
        >
          <span className="d-flex">Back</span>
        </Button>
        <Button
          color="primary"
          className="btn btn-primary"
          type="submit"
          onClick={() => nextButton()}
        >
          <span className="d-flex">
            Next <i className="ph-arrow-right pl-1"></i>
          </span>
        </Button>
      </>
    );
    activeState.footer = footerElement;
    setActiveState({ ...activeState });
  }, [dropdownConfig.isSelected]);

  const changeToAddBankView = () => {
    dropdownConfig.addNewItem = true;
    setDropdownConfig({ ...dropdownConfig });
  };

  const dropdownValue = (props: any) => {
    const {
      data,
      getStyles,
      isDisabled,
      isFocused,
      isSelected,
      innerRef,
      innerProps,
    } = props;

    return (
      <>
        <div
          ref={innerRef}
          {...innerProps}
          className={classNames(
            'px-4 m-2',
            getStyles('option', props),
            {
              option: true,
              'option--is-disabled': isDisabled,
              'option--is-focused': isFocused,
              'option--is-selected': isSelected,
            },
            styles.dropdownValue
          )}
        >
          <Row>
            <Col xs="6" sm="1">
              <span className={styles.bankIcon}>
                <i className="ph ph-bank-fill text-muted icon-xl" />
              </span>
            </Col>
            <Col xs="6" sm="6">
              <span className={styles.bankName}>
                {data?.account_short_name ||
                  data.beneficiary_name ||
                  'Not available'}
              </span>
              <br />
              <span className={styles.accountNo}>
                {data.beneficiary_name || 'Not available'} &bull;{' '}
                {data.name || 'Not available'} /{' '}
                {data?.last_4 || 'Not available'}
              </span>
            </Col>
          </Row>{' '}
          {/* {data?.name || 'Not available'} */}
        </div>
      </>
    );
  };

  const renderValue = (props: any) => {
    const {
      data,
      getStyles,
      isDisabled,
      isFocused,
      isSelected,
      innerRef,
      innerProps,
    } = props;

    return (
      <>
        <div
          ref={innerRef}
          {...innerProps}
          className={classNames(
            'm-2',
            getStyles('option', props),
            {
              option: true,
              'option--is-disabled': isDisabled,
              'option--is-focused': isFocused,
              'option--is-selected': isSelected,
            },
            styles.selectedDropdownValue
          )}
        >
          <Row>
            <Col xs="6" sm="1">
              <span className={styles.bankIcon}>
                <i className="ph ph-bank-fill text-muted icon-xl" />
              </span>
            </Col>
            <Col xs="6" sm="6">
              <span className={styles.bankName}>
                {data?.account_short_name ||
                  data.beneficiary_name ||
                  'Not available'}
              </span>
              <br />
              <span className={styles.accountNo}>
                {data.beneficiary_name || 'Not available'} &bull;{' '}
                {data.name || 'Not available'} /{' '}
                {data?.last_4 || 'Not available'}
              </span>
            </Col>
          </Row>{' '}
          {/* {data?.name || 'Not available'} */}
        </div>
      </>
    );
  };

  return (
    <>
      <div>
        <SelectDropdown
          buttonText="Add a bank account"
          placeHolder={'Select by bank name, account nick name...'}
          dropdownConfig={dropdownConfig}
          setDropdownConfig={setDropdownConfig}
          onSelect={onSelect}
          getOptionValue={(option: BANKING.IVendorAccountList) =>
            option.id || ''
          }
          getLabel={(option: BANKING.IVendorAccountList) =>
            option?.account_short_name || option?.name || ''
          }
          addScreen={changeToAddBankView}
          dropdownValue={dropdownValue}
          renderValue={renderValue}
          multiLineOption
        />
        {error && (
          <ErrorMessage
            className="mb-0"
            isValidation
            message="Please select a bank account."
            preIcon={
              <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
            }
          />
        )}
      </div>
    </>
  );
};

export default ListBankAccounts;
