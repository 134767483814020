import React, { useEffect } from 'react';
import useStore from 'state/store/hooks/useStore';
import ListStores from './listStores';

const Stores = () => {
  const { toFetchStoresList, store } = useStore();
  const { storesList } = store;

  useEffect(() => {
    if (!Array.isArray(storesList)) toFetchStoresList();
  }, []);

  if (store.isLoading > 0) return <></>;

  return <ListStores storesList={storesList} />;
};

export default Stores;
