// eslint-disable-next-line import/no-extraneous-dependencies
import moment, { Moment } from 'moment';
import React, { Dispatch, SetStateAction } from 'react';
// react plugin used to create datetimepicker
import ReactDatetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import classNames from 'classnames';
import { isDateInRange, isDateInPast } from 'utils/date-format';
import styles from './styles.module.scss';

interface IProps {
  setDate?:
    | Dispatch<SetStateAction<string | undefined>>
    | ((val: string) => void);
  initialValue?: string;
  format: string;
  isEndDate?: boolean;
  // isLocalTime?: boolean;
  afterDate?: string;
  beforeDate?: string;
  placeholder?: string;
  className?: string;
  ref?: React.MutableRefObject<ReactDatetime>;
  onChange?: (value: string | moment.Moment) => void;
  onBlur?: ReactDatetime.EventOrValueHandler<React.FocusEvent<Element>>;
  value?: string;
  name?: string;
  allowManualInput?: boolean;
  disabled?: boolean;
}

const DatePicker = ({
  initialValue = '',
  setDate,
  isEndDate = false,
  // isLocalTime = true,
  afterDate,
  beforeDate,
  placeholder = 'Date',
  className = '',
  format,
  ref,
  onChange,
  onBlur,
  value,
  name,
  allowManualInput = false,
  disabled = false,
}: IProps): JSX.Element => {
  const inputProps = {
    placeholder,
    name,
    readOnly: !allowManualInput,
    disabled,
    'aria-label': 'date',
  };

  const isValidDate = (selected: Moment) => {
    return (
      isDateInRange(selected, afterDate, beforeDate) && isDateInPast(selected)
    );
  };

  const customOnChange = (e: Moment | string) => {
    // TODO: fix timestamp for start and end dates
    if (!setDate) return;
    if (e instanceof moment) {
      if (isEndDate) {
        e = (e as Moment).set('hour', 23).set('minute', 59);
      }
      const dateString = moment((e as Moment).format()).toISOString(true);
      setDate(dateString);
    }
  };

  return (
    <div
      className={classNames(
        styles.container,
        'd-inline-flex align-items-center',
        className
      )}
    >
      <span className="d-flex align-items-center">
        <i className="ph-calendar-blank-light" />
      </span>
      <ReactDatetime
        dateFormat={format || 'MM-dd-yyyy'}
        // utc={!isLocalTime}
        isValidDate={isValidDate}
        defaultValue={initialValue}
        inputProps={inputProps}
        onChange={onChange || customOnChange}
        timeFormat={false}
        closeOnSelect
        className="customPicker"
        ref={ref}
        value={value}
        onBlur={onBlur}
      />
    </div>
  );
};

export default DatePicker;
