import { SUMMARY } from 'state/summary/type';
import { PAYMENTS } from 'state/payment/type';
import {
  ADD_BANK_ACCOUNT,
  ADD_BANK_ACCOUNT_FAILURE,
  ADD_BANK_ACCOUNT_SUCCESS,
  FETCH_BANKING_STATS,
  FETCH_BANKING_STATS_FAILURE,
  FETCH_BANKING_STATS_SUCCESS,
  FETCH_DAILY_BANKING_SUMMARIES,
  FETCH_DAILY_BANKING_SUMMARIES_FAILURE,
  FETCH_DAILY_BANKING_SUMMARIES_SUCCESS,
  FETCH_CREDIT_DETAILS,
  FETCH_CREDIT_DETAILS_FAILURE,
  FETCH_CREDIT_DETAILS_SUCCESS,
  FETCH_DEBIT_DETAILS,
  FETCH_DEBIT_DETAILS_FAILURE,
  FETCH_DEBIT_DETAILS_SUCCESS,
  FETCH_EXPORT,
  FETCH_EXPORT_FAILURE,
  FETCH_EXPORT_SUCCESS,
  FETCH_EXTERNAL_ACCOUNT,
  FETCH_EXTERNAL_ACCOUNT_FAILURE,
  FETCH_EXTERNAL_ACCOUNT_SUCCESS,
  FETCH_TOTAL_BALANCE_DETAILS,
  FETCH_TOTAL_BALANCE_DETAILS_FAILURE,
  FETCH_TOTAL_BALANCE_DETAILS_SUCCESS,
  FETCH_PAYOUT_DETAILS,
  FETCH_PAYOUT_DETAILS_SUCCESS,
  FETCH_PAYOUT_DETAILS_FAILURE,
  INITIATE_SELF_PAYOUT,
  INITIATE_SELF_PAYOUT_FAILURE,
  INITIATE_SELF_PAYOUT_SUCCESS,
  RESET_PAYOUT,
  CLEAR_EXPORT,
  CLEAR_ADD_BANK_ACCOUNT,
  FETCH_PAYOUTS,
  FETCH_PAYOUTS_SUCCESS,
  FETCH_PAYOUTS_FAILURE,
  RESET_PAYOUT_FLOW,
  LIST_VENDORS,
  LIST_VENDORS_FAILURE,
  LIST_VENDORS_SUCCESS,
  CREATE_VENDOR,
  CREATE_VENDOR_FAILURE,
  CREATE_VENDOR_SUCCESS,
  UPDATE_VENDOR,
  UPDATE_VENDOR_FAILURE,
  UPDATE_VENDOR_SUCCESS,
  RESET_VENDOR_UPDATE,
  SET_INVOICE_DETAILS,
  SET_PAYOUT_AMOUNT,
  SET_VENDOR_ID,
  GET_VENDOR_PAYOUT_ACCOUNT_LIST,
  GET_VENDOR_PAYOUT_ACCOUNT_LIST_SUCCESS,
  GET_VENDOR_PAYOUT_ACCOUNT_LIST_FAILURE,
  ADD_VENDOR_BANK_ACCOUNT,
  ADD_VENDOR_BANK_ACCOUNT_SUCCESS,
  ADD_VENDOR_BANK_ACCOUNT_FAILURE,
  FETCH_PAYOUTS_SUMMARY_DATA,
  FETCH_PAYOUTS_SUMMARY_DATA_SUCCESS,
  FETCH_PAYOUTS_SUMMARY_DATA_FAILURE,
  FETCH_TOTAL_FAILED_AMOUNT,
  FETCH_TOTAL_FAILED_AMOUNT_SUCCESS,
  FETCH_TOTAL_FAILED_AMOUNT_FAILURE,
  LIST_FAILED_TRANSACTIONS,
  LIST_FAILED_TRANSACTIONS_SUCCESS,
  LIST_FAILED_TRANSACTIONS_FAILURE,
  RESET_ADD_VENDOR_ACCOUNT,
  INITIATE_EXTERNAL_PAYOUT,
  INITIATE_EXTERNAL_PAYOUT_SUCCESS,
  INITIATE_EXTERNAL_PAYOUT_FAILURE,
  SET_VENDOR_ACCOUNT,
  RESET_PAYOUT_SUCCESS,
  SET_VENDOR_NOTES,
  SET_PAYOUT_TYPE,
  LIST_WRITE_OFF_TRANSACTIONS,
  LIST_WRITE_OFF_TRANSACTIONS_SUCCESS,
  LIST_WRITE_OFF_TRANSACTIONS_FAILURE,
  INITIATE_BATCH_RETRY,
  INITIATE_BATCH_RETRY_SUCCESS,
  INITIATE_BATCH_RETRY_FAILURE,
  INITIATE_WRITE_OFFS,
  INITIATE_WRITE_OFFS_FAILURE,
  INITIATE_WRITE_OFFS_SUCCESS,
  RECORD_CASH,
  RECORD_CASH_FAILURE,
  RECORD_CASH_SUCCESS,
  FETCH_BANKING_SUMMARY_EXPORT,
  FETCH_BANKING_SUMMARY_EXPORT_SUCCESS,
  FETCH_BANKING_SUMMARY_EXPORT_FAILURE,
  FETCH_STATEMENT_TOTAL_BALANCE,
  FETCH_STATEMENT_TOTAL_BALANCE_FAILURE,
  FETCH_STATEMENT_TOTAL_BALANCE_SUCCESS
} from './actions';
import { BANKING } from './type';

export const INITIAL_STATE: BANKING.BankingReducer = {
  isLoading: false,
  error: {} as BANKING.Error,
  stats: {} as BANKING.IBankingStatsData,
  total_gross_details: {} as IPaginatedResponse<BANKING.IBankStatement>,
  credit_details: {} as IPaginatedResponse<BANKING.IBankStatement>,
  debit_details: {} as IPaginatedResponse<BANKING.IBankStatement>,
  export_url: '',
  banking_summary_export_url:"",
  external_account: {} as BANKING.IFetchBankAccount,
  fetchExternalAccount: false,
  add_bank_account: false,
  payoutDetails: {} as BANKING.IPayoutDetails,
  payoutList: {} as IPaginatedResponse<BANKING.Payouts>,
  payout_amount: '',
  vendors: {} as BANKING.IListVendors,
  update_vendor: false,
  vendor_updated: undefined,
  vendor_added: undefined,
  invoice_details: {} as BANKING.IInvoiceDetails,
  vendor_id: undefined,
  vendor_bank_account: undefined,
  vendorAccountList: [] as BANKING.IVendorAccountList[],
  add_vendor_account: false,
  payout_summary:
    {} as SUMMARY.ISummaryResponse<BANKING.IPayoutSummaryBreakdown>,
  failed_transaction_summary: {} as BANKING.TotalFailedSummaryData,
  failed_transactions:
    {} as IPaginatedResponse<BANKING.IIndividualTransactionResult>,
  notes: undefined,
  payout_type: undefined,
  write_off_transactions:
    {} as IPaginatedResponse<BANKING.IWriteOffTransaction>,
  batch_retry: {} as BANKING.IBatchRetryResult,
  initiate_write_off: {} as BANKING.IWriteOffTransaction,
  cash_recorded: {} as PAYMENTS.IIndividualTransactionResult,
  auth_session: {} as BANKING.IGetUserSession,
  transaction_summary_daily: {} as Record<
    BANKING.BankingTxnType,
    IPaginatedResponse<SUMMARY.ISummaryData<BANKING.IBankSummaryBreakdown>>
  >,
  total_balance: {} as BANKING.IBankingTotalBalance,
};

const bankingReducer = (
  action: IAction,
  state = INITIAL_STATE
): BANKING.BankingReducer => {
  switch (action.type) {
    case FETCH_BANKING_STATS:
      return {
        ...state,
        isLoading: true,
        error: {},
      };

    case FETCH_BANKING_STATS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        stats: action.payload,
        error: {},
      };

    case FETCH_BANKING_STATS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { fetchStats: action?.payload?.message },
      };

      case FETCH_STATEMENT_TOTAL_BALANCE:
        return {
          ...state,
          isLoading: true,
          total_balance: {} as BANKING.IBankingTotalBalance,
          error: {},
        };
  
      case FETCH_STATEMENT_TOTAL_BALANCE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          total_balance: action.payload,
          error: {},
        };
  
      case FETCH_STATEMENT_TOTAL_BALANCE_FAILURE:
        return {
          ...state,
          isLoading: false,
          total_balance: {} as BANKING.IBankingTotalBalance,
          error: { fetchStats: action?.payload?.message },
        };

    case FETCH_PAYOUTS:
      return {
        ...state,
        isLoading: true,
        payoutList: {} as IPaginatedResponse<BANKING.Payouts>,
        error: {},
      };

    case FETCH_PAYOUTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        payoutList: action.payload,
        error: {},
      };

    case FETCH_PAYOUTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        payoutList: {} as IPaginatedResponse<BANKING.Payouts>,
        error: { fetchPayouts: action?.payload?.message },
      };

    case FETCH_DAILY_BANKING_SUMMARIES:
      return {
        ...state,
        isLoading: true,
        error: {},
      };

    case FETCH_DAILY_BANKING_SUMMARIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        transaction_summary_daily: {
          ...state?.transaction_summary_daily,
          [action.payload.type]: action.payload.summary,
        },
        error: {},
      };

    case FETCH_DAILY_BANKING_SUMMARIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { fetchSummaries: action.payload.message },
      };
    case FETCH_TOTAL_BALANCE_DETAILS:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case FETCH_TOTAL_BALANCE_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        total_gross_details: action.payload,
        error: {},
      };
    case FETCH_TOTAL_BALANCE_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { fetchGrossDetails: action?.payload?.message },
        total_gross_details: {} as IPaginatedResponse<BANKING.IBankStatement>,
      };
    case FETCH_CREDIT_DETAILS:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case FETCH_CREDIT_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        credit_details: action.payload,
        error: {},
      };
    case FETCH_CREDIT_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        credit_details: {} as IPaginatedResponse<BANKING.IBankStatement>,
        error: { creditDetails: action?.payload?.message },
      };
    case FETCH_DEBIT_DETAILS:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case FETCH_DEBIT_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        debit_details: action.payload,
        error: {},
      };
    case FETCH_DEBIT_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        debit_details: {} as IPaginatedResponse<BANKING.IBankStatement>,
        error: { debitDetails: action?.payload?.message },
      };
    case FETCH_EXPORT:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case FETCH_EXPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        export_url: action.payload.url,
        error: {},
      };
    case FETCH_EXPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { exportUrl: action?.payload?.message },
      };
    case INITIATE_EXTERNAL_PAYOUT:
      return {
        ...state,
        isLoading: true,
        auth_session: {} as BANKING.IGetUserSession,
        error: {},
      };
    case INITIATE_EXTERNAL_PAYOUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        auth_session: action.payload,
        error: {},
      };
    case INITIATE_EXTERNAL_PAYOUT_FAILURE:
      return {
        ...state,
        isLoading: false,
        auth_session: {} as BANKING.IGetUserSession,
        error: { initiateExternalPayout: action?.payload?.data },
      };
    case INITIATE_SELF_PAYOUT:
      return {
        ...state,
        isLoading: true,
        auth_session: {} as BANKING.IGetUserSession,
        error: {},
      };
    case INITIATE_SELF_PAYOUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        auth_session: action?.payload,
        error: {},
      };
    case INITIATE_SELF_PAYOUT_FAILURE:
      return {
        ...state,
        isLoading: false,
        auth_session: {} as BANKING.IGetUserSession,
        error: { initiateSelfPayout: action?.payload?.data },
      };
    case FETCH_EXTERNAL_ACCOUNT:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case FETCH_EXTERNAL_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        external_account: action.payload,
        fetchExternalAccount: true,
        error: {},
      };
    case FETCH_EXTERNAL_ACCOUNT_FAILURE:
      return {
        ...state,
        isLoading: false,
        external_account: {} as BANKING.IFetchBankAccount,
        fetchExternalAccount: true,
        error: { externalAccount: action?.payload?.message },
      };
    case RESET_PAYOUT:
      return {
        ...state,
        auth_session: {} as BANKING.IGetUserSession,
        error: { initiateSelfPayout: undefined },
      };
    case ADD_BANK_ACCOUNT:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case ADD_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        add_bank_account: true,
        error: {},
      };
    case ADD_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { addBankAccount: action?.payload?.data },
      };

    case ADD_VENDOR_BANK_ACCOUNT:
      return {
        ...state,
        isLoading: true,
        add_vendor_account: false,
        error: {},
      };
    case ADD_VENDOR_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        add_vendor_account: true,
        error: {},
      };
    case ADD_VENDOR_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        isLoading: false,
        add_vendor_account: false,
        error: { addVendorAccount: action?.payload?.data },
      };

    case FETCH_PAYOUT_DETAILS:
      return {
        ...state,
        isLoading: true,
        error: {},
      };

    case FETCH_PAYOUT_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        payoutDetails: action.payload,
        error: {},
      };

    case FETCH_PAYOUT_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { fetchPayout: action?.payload?.message },
      };

    case CLEAR_EXPORT:
      return {
        ...state,
        export_url: '',
        error: {
          ...state.error,
          exportUrl: undefined,
        },
      };

    case CLEAR_ADD_BANK_ACCOUNT:
      return {
        ...state,
        add_bank_account: false,
        error: { addBankAccount: undefined },
      };

    case SET_INVOICE_DETAILS:
      return {
        ...state,
        invoice_details: action.payload,
      };

    case RESET_PAYOUT_FLOW:
      return {
        ...state,
        auth_session: {} as BANKING.IGetUserSession,
        invoice_details: undefined,
        error: {},
        vendor_id: undefined,
        notes: undefined,
      };

    case RESET_PAYOUT_SUCCESS:
      return {
        ...state,
        payout_amount: '',
        auth_session: {} as BANKING.IGetUserSession,
        vendor_bank_account: undefined,
        payout_type: undefined,
      };

    case GET_VENDOR_PAYOUT_ACCOUNT_LIST:
      return {
        ...state,
        isLoading: true,
        vendorAccountList: [] as BANKING.IVendorAccountList[],
        error: {},
      };

    case GET_VENDOR_PAYOUT_ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        vendorAccountList: action.payload,
        error: {},
      };

    case GET_VENDOR_PAYOUT_ACCOUNT_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        vendorAccountList: [] as BANKING.IVendorAccountList[],
        error: { vendorAccountList: action?.payload?.message },
      };

    case SET_PAYOUT_AMOUNT:
      return {
        ...state,
        payout_amount: action.payload,
      };
    case SET_VENDOR_ID:
      return {
        ...state,
        vendor_id: action.payload,
      };
    case SET_VENDOR_ACCOUNT:
      return {
        ...state,
        vendor_bank_account: action.payload,
      };
    case LIST_VENDORS:
      return {
        ...state,
        vendors: action.payload,
        isLoading: true,
      };
    case LIST_VENDORS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        vendors: action.payload,
        error: {},
      };

    case LIST_VENDORS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { fetchVendors: action?.payload?.message },
      };

    case CREATE_VENDOR:
      return {
        ...state,
        vendor_added: undefined,
        isLoading: true,
      };
    case CREATE_VENDOR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        vendor_added: action.payload,
        error: {},
      };

    case CREATE_VENDOR_FAILURE:
      return {
        ...state,
        isLoading: false,
        vendor_added: undefined,
        error: { createVendor: action?.payload?.data },
      };
    case UPDATE_VENDOR:
      return {
        ...state,
        vendor_updated: undefined,
        isLoading: true,
        update_vendor: false,
      };
    case UPDATE_VENDOR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        vendor_updated: action.payload,
        update_vendor: true,
        error: {},
      };

    case SET_VENDOR_NOTES:
      return {
        ...state,
        notes: action.payload,
      };
    case UPDATE_VENDOR_FAILURE:
      return {
        ...state,
        isLoading: false,
        vendor_updated: undefined,
        error: { fetchVendors: action?.payload?.message },
        update_vendor: false,
      };
    case RESET_VENDOR_UPDATE:
      return {
        ...state,
        update_vendor: false,
        vendor_added: undefined,
        vendor_updated: undefined,
        error: { createVendor: undefined },
      };

    case FETCH_PAYOUTS_SUMMARY_DATA:
      return {
        ...state,
        isLoading: true,
        payout_summary:
          {} as SUMMARY.ISummaryResponse<BANKING.IPayoutSummaryBreakdown>,
      };
    case FETCH_PAYOUTS_SUMMARY_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        payout_summary: action.payload,
      };
    case FETCH_PAYOUTS_SUMMARY_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        payout_summary:
          {} as SUMMARY.ISummaryResponse<BANKING.IPayoutSummaryBreakdown>,

        error: { fetchPayoutsSummaryData: action?.payload?.message },
      };
    case FETCH_TOTAL_FAILED_AMOUNT:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case FETCH_TOTAL_FAILED_AMOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        failed_transaction_summary:
          action.payload as BANKING.TotalFailedSummaryData,
        error: {},
      };
    case FETCH_TOTAL_FAILED_AMOUNT_FAILURE:
      return {
        ...state,
        isLoading: false,
        failed_transaction_summary: {} as BANKING.TotalFailedSummaryData,
        error: { totalFailedAmount: action?.payload?.message },
      };
    case LIST_FAILED_TRANSACTIONS:
      return {
        ...state,
        isLoading: true,
        failed_transactions:
          {} as IPaginatedResponse<BANKING.IIndividualTransactionResult>,
        error: {},
      };
    case LIST_FAILED_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        failed_transactions:
          action.payload as IPaginatedResponse<BANKING.IIndividualTransactionResult>,
        error: {},
      };

    case LIST_FAILED_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        failed_transactions:
          {} as IPaginatedResponse<BANKING.IIndividualTransactionResult>,
        error: { fetchFailedTransactions: action?.payload?.message },
      };
    case LIST_WRITE_OFF_TRANSACTIONS:
      return {
        ...state,
        isLoading: true,
        write_off_transactions:
          {} as IPaginatedResponse<BANKING.IWriteOffTransaction>,
        error: {},
      };
    case LIST_WRITE_OFF_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        write_off_transactions:
          action.payload as IPaginatedResponse<BANKING.IWriteOffTransaction>,
        error: {},
      };

    case LIST_WRITE_OFF_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        write_off_transactions:
          {} as IPaginatedResponse<BANKING.IWriteOffTransaction>,
        error: { fetchWriteOffs: action?.payload?.message },
      };

    case INITIATE_BATCH_RETRY:
      return {
        ...state,
        isLoading: true,
        batch_retry: {} as BANKING.IBatchRetryResult,
        error: {},
      };
    case INITIATE_BATCH_RETRY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        batch_retry: action.payload,
        error: {},
      };

    case INITIATE_BATCH_RETRY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { batchRetry: action?.payload?.message },
      };
    case RECORD_CASH:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case RECORD_CASH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cash_recorded: action.payload,
        error: {},
      };

    case RECORD_CASH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { cashRecord: action?.payload?.message },
      };

    case INITIATE_WRITE_OFFS:
      return {
        ...state,
        isLoading: true,
        auth_session: {} as BANKING.IGetUserSession,
        initiate_write_off: {} as BANKING.IWriteOffTransaction,
        error: {},
      };
    case INITIATE_WRITE_OFFS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        auth_session: action.payload,
        error: {},
      };

    case INITIATE_WRITE_OFFS_FAILURE:
      return {
        ...state,
        isLoading: false,
        auth_session: {} as BANKING.IGetUserSession,
        initiate_write_off: {} as BANKING.IWriteOffTransaction,
        error: { writeoff: action?.payload?.data },
      };

    case RESET_ADD_VENDOR_ACCOUNT:
      return {
        ...state,
        add_vendor_account: false,
        error: { addVendorAccount: undefined },
      };
    case SET_PAYOUT_TYPE:
      return {
        ...state,
        payout_type: action.payload,
      };
      case FETCH_BANKING_SUMMARY_EXPORT:
        return {
          ...state,
          isLoading: true,
          error: {},
        };
      case FETCH_BANKING_SUMMARY_EXPORT_SUCCESS:
        return {
          ...state,
          isLoading: false,
          banking_summary_export_url: action.payload.url,
          error: {},
        };
      case FETCH_BANKING_SUMMARY_EXPORT_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: { bankingSummaryExportUrl: action?.payload?.message },
        };
    default:
      return state;
  }
};
export default bankingReducer;
