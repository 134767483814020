import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import classNames from 'classnames';
import styles from 'views/failedTransactions/retryModal/styles.module.scss';
import ModalContainer from 'components/modals/container';
import BootstrapTable, { SortOrder } from 'react-bootstrap-table-next';
import { formatToTz } from 'utils/date-format';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import { BANKING } from 'state/banking/type';
import useBanking from 'state/banking/hooks/useBanking';
import CURRENCY from 'utils/currency';
import { notRetryableReason } from 'utils/notRetryableTooltip';
import modalStyles from '../styles.module.scss';

interface IProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  data: Array<BANKING.IIndividualTransactionResult>;
}

const MultiRetryModal = ({
  showModal,
  setShowModal,
  data,
}: IProps): JSX.Element => {
  const [nonRetryAbleCount, setNonRetryAbleCount] = useState<number>(0);
  const [retryAbleCount, setRetryAbleCount] = useState<number>(0);
  const [selectedDataList, setSelectedDataList] = useState(data);

  const { toInitiateBatchRetry } = useBanking();
  const { organisation } = useOrganisation();
  const { settings } = organisation;
  const warningTextHeading1 = 'Unable to retry few transactions';
  const warningTextHeading2 = 'Unable to retry all transactions';
  const warningTextHeading3 = 'No transactions to retry';

  const warningMessage1 =
    'Few of the selected transactions cannot be retried. Please remove any transaction that has a red cross next to it and then retry.';
  const warningMessage2 =
    'All of the selected transactions cannot be retried. Please cancel and retry transactions which can be retried. ';
  const warningMessage3 =
    'Please close and select atleast one transaction to retry.';
  const successText =
    'All the below transactions can be retried. Please proceed to retry all of them at once. ';

  const toggle = () => {
    setShowModal(false);
  };

  const retryWarning = (heading_text: string, message: string) => {
    return (
      <div className={classNames(styles.warningBox)}>
        <div className="d-flex">
          <i
            className={classNames(
              'ph-bold ph-warning-circle-fill mr-1',
              styles.warningIcon
            )}
          />
          <div className="ml-2">
            <p className={classNames(styles.warningBoxHeading)}>
              {heading_text}
            </p>
            <p className={classNames(styles.warningText)}>{message}</p>
          </div>
        </div>
      </div>
    );
  };

  const emptyModalTableWarning = (
    <>
      <div className={classNames(styles.emptyTableWarningBox)}>
        <div className="d-flex">
          <i
            className={classNames(
              'ph-bold ph-warning-circle-fill mr-1',
              styles.warningIcon
            )}
          />
          <div>
            <p className={classNames(styles.warningBoxHeading)}>
              {warningTextHeading3}
            </p>
            <p className={classNames(styles.warningText)}>{warningMessage3}</p>
          </div>
        </div>
      </div>
    </>
  );

  const successWarning = (
    <div className={classNames(styles.successWarningBox)}>
      <div className="d-flex">
        <i
          className={classNames(
            'ph-bold ph-check-circle-fill mr-1',
            styles.successIcon
          )}
        />
        <div>
          <p className={classNames(styles.successWarningText)}>{successText}</p>
        </div>
      </div>
    </div>
  );

  const getWarningBanner = () => {
    if (
      retryAbleCount === 0 &&
      nonRetryAbleCount === 0 &&
      selectedDataList.length === 0
    ) {
      return emptyModalTableWarning;
    }
    if (retryAbleCount > 0 && nonRetryAbleCount > 0) {
      return retryWarning(warningTextHeading1, warningMessage1);
    }
    if (
      retryAbleCount === 0 &&
      nonRetryAbleCount > 0 &&
      nonRetryAbleCount === selectedDataList.length
    ) {
      return retryWarning(warningTextHeading2, warningMessage2);
    }
    return successWarning;
  };

  const defaultSorted: [{ dataField: string; order: SortOrder }] = [
    {
      dataField: 'date',
      order: 'desc',
    },
  ];

  const getNonRetryAbleCount = () => {
    const non_retryable_data_list = selectedDataList.filter(
      (selected_data: any) => {
        return selected_data.is_retryable === false;
      }
    );
    setRetryAbleCount(selectedDataList.length - non_retryable_data_list.length);

    return non_retryable_data_list.length;
  };

  const getListOfChargeIds = () => {
    const chargeIdList = selectedDataList.map((list_data) => {
      return list_data.charge_id;
    });
    return { charge_ids: chargeIdList } as BANKING.IBatchRetryRequest;
  };

  const handleInitiateRetry = () => {
    toInitiateBatchRetry(getListOfChargeIds());
    setShowModal(false);
  };

  useEffect(() => {
    setNonRetryAbleCount(getNonRetryAbleCount());
  }, [selectedDataList]);

  useEffect(() => {
    setNonRetryAbleCount(getNonRetryAbleCount());
  }, []);

  const RetryTransactionButton = (
    <Button
      data-testid="retry_initiate_button_retry_modal"
      color="primary"
      onClick={handleInitiateRetry}
      disabled={
        (nonRetryAbleCount !== 0 && retryAbleCount > 0) ||
        nonRetryAbleCount === selectedDataList.length
      }
      type="submit"
      className={classNames(styles.retryButton)}
    >
      <i
        className={classNames(
          'ph-bold ph-arrows-clockwise align-items-center',
          styles.retryIcon
        )}
      />
      Retry transactions
    </Button>
  );

  const retry_modal_columns = [
    {
      dataField: 'is_retryable',
      text: '',
      formatter: (_: boolean, row: BANKING.IIndividualTransactionResult) => {
        if (row.is_retryable) {
          return (
            <i
              className={classNames(
                ' ph-check align-items-center icon-lg',
                styles.retryAbleCheckIcon
              )}
            />
          );
        }
        return (
          <>
            <i
              id={`${row.id}-retry-modal-tooltip`}
              className={classNames(
                ' ph-x align-items-center icon-lg',
                styles.retryAbleCrossIcon
              )}
            />
            <UncontrolledTooltip
              modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
              placement="right"
              target={`${row.id}-retry-modal-tooltip`}
            >
              {notRetryableReason(row)}
            </UncontrolledTooltip>
          </>
        );
      },
    },
    {
      dataField: 'return_date',
      text: 'Return Date',
      sort: true,
      formatter: (cell: string) => {
        return (
          <>
            {cell
              ? formatToTz(cell, settings?.timezone, 'MMM dd, hh:mm a')
              : ''}
          </>
        );
      },
    },
    {
      dataField: 'create_date',
      text: 'Transaction Date',
      sort: true,
      formatter: (cell: string) => {
        return (
          <>
            {cell
              ? formatToTz(cell, settings?.timezone, 'MMM dd, hh:mm a')
              : ''}
          </>
        );
      },
    },
    {
      dataField: 'charge_amount',
      text: 'Gross Amount',
      sort: true,
      formatter: (cell: number) => {
        return (
          <div className="currencyColumn">
            {CURRENCY.convertToMainUnit(cell)}
          </div>
        );
      },
    },
    {
      dataField: '',
      text: '',
      style: {
        paddingLeft: '10px',
        paddingRight: '10px',
      },
      formatter: (_: string, row: BANKING.IIndividualTransactionResult) => {
        if (!row.is_retryable) {
          return (
            <>
              <i
                className={classNames(
                  'ph-x-bold align-items-center text-muted icon-lg',
                  styles.cross_icon_btn
                )}
                onClick={() => {
                  setSelectedDataList(
                    selectedDataList.filter((selected_data: any) => {
                      return selected_data.id !== row.id;
                    })
                  );
                }}
                id={`${row.id}-remove-tooltip`}
                data-testid={`${row.id}-remove-tooltip`}
              />
              <UncontrolledTooltip
                placement="bottom"
                target={`${row.id}-remove-tooltip`}
              >
                Remove
              </UncontrolledTooltip>
            </>
          );
        }
        return <></>;
      },
    },
  ];

  const row_style = (
    row: BANKING.IIndividualTransactionResult,
    rowIndex: number
  ) => {
    const style = { backgroundColor: '#F5F5F6' };
    if (!row.is_retryable) {
      return style;
    }
    return {};
  };

  return (
    <>
      <ModalContainer
        testId="retry_modal"
        isOpen={showModal}
        title={'Retry'}
        onClose={toggle}
        isFullHeight={false}
        footer={
          <div className="d-flex">
            <>
              <Button onClick={toggle} color="secondary">
                Cancel
              </Button>
              {RetryTransactionButton}
            </>
          </div>
        }
        className={modalStyles.setWidth}
      >
        <>
          {getWarningBanner()}
          <div
            className={classNames('mt-3 mb-3', styles.numberOfTransactionText)}
          >
            {retryAbleCount === selectedDataList.length &&
            nonRetryAbleCount === 0
              ? `Retrying ${retryAbleCount} transactions - `
              : `${nonRetryAbleCount} out of ${selectedDataList.length} transactions cannot be
            retried -`}
          </div>
          <BootstrapTable
            id="Retry Modal Table"
            wrapperClasses="table-no-vertical-border"
            keyField="id"
            data={selectedDataList}
            columns={retry_modal_columns}
            defaultSorted={defaultSorted}
            rowStyle={row_style}
            condensed
            bordered
            noDataIndication={
              <p className="text-center text-regular">No results found</p>
            }
          />
        </>
      </ModalContainer>
    </>
  );
};

export default MultiRetryModal;
