import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Link,
} from '@react-pdf/renderer';
import CURRENCY from 'utils/currency';

Font.register({
  family: 'Source Sans Pro',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/sourcesanspro/v14/6xK3dSBYKcSV-LCoeQqfX1RYOo3aPw.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rAkA.ttf',
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Source Sans Pro',
  },
  bold: {
    fontWeight: 700,
  },
  container: {
    width: '550px',
    margin: 'auto',
    marginTop: '48px',
  },
  header: {
    backgroundColor: '#e6f4fb',
    height: '66px',
  },
  title: {
    padding: '36px 24px 0',
    margin: 0,
    fontSize: '32px',
    fontWeight: 700,
  },
  content: {
    padding: '24px',
    fontSize: '16px',
  },
  table: {
    margin: 'auto',
    width: '500px',
    padding: '24px',
    paddingBottom: '64px',
  },
  rowHeading: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#e6f4fb',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  columnHeading: {
    padding: '12px',
    fontSize: '16px',
    fontWeight: 700,
    display: 'flex',
    flex: '4 1',
  },
  column: {
    padding: '6px 12px',
    display: 'flex',
    flex: '4 1',
    fontSize: '14px',
  },
  columnFooter: {
    padding: '12px',
    fontSize: '16px',
    fontWeight: 700,
    display: 'flex',
    flex: '4 1',
    borderTop: '2px dashed #e6f4fb',
    borderBottom: '2px dashed #e6f4fb',
  },
  footer: {
    padding: '24px',
    backgroundColor: '#e6f4fb',
    textAlign: 'center',
  },
  footerContent: {
    padding: '12px 24px',
    fontSize: '14px',
    color: '#666',
  },
});

export interface IInvoice {
  orderAmount: number;
  totalAmount: number;
  tipAmount: number;
  convenienceFee: number;
  storeName: string;
  shopEmail: string;
  trackNumber: string;
  createDate: string;
}

interface IProps {
  data: IInvoice;
}
const Invoice = ({ data }: IProps): JSX.Element => {
  const {
    totalAmount,
    orderAmount,
    tipAmount,
    convenienceFee,
    storeName,
    shopEmail,
    trackNumber,
    createDate,
  } = data;

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.header}></View>
        <View style={styles.container}>
          <View>
            <Text style={styles.title}>Thank you for your Swifter order!</Text>
          </View>
          <View>
            <Text style={styles.content}>
              Here is a summary of your recent order using Swifter at{' '}
              <Text style={styles.bold}>{storeName}</Text> on {createDate}.{' '}
              {shopEmail !== null && (
                <Text>
                  If you have any questions or concerns about your order, please
                  email us at{' '}
                  <Link src={`mailto:${shopEmail}`}>
                    <Text>{shopEmail}</Text>
                  </Link>
                  .
                </Text>
              )}
            </Text>
          </View>
          <View style={styles.table}>
            <View style={styles.rowHeading}>
              <Text style={styles.columnHeading}>Order Reference ID</Text>
              <Text style={styles.columnHeading}>{trackNumber}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.column}>Products</Text>
              <Text style={styles.column}>
                {CURRENCY.convertToMainUnit(orderAmount)}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.column}>Tip</Text>
              <Text style={styles.column}>
                {CURRENCY.convertToMainUnit(tipAmount)}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.column}>ACH Fee</Text>
              <Text style={styles.column}>
                {CURRENCY.convertToMainUnit(convenienceFee)}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.columnFooter}>Total</Text>
              <Text style={styles.columnFooter}>
                {CURRENCY.convertToMainUnit(totalAmount)}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.footer}>
          <View style={styles.container}></View>
        </View>
      </Page>
    </Document>
  );
};

export default Invoice;
