import React from 'react';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import { PAYMENTS } from 'state/payment/type';
import CURRENCY from 'utils/currency';
import { formatToTz } from 'utils/date-format';

interface props {
  info: PAYMENTS.IIndividualTransactionResult;
}
const PaymentDetails = ({
  info = {} as PAYMENTS.IIndividualTransactionResult,
}: props): JSX.Element => {
  const {
    organisation: { settings },
  } = useOrganisation();
  return (
    <>
      <div className="mb-9">
        <h3 className="mb-2 PaymentDetailsHeading">Payment details</h3>
        <hr className="mt-0 mb-2 pb-1" />
        <div>
          <div className="pb-1">
            <span className="PaymentDetailsSummaryHeading">
              Swifter ID
            </span>
            <span className="PaymentDetailsHeadingData">
              {info.track_id?.toUpperCase()}
            </span>
          </div>
          <div className="pb-1">
            <span className="PaymentDetailsSummaryHeading">
              External ID (short)
            </span>
            <span className="PaymentDetailsHeadingData">
              {info?.external_short_identifier || "NA"}
            </span>
          </div>
          <div className="pb-1 mb-2">
            <span className="PaymentDetailsSummaryHeading">
            External ID
            </span>
            <span className="PaymentDetailsHeadingData">
              {info?.external_id || "NA"}
            </span>
          </div>
          <div className="pb-1">
            <span className="PaymentDetailsSummaryHeading">Order Amount</span>
            <span className="PaymentDetailsHeadingData">
              {CURRENCY.convertToMainUnit(info.order_amount)}
            </span>
          </div>
          <div className="pb-1">
            <span className="PaymentDetailsSummaryHeading">Tip</span>
            <span className="PaymentDetailsHeadingData">
              {CURRENCY.convertToMainUnit(info.tip_amount)}
            </span>
          </div>
          <div className="pb-1">
            <span className="PaymentDetailsSummaryHeading">Convenience Fee</span>
            <span className="PaymentDetailsHeadingData">
              {CURRENCY.convertToMainUnit(info.order_fee_amount)}
            </span>
          </div>
          <div className="pb-1 mb-2">
            <span className="PaymentDetailsSummaryHeading">Total</span>
            <span className="PaymentDetailsHeadingData">
              {CURRENCY.convertToMainUnit(
                info.charge_amount || info.calculated_total
              )}
            </span>
          </div>
          <div className="pb-1">
            <span className="PaymentDetailsSummaryHeading">Return Code</span>
            <span className="PaymentDetailsHeadingData">
              {info.return_code || 'NA'}
              {info.return_reason && `(${info.return_reason})`}
            </span>
          </div>
          <div>
            <span className="PaymentDetailsSummaryHeading">Return Date</span>
            <span className="PaymentDetailsHeadingData">
              {info.return_date
                ? formatToTz(
                    info.return_date,
                    settings?.timezone,
                    'MMM dd, hh:mm a'
                  )
                : 'NA'}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentDetails;
