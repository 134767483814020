/**
 * Returns org ID from the given URL base
 * URL format: /{org_id}/...
 * @param {string} pathname - The pathname of the current URL.
 * @returns The orgId if present, otherwise null.
 */
const parseOrgId = (pathname: string): string | null => {
  const orgId = pathname.split('/')[1];
  return /^org_[a-zA-Z0-9]+/.test(orgId) ? orgId : null;
};

const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noreferrer');
};
const URL = { parseOrgId, openInNewTab };

export default URL;
