import {
  MSO_GATEWAY_BASE_URL,
  SPARC_BASE,
  SPARC_BASE_SANDBOX,
  USER_VERIFY_BASE,
} from 'utils/config';
import Api, {
  DASHBOARD_API_BASE,
  SPARC_BASE_SWIFTER,
  USER_API_BASE,
} from './api';
import MsoApi from './msoApi';

/*
 * User API instance
 * https://sparc-pay-api.dev.swifterhq.com/user/v2.0/docs
 */
const api = new Api();

/* API instance to contact the
 * MSO gateway service
 */
const apiWithEntityId = new MsoApi(true);
apiWithEntityId.changeAPIBase(`${MSO_GATEWAY_BASE_URL}`);
/*
 * Dashboard API instance
 * https://sparc-pay-api.dev.swifterhq.com/dashboard/v3.0/docs
 */
const apiWithOrg = new Api(true);

/**
 * Always uses live (dev/prod) dashboard API, should NOT change in sandbox mode
 * To be used to fetch data to avoid sync issues in sandbox mode
 */
const apiOrgLive = new Api(true);
apiOrgLive.changeAPIBase(`${SPARC_BASE}${DASHBOARD_API_BASE}`);

const openApi = new Api(false, false);
openApi.changeAPIBase(`${SPARC_BASE}${DASHBOARD_API_BASE}`);

const apiVerifyUser = new Api(false, false);
apiVerifyUser.changeAPIBase(`${USER_VERIFY_BASE}`);

/**
 * Triggers base URL change based on sandbox mode in all api instances.
 * @param {boolean} isSandboxMode - true for sandbox API, false to use the dev/prod API
 */
const changeAPIBaseInSandbox = (isSandboxMode: boolean): void => {
  const sandboxUrl = `${SPARC_BASE_SANDBOX}${DASHBOARD_API_BASE}`;
  const regularUrl = `${SPARC_BASE}${DASHBOARD_API_BASE}`;

  apiWithOrg.changeAPIBase(isSandboxMode ? sandboxUrl : regularUrl);
};


/**
 * Set active org ID in header for dashboard APIs
 * @param {string} organizationId - active org ID
 */
const setAPIOrgHeader = (organizationId: string): void => {
  apiWithOrg.setOrgHeader(organizationId);
  apiOrgLive.setOrgHeader(organizationId);
};

const setAPIEntityHeader = (entityId: string): void => {
  // apiWithEntityId.set
  apiWithEntityId.setEntityHeader(entityId);
};

export {
  api,
  apiWithOrg,
  apiOrgLive,
  openApi,
  apiVerifyUser,
  apiWithEntityId,
  changeAPIBaseInSandbox,
  setAPIOrgHeader,
  setAPIEntityHeader,
};
