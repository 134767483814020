import FormLabel from 'components/form-label';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { Controller, useForm } from 'react-hook-form';
import ErrorMessage from 'components/errorMessage';
import useBanking from 'state/banking/hooks/useBanking';
import prefillData, { piiTypes } from 'utils/pii';
import {
  EXTERNAL_PAYOUT_STAGES,
  IActiveState,
  PAYOUTS_NAV_CONFIG,
  PAYOUT_STAGE,
} from 'components/modals/banking/payouts/payout';
import { DropdownConfig } from 'components/selectDropdown';
import { BANKING } from 'state/banking/type';
import STRING from 'utils/string';
import {
  DL_ID_VALIDATION,
  MASKED_DL_ID_VALIDATION,
  PHONE_NUMBER_VALIDATION,
} from 'utils/validation';
import styles from '../../styles.module.scss';

interface IProps<T> {
  dropdownConfig: DropdownConfig<BANKING.IVendor>;
  setDropdownConfig: Dispatch<SetStateAction<DropdownConfig<BANKING.IVendor>>>;
  setActiveState: Dispatch<SetStateAction<IActiveState>>;
  activeState: IActiveState;
}

const AddVendor = <T,>({
  setActiveState,
  activeState,
  dropdownConfig,
  setDropdownConfig,
}: IProps<T>): JSX.Element => {
  const { errors, control, handleSubmit, register, setValue } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: dropdownConfig.updateItem
      ? {
          vendor_name: dropdownConfig.selectedItemData?.name,
          email: dropdownConfig.selectedItemData?.email,
          phone_number: dropdownConfig.selectedItemData?.phone,
          vendor_ein_ssn: prefillData(
            piiTypes.driving_license,
            dropdownConfig.selectedItemData?.proof_id
          ),
        }
      : {},
  });

  const { toCreateVendors, toUpdateVendors, banking, resetUpdateVendor } =
    useBanking();

  const { error } = banking;

  const formSubmit = (values: { [x: string]: string }) => {
    const submittedData = {
      name: values.vendor_name,
      proof_id: values.vendor_ein_ssn,
      email: values.email,
      phone: values.phone_number,
    };
    if (dropdownConfig.updateItem && dropdownConfig?.selectedItemData) {
      toUpdateVendors(submittedData, dropdownConfig.selectedItemData.id);
    } else {
      toCreateVendors(submittedData);
    }
    setDropdownConfig({ ...dropdownConfig });
  };

  const formSubmission = () => {
    handleSubmit(formSubmit)();
  };

  // const patternedMasking = (value: string) => {
  //   const vendor_ein_ssn = value;
  //   if (vendor_ein_ssn) {
  //     setValue('vendor_ein_ssn', formatProofId(vendor_ein_ssn));
  //   }
  // };

  useEffect(() => {
    const footerElement = (
      <>
        <Button
          className="btn btn-primary mr-2"
          onClick={() => {
            dropdownConfig.addNewItem = false;
            dropdownConfig.updateItem = false;
            dropdownConfig.isSelected = false;
            dropdownConfig.selectedItemData = undefined;
            dropdownConfig.selectedItemName = undefined;
            setDropdownConfig({ ...dropdownConfig });
            activeState.payoutStage = PAYOUT_STAGE.external;
            activeState.activeForm = EXTERNAL_PAYOUT_STAGES.VENDOR_DETAILS;
            PAYOUTS_NAV_CONFIG[
              EXTERNAL_PAYOUT_STAGES.VENDOR_DETAILS
            ].isCompleted = false;
            setActiveState({ ...activeState });
            resetUpdateVendor();
          }}
        >
          <span className="d-flex">Back</span>
        </Button>
        <Button
          color="primary"
          className="btn btn-primary"
          type="submit"
          onClick={() => {
            formSubmission();
          }}
        >
          <span className="d-flex">
            Next <i className="ph-arrow-right pl-1"></i>
          </span>
        </Button>
      </>
    );
    activeState.footer = footerElement;
    setActiveState({ ...activeState });
  }, []);

  return (
    <>
      <Form role="form" className="mb-0" onSubmit={handleSubmit(formSubmit)}>
        <FormGroup className=" mr-3">
          <FormLabel
            isRequired
            element="vendor_name"
            label="Vendor name"
          ></FormLabel>
          <Input
            style={{ width: '578px', height: '36px' }}
            name="vendor_name"
            id="vendor_name"
            placeholder="Forrest"
            type="text"
            innerRef={
              register &&
              register({
                required: true,
              })
            }
            className={errors?.vendor_name ? styles.borderAlert : ''}
          />
          {errors?.vendor_name && (
            <ErrorMessage
              isValidation
              message="Vendor name should have atleast 3 characters."
              preIcon={
                <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
              }
            />
          )}
        </FormGroup>
        <FormGroup className="mr-3">
          <FormLabel
            isRequired
            element="vendor_ein_ssn"
            label="Vendor EIN/SSN"
          ></FormLabel>
          <Input
            name="vendor_ein_ssn"
            id="vendor_ein_ssn"
            style={{ width: '578px', height: '36px' }}
            maxLength={11}
            placeholder="123334444"
            innerRef={
              register &&
              register({
                required: true,
                validate: {
                  required: (value) => {
                    if (
                      !MASKED_DL_ID_VALIDATION.test(value) &&
                      !DL_ID_VALIDATION.test(value)
                    ) {
                      return false;
                    }
                    return true;
                  },
                },
              })
            }
            className={classNames(
              'd-block form-control',
              errors?.vendor_ein_ssn ? styles.borderAlert : ''
            )}
          />
          {errors?.vendor_ein_ssn && (
            <ErrorMessage
              isValidation
              message="This is a required field."
              preIcon={
                <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
              }
            />
          )}
          <span>
            Please enter the vendor's EIN if they are a business or SSN if they
            are an individual.
          </span>
        </FormGroup>
        <Row>
          <Col>
            <FormGroup className=" mb-0">
              <FormLabel isRequired element="email" label="Email"></FormLabel>
              <Input
                style={{ width: '281px', height: '36px' }}
                placeholder="abc@workmail.com"
                name="email"
                id="email"
                type="email"
                innerRef={
                  register &&
                  register({
                    required: true,
                  })
                }
                className={classNames(
                  'd-block form-control',
                  errors?.email ? styles.borderAlert : ''
                )}
              />
              {errors?.email && (
                <ErrorMessage
                  isValidation
                  message="Please enter an email address."
                  preIcon={
                    <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
                  }
                />
              )}
            </FormGroup>
          </Col>
          <Col>
            <FormGroup className=" mb-0">
              <FormLabel
                element="phone_number"
                label="Phone number"
              ></FormLabel>
              <Controller
                control={control}
                name="phone_number"
                id="phone_number"
                rules={{
                  pattern: PHONE_NUMBER_VALIDATION,
                }}
                render={(field) => (
                  <NumberFormat
                    format="+#-###-###-####"
                    id="phone_number"
                    name="phone_number"
                    mask="_"
                    placeholder="+1-555-555-5555"
                    style={{ width: '281px', height: '36px' }}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      field.onChange(e);
                    }}
                    value={field.value}
                    getInputRef={field.ref}
                    className={classNames(
                      'd-block form-control',
                      errors?.phone_number ||
                        error?.createVendor?.detail[0].loc[1] === 'phone'
                        ? styles.borderAlert
                        : ''
                    )}
                  />
                )}
              />
              {error?.createVendor?.detail[0].loc[1] === 'phone' && (
                <ErrorMessage
                  isValidation
                  message={
                    STRING.capitalizeFirstLetter(
                      error?.createVendor?.detail?.[0]?.msg
                    ) || 'Please enter your phone number.'
                  }
                  preIcon={
                    <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
                  }
                />
              )}
              {errors?.phone_number && (
                <ErrorMessage
                  isValidation
                  message={
                    errors?.phone_number?.message ||
                    'Please enter your phone number.'
                  }
                  preIcon={
                    <i className="ph-warning-circle-fill icon-md dangerIcon p-0 pr-1"></i>
                  }
                />
              )}
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddVendor;
