import { apiOrgLive, openApi } from 'services/api';
import {
  action,
  GET_FORM_DETAILS,
  actionFailure,
  actionSuccess,
  SUBMIT_FORM,
  SET_FORM_VIEW,
  RESET,
  AUTO_SAVE,
  AUTO_SAVE_RESET,
  SET_BO_FORM_VIEW,
  SET_BO_LIST,
  SUBMIT_EXTERNAL_FORM,
  SET_EXTERNAL_BO_TOKEN,
  FETCH_EXTERNAL_FORM,
  RESET_SUBMIT_STAGE,
  RESET_EXT_SUBMIT_STAGE,
  RESET_IS_SUBMITTED,
  REFRESH_FORM,
} from 'state/kybActivation/actions';
import { useEffect } from 'react';
import axios from 'axios';
import { useStateValue } from '../..';
import { ACTIVATION } from '../type';
import { BO_STAGE, FORMS, KYB_VERSION, PRODUCT_STAGE } from '../config';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useActivation = (initialState = {}) => {
  const { state, dispatch } = useStateValue();

  // initial state prop for testing purpose
  const kybActivation = {
    ...state.kybActivation,
    formDetails: {
      ...state.kybActivation.formDetails,
      ...(initialState as ACTIVATION.IActivationReducer)?.formDetails,
    },
  };

  const deleteDocument = async (upload_id: string | undefined) => {
    try {
      if (upload_id) await apiOrgLive.remove(`/uploads/${upload_id}`);
    } catch (e) {
      console.log(e);
    }
  };

  const toSubmitForm = async (
    form: ACTIVATION.ISubmitFormRequest,
    clearLocal = false
  ) => {
    dispatch(action(SUBMIT_FORM));
    try {
      const response = await apiOrgLive.put('/kyb', form);
      if (response?.status >= 400) throw response.data;

      dispatch(actionSuccess(SUBMIT_FORM, response));

      if (clearLocal) {
        localStorage.removeItem(form.stage);
        localStorage.removeItem('stage');
      }
    } catch (err) {
      const errorMessage = axios.isAxiosError(err) ? err?.response : err;
      dispatch(actionFailure(SUBMIT_FORM, { errorMessage, stage: form.stage }));
    }
  };

  const fetchForm = async () => {
    return apiOrgLive.get<ACTIVATION.formDetails>('/kyb', {
      version: KYB_VERSION,
    });
  };

  const getAllFormDetails = async () => {
    dispatch(action(GET_FORM_DETAILS));
    try {
      const response = await fetchForm();
      dispatch(actionSuccess(GET_FORM_DETAILS, response.data));
    } catch (e) {
      dispatch(actionFailure(GET_FORM_DETAILS, e));
    }
  };

  const refreshForm = async () => {
    dispatch(action(REFRESH_FORM));
    try {
      const response = await fetchForm();
      dispatch(actionSuccess(REFRESH_FORM, response.data));
    } catch (e) {
      console.log(e);
    }
  };

  const setActiveForm = (form: FORMS) => {
    dispatch(action(SET_FORM_VIEW, form));
  };

  const setActiveBOForm = (form: BO_STAGE) => {
    dispatch(action(SET_BO_FORM_VIEW, form));
  };

  const reset = () => {
    dispatch(action(RESET));
  };

  const localStorageUpload = (
    values: { [x: string]: unknown },
    formName: FORMS | PRODUCT_STAGE
  ) => {
    dispatch(action(AUTO_SAVE));
    const timer = setTimeout(() => {
      localStorage.setItem(formName, JSON.stringify(values));
      localStorage.setItem('stage', formName);
      dispatch(actionSuccess(AUTO_SAVE, {}));
      clearTimeout(timer);
    }, 1000);
  };

  const resetAutoSave = () => {
    dispatch(action(AUTO_SAVE_RESET));
  };

  const triggerAutoSave = () => {
    dispatch(action(AUTO_SAVE));
    const timer = setTimeout(() => {
      dispatch(actionSuccess(AUTO_SAVE, {}));
      clearTimeout(timer);
    }, 1000);
  };

  const savedBOwners = {
    save(data: Record<string, ACTIVATION.IBusinessOwner>) {
      localStorageUpload(data, FORMS.BUSINESS_OWNERS);
    },
    getBOwners(): Record<string, ACTIVATION.IBusinessOwner> | null {
      const data = localStorage.getItem(FORMS.BUSINESS_OWNERS);
      return data ? JSON.parse(data) : null;
    },

    update(create_date: string, owner: ACTIVATION.IBusinessOwner) {
      let data = this.getBOwners();
      if (!data) {
        data = { [create_date]: owner };
      } else data[create_date] = owner;
      this.save(data);
    },

    delete(create_date: string) {
      const data = this.getBOwners();
      if (!data) return;

      delete data[create_date];
      this.save(data);

      if (Object.keys(data).length === 0) {
        localStorage.removeItem(FORMS.BUSINESS_OWNERS);
        localStorage.removeItem('stage');
      }
    },
  };

  const getAllBusinessOwners = () => {
    const lsData = savedBOwners.getBOwners();

    const list =
      kybActivation.formDetails.business_owners?.owners?.map((item) => {
        if (!lsData || !(item.create_date in lsData)) return item;
        return lsData[item.create_date];
      }) || [];

    // add owners saved in ls
    const allOwnersList = lsData
      ? Object.keys(lsData).reduce(
          (newList: ACTIVATION.IBusinessOwner[], create_date) => {
            if (list?.find((item) => item.create_date === create_date))
              return newList;
            return newList.concat([lsData[create_date]]);
          },
          list
        )
      : list;

    // sort owners list by timestamp
    return allOwnersList.sort((a, b) => {
      if (a.create_date < b.create_date) return -1;
      if (a.create_date > b.create_date) return 1;
      return 0;
    });
  };

  const setBOList = () => {
    dispatch(action(SET_BO_LIST, getAllBusinessOwners()));
  };

  const toSubmitOfficerForm = async (form: ACTIVATION.ISubmitExtForm) => {
    if (!state?.kybActivation?.extToken) return;

    dispatch(action(SUBMIT_EXTERNAL_FORM));
    try {
      const response = await openApi.put(
        `/kyb/business_owners?token=${state.kybActivation.extToken}`,
        form
      );
      if (response.status >= 400) throw response.data;
      dispatch(actionSuccess(SUBMIT_EXTERNAL_FORM, response));
    } catch (err) {
      dispatch(actionFailure(SUBMIT_EXTERNAL_FORM, err));
    }
  };

  const toFetchExtOfficerForm = async () => {
    dispatch(action(FETCH_EXTERNAL_FORM));
    try {
      const response = await openApi.get(
        `/kyb/business_owners?token=${state.kybActivation.extToken}`
      );
      dispatch(actionSuccess(FETCH_EXTERNAL_FORM, response.data));
    } catch (e) {
      dispatch(actionFailure(FETCH_EXTERNAL_FORM, e));
    }
  };

  const toSetExternalBOToken = (
    token: string,
    payload: ACTIVATION.IExBOTokenPayload
  ) => {
    dispatch(action(SET_EXTERNAL_BO_TOKEN, { token, payload }));
  };

  useEffect(() => {
    setBOList();
  }, [
    kybActivation.formDetails.business_owners,
    localStorage.getItem(FORMS.BUSINESS_OWNERS),
  ]);
  const isExtFormCompleted = () => {
    return (
      kybActivation?.businessOwnerExternal.officer?.stage_1?.is_completed &&
      kybActivation?.businessOwnerExternal?.officer?.stage_2?.is_completed &&
      Boolean(kybActivation?.businessOwnerExternal?.business?.ein)
    );
  };
  const resetStageSubmit = () => {
    dispatch(action(RESET_SUBMIT_STAGE));
  };
  const resetExtStageSubmit = () => {
    dispatch(action(RESET_EXT_SUBMIT_STAGE));
  };

  const resetIsSubmitted = () => {
    dispatch(action(RESET_IS_SUBMITTED));
  };

  return {
    kybActivation,
    getAllFormDetails,
    toSubmitForm,
    setActiveForm,
    reset,
    localStorageUpload,
    savedBOwners,
    getAllBusinessOwners,
    setActiveBOForm,
    resetAutoSave,
    setBOList,
    toSubmitOfficerForm,
    toSetExternalBOToken,
    toFetchExtOfficerForm,
    isExtFormCompleted,
    resetStageSubmit,
    resetExtStageSubmit,
    triggerAutoSave,
    resetIsSubmitted,
    deleteDocument,
    refreshForm,
  };
};

export default useActivation;
