import React from 'react';
import { formatToTz } from 'utils/date-format';
import { transactionStatusFormatter } from 'utils/status-formatter';
import { SortOrder } from 'react-bootstrap-table-next';
import CURRENCY from 'utils/currency';
import {
  transactionSourceFormatter,
  parseDeletedCustomerName,
} from 'utils/source-formatter';
import { STATS } from '../../../state/stats/type';

export enum TABS {
  HIGHEST_ORDER_VALUE = 'Highest Order Value',
  FREQUENT_ORDERS = 'Frequent Orders',
  FREQUENT_RETURNS = 'Frequent Returns',
}

export const getColumnConfig = (table: TABS, timezone?: string) => {
  switch (table) {
    case TABS.HIGHEST_ORDER_VALUE:
      return [
        {
          dataField: 'date',
          text: 'Date',
          sort: true,
          formatter: (cell: string) => {
            return (
              <>{cell ? formatToTz(cell, timezone, 'MMM dd, hh:mm a') : ''}</>
            );
          },
        },
        {
          dataField: 'gross_amount',
          text: 'gross amount',
          sort: true,
          formatter: (cell: number) => {
            return (
              <div className={'currencyColumn'}>
                {CURRENCY.convertToMainUnit(cell)}
              </div>
            );
          },
        },
        {
          dataField: 'status',
          text: 'status',
          sort: true,
          formatter: transactionStatusFormatter,
        },
        {
          dataField: 'customer',
          text: 'customer',
          sort: true,
          formatter: (cell: string, row: STATS.ICustomerTopOrder) => {
            if (!row.consumer_profile_id) {
              return <>{parseDeletedCustomerName(cell)}</>;
            }

            return <>{cell}</>;
          },
        },
        {
          dataField: 'external_id',
          text: 'external id',
        },
        {
          dataField: 'source',
          text: 'source',
          formatter: transactionSourceFormatter,
        },
      ];

    case TABS.FREQUENT_ORDERS:
      return [
        {
          dataField: 'gross_amount',
          text: 'gross amount',
          sort: true,
          formatter: (cell: number) => {
            return (
              <div className={'currencyColumn'}>
                {CURRENCY.convertToMainUnit(cell)}
              </div>
            );
          },
        },
        {
          dataField: 'net_amount',
          text: 'net amount',
          sort: true,
          formatter: (cell: number) => {
            return (
              <div className={'currencyColumn'}>
                {CURRENCY.convertToMainUnit(cell)}
              </div>
            );
          },
        },
        {
          dataField: 'customer',
          text: 'customer',
          sort: true,
          formatter: (cell: string, row: STATS.ICustomerTopOrder) => {
            if (!row.consumer_profile_id) {
              return <>{parseDeletedCustomerName(cell)}</>;
            }

            return <>{cell}</>;
          },
        },
        {
          dataField: 'number_of_orders',
          text: 'number of orders',
        },
      ];

    case TABS.FREQUENT_RETURNS:
      return [
        {
          dataField: 'customer',
          text: 'customer',
          sort: true,
          formatter: (cell: string, row: STATS.ICustomerTopOrder) => {
            if (!row.consumer_profile_id) {
              return <>{parseDeletedCustomerName(cell)}</>;
            }

            return <>{cell}</>;
          },
        },
        {
          dataField: 'number_of_returns',
          text: 'number of returns',
        },
        {
          dataField: 'returned_amount',
          text: 'total returned amount',
          sort: true,
          formatter: (cell: number) => {
            return (
              <div className={'currencyColumn'}>
                {CURRENCY.convertToMainUnit(cell)}
              </div>
            );
          },
        },
        {
          dataField: 'amount_recovered',
          text: 'total recovered amount',
          sort: true,
          formatter: (cell: number) => {
            return (
              <div className={'currencyColumn'}>
                {CURRENCY.convertToMainUnit(cell)}
              </div>
            );
          },
        },
      ];

    default:
      return [];
  }
};

export const CUSTOMER_INFO_TABS = [
  {
    label: TABS.HIGHEST_ORDER_VALUE,
    filters: [],
  },
  {
    label: TABS.FREQUENT_ORDERS,
    filters: [],
  },
  {
    label: TABS.FREQUENT_RETURNS,
    filters: [],
  },
];

type IDefaultSort = [
  {
    dataField: string;
    order: SortOrder;
  }
];

export const sort: Record<string, IDefaultSort> = {
  topOrders: [
    {
      dataField: 'gross_amount',
      order: 'desc',
    },
  ],
  freqOrders: [
    {
      dataField: 'number_of_orders',
      order: 'desc',
    },
  ],
  freqReturns: [
    {
      dataField: 'number_of_returns',
      order: 'desc',
    },
  ],
};
