import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'reactstrap';
import useWebhooks from 'state/webhooks/hooks/useWebhooks';
import Webhook from './webhook';

const WebhookDetails = (): JSX.Element => {
  const { webhookId } = useParams<{ webhookId: string }>();

  const { toFetchWebhookDetails, webhooks } = useWebhooks();
  const { webhookDetails } = webhooks;

  useEffect(() => {
    if (webhookDetails?.id !== webhookId && webhookId)
      toFetchWebhookDetails(webhookId);
  }, []);

  return (
    <Card className="card-profile border-0">
      <Webhook />
    </Card>
  );
};

export default WebhookDetails;
