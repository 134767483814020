import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import { UncontrolledTooltip } from 'reactstrap';
import PageHeading from 'components/heading';
import Pagination from 'components/pagination';
import Search from 'components/search';
import ExportBtn from 'components/modals/export/exportBtn';
import ToastContainer, { ToastType } from 'components/toasts/container';
import usePartnerStores from 'state/partnerStores/hooks/usePartnerStores';
import CURRENCY from 'utils/currency';
import useQuery from 'utils/hooks/useQuery';
import { downloadFile } from 'utils/download';
import { useLocation } from 'react-router-dom';
import styles from './styles.module.scss';

const PartnerStores = (): JSX.Element => {
  const location = useLocation();
  const queryParams = location?.search;
  const query = useQuery(queryParams);

  const { partnerStores, toFetchStores, toExportStores } = usePartnerStores();
  const { list } = partnerStores;

  const searchString = query.get('search') || undefined;

  // Next and prev key
  const [prevKey, setPrevKey] = useState<string | null>(null);
  const [nextKey, setNextKey] = useState<string | null>(null);
  const [toast, setToast] = useState<ToastType | false>(false);
  const [numResults, setNumResults] = useState(0);

  // get to prev page
  const prevPage = () => {
    toFetchStores({
      key: prevKey,
      search_string: searchString,
    });
  };

  // get to next page
  const nextPage = () => {
    toFetchStores({
      key: nextKey,
      search_string: searchString,
    });
  };

  useEffect(() => {
    toFetchStores({
      search_string: searchString,
    });
  }, [queryParams]);

  useEffect(() => {
    if (list && list?.items?.length > 0) {
      // setting next and prev key
      setNextKey(list.next_key);
      setPrevKey(list.prev_key);

      setNumResults(list.total_count);
    } else {
      setNumResults(0);
    }
  }, [list]);

  const columns = [
    {
      dataField: 'store_name',
      text: 'STORE',
      sort: true,
      formatter: (cell: string) => {
        return <div data-testid="store_name">{cell}</div>;
      },
    },
    {
      dataField: 'store_id',
      text: 'STORE ID',
      sort: true,
      formatter: (cell: string) => {
        return <div data-testid="store_id">{cell || ''}</div>;
      },
    },
    {
      dataField: 'fee_per_transaction',
      text: 'FEE PER TRANSACTION',
      sort: true,
      formatter: (cell: number) => {
        return (
          <div data-testid="fee_per_transaction" className={'currencyColumn'}>
            {CURRENCY.convertToMainUnit(cell)}
          </div>
        );
      },
      headerFormatter: (column: ColumnDescription) => (
        <>
          <span>{column.text}</span>
          <span>
            <span id="fee_value-tooltip" className="pl-1">
              <i className="ph-info-fill icon-md text-muted" />
            </span>
            <UncontrolledTooltip placement="right" target="fee_value-tooltip">
              This is the fee charged per transaction for a particular store.
            </UncontrolledTooltip>
          </span>
        </>
      ),
    },
    {
      dataField: 'lifetime_value',
      text: 'LIFETIME VALUE',
      sort: true,
      formatter: (cell: number) => {
        return (
          <div data-testid="lifetime_value" className={'currencyColumn'}>
            {CURRENCY.convertToMainUnit(cell)}
          </div>
        );
      },
      headerFormatter: (column: ColumnDescription) => (
        <>
          <span>{column.text}</span>
          <span>
            <span id="lifetime_value-tooltip" className="pl-1">
              <i className="ph-info-fill icon-md text-muted" />
            </span>
            <UncontrolledTooltip
              placement="right"
              target="lifetime_value-tooltip"
            >
              This is total earnings from a particular store till date.
            </UncontrolledTooltip>
          </span>
        </>
      ),
    },
  ];

  const exportFee = async () => {
    const data = await toExportStores();
    if (data && 'url' in data) {
      downloadFile(data.url, 'stores-list', 'csv');
      setToast(ToastType.success);
    } else {
      setToast(ToastType.error);
    }
  };

  return (
    <>
      <PageHeading heading="Stores" className="mb-7" />
      <PageHeading
        heading="Fees"
        subHeading="View the details of fee and it’s type charged for each store per transaction"
        className="mb-5"
        level="h2"
      />
      <div className="d-flex mb-3">
        <span className="mr-auto">
          <Search queryParams={queryParams} />
        </span>

        <ExportBtn onClick={exportFee} id="tnx-export-btn" />
      </div>
      <BootstrapTable
        id="partner-stores"
        wrapperClasses="table-responsive"
        keyField="_id"
        data={list?.items || []}
        columns={columns}
        hover
        condensed
        bordered={false}
        noDataIndication={
          <>
            <div
              className={classNames(
                'text-center body-semibold mb-1 mt-4',
                styles.noResultsHeading
              )}
            >
              No stores to show
            </div>
            <div
              className={classNames(
                'text-center body-subtext-regular',
                styles.noResultsSubtext
              )}
            >
              No partner-stores have been onboarded and connected yet.
            </div>
          </>
        }
      />
      <Pagination
        results={numResults}
        prevPage={prevPage}
        nextPage={nextPage}
        prevKey={prevKey}
        nextKey={nextKey}
      />
      {Boolean(toast) && (
        <ToastContainer
          showToast={Boolean(toast)}
          onClose={() => setToast(false)}
          message={
            toast === ToastType.success
              ? 'Your export is ready!'
              : toast === ToastType.error
              ? 'Something went wrong'
              : ''
          }
          type={toast || undefined}
          id="export"
        />
      )}
    </>
  );
};

export default PartnerStores;
