import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Input } from 'reactstrap';
import { useDebounce } from '@react-hook/debounce';
import useQuery from 'utils/hooks/useQuery';
import QUERY from 'utils/query';
import styles from './search.module.scss';

interface IProps {
  queryParams?: string;
  placeHolders?: string,
  className?: any;
}

const Search = ({ queryParams = '' , placeHolders, className}: IProps): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = useQuery(queryParams);

  const [query, setQuery] = useDebounce<string | null>(null, 600);
  const [searchString, setSearchString] = useState<string>('');

  const reset = () => {
    setQuery(null);
    setSearchString('');
  };

  const clearAll = () => {
    reset();
    navigate({
      search: QUERY.update(location.search, { search: null }),
    });
  };

  const applySearch = () => {
    if (query) {
      navigate({
        search: QUERY.update(location.search, {
          search: query,
        }),
      });
    }
  };

  const getAppliedFromQuery = () => urlParams.get('search');

  useEffect(() => {
    const value = getAppliedFromQuery();
    if (value) {
      setSearchString(value);
    } else {
      setSearchString('');
    }
  }, [queryParams]);

  useEffect(() => {
    applySearch();
  }, [query]);

  return (
    <>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className={classNames(styles.search, className)}
      >
        <span className={classNames(styles.search_icon)}>
          <i className="ph-magnifying-glass"></i>
        </span>
        <Input
          aria-label="Search"
          className={classNames(styles.search_input)}
          placeholder={placeHolders ? placeHolders : "Search..."}
          type="search"
          onChange={(e) => {
            if (e.target.value.length === 0) {
              clearAll();
            }
            setSearchString(e.target.value);
            setQuery(e.target.value);
          }}
          value={searchString}
        />
        {searchString.length > 0 && (
          <span
            data-testid="Clear"
            className={classNames(styles.search_icon, styles.search_clear)}
            onClick={clearAll}
          >
            <i className="ph-x"></i>
          </span>
        )}
      </Form>
    </>
  );
};

export default Search;
