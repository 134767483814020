import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { PAYMENT_CONFIG } from 'state/paymentConfig/type';
import usePaymentConfig from 'state/paymentConfig/hooks/usePaymentConfig';
import ToastContainer, { ToastType } from 'components/toasts/container';
import ProviderDropdown from './providerDropdown';
import CardReaderInput from './cardReaderSection';
import { PaymentSolutionTab } from '../../paymentConfig';

interface IProps {
  defaultProvider: PAYMENT_CONFIG.IProcessors | undefined;
  setDefaultProvider: Dispatch<
    SetStateAction<PAYMENT_CONFIG.IProcessors | undefined>
  >;
  activeTab: PaymentSolutionTab;
  localTab: PaymentSolutionTab;
}

const Layout = ({
  defaultProvider,
  setDefaultProvider,
  activeTab,
  localTab,
}: IProps): JSX.Element => {
  const {
    paymentConfig,
    resetCardReaderValues,
    toGetPaymentDeviceLocation,
    getCardReaderConfig,
    toFetchCardReaderValues,
    resetSaveCardReader,
    resetSaveDefaultCardReader,
    toFetchDefaultProviders,
  } = usePaymentConfig();

  const { error, saveCardReader, saveDefaultCardReader } = paymentConfig;

  const [showErrorToast, setShowErrorToast] = useState(false);

  useEffect(() => {
    if (
      error?.createPaymentDevice ||
      error?.editPaymentDevice ||
      error?.getPaymentDevice ||
      error?.deviceStatus ||
      error?.deviceDeleted ||
      error?.saveCardReader ||
      error?.saveDefaultCardReader ||
      error?.paymentConfiguration
    ) {
      setShowErrorToast(true);
    }
  }, [error]);

  const [saveDeviceConfigSuccess, setSaveDeviceConfigSuccess] = useState(false);

  const [saveDefaultDeviceConfigSuccess, setSaveDefaultDeviceConfigSuccess] =
    useState(false);

  useEffect(() => {
    resetCardReaderValues();
    if (defaultProvider && activeTab === localTab) {
      toGetPaymentDeviceLocation(defaultProvider?.processorName);
      getCardReaderConfig(defaultProvider?.processorName);
      toFetchCardReaderValues(defaultProvider?.processorId);
    }
  }, [defaultProvider, activeTab, localTab]);

  useEffect(() => {
    if (saveCardReader && activeTab === localTab && defaultProvider) {
      toFetchCardReaderValues(defaultProvider?.processorId);
      setSaveDeviceConfigSuccess(true);
      resetSaveCardReader();
    }
  }, [saveCardReader]);

  useEffect(() => {
    if (saveDefaultCardReader && activeTab === localTab) {
      toFetchDefaultProviders();
      setSaveDefaultDeviceConfigSuccess(true);
      resetSaveDefaultCardReader();
    }
  }, [saveDefaultCardReader]);

  return (
    <>
      <ProviderDropdown
        defaultProvider={defaultProvider}
        setDefaultProvider={setDefaultProvider}
      />
      <CardReaderInput
        defaultProvider={defaultProvider}
        activeTab={activeTab}
        localTab={localTab}
      />

      {showErrorToast && (
        <ToastContainer
          type={ToastType.error}
          showToast={showErrorToast}
          onClose={() => setShowErrorToast(false)}
          message={'Something went wrong! Please try again later.'}
          showCross
        />
      )}
      {saveDeviceConfigSuccess && (
        <ToastContainer
          id="saveConfig"
          showToast={saveDeviceConfigSuccess}
          onClose={() => setSaveDeviceConfigSuccess(false)}
          message={'Configuration saved'}
          showCross
        />
      )}
      {saveDefaultDeviceConfigSuccess && (
        <ToastContainer
          id="defaultConfig"
          showToast={saveDefaultDeviceConfigSuccess}
          onClose={() => setSaveDefaultDeviceConfigSuccess(false)}
          message={'Default configuration saved'}
          showCross
        />
      )}
    </>
  );
};

export default Layout;
