import { Reducer } from 'react';
import authReducer from './auth/reducers';
import { AUTH } from './auth/type';
import organisationReducer from './organisation/reducers';
import { ORGANISATION } from './organisation/type';
import paymentReducer from './payment/reducers';
import { PAYMENTS } from './payment/type';
import paymentLinksReducer from './paymentLinks/reducers';
import { PAYMENTLINKS } from './paymentLinks/type';
import refundsReducer from './refunds/reducers';
import { REFUNDS } from './refunds/type';
import settingsReducer from './settings/reducers';
import { SETTINGS } from './settings/type';
import storeReducer from './store/reducers';
import { STORE } from './store/type';
import webhooksReducer from './webhooks/reducers';
import { WEBHOOKS } from './webhooks/type';
import statsReducer from './stats/reducers';
import { STATS } from './stats/type';
import activationReducer from './kybActivation/reducers';
import { ACTIVATION } from './kybActivation/type';
import { TERMINAL } from './terminal/type';
import terminalReducer from './terminal/reducers';
import { CONSUMERS } from './consumer/type';
import consumerReducer from './consumer/reducers';
import { EVENTS } from './events/type';
import eventsReducer from './events/reducers';
import { BANKING } from './banking/type';
import bankingReducer from './banking/reducers';
import { PARTNER_STORES } from './partnerStores/type';
import partnerStoresReducer from './partnerStores/reducers';
import { PAYMENT_CONFIG } from './paymentConfig/type';
import paymentConfigReducer from './paymentConfig/reducers';

export interface IReducers {
  auth: AUTH.IUserReducer;
  organisation: ORGANISATION.OrganisationReducer;
  payment: PAYMENTS.PaymentReducer;
  terminal: TERMINAL.TerminalReducer;
  consumers: CONSUMERS.ConsumerReducer;
  settings: SETTINGS.SettingsReducer;
  store: STORE.StoreReducer;
  webhooks: WEBHOOKS.WebhooksReducer;
  paymentLinks: PAYMENTLINKS.PaymentLinksReducer;
  refunds: REFUNDS.RefundsReducer;
  stats: STATS.IStatsReducer;
  kybActivation: ACTIVATION.IActivationReducer;
  events: EVENTS.EventsReducer;
  banking: BANKING.BankingReducer;
  partnerStores: PARTNER_STORES.IReducer;
  paymentConfig: PAYMENT_CONFIG.PaymentConfigReducer;
}

const reducers: Reducer<IReducers, IAction> = (state, action) => ({
  auth: authReducer(action, state.auth),
  organisation: organisationReducer(action, state.organisation),
  payment: paymentReducer(action, state.payment),
  terminal: terminalReducer(action, state.terminal),
  consumers: consumerReducer(action, state.consumers),
  settings: settingsReducer(action, state.settings),
  store: storeReducer(action, state.store),
  webhooks: webhooksReducer(action, state.webhooks),
  paymentLinks: paymentLinksReducer(action, state.paymentLinks),
  stats: statsReducer(action, state.stats),
  refunds: refundsReducer(action, state.refunds),
  kybActivation: activationReducer(action, state.kybActivation),
  events: eventsReducer(action, state.events),
  banking: bankingReducer(action, state.banking),
  partnerStores: partnerStoresReducer(action, state.partnerStores),
  paymentConfig: paymentConfigReducer(action, state.paymentConfig),
});

export default reducers;
