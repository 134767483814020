export const FETCH_WEBHOOKS = 'webhooks/FETCH_WEBHOOKS';
export const FETCH_WEBHOOKS_SUCCESS = 'webhooks/FETCH_WEBHOOKS_SUCCESS';
export const FETCH_WEBHOOKS_FAILURE = 'webhooks/FETCH_WEBHOOKS_FAILURE';

export const CREATE_WEBHOOK = 'webhooks/CREATE_WEBHOOK';
export const CREATE_WEBHOOK_SUCCESS = 'webhooks/CREATE_WEBHOOK_SUCCESS';
export const CREATE_WEBHOOK_FAILURE = 'webhooks/CREATE_WEBHOOK_FAILURE';

export const FETCH_WEBHOOK_DETAILS = 'webhooks/FETCH_WEBHOOK_DETAILS';
export const FETCH_WEBHOOK_DETAILS_SUCCESS =
  'webhooks/FETCH_WEBHOOK_DETAILS_SUCCESS';
export const FETCH_WEBHOOK_DETAILS_FAILURE =
  'webhooks/FETCH_WEBHOOK_DETAILS_FAILURE';

export const DELETE_WEBHOOK = 'webhooks/DELETE_WEBHOOK';
export const DELETE_WEBHOOK_SUCCESS = 'webhooks/DELETE_WEBHOOK_SUCCESS';
export const DELETE_WEBHOOK_FAILURE = 'webhooks/DELETE_WEBHOOK_FAILURE';

export const REVEAL_WEBHOOK_SECRET = 'webhooks/REVEAL_WEBHOOK_SECRET';
export const REVEAL_WEBHOOK_SECRET_SUCCESS =
  'webhooks/REVEAL_WEBHOOK_SECRET_SUCCESS';
export const REVEAL_WEBHOOK_SECRET_FAILURE =
  'webhooks/REVEAL_WEBHOOK_SECRET_FAILURE';

export const action = (type: string): IAction => ({
  type,
});

export const actionSuccess = <T>(type: string, payload: T): IAction => ({
  type: `${type}_SUCCESS`,
  payload,
});

export const actionFailure = <T>(type: string, payload: T): IAction => ({
  type: `${type}_FAILURE`,
  payload,
});
