import {
  CREATE_PAYMENTLINK,
  CREATE_PAYMENTLINK_FAILURE,
  CREATE_PAYMENTLINK_SUCCESS,
  DELETE_PAYMENTLINK,
  DELETE_PAYMENTLINK_FAILURE,
  DELETE_PAYMENTLINK_SUCCESS,
  FETCH_PAYMENTLINK_SESSIONS,
  FETCH_PAYMENTLINK_SESSIONS_FAILURE,
  FETCH_PAYMENTLINK_SESSIONS_SUCCESS,
  LIST_PAYMENTLINK,
  LIST_PAYMENTLINK_FAILURE,
  LIST_PAYMENTLINK_SUCCESS,
  VERIFY_PAYMENTLINK,
  VERIFY_PAYMENTLINK_FAILURE,
  VERIFY_PAYMENTLINK_RESET,
  VERIFY_PAYMENTLINK_SUCCESS,
  RESET_CREATE_PAYMENTLINK,
  SEND_SMS,
  SEND_SMS_SUCCESS,
  SEND_SMS_FAILURE,
  RESET_SEND_SMS,
  SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAILURE,
  RESET_SEND_EMAIL,
} from './actions';
import { PAYMENTLINKS } from './type';

export const INITIAL_STATE: PAYMENTLINKS.PaymentLinksReducer = {
  isLoading: false,
  error: {} as PAYMENTLINKS.Error,
  createPaymentLink: {} as PAYMENTLINKS.IPaymentLinksResult,
  isCreatedPaymentLink: false,
  paymentLinksList: {} as IPaginatedResponse<PAYMENTLINKS.IPaymentLinksResult>,
  isDeleted: false,
  sessionsList: {} as IPaginatedResponse<PAYMENTLINKS.IPaymentlinkSessions>,
  // paymentlinks: {} as PAYMENTLINKS.PaymentLinks,
  verified: false,
  sendSMSStatus: {} as PAYMENTLINKS.ISMSStatus,
  sendEmailStatus: {} as PAYMENTLINKS.IEmailStatus,
  verifyInvoiceNumber: {} as PAYMENTLINKS.IVerifyInvoiceNumber,
};

const paymentLinksReducer = (
  action: IAction,
  state = INITIAL_STATE
): PAYMENTLINKS.PaymentLinksReducer => {
  switch (action.type) {
    case CREATE_PAYMENTLINK:
      return {
        ...state,
        isLoading: true,
        createPaymentLink: {} as PAYMENTLINKS.IPaymentLinksResult, // for mocking purpose can remove later
        isCreatedPaymentLink: false,
        error: {},
      };
    case CREATE_PAYMENTLINK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createPaymentLink: action.payload, // for mocking purpose can remove later
        isCreatedPaymentLink: true,
        error: {},
      };
    case CREATE_PAYMENTLINK_FAILURE:
      return {
        ...state,
        isLoading: false,
        createPaymentLink: {} as PAYMENTLINKS.IPaymentLinksResult, // for mocking purpose can remove later
        isCreatedPaymentLink: false,
        error: {
          ...state.error,
          createPaymentLink: action.payload,
        },
      };

    case LIST_PAYMENTLINK:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case LIST_PAYMENTLINK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentLinksList: action.payload,
        error: {},
      };
    case LIST_PAYMENTLINK_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { fetchPaymentLinks: action.payload.message },
      };
    case FETCH_PAYMENTLINK_SESSIONS:
      return {
        ...state,
        isLoading: true,
        sessionsList:
          {} as IPaginatedResponse<PAYMENTLINKS.IPaymentlinkSessions>,
        error: {},
      };
    case FETCH_PAYMENTLINK_SESSIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sessionsList: action.payload,
        error: {},
      };
    case FETCH_PAYMENTLINK_SESSIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { fetchSessionPaymentLinks: action.payload.message },
      };

    case VERIFY_PAYMENTLINK:
      return {
        ...state,
        isLoading: true,
        verified: false,
        verifyInvoiceNumber: {} as PAYMENTLINKS.IVerifyInvoiceNumber,
        error: {},
      };
    case VERIFY_PAYMENTLINK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        verifyInvoiceNumber: action.payload,
        verified: true,
        error: {},
      };
    case VERIFY_PAYMENTLINK_FAILURE:
      return {
        ...state,
        isLoading: false,
        verifyInvoiceNumber: {} as PAYMENTLINKS.IVerifyInvoiceNumber,
        verified: false,
        error: { verifyInvoiceNo: action.payload.message },
      };
    case VERIFY_PAYMENTLINK_RESET:
      return {
        ...state,
        isLoading: false,
        verifyInvoiceNumber: {} as PAYMENTLINKS.IVerifyInvoiceNumber,
        verified: false,
        error: {},
      };
    case DELETE_PAYMENTLINK:
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
      };
    case DELETE_PAYMENTLINK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDeleted: true,
        error: {},
      };
    case DELETE_PAYMENTLINK_FAILURE:
      return {
        ...state,
        isLoading: false,
        isDeleted: false,
        error: { deletePaymentLink: action.payload.message },
      };

    case SEND_SMS:
      return {
        ...state,
        isLoading: true,
        sendSMSStatus: {} as PAYMENTLINKS.ISMSStatus,
      };
    case SEND_SMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sendSMSStatus: action.payload,
        error: {},
      };
    case SEND_SMS_FAILURE:
      return {
        ...state,
        isLoading: false,
        sendSMSStatus: {} as PAYMENTLINKS.ISMSStatus,
        error: { sendSMS: action.payload.message },
      };
    case RESET_SEND_SMS:
      return {
        ...state,
        sendSMSStatus: {} as PAYMENTLINKS.ISMSStatus,
      };
    case SEND_EMAIL:
      return {
        ...state,
        isLoading: true,
        sendEmailStatus: {} as PAYMENTLINKS.IEmailStatus,
      };
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sendEmailStatus: action.payload,
        error: {},
      };
    case SEND_EMAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        sendEmailStatus: {} as PAYMENTLINKS.IEmailStatus,
        error: { sendEmail: action.payload || 'Something went wrong' },
      };
    case RESET_SEND_EMAIL:
      return {
        ...state,
        sendEmailStatus: {} as PAYMENTLINKS.IEmailStatus,
      };
    case RESET_CREATE_PAYMENTLINK:
      return {
        ...state,
        isLoading: false,
        createPaymentLink: {} as PAYMENTLINKS.IPaymentLinksResult,
        error: {},
      };

    default:
      return state;
  }
};
export default paymentLinksReducer;
