import React from 'react';
import { Card } from 'reactstrap';
import classNames from 'classnames';
import styles from './styles.module.scss';

const ComingSoon = (): JSX.Element => {
  return (
    <>
      <Card className={classNames('border-0', styles.container)}>
        <div className={classNames('text-center', styles.content)}>
          <h1 className={classNames('h1 mb-2', styles.heading)}>
            Coming Soon...
          </h1>
          <div className={classNames('body-subtext mb-2', styles.body)}>
            This page is under construction.
          </div>
          <p className={classNames('body-subtext', styles.body)}>
            Contact us at{' '}
            <a
              className={classNames(styles.link)}
              href="mailto:hello@swifterhq.com"
            >
              hello@swifterhq.com
            </a>
          </p>
        </div>
      </Card>
    </>
  );
};

export default ComingSoon;
