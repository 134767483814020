import accessLocalStorage from 'services/localStorage';
import {
  LOGIN,
  LOGOUT,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  EDIT,
  EDIT_SUCCESS,
  EDIT_FAILURE,
  REQUEST_RESET,
  REQUEST_RESET_SUCCESS,
  REQUEST_RESET_FAILURE,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  CODE,
  CODE_SUCCESS,
  CODE_FAILURE,
  SET_VIEW,
  SET_PERMISSIONS,
  FETCH_USER_INTEGRATIONS,
  FETCH_USER_INTEGRATIONS_SUCCESS,
  FETCH_USER_INTEGRATIONS_FAILURE,
  SET_MODE_SUCCESS,
  RESEND_VERIFY_EMAIL,
  RESEND_VERIFY_EMAIL_SUCCESS,
  RESEND_VERIFY_EMAIL_FAILURE,
  RESET_USER_SESSION,
  VERIFY_OTP_CHALLENGE,
  VERIFY_OTP_CHALLENGE_FAILURE,
  VERIFY_OTP_CHALLENGE_SUCCESS,
  FETCH_TENANT,
  FETCH_TENANT_SUCCESS,
  FETCH_TENANT_FAILURE,
  SET_SSO_LOGIN,
  SET_PERMISSION_ERROR,
} from './actions';
import { errorMessage } from '../../utils/constants';
import { AUTH } from './type';

export const INITIAL_STATE: AUTH.IUserReducer = {
  isSandbox: false,
  loggedIn: false,
  isSSOLogin: false,
  user: {} as AUTH.IUser,
  userIntegrations: {} as AUTH.IUserIntegration,
  error: {},
  isRequested: false,
  // isReset: false,
  isEmailVerified: false,
  register: false,
  permissions: {} as AUTH.IPermission,
  isLoading: false,
  edited: false,
  isAuthRequired: false,
  code: false,
  view: '',
  otp_challenge_verified: {} as AUTH.IUserOtpVerified,
  permissionError: false,
};

const authReducer = (
  action: IAction,
  state = INITIAL_STATE
): AUTH.IUserReducer => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loggedIn: false,
        user: {} as AUTH.IUser,
        isLoading: true,
        error: {},
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        isLoading: false,
        user: action.payload,
        error: {},
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        isLoading: false,
        user: {} as AUTH.IUser,
        error: { login: action.payload || errorMessage.GENERIC },
      };
    case REGISTER:
      return {
        ...state,
        register: false,
        isLoading: true,
        error: {},
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        register: true,
        isLoading: false,
        error: {},
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        register: false,
        isLoading: false,
        error: { register: action.payload || errorMessage.GENERIC },
      };
    case FETCH_USER:
      return {
        ...state,
        isLoading: true,
        error: {},
        isAuthRequired: false,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        user: action.payload, // .user
        loggedIn: true,
        isLoading: false,
        error: {},
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { user: action.payload },
      };
    case FETCH_USER_INTEGRATIONS:
      return {
        ...state,
        isLoading: true,
        error: {},
        userIntegrations: {} as AUTH.IUserIntegration,
      };
    case FETCH_USER_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        userIntegrations: action.payload,
        isLoading: false,
        error: {},
      };
    case FETCH_USER_INTEGRATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { fetchUserIntegrations: action.payload },
      };
    case EDIT:
      return {
        ...state,
        error: {},
        isLoading: true,
        isAuthRequired: false,
      };
    case EDIT_SUCCESS:
      return {
        ...state,
        error: {},
        isLoading: false,
        edited: true,
        user: { ...state.user, ...action.payload.user },
      };
    case EDIT_FAILURE:
      return {
        ...state,
        error: { edit: action.payload || errorMessage.GENERIC },
        isLoading: false,
        edited: false,
      };
    case REQUEST_RESET:
      return {
        ...state,
        error: {},
        isLoading: true,
        isRequested: false,
      };
    case REQUEST_RESET_SUCCESS:
      return {
        ...state,
        error: {},
        isLoading: false,
        isRequested: true,
      };
    case REQUEST_RESET_FAILURE:
      return {
        ...state,
        error: { reset: action.payload || errorMessage.GENERIC },
        isLoading: false,
        isRequested: false,
      };
    case CODE:
      return {
        ...state,
        isLoading: true,
        error: {},
      };

    case CODE_SUCCESS:
      return {
        ...state,
        code: true,
        isLoading: false,
        error: {},
      };

    case CODE_FAILURE:
      return {
        ...state,
        code: false,
        isLoading: false,
        error: { code: action.payload || errorMessage.GENERIC },
      };
    case LOGOUT:
      return {
        ...state,
        loggedIn: false,
        isLoading: true,
        error: {},
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        loggedIn: false,
        isLoading: false,
        error: {},
      };

    case LOGOUT_FAILURE:
      return {
        ...state,
        loggedIn: true,
        isLoading: false,
        error: action.payload || errorMessage.GENERIC,
      };

    case SET_VIEW:
      return { ...state, view: action.payload };

    case SET_MODE_SUCCESS:
      return {
        ...state,
        isSandbox: action.payload,
      };

    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };

    case RESEND_VERIFY_EMAIL:
      return {
        ...state,
        isLoading: true,
        error: {},
      };

    case RESEND_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: {},
      };

    case RESEND_VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { resendVerifyEmail: action.payload },
      };

    case VERIFY_OTP_CHALLENGE:
      return {
        ...state,
        isLoading: true,
        otp_challenge_verified: {} as AUTH.IUserOtpVerified,
        error: {},
      };
    case VERIFY_OTP_CHALLENGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        otp_challenge_verified: action.payload,
        error: {},
      };

    case VERIFY_OTP_CHALLENGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        otp_challenge_verified: {} as AUTH.IUserOtpVerified,
        error: { otpChallengeVerify: action?.payload?.data },
      };

    case FETCH_TENANT:
      return {
        ...state,
        isLoading: true,
        user: {} as AUTH.IUser,
      };

    case FETCH_TENANT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loggedIn: true,
        user: action.payload,
      };

    case FETCH_TENANT_FAILURE:
      return {
        ...state,
        loggedIn: false,
        isLoading: false,
        user: {} as AUTH.IUser,
      };

    case RESET_USER_SESSION:
      return {
        ...state,
        otp_challenge_verified: {} as AUTH.IUserOtpVerified,
        error: { otpChallengeVerify: undefined },
      };

    case SET_PERMISSION_ERROR:
      return {
        ...state,
        permissionError: action.payload,
      };

    case SET_SSO_LOGIN:
      return { ...state, isSSOLogin: true };
    default:
      return state;
  }
};

export default authReducer;
