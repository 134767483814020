import React from 'react';
import classNames from 'classnames';
import { Row } from 'reactstrap';
import { formatToTz } from 'utils/date-format';
import { eventTransactionFeesClass, payoutEventDetails } from 'utils/timelineEvents';
import { BANKING } from 'state/banking/type';
import useOrganisation from 'state/organisation/hooks/useOrganisation';
import styles from '../../transactions/transactions.module.scss';

interface IProps {
  events?: BANKING.IPayoutEvent[];
  payout: BANKING.IPayout;
  className?: string;
}

const Timeline = ({
  events = [],
  payout = {} as BANKING.IPayout,
  className = '',
}: IProps): JSX.Element => {
  const { organisation } = useOrganisation();
  const { settings } = organisation;

  return (
    <>
      <div className={`mb-7 ${className}`}>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h3 className="mb-2 PaymentDetailsHeading">Timeline</h3>
        </div>
        <hr className="mt-0 mb-10" />
        {events.length >= 1 && (
          <>
            {events
              .slice()
              .reverse()
              .map((e, index) => {
                let timelineEventName ;
                let timelineEventIcon;
                if ( e.name === BANKING.TransactionFeeEvents.CREATED) 
                {
                  const timelineEvent = eventTransactionFeesClass[e?.name]
                  timelineEventIcon = timelineEvent?.icon
                  timelineEventName = timelineEvent?.formatter(e)
                }
                else 
                {
                  const timelineEvent = payoutEventDetails[e?.name];
                  timelineEventIcon = timelineEvent?.icon 
                  timelineEventName = timelineEvent?.formatter(payout)
                }

                return (
                  <>
                    {timelineEventName && (
                      <Row className="pl-4" data-testid="payout_event">
                        <div className="pr-5 d-flex PL6">
                          <div
                            className={
                              index === events.length - 1
                                ? classNames(styles.timelineIcon, styles.last)
                                : classNames(styles.timelineIcon)
                            }
                          >
                            <i
                              className={classNames(
                                timelineEventIcon,
                                'icon-md'
                              )}
                            />
                          </div>
                          <div className="pb-4 DIB">
                            <div className="TimelineSummaryHeading">
                              {timelineEventName}
                            </div>
                            <div className="TimelineHeadingData">
                              {formatToTz(
                                e.create_date,
                                settings?.timezone,
                                'MMM dd, hh:mm a'
                              )}
                            </div>
                          </div>
                        </div>
                      </Row>
                    )}
                  </>
                );
              })}
          </>
        )}
      </div>
    </>
  );
};

export default Timeline;
