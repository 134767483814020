import classNames from 'classnames';
import React from 'react';
import styles from './banner.module.scss';

export enum BannerType {
  warning = 'warning',
  error = 'error',
}

interface IProps {
  showBanner: boolean;
  heading?: string;
  message: string;
  type?: BannerType;
  setShowBanner?: () => void;
  listOfError?: boolean;
  fixedHeight?: boolean;
  testId?: string;
}

const BannerContainer = ({
  showBanner,
  type,
  message,
  heading,
  setShowBanner,
  listOfError = true,
  fixedHeight = true,
  testId,
}: IProps): JSX.Element => {
  return (
    <>
      {showBanner && (
        <div
          className={classNames(
            setShowBanner ? styles.banner : styles.noCloseBtnBanner,
            type === BannerType.error
              ? styles.errorBanner
              : styles.warningBanner
          )}
          style={fixedHeight ? { height: '92px' } : {}}
          data-testid={testId}
        >
          {type === BannerType.error ? (
            <>
              <span id={`settle-tooltip`} className={classNames('pl-1 mr-2 ')}>
                <span className="d-flex align-items-center">
                  <i
                    className={classNames(
                      'ph-info-fill icon-lg pr-2 dangerIcon '
                    )}
                  />
                  <span className={classNames(styles.dangerText)}>
                    {heading}
                  </span>
                </span>
                {listOfError ? (
                  <span>
                    <ul>
                      <li className={styles.message}>{message}</li>
                    </ul>
                  </span>
                ) : (
                  <span className={classNames(styles.message, 'ml-5 d-block')}>
                    {message}
                  </span>
                )}
              </span>
            </>
          ) : (
            <>
              <span
                id={`settle-tooltip`}
                className={classNames('pl-1 mr-2 d-flex')}
              >
                <i
                  className={classNames(
                    'ph-info-fill icon-lg pr-2 warningIcon pt-1'
                  )}
                />

                <span className={styles.warningText}>{message}</span>
                {setShowBanner && (
                  <span className={styles.closeBtn} onClick={setShowBanner}>
                    <i className={classNames('ph-x icon-lg pt-1')} />
                  </span>
                )}
              </span>
            </>
          )}
        </div>
      )}
    </>
  );
};
export default BannerContainer;
