export const GET_FORM_DETAILS = 'kybActivation/GET_FORM_DETAILS';
export const GET_FORM_DETAILS_SUCCESS =
  'kybActivation/GET_FORM_DETAILS_SUCCESS';
export const GET_FORM_DETAILS_FAILURE =
  'kybActivation/GET_FORM_DETAILS_FAILURE';

export const SET_FORM_VIEW = 'kybActivation/SET_FORM_VIEW';
export const SET_BO_FORM_VIEW = 'kybActivation/SET_BO_FORM_VIEW';
export const SET_BO_LIST = 'kybActivation/SET_BO_LIST';
export const RESET = 'kybActivation/RESET';

export const SUBMIT_FORM = 'kybActivation/SUBMIT_FORM';
export const SUBMIT_FORM_SUCCESS = 'kybActivation/SUBMIT_FORM_SUCCESS';
export const SUBMIT_FORM_FAILURE = 'kybActivation/SUBMIT_FORM_FAILURE';

export const SUBMIT_EXTERNAL_FORM = 'kybActivation/SUBMIT_EXTERNAL_FORM';
export const SUBMIT_EXTERNAL_FORM_SUCCESS =
  'kybActivation/SUBMIT_EXTERNAL_FORM_SUCCESS';
export const SUBMIT_EXTERNAL_FORM_FAILURE =
  'kybActivation/SUBMIT_EXTERNAL_FORM_FAILURE';

export const FETCH_EXTERNAL_FORM = 'kybActivation/FETCH_EXTERNAL_FORM';
export const FETCH_EXTERNAL_FORM_SUCCESS =
  'kybActivation/FETCH_EXTERNAL_FORM_SUCCESS';
export const FETCH_EXTERNAL_FORM_FAILURE =
  'kybActivation/FETCH_EXTERNAL_FORM_FAILURE';

export const AUTO_SAVE = 'kybActivation/AUTO_SAVE';
export const AUTO_SAVE_SUCCESS = 'kybActivation/AUTO_SAVE_SUCCESS';
export const AUTO_SAVE_RESET = 'kybActivation/AUTO_SAVE_RESET';

export const SET_EXTERNAL_BO_TOKEN = 'kybActivation/SET_EXTERNAL_BO_TOKEN';

export const RESET_IS_SUBMITTED = 'kybActivation/RESET_IS_SUBMITTED';
export const RESET_SUBMIT_STAGE = 'kybActivation/RESET_SUBMIT_STAGE';
export const RESET_EXT_SUBMIT_STAGE = 'kybActivation/RESET_EXT_SUBMIT_STAGE';

export const REFRESH_FORM = 'kybActivation/REFRESH_FORM';
export const REFRESH_FORM_SUCCESS = 'kybActivation/REFRESH_FORM_SUCCESS';

export const action = <T>(type: string, payload?: T): IAction => ({
  type,
  payload,
});

export const actionSuccess = <T>(type: string, payload: T): IAction => ({
  type: `${type}_SUCCESS`,
  payload,
});

export const actionFailure = <T>(type: string, payload: T): IAction => ({
  type: `${type}_FAILURE`,
  payload,
});
